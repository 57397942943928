import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable, autorun } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { FilterIcon } from '@seedlang/icons';
import Modal from 'components/modal';
import FilterOptionsItem from 'components/filter_options_item';
import Text from 'components/text';
import Paginator from 'components/paginator';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import Spinner from 'components/spinner';
import { Theme } from '@seedlang/constants';
import { Link } from 'react-router';
import { each, keys } from 'lodash';
import { isBlank } from '@seedlang/utils';
import SearchInput from 'components/form/search_input';

const Wrapper = styled.div`

`;

const TopBar = styled.div`
  display: flex;
  margin-bottom: 10px;
  margin-top: ${props => props.marginTop};
`;

const FilterLabel = styled.div`
  width: 60px;
  padding-left: 10px;
  font-size: 12px;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color: #333;
  height: 19px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ModalWrapper = styled.div`
  padding: 20px 10px;
`;

const Tag = styled.div`
  background: #FFF;
  padding: 5px;
  padding-right: ${props => props.paddingRight};
  border-radius: 5px;
  margin: 3px;
  position: relative;
  border: 1px solid #CCC;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content;
`;

const Filter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFF;
  border: 3px solid #CCC;
  border-radius: 5px;
  height: 46px;
  width: calc(100% - 20px);
  margin: 10px;
`;

const FilterIconWrapper = styled.div`
  width: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  cursor: pointer;
  svg {
    fill: #333;
    width: 20px;
  }
`;

const Deck = styled.div`
  display: flex;
  padding: 10px;
  background: #FFF;
  margin: 10px;
  align-items: center;
`;

const ImageWrapper = styled.div`
  background: #2F25BB;
  border-radius: 10px;
  img {
    border-radius: 10px;
    width: 120px;
    height: 120px;
  }
  cursor: pointer;
  margin-right: 10px;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  background: #e1e1e1;
  display: flex;
  margin-right: 10px;
  align-items: center;
  height: 35px;
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
  color: #333;
  width: 180px;
  padding: 0 0 0 10px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
`;

const Field = styled.div`
  display: flex;
  font-size: 14px;
  flex: 1;
  padding-right: 10px;
  .fa-times {
    color: ${Theme.red};
  }
  .fa-check {
    color: ${Theme.green};
  }
`;

const Labels = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

@observer
class TranslatorSentencesDecksIndex extends React.Component {
  @observable showFilterOptions = false;
  @observable selected = {};
  @observable userIsLoaded = false;
  @observable showSpinner = false;
  @observable searchQuery = '';

  constructor(props) {
    super(props);
    autorun(() => {
      if (AppUI.userIsLoaded && !this.userIsLoaded) {
        this.userIsLoaded = true;
        this.getDecks();
      }
    });
  }

  @computed get hasAllTranslationsString() {
    if (AppUI.user.translatorsFilterHasTranslation === null) {
      return 'Any';
    }
    return AppUI.user.translatorsFilterHasTranslation ? 'Yes' : 'No';
  }

  @computed get filters() {
    const filters = {
      deck_type: '!trivia',
      web_published: true,
      language_id: AppUI.user.targetLanguageId,
    };
    if (AppUI.user.translatorsFilterHasTranslation !== null) {
      filters['languages_string'] = AppUI.user.translatorsFilterHasTranslation ? AppUI.user.languageId : `!${AppUI.user.languageId}`;
    }
    if (this.searchQuery && this.searchQuery.length > 2) {
      filters['name'] = `~${this.searchQuery}~`;
    }
    return filters;
  }

  @autobind getDecks() {
    this.showSpinner = true;
    this.userRequestIsPending = false;
    AppUI.deckStore.setFilters(this.filters);
    AppUI.deckStore.getIndex({sort: 'name'}, this.afterGetDecks);
  }

  @autobind afterGetDecks(resp) {
    this.showSpinner = false;
    AppUI.deckStore.setIndexData(resp);
  }

  @autobind setShowFilterOptions(showFilterOptions) {
    this.showFilterOptions = showFilterOptions;
  }

  @autobind onSetFilter(key, value) {
    this.selected[key] = value;
    const data = {};
    data[key] = value;
    this.updateUser(data, this.getDecks);
  }

  @autobind updateUser(data, callback) {
    each(keys(data), key => {
      if (AppUI.user) {
        AppUI.user.set(key, data[key]);
      }
      if (isBlank(this.selected)) {
        this.selected = {};
      }
      if (this.userRequestIsPending) {
        this.selected[key] = data[key];
      }
    });
    if (!this.userRequestIsPending) {
      this.userRequestIsPending = true;
      if (callback) {
        this.callback = callback;
      }
      AppUI.authUserStore.update({data: this.selected}, this.getDecks);
    }
  }

  @autobind onUpdateSearchQuery(val) {
    this.searchQuery = val;
    if (val?.length > 1 || isBlank(val)) {
      clearInterval(this.interval);
      this.interval = setTimeout(() => this.getDecks(), 500);
    }
  }

  render() {
    return (
      <Wrapper>
        {
          this.showFilterOptions &&
            <Modal
              width='400px'
              height='600px'
              onCloseModal={() => this.setShowFilterOptions(false)}
              gradient
              padding='0'
              title='Sentence Filters'
              contentShouldScroll
            >
              <ModalWrapper>
                <FilterOptionsItem
                  label='Language'
                  filterType='select'
                  userKey='targetLanguageId'
                  options={[['DE', 'German'], ['ES', 'Spanish'], ['FR', 'French']]}
                  value={AppUI.user.targetLanguageId}
                  onSetFilter={this.onSetFilter}
                />
                <FilterOptionsItem
                  label='Has All Translations'
                  filterType='select'
                  userKey='translatorsFilterHasTranslation'
                  options={[[null, 'Any'], [true, 'Yes'], [false, 'No']]}
                  value={AppUI.user.translatorsFilterHasTranslation}
                  onSetFilter={this.onSetFilter}
                />
              </ModalWrapper>
            </Modal>
        }
        <TopBar
          marginTop='10px'
        >
          <SearchInput
            placeholder='Search for a deck'
            onChange={this.onUpdateSearchQuery}
            value={this.searchQuery}
            background='#FFF'
          />
        </TopBar>
        <Filter>
          <FilterLabel
            onClick={() => this.setShowFilterOptions(true)}
          >
            Filters
          </FilterLabel>
          <Center>
            <Tag>
              <Text
                color='#333'
                bold
                fontSize={12}
                lineHeight={15}
              >
                Has All Translations: {this.hasAllTranslationsString}
              </Text>
            </Tag>
            <Tag>
              <Text
                color='#333'
                bold
                fontSize={12}
                lineHeight={15}
              >
                Language: {AppUI.user.targetLanguageId }
              </Text>
            </Tag>
          </Center>
          <FilterIconWrapper
            onClick={() => this.setShowFilterOptions(true)}
          >
            <FilterIcon
              width={20}
              height={20}
              fill='#444'
            />
          </FilterIconWrapper>
        </Filter>
        {
          this.showSpinner &&
            <Spinner />
        }
        {
          !this.showSpinner && AppUI.deckStore.indexData.map(item => {
            return (
              <Deck
                key={item.id}
              >
                <Labels>
                  <Row>
                    <Label>
                      Deck
                    </Label>
                    <Field>
                      <Link
                        className='underline'
                        to={{name: 'translator.decks_show', params: {deckId: item.id, languageId: this.props.params.languageId}}}
                      >
                        {item.name}
                      </Link>
                    </Field>
                  </Row>
                  <Row>
                    <Label>
                      Sentences Count
                    </Label>
                    <Field>
                      {item.concept?.sentencesCount}
                    </Field>
                  </Row>
                  <Row>
                    <Label>
                      Has All Translations
                    </Label>
                    <Field>
                      { item.hasAllTranslations(AppUI.user.languageId) && <i className='fa fa-check' /> }
                      { !item.hasAllTranslations(AppUI.user.languageId) && <i className='fa fa-times' /> }
                    </Field>
                  </Row>
                </Labels>
                <ImageWrapper>
                  <Link
                    to={{name: 'translator.decks_show', params: {deckId: item.id, languageId: this.props.params.languageId}}}
                  >
                    <img
                      src={item.imageUrl}
                      alt='Deck thumbnail'
                    />
                  </Link>
                </ImageWrapper>
              </Deck>
            );
          })
        }
        {
          !this.showSpinner &&
            <Paginator
              store={AppUI.deckStore}
              filters={this.filters}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TranslatorSentencesDecksIndex);
