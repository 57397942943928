import React from 'react';
import cx from 'classnames';
import { CheckWithCircleIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.span`
  color: #000;
  display: block;
  margin: ${props => props.margin};
  font-size: 14px;
  line-height: 16px;
  svg {
    width: ${props => props.width};
    fill: ${props => props.checked ? Theme.green : '#a5a5a5'};
    margin-right: 2px;
  }
  .fa {
    font-size: ${props => props.iconFontSize};
    margin-right: 5px;
  }
`;

class CheckIfTrue extends React.Component {

  static defaultProps = {
    margin: '0 5px 5px 0',
    width: '24px',
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
        checked={this.props.value}
        width={this.props.width}
        iconFontSize={this.props.iconFontSize}
        style={this.props.style}
      >
        {
          this.props.circle &&
            <CheckWithCircleIcon />
        }
        {
          !this.props.circle &&
            <i
              className={cx('fa', 'fa-check', {
                disabled: !this.props.value,
              })}
              style={{
                visibility: !this.props.hideIfFalse || this.props.value ? 'visible' : 'hidden',
              }}
            />
        }
        {this.props.label}
      </Wrapper>
    );
  }
}

export default CheckIfTrue;
