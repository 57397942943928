import React from 'react';
import { observer } from 'mobx-react';
import { computed, autorun, observable } from 'mobx';
import { ExerciseUI , AppUI } from '@seedlang/state';
import { flexCenterColumn } from '@seedlang/style_mixins';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Text from 'components/text';
import Alert from 'components/alert';
import Button from 'components/button/button';
import { last } from 'lodash';
import PatreonConfirmation from 'components/user/patreon_confirmation';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  .patreon-logo {
    width: 300px;
    cursor: pointer;
  }
  .sortable-columns {
    width: 100%;
  }
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  margin: 0 0 10px 0;
  @media only screen and (max-width : 649px) {
    padding: 0px 5px;
  }
`;

const Group = styled.div`
  width: 100%;
`;

const Underline = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const Block = styled.div`
  ${flexCenterColumn()}
  max-width: 600px;
  background: white;
  border: 1px solid #CCC;
  border-radius: 20px;
  padding: 20px;
`;

const Content = styled.div`
  display: flex;
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  @media only screen and (max-width : 649px) {
    padding: 0px 5px;
  }
`;

const Or = styled.div`
  margin: 0 5px;
`;

@observer
class GroupShow extends React.Component {

  @computed get routeName() {
    return this.props.router && last(this.props.router.routes).name;
  }

  constructor(props) {
    super(props);
    const groupId = AppUI.siteIsDefault ? 'f74a4102-d65b-448c-b261-60b6be2c7eca' : AppUI.site.groupId;
    if (groupId) {
      ExerciseUI.set('groupId', groupId);
      ExerciseUI.groupStore.getShow({ids: {groupId: groupId}}, this.afterGetShow);
      this.disposer = autorun(() => {
        if (!this.props.children && AppUI.routeStore.routerIsSetup) {
          AppUI.routeStore.routeToNamed('worksheets.index');
        }
      });
    }
  }

  @autobind afterGetShow(resp) {
    ExerciseUI.groupStore.setShowData(resp);
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  render() {
    return (
      <Wrapper>
        {
          ExerciseUI.user.signedOut && AppUI.siteIsDefault &&
            <Block>
              <Text
                bold
                center
              >
                To gain access to the worksheets, please&nbsp;
                <Underline
                  onClick={() => AppUI.routeStore.routeToNamed('onboarding.registration')}
                >
                  register
                </Underline>
                <Or>
                  or
                </Or>
                <Underline
                  onClick={() => AppUI.routeStore.routeToNamed('onboarding.sign_in')}
                >
                  sign in
                </Underline>
                .
              </Text>
            </Block>
        }
        {
          (!ExerciseUI.hasWorksheetAccess && AppUI.siteIsDefault) &&
            <Block>
              {
                ExerciseUI.group &&
                  <div dangerouslySetInnerHTML={{ __html: ExerciseUI.group.noAccessText }} />
              }
              <PatreonConfirmation />
            </Block>
        }
        {
          window.location.href.match('patreon_error') &&
            <Alert
              textAlign='center'
            >
              We're sorry, but the worksheets are only available to current Patrons of Easy German.
            </Alert>
        }
        {
          ExerciseUI.hasWorksheetAccess &&
            <Group>
              {
                AppUI.siteIsDefault &&
                  <TopBar>
                    <Text
                      bold
                    >
                      {ExerciseUI.group.name}
                    </Text>
                  </TopBar>
              }
              {
                AppUI.siteIsDefault &&
                  <Buttons>
                    <Button
                      fontSize='14px'
                      color='#333'
                      borderRadius='5px'
                      padding={ExerciseUI.layout.isMobile ? '0 5px' : '0 10px'}
                      background={this.routeName === 'worksheets.index' ? '#FFF' : '#D8D8D8'}
                      onClick={() => ExerciseUI.routeStore.routeToNamed('worksheets.index', {groupId: this.props.params.groupId})}
                    >
                      WORKSHEETS
                    </Button>
                    {
                      !ExerciseUI.layout.isMobile &&
                        <Button
                          fontSize='14px'
                          color='#333'
                          borderRadius='5px'
                          padding={ExerciseUI.layout.isMobile ? '0 5px' : '0 10px'}
                          background={this.routeName === 'exercises.index' ? '#FFF' : '#D8D8D8'}
                          onClick={() => ExerciseUI.routeStore.routeToNamed('exercises.index', {groupId: this.props.params.groupId})}
                        >
                          FILTER EXERCISES
                        </Button>
                    }
                    <Button
                      fontSize='14px'
                      color='#333'
                      borderRadius='5px'
                      padding={ExerciseUI.layout.isMobile ? '0 5px' : '0 10px'}
                      background={this.routeName === 'groups.info' ? '#FFF' : '#D8D8D8'}
                      onClick={() => ExerciseUI.routeStore.routeToNamed('groups.info', {groupId: this.props.params.groupId})}
                    >
                      {ExerciseUI.group.infoTitle ? ExerciseUI.group.infoTitle.toUpperCase() : 'INFO'}
                    </Button>
                    {
                      ExerciseUI.group.info2Title &&
                        <Button
                          fontSize='14px'
                          color='#333'
                          borderRadius='5px'
                          padding={ExerciseUI.layout.isMobile ? '0 5px' : '0 10px'}
                          background={this.routeName === 'groups.info2' ? '#FFF' : '#D8D8D8'}
                          onClick={() => ExerciseUI.routeStore.routeToNamed('groups.info2', {groupId: this.props.params.groupId})}
                        >
                          {ExerciseUI.group.info2Title.toUpperCase()}
                        </Button>
                    }
                  </Buttons>
              }
              <Content>
                {this.props.children}
              </Content>
            </Group>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GroupShow);
