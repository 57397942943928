import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { LanguageIcon } from '@seedlang/icons';
import Dropdown from 'components/dropdown';
import { Languages } from '@seedlang/constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin};
  .hover-state:hover {
    color: #FFF;
  }
`;

const LanguageOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const TextWrapper = styled.div`
  flex: 1;
  margin-right: 5px;
  text-align: left;
  font-size: 14px;
`;

const LanguageIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 50%;
`;

@observer
class LanguageDropdown extends React.Component {

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <Dropdown
          top='60px'
          right='60px'
        >
          <LanguageIconWrapper>
            <LanguageIcon
              width='32px'
              languageId={this.props.languageId || 'DE'}
            />
          </LanguageIconWrapper>
          <ul>
            {
              this.props.languageIds.map(item => {
                return (
                  <li
                    className='hover-state'
                    key={item}
                    onClick={() => this.props.onClick(item)}
                  >
                    <LanguageOption>
                      <TextWrapper>
                        {Languages[item]}
                      </TextWrapper>
                      <LanguageIcon
                        width='25px'
                        languageId={item}
                      />
                    </LanguageOption>
                  </li>
                );
              })
            }
            </ul>
        </Dropdown>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LanguageDropdown);
