import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { isPresent } from '@seedlang/utils';
import { InfoIcon } from '@seedlang/icons';
import { Constants, Theme } from '@seedlang/constants';
import { keys } from 'lodash';
import { DeckUI } from '@seedlang/state';

const BG_COLOR = {
  ak: Theme.blue,
  dt: Theme.red,
  nm: '#333',
  gn: Theme.purple,
};

const Wrapper = styled.div`
  font-weight: ${props => props.bold ? 'bold' : 'inherit'};
  font-size: ${props => props.big ? '24px' : '16px'};
  margin: ${props => props.big ? '5px 0' : 0};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: left;
  text-align: left;
  cursor: pointer;
  padding: 3px 5px;
  line-height: 20px;
`;

const IconWrapper = styled.div`
  width: 25px;
  .icon-wrapper {
    display: inline-block;
    width: 25px!important;
    height: 20px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
`;

const Line1 = styled.div`

`;

const Line2 = styled.div`
  font-size: 14px;
  font-style: italic;
`;

const ArticleWrapper = styled.div`
  display: flex;
  margin: 10px 0 0 0;
  overflow: hidden;
`;

const Article = styled.div`
  display: flex;
  border-right: ${props => props.borderRight};
  font-size: 12px;
  font-weight: bold;
  padding: 2px 10px;
  text-transform: uppercase;
  width: ${props => props.width};
  background: ${props => props.background};
  color: ${props => props.background ? '#FFF' : '#333'};
  align-items: center;
  justify-content: center;
`;

const CaseAbbreviation = styled.div`
  border-right: ${props => props.borderRight};
  border-bottom: ${props => props.borderBottom};
  background: ${props => props.background};
  display: flex;
  font-size: 10px;
  padding: 2px 10px;
  width: ${props => props.width};
  color: #333;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const CaseAndArticle = styled.div`
  display: flex;
  flex-direction: column;
`;

@observer
class WordCardTitle extends React.Component {

  render() {
    return (
      <Wrapper
        big={this.props.big}
        bold={this.props.bold}
      >
        <ColumnWrapper>
          <TextWrapper>
            <IconWrapper>
              {
                !this.props.icon && !this.props.hideIcon &&
                  <InfoIcon />
              }
              {this.props.icon}
            </IconWrapper>
            {
              !this.props.concept && isPresent(this.props.text) &&
                <TextWrapper>
                  {this.props.text}
                </TextWrapper>
            }
            {
              this.props.concept &&
                <ColumnWrapper>
                  <Line1>
                    {this.props.concept.displayNameLine1WithCase}
                  </Line1>
                  {
                    isPresent(this.props.concept.displayNameLine1) &&
                     <Line2>
                       {this.props.concept.displayNameLine2}
                     </Line2>
                  }
                </ColumnWrapper>
            }
          </TextWrapper>
          {
            this.props.gender && DeckUI.deck.languageId === 'DE' &&
              <ArticleWrapper>
                {
                  keys(Constants.ARTICLES_BY_GENDER[this.props.gender]).map((caseAbbreviation, index) => {
                    return (
                      <CaseAndArticle
                        key={caseAbbreviation}
                      >
                        <CaseAbbreviation
                          borderRight={index < 3 ? '1px solid #9a9a9a' : null}
                          borderBottom={`4px solid ${BG_COLOR[caseAbbreviation.toLowerCase()]}`}
                          width={DeckUI.layout.deckWidth > 420 ? '90px' : '55px'}
                        >
                          {DeckUI.layout.deckWidth > 420 ? Constants.CASE_NAMES[caseAbbreviation.toLowerCase()] : Constants.CASE_NAMES[caseAbbreviation.toLowerCase()].substring(0, 3)}
                        </CaseAbbreviation>
                        <Article
                          borderRight={index < 3 ? '1px solid #9a9a9a' : null}
                          width={DeckUI.layout.deckWidth > 420 ? '90px' : '55px'}
                        >
                          {
                            this.props.caseAbbreviation && caseAbbreviation.toLowerCase() === this.props.caseAbbreviation.toLowerCase() &&
                              <i className='fa fa-check' />
                          }
                          {Constants.ARTICLES_BY_GENDER[this.props.gender][caseAbbreviation]}
                        </Article>
                      </CaseAndArticle>
                    );
                  })
                }
              </ArticleWrapper>
          }
        </ColumnWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordCardTitle);
