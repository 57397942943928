import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { WordTypeStore, SpacyAttributeStore, ConceptStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import autobind from 'autobind-decorator';
import Modal from 'components/modal';
import { isPresent, isBlank } from '@seedlang/utils';
import DeleteButton from 'components/button/delete_button';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import ConceptCreate from 'pages/builder/concepts/concept_create';

@observer
class WordTypeEdit extends React.Component {
  @observable tag;
  @observable showCreate = false;

  componentDidMount() {
    this.loadWordType();
  }

  componentWillUnmount() {
    WordTypeStore.clearShowData();
  }

  @autobind loadWordType() {
    WordTypeStore.getShow({ ids: {wordTypeId: this.props.params.wordTypeId }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    WordTypeStore.setShowData(resp);
  }

  @autobind onDeleteSpacyAttribute(id) {
    SpacyAttributeStore.destroy({ids: {spacyAttributeId: id}}, this.loadWordType);
  }

  @autobind onDeleteConcept(id) {
    ConceptStore.destroy({ids: {conceptId: id}}, this.loadWordType);
  }

  @autobind onCreateSpacyAttribute() {
    SpacyAttributeStore.create({data: {
      tag: this.refs.tag.value,
      wordTypeId: WordTypeStore.showData.id,
    }}, this.loadWordType);
  }

  @autobind onCopy(id) {
    ConceptStore.copy({ ids: { conceptId: id } }, this.loadWordType);
  }

  render() {
    return (
      <div className='word_type-edit'>
        {
          !this.props.hideCreateButton && this.showCreate &&
            <Modal
              onCloseModal={() => this.showCreate = false}
            >
              <ConceptCreate
                data={{
                  wordTypeId: WordTypeStore.showDataField('id'),
                  showAsWordType: true,
                }}
              />
            </Modal>
        }
        <div className='row'>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Name</legend>
              {WordTypeStore.showDataField('name')}
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Abbreviation</legend>
              {
                isBlank(WordTypeStore.showDataField('abbreviation')) &&
                  <InPlaceText
                    defaultValue={WordTypeStore.showDataField('abbreviation')}
                    model='word_types'
                    field='abbreviation'
                    id={WordTypeStore.showDataField('id')}
                  />
              }
              {
                isPresent(WordTypeStore.showDataField('abbreviation')) &&
                  <span>
                    {WordTypeStore.showDataField('abbreviation')}
                  </span>
              }
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Settings</legend>
              <div>
                <InPlaceCheckbox
                  value={WordTypeStore.showDataField('root')}
                  model='word_types'
                  field='root'
                  icon='check'
                  id={WordTypeStore.showDataField('id')}
                  afterChange={this.loadWordType}
                >
                  Root
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  value={WordTypeStore.showDataField('evaluateFromRoot')}
                  model='word_types'
                  field='evaluate_from_root'
                  icon='check'
                  id={WordTypeStore.showDataField('id')}
                >
                  Evaluate from Root
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  value={WordTypeStore.showDataField('wordOption')}
                  model='word_types'
                  field='word_option'
                  icon='check'
                  id={WordTypeStore.showDataField('id')}
                >
                  Word Option
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  value={WordTypeStore.showDataField('requiresCase')}
                  model='word_types'
                  field='requires_case'
                  icon='check'
                  id={WordTypeStore.showDataField('id')}
                >
                  Requires Case
                </InPlaceCheckbox>
              </div>
              <div>
                <InPlaceCheckbox
                  value={WordTypeStore.showDataField('useNextWordForCase')}
                  model='word_types'
                  field='use_next_word_for_case'
                  icon='check'
                  id={WordTypeStore.showDataField('id')}
                >
                  Use Next Word For Case
                </InPlaceCheckbox>
              </div>
            </fieldset>
          </div>
        </div>
        {
          !WordTypeStore.showDataField('root') &&
            <div className='row'>
              <div className='col-xs-3'>
                  <fieldset>
                    <legend>Parent</legend>
                    <InPlaceSelect
                      includeBlank
                      value={isPresent(WordTypeStore.showData.rootWordType) ? WordTypeStore.showData.rootWordType.id : null}
                      model='word_types'
                      field='root_word_type_id'
                      id={WordTypeStore.showDataField('id')}
                      options={WordTypeStore.rootWordTypes.map(item => [item.id, item.name])}
                      afterChange={this.loadWordType}
                    />
                  </fieldset>
              </div>
              <div className='col-xs-3'>
                <fieldset>
                  <legend>Preceding</legend>
                  <InPlaceSelect
                    includeBlank
                    value={isPresent(WordTypeStore.showData.precedingWordType) ? WordTypeStore.showData.precedingWordType.id : null}
                    model='word_types'
                    field='preceding_word_type_id'
                    id={WordTypeStore.showDataField('id')}
                    options={WordTypeStore.indexData.map(item => [item.id, item.name])}
                    afterChange={this.loadWordType}
                  />
                </fieldset>
              </div>
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Concepts</legend>
              {
                !this.props.hideCreateButton && (
                  <button
                    style={{margin: '0 0 10px 0'}}
                    className='gray-button'
                    onClick={() => this.showCreate = true}
                  >
                    <i className='fa fa-plus' />
                    Create
                  </button>
                )
              }
              <table className='table-wrapper admin-table'>
                <thead>
                  <tr>
                    <th width='50' />
                    <th>Name</th>
                    <th>Language</th>
                    <th width='50' />
                    <th width='50px' />
                  </tr>
                </thead>
                <tbody>
                  {
                    WordTypeStore.hasShowData && WordTypeStore.showData.concepts.map(item => {
                      return (
                        <tr
                          key={item.id}
                        >
                          <td>
                            <Link
                              to={{name: 'builder.concepts.edit', params: {conceptId: item.id}}}
                            >
                              <i className='fa fa-chevron-right fa-block' />
                            </Link>
                          </td>
                          <td>
                            {item.name}
                          </td>
                          <td>
                            {item.languageId}
                          </td>
                          <td>
                            <div
                              data-tip
                              data-for={item.id}
                            >
                              <i
                                className='fa fa-copy fa-block fa-on-click'
                                onClick={() => this.onCopy(item.id)}
                              />
                              <ReactTooltip
                                place='left'
                                type='dark'
                                effect='solid'
                                id={item.id}
                                class='custom-tooltip'
                              >
                                Clone this Concept
                              </ReactTooltip>
                            </div>
                          </td>
                          <td>
                            <DeleteButton
                              onConfirm={() => this.onDeleteConcept(item.id)}
                              message='Delete this concept?'
                              className='fa-block right'
                            />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Spacy Attributes</legend>
              <div className=' create-row row'>
                <div className='col-xs-10'>
                  <input
                    placeholder='Add a tag'
                    ref='tag'
                  />
                </div>
                <div className='col-xs-2'>
                  <button
                    className='button-primary'
                    onClick={this.onCreateSpacyAttribute}
                  >
                    Create
                  </button>
                </div>
              </div>
              <table className='table-wrapper admin-table'>
                <thead>
                  <tr>
                    <th>Tag</th>
                    <th>Dep</th>
                    <th>Pos</th>
                    <th>Morph Definite</th>
                    <th>Description</th>
                    <th width='50px' />
                  </tr>
                </thead>
                <tbody>
                  {
                    WordTypeStore.hasShowData && WordTypeStore.showData.spacyAttributes.map(item => {
                      return (
                        <tr
                          key={item.id}
                        >
                          <td>
                            <InPlaceText
                              model='spacy_attributes'
                              field='tag'
                              defaultValue={item.tag}
                              id={item.id}
                            />
                          </td>
                          <td>
                            <InPlaceText
                              model='spacy_attributes'
                              field='dep'
                              defaultValue={item.dep}
                              id={item.id}
                            />
                          </td>
                          <td>
                            <InPlaceText
                              model='spacy_attributes'
                              field='pos'
                              defaultValue={item.pos}
                              id={item.id}
                            />
                          </td>
                          <td>
                            <InPlaceText
                              model='spacy_attributes'
                              field='morph_definite'
                              defaultValue={item.morphDefinite}
                              id={item.id}
                            />
                          </td>
                          <td>
                            <InPlaceText
                              model='spacy_attributes'
                              field='description'
                              defaultValue={item.description}
                              id={item.id}
                            />
                          </td>
                          <td>
                            <DeleteButton
                              onConfirm={() => this.onDeleteSpacyAttribute(item.id)}
                              message='Delete this spacy attribute?'
                              className='fa-block right'
                            />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default WordTypeEdit;
