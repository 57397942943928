import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { ConceptCategoryStore } from '@seedlang/stores';
import InPlaceSelect from 'components/form/in_place_select';
import { isPresent } from '@seedlang/utils';

@observer
class ConceptCategoryCreate extends React.Component {
  @observable selectedDeckRulebookId;

  @computed get deckRulebookId() {
    return this.selectedDeckRulebookId || this.defaultDeckRulebookId;
  }

  @computed get defaultDeckRulebookId() {
    if (isPresent(this.props.deckRulebooks)) {
      const defaultRulebook = this.props.deckRulebooks.find(item => item.default);
      if (isPresent(defaultRulebook)) {
        return defaultRulebook.id;
      }
    } return null;
  }

  onClick() {
    ConceptCategoryStore.create({data: {
      name: this.refs.name.value,
      deck_rulebook_id: this.deckRulebookId,
    }}, this.props.callback);
    this.refs.name.value = '';
  }

  render() {
    return (
      <div className='concept-category-create flex-row'>
        <input
          placeholder={this.props.placeholder || 'Add a name'}
          ref='name'
        />
        {
          this.props.deckRulebooks &&
            <InPlaceSelect
              value={this.deckRulebookId}
              options={this.props.deckRulebooks.map(item => [item.id, item.name])}
              onChange={id => this.selectedDeckRulebookId = id}
            />
        }
        <button
          className='button-primary'
          onClick={this.onClick.bind(this)}
        >
          Create
        </button>
      </div>
    );
  }
}

export default ConceptCategoryCreate;
