import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Button = styled.button`
  cursor: ${props => props.disabled ? 'default!important' : 'pointer'};
  background: ${props => props.disabled ? '#ababab' : '#1DB5D7'};
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
  width: 100%;
  &:hover {
    color: #fff;
    cursor: pointer;
    background: ${props => props.disabled ? '#ababab' : '#1190ad'};
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  }
`;

@observer
class PaymentButton extends React.Component {

  render() {
    return (
      <Button
        {...this.props}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default ErrorBoundary(PaymentButton);
