import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { indexOf, isFunction } from 'lodash';
import autobind from 'autobind-decorator';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';
import FilterComponent from 'components/backend_filter/filter_component';
import FilterButton from 'components/backend_filter/filter_button';
import FilterButtonText from 'components/backend_filter/filter_button_text';

const Wrapper = styled.div`
  select {
    font-size: 13px;
    margin: 0;
    margin: 0 0 0 4px;
  }
  .fa {
    color: ${props => props.iconColor};
  }
`;

const OnClickArea = styled.div`
  display: inline-flex;
  align-items: center;
`;

@observer
class FilterBoolean extends React.Component {
  @observable value = this.props.store.getFilterValue(this.props.name);

  @computed get name() {
    return (this.props.label || this.props.name).replace(/_/g, ' ');
  }

  @computed get normalizedValue() {
    return isPresent(this.value) ? this.value.toString() : '';
  }

  @autobind onChange() {
    this.value = this.refs.select.value;
    this.props.store.setFilter(this.props.name, this.refs.select.value, { refresh: true });
    if (isFunction(this.props.afterChange)) {
      this.props.afterChange();
    }
  }

  @autobind onClick() {
    let options;
    if (this.props.withoutAny) {
      options = ['true', 'false'];
    } else {
      options = ['', 'true', 'false'];
    }
    const currentIndex = indexOf(options, this.refs.select.value);
    const newIndex = currentIndex >= options.length - 1 ? 0 : currentIndex + 1;
    this.refs.select.value = options[newIndex];
    this.onChange();
  }

  @computed get iconColor() {
    if (this.normalizedValue === 'true') {
      return 'green';
    }
    if (this.normalizedValue === 'false' ) {
      return '#ccc';
    }
    if (this.normalizedValue !== 'true' && this.normalizedValue !== 'false') {
      return '#9e9e9e';
    }
    return null;
  }

  render() {
    return (
      <FilterComponent
        selected={this.normalizedValue === 'false' || this.normalizedValue === 'true'}
        hidden={this.props.hidden}
      >
        <Wrapper
          iconColor={this.iconColor}
        >
          <FilterButton>
            <OnClickArea
              onClick={this.onClick}
            >
              {
                this.normalizedValue === 'true' &&
                 <i className={`fa fa-${this.props.icon ? this.props.icon : 'check-circle'}`} />
              }
              {
                this.normalizedValue === 'false' &&
                 <i className={`fa fa-${this.props.icon ? this.props.icon : 'ban'}`} />
              }
              {
                this.normalizedValue !== 'true' && this.normalizedValue !== 'false' &&
                  <i className={`fa fa-${this.props.icon ? this.props.icon : 'circle'}`} />
              }
              <FilterButtonText>
                {this.name}
              </FilterButtonText>
            </OnClickArea>
            <select
              ref='select'
              onChange={this.onChange}
              value={this.normalizedValue}
            >
              { !this.props.withoutAny && <option value=''>Any</option> }
              <option value='true'>True</option>
              <option value='false'>False</option>
            </select>
          </FilterButton>
        </Wrapper>
      </FilterComponent>
    );
  }
}

export default FilterBoolean;
