import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { SentenceSubmissionStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import Modal from 'components/modal';
import SentenceCreate from 'pages/builder/sentences/sentence_create';
import Paginator from 'components/paginator';
import Filter from 'components/backend_filter/filter';
import { isPresent } from '@seedlang/utils';
import { LanguageIcon } from '@seedlang/icons';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`

`;

@observer
class SentenceSubmissionIndex extends React.Component {
  @observable showModal = false;
  @observable targetText;
  @observable sourceText;
  @observable sentenceSubmissionId;

  @autobind rejectSentenceSubmission(sentenceSubmissionId) {
    SentenceSubmissionStore.update({data: {rejected: true}, ids: {sentenceSubmissionId: sentenceSubmissionId}}, this.loadSentenceSubmissions);
  }

  @autobind loadSentenceSubmissions() {
    SentenceSubmissionStore.reloadIndexFiltered();
  }

  @autobind convertToSentence(sentenceSubmission) {
    this.sentenceSubmission = sentenceSubmission;
    this.showModal = true;
  }

  @autobind closeModal() {
    this.sentenceSubmission = null;
    this.showModal = false;
  }

  @autobind afterSentenceCreate(resp) {
    SentenceSubmissionStore.update({data: {sentenceId: resp['id']}, ids: {sentenceSubmissionId: this.sentenceSubmission.id}}, this.loadSentenceSubmissions);
    this.closeModal();
  }

  render() {
    return (
      <Wrapper>
        {
          this.showModal &&
            <Modal
              onCloseModal={() => this.closeModal()}
            >
              <SentenceCreate
                targetText={this.sentenceSubmission.targetText}
                sourceText={this.sentenceSubmission.sourceText}
                submittedBy={this.sentenceSubmission.userId}
                afterCreate={this.afterSentenceCreate}
                simple
              />
            </Modal>
        }
        <Filter
          store={SentenceSubmissionStore}
          defaultSort='-score'
          namespace='user-index'
          defaultFilters={{word_id: this.props.wordId, user_id: this.props.userId}}
          queryStrings={{include_count: true}}
          filters={
            [
              {
                type: 'text',
                name: 'target_text',
                label: 'Submission text',
                default: '',
                placeholder: 'Search in sentence',
              },
              {
                type: 'boolean',
                name: 'rejected',
                label: 'rejected',
              },
              {
                type: 'boolean',
                name: 'sentence_id',
                label: 'Converted',
              },
              {
                type: 'boolean',
                name: 'joins:word|sentences_count',
                label: 'Needs Sentences',
              },
              {
                type: 'select',
                name: 'target_language',
                options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                labelField: 'name',
                label: 'Target Language',
                default: '',
              },
            ]
          }
        />
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th width='230'>Status</th>
              <th
                width='60'
                className={cx('sortable', this.props.columnClassNames('score'))}
                onClick={this.props.onSortColumn.bind(this, 'score')}
                data-tip data-for='score'
              >
                <span>Score</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='score'
                  class='custom-tooltip'
                >
                  Takes into account length of the sentence & whether <br/>
                  it contains words that need more example sentences.<br/>
                  Max value is 7.
                </ReactTooltip>
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('created_at'))}
                onClick={this.props.onSortColumn.bind(this, 'created_at')}
                width='120'
              >
                Date
              </th>
              <th>Submission</th>
              {
                !isPresent(this.props.wordId) &&
                  <th>Word</th>
              }
              {
                !isPresent(this.props.wordId) &&
                  <th>Language</th>
              }
              {
                !isPresent(this.props.userId) &&
                  <th>User</th>
              }
            </tr>
          </thead>
          <tbody>
            {
              SentenceSubmissionStore.indexData.map(item => {
                return (
                  <tr
                    key={item.id}
                  >
                    <td>
                      <div className='text'>
                        {
                          !item.rejected && !item.sentenceId &&
                            <div>
                              <button
                                className='button-primary'
                                style={{marginRight: 5}}
                                onClick={() => this.convertToSentence(item)}
                              >
                                <i className='fa fa-check' style={{color: 'green'}} />
                                Accept
                              </button>
                              <button
                                className='button-primary'
                                onClick={() => this.rejectSentenceSubmission(item.id)}
                              >
                                <i className='fa fa-times' style={{color: 'red'}} />
                                Reject
                              </button>
                            </div>
                        }
                        {
                          item.rejected &&
                            <b>Rejected</b>
                        }
                        {
                          item.sentenceId &&
                            <Link
                              className='underline'
                              to={{name: 'builder.sentences.edit', params: {sentenceId: item.sentenceId}}}
                            >
                              Converted to Sentence
                            </Link>
                        }
                      </div>
                    </td>
                    <td>
                      {item.score}
                    </td>
                    <td>
                      <div className='text'>
                        {item.createdAt.formattedDateWithYear}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.targetText}
                      </div>
                      <div className='text'>
                        {item.sourceText}
                      </div>
                    </td>
                    {
                      !isPresent(this.props.wordId) &&
                        <td>
                          {
                            isPresent(item.word) &&
                            <Link
                              to={{ name: 'builder.words.edit', params: { wordId: item.word.id } }}
                              target='word-page'
                              className='on-click'
                            >
                              { item.word.targetText}
                            </Link>
                          }
                        </td>
                    }
                    {
                      !isPresent(this.props.wordId) &&
                        <td>
                          <LanguageIcon
                            languageId={item.targetLanguage}
                          />
                        </td>
                    }
                    {
                      !isPresent(this.props.userId) &&
                        <td>
                          {
                            isPresent(item.user) &&
                            <Link
                              to={{ name: 'builder.users.edit', params: { userId: item.user.id } }}
                              className='on-click'
                            >
                              { item.user.name}
                            </Link>
                          }
                        </td>
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={SentenceSubmissionStore}
        />
      </Wrapper>
    );
  }
}

// export default ErrorBoundary(SentenceSubmissionIndex);
export default SortableColumns(SentenceSubmissionIndex, SentenceSubmissionStore, { sortColumn: 'score', sortIsAscending: false });
