import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import { AppUI , TreeUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import Text from 'components/text';
import { flexCenter } from '@seedlang/style_mixins';
import GamesPlayedPlayer from 'components/trivia/games_played_player';
import { isPresent } from '@seedlang/utils';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`

`;

const Row = styled.div`
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  padding: 5px 0 5px 5px;
`;

const Result = styled.div`
  ${flexCenter()}
  background: ${props => props.background};
  font-size: 20px;
  width: 40px;
  color: white;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const ButtonWrapper = styled.div`

`;

const RowWrapper = styled.div`
  display: flex;
  background: #888;
  border-radius: 5px;
  margin: 0 5px 5px 5px;
`;

const Date = styled.div`
  background: #676767;
  position: relative;
  width: 100%;
  color: white;
  text-align: center;
  font-size: 11px;
  margin-bottom: 5px;
  font-style: italic;
  border-top-right-radius: 5px;
`;

const ScoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

@observer
class GamesPlayed extends React.Component {
  @observable userDecksLoaded = false;

  constructor(props) {
    super(props);
    AppUI.userDeckStore.getIndex({queryStrings: {user_id: AppUI.user.id, with_opponent: true}, limit: 5, filters: {exclude_from_score: false, finished_match: true, opponent_user_deck_id: true}}, this.afterGetUserDecks);
  }

  @autobind afterGetUserDecks(resp) {
    this.userDecksLoaded = true;
    AppUI.userDeckStore.setIndexData(resp);
  }

  resultBackground(item) {
    if (item.won) {
      return Theme.green;
    }
    return '#676767';
  }

  render() {
    return (
      <Wrapper>
        {
          this.userDecksLoaded && !AppUI.userDeckStore.hasIndexData &&
            <Text
              fontSize='14px'
              center
            >
              No Games Played Yet
            </Text>
        }
        {
          AppUI.userDeckStore.indexData.map(item => {
            if (isPresent(item.opponentUserDeck)) {
              return (
                <RowWrapper
                  key={item.id}
                >
                  <Result
                    background={this.resultBackground(item)}
                  >
                    {item.won && !item.tied && <i className='fa fa-check' />}
                    {item.lost && <i className='fa fa-times' />}
                    {(item.tied || (!item.won && !item.lost)) && <i className='fa fa-minus' />}
                  </Result>
                  <ScoreWrapper>
                    <Date>
                      {item.createdAt.formatted3DigitMonth}
                    </Date>
                    <Row>
                      <GamesPlayedPlayer
                        id={`${item.id}-1`}
                        user={item.won ? AppUI.user : item.opponentUserDeck.user}
                        points={item.won ? item.points : item.opponentUserDeck.points}
                      />
                      <GamesPlayedPlayer
                        id={`${item.id}-2`}
                        user={item.won ? item.opponentUserDeck.user : AppUI.user}
                        points={item.won ? item.opponentUserDeck.points : item.points}
                      />
                      <ButtonWrapper>
                        <Button
                          fontSize='11px'
                          padding='8px'
                          height='33px'
                          borderRadius='5px'
                          margin='0 5px 0 0'
                          lineHeight='9px'
                          color='#333'
                          disabled={TreeUI.disableStartNewTriviaGame}
                          background={TreeUI.disableStartNewTriviaGame ? null : '#FFF'}
                          onClick={() => AppUI.routeStore.routeToNamed('trivia.decks.show', {userDeckId: item.opponentUserDeck.id, deckId: item.deckId})}
                        >
                          Rematch
                        </Button>
                      </ButtonWrapper>
                    </Row>
                  </ScoreWrapper>
                </RowWrapper>
              );
            }
            return null;
          })
        }
        {
          AppUI.userDeckStore.hasIndexData && (AppUI.userDeckStore.indexData.length === 5 || AppUI.userDeckStore.page > 1) &&
            <Paginator
              margin='0 0 0 10px'
              store={AppUI.userDeckStore}
              limit='5'
              queryStrings={{with_opponent: true, user_id: AppUI.user.id}}
              filters={{finished_match: true, opponent_user_deck_id: true}}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GamesPlayed);
