// rake referrer_options:create_constants_file generates this array
// run through ChatGPT with this prompt: convert this to js, convert underscore to camelCase

const ReferrerOptions = [
  { id: '121c5fd9-3265-4b98-b9f0-566d55bdba91', languageId: 'DE', text: 'Easy German' },
  { id: '06dd0a35-fcf4-4832-a920-1e104e734030', languageId: 'DE', text: 'Friend\'s Recommendation' },
  { id: '3cc3715d-ccb8-4981-b715-1cddac5f5419', languageId: 'DE', text: 'Search' },
  { id: 'd65d0fcd-a2c1-4de9-a842-261c6f71d49b', languageId: 'DE', text: 'Seedlang Social Media' },
  { id: 'e377e8f7-916f-4436-83e3-aa369ed72b3d', languageId: 'DE', text: 'Your Daily German' },
  { id: '7b746f2b-9158-4dca-a973-babeba1aaa5c', languageId: 'DE', text: 'Your True Brit' },
  { id: '567b4d98-37bf-4f83-bd0d-de7e4793f791', languageId: 'ES', text: 'Easy Spanish' },
  { id: '238f710f-1ec2-48d5-a482-5a3ac2b56a31', languageId: 'ES', text: 'Friend\'s Recommendation' },
  { id: 'ee4f2441-732f-4fad-b463-dee4f4f80641', languageId: 'ES', text: 'Search' },
  { id: '31d2454c-9b2d-47a7-b399-9d85872c0239', languageId: 'ES', text: 'Seedlang Social Media' },
  { id: 'e1029aee-b69f-4d79-9f81-16c6181025e4', languageId: 'FR', text: 'Easy French' },
  { id: '567be476-bbc3-48f6-8a47-2243961e8b11', languageId: 'FR', text: 'Friend\'s Recommendation' },
  { id: 'e485538b-0362-4f56-a35f-8c890c96cd8b', languageId: 'FR', text: 'Search' },
  { id: '124ec94c-e4dd-4711-b7f9-8fd1d46b2905', languageId: 'FR', text: 'Seedlang Social Media' },
];

export default ReferrerOptions;