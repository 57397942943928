import React from 'react';
import { observer } from 'mobx-react';
import { ConceptCategoryStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import autobind from 'autobind-decorator';
import ConceptCategoryConceptIndex from 'pages/builder/concepts/concept_category_concept_index';

@observer
class ConceptCategoryEdit extends React.Component {

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    ConceptCategoryStore.clearShowData();
  }

  @autobind loadData() {
    ConceptCategoryStore.getShow({ ids: {conceptCategoryId: this.props.params.conceptCategoryId }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    ConceptCategoryStore.reloadShow();
  }

  render() {
    return (
      <div className='concept-category-edit'>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                defaultValue={ConceptCategoryStore.showDataField('name')}
                model='concept_categories'
                field='name'
                id={ConceptCategoryStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Slug</legend>
              <InPlaceText
                defaultValue={ConceptCategoryStore.showDataField('slug')}
                model='concept_categories'
                field='slug'
                id={ConceptCategoryStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Published</legend>
              <InPlaceCheckbox
                model='concept_categories'
                field='published'
                value={ConceptCategoryStore.showDataField('published')}
                id={ConceptCategoryStore.showDataField('id')}
                icon='check'
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Featured</legend>
              <InPlaceCheckbox
                model='concept_categories'
                field='featured'
                value={ConceptCategoryStore.showDataField('featured')}
                id={ConceptCategoryStore.showDataField('id')}
                icon='check'
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Content</legend>
              <InPlaceCheckbox
                model='concept_categories'
                field='content'
                value={ConceptCategoryStore.showDataField('content')}
                id={ConceptCategoryStore.showDataField('id')}
                icon='check'
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Collaborative</legend>
              <InPlaceCheckbox
                model='concept_categories'
                field='collaborative'
                value={ConceptCategoryStore.showDataField('collaborative')}
                id={ConceptCategoryStore.showDataField('id')}
                icon='check'
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Description</legend>
              <InPlaceText
                richText
                inputType='textarea'
                model='concept_categories'
                field='description'
                defaultValue={ConceptCategoryStore.showDataField('description')}
                id={ConceptCategoryStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Concepts</legend>
              <ConceptCategoryConceptIndex
                conceptCategoryId={this.props.params.conceptCategoryId}
                hideFilter
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default ConceptCategoryEdit;
