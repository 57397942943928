import React from 'react';

class Arrow extends React.Component {

  render() {
    return (
      <svg fill={this.props.fill} height={this.props.height} width={this.props.width} viewBox='0 0 101 50' version='1.1' xmlns='http://www.w3.org/2000/svg'>
        <g id='Group' transform='translate(-0.351790, -0.703615)'>
            <path d='M50.8517901,0.70361496 C59.1330401,0.70361496 75.9663734,17.3702816 101.35179,50.703615 L0.351790065,50.703615 C25.7372067,17.3702816 42.5705401,0.70361496 50.8517901,0.70361496 Z' id='Triangle' fill='#979797' transform='translate(50.851790, 25.703615) scale(1, -1) translate(-50.851790, -25.703615) ' />
            <path d='M50.8517901,0.70361496 C58.1974932,0.70361496 74.2738278,16.3702816 99.080794,47.703615 L2.63548147,47.703615 C27.4339841,16.3702816 43.5060869,0.70361496 50.8517901,0.70361496 Z' id='Triangle' fill={this.props.fill || '#FFF'} transform='translate(50.858138, 24.203615) scale(1, -1) translate(-50.858138, -24.203615) ' />
        </g>
      </svg>
    );
  }
}

export default Arrow;
