import React from 'react';
import { observer } from 'mobx-react';
import { GroupStore } from '@seedlang/stores';
import GroupCreate from 'pages/builder/groups/group_create';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import { formatNumber } from '@seedlang/utils';

@observer
class GroupIndex extends React.Component {

  componentDidMount() {
    GroupStore.getIndex();
  }

  @autobind onDelete(id) {
    GroupStore.destroy({ids: {groupId: id}});
  }

  render() {
    return (
      <div className='group-index'>
        <div style={{marginBottom: 10}}>
          <GroupCreate />
        </div>
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th>Name</th>
              <th>Type</th>
              <th>Count</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              GroupStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.groups.edit', params: { groupId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.groupType}
                    </td>
                    <td>
                      {formatNumber(item.userGroupsCount)}
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this group?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default GroupIndex;
