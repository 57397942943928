import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { CloseIcon } from '@seedlang/icons';
import { observable } from 'mobx';
import autobind from 'autobind-decorator';
import { DeckUI, AppUI } from '@seedlang/state';
import { keys, noop } from 'lodash';
import Text from 'components/text';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  position: absolute;
  z-index: 33;
  background: white;
  top: 80px;
  left: 10px;
  font-size: 12px;
  padding: 10px;
  text-align: left;
  margin: 0 auto;
  right: 10px;
  overflow: auto;
  width: ${props => props.width};
  border: 5px solid #CCC;
`;

const DebugItem = styled.div`
  margin: 5px 0;
`;

const OverlayContent = styled.div`
  display: ${props => props.display};
  margin-top: 20px;
  flex-direction: column;
`;

const OverlayHeader = styled.div`
  cursor: pointer;
  background: #CCC;
  padding: 5px;
  text-align: center;
  font-weight: bold;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 50px;
  right: 10px;
  svg {
    width: 24px;
    fill: #797979;
  }
`;

@observer
class DebugOverlay extends React.Component {
  @observable expandDeckInfo = AppUI.getCookieValue('expand-deck-info');

  @autobind toggleExpandDeckInfo() {
    this.expandDeckInfo = !this.expandDeckInfo;
    AppUI.setCookieValue('expand-deck-info', this.expandDeckInfo);
  }

  @autobind onCloseDebugInfo() {
    AppUI.authUserStore.update({data: {displayDeckDebugInfo: false}}, noop);
    AppUI.user.set('displayDeckDebugInfo', false);
  }

  render() {
    return (
      <Wrapper
        width={this.expandDeckInfo ? 'calc(100% - 20px)' : '120px'}
      >
        {
          this.expandDeckInfo &&
            <CloseIconWrapper>
              <CloseIcon
                onClick={this.onCloseDebugInfo}
              />
            </CloseIconWrapper>
        }
        <OverlayHeader
          onClick={this.toggleExpandDeckInfo}
        >
          Debug Info
        </OverlayHeader>
        <OverlayContent
          display={this.expandDeckInfo ? 'flex' : 'none'}
        >
          <h3>Deck Info</h3>
          <div>
            {
              keys(DeckUI.debugInfo.deckInfo).map(key => {
                return (
                  <div
                    key={key}
                  >
                    {key}: {DeckUI.debugInfo.deckInfo[key]}
                  </div>
                );
              })
            }
          </div>
          <h3>Video Events</h3>
          <div>
            {
              isPresent(DeckUI.currentCard) && DeckUI.currentCard.cardState.videoEvents.map((ary, index) => {
                return (
                  <div
                    key={index}
                  >
                    {ary[0].toISOString()}, {ary[1]}, {ary[2]},
                  </div>
                );
              })
            }
          </div>
          <h3>Animation Cards</h3>
          {
            DeckUI.debugInfo.animationCards.map(item => {
              return (
                <DebugItem
                  key={`${item.cardId}-${item.position}`}
                >
                  {
                    keys(item).map(key => {
                      return (
                        <div
                          key={key}
                        >
                          <Text
                            bold={key === 'position'}
                            fontSize='12px'
                          >
                            {key}: {item[key]}
                          </Text>
                        </div>
                      );
                    })
                  }
                </DebugItem>
              );
            })
          }
          <h3>User</h3>
          <div>
            {
              keys(DeckUI.debugInfo.userInfo).map(key => {
                return (
                  <div
                    key={key}
                  >
                    {key}: {DeckUI.debugInfo.userInfo[key]}
                  </div>
                );
              })
            }
          </div>
        </OverlayContent>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DebugOverlay);
