import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import Spinner from 'components/spinner';
import { isPresent, isBlank } from '@seedlang/utils';
import { Link } from 'react-router';

const Wrapper = styled.div`
  background: #FFF;
  border-radius: 20px;
  padding: 20px;
  margin-top: 10px;
`;

const Message = styled.div`
`;

@observer
class BlockNotifications extends React.Component {
  @observable success;
  @observable notificationToken;
  @observable showSpinner = true;

  constructor(props) {
    super(props);
    const url = new URL(window.location.href);
    this.notificationToken = url.searchParams.get('notification_token');
    if (isPresent(this.notificationToken)) {
      AppUI.userStore.blockNotifications({data: {notificationToken: this.notificationToken}}, this.afterBlockNotifications);
    } else {
      this.showSpinner = false;
    }
  }

  @autobind afterBlockNotifications(resp) {
    this.success = resp['success'];
    this.showSpinner = false;
  }

  render() {
    return (
      <Wrapper>
        {
          this.showSpinner &&
            <Spinner
              background={AppUI.site.accentColor}
            />
        }
        {
          isBlank(this.notificationToken) &&
            <Message>
              <h3>We are sorry but this URL is not correct.</h3>
              <p>You can contact us at help@seedlang.com and we will assist you in blocking all emails from being sent to you from Seedlang.</p>
            </Message>
        }
        {
          this.success === false &&
            <Message>
              <h3>We are sorry but that token has expired.</h3>
              <p>You can contact us at help@seedlang.com and we will assist you in blocking all emails from being sent to you from Seedlang.</p>
            </Message>
        }
        {
          this.success === true &&
            <Message>
              <h3>You will no longer receive our emails.</h3>
              {
                AppUI.userIsSignedIn &&
                <p>You can change this at anytime by going to <Link
                  to={{name: 'settings.user_notifications', params: {groupId: this.props.params.groupId}}}
                  className='underline'
                ><b>Settings → Notifications</b></Link> and updating which emails you would like
                  to receive.</p>
              }
              {
                !AppUI.userIsSignedIn &&
                <p>You can change this at anytime by going to <b>Settings → Notifications</b> and updating which emails you would like
                  to receive.</p>
              }
              </Message>
        }
      </Wrapper>
    );
  }
}

export default BlockNotifications;
