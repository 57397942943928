import React from 'react';
import { observer } from 'mobx-react';
import { PageStore, SiteStore, RouteStore } from '@seedlang/stores';
import { observable } from 'mobx';
import { AppUI } from '@seedlang/state';

@observer
class PageCreate extends React.Component {
  @observable siteId = '';

  componentDidMount() {
    SiteStore.getIndex();
  }

  onClick() {
    PageStore.create(
      {
        data: {
          title: this.refs.title.value,
          site_id: AppUI.site.id,
        },
      }, this.afterCreate.bind(this));
    this.refs.title.value = '';
  }

  onSelect(e) {
    this.siteId = e.target.value;
  }

  afterCreate(resp) {
    if (this.props.routeToAfterCreate) {
      RouteStore.routeToNamed(this.props.routeToAfterCreate, Object.assign({}, {pageId: resp.pageId}, this.props.routeParams));
    } else if (resp.pageId && this.props.routeToName) {
      RouteStore.routeToNamed(this.props.routeToName, Object.assign({pageId: resp.pageId}, this.props.routeIds));
    } else if (resp.pageId) {
      RouteStore.routeTo(`/builder/pages/${resp.pageId}/edit`);
    } else {
      this.error = true;
    }
  }

  render() {
    return (
      <div className='page-create'>
        {
          this.error &&
          <div className='row'>
            <div className='col-xs-12'>
              <div className='alert'>Sorry, there was an error</div>
            </div>
          </div>
        }
        <input
          placeholder='Add a page title'
          ref='title'
        />
        <div>
          <button
            style={{marginTop: '10px'}}
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default PageCreate;
