import React from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { GroupStore, UserGroupStore} from '@seedlang/stores';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import { Link } from 'react-router';
import UserSearch from 'components/user_search';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI } from '@seedlang/state';
import { Permissions } from '@seedlang/models';
import InfoTooltip from 'components/info_tooltip';

const Wrapper = styled.div`

`;

@observer
class AdminsIndex extends React.Component {

  constructor(props) {
    super(props);
    this.loadGroup();

    this.disposer = autorun(() => {
      if (AppUI.userIsLoaded && !AppUI.user.guest && !AppUI.permissions.canAccessAdmins) {
        AppUI.routeStore.routeToNamed('creator.groups.worksheets.index', {groupId: this.props.params.groupId});
      }
    });
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  @autobind loadGroup() {
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}});
  }

  @autobind onAddUser(user) {
    UserGroupStore.addAdmin({data: {userId: user.id, groupId: this.props.params.groupId}}, this.afterChange);
  }

  @autobind afterChange() {
    GroupStore.reloadShow();
  }

  @autobind onDelete(id) {
    UserGroupStore.update({data: {admin: false}, ids: {userGroupId: id}}, this.afterChange);
  }

  render() {
    return (
      <Wrapper>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Admins</legend>
              <UserSearch
                onSubmit={this.onAddUser}
                placeholder='Search by Email'
                margin='0 0 10px 0'
              />
              <table className='table-wrapper admin-table'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Role <InfoTooltip><div>This only serves as a way of displaying the role</div><div>of the team member, and currently has no role</div><div>in assigning user rights.</div></InfoTooltip></th>
                    <th>Admin Access <InfoTooltip><div>This shows the sections of the admin backend</div><div>that the admin has access to.</div></InfoTooltip></th>
                    <th>Has User Access<InfoTooltip>
                      <div>None -&gt; user management section is hidden.</div>
                      <div>Basic -&gt; Can see 'Users' and 'Subscriptions' pages, but can't see 'Admins' page.</div>
                      <div>Complete -&gt; Can see all user and admin pages.</div>
                    </InfoTooltip></th>
                    <th>Records Access <InfoTooltip>
                      <div>"Can update" means they can update any record.</div>
                      <div>"Can delete" means they can delete any record.</div>
                      <div>A "record" is any entry in our database such as</div>
                      <div>a post or subscription.</div>
                    </InfoTooltip></th>
                    <th width='40'></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    GroupStore.hasShowData && GroupStore.showData.userGroups.map(item => {
                      return (
                        <tr
                          key={item.id}
                        >
                          <td>
                            <Link
                              to={{name: 'creator.groups.users.edit', params: {groupId: this.props.params.groupId, userId: item.user.id}}}
                              className='underline'
                            >
                              {item.user?.name}
                            </Link>
                          </td>
                          <td>
                            {item.user?.role}
                          </td>
                          <td>
                            <div>
                            <div>
                              <InPlaceCheckbox
                                model='user_groups'
                                field='can_access_admin_posts'
                                value={item.canAccessAdminPosts}
                                id={item.id}
                                icon='check'
                              >
                                Posts
                              </InPlaceCheckbox>
                            </div>
                              <InPlaceCheckbox
                                model='user_groups'
                                field='can_access_admin_users'
                                value={item.canAccessAdminUsers}
                                id={item.id}
                                icon='check'
                              >
                                User Management
                              </InPlaceCheckbox>
                            </div>
                            <div>
                              <InPlaceCheckbox
                                model='user_groups'
                                field='can_access_admin_site'
                                value={item.canAccessAdminSite}
                                id={item.id}
                                icon='check'
                              >
                                Site Setup
                              </InPlaceCheckbox>
                            </div>
                          </td>
                          <td>
                            <InPlaceSelect
                              model='user_groups'
                              id={item.id}
                              field='user_data_access_level'
                              options={Permissions.userDataAccessLevelForSelect}
                              value={item.userDataAccessLevel}
                              afterChange={this.loadGroup}
                            />
                          </td>
                          <td>
                            <div>
                              <InPlaceCheckbox
                                model='user_groups'
                                field='can_update_records'
                                value={item.canUpdateRecords}
                                id={item.id}
                                icon='check'
                              >
                                Can Update
                              </InPlaceCheckbox>
                            </div>
                            <div>
                              <InPlaceCheckbox
                                model='user_groups'
                                field='can_delete_records'
                                value={item.canDeleteRecords}
                                id={item.id}
                                icon='check'
                              >
                                Can Delete
                              </InPlaceCheckbox>
                            </div>
                          </td>
                          <td>
                            <DeleteButton
                              width='50px'
                              onConfirm={() => this.onDelete(item.id)}
                              message='Remove this association?'
                            >
                              <i className='fa fa-times fa-block' />
                            </DeleteButton>
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(AdminsIndex);
