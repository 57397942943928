import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { computed, observable } from 'mobx';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { AppUI } from '@seedlang/state';
import Spinner from 'components/spinner';
import { Link } from 'react-router';
import { without } from 'lodash';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import NavigationItemPathWarning from './navigation_item_path_warning';

const Wrapper = styled.div`
  margin: 20px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

@observer
class NavigationItemEdit extends React.Component {
  @observable path = '';

  constructor(props) {
    super(props);
    this.getNavigationItem();
    if (!AppUI.membershipTypeStore.hasIndexData) {
      AppUI.membershipTypeStore.getIndex();
    }
  }

  @autobind getNavigationItem() {
    AppUI.navigationItemStore.getShow({ ids: { navigationItemId: this.props.params.navigationItemId }});
  }

  @autobind onUpdateMembershipType(membershipTypeId) {
    let ary = AppUI.navigationItemStore.showData.membershipTypeIds.split(',');
    if (ary.indexOf(membershipTypeId) === -1) {
      ary.push(membershipTypeId);
    } else {
      ary = without(ary, membershipTypeId);
    }
    const membershipTypeIds = ary.join(',');
    AppUI.navigationItemStore.showData.set('membershipTypeIds', membershipTypeIds);
    AppUI.navigationItemStore.update({ids: {navigationItemId: this.props.params.navigationItemId}, data: {membershipTypeIds: membershipTypeIds}});
  }

  @autobind onPathChange(value) {
    this.path = value;
    AppUI.navigationItemStore.update({
      ids: { navigationItemId: this.props.params.navigationItemId },
      data: { path: value },
    });
  }

  @computed get navigationItem() {
    return AppUI.navigationItemStore.showData;
  }

  componentDidMount() {
    AppUI.pageStore.getIndex({}, data => {
      AppUI.pageStore.setIndexData(data);
      this.path = this.navigationItem.path;
    });
  }

  render() {
    if (!AppUI.navigationItemStore.hasShowData) {
      return (
        <Spinner />
      );
    }

    return (
      <Wrapper>
        <div className='breadcrumbs-wrapper'>
          <div className='breadcrumbs'>
            <Link
              to={{name: 'creator.groups.site_navigation', params: {groupId: this.props.params.groupId}}}
            >
              Navigation Items
            </Link>
            <i className='fa fa-angle-double-right' />
            <div className='current'>
              {this.navigationItem.position} - {this.navigationItem.name}
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                id={this.navigationItem.id}
                defaultValue={this.navigationItem.name}
                model='navigation_items'
                field='name'
              />
            </fieldset>
          </div>
            <div className='col-xs-8'>
              <fieldset>
                <legend>Path</legend>
                <InPlaceText
                  id={this.navigationItem.id}
                  defaultValue={this.navigationItem.path}
                  value={this.path}
                  model='navigation_items'
                  field='path'
                  onChange={this.onPathChange}
                  readOnly={!!this.navigationItem.routeName}
                  hideEditable={!!this.navigationItem.routeName}
                />
                {
                  !this.navigationItem.routeName &&
                    <NavigationItemPathWarning
                      groupId={this.props.params.groupId}
                      path={this.path}
                    />
                }
                {
                  this.navigationItem?.externalLink &&
                    <InPlaceCheckbox
                      id={this.navigationItem.id}
                      value={this.navigationItem.targetBlank}
                      model='navigation_items'
                      field='target_blank'
                      icon='check'
                    >
                      Open External Link in New Tab
                    </InPlaceCheckbox>
                }
              </fieldset>
            </div>
        </div>
        <div className='row'>
        <div className='col-xs-4'>
            <fieldset>
              <legend>Show in...</legend>
              <Column>
                <InPlaceCheckbox
                  id={this.navigationItem.id}
                  value={this.navigationItem.headerLink}
                  model='navigation_items'
                  field='header_link'
                  icon='check'
                >
                  Header
                </InPlaceCheckbox>
                <InPlaceCheckbox
                  id={this.navigationItem.id}
                  value={this.navigationItem.footerLink}
                  model='navigation_items'
                  field='footer_link'
                  icon='check'
                >
                  Footer
                </InPlaceCheckbox>
                <InPlaceCheckbox
                  id={this.navigationItem.id}
                  value={this.navigationItem.settingsLink}
                  model='navigation_items'
                  field='settings_link'
                  icon='check'
                >
                  User Settings
                </InPlaceCheckbox>
              </Column>
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Show To</legend>
              <Column>
                <InPlaceCheckbox
                  id={this.navigationItem.id}
                  value={this.navigationItem.showWhenSignedIn}
                  model='navigation_items'
                  field='show_when_signed_in'
                  icon='check'
                  afterChange={this.getNavigationItem}
                >
                  Signed In Users
                </InPlaceCheckbox>
                <InPlaceCheckbox
                  id={this.navigationItem.id}
                  value={this.navigationItem.showWhenSignedOut}
                  model='navigation_items'
                  field='show_when_signed_out'
                  icon='check'
                >
                  Signed Out Users
                </InPlaceCheckbox>
              </Column>
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Membership Types when Signed In</legend>
              <Column>
                {
                  AppUI.membershipTypeStore.indexData.map(item => {
                    return (
                      <div className='row' key={item.id}>
                        <InPlaceCheckbox
                          value={AppUI.navigationItemStore.showData.membershipTypeIds.indexOf(item.id) !== -1}
                          icon='check'
                          onChange={() => this.onUpdateMembershipType(item.id)}
                          disabled={!this.navigationItem.showWhenSignedIn}
                        >
                          {item.name}
                        </InPlaceCheckbox>
                      </div>
                    );
                  })
                }
              </Column>
            </fieldset>
          </div>
        </div>
        {
          !this.navigationItem.showWhenSignedIn &&
            <BackEndExplainer>Membership Types can only be selected if the navigation item is shown when signed in.</BackEndExplainer>
        }
        {
          this.navigationItem.path.startsWith('/pages') &&
            <BackEndExplainer>
              <div>Filtering of navigation items is independent from the page set up. A page can be visible in the navigation, but its content can be blocked for a given user, and vice versa.</div>
              <div>You can set up pages' visibility in the <Link className='underline' to={{name: 'creator.groups.site_pages', params: {groupId: this.props.params.groupId}}}>Pages</Link> section.</div>
            </BackEndExplainer>
        }
      </Wrapper>
    );
  }
}

export default NavigationItemEdit;