import { AppUI , LoadingState } from '@seedlang/state';
import { PaymentMigrationConfigStore, PaymentMigrationStore } from '@seedlang/stores';
import { stripHtmlTags , isPresent, isBlank , dollar, cents } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import ContactUsTextWithLink from 'components/contact_us_text_with_link';
import Spinner from 'components/spinner';
import Text from 'components/text';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import OutlineButton from 'components/button/outline_button';
import { Theme } from '@seedlang/constants';
import styled from '@emotion/styled';
import cx from 'classnames';

const Wrapper = styled.div`
 margin-top: 20px;
 text-align: left;
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: ${props => props.justifyContent};
`;

const InstructionList = styled.ol`
  li {
    margin-bottom: 5px;
  }
`;

const CancelButton = ({children, onClick, ...rest}) => (
  <OutlineButton
    selectedBackground={Theme.red}
    onClick={onClick}
    {...rest}
  >
    {children ?? 'Cancel'}
  </OutlineButton>
);

const StyledSpinner = props => (
  <Spinner
    background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
    {...props}
    margin={props.margin ?? `${props.marginTop ?? '0'} ${props.centered ? 'auto' : '0'} ${props.marginBottom ?? '0'} ${props.centered ? 'auto' : '0'}`}
  />
);

const PatreonMembershipLink = ({campaignId, children, ...rest}) => (
  <a href={`https://www.patreon.com/settings/memberships/${campaignId}`} target='_blank' rel='noopener noreferrer' className='underline'>{children}</a>
);

@observer
class SettingsBillingMigrationSection extends React.Component {
  refreshInterval = 1000;
  refreshTimer = null;
  loadingState = new LoadingState();
  paymentSetupLoadingState = new LoadingState();
  paymentStatusLoadingState = new LoadingState();
  cancelledLoadingState = new LoadingState();

  @observable usePaymentMigrationStore = false;

  constructor(props) {
    super(props);
    this.periodicallyRefreshIfNeeded(this.paymentStatusLoadingState);
    PaymentMigrationConfigStore.getShow();
    this.loadPaymentMigration();
  }

  @autobind async loadPaymentMigration(params, callback = null) {
    const loading = params?.loadingState ?? this.loadingState;
    if (isBlank(this.paymentMigration.id) || params?.forceGetShow) {
      loading.started();
      try {
        // PaymentMigrationStore.getShow is called in order to *create* the migration
        await PaymentMigrationStore.getShowPromise();

        // AppUI.loadUser is called to load the migration into `AppUI.user.paymentMigration`
        await AppUI.loadUserPromise();

        loading.succeeded();
        if (callback) callback();
      } catch (e) {
        console.error(e);
        loading.failed(e.message ?? 'Something went wrong. Please try again later.');
      }
    }
  }

  get paymentMigration() {
    return PaymentMigrationStore.showData;
  }

  periodicallyRefreshIfNeeded(loadingState) {
    if (this.paymentMigration.status === 'stripe_payment_setup_started') {
      loadingState.started();

      this.refreshTimer = setTimeout(async () => {
        try {
          await PaymentMigrationStore.getShowPromise();
          this.usePaymentMigrationStore = true;
          this.refreshInterval *= 1.5;
          this.periodicallyRefreshIfNeeded(loadingState);
        } catch (e) {
          console.error('Error loading payment migration', e);
          loadingState.failed(e.message ?? 'Something went wrong. Please try again later.');
        }
      }, this.refreshInterval);
    }
    else {
      loadingState.succeeded();
    }
  }

  @autobind startPaymentSetup() {
    this.clearLoadingStates();
    const loadingState = this.paymentSetupLoadingState;
    loadingState.started();

    PaymentMigrationStore.createPaymentSetupSession(resp => {
      if (!resp.url) {
        console.warn('createPaymentSetupSession resp.url is missing', resp);
      }
      window.location.href = resp.url;
      // Note: no loadingState.succeeded() here because we don't want the button to appear for a moment, while the browser is loading the Stripe page
    }, {
      data: {onboarding: true, membershipId: this.membership.id, currency: this.currency},
      failureCallback: resp => {
        loadingState.failed(resp.message ?? 'Something went wrong. Please try again later.');
      },
    });
  }

  @autobind cancelMigration() {
    this.clearLoadingStates();
    this.loadingState.started();
    if (this.refreshTimer) clearTimeout(this.refreshTimer);
    PaymentMigrationStore.destroy({ids: {paymentMigrationId: this.paymentMigration.id}}, this.afterDestroy);
  }

  @autobind afterDestroy(resp) {
    this.loadPaymentMigration({forceGetShow: true});
  }

  @autobind async handleIHaveCancelledClick() {
    const loadingState = this.cancelledLoadingState;

    this.clearLoadingStates();
    await this.loadPaymentMigration({loadingState, forceGetShow: true});

    if (loadingState.isSuccess && this.paymentMigration.status === 'waiting_for_patreon_cancellation') {
      loadingState.failed('We are waiting for a confirmation from Patreon that your membership has been cancelled. Please try again in a few minutes and contact us if the problem persists.');
    }
  }

  get afterPaymentSetupHtml() {
    return PaymentMigrationConfigStore.showData.afterPaymentSetupMonthlyHtml;
  }

  @computed get isYearly() {
    return this.paymentMigration.patreonPledgeCadence === 12;
  }

  @computed get currency() {
    if (!this.paymentMigration) {
      console.warn('paymentMigration is missing in SettingsBillingMigrationSection');
    }
    if (typeof this.paymentMigration?.currency?.toUpperCase() === 'undefined' || this.paymentMigration?.currency?.toUpperCase() === null) {
      console.warn('paymentMigration.currency is blank in SettingsBillingMigrationSection');
    }
    console.log('this.paymentMigration?.currency', this.paymentMigration?.currency, AppUI.user?.currency);
    return this.paymentMigration?.currency ?? AppUI.user?.currency ?? 'USD';
  }

  @computed get currencySymbol() {
    return this.currency?.toUpperCase() === 'USD' ? '$' : '€';
  }

  @computed get membership() {
    return this.paymentMigration.membership;
  }

  @computed get cost() {
    return this.membership.cost(this.currency);
  }

  @computed get costMonthly() {
    return this.membership.costMonthly(this.currency);
  }

  @computed get patreonCampaignId() {
    return AppUI.user.site?.patreonCampaignId;
  }

  clearLoadingStates() {
    this.loadingState.clear();
    this.paymentSetupLoadingState.clear();
    this.paymentStatusLoadingState.clear();
    this.cancelledLoadingState.clear();
  }

  render() {
    return (
      <Wrapper>
        {
          !this.props.onboarding && isPresent(this.paymentMigration.status) && !this.paymentMigration.isComplete &&
            <Text heading='4' margin='20px 0 10px 0'>Migration from Patreon</Text>
        }
        {
          this.loadingState.isLoading && (
            <StyledSpinner centered={this.props.onboarding} />
          )
        }
        {
          this.loadingState.errorMessage && (
            <Text color='red' margin='0 0 10px 0' style={{textAlign: this.props.onboarding ? 'center' : 'left'}}>{this.loadingState.errorMessage}</Text>
          )
        }
        {
          this.paymentMigration.status === 'setting_up' && (
            AppUI.user.patreonUser?.isPatreonActive ? (
              <>
                <Text margin='0 0 20px 0' fontSize='18px'>By switching your payment from Patreon to our site, you'll unlock our platform's new interactive features.</Text>

                {this.membership ? (
                  <>
                    <Text fontSize='16px'>
                      <strong>New membership price:{' '}</strong>
                      <span>
                        {stripHtmlTags(this.membership.title)}
                        {' – '}
                        {this.currencySymbol}{dollar(this.costMonthly)}.{cents(this.costMonthly)} / month (incl. tax)
                        {' – '}
                        {this.currency?.toUpperCase() === 'USD' ? this.membership.descriptionUsd : this.membership.descriptionEur}
                      </span>
                      {' '}
                      <Link
                        to={{ name: 'memberships.change', query: { mode: 'payment_migration', membershipId: this.paymentMigration.membership.id } }}
                        style={{ color: AppUI.siteIsDefault && isPresent(AppUI.site.accentColor) ? '#0584a1' : AppUI.site.accentColor, marginLeft: '0.5em' }}
                        className='underline'
                      >
                        Change Tier?
                      </Link>
                    </Text>
                    <ButtonRow justifyContent={this.props.onboarding ? 'center' : 'left'}>
                      {
                        this.paymentSetupLoadingState.isLoading ? (
                          <StyledSpinner marginTop='20px' marginBottom='20px' centered={this.props.onboarding} />
                        ) : (
                          <Button
                            background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
                            margin='10px 0'
                            onClick={this.startPaymentSetup}
                          >
                            Add Your Payment Details
                          </Button>
                        )
                      }
                    </ButtonRow>
                    <Text italic fontSize='14px' textAlign={this.props.onboarding ? 'center' : 'left'}>You will not be charged during the migration process.</Text>
                  </>
                ) : (
                  <ButtonRow justifyContent={this.props.onboarding ? 'center' : 'start'}>
                    <Link
                      to={{ name: 'memberships.change', query: { mode: 'payment_migration' }}}
                    >
                      <Button
                        background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? null : AppUI.site.accentColor}
                        className='underline'
                      >
                        Select Membership Tier
                      </Button>
                    </Link>
                  </ButtonRow>
                )}

                {
                  this.paymentSetupLoadingState.errorMessage && (
                    <Text color='red' margin='0 0 10px 0'>{this.paymentSetupLoadingState.errorMessage}</Text>
                  )
                }
                {
                  !this.props.hideContactLink &&
                    <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
                }
              </>
            ) : !AppUI.user.patreonUser && !this.props.hasActiveSubscription ? (
              <Text margin='20px 0 20px 0'>
                If you have a subscription on Patreon, you can{' '}
                <Link to={{ name: 'settings.patreon' }} className='underline' style={{ color: 'inherit'}}>
                  link your Patreon account
                </Link>{' '}
                and migrate your subscription to our membership platform.
              </Text>
            ) : null
          )
        }

        {
          this.paymentMigration.status === 'stripe_payment_setup_started' && (
            <div style={{textAlign: this.props.onboarding ? 'center' : 'left'}}>
              {
                this.paymentStatusLoadingState.errorMessage ? (
                  <Text color='red' margin='0 0 0 0'>{this.paymentStatusLoadingState.errorMessage}</Text>
                ) : this.paymentStatusLoadingState.isLoading ? (
                  <>
                    <Text margin='0 0 0 0'>Waiting for confirmation from the payment provider. This should take a few seconds...</Text>
                    <StyledSpinner marginTop='10px' marginBottom='10px' centered={this.props.onboarding} />
                    <CancelButton onClick={this.cancelMigration} margin='10px 0 0 0'>Cancel</CancelButton>
                  </>
                ) : null
              }
              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </div>
          )
        }

        {
          this.paymentMigration.status === 'waiting_for_patreon_cancellation' && (
            <>
              <div style={{textAlign: this.props.onboarding ? 'center' : 'left', fontSize: this.props.onboarding ? '20px' : '18px', fontWeight: 800}}>Payment Details Added Successfully</div>
              <p style={{textAlign: this.props.onboarding ? 'center' : 'left', fontStyle: 'italic', margin: this.props.onboarding ? '30px 0 15px 0' : null}}>Now, let's finalize your switch from Patreon to unlock all features:</p>
              <InstructionList>
                <li>Click the button to open your Patreon Membership settings</li>
                <li>Choose "Cancel Membership" and confirm cancellation on Patreon</li>
                <li>Afterwards, please return to this page</li>
              </InstructionList>
              {
                this.patreonCampaignId && (
                  <div>
                    <ButtonRow justifyContent={this.props.onboarding ? 'center' : 'start'}>
                      <PatreonMembershipLink campaignId={this.patreonCampaignId}>
                        <Button
                          margin='10px 0'
                          background={AppUI.site.accentColor}
                          fontWeight={800}
                          fontSize='16px'
                        >
                          Go to Patreon to Cancel Membership
                        </Button>
                      </PatreonMembershipLink>
                    </ButtonRow>
                  </div>
                )
              }
              {
                !this.cancelledLoadingState.isLoading &&
                <div>
                  <Text margin='20px 0 10px 0' style={{ textAlign: this.props.onboarding ? 'center' : 'left', fontSize: 18, fontWeight: 800 }}>Please confirm: Did you cancel on Patreon?</Text>
                  <div onClick={() => this.handleIHaveCancelledClick()} style={{marginTop: 10, cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 4, justifyContent: 'center'}}>
                    <i
                      className={cx('fa', 'fa-check', 'fa-on-click', 'disabled' )}
                    />
                    <span>I've Cancelled My Patreon Membership</span>
                  </div>
                </div>
              }
              {
                this.cancelledLoadingState.isLoading && (
                  <StyledSpinner marginTop='20px' marginBottom='10px' centered={this.props.onboarding} />
                )
              }
              {
                this.cancelledLoadingState.errorMessage && (
                  <Text color='red' margin='10px 0 10px 0'>{this.cancelledLoadingState.errorMessage}</Text>
                )
              }
              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </>
          )
        }

        {
          this.paymentMigration.status === 'waiting_for_patreon_expiry' && (
            <>
              { this.props.onboarding && <h3 style={{textAlign: this.props.onboarding ? 'center' : 'left'}}>Thank You for Migrating Your Payment Method</h3> }
              {
                !this.isYearly &&
                  <p>We will start charging you <strong>{this.currencySymbol}{dollar(this.costMonthly)}.{cents(this.costMonthly)} / month</strong> once your subscription on Patreon has expired.</p>
              }
              {
                this.isYearly &&
                  <p>We will start charging you <strong>{this.currencySymbol}{dollar(this.cost)}.{cents(this.cost)} / year</strong> once your subscription on Patreon has expired.</p>
              }
              <p>In the meantime, please enjoy all the benefits and perks connected to your membership.</p>
              {
                !this.props.onboarding &&
                  <CancelButton onClick={this.cancelMigration}>Cancel Migration</CancelButton>
              }

              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </>
          )
        }

        {
          (this.paymentMigration.status === 'waiting_for_patreon_refund') && (
            <>
              <p>Setup complete.</p>
              <div
                dangerouslySetInnerHTML={{ __html: PaymentMigrationConfigStore.showData.waitingForPatreonRefundHtml}}
              />
              {
                !this.props.onboarding &&
                  <CancelButton onClick={this.cancelMigration}>Cancel Migration</CancelButton>
              }
              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </>
          )
        }

        {
          (this.paymentMigration.status === 'waiting_for_patreon_refund_timeout') && (
            <>
              <p>Setup complete.</p>
              <div
                dangerouslySetInnerHTML={{ __html: PaymentMigrationConfigStore.showData.waitingForPatreonRefundTimeoutHtml}}
              />
              {
                !this.props.onboarding &&
                  <CancelButton onClick={this.cancelMigration}>Cancel Migration</CancelButton>
              }
              {
                !this.props.hideContactLink &&
                <ContactUsTextWithLink marginTop={this.props.onboarding ? '5px' : '20px'} textAlign={this.props.onboarding ? 'center' : null}/>
              }
            </>
          )
        }
      </Wrapper>
    );
  }
}

export default SettingsBillingMigrationSection;
