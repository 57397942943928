import React from 'react';
import { observer } from 'mobx-react';
import { ResultsCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import CardButtons from 'components/card/card_buttons';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { pixify } from '@seedlang/utils';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import LanguageButton from 'components/button/language_button';
import ResultsCardCenter from 'components/card/results_card_center';
import NextButton from 'components/button/next_button';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';

const ContentInner = styled.div`
  ${flexCenterColumn()}
  position: relative;
  z-index: ${Theme.z['card-2']};
  width: 100%;
  height: ${props => props.height};
  background: ${Theme.darkestBlue};
  border-radius: 10px;
`;

const BottomSection = styled.div`
  z-index: 0;
  position: absolute;
  background: #f1f1f1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: ${props => props.height};
  bottom: 0;
  width: 100%;
`;

const Encouragement = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
  @media (max-height: 500px) {
    display: none;
  }
`;

const LanguageButtonWrapper = styled.div`
  top: auto;
  right: auto;
  position: relative;
  margin: -2px 5px 0 0;
`;

const TextWrapper = styled.div`
  z-index: 1;
`;

@observer
class ResultsCard extends React.Component {

  render() {
    return (
      <CardWrapper>
        <CardContent>
          <ContentInner
            height={pixify(DeckUI.layout.contentHeight)}
          >
            <BottomSection
              height={pixify((DeckUI.layout.contentHeight) / 2)}
            />
            <Encouragement>
              <LanguageButtonWrapper>
                <LanguageButton
                  width='25px'
                  background='#254F6A'
                  arrowColor='#FFF'
                  languageId={this.props.messageType === 'target' ? this.props.languageId : 'EN'}
                  onClick={this.props.onToggleMessageType}
                  useUkFlagForEn={DeckUI.user.useBritishEnglish}
                />
              </LanguageButtonWrapper>
              { this.props.message && this.props.message[this.props.messageType] }
            </Encouragement>
            <ResultsCardCenter
              inView={this.props.inView}
              correctCount={this.props.correctCount}
              numCards={this.props.numCards}
              percentageCorrect={this.props.percentageCorrect}
            />
            <TextWrapper>
              <Text
                bold
                margin='20px 0 0 0'
              >
                You got {this.props.correctCount} / {this.props.numCards} correct on the first try!
              </Text>
            </TextWrapper>
          </ContentInner>
        </CardContent>
        <CardButtons>
         <NextButton
            disabled={!this.props.inView}
            onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
            submitOnEnter
            shortcut='1'
         />
        </CardButtons>
      </CardWrapper>
    );
  }
}

export default ResultsCardWrapper(ResultsCard);
