import React from 'react';
import { observer } from 'mobx-react';
import { BannerNoticeStore } from '@seedlang/stores';
import { AppUI } from '@seedlang/state';

@observer
class BannerNoticeCreate extends React.Component {

  onClick() {
    BannerNoticeStore.create(
      {
        data: {
          title: this.refs.title.value,
          siteId: AppUI.site.id,
        },
      },
    );
    this.refs.title.value = '';
  }

  render() {
    return (
      <div className='concept-category-create row' style={{marginBottom: 10}}>
        <div className='col-xs-10'>
          <input
            placeholder='Add a title'
            ref='title'
          />
        </div>
        <div className='col-xs-2'>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default BannerNoticeCreate;
