import React from 'react';
import { observer } from 'mobx-react';
import { TreeNodeGrammarWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import TextDisplay from 'components/text_display';
import Spinner from 'components/spinner';
import Text from 'components/text';

const Wrapper = styled.div`
  font-size: 14px;
  .grammar-section:not(:last-child) {
    margin-bottom: 20px;
  }
`;

@observer
class TreeNodeGrammar extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.showSpinner &&
            <Spinner />
        }
        {
          !this.props.showSpinner && !this.props.treeNode.grammarDescriptionOverride && this.props.treeNode.treeNodeGrammarConcepts.map(grammarConcept => {
            if (grammarConcept.concept) {
              return (
                <div
                  className='grammar-section'
                  key={grammarConcept.id}
                >
                  <Text heading='3'>{grammarConcept.concept.displayNameLine1WithCase}</Text>
                  {
                    grammarConcept.concept.displayNameLine2 &&
                    <Text italic fontSize='14px'>{grammarConcept.concept.displayNameLine2}</Text>
                  }
                  {
                    grammarConcept.concept.longDescription &&
                    <TextDisplay
                      htmlText={grammarConcept.concept.longDescription}
                    />
                  }
                </div>
              );
            } else {
              return null;
            }
          })
        }
        {
          !this.props.showSpinner && (this.props.treeNode.grammarDescriptionOverride || this.props.treeNode.treeNodeGrammarConcepts.length === 0) &&
            <TextDisplay
              htmlText={this.props.treeNode.grammarDescription}
            />
        }
      </Wrapper>
    );
  }
}

export default TreeNodeGrammarWrapper(TreeNodeGrammar);
