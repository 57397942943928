import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { without } from 'lodash';
import Text from 'components/text';
import RadioButton from 'components/radio_button';
import { Link } from 'react-router';
import Paginator from 'components/paginator';
import InfoTooltip from 'components/info_tooltip';

const Wrapper = styled.div`

`;

@observer
class DownloadLinkTypeEdit extends React.Component {

  constructor(props) {
    super(props);
    this.getDownloadLinkType();
    AppUI.membershipTypeStore.getIndex();
    AppUI.podcastStore.getIndex();
  }

  @autobind getDownloadLinkType() {
    AppUI.downloadLinkTypeStore.getShow({ids: {downloadLinkTypeId: this.props.params.downloadLinkTypeId}});
    AppUI.downloadLinkStore.getIndex({filters: {download_link_type_id: this.props.params.downloadLinkTypeId}});
  }

  @autobind onUpdateMembershipType(membershipTypeId) {
    let ary = AppUI.downloadLinkTypeStore.showData.membershipTypeIds.split(',');
    if (ary.indexOf(membershipTypeId) === -1) {
      ary.push(membershipTypeId);
    } else {
      ary = without(ary, membershipTypeId);
    }
    const membershipTypeIds = ary.join(',');
    AppUI.downloadLinkTypeStore.showData.set('membershipTypeIds', membershipTypeIds);
    AppUI.downloadLinkTypeStore.update({ids: {downloadLinkTypeId: this.props.params.downloadLinkTypeId}, data: {membershipTypeIds: membershipTypeIds}});
  }

  @autobind onUpdatePodcast(id) {
    AppUI.downloadLinkTypeStore.update({ids: {downloadLinkTypeId: this.props.params.downloadLinkTypeId}, data: {podcastId: id}});
  }

  render() {
    return (
      <Wrapper>
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Title</legend>
              <InPlaceText
                defaultValue={AppUI.downloadLinkTypeStore.showData?.title}
                model='download_link_types'
                field='title'
                id={AppUI.downloadLinkTypeStore.showData?.id}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Subtitle</legend>
              <InPlaceText
                defaultValue={AppUI.downloadLinkTypeStore.showData?.subtitle}
                model='download_link_types'
                field='subtitle'
                id={AppUI.downloadLinkTypeStore.showData?.id}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Icon</legend>
              <div style={{display: 'flex', gap: '10px', alignItems: 'center'}}>
                <i className={`fa fa-${AppUI.downloadLinkTypeStore.showData?.icon}`} />
                <InPlaceSelect
                  options={[
                    ['file-pdf-o', 'pdf'],
                    ['file-text-o', 'text'],
                    ['file-code-o', 'html'],
                    ['volume-up', 'sound'],
                    ['film', 'video'],
                    ['link', 'link'],
                    ['download', 'download'],
                    ['image', 'image'],
                    ['archive', 'archive'],
                  ]}
                  model='download_link_types'
                  field='icon'
                  includeBlank
                  id={AppUI.downloadLinkTypeStore.showData?.id}
                  value={AppUI.downloadLinkTypeStore.showData?.icon}
                  placeholder='Choose an Icon'
                  afterChange={this.getDownloadLinkType}
                  margin='0 5px 0 0'
                  height='25px'
                />
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-8'>
            <fieldset>
              <legend>Membership Types</legend>
              {
                AppUI.downloadLinkTypeStore.hasShowData && AppUI.membershipTypeStore.indexData.map(item => {
                  return (
                    <div className='row' key={item.id}>
                      <InPlaceCheckbox
                        value={AppUI.downloadLinkTypeStore.showData.membershipTypeIds.indexOf(item.id) !== -1}
                        icon='check'
                        onChange={() => this.onUpdateMembershipType(item.id)}
                      >
                        {item.name}
                      </InPlaceCheckbox>
                    </div>
                  );
                })
              }
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset style={{height: '100%'}}>
              <legend>Generated PDFs</legend>
              <InPlaceCheckbox
                value={AppUI.downloadLinkTypeStore.showData.generatedPdfs}
                disabled={AppUI.downloadLinkTypeStore.showData.generatedPdfs}
                id={AppUI.downloadLinkTypeStore.showData?.id}
                icon='check'
                model='download_link_types'
                field='generated_pdfs'
              >
                Automatically add PDF worksheets <InfoTooltip position='left'>If enabled, when you generate the PDF version of a post, it will be added as a download link of this type.</InfoTooltip>
              </InPlaceCheckbox>
              {
                AppUI.downloadLinkTypeStore.showData.generatedPdfs &&
                <Text fontSize='14px'>You cannot disable this, but you can select another download link type to replace this one.</Text>
              }
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Comment</legend>
              <Text fontSize='14px'>This comment is only visible for admins in the back-end. You can use it to add instructions about what this download link type is for.</Text>
              <InPlaceText
                textarea
                show
                defaultValue={AppUI.downloadLinkTypeStore.showData?.comment}
                model='download_link_types'
                field='comment'
                id={AppUI.downloadLinkTypeStore.showData?.id}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Podcast</legend>
              <Text fontSize='14px'>You will select episodes from this podcast as download links. This is useful if you have an 'Audio Only' podcast with the audio files of your videos.</Text>
              {
                AppUI.downloadLinkTypeStore.hasShowData && AppUI.podcastStore.indexData.map(item => {
                  return (
                    <div className='row' key={item.id}>
                      <RadioButton
                        margin='0 10px 0 0'
                        value={AppUI.downloadLinkTypeStore.showData.podcast?.id === item.id}
                        onClick={() => this.onUpdatePodcast(item.id)}
                      />
                      <Text fontSize='14px'>{item.title}</Text>
                    </div>
                  );
                })
              }
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Worksheets</legend>
              <Text fontSize='14px'>The following worksheets have a download link of this type:</Text>
              <table className='table-wrapper admin-table'>
                <thead>
                <tr>
                  <th width='20' />
                  <th width='300'>Title</th>
                  <th>Url</th>
                </tr>
                </thead>
                <tbody>
                  {
                    AppUI.downloadLinkStore.hasIndexData && AppUI.downloadLinkStore.indexData.map(downloadLink => {
                      return (
                        <tr key={downloadLink.id}>
                          <td>
                            <Link
                              to={{ name: 'creator.groups.worksheets.edit', params: { worksheetId: downloadLink.worksheetId, groupId: this.props.params.groupId } }}
                              className='on-click'
                            >
                              <i className='fa fa-chevron-right fa-block' />
                            </Link>
                          </td>
                          <td>{downloadLink.worksheetName}</td>
                          <td><a href={downloadLink.text} target='_blank'>{downloadLink.text}</a></td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
              <Paginator
                store={AppUI.downloadLinkStore}
                filters={{download_link_type_id: this.props.params.downloadLinkTypeId}}
              />
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default DownloadLinkTypeEdit;
