import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import PieChart from 'components/visualization/pie_chart';
import { Theme } from '@seedlang/constants';
import TriviaRecordScore from 'components/trivia/trivia_record_score';

@observer
class TriviaRecord extends React.Component {

  render() {
    return (
      <PieChart
        hideSlicesLabels
        hideTooltip
        width={160}
        colors={[Theme.green, '#676767']}
        data={[
          {
            y: this.props.won,
          },
          {
            y: this.props.lost,
          },
        ]}
        label={
          <TriviaRecordScore
            won={this.props.won}
            lost={this.props.lost}
          />
        }
      />
    );
  }
}

export default ErrorBoundary(TriviaRecord);
