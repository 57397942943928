import React from 'react';
import { computed, toJS } from 'mobx';
import FormInput from './form_input';
import FormList from './form_list';
import FormSelect from './form_select';
import FormFlatSelect from './form_flat_select';
import FormCheckbox from './form_checkbox';
import FormTextarea from './form_textarea';
import FormToggle from './form_toggle';
import { isPresent, isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';

class FormFactory {

  constructor(store, props) {
    if (isPresent(store)) {
      this.store = store;
    } else {
      console.warn('Your form is being instantiated with an empty store.');
    }
    this.props = props || {};
    // this.store.initializeForm();
  }

  @computed get isUpdate() {
    return this.store.hasShowData;
  }

  @computed get submissionEnabled() {
    return this.validated && this.store.dirtyForm;
  }

  @computed get validated() {
    return isBlank(toJS(this.store.requiredFields).find(field => isBlank(toJS(this.store.formCache)[field])));
  }

  @autobind onChange(field, value) {
    this.store.setFormCacheValue(field, value);
  }

  onSubmit() {
    if (this.isUpdate) {
      this.store.update({ ids: toJS(this.props.ids), data: this.store.serializedFormCache }, this.props.onAfterUpdate);
    } else {
      this.store.create({ ids: toJS(this.props.ids), data: this.store.serializedFormCache }, this.props.onAfterCreate);
    }
    if (this.props.resetFormOnSubmit) {
      this.store.resetFormCache();
    } else {
      this.store.resetPristine();
    }
  }

  onClear(e) {
    this.store.resetFormCache();
  }

  getValue(fieldName) {
    return this.store.formCache[fieldName];
  }

  registerComponent(props) {
    const value = this.store.hasShowData ? this.store.showData[props.name] : props.defaultValue;
    this.store.initializeFormCacheValue(props.name, value);
    this.store.registerFormField(props.name, props.required);
  }

  unregisterComponent(props) {
    // this.store.unregisterFormField(props.name, props.required);
  }

  input(props) {
    return (
      <FormInput
        store={this.store}
        onChange={this.onChange}
        form={this}
        {...props}
      />
    );
  }

  select(props) {
    return (
      <FormSelect
        store={this.store}
        onChange={this.onChange}
        form={this}
        {...props}
      />
    );
  }

  flatSelect(props) {
    return (
      <FormFlatSelect
        store={this.store}
        onChange={this.onChange}
        form={this}
        {...props}
      />
    );
  }

  checkbox(props) {
    return (
      <FormCheckbox
        store={this.store}
        onChange={this.onChange}
        form={this}
        {...props}
      />
    );
  }

  toggle(props) {
    return (
      <FormToggle
        store={this.store}
        onChange={this.onChange}
        form={this}
        {...props}
      />
    );
  }

  list(props) {
    return (
      <FormList
        store={this.store}
        onChange={this.onChange}
        form={this}
        {...props}
      />
    );
  }

  textarea(props) {
    return (
      <FormTextarea
        store={this.store}
        onChange={this.onChange}
        form={this}
        {...props}
      />
    );
  }
}

export default FormFactory;
