import React from 'react';
import { observer } from 'mobx-react';
import { QuestionStore, SentenceStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import SentenceSearch from 'components/sentence_search';

@observer
class QuestionAssociationEdit extends React.Component {

  @autobind onSubmit(question) {
    SentenceStore.update({ids: {sentenceId: this.props.sentence.id}, data: {question_id: question.id}}, this.props.afterChange);
  }

  render() {
    return (
      <div className='question-association-edit sentence-concept-edit'>
        <SentenceSearch
          placeholder='Search for Question'
          store={QuestionStore}
          onSubmit={this.onSubmit}
        />
      </div>
    );
  }
}

export default QuestionAssociationEdit;
