import React from 'react';
import { observer } from 'mobx-react';
import cx from 'classnames';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  .fa-check {
    margin: 6px 3px 0 0;
    color: #ababab;
    &.complete {
      color: ${Theme.green};
    }
  }
`;

@observer
class TabLabel extends React.Component {

  render() {
    return (
      <Wrapper>
        <i
          className={cx('fa fa-check', {
            complete: this.props.valid,
          })}
        />
        {this.props.label}
        {
          !this.props.valid &&
            <span className='required'>
              {this.props.requiredLabel || '(Required)'}
            </span>
        }
      </Wrapper>
    );
  }
}

export default TabLabel;
