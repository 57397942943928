import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  align-items: center;
  font-size: 18px;
  height: 90px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  background: ${props => props.background};
  position: relative;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
`;

@observer
class ForumRow extends React.Component {

  static defaultProps = {
    background: '#FFF',
  }

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
        background={this.props.background}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ForumRow);
