import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import WorksheetGrid from 'components/worksheet/worksheet_grid';
import WorksheetList from 'components/worksheet/worksheet_list';
import BannerNoticeIndex from 'components/banner_notice/banner_notice_index';

const Wrapper = styled.div`
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
`;

@observer
class WorksheetIndex extends React.Component {

  render() {
    return (
      <Wrapper>
        <BannerNoticeIndex
          route='worksheets.index'
        />
        {
          AppUI.siteIsDefault &&
            <WorksheetList
              {...this.props}
            />
        }
        {
          !AppUI.siteIsDefault &&
            <WorksheetGrid
              useGridSection
              showFilters
              {...this.props}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetIndex);
