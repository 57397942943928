import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { flexCenter } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  ${flexCenter()}
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  border-radius: 22px;
  border: 2px solid ${props => props.value ? props.selectedBackground : '#d8d8d8'};
  cursor: pointer;
  background: #FFF;
  font-size: 12px;
  &:hover {
    background: ${props => props.value ? props.selectedBackgroundHover : props.backgroundHover};
    border: 2px solid ${props => props.value ? '#000' : '#7d7c7c'};
  }
`;

const Selected = styled.div`
  width: ${props => `${(parseInt(props.width, 10) / 2)}px`};
  height: ${props => `${(parseInt(props.height, 10) / 2)}px`};
  border-radius: 12px;
  background: ${Theme.darkestBlue};
`;

@observer
class RadioButton extends React.Component {

  static defaultProps = {
    background: '#FFF',
    selectedBackground: Theme.darkestBlue,
    backgroundHover: 'whitesmoke',
    selectedBackgroundHover: '#000',
    width: '22px',
    height: '22px',
  }

  render() {
    return (
      <Wrapper
        className='radio-button'
        width={this.props.width}
        height={this.props.height}
        margin={this.props.margin}
        onClick={this.props.onClick}
        value={this.props.value}
        background={this.props.background}
        selectedBackground={this.props.selectedBackground}
        backgroundHover={this.backgroundHover}
        selectedBackgroundHover={this.selectedBackgroundHover}
      >
        {
          this.props.value &&
            <Selected
              width={this.props.width}
              height={this.props.height}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(RadioButton);
