import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.form`

`;

const FormButtons = styled.div`
  display: flex;
`;

@observer
class Form extends React.Component {

  static propTypes = {
    onSubmit: PropTypes.func,
    form: PropTypes.object.isRequired,
    fields: PropTypes.object.isRequired,
  };

  @autobind onSubmit(e) {
    e.preventDefault();
    if (this.props.form.submissionEnabled) {
      if (this.props.onSubmit) {
        this.props.onSubmit(this.props.form.store.formCache);
      } else {
        this.props.form.onSubmit();
      }
    }
  }

  @autobind onClear(e) {
    e.preventDefault();
    if (this.props.onClear) {
      this.props.onClear();
    } else {
      this.props.form.onClear();
    }
  }

  render() {
    return (
      <Wrapper>
        {this.props.fields}
        <FormButtons>
          <Button
            disabled={!this.props.form.submissionEnabled}
            onClick={this.onSubmit}
            background={Theme.green}
            margin='0 5px 0 0'
          >
            <i className='fa fa-check' />
            {this.props.form.isUpdate ? 'Update' : 'Submit'}
          </Button>
          {
            !this.props.hideReset &&
              <Button
                background={Theme.red}
                margin='0 5px 0 0'
                disabled={!this.props.form.store.dirtyForm}
                onClick={this.onClear}
              >
                <i className='fa fa-times' />
                Reset
              </Button>
          }
        </FormButtons>
      </Wrapper>
    );
  }
}

export default Form;
