import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Badge from 'components/svg/badge';
import Text from 'components/text';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { LanguageIcon } from '@seedlang/icons';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  font-size: ${props => props.fontSize};
  width: ${props => props.width};
  position: relative;
  svg {
    width: ${props => props.width};
    fill: #575757;
  }
`;

const TextWrapper = styled.div`
  ${flexCenterColumn()}
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
`;

@observer
class LevelBadge extends React.Component {

  render() {
    return (
      <Wrapper
        width={this.props.width}
        fontSize={this.props.fontSize}
      >
        <TextWrapper>
          {
            isPresent(this.props.languageId) &&
              <LanguageIcon
                languageId={this.props.languageId}
                margin='3px 0 5px 0'
              />
          }
          <Text
            bold
            center
            color='#575757'
            fontSize={this.props.fontSize}
            margin='-3px 0 0 0'
          >
            {this.props.level}
          </Text>
        </TextWrapper>
        <Badge />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LevelBadge);
