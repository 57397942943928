import React from 'react';
import { observer } from 'mobx-react';
import { VocabOptionsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import Button from 'components/button/button';
import Checkbox from 'components/checkbox';
import { capitalize } from 'lodash';
import Spinner from 'components/spinner';
import Alert from 'components/alert';
import { CrownIcon } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';
import FilterTags from 'components/filter_tags';
import AdvancedFilters from 'components/advanced_filters';
import DeckFilters from 'components/deck_filters';
import { isPresent } from '@seedlang/utils/src';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const ButtonText = styled.div`
  width: 100%;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  i {
    color: #FFF;
    position: absolute;
    left: -5px;
    top: 16px;
    transform: rotate(-45deg);
  }
`;

const SettingsRow = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  padding: 0 10px 10px 10px;
`;

const FieldColumn = styled.div`
  width: ${props => props.width};
  padding-right: 10px;
`;

const DescriptionColumn = styled.div`
  flex: 1;
  font-size: 12px;
  color: #333;
`;

const IconWrapper = styled.div`
  position: absolute;
  left: -5px;
  top: 16px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 100%;
  margin-top: ${props => props.marginTop};
  margin-bottom: 5px;
`;

@observer
class VocabOptions extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.cardsNotFound &&
            <Alert
              textAlign='center'
              width='100%'
            >
              {this.props.cardsNotFoundMessage}
              {
                (this.props.type === 'gender' || this.props.type === 'plural') &&
                  <span>
                    &nbsp;Please note that not all nouns can be used in the {capitalize(this.props.type)} trainer.
                  </span>
              }
            </Alert>
        }
        {
          this.props.filterWasSet &&
            <DeckFilters
              title='Current Vocab Filters'
            >
              <FilterTags
                width='100%'
                filterSettings={this.props.filterSettingsWithoutWordType}
                onSetFilter={this.props.onSetFilter}
                padding='5px'
                disabled={!this.props.useCurrentFilter}
                inDeckOptions
                showSeenAny
              />
              <SettingsRow>
                <FieldColumn>
                  <Checkbox
                    value={this.props.useCurrentFilter}
                    onClick={this.props.toggleUseCurrentFilter}
                  />
                </FieldColumn>
                <DescriptionColumn>
                  Use Current Vocab Filters
                </DescriptionColumn>
              </SettingsRow>
            </DeckFilters>
        }
        {
          isPresent(this.props.advancedFilterSettings) &&
            <AdvancedFilters
              margin='0 0 20px 0'
              title='Advanced Trainer Settings'
              filterSettings={this.props.advancedFilterSettings}
            />
        }
        {
          this.props.deckIsBeingCreated &&
            <ButtonWrapper>
              <Spinner
                background={Theme.blue}
                margin='0'
              />
            </ButtonWrapper>
        }
        {
          !this.props.deckIsBeingCreated &&
            <ButtonWrapper>
              <Button
                height='50px'
                width='100%'
                background={Theme.blue}
                onClick={this.props.onCreateDeck}
                margin='10px 0 0 0'
              >
                <ButtonText
                  color='#FFF'
                >
                  <i className='fa fa-arrow-right' />
                  {AppUI.user.isMember ? this.props.title : `Try a ${capitalize(this.props.type)} Deck`}
                </ButtonText>
              </Button>
            </ButtonWrapper>
        }
        {
          !AppUI.user.isMember &&
            <ButtonWrapper
              marginTop='10px'
            >
              <Button
                height='50px'
                background={Theme.green}
                onClick={() => AppUI.routeStore.routeToNamed('memberships.index')}
                width='100%'
              >
                <ButtonText
                  color='#FFF'
                >
                  <IconWrapper>
                    <CrownIcon />
                  </IconWrapper>
                  Unlock All Decks
                </ButtonText>
              </Button>
            </ButtonWrapper>
        }
      </Wrapper>
    );
  }
}

export default VocabOptionsWrapper(VocabOptions);
