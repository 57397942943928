import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: ${props => props.padding};
  background: ${props => props.background};
  border-radius: ${props => props.borderRadius};
  a {
    cursor: pointer;
  }

  form {
    margin: 0;
  }

  label {
    margin-top: 10px;
    font-size: 14px;
    margin: 0;
    text-align: left;
    color: ${props => props.color};
  }

  input {
    width: 100%;
    margin-bottom: 10px;
    border-radius: 10px;
    height: 50px;
  }

  input[type="submit"]:hover, input[type="submit"]:active, input[type="submit"]:visited, input[type="submit"]:focus {
    color: ${props => props.color};
    border: none;
  }
`;

@observer
class FormWrapper extends React.Component {

  static defaultProps = {
    padding: '20px',
    color: '#FFF',
  }

  render() {
    return (
      <Wrapper
        padding={this.props.padding}
        background={this.props.background}
        borderRadius={this.props.borderRadius}
        color={this.props.color}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FormWrapper);
