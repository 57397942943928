import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import InPlaceText from 'components/form/in_place_text';
import SentenceCreate from 'pages/builder/sentences/sentence_create';
import SentenceIndex from 'pages/builder/sentences/sentence_index';
import { InterjectionSentenceStore, InterjectionStore, SentenceStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import Modal from 'components/modal';

@observer
class InterjectionEdit extends React.Component {
  @observable showCreateSentence = false;

  componentDidMount() {
    InterjectionStore.getShow({ ids: {interjectionId: this.props.params.interjectionId }}, this.afterGetShow);
    this.loadSentences();
  }

  componentWillUnmount() {
    InterjectionStore.clearShowData();
  }

  @autobind afterGetShow(resp) {
    InterjectionStore.setShowData(resp);
  }

  @autobind loadSentences() {
    InterjectionSentenceStore.getIndex({ ids: {interjectionId: this.props.params.interjectionId }});
  }

  @autobind onRemoveInterjection(id) {
    SentenceStore.update({ids: {sentenceId: id}, data: {interjection_id: null}}, this.loadSentences);
  }

  render() {
    return (
      <div className='interjection-edit'>
        {
          this.showCreateSentence &&
            <Modal
              onCloseModal={() => this.showCreateSentence = false}
            >
              <SentenceCreate
                afterCreate={this.loadSentences}
                interjectionId={InterjectionStore.showData.id}
              />
            </Modal>
        }
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                defaultValue={InterjectionStore.showDataField('name')}
                model='interjections'
                field='name'
                id={InterjectionStore.showDataField('id')}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Code</legend>
              <InPlaceText
                defaultValue={InterjectionStore.showDataField('code')}
                model='interjections'
                field='code'
                id={InterjectionStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Sentences</legend>
              <button
                className='gray-button'
                onClick={() => {this.showCreateSentence = true;}}
              >
                <i className='fa fa-plus' />
                Create
              </button>
              <SentenceIndex
                onDelete={this.onRemoveInterjection}
                store={InterjectionSentenceStore}
                ids={{ interjectionId: this.props.routeParams.interjectionId }}
                deletePrompt='Remove this sentence from this interjection?'
                scoped
                hide={['ENSource']}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default InterjectionEdit;
