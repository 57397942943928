import React from 'react';
import { observer } from 'mobx-react';
import { isPresent, isBlank, pixify } from '@seedlang/utils';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { BorderRadius , CardWordTextWrapper } from '@seedlang/hoc';
import CardSection from 'components/vocab_card/card_section';
import ExpandedTarget from 'components/vocab_card/expanded_target';
import CardSourceList from 'components/vocab_card/card_source_list';
import autobind from 'autobind-decorator';
import LanguageButton from 'components/button/language_button';
import { DeckUI } from '@seedlang/state';

const Wrapper = styled.div`
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: ${Theme.z['card-3']};
  .fa-times {
    color: ${Theme.red};
  }
  .fa-check {
    color: ${Theme.green};
  }
`;

const SourcesButton = styled.div`
  margin: 5px; 
  cursor: pointer;
  font-style: italic;
  color: #676767;
  font-size: 14px;
  position: absolute;
  bottom: 5px;
  border-top: #a7a5a5 solid 1px;
  width: 100%;
  padding-top: 5px;
`;

const LanguageButtonWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: ${props => props.width};
  height: ${props => props.height};
  background: #FFF;
  border-radius: 10px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;

@observer
class CardWordText extends React.Component {

  static defaultProps = {
    languageButtonTop: '10px',
    languageButtonRight: '10px',
    position: 'relative',
    borderBottomRadius: 10,
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.showType === 'target' &&
          <CardSection
            background={this.props.card.word.isPhoneme || this.props.card.word.isGrapheme || this.props.toggleToShowSources ? '#CCC' : '#FFF'}
            height={this.props.card.word.isPhoneme || this.props.card.word.isGrapheme || this.props.toggleToShowSources ? pixify(Math.floor(this.props.sectionHeight)) : pixify(Math.floor(this.props.sectionHeight / 2))}
            borderBottomRadius={(this.props.card.word.isPhoneme || this.props.card.word.isGrapheme || this.props.toggleToShowSources) && !this.props.hasUserInput ? this.props.borderBottomRadius : null}
          >
            {
              this.props.showType === 'target' &&
              <IconWrapper>
                {
                  isPresent(this.props.correct) && this.props.correct &&
                  <i className='fa fa-check'/>
                }
                {
                  isPresent(this.props.correct) && !this.props.correct && isBlank(this.props.cardState.textEntry) &&
                  <i className='fa fa-times'/>
                }
              </IconWrapper>
            }
            {
              this.props.toggleToShowSources && (this.props.cardState.revealed || !this.props.showIfRevealed) && !this.props.card.word?.isPhoneme && !this.props.card.word?.isGrapheme &&
                <LanguageButtonWrapper
                  onClick={this.props.onClickLanguageButton}
                  height={DeckUI.layout.minimumHeight ? '30px' : '40px'}
                  width={DeckUI.layout.minimumHeight ? '30px' : '40px'}
                >
                  <LanguageButton
                    background='#e8e8e8'
                    arrowLeft={this.props.arrowLeft}
                    width={this.props.languageIconWidth}
                    languageId={this.props.showType === 'source' ? this.props.sentence.sourceTextForUserLanguageId(DeckUI.user) || 'EN' : DeckUI.deck?.languageId || this.props.card?.languageId || DeckUI.targetLanguageId}
                  />
                </LanguageButtonWrapper>
            }
            {
              (this.props.cardState.revealed || !this.props.showIfRevealed) &&
                <ExpandedTarget
                  card={this.props.card}
                  cardState={this.props.cardState}
                  word={this.props.card.word}
                />
            }
          </CardSection>
        }
        {
          this.props.showType === 'target' && !this.props.hideSources && !this.props.toggleToShowSources &&
            <CardSection
              background='#CCC'
              height={pixify(Math.floor(this.props.sectionHeight / 2))}
              borderBottomRadius={this.props.hasUserInput ? null : this.props.borderBottomRadius}
              padding='5px'
            >
              <CardSourceList
                preview
                word={this.props.card.word}
                card={this.props.card}
                toggleLanguage={() => this.props.card.cardState.toggleShowType()}
                sourcePadding='5px 0'
                numberPreviewSources={2}
                showType={this.props.showType}
              />
            </CardSection>
        }
        {
          this.props.showType === 'source' &&
            <CardSection
              background='#CCC'
              borderBottomRadius={this.props.hasUserInput ? null : this.props.borderBottomRadius}
              height={pixify(this.props.sectionHeight)}
            >
              <CardSourceList
                showEdit
                showLanguageButton
                word={this.props.card.word}
                card={this.props.card}
                numberPreviewSources={2}
                toggleLanguage={() => this.props.card.cardState.toggleShowType()}
                showType={this.props.showType}
              />
            </CardSection>
        }
      </Wrapper>

    );
  }
}

export default BorderRadius(CardWordTextWrapper(CardWordText));