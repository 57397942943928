import React from 'react';
import { observer } from 'mobx-react';
import { SiteNoticeStore } from '@seedlang/stores';
import SiteNoticeCreate from 'pages/builder/site_notices/site_notice_create';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import CheckIfTrue from 'components/check_if_true';

@observer
class SiteNoticeIndex extends React.Component {

  componentDidMount() {
    SiteNoticeStore.getIndex();
  }

  @autobind onDelete(id) {
    SiteNoticeStore.destroy({ids: {siteNoticeId: id}});
  }

  render() {
    return (
      <div className='chat-builder-index'>
        <SiteNoticeCreate />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th>Created At</th>
              <th>Title</th>
              <th>Onboarding</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              SiteNoticeStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.site_notices.edit', params: { siteNoticeId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td width='130'>
                      {item.createdAt.formatted}
                    </td>
                    <td>
                      {item.title}
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.onboarding}
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this site notice?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default SiteNoticeIndex;
