import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI, LoadingState } from '@seedlang/state';
import { WorksheetStore } from '@seedlang/stores';
import { isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Copy from 'components/copy';
import Spinner from 'components/spinner';
import { each } from 'lodash';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import React from 'react';
import DownloadLinkUploadScreen from './download_link_upload_screen';
import InfoTooltip from 'components/info_tooltip';

const Wrapper = styled.div`
  h3 {
    width: 100%;
    margin-bottom: 20px!important;
  }
  padding-bottom: 20px;
`;

const Table = styled.table`

`;

const TitleWrapper = styled.div`
  i {
    margin-right: 5px;
  }
`;

const Title = styled.span`
  margin-right: 5px;
  display: flex;
  align-items: center;
`;

const Subtitle = styled.span`
  font-style: italic;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  input {
    border: 1px solid #AAA!important;
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const Comment = styled.div`
  color: ${Theme.gray};
  font-size: 12px;
  margin-top: 5px;
  line-height: 14px;
  i {
    margin-right: 5px;
  }
`;

@observer
class DownloadLinkEdit extends React.Component {
  @observable values = {};
  @observable showPodcastEpisodeSelect;
  @observable currentScreen = 'main';
  @observable currentDownloadLinkType;
  loadingState = new LoadingState();

  constructor(props) {
    super(props);
    this.getLinks();
    this.updateValues();
  }

  @autobind getLinks() {
    AppUI.downloadLinkTypeStore.getIndex();
  }

  @autobind updateValue(value, id) {
    this.values[id] = value;
  }

  @autobind updateValues() {
    each(this.props.worksheet.downloadLinks, downloadLink => {
      this.values[downloadLink.downloadLinkTypeId] = downloadLink.text;
    });
  }

  @autobind onSubmit(id) {
    const data = {
      worksheetId: this.props.worksheet.id,
      downloadLinkTypeId: id,
      text: this.values[id],
    };
    AppUI.downloadLinkStore.upsert({data: data}, this.props.onChange);
  }

  @autobind addPodcastEpisode(podcastEpisode, id) {
    this.values[id] = podcastEpisode?.mp3?.url;
    this.showPodcastEpisodeSelect = null;
    this.onSubmit(id);
  }

  @autobind togglePodcastSearch(downloadLink) {
    this.showPodcastEpisodeSelect = downloadLink.id;
  }

  @autobind onShowUploadScreen(downloadLinkType) {
    this.currentDownloadLinkType = downloadLinkType;
    this.currentScreen = 'upload';
  }

  @autobind onHideUploadScreen() {
    this.currentDownloadLinkType = null;
    this.currentScreen = 'main';
  }

  @autobind async reloadDataAndShowMainScreen() {
    this.loadingState.started();
    try {
      await WorksheetStore.getShowPromise({ ids: { worksheetId: this.props.worksheet.id } });
      this.updateValues();
      this.currentScreen = 'main';
      this.currentDownloadLinkType = null;
      this.loadingState.succeeded();
    }
    catch (error) {
      console.error('Failed reloading data', error);
      this.loadingState.failed('An error occurred while reloading the data.');
    }
  }

  render() {
    return (
      <Wrapper>
        {
          this.loadingState.isLoading ? (
            <Spinner className='blue' />
          ) : (
            <>
              {
                this.loadingState.errorMessage && (
                  <div style={{ color: 'red' }} onClick={() => this.loadingState.clear()}>
                    {this.loadingState.errorMessage}
                  </div>
                )
              }
              {
                this.currentScreen == 'main' && this.renderMainScreen()
              }
              {
                this.currentScreen == 'upload' && (
                  <DownloadLinkUploadScreen
                    downloadLinkType={this.currentDownloadLinkType}
                    worksheet={this.props.worksheet}
                    values={this.values}
                    onHideUploadScreen={this.onHideUploadScreen}
                    onSelectEpisode={episode => this.addPodcastEpisode(episode, this.currentDownloadLinkType.id)}
                    onChange={this.reloadDataAndShowMainScreen}
                  />
                )
              }
            </>
          )
        }
      </Wrapper>
    );
  }

  renderMainScreen() {
    return (
      <>
        <center><h3>Edit Download Links for {this.props.worksheet.truncatedName(30)}</h3></center>
        <Table>
          <tbody>
          {
            AppUI.downloadLinkTypeStore.indexData.map(downloadLinkType => {
              return (
                <tr
                  key={downloadLinkType.id}
                >
                  <td>
                    <TitleWrapper>
                      <Title><i className={`fa fa-${downloadLinkType.icon}`} /> {downloadLinkType.title}</Title>
                      {
                        downloadLinkType.subtitle &&
                        <Subtitle>{downloadLinkType.subtitle}</Subtitle>
                      }
                    </TitleWrapper>
                    {
                      downloadLinkType.podcast &&
                      <Comment><i className='fa fa-rss' /> Linked to the Podcast Feed {downloadLinkType.podcast.title}</Comment>
                    }
                    {
                      downloadLinkType.comment &&
                      <Comment><i className='fa fa-info-circle' /> {downloadLinkType.comment}</Comment>
                    }
                    {
                      downloadLinkType.generatedPdfs &&
                      <Comment><i className='fa fa-file-pdf-o' /> PDF worksheets are added automatically added here.</Comment>
                    }
                  </td>
                  <td style={{width: '50%'}}>
                    <InputWrapper>
                      <input
                        onChange={el => this.updateValue(el.target.value, downloadLinkType.id)}
                        onBlur={() => this.onSubmit(downloadLinkType.id)}
                        value={this.values[downloadLinkType.id]}
                      />
                      <IconsWrapper>
                        <InfoTooltip
                          icon={downloadLinkType.podcast ? 'rss' : 'upload'}
                          onClick={() => this.onShowUploadScreen(downloadLinkType)}
                          position='left'
                        >
                          {`${isPresent(downloadLinkType.podcast) ? 'Select a Podcast Episode or ' : ''}Upload a File`}
                        </InfoTooltip>
                        {
                          isPresent(this.values[downloadLinkType.id]) &&
                          <>
                            <a
                              href={this.values[downloadLinkType.id]}
                              target='_blank'
                              title='Open Link'
                            >
                              <i className='fa fa-external-link'/>
                            </a>
                            <Copy
                              text={this.values[downloadLinkType.id]}
                            />
                          </>
                        }
                      </IconsWrapper>
                    </InputWrapper>
                  </td>
                  <td>
                    {
                      isPresent(this.values[downloadLinkType.id]) && !/^(http|https):\/\//.test(this.values[downloadLinkType.id]) &&
                        <i className='fa fa-warning' style={{marginTop: '8px'}}/>
                    }
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </Table>
        <BackEndExplainer compact background={Theme.lightGray}>These download link categories are set up in the Download Links section. Here, you can add links for this post's downloads.</BackEndExplainer>
      </>
    );
  }
}

export default DownloadLinkEdit;
