import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import { DeckUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';
import { RightIcon } from '@seedlang/icons';

@observer
class NextButton extends React.Component {

  render() {
    return (
      <CardButtons
        height={DeckUI.layout.buttonHeight - 10}
        margin={this.props.margin}
        width='calc(100% - 20px)'
      >
        <CardButton
          submitOnEnter={this.props.submitOnEnter}
          shortcut={this.props.shortcut}
          borderTopLeftRadius={10}
          borderTopRightRadius={10}
          borderBottomLeftRadius={10}
          borderBottomRightRadius={10}
          text={this.props.text || 'Next Card'}
          backgroundColor={Theme.green}
          icon={<RightIcon />}
          disabled={this.props.disabled}
          onClick={this.props.onClick}
        />
      </CardButtons>
    );
  }
}

export default ErrorBoundary(NextButton);
