import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import WordBreakdown from 'components/word_breakdown';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

const Wrapper = styled.div`
  font-size: ${props => props.fontSize};
  display: flex;
  flex-wrap: wrap;
  .underline {
    border-bottom: ${props => props.clickable ? '2px solid ' : null};
    border-color: #cccccc;
    text-decoration: none;
  }
`;

@observer
class WorksheetVocabTargetText extends React.Component {

  @computed get wordTargetText() {
    const item = this.props.worksheetWord;
    if (isPresent(item.wordStructure) && isPresent(item.wordStructure.text)) {
      if (AppUI.layout.isMobile && isPresent(item.wordStructure.shortText)) {
        return item.wordStructure.shortText;
      } return item.wordStructure.text;
    }
    if (AppUI.site.hideDefiniteArticle) {
      return item.word.targetTextWithGender;
    } else {
      return item.word.targetTextWithDefiniteArticle;
    }
  }

  render() {
    return (
      <Wrapper
        fontSize={this.props.worksheetWord.word?.rightToLeftLanguage ? '20px' : '16px'}
        clickable={AppUI.site.showWordPages}
      >
        {
          !AppUI.site.showWordStress &&
            <div
              className='underline'
              dangerouslySetInnerHTML={{__html: this.wordTargetText}}
            />
        }
        {
          AppUI.site.showWordStress &&
            <WordBreakdown
              word={this.props.worksheetWord.word}
              displayText={this.wordTargetText}
              className='underline'
            />
        }
        {
          AppUI.site.showPlural && isPresent(this.props.worksheetWord.word?.pluralNounsString) &&
            <div style={{marginRight: '4px', color: 'gray'}}>,</div>
        }
        {
          !AppUI.site.showWordStress && AppUI.site.showPlural && isPresent(this.props.worksheetWord.word?.pluralNounsString) &&
            <div style={{color: 'gray'}}>{this.props.worksheetWord.word?.pluralNounsString}</div>
        }
        {
          AppUI.site.showPlural && isPresent(this.props.worksheetWord.word?.pluralNoun) && AppUI.site.showWordStress &&
            <>
              <WordBreakdown
                color='gray'
                word={this.props.worksheetWord.word.pluralNoun}
                displayText={this.props.worksheetWord.word?.pluralNoun.targetTextWithDefiniteArticle}
              />
            </>
        }
      </Wrapper>
    );
  }
}

export default WorksheetVocabTargetText;
