import React from 'react';
import { observer } from 'mobx-react';
import Badge from 'components/badge';
import { CommunityBadgeIcon, SentencewriterIcon, PluralIcon, TranslateIcon, BrainIcon, TriviamasterIcon, DerDieDasIcon, MagnifyWordsIcon, BookIcon, ShakespeareIcon, MicrophoneBadgeIcon, CardsIcon, ClockfaceIcon } from '@seedlang/icons';
import { BadgeCollectionWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const EmptyBadges = styled.div`
  padding: 0 20px 20px 20px;
  font-weight: bold;
`;

const ICONS = {
  sentencewriter: SentencewriterIcon,
  storyteller: ShakespeareIcon,
  interviewMe: MicrophoneBadgeIcon,
  deckmaster: CardsIcon,
  backInTime: ClockfaceIcon,
  storymaster: BookIcon,
  gendermaster: DerDieDasIcon,
  vocabmaster: MagnifyWordsIcon,
  brainiac: BrainIcon,
  triviamaster: TriviamasterIcon,
  translationmaster: TranslateIcon,
  pluralmaster: PluralIcon,
  community: CommunityBadgeIcon,
};

@observer
class BadgeCollection extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.emptyBadges() &&
            <EmptyBadges>
              No badges have been earned yet.
            </EmptyBadges>
        }
        {
          this.props.filteredBadges.map(badge => {
            if ((AppUI.user.translator || badge.slug !== 'translationmaster') && (!this.props.hideLevel0 || this.props.user[badge.field] >= badge.levels[0])) {
              return (
                <Badge
                  alignment={this.props.alignment}
                  width={this.props.width}
                  wrapperWidth={this.props.wrapperWidth}
                  wrapperHeight={this.props.wrapperHeight}
                  margin={this.props.margin}
                  badgeWidth={this.props.badgeWidth}
                  showProgress={this.props.showProgress}
                  key={badge.name}
                  name={badge.name}
                  description={badge.description}
                  current={this.props.user[badge.field]}
                  levels={badge.levels}
                  icon={React.createElement(ICONS[badge.slug])}
                  fontSize={this.props.fontSize}
                  textMarginTop={this.props.textMarginTop}
                  textMarginBottom={this.props.textMarginBottom}
                  bottom={this.props.bottom}
                  right={this.props.right}
                />
              );
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default BadgeCollectionWrapper(BadgeCollection);
