import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import SimpleSentenceIndexRow from 'pages/builder/sentences/simple_sentence_index_row';

const Wrapper = styled.div`
  background: white;
  border-top: 1px solid #CCC;
  border-left: 1px solid #CCC;
  margin-bottom: 20px;
`;

@observer
class SimpleSentenceIndex extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.sentences.map(item => {
            return (
              <div
                key={item.id}
              >
                <SimpleSentenceIndexRow
                  sentence={item}
                  sentenceId={this.props.sentenceId}
                  onSelectSentenceId={this.props.onSelectSentenceId}
                  onDeleteSentence={this.props.onDeleteSentence}
                />
                {
                  item.hasAnswers &&
                    <SimpleSentenceIndexRow
                      sentence={item.answers[0]}
                      sentenceId={this.props.sentenceId}
                      onSelectSentenceId={this.props.onSelectSentenceId}
                      onDeleteSentence={this.props.onDeleteSentence}
                    />
                }
              </div>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default SimpleSentenceIndex;
