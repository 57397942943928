import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { PageStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import Spinner from 'components/spinner';
import { isPresent, isBlank } from '@seedlang/utils';
import NoAccessButton from 'components/worksheet/no_access_button';
import { Link } from 'react-router';

const Wrapper = styled.div`
    margin-top: ${props => props.marginTop};
    margin-bottom: ${props => props.marginBottom};
    height: ${props => props.hasAccess ? null : '100%'};
    display: ${props => props.hasAccess ? null : 'flex'};
    align-items: ${props => props.hasAccess ? null : 'center'};
    justify-content: ${props => props.hasAccess ? null : 'center'};
    padding: 10px 20px;
    background: #FFF;
    border-radius: ${props => props.borderRadius};
    h1,h2 {
      line-height: 32px;
    }
    li {
      margin-left: 20px;
      list-style-type: disc;
    }
    a {
      text-decoration: underline;
      word-break: break-word;
    }
    a:hover {
      text-decoration: none;
    }
    p {
      margin: 10px 0!important;
    }
    img {
      max-width: 100%;
      height: auto;
    }
    .no-access-content {
      max-width: 500px;
      text-align: center;
    }
`;

const AdminLink = styled.div`
  font-size: 11px;
  text-align: center;
  padding: 10px 5px 0 5px;
  display: flex;
  flex-direction: column;
`;
@observer
class PageShow extends React.Component {
  componentDidMount() {
    this.getPage();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.pageId !== prevProps.routeParams.pageId) {
      this.getPage();
    }
  }

  componentWillUnmount() {
    PageStore.clearShowData();
  }

  @computed get page() {
    return PageStore.showData;
  }

  @autobind getPage() {
    PageStore.getShow({ ids: { pageId: this.props.routeParams.pageId || 'home' }});
  }

  @computed get hasAccess() {
    if (isBlank(this.page)) { return false; }
    if (this.page.membershipTypePages.length === 0 && this.page.showToNonMembers) { return true; }
    return AppUI.userIsGroupAdmin || (!AppUI.user?.isMember && this.page.showToNonMembers) || (AppUI.user.isMember && this.pageHasUserMembershipType);
  }

  @computed get pageHasUserMembershipType() {
    return isPresent(this.page) && this.page.membershipTypes.map(item => item.id).indexOf(AppUI.user.currentUserGroup?.membershipTypeId) !== -1;
  }

  render() {
    return (
      <Wrapper
        marginTop={AppUI.layout.isMobile ? '15px' : null}
        marginBottom={AppUI.layout.isMobile ? '60px' : null}
        marginTop={AppUI.layout.isMobile ? '15px' : null}
        marginBottom={AppUI.layout.isMobile ? '60px' : null}
        borderRadius={AppUI.layout.isMobile ? '0' : '10px'}
        hasAccess={this.hasAccess}
      >
        {
          PageStore.requestCounter > 0 &&
          <Spinner background={AppUI.site.accentColor} />
        }
        {
          !this.hasAccess && isPresent(this.page.membershipTypes) &&
            <div className='no-access-content'>
              <h3>Sorry, you don't have access to this page!</h3>
              <p>Please upgrade your membership level to access this page.</p>
              <NoAccessButton />
            </div>
        }
        {
          this.page.content &&
            <div
              className='page-content'
              dangerouslySetInnerHTML={{ __html: this.hasAccess && this.page.content || '' }}
            />
        }
        {
          AppUI.userIsGroupAdmin &&
            <AdminLink>
              {isPresent(this.page.membershipTypes) ? `Page visible to members of ${this.page.membershipTypes?.map(item => item.name)?.join(', ')}` : 'Visible to all users' }

              {
                isPresent(this.page) && isPresent(AppUI.site?.groupId) &&
                  <Link
                    className='underline'
                    style={{lineHeight: '10px'}}
                    to={{name: 'creator.groups.site_pages.edit', params: {pageId: this.page?.id, groupId: AppUI.site.groupId}}}
                  >
                    Edit Page
                  </Link>
              }
            </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default PageShow;
