import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import UserProfileImage from 'components/user/user_profile_image';
import { Link } from 'react-router';
import NameWithIcons from 'components/user/name_with_icons';
import autobind from 'autobind-decorator';
import { AppUI, ForumUI } from '@seedlang/state';
import styled from '@emotion/styled';
import Text from 'components/text';
import { flexCenter } from '@seedlang/style_mixins';
import ForumThreadCreate from 'components/forum_thread/forum_thread_create';
import CommentCreate from 'components/comment/comment_create';
import CommentButton from 'components/button/comment_button';
import DeleteButton from 'components/button/delete_button';
import { PencilIcon, PinIcon, ReplyIcon, SeedlangIcon, SmileIcon } from '@seedlang/icons';
import VideoPlayer from 'components/media/video_player';
import VideoPlayerWithSupplement from 'components/media/video_player_with_supplement';
import { displayTargetTextCorrectedForLanguage, isPresent } from '@seedlang/utils';
import Alert from 'components/alert';
import { Constants, Theme } from '@seedlang/constants';
import Modal from 'components/modal';
import Button from 'components/button/button';
import TextDisplay from 'components/text_display';
import FeedbackPicker from 'components/feedback/feedback_picker';
import FeedbackList from 'components/feedback/feedback_list';
import MoveThread from 'components/forum_thread/move_thread';
import { invert } from 'lodash';
import ExpandableTextDisplay from '../expandable_text_display';
import SiteProfileImage from 'components/site_profile_image';
import NameWithoutIcons from 'components/user/name_without_icons';

const Wrapper = styled.div`
  background: white;
  padding: 20px;
  position: relative;
  border-radius: 5px;
  .seedlang-icon {
    border: 1px solid gray;
    border-radius: 50%;
    svg {
      width: 50px;
    }
  }
  .pencil-icon {
    width: 10px;
    margin-right: 4px;
  }
  .smile-icon {
    width: 11px;
    margin-right: 4px;
  }
  .reply-icon {
    width: 12px;
    margin-right: 3px;
  }
  .pin-icon {
    width: 11px;
    margin-right: 4px;
  }
  .reply-icon, .smile-icon, .pencil-icon, .pin-icon {
    fill: #333;
  }
  i {
    color: #333;
    font-size: 12px;
    margin: -1px 4px 0 0;
  }
`;

const PostWrapper = styled.div`
  display: flex;
`;

const PostAndInputWrapper = styled.div`
  background: #f1f1f1;
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #c1c1c1;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

const Title = styled.div`
  font-size: 26px;
  line-height: 30px;
  margin: 10px 0;
  flex: 1;
`;

const Target = styled.div`
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  text-decoration: ${props => props.underline ? 'underline' : null};
`;

const Source = styled.div`
 font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  font-style: italic;
  margin: 0 0 10px 0;
`;

const BottomBar = styled.div`
  position: relative;
  display: flex;
  background: #f1f1f1;
  padding: 10px 0;
`;

const TopBar = styled.div`
  display: flex;
  align-items: center;
`;

const PostedAt = styled.div`
  font-size: 11px;
  line-height: 14px;
  color: #9c9c9c;
  font-style: italic;
  text-align: right;
`;

const Body = styled.div`
  margin: 0 0 10px 0;
  font-size: 16px;
  line-height: 22px;
  a {
    text-decoration: underline;
  }
  h1, h2, h3, h4, h5, h6 {
    margin: 10px 0;
  }
  ul {
    margin-left: 20px;
    li {
      list-style-type: disc;
    }
  }
  img {
    max-width: 500px;
  }
`;

const CreateWrapper = styled.div`
  padding: 10px 0 0 0;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
`;

const VideoPlayers = styled.div`
  ${flexCenter()}
`;

const VideoPlayerWrapper = styled.div`
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  width: 180px;
  height: 180px;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AdminLink = styled.div`
  font-size: 12px;
  a {
    color: #525252;
  }
`;

const EditTags = styled.div`
  h3 {
    margin-bottom: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
`;

const HelpCenterText = styled.div`
  display: flex;
  align-items: center;
`;

@observer
class ForumThreadPost extends React.Component {
  @observable showEditTagsModal = false;
  @observable showFeedback = false;
  @observable showCreate = true;
  @observable showMoveThread = false;

  @computed get videoClip() {
    if (this.props.post.sentence && this.props.post.sentence.target) {
      return this.props.post.sentence.target.videoClip;
    } else if (this.props.post.word && this.props.post.word.target && this.props.post.word.target.videoClip) {
      return this.props.post.word.target.videoClip;
    } return null;
  }

  @computed get hasVideoClip() {
    return isPresent(this.videoClip);
  }

  @computed get supplementVideoClip() {
    return this.props.post.sentence && this.props.post.sentence.videoClip;
  }

  @autobind onClickFeedback(feedbackType) {
    ForumUI.feedbackStore.create({data: {
      feedbackType: feedbackType,
      forumThreadId: ForumUI.forumThread.id,
      userId: AppUI.user.id,
    }}, this.props.onUpdateForumThread);
  }

  render() {
    return (
      <Wrapper>
        {
          this.showMoveThread &&
            <Modal
              onCloseModal={() => this.showMoveThread = false}
              height='450px'
              width='500px'
            >
              <MoveThread
                onChange={this.props.onChange}
              />
            </Modal>
        }
        {
          this.showEditTagsModal &&
            <Modal
              onCloseModal={() => this.showEditTagsModal = false}
              height='180px'
              width='500px'
            >
              <EditTags>
                <h3
                  style={{marginBottom: 20}}
                >
                  Tag this Post
                </h3>
                <b>Teams</b>
                <Row>
                  {
                    ['Ling-DE', 'Ling-ES', 'Ling-FR', 'Cust-Sup', 'Marie'].map(item =>
                      <CommentButton
                        key={item}
                        fontSize='14px'
                        padding='10px'
                        onClick={() => ForumUI.onSetTag(item)}
                        background={isPresent(ForumUI.forumThread.tag) && ForumUI.forumThread.tag.match(item) ? Theme.orange : '#FFF'}
                        color={isPresent(ForumUI.forumThread.tag) && ForumUI.forumThread.tag.match(item) ? '#FFF' : '#525252'}
                        border={isPresent(ForumUI.forumThread.tag) && ForumUI.forumThread.tag.match(item) ? '1px solid #f78139' : '1px solid gray'}
                      >
                        {item}
                      </CommentButton>,
                    )
                  }
                </Row>
              </EditTags>
            </Modal>
        }
        <PostAndInputWrapper>
          <PostWrapper>
            {
              this.props.post.user &&
                <UserProfileImage
                  clickToProfile={AppUI.siteIsDefault}
                  user={this.props.post.user}
                  width={50}
                />
            }
            {
              !this.props.post.user && AppUI.siteIsDefault &&
                <SeedlangIcon />
            }
            {
              !this.props.post.user && !AppUI.siteIsDefault &&
                <SiteProfileImage
                  site={AppUI.site}
                  width={50}
                />
            }
            <Content>
              {
                this.props.post.user &&
                  <TopBar>
                    {
                      !this.props.hideUserProfile &&
                        <NameWithIcons
                          user={this.props.post.user}
                        />
                    }
                    {
                      this.props.hideUserProfile &&
                      <NameWithoutIcons
                        user={this.props.post.user}
                      />
                    }
                  </TopBar>
              }
              <TitleWrapper>
                <Title>
                  {
                    !this.props.post.target && this.props.post.title &&
                      <Target
                        fontSize={this.props.post.answer ? '20px' : '26px'}
                        lineHeight={this.props.post.answer ? '23px' : '30px'}
                      >
                        {displayTargetTextCorrectedForLanguage(this.props.post.title, this.props.languageId || this.props.post.languageId)}
                      </Target>
                  }
                  {
                    this.props.post.target && this.props.post.word &&
                      <Link
                        to={{name: 'vocab.word', params: {wordId: this.props.post.word.id}}}
                      >
                        <Target
                          fontSize={this.props.post.answer ? '20px' : '26px'}
                          lineHeight={this.props.post.answer ? '23px' : '30px'}
                          underline
                        >
                          {displayTargetTextCorrectedForLanguage(this.props.post.target, this.props.languageId || this.props.post.languageId)}
                        </Target>
                      </Link>
                  }
                  {
                    this.props.post.target && this.props.post.sentence &&
                      <Target
                        fontSize={this.props.post.answer ? '20px' : '26px'}
                        lineHeight={this.props.post.answer ? '23px' : '30px'}
                      >
                        {displayTargetTextCorrectedForLanguage(this.props.post.target, this.props.languageId || this.props.post.languageId)}
                      </Target>
                  }
                  {
                    this.props.post.target && this.props.post.concept &&
                      <Link
                        to={{name: 'grammar.show', params: {grammarNodeId: this.props.post.concept.grammarNodeId}}}
                      >
                        <Target
                          fontSize='26px'
                          lineHeight='30px'
                          underline
                        >
                          {this.props.post.target}
                        </Target>
                      </Link>
                  }
                  {
                    this.props.post.source && isPresent(this.props.post.source(this.props.user)) &&
                      <Source
                        fontSize={this.props.post.answer ? '16px' : '20px'}
                        lineHeight={this.props.post.answer ? '19px' : '25px'}
                      >
                        {this.props.post.source(this.props.user)}
                      </Source>
                  }
                  {
                    this.props.post.answer &&
                      <Target
                        fontSize={this.props.post.answer ? '20px' : '26px'}
                        lineHeight={this.props.post.answer ? '23px' : '30px'}
                      >
                        {this.props.post.answer.targetText}
                      </Target>
                  }
                  {
                    this.props.post.answer &&
                      <Source
                        fontSize={this.props.post.answer ? '16px' : '20px'}
                        lineHeight={this.props.post.answer ? '19px' : '25px'}
                      >
                        {this.props.post.answer.sourceText}
                      </Source>
                  }
                  {
                    this.props.post.faqItem &&
                      <HelpCenterText>
                        <Text
                          fontSize='14px'
                          lineHeight='18px'
                        >
                          This is an article from our&nbsp;
                        </Text>
                        <Link
                          style={{fontSize: '14px', lineHeight: '18px', textDecoration: 'underline'}}
                          to={{name: 'help_center'}}
                        >
                          Help Center
                        </Link>
                      </HelpCenterText>
                  }
                </Title>
                {
                  !this.props.inModal && this.props.post && this.props.post.referralRoute &&
                    <AdminLink>
                      <Link
                        to={{name: this.props.post.referralRoute, params: this.props.post.referralParamsCamelCase}}
                      >
                        <Button
                          fontSize='12px'
                        >
                          Go to Referring Page
                        </Button>
                      </Link>
                    </AdminLink>
                }
              </TitleWrapper>
              <VideoPlayers>
                {
                  this.hasVideoClip &&
                    <VideoPlayerWithSupplement
                      hasVideoClip
                      autoPlay={false}
                      supplementAutoPlay={false}
                      width={180}
                      supplementVideoClip={this.supplementVideoClip}
                      videoClip={this.videoClip}
                    />
                }
                {
                  this.props.post.answer && this.props.post.answer.target && this.props.post.answer.target.videoClip &&
                    <VideoPlayerWrapper>
                      <VideoPlayer
                        videoClip={this.props.post.answer.target.videoClip}
                        width={180}
                      />
                    </VideoPlayerWrapper>
                }
              </VideoPlayers>
              {
                this.props.post && isPresent(this.props.post.body) &&
                  <Body>
                    <TextDisplay
                      markdown={this.props.post.body}
                    />
                  </Body>
              }
              {
                this.props.post && isPresent(this.props.post.faqItem?.body) &&
                  <Body>
                    <TextDisplay
                      markdown={this.props.post.faqItem?.body}
                    />
                  </Body>
              }
              {
                this.props.post.target && this.props.post.concept &&
                  <ExpandableTextDisplay
                    alwaysShowButton
                    fontSize='14px'
                    markdownText={this.props.post.concept.longDescriptionMd}
                    htmlText={this.props.post.concept.longDescription}
                  />
              }
            </Content>
          </PostWrapper>
          <BottomBar>
            <Left>
              {
                !AppUI.user.guest &&
                  <CommentButton
                    onClick={() => this.showCreate = !this.showCreate}
                  >
                    <ReplyIcon />
                    Reply
                  </CommentButton>
              }
              {
                !AppUI.user.guest &&
                  <CommentButton
                    onClick={() => this.showFeedback = !this.showFeedback}
                  >
                    <SmileIcon />
                    React
                  </CommentButton>
              }
              {
                this.showFeedback &&
                  <FeedbackPicker
                    arrowBoxBottom='45px'
                    onClose={() => this.showFeedback = false}
                    onClick={this.onClickFeedback}
                  />
              }
              {
                (ForumUI.dropInForumThread || (this.props.post.user && (AppUI.user.admin || this.props.post.user.id === ForumUI.user.id))) &&
                  <CommentButton
                    onClick={ForumUI.toggleEditForumThread}
                  >
                    <PencilIcon />
                    {ForumUI.dropInForumThread ? 'Edit Message' : 'Edit'}
                  </CommentButton>
              }
              {
                !ForumUI.dropInForumThread && ((this.props.post.user && this.props.post.user.id === ForumUI.user.id && ForumUI.forumThread.comments.length === 0) || ForumUI.user.moderator) &&
                  <CommentButton>
                    <i className='fa fa-times' />
                    <DeleteButton
                      allowDeletion
                      right='-180px'
                      confirmationMessageFloatsRight
                      onConfirm={() => ForumUI.onDestroyThread()}
                      message='Delete this Thread?'
                    >
                      Delete
                    </DeleteButton>
                  </CommentButton>
              }
              {
                (ForumUI.user.moderator || ForumUI.user.admin) && !this.props.groupId &&
                  <CommentButton
                    onClick={() => ForumUI.onTogglePinned()}
                  >
                    <PinIcon />
                    {ForumUI.forumThread.pinned ? 'Unpin' : 'Pin'}
                  </CommentButton>
              }
              {
                (ForumUI.user.moderator || ForumUI.user.admin) && isPresent(ForumUI.forumThread.tag) && ForumUI.forumThread.tag.split(',').map(item =>
                  <CommentButton
                    key={item}
                    onClick={() => ForumUI.onSetTag(item)}
                    background={Theme.orange}
                    color='#FFF'
                  >
                    {item}
                  </CommentButton>,
                )
              }
              {
                (ForumUI.user.moderator || ForumUI.user.admin) && !this.props.groupId &&
                  <CommentButton
                    onClick={() => this.showEditTagsModal = true}
                  >
                    <i className='fa fa-plus' />
                    Tag
                  </CommentButton>
              }
              {
                !ForumUI.dropInForumThread && (ForumUI.user.moderator || ForumUI.user.admin) && ForumUI.forum.highlightUnseen &&
                  <CommentButton
                    onClick={() => ForumUI.onToggleSeenByAdmin()}
                    background={!ForumUI.forumThread.seenByAdmin ? '#feff93' : '#FFF'}
                  >
                    <i className='fa fa-eye' />
                    {ForumUI.forumThread.seenByAdmin ? 'Checked: Yes' : 'Checked: No'}
                  </CommentButton>
              }
              {
                ForumUI.user.admin && this.props.post.word &&
                  <CommentButton>
                    <Link
                      to={{name: 'builder.words.edit', params: {wordId: this.props.post.word.id}}}
                    >
                      <i className='fa fa-user' />
                      Admin
                    </Link>
                  </CommentButton>
              }
              {
                ForumUI.user.admin && this.props.post.sentence &&
                  <CommentButton>
                    <Link
                      to={{name: 'builder.sentences.edit', params: {sentenceId: this.props.post.sentence.id}}}
                    >
                      Admin
                    </Link>
                  </CommentButton>
              }
              {
                (ForumUI.user.admin || ForumUI.user.moderator) && !this.props.groupId &&
                  <CommentButton
                    onClick={() => this.showMoveThread = true}
                  >
                    <i className='fa fa-upload' />
                    Move
                  </CommentButton>
              }
            </Left>
            <PostedAt>
              {this.props.post.createdAt && this.props.post.createdAt.relativeTime}
              {
                this.props.post.updated &&
                  <span
                    style={{marginLeft: 5}}
                  >
                    (updated)
                  </span>
              }
            </PostedAt>
          </BottomBar>
          {
            ForumUI.editForumThread &&
              <ForumThreadCreate
                blockTitleEdit={ForumUI.dropInForumThread}
                forumThreadId={ForumUI.forumThread.id}
              />
          }
          {
            !ForumUI.user.guest && !ForumUI.editForumThread && this.showCreate &&
              <CreateWrapper>
                <CommentCreate
                  showXp
                  background='#f1f1f1'
                  textInputButtonBackground='#FFF'
                  namespace={ForumUI.forumThread.id}
                  commentable={ForumUI.forumThread}
                  commentableType='ForumThread'
                  afterCreateComment={this.props.onChange}
                  languageId={this.props.languageId || invert(Constants.VOCAB_FORUM_IDS)[ForumUI.forumThread.forumId] || 'DE'}
                />
              </CreateWrapper>
          }
          {
            ForumUI.forumThread.feedbacks && ForumUI.forumThread.feedbacks.length > 0 &&
              <FeedbackList
                id={ForumUI.forumThread.id}
                feedbacks={ForumUI.forumThread.feedbacks}
                forumThreadId={ForumUI.forumThread.id}
                onChange={this.props.onUpdateForumThread}
              />
          }
        </PostAndInputWrapper>
        {
          ForumUI.user.guest &&
            <Alert
              textAlign='center'
              margin='10px 0 0 0'
            >
              Register to leave a comment
            </Alert>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ForumThreadPost);
