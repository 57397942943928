import React from 'react';
import { observer } from 'mobx-react';
import ProgressBar from 'components/progress_bar';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  .progress-bar {
    height: 16px;
    border-radius: 5px;
    width: 100%;
    .current {
      background: ${Theme.orange};
    }
  }
`;

const TextRow = styled.div`
  display: flex;
  width: 100%;
  order: -1;
  margin: ${props => props.margin};
`;

const TextWrapper = styled.div`
  flex: 1;
`;

@observer
class ProgressBarWithLevel extends React.Component {

  render() {
    return (
      <Wrapper >
        <ProgressBar
          current={AppUI.targetLanguage.progressOnCurrentLevel}
          total={AppUI.targetLanguage.xpRangeBetweenLevels}
        />
        <TextRow
          margin='0 0 1px 0'
        >
          <TextWrapper>
            <Text
              bold
              textAlign='left'
            >
              Level {AppUI.targetLanguage.xpLevel}
            </Text>
          </TextWrapper>
          <TextWrapper>
            <Text
              bold
              textAlign='right'
            >
              {AppUI.targetLanguage.xp}/{AppUI.targetLanguage.xpForNextLevel} XP
            </Text>
          </TextWrapper>
        </TextRow>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ProgressBarWithLevel);
