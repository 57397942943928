import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';
import { pixify } from '@seedlang/utils';
import Legend from 'components/visualization/legend';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import { VictoryAxis, VictoryChart, VictoryStack, VictoryArea, VictoryTooltip, VictoryVoronoiContainer } from 'victory';

const Wrapper = styled.div`
  width: ${props => props.width};
  position: relative;
`;

const Controls = styled.div`
  margin: 5px 0 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const LegendWrapper = styled.div`
  display: flex;
`;

const ChartImage = styled.div`
  width: ${props => props.width};
  height: ${props => props.width};
  position: relative;
  img {
    width: ${props => props.width};
  }
`;

const Message = styled.div`
  position: absolute;
  top: 62px;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: white;
  border-radius: 10px;
  padding: 10px;
  height: 79px;
  width: 214px;
  border: 2px solid black;
  text-align: center;
  font-size: 14px;
  line-height: 17px;
`;

const NavigationIcon = styled.div`
  width: 20px;
  height: 30px;
  background: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    font-size: 29px;
    color: #a2a2a2;
    &.disabled {
      color: #CCC;
      cursor: default;
    }
  }
`;

const VictoryChartWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 220px;
  background: #e6e6e6;
  border-radius: 5px;
  overflow: hidden;
`;

const ArrowsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

@observer
class DailyReviewSummaryChart extends React.Component {
  @observable loading = true;
  @observable page = 1;

  @computed get user() {
    return this.props.user ? this.props.user : AppUI.user;
  }

  @computed get hasReviewCardsData() {
    return this.user.reviewCardsCount > 0 && AppUI.dailyReviewSummaryStore.indexData.filter(item => item.occurredAt.date).length > 1;
  }

  @computed get disableIncrement() {
    return this.page === 1;
  }

  @computed get disableDecrement() {
    return (this.page * this.props.limit) > this.user.dailyReviewSummariesCount;
  }

  static defaultProps = {
    width: 250,
  }

  constructor(props) {
    super(props);
    this.loadChart();
  }

  @autobind afterGetIndex(resp) {
    this.loading = false;
    AppUI.dailyReviewSummaryStore.setIndexData(resp);
  }

  @autobind increment() {
    if (!this.disableIncrement) {
      this.page = this.page - 1;
      this.loadChart();
    }
  }

  @autobind decrement() {
    if (!this.disableDecrement) {
      this.page = this.page + 1;
      this.loadChart();
    }
  }

  @autobind loadChart() {
    AppUI.dailyReviewSummaryStore.setPage(this.page);
    AppUI.dailyReviewSummaryStore.getIndex({queryStrings: {limit: this.props.limit, user_id: this.user ? this.user.id : null}}, this.afterGetIndex);
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.props.width)}
      >
        {
          !this.loading && !this.hasReviewCardsData && AppUI.user.isMember &&
            <ChartImage
              width={pixify(this.props.width)}
            >
              <Message>Your progress chart will appear after you've created a review.</Message>
              <img src='/images/empty_daily_review_summaries_chart.png' alt='' />
            </ChartImage>
        }
        {
          !this.loading && !AppUI.user.isMember &&
            <ChartImage
              width={pixify(this.props.width)}
            >
              <Message>Become a Seedlang Pro to unlock this review progress chart.</Message>
              <img src='/images/empty_daily_review_summaries_chart.png' alt='' />
            </ChartImage>
        }
        {
          this.loading && <ChartImage />
        }
        {
          !this.loading && this.hasReviewCardsData && AppUI.user.isMember &&
            <VictoryChartWrapper>
              <VictoryChart
                padding={0}
                height={230}
                width={250}
                containerComponent={<VictoryVoronoiContainer/>}
              >
                <VictoryAxis
                  style={{
                    axis: {stroke: 'transparent'},
                    ticks: {stroke: 'transparent'},
                    tickLabels: { fill:'transparent'},
                  }}
                />
                <VictoryStack
                  padding={0}
                >
                  {
                    AppUI.dailyReviewSummaryStore.forChart.map(item => {
                      return (
                        <VictoryArea
                          interpolation='catmullRom'
                          style={{ data: { fill: item.color } }}
                          key={item.id}
                          data={item.data}
                          labels={({ datum }) => item.id === 'Due' ? null : `${item.id} - ${datum.x} - ${datum.y}`}
                          labelComponent={
                            <VictoryTooltip
                              pointerLength={0}
                              style={{
                                fontSize: 16,
                              }}
                              flyoutStyle={{
                                fill: '#FFF',
                                opacity: item.id === 'Due' ? 0 : 1,
                              }}
                              centerOffset={{ y: -10 }}
                            />
                          }
                        />
                      );
                    })
                  }
                </VictoryStack>
              </VictoryChart>
            </VictoryChartWrapper>
        }
        <Controls>
          <LegendWrapper>
            <Legend
              iconBackground={Theme.green}
              text='Caught Up'
              width='auto'
            />
            <Legend
              iconBackground={Theme.purple}
              text='Retired'
              width='auto'
            />
          </LegendWrapper>
          <ArrowsWrapper>
            {
              !this.loading &&
                <NavigationIcon
                  onClick={this.decrement}
                >
                  <i
                    className={cx('fa', 'fa-angle-left', {
                      disabled: this.disableDecrement,
                    })}
                  />
                </NavigationIcon>
            }
            {
              !this.loading &&
                <NavigationIcon
                  onClick={this.increment}
                >
                  <i
                    className={cx('fa', 'fa-angle-right', {
                      disabled: this.disableIncrement,
                    })}
                  />
                </NavigationIcon>
            }
          </ArrowsWrapper>
        </Controls>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DailyReviewSummaryChart);
