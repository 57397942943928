import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { ContactMessageStore } from '@seedlang/stores';
import ReactJson from 'react-json-view';
import { isPresent } from '@seedlang/utils';
import Button from 'components/button/button';
import { Link } from 'react-router';
import Copy from 'components/copy';
import { computed } from 'mobx';
import isBlank from 'is-blank';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`

`;

@observer
class ContactMessageShow extends React.Component {

  componentDidMount() {
    ContactMessageStore.getShow({ ids: {contactMessageId: this.props.params.contactMessageId }});
  }

  @computed get currentCard() {
    if (!ContactMessageStore.hasShowData || isBlank(ContactMessageStore.showData.debugInfoJson) || isBlank(ContactMessageStore.showData.debugInfoJson['animation_cards'])) { return; }
    return ContactMessageStore.showData.debugInfoJson['animation_cards'][0];
  }

  render() {
    return (
      <Wrapper>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Email</legend>
              {ContactMessageStore.showData.email}
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Message</legend>
              {ContactMessageStore.showData.message}
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Image</legend>
              {
                ContactMessageStore.showData.imageUrl &&
                  <img
                    src={ContactMessageStore.showData.imageUrl}
                    alt=''
                  />
              }
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Debug Info</legend>
              {
                ContactMessageStore.hasShowData && isPresent(ContactMessageStore.showData.debugInfoJson) &&
                  <div>
                    {
                      this.currentCard &&
                        <div
                          style={{marginBottom: 10}}
                        >
                          <div
                            style={{fontWeight: 'bold'}}
                          >
                            Current Card Translation
                          </div>
                          <div style={{display: 'flex', gap: '10px'}}>
                            {this.currentCard['translation']}
                            {
                              isPresent(this.currentCard['translation_id']) && isPresent(this.currentCard['translation_type']) && this.currentCard['card_type'] !== 'exercise' &&
                              <Link
                                className='underline'
                                target='_blank'
                                style={{marginLeft: '20px', fontSize: '14px'}}
                                to={{name: this.currentCard['translation_type'] === 'Sentence' ? 'builder.sentences.edit' : 'builder.words.edit', params: {sentenceId: this.currentCard['translation_id'], wordId: this.currentCard['translation_id']}}}
                              >
                                Edit translation
                              </Link>
                            }
                            <Copy
                              text={this.currentCard['translation']}
                            />
                          </div>
                          <div
                            style={{fontWeight: 'bold'}}
                          >
                            Card Type
                          </div>
                          <div style={{display: 'flex', gap: '10px'}}>
                            {this.currentCard['card_type']}
                          </div>
                        </div>
                    }
                    <div style={{display: 'flex', gap: '10px'}}>
                      {
                        ContactMessageStore.showData.userId &&
                        <Link
                          to={{name: 'builder.users.edit', params: {userId: ContactMessageStore.showData.userId}}}
                        >
                          <Button
                            margin='0 0 10px 0'
                          >
                            Go To User
                          </Button>
                        </Link>
                      }
                      {
                        ContactMessageStore.showData.debugInfoJson['deck_info']['deck'] !== 'review' &&
                        <Link
                          to={{name: 'decks.show', params: {deckId: ContactMessageStore.showData.debugInfoJson['deck_info']['deck_id']}}}
                        >
                          <Button
                            margin='0 0 10px 0'
                          >
                            Open Deck
                          </Button>
                        </Link>
                      }
                      {
                        ContactMessageStore.showData.debugInfoJson['deck_info']['deck'] !== 'review' &&
                        <Link
                          to={{name: 'builder.decks.edit', params: {deckId: ContactMessageStore.showData.debugInfoJson['deck_info']['deck_id']}}}
                          background={Theme.purple}
                        >
                          <Button
                            margin='0 0 10px 0'
                          >
                            <i className='fa fa-edit' />
                            Go to Deck Edit
                          </Button>
                        </Link>
                      }
                    </div>
                    <ReactJson
                      src={ContactMessageStore.showData.debugInfoJson}
                      displayDataTypes={false}
                      displayObjectSize={false}
                    />
                  </div>
              }
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ContactMessageShow);
