import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { autorun } from 'mobx';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  background: white;
  padding: 20px;
  margin: 50px;
  border: 20px solid ${props => props.borderColor};
  border-radius: 10px;
  text-align: center;
  @media only screen and (max-width : 649px) {
    margin: 40px 20px;
  }
`;

const TextWrapper = styled.div`
  margin: 10px 0;
`;

@observer
class ThankYou extends React.Component {

  constructor(props) {
    super(props);
    this.disposer = autorun(() => {
      if (AppUI.userIsLoaded) {
        AppUI.user.set('successfulCheckout', true);
      }
    });
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  @autobind routeToHome() {
    if (AppUI.siteIsDefault) {
      AppUI.routeStore.routeToNamed('stories.home');
    } else {
      AppUI.routeStore.routeToNamed('worksheets.index');
    }
  }

  render() {
    return (
      <Wrapper
        borderColor={AppUI.siteIsDefault ? Theme.blue : AppUI.site.accentColor}
      >
        <h3>Thank you for your subscription! </h3>
        <TextWrapper>
          <span>Your payment was successful and you can view your payment details in the </span>
          <Link
            to={{name: 'settings.billing'}}
            className='underline'
          >
            Billing
          </Link>
          <span> section.</span>
          <p>
            Get started with all of your newly unlocked learning content now! 🎉🎉
          </p>
          <Button
            onClick={this.routeToHome}
          >
            View Unlocked Content
          </Button>
        </TextWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ThankYou);
