"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
// this is ordered by name

var AiLanguages = {
  EN: 'English (American)',
  EN2: 'English (British)',
  AR: 'Arabic',
  ZH: 'Chinese (Simplified)',
  ZH2: 'Chinese (Traditional)',
  HR: 'Croatian',
  CS: 'Czech',
  NL: 'Dutch',
  FI: 'Finnish',
  FR: 'French',
  DE: 'German',
  HU: 'Hungarian',
  IT: 'Italian',
  JA: 'Japanese',
  KO: 'Korean',
  FA: 'Persian',
  PL: 'Polish',
  PT2: 'Portuguese (Brazilian)',
  PT: 'Portuguese (European)',
  RO: 'Romanian',
  RU: 'Russian',
  SR: 'Serbian',
  SK: 'Slovak',
  ES: 'Spanish',
  TR: 'Turkish',
  UK: 'Ukrainian',
  VI: 'Vietnamese'
};
var _default = exports["default"] = AiLanguages;