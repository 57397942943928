import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { PlayIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  margin: 30px 0 100px 0;
`;

const IframeWrapper = styled.div`
  border: 10px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  background: ${Theme.blue};
`;

const VideoLink = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  position: relative;
  will-change: height;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: ${Theme.blue};
  margin: 0 auto;
  cursor: pointer;
  border: 10px solid rgba(255, 255, 255, 0.75);
  box-shadow: 0 6px 30px 0 rgba(0,0,0,0.3);
  img {
    width: 100%;
    height: 100%;
  }
  .icon-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    will-change: opacity;
    -moz-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    height: 80px;
    width: 80px;
    margin: auto;
    opacity: 1;
    .play-icon {
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      background: white;
      display: inline-block!important;
      border-radius: 100%;
      opacity: 1;
      border: 3px solid white;
    }
    svg {
      width: 80px;
      fill: ${Theme.orange};
    }
  }
  &:hover {
    border: 10px solid rgba(255, 255, 255, 1);
    .play-icon {
      transform: scale(1.1);
    }
    img {
      opacity: 1;
    }
  }
`;

@observer
class DemoVideo extends React.Component {
  @observable showVideo = false;

  @computed get width() {
    return !AppUI.layout.viewportWidth || (AppUI.layout.viewportWidth > 800) ? 800 : AppUI.layout.viewportWidth - 20;
  }

  @computed get height() {
    return this.width * 0.5625;
  }

  @autobind onShowVideo() {
    AppUI.createEvent('landing - view explainer', {device: 'web'});
    this.showVideo = true;
  }

  render() {
    return (
      <Wrapper>
        {
          this.showVideo &&
            <IframeWrapper>
              <iframe
                src='https://player.vimeo.com/video/272074752?autoplay=1'
                width={this.width}
                height={this.height}
                frameBorder='0'
                allowFullScreen
                title='Seedlang demo video'
              />
            </IframeWrapper>
        }
        {
          !this.showVideo &&
            <VideoLink
              onClick={this.onShowVideo}
              width={pixify(this.width)}
              height={pixify(this.height)}
            >
              <img
                src={this.props.imageSource || '/images/video_thumbnail2.jpg'}
                alt=''
              />
              <PlayIcon />
            </VideoLink>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DemoVideo);
