import React from 'react';
import { observer } from 'mobx-react';
import { ConceptCategoryStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import DeleteButton from 'components/button/delete_button';
import ConceptCategoryCreate from 'pages/builder/concept_categories/concept_category_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';

@observer
class ConceptCategoryIndex extends React.Component {

  componentDidMount() {
    ConceptCategoryStore.getIndex();
  }

  @autobind onDelete(id) {
    ConceptCategoryStore.destroy({ ids: { conceptCategoryId: id } });
  }

  @autobind loadConceptCategories() {
    ConceptCategoryStore.getIndex();
  }

  render() {
    return (
      <div className='concept-category-index'>
        <ConceptCategoryCreate />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th>Name</th>
              <th>Count</th>
              <th>Published</th>
              <th>Featured</th>
              <th>Content</th>
              <th>Collab</th>
              <th>Sentences Ordrd</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              ConceptCategoryStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.concept_categories.edit', params: { conceptCategoryId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceText
                          defaultValue={item.name}
                          model='concept_categories'
                          field='name'
                          id={item.id}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.conceptsCount}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceCheckbox
                          model='concept_categories'
                          field='published'
                          value={item.published}
                          id={item.id}
                          icon='check'
                          afterChange={this.loadConceptCategories}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceCheckbox
                          model='concept_categories'
                          field='featured'
                          value={item.featured}
                          id={item.id}
                          icon='check'
                          afterChange={this.loadConceptCategories}
                        />
                      </div>
                    </td>
                    <td>
                      <InPlaceCheckbox
                        model='concept_categories'
                        field='content'
                        value={item.content}
                        id={item.id}
                        icon='check'
                        afterChange={this.loadConceptCategories}
                      />
                    </td>
                    <td>
                      <InPlaceCheckbox
                        model='concept_categories'
                        field='collaborative'
                        value={item.collaborative}
                        id={item.id}
                        icon='check'
                        afterChange={this.loadConceptCategories}
                      />
                    </td>
                    <td>
                      <InPlaceCheckbox
                        model='concept_categories'
                        field='sentences_maintain_ordering'
                        value={item.sentencesMaintainOrdering}
                        id={item.id}
                        icon='check'
                        afterChange={this.loadConceptCategories}
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this category?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={ConceptCategoryStore}
        />
      </div>
    );
  }
}

export default ConceptCategoryIndex;
