import React from 'react';
import { observer } from 'mobx-react';
import { FeedEntryWrapper } from '@seedlang/hoc';
import AudioPlayer from 'components/media/audio_player';
import VideoPlayer from 'components/media/video_player';
import UserPost from 'components/user/user_post';
import CommentIndex from 'components/comment/comment_index';
import { AppUI } from '@seedlang/state';
import { isPresent, pixify } from '@seedlang/utils';
import { ThreeDotsIcon } from '@seedlang/icons';
import Dropdown from 'components/dropdown';
import CompositeVideoPlayer from 'components/media/composite_video_player';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import { flexCenter } from '@seedlang/style_mixins';
import CommentCreate from 'components/comment/comment_create';

const Wrapper = styled.div`
  margin-top: 10px;
  li {
    padding: 10px;
    color: #060606;
    font-size: 13px;
  }
  li:hover {
    background: $darkestBlue;
  }
  .three-dots-icon {
    width: 15px;
    display: flex;
    justify-content: flex-end;
    height: 18px;
    margin-top: 5px;
    top: -7px;
    svg {
      width: 5px;
    }
  }
`;

const Card = styled.div`
  border: ${props => props.private ? `2px solid ${Theme.orange}` : 'none'};
  background: white;
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  width: 100%;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
`;

const Title = styled.div`
  padding: 5px 20px 10px 0;
  display: flex;
  font-weight: bold;
  font-size: 21px;
  line-height: 22px;
  flex: 1;
`;

const VideoSection = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

const VideoPlayerWrapper = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  .video-player {
    left: 0px;
    width: 100%;
    height: 100%;
  }
  .supplement {
    .video-player {
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      overflow: hidden;
    }
  }
  .media-with-supplement, .video-player {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }
  .composite-video-player {
    .quadrant {
      background: black;
    }
  }
`;

const UserSection = styled.div`
  background: #ECECEC;
  padding: 10px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;

const BottomBar = styled.div`
  display: flex;
  align-items: center;
`;

const PrivateLabel = styled.div`
  ${flexCenter()}
  background: ${Theme.orange};
  cursor: pointer;
  height: 30px;
  padding: 3px 6px;
  margin-right: 5px;
  color: white;
  font-size: 12px;
  border-radius: 3px;
  margin-top: 10px;
`;

const CommentsButton = styled.div`
  ${flexCenter()}
  cursor: pointer;
  height: 30px;
  padding: 3px 6px;
  font-size: 12px;
  border-radius: 3px;
  margin-top: 10px;
`;

@observer
class FeedEntry extends React.Component {

  render() {
    return (
      <Wrapper>
        <Card
          private={this.props.feedEntry.private}
        >
          {
            (this.props.submittedByMe || AppUI.user.admin) &&
              <Dropdown>
                <ThreeDotsIcon
                  className='main-three-dots'
                />
                <ul>
                  <li
                    onClick={this.props.onTogglePrivate}
                  >
                    {this.props.feedEntry.private ? 'Make Public' : 'Make Private'}
                  </li>
                  <li
                    onClick={() => this.props.onDestroyFeedEntry(this.props.feedEntry.id)}
                  >
                    Delete
                  </li>
                </ul>
              </Dropdown>
          }
          <Title>
            {this.props.feedEntry.concept.name}
          </Title>
          <VideoSection>
            <VideoPlayerWrapper
              width={pixify(this.props.width - 20)}
              height={pixify(this.props.width - 20)}
            >
              {
                this.props.feedEntry.hasCompositeMedia &&
                  <CompositeVideoPlayer
                    height={this.props.width - 20}
                    width={this.props.width - 20}
                    mediaItems={this.props.feedEntry.feedEntryMediaItems}
                  />
              }
              {
                !this.props.feedEntry.hasCompositeMedia &&
                  <VideoPlayer
                    autoPlay={this.props.feedEntry.loopSupplement}
                    loop={this.props.feedEntry.loopSupplement}
                    videoClip={this.props.feedEntry.videoClip}
                    usingDataSaverHeader={AppUI.usingDataSaverHeader}
                    width={this.props.width - 20}
                  />
              }
            </VideoPlayerWrapper>
          </VideoSection>
          <UserSection>
            <UserPost
              showUserIcons={!AppUI.layout.isMobile}
              onClick={() => this.props.onUserClick(this.props.feedEntry.user)}
              user={this.props.feedEntry.user}
              date={this.props.feedEntry.createdAt}
            />
            {
              this.props.feedEntry.audioUrl &&
                <AudioPlayer
                  margin='10px 0 0 0'
                  url={this.props.feedEntry.audioUrl}
                  duration={this.props.feedEntry.duration}
                  mediaStore={this.props.mediaStore}
                />
            }
            {
              isPresent(this.props.feedEntry.message) &&
                <Text
                  margin='10px 0 0 0'
                >
                  {this.props.feedEntry.message}
                </Text>
            }
          </UserSection>
          <BottomBar>
            {
              !this.props.feedEntry.private &&
                <CommentsButton
                  onClick={() => this.props.toggleCommentsExpanded()}
                  multipleComments={this.props.feedEntry.comments.length > 0}
                >
                  {this.props.feedEntry.comments.length} Comments
                </CommentsButton>
            }
            {
              this.props.feedEntry.private &&
                <PrivateLabel>
                  Private
                </PrivateLabel>
            }
          </BottomBar>
          {
            !this.props.feedEntry.private && this.props.commentsExpanded &&
              <CommentCreate
                showXp
                languageId={this.props.feedEntry.languageId}
                namespace={this.props.feedEntry.id}
                commentable={this.props.feedEntry}
                commentableType='FeedEntry'
                afterSubmitComment={this.props.afterChange}
              />
          }
          {
            !this.props.feedEntry.private && this.props.commentsExpanded &&
              <CommentIndex
                languageId={this.props.feedEntry.languageId}
                indentation='30'
                padding='0'
                margin='10px 0 0 0'
                fontSize='14px'
                lineHeight='18px'
                userProfileImageSize={30}
                feedEntryId={this.props.feedEntry.id}
                user={AppUI.user}
                commentable={this.props.feedEntry}
                commentableType='FeedEntry'
                afterCreateComment={this.props.onChange}
                onDeleteComment={this.props.onDeleteComment}
              />
          }
        </Card>
      </Wrapper>
    );
  }
}

export default FeedEntryWrapper(FeedEntry);
