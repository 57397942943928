import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import SentenceIndex from 'pages/builder/sentences/sentence_index';
import { CreatorUI } from '@seedlang/state';
import SentenceStudio from 'components/sentence_studio/sentence_studio';
import autobind from 'autobind-decorator';
import { isPresent } from '@seedlang/utils';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  .sentences {
    display: flex;
    .left-column {
      flex: 1;
    }
    .right-column {
      margin-left: 10px;
      width: 460px;
    }
  }
`;

@observer
class Sentences extends React.Component {
  @observable selectedSentenceIndex;

  constructor(props) {
    super(props);
    CreatorUI.levelStore.getIndex();
    CreatorUI.setOnChangeCreatorCallback(this.changeCreator);
  }

  @autobind changeCreator() {
    CreatorUI.sentenceStore.reloadIndexFiltered();
  }

  @autobind onClickIcon(index) {
    if (index === this.selectedSentenceIndex) {
      this.selectedSentenceIndex = null;
    } else {
      this.selectedSentenceIndex = index;
    }
  }

  @autobind afterCreateVideo(resp) {
    if (CreatorUI.autoAdvanceWord && resp.hasAllVideoClips && this.selectedSentenceIndex < CreatorUI.sentenceStore.indexData.length - 1) {
      this.selectedSentenceIndex += 1;
    }
    else if (CreatorUI.autoAdvanceWord && resp.hasAllVideoClips && this.selectedSentenceIndex === CreatorUI.sentenceStore.indexData.length - 1) {
      this.selectedSentenceIndex = null;
    }
  }

  render() {
    return (
      <Wrapper>
        <div className='sentences'>
          <div className='left-column'>
            <SentenceIndex
              simplifiedCreate
              // hideFilter={isPresent(this.selectedSentenceId)}
              hide={['creator', 'isAnswer', 'createdAt', 'reviewed', 'tag', 'ENSource']}
              store={CreatorUI.sentenceStore}
              routeName='creator.sentences.edit'
              filterNamespace='creator-sentence-index'
              icon={<i className='fa fa-microphone fa-block fa-on-click' />}
              onClickIcon={this.onClickIcon}
            />
          </div>
          {
            isPresent(this.selectedSentenceIndex) &&
              <div className='right-column'>
                <SentenceStudio
                  sentenceId={CreatorUI.sentenceStore.hasIndexData && CreatorUI.sentenceStore.indexData[this.selectedSentenceIndex].id}
                  width={300}
                  afterCreateVideoCallback={this.afterCreateVideo}
                />
              </div>
          }
        </div>
      </Wrapper>
    );
  }
}

export default Sentences;
