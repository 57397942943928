import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import FaqCategoryCreate from 'pages/builder/faq_categories/faq_category_create';
import { FaqCategoryStore } from '@seedlang/stores';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`

`;

@observer
class FaqCategoryIndex extends React.Component {

  constructor(props) {
    super(props);
    FaqCategoryStore.getIndex();
  }

  @autobind onDelete(id) {
    FaqCategoryStore.destroy({ids: {faqCategoryId: id}});
  }

  render() {
    return (
      <Wrapper>
        <FaqCategoryCreate />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th width='80'>Pos</th>
              <th>Name</th>
              <th>Slug</th>
              <th>Category Group</th>
              <th>Published</th>
              <th>Count</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
          {
            FaqCategoryStore.indexData.map(item => {
              return (
                <tr key={item.id}>
                  <td>
                    <Link
                      to={{ name: 'builder.faq_categories.edit', params: { faqCategoryId: item.id } }}
                      className='on-click'
                    >
                      <i className='fa fa-chevron-right fa-block' />
                    </Link>
                  </td>
                  <td>
                    <InPlaceText
                      model='faq_categories'
                      field='position'
                      id={item.id}
                      defaultValue={item.position}
                      afterChange={() => FaqCategoryStore.getIndex()}
                    />
                  </td>
                  <td>
                    {item.name}
                  </td>
                  <td>
                    {item.slug}
                  </td>
                  <td>
                    {item.categoryGroup}
                  </td>
                  <td>
                    <InPlaceCheckbox
                      model='faq_categories'
                      id={item.id}
                      value={item.published}
                      field='published'
                      icon='check'
                    />
                  </td>
                  <td>
                    {item.faqItemsCount}
                  </td>
                  <td>
                    <DeleteButton
                      onConfirm={() => this.onDelete(item.id)}
                      message='Delete this faq category?'
                      className='fa-block right'
                    />
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FaqCategoryIndex);
