import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AffiliatePayoutStore , PaymentStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { Link } from 'react-router';
import CheckIfTrue from 'components/check_if_true';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`

`;

const LinkWrapper = styled.div`
  margin: 0 0 10px 0;
  i {
    margin: 0 5px 0 0;
  }
`;

@observer
class AffiliatePayoutShow extends React.Component {

  constructor(props) {
    super(props);
    AffiliatePayoutStore.getShow({ids: {affiliatePayoutId: this.props.params.affiliatePayoutId}});
    PaymentStore.getIndex({queryStrings: {affiliate_payout_id: this.props.params.affiliatePayoutId}});
  }

  render() {
    return (
      <Wrapper>
        <LinkWrapper>
          <Link
            to={{name: 'builder.affiliate_payouts.index'}}
          >
            <i className='fa fa-angle-left' />
            Payouts
          </Link>
        </LinkWrapper>
        {
          AffiliatePayoutStore.hasShowData &&
            <div>
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>User</legend>
                    {AffiliatePayoutStore.showData.user && AffiliatePayoutStore.showData.user.name}
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Date</legend>
                    {AffiliatePayoutStore.showData.occurredAt.formattedDateWithYear.toString().replace('01 ', '')}
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Paid</legend>
                    <InPlaceCheckbox
                      model='affiliate_payouts'
                      field='paid'
                      id={AffiliatePayoutStore.showData.id}
                      value={AffiliatePayoutStore.showData.paid}
                      icon='check'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Amount</legend>
                    {AffiliatePayoutStore.showData.formattedAmount}
                  </fieldset>
                </div>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>USD to EUR Rate</legend>
                    {AffiliatePayoutStore.showData.usdToEurRate}
                  </fieldset>
                </div>
              </div>
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Payments</legend>
              <table
                className='table-wrapper'
                style={{marginTop: 10}}
              >
                <thead>
                  <tr>
                    <th />
                    <th>Date</th>
                    <th>User</th>
                    <th>Amount</th>
                    <th>Affiliate</th>
                    <th>Promotion</th>
                    <th>Refunded</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    PaymentStore.indexData.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Link
                              to={{name: 'builder.payments.edit', params: { subscriptionId: this.props.subscriptionId, paymentId: item.id } }}
                              className='on-click'
                            >
                              <i className='fa fa-chevron-right fa-block' />
                            </Link>
                          </td>
                          <td>
                            {item.occurredAt.formatted}
                          </td>
                          {
                            !this.props.subscriptionId &&
                              <td>
                                <Link
                                  to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                                >
                                  {item.user.name}
                                </Link>
                              </td>
                          }
                          <td>
                            {item.amountFormatted}
                          </td>
                          <td>
                            {isPresent(item.affiliateOwner) ? item.affiliateOwner.name : null}
                          </td>
                          <td>
                            {isPresent(item.promotion) ? item.promotion.name : null}
                          </td>
                          <td>
                            <CheckIfTrue
                              value={item.refunded}
                            />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
              <Paginator
                store={PaymentStore}
                queryStrings={{affiliate_payout_id: this.props.params.affiliatePayoutId}}
              />
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(AffiliatePayoutShow);
