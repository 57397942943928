import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { ErrorLogStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';
import Filter from 'components/backend_filter/filter';
import autobind from 'autobind-decorator';
import Copy from 'components/copy';

const Item = styled.div`
  border-bottom: 1px solid #CCC;
  margin-bottom: 10px;
  background: #FFF;
  padding: 10px 20px;
  font-size: 14px;
`;

const Row = styled.div`
  display: flex;
  padding: 5px 0;
  .link {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Label = styled.div`
  width: 120px;
  font-weight: bold;
`;

const Value = styled.div`
  flex: 1;
`;

const ValueWrapper = styled.div`
  max-width: 600px;
  overflow-y: auto;
`;

@observer
class ErrorLogIndex extends React.Component {
  @observable params = [];
  @observable headers = [];
  @observable stacktraces = [];

  componentDidMount() {
    ErrorLogStore.getIndex({queryStrings: {user_id: this.props.userId}});
  }

  @autobind onToggleHeaders(id) {
    // tried to remove the duplication, but this[aryName] wasn't registering the update in the component
    if (this.headers.includes(id)) {
      this.headers = this.headers.filter(item => item !== id);
    } else {
      this.headers.push(id);
    }
  }

  @autobind onToggleParams(id) {
    if (this.params.includes(id)) {
      this.params = this.params.filter(item => item !== id);
    } else {
      this.params.push(id);
    }
  }

  @autobind onToggleStacktraces(id) {
    if (this.headers.includes(id)) {
      this.stacktraces = this.stacktraces.filter(item => item !== id);
    } else {
      this.stacktraces.push(id);
    }
  }

  render() {
    return (
      <div className='click-index'>
        {
          !this.props.userId &&
            <Filter
              store={ErrorLogStore}
              defaultSort='-created_at'
              namespace='error-log-index'
              filters={
              [
                {
                  type: 'text',
                  name: 'controller',
                  labelField: 'Controller',
                  default: '',
                },
                {
                  type: 'text',
                  name: 'message',
                  labelField: 'Message',
                  default: '',
                },
                {
                  type: 'text',
                  name: 'params',
                  labelField: 'Params',
                  default: '',
                },
              ]}
            />
        }
        {
          ErrorLogStore.indexData.map(item => {
            return (
              <Item
                key={item.id}
              >
                <Row>
                  <Label>
                    ID
                  </Label>
                  <Value>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <Copy text={item.id} /><div style={{marginLeft: '5px'}}>{item.id}</div>
                    </div>
                  </Value>
                </Row>
                <Row>
                  <Label>
                    Controller
                  </Label>
                  <Value>
                    {item.controller}
                  </Value>
                </Row>
                <Row>
                  <Label>
                    Date
                  </Label>
                  <Value>
                    {item.createdAt.formattedWithSeconds}
                  </Value>
                </Row>
                {
                  isPresent(item.userEmail) && isPresent(item.userId) &&
                    <Row>
                      <Label>
                        User
                      </Label>
                      <Value>
                        <Link
                          to={{name: 'builder.users.edit', params: {userId: item.userId}}}
                        >
                          {item.userEmail}
                        </Link>
                      </Value>
                    </Row>
                }
                {
                  item.message &&
                    <Row>
                      <Label>
                        Message
                      </Label>
                      <Value>
                        {item.message}
                      </Value>
                    </Row>
                }
                {
                  item.params &&
                    <Row>
                      <Label>
                        Params
                      </Label>
                      <Value>
                        <Link
                          className='link'
                          onClick={() => this.onToggleParams(item.id)}
                        >
                          {this.params.includes(item.id) ? 'Hide' : 'Show'}
                        </Link>
                        {this.params.includes(item.id) && item.params}
                      </Value>
                    </Row>
                }
                {
                  item.stacktrace &&
                    <Row>
                      <Label>
                        Stack Trace
                      </Label>
                      <Value>
                        <Link
                          className='link'
                          onClick={() => this.onToggleStacktraces(item.id)}
                        >
                          {this.stacktraces.includes(item.id) ? 'Hide' : 'Show'}
                        </Link>
                        {this.stacktraces.includes(item.id) && item.stacktrace}
                      </Value>
                    </Row>
                }
                {
                  item.headers &&
                    <Row>
                      <Label>
                        Headers
                      </Label>
                      <ValueWrapper>
                        <Link
                          className='link'
                          onClick={() => this.onToggleHeaders(item.id)}
                        >
                          {this.headers.includes(item.id) ? 'Hide' : 'Show'}
                        </Link>
                        {
                          this.headers.includes(item.id) &&
                            <Value
                              dangerouslySetInnerHTML={{ __html: item.headers }}
                            />
                        }
                      </ValueWrapper>
                    </Row>
                }
              </Item>
            );
          })
        }
        <Paginator
          store={ErrorLogStore}
          queryStrings={{userId: this.props.userId}}
        />
      </div>
    );
  }
}

export default ErrorLogIndex;
