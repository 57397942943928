import React from 'react';
import { observer } from 'mobx-react';
import ProgressBar from 'components/progress_bar';
import ReactTooltip from 'react-tooltip';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import { BadgeWrapper } from '@seedlang/hoc';
import { depixify, kFormatter, pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  ${flexCenter()}
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin};
  flex-direction: ${props => props.alignment === 'vertical' ? 'column' : 'row'};
  position: relative;
`;

const BadgeIconWrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.width};
  ${flexCenter()}
  position: relative;
  border: 3px solid ${props => props.fill};
  border-radius: 50%;
  svg {
    width: ${props => props.svgWidth};
    #background {
      fill: ${props => props.fill};
    }
  }
`;

const LevelIndicator = styled.div`
  z-index: ${Theme.z['foreground']};
  background: ${props => props.background};
  ${flexCenter()}
  color: white;
  height: 20px;
  width: 20px;
  position: absolute;
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  font-weight: bold;
  border-radius: 50%;
  border: 2px solid #FFF;
`;

const MainIcon = styled.div`
  z-index: ${Theme.z['background']};
`;

const Info = styled.div`
  flex: 1;
  margin: 0 0 0 10px;
`;

@observer
class Badge extends React.Component {

  static defaultProps = {
    alignment: 'horizontal',
    margin: '0 0 10px 0',
    fontSize: 16,
    textMarginBottom: 3,
    bottom: '1px',
    right: '1px',
  }

  render() {
    return (
      <div
        style={{width: this.props.badgeWidth}}
        data-tip
        data-for={this.props.uuid}
      >
        <Wrapper
          alignment={this.props.alignment}
          margin={this.props.margin}
          width={this.props.wrapperWidth}
          height={this.props.wrapperHeight}
        >
          <BadgeIconWrapper
            fill={this.props.badgeColor}
            width={this.props.width}
            svgWidth={pixify(depixify(this.props.width) - 10)}
            height={this.props.height}
          >
            <MainIcon>
              {this.props.icon}
            </MainIcon>
            {
              this.props.currentLevel > 0 &&
                <LevelIndicator
                  background={this.props.badgeColor}
                  bottom={this.props.bottom}
                  right={this.props.right}
                >
                  {this.props.levelRoman}
                </LevelIndicator>
            }
          </BadgeIconWrapper>
          <Info>
            <Text
              bold
              fontSize={pixify(this.props.fontSize)}
              lineHeight='20px'
              marginTop={this.props.textMarginTop}
              marginBottom={this.props.textMarginBottom}
            >
              {this.props.name}
            </Text>
            {
              this.props.showProgress &&
                <ProgressBar
                  current={this.props.progressCurrent}
                  total={this.props.progressTotal}
                  width='100%'
                  borderRadius='5px'
                  height='12px'
                />
            }
          </Info>
        </Wrapper>
        <ReactTooltip
          place='top'
          type='dark'
          effect='solid'
          delayHide={1}
          delayUpdate={1}
          id={this.props.uuid}
          class='custom-tooltip'
        >
          <div>
            {this.props.description}: {kFormatter(this.props.current)}
          </div>
          <div>
            Next level: {kFormatter(this.props.currentGoal)}
          </div>
        </ReactTooltip>
      </div>
    );
  }
}

export default BadgeWrapper(Badge);
