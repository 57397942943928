import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import ExerciseProgress from 'components/exercise/exercise_progress';
import { flexCenter } from '@seedlang/style_mixins';
import Text from 'components/text';
import { noop } from 'lodash';
import { AppUI, ExerciseUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isPresent , isBlank } from '@seedlang/utils';
import WorksheetFilter from 'components/worksheet/worksheet_filter';
import Spinner from 'components/spinner';
import ExerciseTags from 'components/exercise/exercise_tags';
import { Theme } from '@seedlang/constants';
import cx from 'classnames';
import { LockIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TableWrapper = styled.div`
  flex: 1;
`;

const Table = styled.div`
  border-radius: ${props => props.borderRadius};
  overflow: hidden;
`;

const Row = styled.div`
  ${flexCenter()}
  position: relative;
  background: ${props => props.header ? '#CCC' : '#FFF'};
  cursor: ${props => props.header ? 'default' : 'pointer'};
  border-radius: ${props => props.borderRadius};
  margin: ${props => props.margin};
  padding: ${props => props.padding || '10px'};
  &:hover {
    background: ${props => props.header ? '#CCC' : 'whitesmoke'};
    .grayscale {
      filter: none;
    }
  }
`;

const Exercise = styled.div`
  flex: 1;
`;

const Score = styled.div`
  width: 80px;
  text-align: center;
`;

const Date = styled.div`
  width: 120px;
  text-align: ${props => props.textAlign || 'center'};
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
  .fa {
    margin-right: 5px;
    padding-top: 3px;
  }
`;

const Tags = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  font-size: 11px;
  padding: 4px 8px;
  border: 1px solid gray;
  border-radius: 3px;
  margin: 1px;
  line-height: 11px;
`;

const TagSection = styled.div`
  width: 200px;
  margin-right: 10px;
`;

const Thumbnail = styled.div`
  height: 60px;
  width: 106px;
  margin-right: 10px;
  background: linear-gradient(-134deg, rgb(216 216 216) 30%, rgb(239 239 239) 100%);
  border-radius: 10px;
  img {
    border-radius: 10px;
    height: 100%;
    cursor: pointer;
  }
  .grayscale {
    filter: gray;
    filter: grayscale(1);
    transition: filter 0.3s ease;
  }
  .pin-icon {
    position: absolute;
    height: 14px;
    width: 14px;
    padding: 5px;
    opacity: 0.7;
    right: 33px;
    top: 22px;
    svg {
      width: 14px;
      fill: #FFF;
    }
  }
`;

const LockIconWrapper = styled.div`
  z-index: ${Theme.z['foreground']};
  width: 20px;
  height: 20px;
  border-radius: 8px;
  background: #777777;
  position: absolute;
  top: 1px;
  left: 1px;
  pointer-events: none;
  .icon-wrapper {
    svg {
      fill: #FFF;
      width: 8px;
      position: absolute;
      left: 6px;
      top: 4px;
    }
  }
`;

@observer
class ExerciseIndex extends React.Component {

  @observable searchQuery = null;
  @observable sort = '-worksheets.published_at';

  @computed get paginatorIds() {
    const params = {groupId: this.props.params.groupId};
    if (this.selectedTagIds) {
      params['tag_ids'] = this.selectedTagIds;
    }
    return params;
  }

  constructor(props) {
    super(props);
    if (!AppUI.tagCategoryStore.hasIndexData) {
      AppUI.tagCategoryStore.getIndex();
    }
    this.sort = isPresent(ExerciseUI.userGroup?.exerciseSort) && !AppUI.layout.isMobile ? ExerciseUI.userGroup?.exerciseSort : '-worksheets.published_at';
    if (!ExerciseUI.exerciseStore.hasIndexData) {
      this.getExercises();
    }
    AppUI.createEvent('worksheets - view exercise list');
  }

  @autobind getExercises() {
    const params = {filters: {}, sort: this.sort};
    if (this.searchQuery) {
      params['filters']['exercises.name'] = `~${this.searchQuery}~`;
    } else if (isBlank(this.searchQuery) && ExerciseUI.exerciseStore.hasFilterValue('exercises.name')) {
      ExerciseUI.exerciseStore.removeFilter('exercises.name');
    }
    if (this.sort !== ExerciseUI.exerciseStore.sort) {
      ExerciseUI.userGroupStore.update({data: {exerciseSort: this.sort}, ids: {userGroupId: ExerciseUI.userGroup?.id}});
    }
    ExerciseUI.exerciseStore.setPage(1);
    ExerciseUI.exerciseStore.getIndex(params);
  }

  @computed get filteredTagCategories() {
    return AppUI.tagCategoryStore.indexData?.filter(item => item.published && item.isExerciseTag);
  }

  @autobind onSearchQueryChange(val) {
    this.searchQuery = val;
    clearInterval(this.interval);
    if (isBlank(val)) {
      this.getExercises();
    }
    if ( isBlank(val) || val.length > 1) {
      this.interval = setTimeout(this.getExercises, 500);
    }
  }

  @autobind toggleSort(field) {
    if (this.sort.endsWith(field)) {
      this.sort = this.sort.startsWith('-') ? field : `-${field}`;
    } else {
      this.sort = `-${field}`;
    }
    this.getExercises();
  }

  render() {
    return (
      <Wrapper>
        <WorksheetFilter
          showFilters
          isExerciseFilter
          onUpdateFilter={this.getExercises}
          onSearchQueryChange={this.onSearchQueryChange}
          searchQuery={this.searchQuery}
          routeName={this.props.route?.name}
          showProgressTag
          filteredTagCategories={this.filteredTagCategories}
          allTagCategories={AppUI.tagCategoryStore.indexData}
          hideSort
        />
        <TableWrapper>
          <Table
            borderRadius={AppUI.layout.isMobile ? '0' : '10px'}
          >
            <Row
              header
            >
              <Exercise>
                <Text
                  bold
                  fontSize='14px'
                >
                  Exercise
                </Text>
              </Exercise>
              <Date
                onClick={() => this.toggleSort('worksheets.published_at')}
                clickable
                textAlign={AppUI.layout.isMobile ? 'right' : 'center'}
              >
                <Text
                  bold
                  fontSize='14px'
                >
                  {
                    this.sort.endsWith('worksheets.published_at') &&
                      <i className={cx('fa', {
                        'fa-caret-down': this.sort.startsWith('-'),
                        'fa-caret-up': !this.sort.startsWith('-'),
                      })}
                      />
                  }
                  Post Date
                </Text>
              </Date>
              {
                !AppUI.layout.isMobile &&
                  <Date
                    onClick={() => this.toggleSort('user_exercise_updated_at')}
                    clickable
                  >
                  <Text
                    bold
                    fontSize='14px'
                  >
                    {
                      this.sort.endsWith('user_exercise_updated_at') &&
                        <i className={cx('fa', {
                          'fa-caret-down': this.sort.startsWith('-'),
                          'fa-caret-up': !this.sort.startsWith('-'),
                        })}
                        />
                    }
                    Last Attempt
                  </Text>
                </Date>
              }
              {
                !AppUI.layout.isMobile &&
                  <Score>
                    <Text
                      bold
                      fontSize='14px'
                    >
                      My Score
                    </Text>
                  </Score>
              }
            </Row>
            {
              ExerciseUI.exerciseStore.requestCounter > 0 &&
                <Row
                  borderRadius={!ExerciseUI.exerciseStore.hasIndexData && AppUI.layout.isDesktop ? '0 0 10px 10px' : ''}
                >
                  <Spinner margin='30px auto' background={ExerciseUI.site.accentColor || Theme.blue} />
                </Row>
            }
            {
              ExerciseUI.exerciseStore.requestCounter === 0 && !ExerciseUI.exerciseStore.hasIndexData &&
                <Row
                  borderRadius={AppUI.layout.isDesktop ? '0 0 10px 10px' : ''}
                >
                  <Text>
                    No exercises found
                  </Text>
                </Row>
            }
            {
              ExerciseUI.exerciseStore.indexData.map((item, index) => {
                return (
                  <React.Fragment key={`exercise-${item.id}`}>
                    <Row
                      key={item.id}
                      padding={AppUI.layout.isMobile ? '10px 10px 5px 10px' : '10px'}
                      onClick={() => ExerciseUI.routeStore.routeToNamed('exercises.show', {worksheetId: item.worksheetId, exerciseId: item.id, namespace: 'exercises'})}
                      borderRadius={index === ExerciseUI.exerciseStore.indexData.length - 1 && !AppUI.layout.isMobile ? '0 0 10px 10px' : ''}
                    >
                      {
                        !ExerciseUI.hasWorksheetAccess(item.worksheet) &&
                          <LockIconWrapper>
                            <LockIcon />
                          </LockIconWrapper>
                      }
                      <div style={{display: 'flex', flex: 1}}>
                        {
                          !AppUI.layout.isMobile &&
                            <Thumbnail>
                              {
                                isPresent(item.worksheet?.imageUrl) &&
                                  <img
                                    alt=''
                                    src={item.worksheet?.imageUrl}
                                    className={cx({grayscale: !ExerciseUI.hasWorksheetAccess(item.worksheet)})}
                                  />
                              }
                            </Thumbnail>
                        }
                        <Exercise>
                          <Text
                            bold
                          >
                            {item.name}
                          </Text>
                          {
                            item.worksheet?.name &&
                              <Text fontSize='14px' lineHeight='14px' color='#a8a8a8'>
                                {item.worksheet?.name}
                              </Text>
                          }
                          <ExerciseTags compact exercise={item} />
                        </Exercise>
                      </div>
                      <Date
                        textAlign={AppUI.layout.isMobile ? 'right' : 'center'}
                      >
                        <Text
                          italic
                          fontSize='14px'
                        >
                          {item.worksheet && item.worksheet.publishedAtDate}
                        </Text>
                      </Date>
                      {
                        !AppUI.layout.isMobile &&
                          <Date>
                            <Text
                              italic
                              fontSize='14px'
                            >
                              {item.worksheet && item.userExerciseUpdatedAt?.formattedDateWithYear}
                            </Text>
                          </Date>
                      }
                      {
                        !AppUI.layout.isMobile &&
                          <Score>
                            <ExerciseProgress
                              largeVersion
                              current={item.exerciseEntriesCount === 0 && item.completed ? 1 : item.correctAnswersCount}
                              total={item.exerciseEntriesCount === 0 ? 1 : item.exerciseEntriesCount}
                            />
                          </Score>
                      }
                    </Row>
                    {
                      AppUI.layout.isMobile &&
                        <ExerciseProgress
                          key={`progress-${item.id}`}
                          hideCounts
                          noBorderRadius={index != ExerciseUI.exerciseStore.indexData.length - 1 || AppUI.layout.isMobile}
                          current={item.exerciseEntriesCount === 0 && item.completed ? 1 : item.correctAnswersCount}
                          total={item.exerciseEntriesCount === 0 ? 1 : item.exerciseEntriesCount}
                        />
                    }
                  </React.Fragment>
                );
              })
            }
            <Paginator
              onClickScrollToTop
              withSpinner
              store={ExerciseUI.exerciseStore}
              ids={this.paginatorIds}
              margin='20px 0'
            />
          </Table>
        </TableWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseIndex);
