import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { AppUI, TreeUI } from '@seedlang/state';
import Module from 'components/module';
import styled from '@emotion/styled';
import TreeNodeIcon from 'components/tree_node/tree_node_icon';
import { isPresent, pixify } from '@seedlang/utils';
import Text from 'components/text';
import { flexCenterColumn } from '@seedlang/style_mixins';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { TreeShowWrapper } from '@seedlang/hoc';
import Spinner from 'components/spinner';
import { Theme } from '@seedlang/constants';
import isBlank from 'is-blank';

const Wrapper = styled.div`
  flex: 1;
`;

const TextWrapper = styled.div`
  ${flexCenterColumn()}
  width: ${props => props.width};
  margin: 20px 0 0 0;
`;

const TreeRow = styled.div`
  display: flex;
  justify-content: center;
`;

const NextTreeNode = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .fa {
    position: absolute;
    left: 10px;
    font-size: 24px;
  }
`;

@observer
class TreeShow extends React.Component {

  render() {
    return (
      <Wrapper
        id='tree-body'
      >
        {
          AppUI.userIsLoaded && this.props.showLevelWarning && TreeUI.storiesTree && TreeUI.storiesTree.languageId === 'DE' &&
            <PageOnboarding
              fatCloseIcon
              className='primary-page-onboarding'
              uiElement='level-warning'
            >
              {isPresent(AppUI.targetLanguage?.level) ? `Your level is ${AppUI.targetLanguage.level.abbreviation}. ` : ''}Our content tree starts at A1 and goes to B2. You can either start at the beginning and review easier content, or skip ahead to content that is more appropriate for your level.
            </PageOnboarding>
        }
        {
          AppUI.layout.iPadSafari &&
            <PageOnboarding
              hideCloseIcon
              background={Theme.orange}
              color='#FFF'
            >
              We are sorry, but our Stories section only works with the <b>Chrome browser</b> on an iPad. Please switch to a Chrome browser to continue.
            </PageOnboarding>
        }
        {
          !TreeUI.storiesTree &&
            <Spinner />
        }
        {
          isPresent(this.props.nextTreeNode) &&
          <PageOnboarding
            hideCloseIcon
            background={Theme.green}
            color='#FFF'
          >
            <NextTreeNode
              onClick={this.props.scrollToNextTreeNode}
            >
              <i className='fa fa-angle-double-down'/>
              Go to Your Next Story:&nbsp;<b>{this.props.nextTreeNode?.name}</b>
            </NextTreeNode>
          </PageOnboarding>
        }
        {
          TreeUI.showStoriesNewLabel && isBlank(this.props.nextTreeNode) &&
          <PageOnboarding
            hideCloseIcon
            background={Theme.green}
            color='#FFF'
          >
            <NextTreeNode
              onClick={this.props.scrollToNewTreeNode}
            >
              <i className='fa fa-angle-double-down'/>
              Go to Our New Story
            </NextTreeNode>
          </PageOnboarding>
        }
        {
          TreeUI.storiesTree && TreeUI.storiesTree.treeModules.map(treeModule => {
            if (treeModule.webPublished || AppUI.user.admin) {
              return (
                <Module
                  key={treeModule.id}
                  title={treeModule.name}
                  slug={treeModule.id}
                  forceExpand={treeModule.id === this.props.currentTreeModuleId}
                >
                    {
                      treeModule.treeRows.map(treeRow => {
                        return (
                          <TreeRow
                            key={treeRow.id}
                          >
                            {
                              treeRow.treeNodes.map(treeNode => {
                                if (treeNode.webPublished || AppUI.user.admin) {
                                  const userTreeNode = TreeUI.userTreeNodeStore.indexData.find(item => item.treeNodeId === treeNode.id);
                                  return (
                                    <TreeNodeIcon
                                      key={treeNode.id}
                                      treeNode={treeNode}
                                      width={this.props.treeNodeIconWidth}
                                      height={this.props.treeNodeIconWidth}
                                      userTreeNode={userTreeNode}
                                      finishedUserDecksCount={userTreeNode ? userTreeNode.finishedUserDecksCount : 0}
                                      className={treeNode.webPublished && treeNode.webPublishedInLastWeek ? 'new' : null}
                                    >
                                      <TextWrapper
                                        width={pixify(this.props.treeNodeIconWidth)}
                                      >
                                        <Text
                                          bold
                                          center
                                          fontSize='15px'
                                          lineHeight='17px'
                                        >
                                          {treeNode.name}
                                        </Text>
                                      </TextWrapper>
                                    </TreeNodeIcon>
                                  );
                                } else {
                                  return null;
                                }
                              })
                            }
                          </TreeRow>
                        );
                      })
                    }
                </Module>
              );
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default TreeShowWrapper(TreeShow);
