import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI, ExerciseUI } from '@seedlang/state';
import { isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import InfoTooltip from 'components/info_tooltip';
import Modal from 'components/modal';
import WorksheetBackButton from 'components/worksheet/breadcrumbs';
import NoAccessButton from 'components/worksheet/no_access_button';
import PodcastButton from 'components/worksheet/podcast_button';
import Tags from 'components/worksheet/tags';
import { startCase } from 'lodash';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import CompletedButton from './completed_button';

const Wrapper = styled.div`
  flex: 2;
  height: ${props => props.height};
  max-height: ${props => props.maxHeight};
  padding: 20px;
  position: relative;
  background: white;
  border-radius: ${props => props.borderRadius};
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

const Title = styled.div`
`;

const TitleText = styled.div`
  font-size: 28px;
  line-height: 32px;
  font-weight: 900;
  @media (max-width: 1200px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  overflow-y: hidden;
  gap: 10px;
`;

const overflowCss = `
  box-shadow: inset 0 0 20px #d8d8d8;
  .fa {
    transform: scale(1.2);
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  background: #f1f3f5;
  position: relative;
  padding: 10px 15px;
  margin-top: 10px;
  max-height: ${props => props.maxHeight};
  border-radius: 10px;
  font-size: 16px;
  line-height: 22px;
  width: 100%;
  cursor: ${props => props.overflows ? 'pointer' : null};
  &:hover {
    ${props => props.overflows ? overflowCss : null}
  }
`;

const GradientWrapper = styled.div`
  -webkit-mask-image:  ${props => props.overflows ? 'linear-gradient(to bottom, black 50%, transparent);' : null};
`;

const Description = styled.div`
  p, li {
    margin-block-start: 2px;
    margin-block-end: 2px;
  }
  li {
    list-style-type: disc;
    margin-left: 20px;
  }
  h1 {
    font-size: 26px;
  }
  h2 {
    font-size: 22px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-family: 'Karla', sans-serif !important;
    margin-block-start: 15px;
    margin-block-end: 5px;
    font-weight: 600;
  }
  a {
    color: ${props => props.linkColor};
    font-weight: 600;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
`;

const Buttons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${props => props.gap};
  margin-top: 10px;
  margin-right: 5px
`;

const ExpandIcon = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 14px;
  position: absolute;
  cursor: pointer;
  right: 5px;
  bottom: 10px;
  color: #636363;
  cursor: pointer;
  background: #f1f3f5;
  padding: 2px 10px;
  box-shadow: 0 0 7px 2px #f1f3f5;
  border-radius: 10px;
  .fa {
    margin-left: 10px;
    font-size: 18px;
  }
`;

const PublicationDate = styled.div`
  font-size: 14px;
  color: #636363;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
`;

const SubtitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const AdminLink = styled.div`
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
`;

const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

@observer
class WorksheetDescription extends React.Component {
  @observable descriptionOverflows = false;
  @observable showModal = false;
  @observable namespace;

  componentDidMount() {
    const el = document.getElementById('description-wrapper');
    this.descriptionOverflows = el?.scrollHeight > el?.offsetHeight;
    this.namespace = this.props.namespace;
  }

  componentDidUpdate(prevProps) {
    if (isPresent(this.props.namespace) && prevProps.namespace !== this.props.namespace) {
      this.namespace = this.props.namespace;
    }
  }

  @computed get descriptionWithTargetBlankLinks() {
    const descriptionElement = new DOMParser().parseFromString(ExerciseUI.worksheet.description, 'text/html');
    if (descriptionElement && descriptionElement.body) {
      const links = descriptionElement.body.getElementsByTagName('a');
      for (let i = 0; i < links.length; i++) {
        links[i].setAttribute('target', '_blank');
      }
    }
    return new XMLSerializer().serializeToString(descriptionElement);
  }

  @computed get height() {
    if (isBlank(ExerciseUI.worksheet.description)) {
      return 'fit-content';
    }
    if (AppUI.layout.isMobile) {
      if (ExerciseUI.worksheet.youtubeUrlIsValid) {
        return '400px';
      }
      return '500px';
    }
    if (ExerciseUI.worksheet.youtubeUrlIsValid && isPresent(ExerciseUI.worksheet.podcastEpisode)) {
      return '400px';
    }
    if (ExerciseUI.worksheet.youtubeUrlIsValid) {
      return '340px';
    }
    if (isPresent(ExerciseUI.worksheet.podcastEpisode)) {
      return '450px';
    }
  }

  @computed get descriptionMaxHeight() {
    if (AppUI.layout.isMobile && !ExerciseUI.worksheet.youtubeUrlIsValid) {
      return '450px';
    }
    return '350px';
  }

  @autobind goBackToPosts() {
    ExerciseUI.routeStore.routeToNamed(`${this.props.namespace || ExerciseUI.worksheetNamespace}.index`, {groupId: ExerciseUI.groupId});
  }

  _button(buttonText, iconName, params) {
    return (
      <a
        href={params.href}
        download={params.download}
        target='_blank'
        rel='noopener noreferrer'
      >
        <Button
          height='35px'
          fontSize={ExerciseUI.layout.isMobile ? '12px' : '14px'}
          color='#333'
          borderRadius='10px'
          background='#f1f3f5'
          backgroundOnHover='#c7c7c7'
          onClick={params.onClick}
        >
          {
            isPresent(iconName) &&
            <i className={`fa fa-${iconName}`}/>
          }
          {!(params.hideTextOnMobile && AppUI.layout.isMobile) && buttonText.toUpperCase()}
        </Button>
      </a>
    );
  }

  render() {
    if (ExerciseUI.hasWorksheet) {
      return (
        <Wrapper
          maxHeight={this.height}
          height='auto'
          borderRadius={this.props.borderRadius}
        >
          <HeaderRow>
            {
              ExerciseUI.layout.isDesktop &&
                <WorksheetBackButton
                  namespace={this.namespace}
                />
            }
            {
              ExerciseUI.layout.isDesktop && ExerciseUI.user.anyAdmin && ExerciseUI.worksheet && ExerciseUI.worksheet.groupId &&
              <AdminLink>
                <Link
                  to={{name: 'creator.groups.worksheets.edit', params: {groupId: ExerciseUI.worksheet.groupId, worksheetId: ExerciseUI.worksheet.id}}}
                >
                  Admin Link
                </Link>
              </AdminLink>
            }
          </HeaderRow>
          {
            this.showModal &&
            <Modal
              animate
              contentShouldScroll
              padding='20px'
              width={AppUI.layout.isMobile ? '90%' : '600px'}
              top={AppUI.layout.isMobile ? '100px' : null}
              maxHeight={AppUI.layout.isMobile ? AppUI.layout.viewportHeight - 200 : AppUI.layout.viewportHeight - 300}
              onCloseModal={() => this.showModal = false}
              iconFill='white'
              title={ExerciseUI.worksheet.nameWithPublished}
              background={AppUI.site.secondaryAccentColor || AppUI.site.accentColor || '#636363'}
            >
                <Description
                  linkColor={AppUI.site.secondaryAccentColor || AppUI.site.accentColor || Theme.blue}
                  dangerouslySetInnerHTML={{ __html: this.descriptionWithTargetBlankLinks }}
                  onClick={() => this.descriptionOverflows ? this.showModal = true : null}
                />
            </Modal>
          }
          <Title>
            <TitleText>{ExerciseUI.worksheet.nameWithPublished}</TitleText>
            <SubtitleRow>
                <PublicationDate
                  data-tip
                  data-for='publication-date'
                >
                  {isPresent(ExerciseUI.worksheet.publishedAt) && ExerciseUI.worksheet.publishedAt.relativeTime}
                  {
                    !ExerciseUI.worksheet.contentPublishedOnADifferentDay &&
                      <ReactTooltip
                        place='right'
                        type='light'
                        effect='float'
                        id='publication-date'
                        class='custom-tooltip'
                      >
                        {isPresent(ExerciseUI.worksheet.publishedAt) && ExerciseUI.worksheet.publishedAt.formattedDateWithYear}
                      </ReactTooltip>
                  }
                  {
                    isPresent(ExerciseUI.worksheet.contentPublishedAt) && ExerciseUI.worksheet.contentPublishedOnADifferentDay &&
                      <InfoTooltip
                        position='right'
                        icon='calendar'
                        color='#636363'
                        margin='0 5px'
                      >
                        <div>{`${startCase(ExerciseUI.worksheet.contentType)} originally published on`}</div>
                        <div>{ExerciseUI.worksheet.contentPublishedAt.fullTextFormattedDateWithYear}</div>
                      </InfoTooltip>
                  }
                  {
                    ExerciseUI.worksheet.episodeCode &&
                      <span>{` · ${ExerciseUI.worksheet.episodeCode}`}</span>
                  }
                </PublicationDate>
                {
                  (isPresent(ExerciseUI.worksheet.worksheetTags) || isPresent(ExerciseUI.worksheet.episodeCode)) &&
                    <Tags
                      width={null}
                      worksheet={ExerciseUI.worksheet}
                      onUpdateFilter={this.goBackToPosts}
                    />
                }
            </SubtitleRow>
          </Title>
          {
            ExerciseUI.hasWorksheet && ExerciseUI.worksheet?.exercises.length === 0 && ExerciseUI.worksheet.hasPdf && !ExerciseUI.worksheet.hasTranscripts &&
              <Buttons
                gap={ExerciseUI.layout.isMobile ? '5px' : '10px'}
              >
                {this._button('exercises', 'file-pdf-o', {href: isPresent(ExerciseUI.worksheet.pdfUrl) ? ExerciseUI.worksheet.pdfUrl : ExerciseUI.worksheet.pdf.url, download: 'Worksheet'})}
              </Buttons>
          }
          {
            ExerciseUI.hasCurrentWorksheetAccess && AppUI.layout.isMobile && ExerciseUI.hasWorksheet && !ExerciseUI.worksheet.youtubeUrlIsValid && isPresent(ExerciseUI.worksheet.podcastEpisode) &&
              <PodcastButton
                hasAccess={ExerciseUI.hasCurrentWorksheetAccess}
                podcastEpisode={ExerciseUI.worksheet.podcastEpisode}
                margin='5px 0 -5px 0'
              />
          }
          {
            ExerciseUI.hasWorksheet && ExerciseUI.worksheet.exerciseEntriesCount === 0 &&
              <CompletedButton
                worksheet={ExerciseUI.worksheet}
              />
          }
          <Content>
            {
              isPresent(ExerciseUI.worksheet.description) &&
              <DescriptionWrapper
                id='description-wrapper'
                overflows={this.descriptionOverflows}
                maxHeight={this.descriptionMaxHeight}
              >
                <GradientWrapper
                  overflows={this.descriptionOverflows}
                >
                  <Description
                    overflows={this.descriptionOverflows}
                    linkColor={AppUI.site.secondaryAccentColor || AppUI.site.accentColor || Theme.blue}
                    dangerouslySetInnerHTML={{ __html: this.descriptionWithTargetBlankLinks }}
                    onClick={() => this.descriptionOverflows ? this.showModal = true : null}
                  />
                </GradientWrapper>
                {
                  this.descriptionOverflows &&
                    <ExpandIcon
                      onClick={() => this.descriptionOverflows ? this.showModal = true : null}
                    >
                      View Show Notes
                      <i className='fa fa-expand' />
                    </ExpandIcon>
                }
              </DescriptionWrapper>
            }
          </Content>
          {
            ExerciseUI.hasWorksheet && !ExerciseUI.hasCurrentWorksheetAccess && AppUI.layout.isMobile &&
            <NoAccessButton
              margin='20px 0 5px 0'
            />
          }
        </Wrapper>
      );
    }
  }
}

export default ErrorBoundary(WorksheetDescription);
