import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import key from 'keymaster';
import Spinner from 'components/spinner';
import { pixify } from '@seedlang/utils';
import { BrokenIcon, PlayIcon, RefreshThickIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { BorderRadius, VideoPlayerWrapper } from '@seedlang/hoc';
import ReactPlayer from 'react-player';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  z-index: ${Theme.z['foreground']};
  border: ${props => props.border};
  width: ${props => props.width};
  height: ${props => props.height};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  top: ${props => props.top};
  position: ${props => props.position};
  border-top-left-radius: ${props => props.borderTopLeftRadius};
  border-top-right-radius: ${props => props.borderTopRightRadius};
  border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
  border-bottom-right-radius: ${props => props.borderBottomRightRadius};
  overflow: ${props => props.borderRadius ? 'hidden' : null};
  cursor: pointer;
  display: inline-block;
  background: #000;
  overflow: hidden;
  color: white;
  .video-js {
      width: 100% !important;
      height: 100% !important;
  }
`;

const ErrorLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 160px;
  height: 160px;
  background: white;
  margin: auto;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 14px;
  line-height: 18px;
  color: #333;
  z-index: ${Theme.z['video-overlay-foreground']};
  padding: 10px;
  .icon-wrapper {
    margin-bottom: 10px;
  }
  svg {
    width: 30px;
  }
`;

const VideoOverlay = styled.div`
  z-index: ${Theme.z['video-overlay-background']};
  background: ${props => props.background};
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
  width: 100%;
  height: 100%;
  .icon-wrapper {
    position: initial;
  }
  .play {
    width: 40%;
    height: 40%;
    svg {
      fill: #FFF;
    }
  }
  .play, .refresh-thick-icon {
    z-index: ${Theme.z['video-overlay-foreground']};
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .refresh-thick-icon {
    width: 40%;
    height: 40%;
    pointer-events: none;
  }
  .refresh-thick-icon {
    svg {
      fill: #FFF;
    }
  }
`;

const SpinnerWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VideoElementWrapper = styled.div`
  background: ${props => props.background};
  width: ${props => props.width};
  height: ${props => props.height};
  text-align: center;
`;

@observer
class VideoPlayer extends React.Component {

  static propTypes = {
    addKeyBindings: PropTypes.bool,
    objectUrl: PropTypes.string,
    videoClip: PropTypes.object,
    maximized: PropTypes.bool,
    loadVideo: PropTypes.bool,
    autoPlay: PropTypes.bool,
    children: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    bottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    left: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    top: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  static defaultProps = {
    maximized: true,
    loadVideo: true,
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 0,
  }

  componentDidMount() {
    if (this.props.addKeyBindings) {
      key('space', () => this.props.onTogglePlay);
    }
  }

  componentWillUnmount() {
    if (this.props.addKeyBindings) {
      key.unbind('space');
    }
  }

  render() {
    return (
      <Wrapper
        border={this.props.border}
        width={pixify(this.props.wrapperWidth || this.props.width)}
        height={pixify(this.props.wrapperWidth || this.props.width)}
        borderTopRightRadius={pixify(this.props.borderTopRightRadius)}
        borderTopLeftRadius={pixify(this.props.borderTopLeftRadius)}
        borderBottomLeftRadius={pixify(this.props.borderBottomLeftRadius)}
        borderBottomRightRadius={pixify(this.props.borderBottomRightRadius)}
        bottom={this.props.bottom}
        left={this.props.left}
        top={this.props.top}
        position={this.props.position}
      >
        {
          this.props.errorLoading &&
            <ErrorLoading>
              <BrokenIcon />
              {AppUI.getErrorMessage('video')}
            </ErrorLoading>
        }
        <VideoElementWrapper
          width={pixify(this.props.width)}
          height={pixify(this.props.width)}
        >
          <ReactPlayer
            playsinline
            ref={this.setUpPlayer}
            controls={false}
            playing={this.props.playing}
            loop={this.props.loop}
            muted={this.props.muted}
            url={this.props.src}
            width={this.props.width}
            height={this.props.width}
            onReady={this.props.onCanPlay}
            onEnded={this.props.onEndPlayback}
            onError={this.props.onError}
          />
        </VideoElementWrapper>
        <VideoOverlay
          onClick={this.props.onTogglePlay}
          background={this.props.playing || this.props.hideOverlay ? 'transparent' : 'rgba(0, 0, 0, 0.5)'}
          height={this.props.width}
        >
          {
            this.props.showSpinnerIcon &&
              <SpinnerWrapper>
                <Spinner
                  margin='0 auto'
                  background='rgba(255, 255, 255, 0.8)'
                />
              </SpinnerWrapper>
          }
          {
            this.props.showRefreshIcon &&
              <RefreshThickIcon
                style={{top: 0}}
              />
          }
          {
            this.props.showPlayIcon &&
              <PlayIcon
                style={{top: 0}}
              />
          }
        </VideoOverlay>
        { this.props.children }
      </Wrapper>
    );
  }
}

export default BorderRadius(VideoPlayerWrapper(VideoPlayer));
