import React from 'react';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ExerciseUI } from '@seedlang/state';
import ExerciseSentence from 'components/exercise/exercise_sentence';
import styled from '@emotion/styled';
import Prompt from 'components/exercise/prompt';
import ExerciseEntry from 'components/exercise/exercise_entry';
import { isBlank, isPresent } from '@seedlang/utils';

const Wrapper = styled.div`

`;

@observer
class ExerciseInputEnter extends React.Component {
  @observable focusedSentenceId;

  @autobind selectFirstSentence() {
    if (isPresent(ExerciseUI.exercise) && ExerciseUI.exercise.exerciseEntries.length > 0) {
      this.focusedSentenceId = ExerciseUI.exercise.exerciseEntries[0].sentence.id;
    }
  }

  componentDidMount() {
    if (isBlank(this.focusedSentenceId)) {
      this.selectFirstSentence();
    }
  }

  componentDidUpdate() {
    if (isBlank(this.focusedSentenceId)) {
      this.selectFirstSentence();
    }
  }

  @autobind focusNextEntry(exerciseEntry) {
    const currentIndex = ExerciseUI.exercise.exerciseEntries.findIndex(item => item.id === exerciseEntry.id);
    if (ExerciseUI.exercise.exerciseEntries.length > currentIndex + 1) {
      const nextEntry = ExerciseUI.exercise.exerciseEntries[currentIndex + 1];
      if (nextEntry) {
        this.focusedSentenceId = nextEntry.sentence.id;
      }
    }
  }

  render() {
    return (
      <Wrapper>
        {
          ExerciseUI.exercise.exerciseEntries.map(item => {
            return (
              <ExerciseEntry
                key={item.id}
                grammarTip={item.grammarTip}
                showButtons={!this.props.inDeck && ExerciseUI.exercise.exerciseEntries.length > 1}
                exerciseEntry={item}
                warningMessages={item.warningMessages}
                afterReveal={() => this.focusNextEntry(item)}
                {...this.props}
              >
                {
                  item.prompt &&
                    <Prompt>
                      {item.prompt}
                    </Prompt>
                }
                {
                  item.sentence &&
                    <ExerciseSentence
                      inputType='text'
                      onFocus={() => this.focusedSentenceId = item.sentence.id}
                      isFocused={this.focusedSentenceId === item.sentence.id}
                      sentence={item.sentence}
                      revealed={item.state.revealed}
                      hideTranslationButton
                      onEnter={() => ExerciseUI.revealExerciseEntryAnswer(item, {}, () => this.focusNextEntry(item))}
                      showAlternateTextFeedback={item.state.correct && item.state.submittedValueMatchesAlternateText}
                    />
                }
              </ExerciseEntry>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ExerciseInputEnter;
