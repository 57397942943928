import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { FaqCategoryStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import FaqItemCreate from 'pages/builder/faq_items/faq_item_create';
import FaqItemIndex from 'pages/builder/faq_items/faq_item_index';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';

@observer
class FaqCategoryEdit extends React.Component {

  constructor(props) {
    super(props);
    this.getFaqCategory();
  }
  @autobind getFaqCategory() {
    FaqCategoryStore.getShow({ ids: {faqCategoryId: this.props.params.faqCategoryId }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    FaqCategoryStore.setShowData(resp);
  }

  render() {
    if (FaqCategoryStore.hasShowData) {
      return (
        <div className='faq-edit'>
          <Link
            to={{name: 'builder.faq_categories.index'}}
            style={{margin: '5px 0'}}
          >
            <i className='fa fa-angle-left' style={{marginRight: 4}} />
            FAQ Category List
          </Link>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Id</legend>
                <InPlaceText
                  defaultValue={FaqCategoryStore.showDataField('id')}
                  model='faq_categories'
                  field='id'
                  id={FaqCategoryStore.showDataField('id')}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-4'>
              <fieldset>
                <legend>Name</legend>
                <InPlaceText
                  defaultValue={FaqCategoryStore.showDataField('name')}
                  model='faq_categories'
                  field='name'
                  id={FaqCategoryStore.showDataField('id')}
                />
              </fieldset>
            </div>
            <div className='col-xs-4'>
              <fieldset>
                <legend>Slug</legend>
                <InPlaceText
                  defaultValue={FaqCategoryStore.showDataField('slug')}
                  model='faq_categories'
                  field='slug'
                  id={FaqCategoryStore.showDataField('id')}
                />
              </fieldset>
            </div>
            <div className='col-xs-4'>
              <fieldset>
                <legend>Category Group</legend>
                <InPlaceText
                  defaultValue={FaqCategoryStore.showDataField('categoryGroup')}
                  model='faq_categories'
                  field='category_group'
                  id={FaqCategoryStore.showDataField('id')}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>FAQ Items</legend>

                <FaqItemCreate
                  faqCategoryId={FaqCategoryStore.showDataField('id')}
                  afterChange={this.getFaqCategory}
                />
                <FaqItemIndex
                  faqCategoryId={FaqCategoryStore.showDataField('id')}
                  faqItems={FaqCategoryStore.showDataField('faqItems')}
                  afterChange={this.getFaqCategory}
                />
              </fieldset>
            </div>
          </div>
        </div>
      );
    }
    return <span />;
  }
}

export default ErrorBoundary(FaqCategoryEdit);
