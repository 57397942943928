import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import cx from 'classnames';
import { pixify } from '@seedlang/utils';

const TITLES = {
  1: '30px',
  2: '26px',
  3: '20px',
};

const LINE_HEIGHTS = {
  1: '34px',
  2: '30px',
  3: '24px',
};

const Wrapper = styled.div`
  display: ${props => props.display};
  direction: ${props => props.rtl ? 'rtl' : 'auto'};
  font-size: ${props => props.heading ? TITLES[props.heading] : props.fontSize};
  line-height: ${props => props.heading ? LINE_HEIGHTS[props.heading] : props.lineHeight};
  font-weight: ${props => props.fontWeight};
  text-align: ${props => props.textAlign};
  color: ${props => props.color};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  text-transform: ${props => props.textTransform};
  font-style: ${props => props.fontStyle};
  cursor: ${props => props.cursor};
  width: ${props => props.width};
  text-wrap: ${props => props.textWrap};
  max-width: ${props => props.maxWidth};
  text-decoration: ${props => props.textDecoration};
  letter-spacing: ${props => props.letterSpacing};
  word-break: ${props => props.wordBreak};
`;

@observer
class Text extends React.Component {

  static defaultProps = {
    margin: 0,
    color: '#000',
    lineHeight: '22px',
    fontSize: '16px',
    display: 'block',
    wordBreakd: 'break-word',
  }

  @computed get headingClassName() {
    return this.props.heading ? `heading-${this.props.heading}` : null;
  }

  @computed get fontWeight() {
    return this.props.bold || this.props.heading ? 'bold' : this.props.fontWeight;
  }

  @computed get textAlign() {
    if (this.props.textAlign) {
      return this.props.textAlign;
    } else if (this.props.right) {
      return 'right';
    } else if (this.props.center) {
      return 'center';
    } return '';
  }

  @computed get cursor() {
    return this.props.link ? 'pointer' : this.props.cursor;
  }

  @computed get textDecoration() {
    return this.props.underline || this.props.link ? 'underline' : this.props.textDecoration;
  }

  @computed get fontStyle() {
    return this.props.italic ? 'italic' : this.props.fontStyle;
  }

  @computed get padding() {
    if (this.props.padding) {
      return this.props.padding;
    } else if (this.props.verticalPadding || this.props.horizontalPadding) {
      return `${this.props.verticalPadding || 0}px ${this.props.horizontalPadding || 0}px`;
    } return '';
  }

  render() {
    return (
      <Wrapper
        rtl={this.props.rtl}
        display={this.props.display}
        lineHeight={pixify(this.props.lineHeight)}
        heading={this.props.heading}
        style={this.props.style}
        color={this.props.color}
        textAlign={this.textAlign}
        maxWidth={this.props.maxWidth}
        margin={this.props.margin}
        fontWeight={this.fontWeight}
        fontSize={pixify(this.props.fontSize)}
        padding={this.padding}
        textTransform={this.props.textTransform}
        fontStyle={this.fontStyle}
        textDecoration={this.textDecoration}
        cursor={this.cursor}
        letterSpacing={this.props.letterSpacing}
        width={this.props.width}
        textWrap={this.props.wrap}
        className={cx('text', this.headingClassName, {
          heading: this.props.heading,
        })}
        onClick={this.props.onClick}
        wordBreak={this.props.wordBreak}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Text);
