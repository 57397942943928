import React from 'react';
import { observer } from 'mobx-react';
import InPlaceSelect from 'components/form/in_place_select';
import { ConceptWordStore } from '@seedlang/stores';
import { first, intersection, map, sortBy } from 'lodash';
import { isPresent } from '@seedlang/utils';
import cx from 'classnames';
import { computed, observable } from 'mobx';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  .concept-select {
    .dative {
      background: #FF5252;
      color: white;
      padding: 0 5px;
    }
    .nominative {
      background: #636363;
      color: white;
      padding: 0 5px;
    }
    .genitive {
      background: #9873E6;
      color: white;
      padding: 0 5px;
    }
    .accusative {
      background: #1DB5D7;
      color: white;
      padding: 0 5px;
    }
  }
`;

@observer
class ConceptSelect extends React.Component {
  @observable selectedConceptId;

  @computed get intersectionConceptId() {
    const allConceptIds = map(this.props.concepts, item => item.id);
    return first(intersection(allConceptIds, this.props.word.concepts.map(item => item.id)));
  }

  @computed get conceptId() {
    return this.selectedConceptId || this.intersectionConceptId;
  }

  @computed get concept() {
    return this.props.concepts.find(item => item.id === this.conceptId);
  }

  onChange(conceptId) {
    if (this.selectedConceptId) {
      ConceptWordStore.destroy({ ids: { concept_id: this.selectedConceptId, word_id: this.props.word.id } }, this.props.afterUpdate);
    }
    if (conceptId) {
      ConceptWordStore.create({ data: { concept_id: conceptId, word_id: this.props.word.id } }, this.props.afterUpdate);
    }
    this.selectedConceptId = conceptId;
  }

  render() {
    return (
      <Wrapper>
        <div
          className='concept-select'
        >
          <InPlaceSelect
            className={cx({
              dative: isPresent(this.concept) && this.concept.isDative,
              accusative: isPresent(this.concept) && this.concept.isAccusative,
              nominative: isPresent(this.concept) && this.concept.isNominative,
              genitive: isPresent(this.concept) && this.concept.isGenitive,
            })}
            onlyCreate
            clickToEdit
            includeBlank
            value={this.conceptId || ''}
            style={this.props.style}
            onChange={this.onChange.bind(this)}
            options={sortBy(this.props.concepts, item => item[this.props.optionName]).map(item => [item.id, item[this.props.optionName]])}
          />
        </div>
      </Wrapper>
    );
  }
}

export default ConceptSelect;
