import React from 'react';
import { observer } from 'mobx-react';
import { InterjectionStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import InterjectionCreate from 'pages/builder/interjections/interjection_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';

@observer
class InterjectionIndex extends React.Component {

  componentDidMount() {
    InterjectionStore.getIndex();
  }

  @autobind onDelete(id) {
    InterjectionStore.destroy({ids: {interjectionId: id}});
  }

  render() {
    return (
      <div className='interjection-index'>
        <InterjectionCreate />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th>Name</th>
              <th>Code</th>
              <th># Sentences</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              InterjectionStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.interjections.edit', params: { interjectionId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.code}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.sentencesCount}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this interjection?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={InterjectionStore}
        />
      </div>
    );
  }
}

export default InterjectionIndex;
