import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { ConjugationCardPromptWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { Theme, Constants } from '@seedlang/constants';
import { last } from 'lodash';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  flex-wrap: wrap;
  line-height: 28px;
`;

const SentenceWrapper = styled.div`
  margin: ${props => props.margin};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  flex-wrap: wrap;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Pronoun = styled.span`
  font-size: 28px;
  color: #333;
  font-weight: bold;
  margin-right: ${props => props.marginRight || '15px'};
  margin-top: ${props => props.marginTop};
`;

const PromptText = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  color: #333;
  display: flex;
  align-items: center;
`;

const Underline = styled.div`
  margin-right: ${props => props.marginRight};
`;

const IconWrapper = styled.div`
  width: 22px;
  height: 22px;
  background: ${props => props.background};
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  i {
    color: #FFF;
    font-size: 14px;
  }
  .fa-arrow-left {
    margin-left: -1px;
  }
`;

const TENSE_ICONS = {
  'a9f10fd6-e041-4087-b31f-2ae640c3c3c4': { // DE Present
    background: Theme.green,
    iconString: 'fa-arrow-down',
  },
  '56e11a26-8f93-4486-a897-f5be4fa2fb34': { // DE Simple Past
    background: Theme.purple,
    iconString: 'fa-book',
  },
  'c1e9f360-b6d3-422b-bde4-1ad86deb466a': { // DE Present Perfect
    background: Theme.orange,
    iconString: 'fa-arrow-left',
  },
  '2c6bd03e-ba93-4721-8778-9addc42f08d2': { // DE Subjunctive I
    background: Theme.gold,
    iconString: 'fa-book',
  },
  '6add2c4d-aa54-4cc5-8819-608272a44458': { // DE Subjunctive II
    background: Theme.blue,
    iconString: 'fa-book',
  },
  '33635da2-3d1d-4dc3-a75d-bd7b038b964d': { // FR Present
    background: Theme.green,
    iconString: 'fa-arrow-down',
  },
  '1dc1f99d-c766-4c2c-9f8e-0219c6b28b21': { // FR Perfect
    background: Theme.purple,
    iconString: 'fa-arrow-left',
  },
  'b37d06ac-8b5d-44c7-8ab6-4547ada49112': { // FR Imperfect
    background: Theme.orange,
    iconString: 'fa-arrow-left',
  },
  '6de9af2b-0219-4348-980b-fec89e52696a': { // FR future
    background: Theme.gold,
    iconString: 'fa-arrow-right',
  },
  'ad790421-3602-408f-af2d-cb91e4197ac2': { // FR conditional
    background: Theme.blue,
    iconString: 'fa-book',
  },
  '38ce3ce6-2b14-4f9b-b81e-2335d74d394b': { // ES Present
    background: Theme.green,
    iconString: 'fa-arrow-down',
  },
  '239aea46-cf43-49d1-ac75-41fe4e27feb3': { // ES Indefinite past
    background: Theme.darkPurple,
    iconString: 'fa-arrow-left',
  },
  'f714550e-6d45-4050-927c-b3e720261a31': { // ES Present perfect
    background: Theme.purple,
    iconString: 'fa-arrow-left',
  },
  '23e01211-7348-4790-8ed4-d0a4d6d186d3': { // ES Imperfect
    background: Theme.orange,
    iconString: 'fa-arrow-left',
  },
  'b43e9bd2-9f06-4602-9a0d-d47847e8f763': { // ES Future
    background: Theme.gold,
    iconString: 'fa-arrow-right',
  },
  '4f187664-22a4-4580-8cdb-48f859f86a3b': { // ES Subjunctive
    background: Theme.blue,
    iconString: 'fa-book',
  },

};

@observer
class ConjugationCardPrompt extends React.Component {

  @computed get icon() {
    return TENSE_ICONS[this.props.tenseConceptId];
  }

  render() {
    return (
      <Wrapper>
        <ColumnWrapper>
          <SentenceWrapper>
            <Pronoun
                marginRight={last(this.props.personalPronoun) === '\'' ? '5px' : '15px'}
            >
              {this.props.personalPronoun}
            </Pronoun>
            {
              this.props.correctAnswer && this.props.correctAnswerDisplay.split(' ').map(item => {
                return (
                  <Underline
                    key={item}
                    marginRight='15px'
                  >
                    {'_'.repeat(item.length)}
                  </Underline>
                );
              })
            }
          </SentenceWrapper>
          <PromptText>
            {this.props.card.word.targetText}, {Constants.GRAMMAR_TENSES[this.props.card.word.languageId][this.props.tenseConceptId]}
            <IconWrapper
              background={this.icon['background']}
            >
              <i className={`fa ${this.icon.iconString}`} />
            </IconWrapper>
          </PromptText>
        </ColumnWrapper>
      </Wrapper>
    );
  }
}

export default ConjugationCardPromptWrapper(ConjugationCardPrompt);
