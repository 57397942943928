import React from 'react';
import { observer } from 'mobx-react';
import { FaqCategoryShowWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { isPresent, isBlank } from '@seedlang/utils';
import { Link } from 'react-router';
import Text from 'components/text';
import FaqHeader from 'components/faq_header';
import { Theme } from '@seedlang/constants';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

const InnerWrapper = styled.div`

`;

const Row = styled.div`
  background: #FFF;
  padding: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  &:hover {
    background: whitesmoke;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const IconWrapper = styled.div`
  width: 70px;
`;

const FileIcon = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8e8e8;
`;

@observer
class FaqCategoryShow extends React.Component {

  render() {
    return (
      <Wrapper>
        <FaqHeader
          currentRoute='faq_categories.show'
          faqCategoryName={this.props.faqCategory.name}
        />
        {
          isBlank(this.props.faqCategory) &&
            <Spinner
              background={Theme.blue}
            />
        }
        {
          this.props.faqCategory &&
            <InnerWrapper>
              {
                isPresent(this.props.faqCategory.faqItems) && this.props.faqCategory.faqItems.map(item => {
                  if (AppUI.user.admin || item.published) {
                    return (
                      <Link
                        to={{name: 'faq_items.show', params: {faqCategoryId: this.props.faqCategory.id, faqItemId: item.id}}}
                        key={item.id}
                      >
                        <Row>
                          <IconWrapper>
                            <FileIcon>
                              <i className='fa fa-files-o' />
                            </FileIcon>
                          </IconWrapper>
                          <TextWrapper>
                            <Text
                              fontSize='18px'
                              fontWeight='22px'
                              bold
                              color='#333'
                            >
                              {item.titleWithPublished}
                            </Text>
                            <Text
                              fontSize='14px'
                              fontWeight='28px'
                              color='#333'
                            >
                              {item.description}
                            </Text>
                            <Text
                              italic
                              fontSize='12px'
                              fontWeight='20px'
                              color='#333'
                            >
                              Updated {item.updatedAt.relativeTime}
                            </Text>
                          </TextWrapper>
                        </Row>
                      </Link>
                    );
                  } else {
                    return <span />;
                  }
                })
              }
            </InnerWrapper>
        }
      </Wrapper>
    );
  }
}

export default FaqCategoryShowWrapper(FaqCategoryShow);
