import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import { CloseIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { pixify , isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  background: ${props => props.background};
  width: ${props => props.width};
  margin: ${props => props.margin};
  border: ${props => props.border};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  position: relative;
  padding: 10px;
  line-height: 20px;
  border-radius: ${props => props.borderRadius};
  text-align: ${props => props.textAlign};
  a {
    color: ${props => props.color};
  }
`;

const Content = styled.div`
  display: block;
  width: 100%;
  padding: ${props => props.padding};
`;

const CloseIconWrapper = styled.div`
  .close-icon {
    position: absolute;
    top: 7px;
    right: 0px;
    z-index: ${props => props.zIndex};
    transform: rotate(0);
    svg {
      width: 16px;
      fill: #333;
    }
  }
`;

@observer
class PageOnboarding extends React.Component {

  static defaultProps = {
    width: '100%',
    margin: '0 0 10px 0',
    background: 'white',
    borderRadius: 5,
    color: '#000',
    textAlign: 'center',
    fontSize: 15,
    padding: '0 15px 0 0',
  }

  render() {
    return (
      <Wrapper
        fontSize={pixify(this.props.fontSize)}
        lineHeight={pixify(this.props.fontSize + 3)}
        className={this.props.className}
        width={this.props.width}
        margin={this.props.margin}
        border={this.props.border}
        color={this.props.color}
        background={this.props.background}
        textAlign={this.props.textAlign}
        flat={this.props.flat}
        borderRadius={pixify(this.props.borderRadius)}
      >
        <Content
          padding={this.props.padding}
        >
          {this.props.children}
        </Content>
        {
          !this.props.hideCloseIcon && !this.props.fatCloseIcon &&
            <CloseIconWrapper
              zIndex={Theme.z['foreground']}
            >
              <CloseIcon
                className='page-onboarding-close-icon'
                onClick={() => AppUI.designateUiElementAsSeen(this.props.uiElement)}
                fill={this.props.color}
              />
            </CloseIconWrapper>
        }
        {
          !this.props.hideCloseIcon && this.props.fatCloseIcon &&
            <i
              className='fa fa-times'
              style={{color: '#cecece'}}
              onClick={() => AppUI.designateUiElementAsSeen(this.props.uiElement)}
            />
        }
      </Wrapper>
    );
  }
}

export default PageOnboarding;
