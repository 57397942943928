import React from 'react';
import { observer } from 'mobx-react';
import { DeckUI } from '@seedlang/state';
import { CardArrowBoxWrapper } from '@seedlang/hoc';
import ArrowBox from 'components/arrow_box';
import CardArrowBoxText from 'components/card/card_arrow_box_text';
import { Theme } from '@seedlang/constants';
import OnboardingPopup from 'components/onboarding/onboarding_popup';
import { isPresent } from '@seedlang/utils';
import { isFunction } from 'lodash';

@observer
class CardArrowBox extends React.Component {

  render() {
    if (isPresent(this.props.filteredArrowBoxes) && DeckUI.user.showDeckArrowBox) {
      return this.props.filteredArrowBoxes.map(item => {
        return (
          <div>
          <div id={item.slug} />
          <ArrowBox
            {...item}
            card={this.props.card}
            enableClickOnWrapper
            key={item.slug}
            show={!DeckUI.hideDeckArrowBox && item.slug === this.props.currentArrowBox}
            onClick={() => this.props.onHideArrowBox(item)}
            onClose={isFunction(item.onClose) ? () => item.onClose(item.slug) : null}
            zIndex={Theme.z['card-4']}
            closeIconFill={item['onboarding'] ? '#FFF' : '#000'}
            hideCloseIcon={!item.onboarding}
          >
            {
              !item.onboarding &&
                <CardArrowBoxText
                  title={item.title}
                  subText={item.subText}
                />
            }
            {
              item.onboarding &&
                <OnboardingPopup
                  title={item.title}
                  message={item.message}
                />
            }
          </ArrowBox>
          </div>
        );
      });
    }
    return <span />;
  }
}

export default CardArrowBoxWrapper(CardArrowBox);
