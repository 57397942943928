import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { CampaignTypeStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import CampaignTypeCreate from 'pages/builder/campaign_types/campaign_type_create';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import Filter from 'components/backend_filter/filter';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import InfoTooltip from 'components/info_tooltip';

@observer
class CampaignTypeIndex extends React.Component {

  componentDidMount() {
    if (!AppUI.siteIsDefault) {
      CampaignTypeStore.getIndex({queryStrings: {include_count: true}});
    }
  }

  @computed get pageName() {
    return AppUI.siteIsDefault ? 'Campaign Types' : 'Automated Email Campaigns';
  }

  @autobind onDelete(id) {
    CampaignTypeStore.destroy({ids: {campaignTypeId: id}});
  }

  render() {
    return (
      <div className='mailing-type-index'>
        {
          AppUI.siteIsDefault &&
            <Filter
              store={CampaignTypeStore}
              defaultSort='name'
              namespace='notification-index'
              queryStrings={{include_count: true}}
              filters={[
                {
                  type: 'boolean',
                  label: 'Mobile',
                  name: 'mobile_notification',
                  default: '',
                },
                {
                  type: 'boolean',
                  label: 'Poll',
                  name: 'poll',
                  default: '',
                },
              ]}
            />
        }
        <div className='breadcrumbs-wrapper'>
          <div className='breadcrumbs'>
            <div className='current'>
              {this.pageName}
            </div>
          </div>
        </div>
        {
          AppUI.site.blockPostNotifications &&
            <Alert>
              {`All notifications are currently blocked. Users will not be notified about new posts. ${ AppUI.user.canAccessAdminSite ? 'This can be modified in the site settings.' : '' }`}
            </Alert>
        }
        <BackEndExplainer margin='10px 0'>Emails that are sent out to users automatically by the platform can be set up here.</BackEndExplainer>
        <CampaignTypeCreate />
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th width='50'></th>
              <th>Campaign Type</th>
              { AppUI.siteIsDefault && <th>Mobile</th> }
              { AppUI.siteIsDefault && <th>Poll</th> }
              <th>1X <InfoTooltip>When enabled, users will only receive this email once.</InfoTooltip></th>
              <th>Priority <InfoTooltip>Higher priority emails are sent first when multiple emails are queued.</InfoTooltip></th>
              <th># Sent</th>
              <th># Clicks</th>
              <th>% Clicked</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              CampaignTypeStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: this.props.whiteLabeled ? 'creator.campaign_types.edit' : 'builder.campaign_types.edit', params: { groupId: this.props.params.groupId, campaignTypeId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        {item.name}
                      </div>
                    </td>
                    {
                      AppUI.siteIsDefault &&
                        <td>
                          <InPlaceCheckbox
                            icon='check'
                            model='campaign_types'
                            field='mobile_notification'
                            id={item.id}
                            value={item.mobileNotification}
                          />
                        </td>
                    }
                    {
                      AppUI.siteIsDefault &&
                        <td>
                          <InPlaceCheckbox
                            icon='check'
                            model='campaign_types'
                            field='poll'
                            id={item.id}
                        value={item.poll}
                          />
                        </td>
                    }
                    <td>
                      <InPlaceCheckbox
                        icon='check'
                        model='campaign_types'
                        field='one_time_sending'
                        id={item.id}
                        value={item.oneTimeSending}
                      />
                    </td>
                    <td>
                      <div className='text'>
                        {item.priority}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.campaignMessagesCount}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.clickedCount}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.percentageClicked}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this mailing type?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={CampaignTypeStore}
        />
      </div>
    );
  }
}

export default ErrorBoundary(CampaignTypeIndex);
