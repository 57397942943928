import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import CommentIndex from 'components/comment/comment_index';
import { ForumUI } from '@seedlang/state';
import Text from 'components/text';

const Wrapper = styled.div`
  padding: ${props => props.padding};
  overflow: hidden;
`;

@observer
class ConceptComments extends React.Component {

  static defaultProps = {
    padding: '20px',
  }

  render() {
    return (
      <Wrapper
        padding={this.props.padding}
      >
        {
          !this.props.hideTitle &&
            <div>
              <Text
                heading='3'
                margin='10px 0 0 0'
              >
                {this.props.concept?.displayNameLine1}
              </Text>
              {
                this.props.concept?.displayNameLine2 &&
                  <Text
                    italic
                    margin='0 0 20px 0'
                  >
                    {this.props.concept?.displayNameLine2}
                  </Text>
              }
            </div>
        }
        {
          this.props.concept &&
            <CommentIndex
              showXp
              showCommentCreate
              showCommentCount
              indentation={20}
              user={ForumUI.user}
              conceptId={this.props.concept?.id}
              namespace={this.props.concept?.id}
              blockTextExpansion={this.props.blockTextExpansion}
              onChange={this.props.afterEmbeddedCommentCreate}
              // commentable={this.props.forumThread}
              commentableType='ForumThread'
              commentableId={this.props.concept?.forumThreadId}
              forumThreadId={this.props.concept?.forumThreadId}
              commentMargin='10px 0'
              margin={this.props.commentMargin}
              padding='0'
              languageId={this.props.concept?.languageId}
            />
        }

      </Wrapper>
    );
  }
}

export default ConceptComments;
