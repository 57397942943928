import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import CampaignIndex from 'pages/builder/campaigns/campaign_index';
import BackEndExplainer from 'pages/creator/back_end_explainer';
const Wrapper = styled.div`

`;

@observer
class SendEmail extends React.Component {

  render() {
    return (
      <Wrapper>
        <BackEndExplainer>You can set up and send one-time emails to users here.</BackEndExplainer>
        <div className='breadcrumbs-wrapper'>
          <div className='breadcrumbs'>
            <div className='current'>
              Send Email
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Emails</legend>
              <CampaignIndex
                oneTimeSending={true}
                groupId={this.props.params.groupId}
                whiteLabeled={true}
                campaignTypeId='one-time'
                mobileNotification={false}
              />
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SendEmail);
