import React from 'react';
import { observer } from 'mobx-react';
import { ReportBugWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Text from 'components/text';
import InPlaceTextInput from 'components/form/in_place_text_input';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import Alert from 'components/alert';
import { DeckUI } from '@seedlang/state';

const Wrapper = styled.div`
  background: #545454;
  height: 100%;
  padding: 10px 20px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
`;

@observer
class ReportBug extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.displaySubmittedMessage &&
            <Alert>
              Thank you for submitting your bug report. We will be in touch shortly.
            </Alert>
        }
        <Text
          heading='2'
          textAlign='center'
          padding='0 0 20px 0'
          color='#FFF'
        >
          Report Bug
        </Text>
        <Text
          padding='10px 0'
          color='#FFF'
          textAlign='left'
          fontSize='14px'
          lineHeight='18px'
        >
          Thank you for letting us know about the problem that you are experiencing. This message will include information such as your device type and your deck's current state.
        </Text>
        {
          DeckUI.user.guest &&
            <Text
              padding='10px 0'
              color='#FFF'
              textAlign='left'
              bold
            >
              Email
            </Text>
        }
        {
          DeckUI.user.guest &&
            <InPlaceTextInput
              value={this.props.email}
              onChange={val => this.props.setEmail(val)}
            />
        }
        <Text
          padding='10px 0'
          color='#FFF'
          textAlign='left'
          bold
        >
          Message
        </Text>
        <InPlaceTextInput
          value={this.props.message}
          inputType='textarea'
          onChange={val => this.props.setMessage(val)}
          height='140px'
          expandedHeight='140px'
        />
        <Text
          padding='10px 0'
          color='#FFF'
          textAlign='left'
          bold
        >
          Attach Image
        </Text>
        <InPlaceImageUpload
          model='images'
          margin='0'
          onChange={this.props.setImage}
          image={this.props.image}
        />
        <Text
          padding='10px 0'
          color='#FFF'
          textAlign='left'
          fontSize='14px'
        >
          If you can attach a screenshot, it is very helpful for troubleshooting.
        </Text>
        <ButtonRow>
          <Button
            background={Theme.green}
            color='#FFF'
            width={130}
            disabled={this.props.disabled}
            onClick={() => this.props.onSubmit({webVersion: DeckUI.user?.site?.currentWebVersion})}
            margin='0 10px 0 0'
          >
            Send
          </Button>
          <Button
            background={Theme.red}
            color='#FFF'
            text='Reset'
            width={130}
            marginLeft={10}
            disabled={this.props.disabled}
            onClick={this.props.onReset}
          >
            Reset
          </Button>
        </ButtonRow>
        <Text
          padding='10px 0'
          color='#FFF'
          textAlign='left'
          fontSize='14px'
          lineHeight='18px'
        >
          Note: If you would like to report a problem with a translation, please post it to our comments section.
          &nbsp;<span
            className='underline'
            onClick={() => DeckUI.gotoCardComments({card: DeckUI.currentCard})}
          >
            Open Comments Section
          </span>
        </Text>
      </Wrapper>
    );
  }
}

export default ReportBugWrapper(ReportBug);
