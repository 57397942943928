import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { DashboardStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import PieChart from 'components/visualization/pie_chart';

const Wrapper = styled.div`

`;

@observer
class LevelsDashboard extends React.Component {
  @observable counts = {};

  componentDidMount() {
    DashboardStore.api({}, this.afterGetCounts, 'GET', 'levels');
  }

  @autobind afterGetCounts(resp) {
    this.counts = resp;
  }

  render() {
    return (
      <Wrapper>
        <fieldset>
          <legend>Levels</legend>
          <div className='row'>
            <div className='col-xs-3'>
              <PieChart
                width={200}
                innerRadius={70}
                data={this.counts.userLevelsCount}
                label='Users'
              />
            </div>
            <div className='col-xs-3'>
              <PieChart
                width={200}
                innerRadius={70}
                data={this.counts.proLevelsCount}
                label='Pros'
              />
            </div>
            <div className='col-xs-3'>
              <PieChart
                width={200}
                innerRadius={70}
                data={this.counts.startedDeckLevelsCount}
                label={<center>Decks<br />Started</center>}
              />
            </div>
            <div className='col-xs-3'>
              <PieChart
                width={200}
                innerRadius={70}
                data={this.counts.finishedDeckLevelsCount}
                label={<center>Decks<br />Finished</center>}
              />
            </div>
          </div>
        </fieldset>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LevelsDashboard);
