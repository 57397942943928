import styled from '@emotion/styled';
import {
  CustomTabStore,
  DownloadLinkTypeStore,
  GroupStore,
  MembershipGroupStore,
  PodcastStore,
  SiteStore,
} from '@seedlang/stores';
import { isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import InPlaceText from 'components/form/in_place_text';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import InfoTooltip from 'components/info_tooltip';
import Text from 'components/text';
import Cookies from 'js-cookie';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import React from 'react';

const Wrapper = styled.div`
  .small {
    font-size: 12px;
    line-height: 14px!important;
  }
`;

const ColorRow = styled.div`
  display: flex;
  align-items: center;
  .fa {
    margin-right: 5px;
    color: ${props => props.color}
  }
`;

const Small = styled.div`
  font-size: 12px;
  color: #333;
`;

@observer
class WorksheetSettings extends React.Component {
  constructor(props) {
    super(props);
    this.getSite();
    if (!MembershipGroupStore.hasIndexData) {
      MembershipGroupStore.getIndex();
    }
    if (!DownloadLinkTypeStore.hasIndexData) {
      DownloadLinkTypeStore.getIndex({});
    }
    if (!PodcastStore.hasIndexData) {
      PodcastStore.getIndex({});
    }
    if (!CustomTabStore.hasIndexData) {
      CustomTabStore.getIndex({});
    }
  }

  componentDidMount() {
    this.getSite();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.conceptId !== prevProps.routeParams.conceptId) {
      this.getSite();
    }
  }

  @computed get site() {
    return SiteStore.showData;
  }

  @autobind getSite() {
    console.log('getSite');
    SiteStore.getShow({ ids: { siteId: Cookies.get('site_id') }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    SiteStore.setShowData(resp);
  }

  @autobind loadGroup() {
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}});
  }

  render() {
    return (
      <Wrapper>
        <BackEndExplainer>These settings affect the way posts are displayed to users.</BackEndExplainer>
        {
          SiteStore.hasShowData &&
            <>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>General Post Settings</legend>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.showWorksheetComments}
                        model='sites'
                        field='show_worksheet_comments'
                        icon='check'
                      >
                        Show Post Comments <InfoTooltip position='left'>Users will be able to comment on posts and exercises.</InfoTooltip>
                      </InPlaceCheckbox>
                    </div>
                  </fieldset>
                </div>
              </div>
              <div className='backend-title'>Post Vocab Settings</div>
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Vocab settings</legend>
                      <div>
                        <InPlaceCheckbox
                          id={this.site.id}
                          value={this.site.showWordPages}
                          model='sites'
                          field='show_word_pages'
                          icon='check'
                          disabled={!this.site.language?.hasLessons}
                        >
                          Link to Word Pages (with Video)
                          <InfoTooltip>
                            <div>If enabled, users can click on words to open up a page</div>
                            <div>with information about the word and a video (if available).</div>
                            {
                              !this.site.language?.hasLessons &&
                                <div className='small'>This is disabled for languages currently without any videos.</div>
                            }
                          </InfoTooltip>
                        </InPlaceCheckbox>
                      </div>
                      <div>
                        <InPlaceCheckbox
                          id={this.site.id}
                          value={this.site.showWordStress}
                          model='sites'
                          field='show_word_stress'
                          icon='check'
                        >
                          Word Stress <InfoTooltip><div>By toggling this, you will have the ability</div><div>to underline characters in words to indicate stress</div></InfoTooltip>
                        </InPlaceCheckbox>
                      </div>
                      <div>
                        <InPlaceCheckbox
                          id={this.site.id}
                          value={this.site.showPlural}
                          model='sites'
                          field='show_plural'
                          icon='check'
                        >
                          Show Plural <InfoTooltip>The plural of each noun will be displayed next to the singular form.</InfoTooltip>
                        </InPlaceCheckbox>
                      </div>
                      <div>
                        <InPlaceCheckbox
                          model='sites'
                          field='show_alternate_target_text'
                          value={this.site.showAlternateTargetText}
                          id={this.site.id}
                          icon='check'
                          afterChange={this.getSite}
                        >
                          Show Romanized spelling <InfoTooltip>Useful for languages that use several writing systems.</InfoTooltip>
                        </InPlaceCheckbox>
                        <div>
                      </div>
                        <InPlaceCheckbox
                          value={this.site.hideDefiniteArticle}
                          model='sites'
                          icon='check'
                          field='hide_definite_article'
                          id={this.site.id}
                          afterChange={this.getSite}
                        >
                          Hide Articles & Show Gender
                          <InfoTooltip>
                            <div>By selecting this option, articles will no longer be displayed before nouns,</div>
                            <div>and gender abbreviations will be added after nouns (if relevant).</div></InfoTooltip>
                        </InPlaceCheckbox>
                      </div>
                    {
                      this.site.showAlternateTargetText &&
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <Text
                            fontSize='12px'
                            wrap='nowrap'
                            margin='0 5px 0 0'
                          >
                            Column Name:
                          </Text>
                          <InPlaceText
                            submitOnEnter
                            id={this.site.id}
                            defaultValue={this.site.alternateTargetTextName}
                            model='sites'
                            field='alternate_target_text_name'
                            show={isBlank(this.site.alternateTargetTextName)}
                            afterChange={this.getSite}
                            fontSize='12px'
                          />
                          {
                            isBlank(this.site.alternateTargetTextName) &&
                              <i className='fa fa-warning' />
                          }
                        </div>
                    }
                      <InPlaceCheckbox
                        model='sites'
                        field='show_level_column'
                        value={SiteStore.showData.showLevelColumn}
                        id={SiteStore.showData.id}
                        icon='check'
                      >
                        Show level column <InfoTooltip>The level of each word (A1, A2, etc.) will be displayed.</InfoTooltip>
                      </InPlaceCheckbox>
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Filter settings <InfoTooltip>Users can filter the words by...</InfoTooltip></legend>
                      <InPlaceCheckbox
                        model='sites'
                        field='show_level_filter'
                        value={SiteStore.showData.showLevelFilter}
                        id={SiteStore.showData.id}
                        icon='check'
                      >
                        Show level filters
                      </InPlaceCheckbox>
                      <InPlaceCheckbox
                        model='sites'
                        field='show_word_type_filter'
                        value={SiteStore.showData.showWordTypeFilter}
                        id={SiteStore.showData.id}
                        icon='check'
                      >
                        Show word type filters
                      </InPlaceCheckbox>
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Sorting Settings <InfoTooltip>Users can sort the words by...</InfoTooltip></legend>
                      <InPlaceCheckbox
                        model='sites'
                        field='show_alphabetical_sort'
                        value={SiteStore.showData.showAlphabeticalSort}
                        id={SiteStore.showData.id}
                        icon='check'
                      >
                        Show alphabetical sort
                      </InPlaceCheckbox>
                      <InPlaceCheckbox
                        model='sites'
                        field='show_chronological_sort'
                        value={SiteStore.showData.showChronologicalSort}
                        id={SiteStore.showData.id}
                        icon='check'
                      >
                        Show chronological sort
                      </InPlaceCheckbox>
                      <InPlaceCheckbox
                        model='sites'
                        field='show_level_sort'
                        value={SiteStore.showData.showLevelSort}
                        id={SiteStore.showData.id}
                        icon='check'
                      >
                        Show level sort
                      </InPlaceCheckbox>
                  </fieldset>
                </div>
              </div>
              <div className='backend-title'>PDF Export Settings</div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>PDF Banner Image (1160x230) <InfoTooltip>This is the image that will be displayed in the PDF version of the post.</InfoTooltip></legend>
                    <InPlaceImageUpload
                      model='images'
                      image={GroupStore.showData.image}
                      onChange={() => GroupStore.reloadShow()}
                      querystring={{
                        imageable_id: GroupStore.showData.id,
                        imageable_type: 'Group',
                      }}
                    />
                  </fieldset>
                </div>
              </div>
            </>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetSettings);
