import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { CustomTabStore } from '@seedlang/stores';
import { computed, observable } from 'mobx';
import autobind from 'autobind-decorator';
import { ExerciseUI , AppUI } from '@seedlang/state';
import { Link } from 'react-router';
import NoAccessButton from 'components/worksheet/no_access_button';
import YoutubePlayer from 'components/worksheet/youtube_player';
import Button from 'components/button/button';
import Spinner from 'components/spinner';
import { isPresent, isBlank } from '@seedlang/utils';

const Wrapper = styled.div`
`;

const Image = styled.div`
  img {
    max-width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

const TextContent = styled.div`
  padding: 0 20px 20px 20px;
`;

const AdminLink = styled.div`
  margin-top: 5px;
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

const Content = styled.div`
  padding: 10px 0 20px 0;
  background: #FFF;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  min-height: 180px;
  li {
    margin-left: 20px;
    list-style-type: disc;
  }
  a {
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  p {
    margin: 10px 0!important;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

@observer
class CustomTabShow extends React.Component {
  @observable loading = false;

  constructor(props) {
    super(props);
    if (!ExerciseUI.worksheetStore.hasShowData) {
      this.loading = true;
      ExerciseUI.worksheetStore.getShow({ids: {worksheetId: this.props.params.worksheetId}}, this.afterGetWorksheet);
    }
  }

  @autobind afterGetWorksheet(resp) {
    this.loading = false;
    ExerciseUI.worksheetStore.setShowData(resp);
  }

  @computed get customTab() {
    return ExerciseUI.hasWorksheet && ExerciseUI.worksheet.customTabs?.find(item => item.id === this.props.params.customTabId);
  }

  @autobind blockedUntilMigrated(customTab) {
    return AppUI.paymentMigrationEnabled && customTab.blockIfNotMigrated && AppUI.isPaymentMigrationStartable;
  }

  render() {
    return (
      <Wrapper>
          <Content>
            {
              this.loading &&
              <Spinner background={AppUI.site.accentColor} />
            }
            {
              !this.loading &&
                <>
                  {
                    this.blockedUntilMigrated(this.customTab) &&
                    <NoAccessButton migrate/>
                  }
                  {
                    !this.blockedUntilMigrated(this.customTab) &&
                      <>
                        {
                          this.customTab.content &&
                          <TextContent
                            dangerouslySetInnerHTML={{__html: this.customTab.content || ''}}
                          />
                        }
                        {
                          this.customTab.imageLinkUrl && isPresent(this.customTab.image) &&
                          <a
                            href={this.customTab.imageLinkUrl}
                            target='_blank'
                            rel='noopener noreferrer'
                          >
                            {
                              this.customTab.image &&
                              <Image>
                                <img src={this.customTab.image.url} alt=''/>
                              </Image>
                            }
                          </a>
                        }
                        {
                          isBlank(this.customTab.imageLinkUrl) && isPresent(this.customTab.image) &&
                            <Image>
                              <img src={this.customTab.image.url} alt=''/>
                            </Image>
                        }
                        {
                          this.customTab.formattedYoutubeUrl &&
                          <RowWrapper>
                            <YoutubePlayer
                              hasAccess={this.blockedUntilMigrated(this.customTab)}
                              url={this.customTab.formattedYoutubeUrl}
                              borderRadius='10px'
                            />
                          </RowWrapper>
                        }
                        {
                          this.customTab.buttonUrl && this.customTab.buttonText &&
                          <RowWrapper>
                            <Button
                              height='50px'
                              fontSize='16px'
                              borderRadius='10px'
                              background={AppUI.site.accentColor}
                              scaleOnHover={AppUI.layout.isMobile ? null : '1.03'}
                              padding='5px 20px'
                              minWidth='100px'
                            >
                              <a href={this.customTab.buttonUrl} target='_blank'
                                style={{color: '#FFF', textDecorationLine: 'none'}}
                              >{this.customTab.buttonText}</a>
                            </Button>
                          </RowWrapper>
                        }
                      </>
                  }
                </>
            }
          </Content>
        {
          ExerciseUI.user.anyAdmin && ExerciseUI.worksheet && ExerciseUI.worksheet.groupId &&
            <AdminLink>
              {
                isBlank(this.customTab?.worksheetId) &&
                  <Link
                    to={{
                      name: 'creator.groups.custom_tabs.edit',
                      params: {groupId: ExerciseUI.worksheet.groupId, customTabId: this.props.params.customTabId},
                    }}
                  >
                    Admin Link (Custom Tab)
                  </Link>
              }
              {
                isPresent(this.customTab?.worksheetId) &&
                  <Link
                    to={{
                      name: 'creator.groups.worksheets.edit',
                      params: {groupId: ExerciseUI.worksheet.groupId, worksheetId: this.customTab.worksheetId}}}
                  >
                    Admin Link
                  </Link>
              }
            </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default CustomTabShow;
