import React from 'react';
import autobind from 'autobind-decorator';

const SortableColumns = (Component, Store, config) => class extends React.Component { // eslint-disable-line react/display-name

  constructor(props) {
    super(props);
    this.state = {
      sortColumn: config.sortColumn,
      sortIsAscending: config.sortIsAscending,
    };
  }

  @autobind columnClassNames(column) {
    return { highlighted: this.state.sortColumn === column, ascending: this.state.sortColumn === column && this.state.sortIsAscending, descending: this.state.sortColumn === column && !this.state.sortIsAscending };
  }

  @autobind onSortColumn(column, forceSortIsDescending = false, params) {
    let sortIsAscending = this.state.sortIsAscending;
    if (this.state.sortColumn === column) {
      sortIsAscending = !sortIsAscending;
    } else if (forceSortIsDescending) {
      sortIsAscending = false;
    } else {
      sortIsAscending = true;
    }
    this.setState({
      sortColumn: column,
      sortIsAscending: sortIsAscending,
    });
    if (!sortIsAscending) {
      column = `-${column}`;
    }
    Store.setSort(column, { refresh: true, ...params });
  }

  render() {
    return (
      <span className='sortable-columns'>
        <Component
          onSortColumn={this.onSortColumn}
          columnClassNames={this.columnClassNames}
          {...this.props}
          {...this.state}
        />
      </span>
    );
  }
};

SortableColumns.displayName = 'SortableColumns';
export default SortableColumns;
