import React from 'react';
import { observer } from 'mobx-react';
import { isPresent, pixify, isBlank } from '@seedlang/utils';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { UserIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  margin: ${props => props.margin};
  padding: 3px;
  svg {
    fill: #FFF;
  }
`;

const InnerWrapper = styled.div`
  z-index: ${props => props.zIndex};
  width: ${props => props.width};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  background: linear-gradient(-134deg,#efefef 0%,#dddddd 100%);
`;

const Image = styled.div`
  width: ${props => props.width};
  height: ${props => props.width};
  background-image: ${props => `url(${props.imageUrl})`};
  cursor: ${props => props.hasOnClick ? 'pointer' : 'default'};
  border: ${props => props.border};
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
`;

const Anonymous = styled.div`
  ${flexCenterColumn()}
  width: ${props => props.width};
  height: ${props => props.width};
  border: ${props => props.border};
  border-radius: 50%;
  background: #616161;
  .icon-wrapper {
    width: 60%;
    height: 60%;
  }
`;

@observer
class SiteProfileImage extends React.Component {

  static defaultProps = {
    width: 35,
    fontSize: 14,
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <InnerWrapper
          zIndex={this.props.zIndex}
          width={pixify(this.props.width)}
        >
          {
            this.props.site && isPresent(this.props.site.teamLogo?.image) &&
              <Image
                margin={this.props.margin}
                border={this.props.border}
                width={pixify(this.props.width)}
                imageUrl={this.props.site.favicon.image.url}
              />
          }
          {
            isBlank(this.props.site?.favicon) &&
              <Anonymous
                margin={this.props.margin}
                width={pixify(this.props.width)}
                border={this.props.border || this.props.anonymousBorder}
              >
                <UserIcon />
              </Anonymous>
          }
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SiteProfileImage);
