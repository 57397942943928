import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { isBlank } from '@seedlang/utils';
import { Link } from 'react-router';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  font-size: 40px;
  border: 1px solid gray;
  border-radius: 3px;
  background: white;
  margin: 0 10px;
`;

const Label = styled.div`
  font-size: 13px;
  font-weight: bold;
  background: #525252;
  color: white;
  width: 100%;
  text-align: center;
`;

const Count = styled.div`
  padding: 20px;
`;

@observer
class DashboardCount extends React.Component {

  render() {
    if (isBlank(this.props.count) && this.props.count !== 0) {
      return <span />;
    }
    if (this.props.linkTo) {
      return (
        <Link
          to={this.props.linkTo}
        >
          <Wrapper>
            <Label>{this.props.label}</Label>
            <Count>{this.props.count}</Count>
          </Wrapper>
        </Link>
      );
    }
    if (!this.props.linkTo) {
      return (
        <Wrapper>
          <Label>{this.props.label}</Label>
          <Count>{this.props.count}</Count>
        </Wrapper>
      );
    }
  }
}

export default ErrorBoundary(DashboardCount);
