import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import DailyActivityCalendarDay from 'components/daily_activity/daily_activity_calendar_day';
import Spinner from 'components/spinner';
import { LanguageIcon } from '@seedlang/icons';
import { groupBy } from 'lodash';

const Wrapper = styled.div`
  height: 210px;
  overflow: scroll;
  margin: 10px 0;
`;

const Entry = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #CCC;
  margin-bottom: 5px;
  padding-bottom: 5px;
`;

const DateAndIcon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Date = styled.div`
  margin-left: 5px;
  font-size: 14px;
  font-weight: bold;
`;

const XpBreakdowns = styled.div`
  margin-left: 33px;
  font-size: 12px;
`;

const XpBreakdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const XpBreakdown = styled.div`
  flex: 1;
`;

const Time = styled.div`
  width: 45px;
  color: #a5a5a5;
  font-style: italic;
`;

const Flag = styled.div`
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon-wrapper {
    display: flex;
    align-items: center;
  }
`;

const NoActivity = styled.div`
  font-size: 14px;
  margin: 0 10px;
`;

@observer
class DailyActivityList extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          !this.props.loading && !this.props.store.hasIndexData &&
            <NoActivity>
              <i className='fa fa-warning' />
              Your account does not yet have any activity.
            </NoActivity>
        }
        {
          this.props.loading &&
            <Spinner />
        }
        {
          !this.props.loading && this.props.store.indexData.map(item => {
            if (item.xp > 0) {
              return (
                <Entry
                  key={item.id}
                >
                  <DateAndIcon>
                    <DailyActivityCalendarDay
                      dailyXpCount={item}
                      width='22px'
                      height='22px'
                      borderRadius='7px'
                      iconHeight='13px'
                      iconWidth='8px'
                      showFlags={this.props.showFlags}
                    />
                    <Date>
                      {item.xpCalendarDateMonth} {item.xpCalendarDateDay}
                    </Date>
                  </DateAndIcon>
                  <XpBreakdowns>
                    {
                      this.props.summarizedXpBreakdowns(item)?.map(xpBreakdown => { // sum up by language
                        return (
                          <XpBreakdownWrapper
                              key={xpBreakdown.id}
                          >
                            <XpBreakdown>
                              {`${xpBreakdown.xp} XP ${xpBreakdown.xpDescriptionFromSlug}`}
                            </XpBreakdown>
                            {
                              this.props.showFlags &&
                                <Flag>
                                  <LanguageIcon
                                    width='12px'
                                    languageId={xpBreakdown.languageId || 'DE'}
                                  />
                                </Flag>
                            }
                            <Time>
                              {xpBreakdown.occurredAtTimeLocal}
                            </Time>
                          </XpBreakdownWrapper>
                        );
                      })
                    }
                  </XpBreakdowns>
                </Entry>
              );
            }
            return null;
          })
        }
      </Wrapper>
    );
  }
}

export default DailyActivityList;
