import React from 'react';
import { observer } from 'mobx-react';
import { observable, when, computed } from 'mobx';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import Form from 'components/form_factory/form';
import FormFactory from 'components/form_factory/form_factory';
import styled from '@emotion/styled';
import Text from 'components/text';
import OutlineButton from 'components/button/outline_button';
import { Theme, TimeZones } from '@seedlang/constants';
import Modal from 'components/modal';
import Button from 'components/button/button';
import Alert from 'components/alert';
import SettingsUserImage from 'components/user/settings_user_image';
import { SettingsBillingWrapper } from '@seedlang/hoc';
import Spinner from 'components/spinner';
import { Link } from 'react-router';
import FormLabel from 'components/form_factory/form_label';
import { isPresent, isBlank } from '@seedlang/utils';

const Wrapper = styled.div`
  textarea {
    height: 80px!important;
  }
  h3 {
    text-align: center;
  }
`;

const Fields = styled.div`

`;

const DeleteModal = styled.div`
  display: flex;
  align-items center;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 20px 0 0 0;
  }
  padding: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  padding: 0 5px;
`;

@observer
class SettingsUserProfile extends React.Component {
  @observable formLoaded = false;
  @observable showConfirmDeleteModal = false;
  @observable showDeletionConfirmation = false;
  @observable targetLanguageLevelsCache = {}
  @observable deletionSuccess = false;
  @observable showDeletionSpinner = false;

  constructor(props) {
    super(props);
    if (!AppUI.levelStore.hasIndexData) {
      AppUI.levelStore.getIndex();
    }
    when(() => {
      if (AppUI.userIsLoaded) {
        this.formLoaded = true;
        this.form = new FormFactory(AppUI.authUserStore, this.props);
        // AppUI.authUserStore.initializeForm();
      }
    });
  }

  @autobind onDeleteUser() {
    this.showDeletionSpinner = true;
    AppUI.userStore.destroy({ ids: { userId: AppUI.user.id } }, this.afterDeleteUser);
  }

  @autobind afterDeleteUser(resp) {
    this.showDeletionConfirmation = true;
    this.showDeletionSpinner = false;
    this.deletionSuccess = resp['success'];
    if (this.deletionSuccess) {
      AppUI.signOut();
    }
  }

  @autobind onChangeUser(field, value) {
    AppUI.authUserStore.setFormCacheValue(field, value);
  }

  getValueUser(field) {
    return AppUI.authUserStore.formCache[field] || '';
  }

  @computed get streakExtendGoal() { // see user.rb
    if (isBlank(AppUI.authUserStore?.formCache?.dailyXpGoal)) { return AppUI.user?.streakExtendGoal; }
    return Math.max(100, AppUI.authUserStore?.formCache?.dailyXpGoal * 2);
  }

  fields() {
    return (
      <Fields>
        {
          AppUI.authUserStore.dirtyForm &&
            <Alert
              textAlign='center'
            >
              You have unsaved changes.
            </Alert>
        }
        {
          isPresent(AppUI.user?.email) && !AppUI.user.email.endsWith('@apple.com') && ! AppUI.user.email.endsWith('privaterelay.appleid.com') &&
            <div style={{margin: '0 0 20px 0'}}>
              <FormLabel name='Your Email'/>
              <Text>{AppUI.user?.email}</Text>
            </div>
        }
        { this.form.input({ margin: '0 0 20px 0', name: 'name', label: 'Your name' }) }
        { AppUI.siteIsDefault && this.form.input({ margin: '0 0 20px 0', name: 'location', label: 'Your location' }) }
        { this.form.select({margin: '0 0 20px 0', name: 'timeZone', label: 'Your Time Zone', options: TimeZones})}
        { this.form.select({ margin: '0 0 20px 0', name: 'languageId', label: 'Your Native Language', options: AppUI.languageOptions }) }
        { AppUI.siteIsDefault && this.form.select({ margin: '0 0 20px 0', name: 'englishPreference', label: 'English Preference', options: [['us', 'American English'], ['uk', 'British English']] }) }
        { AppUI.siteIsDefault && this.form.textarea({ margin: '0 0 20px 0', name: 'introduction', label: 'Your introduction' }) }
        { AppUI.siteIsDefault && this.form.select({ margin: '0 0 20px 0', name: 'deLevelId', label: 'Your German Level', options: AppUI.levelStore.indexData.map(item => {
          return {id: item.id, name: item.nameWithAbbreviation};
        }) }) }
        { AppUI.siteIsDefault && this.form.select({ margin: '0 0 20px 0', name: 'esLevelId', label: 'Your Spanish Level', options: AppUI.levelStore.indexData.map(item => {
          return {id: item.id, name: item.nameWithAbbreviation};
        }) }) }
        { AppUI.siteIsDefault && this.form.select({ margin: '0 0 20px 0', name: 'frLevelId', label: 'Your French Level', options: AppUI.levelStore.indexData.map(item => {
          return {id: item.id, name: item.nameWithAbbreviation};
        }) }) }
        {
          AppUI.siteIsDefault && this.form.flatSelect({
            margin: '0 0 20px 0',
            name: 'dailyXpGoal',
            label: 'Your Daily XP Goal',
            options: [
              { id: 15, name: 'Easy (15 XP per day)' },
              { id: 20, name: 'Regular (20 XP per day)' },
              { id: 30, name: 'Hard (30 XP per day)' },
              { id: 100, name: 'Extreme (100 XP per day)' },
              { id: 200, name: 'Insane (200 XP per day)' },
            ],
          })
        }
        {
          AppUI.siteIsDefault && this.form.toggle({
            margin: '0 0 20px 0',
            name: 'enableStreakExtension',
            label: `Get an extra Streak day at ${this.streakExtendGoal} XP`,
          })
        }
        {
          AppUI.siteIsDefault && this.form.toggle({
            margin: '0 0 20px 0',
            name: 'blockUserTracking',
            label: 'Block tracking of user activity',
          })
        }
        {
          AppUI.siteIsDefault && AppUI.user.languageId !== 'EN' && this.form.toggle({
            margin: '0 0 20px 0',
            name: 'onlyShowNativeLanguage',
            label: 'Only show my native language (when possible)',
          })
        }
        {
          AppUI.siteIsDefault && this.form.toggle({
            margin: '0 0 20px 0',
            name: 'hideOnLeaderboard',
            label: 'Hide my name on leaderboards',
          })
        }
        {
          AppUI.siteIsDefault && this.form.toggle({
            margin: '0 0 20px 0',
            name: 'hideTriviaRecord',
            label: 'Hide my trivia win/loss record',
          })
        }
      </Fields>
    );
  }

  render() {
    return (
      <Wrapper>
        {
          this.showConfirmDeleteModal &&
            <Modal
              onCloseModal={() => this.showConfirmDeleteModal = false}
              width={350}
            >
              <DeleteModal>
                <h3>Delete Account</h3>
                {
                  !this.showDeletionConfirmation && !this.showDeletionSpinner &&
                    <>
                      Are you sure that you want to delete your account and all of your data? This is not a reversible
                      action.
                      {
                        this.props.activeStripeSubscriptionExists &&
                        <p>
                          Deleting your account will also cancel your current subscription.
                        </p>
                      }
                      <ButtonGroup>
                        <ButtonWrapper>
                          <Button
                            onClick={this.onDeleteUser}
                            background={Theme.green}
                            width='100%'
                          >
                            <i className='fa fa-check'/>
                            Confirm
                          </Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                          <Button
                            onClick={() => this.showConfirmDeleteModal = false}
                            background={Theme.red}
                            width='100%'
                          >
                            <i className='fa fa-times'/>
                            Cancel
                          </Button>
                        </ButtonWrapper>
                      </ButtonGroup>
                    </>
                }
                {
                  this.showDeletionSpinner &&
                    <div style={{textAlign: 'center'}}>
                      <div>Your account is being deleted.</div>
                      <div>You will be signed out.</div>
                      <Spinner background={AppUI.site.accentColor} />
                    </div>
                }
                {
                  this.showDeletionConfirmation && !this.deletionSuccess &&
                    <div style={{textAlign: 'center'}}>
                      <div>Your account will be deleted by our team within 72 hours.</div>
                      <br />
                      <div>You can contact our support team if you have any questions.</div>
                      <Link
                        to={{name: 'contact'}}
                      >
                        <Button
                          background={AppUI.siteIsDefault ? Theme.blue : AppUI.site.accentColor}
                          margin='10px 0 0 0'
                        >
                          Contact Us
                        </Button>
                      </Link>
                    </div>
                }
              </DeleteModal>
            </Modal>
        }
        <Text
          heading='3'
          margin='0 0 20px 0'
        >
          User Settings
        </Text>
        <SettingsUserImage />
        {
          this.formLoaded &&
            <Form
              form={this.form}
              fields={this.fields()}
              {...this.props}
            />
        }
        <OutlineButton
          selectedBackground={AppUI.siteIsDefault ? '#CCC' : Theme.red}
          margin='10px 0 0 0'
          onClick={() => this.showConfirmDeleteModal = true}
        >
          Delete Account
        </OutlineButton>
      </Wrapper>
    );
  }
}

export default SettingsBillingWrapper(SettingsUserProfile);
