import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { PodcastEpisodeStore } from '@seedlang/stores';
import { isPresent, isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { observer } from 'mobx-react';
import React from 'react';
import DownloadLinkFileUpload from './download_link_file_upload';
import DownloadLinkCopyFromWeb from './download_link_copy_from_web';
import Paginator from 'components/paginator';
import InfoTooltip from 'components/info_tooltip';
import BackendExplainer from 'pages/creator/back_end_explainer';
import { Link } from 'react-router';

const UploadScreenWrapper = styled.div`
  padding-bottom: 20px;
  .episode-search-input {
    border: 1px solid #AAA!important;
  }
`;

const BackButtonWrapper = styled(Link)`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  cursor: pointer;
  .fa-angle-left {
    font-size: 20px;
  }
`;

@observer
class DownloadLinkUploadScreen extends React.Component {
  @autobind onSearchChange(event) {
    const searchTerm = event.target.value;
    if (isPresent(this.props.downloadLinkType?.podcast)) {
      if (isBlank(searchTerm)) {
        PodcastEpisodeStore.setFilter('title', null);
      } else {
        PodcastEpisodeStore.setFilter('title', `~${searchTerm}~`);
      }
      PodcastEpisodeStore.getIndex({limit: 5});
    }
  }

  constructor(props) {
    super(props);
    if (isPresent(props.downloadLinkType?.podcast)) {
      PodcastEpisodeStore.setFilter('podcast_id', props.downloadLinkType.podcast.id);
      PodcastEpisodeStore.getIndex({limit: 5});
    }
  }

  componentDidUnmount() {
    PodcastEpisodeStore.clearIndexData();
    PodcastEpisodeStore.clearFilter();
  }

  @autobind hideUploadScreen() {
    this.props.onHideUploadScreen();
  }

  render() {
    return (
      <>
        <BackButtonWrapper
          onClick={this.hideUploadScreen}
        >
          <i className='fa fa-angle-left' />
          Back to Link for {this.props.worksheet.truncatedName(30)}
        </BackButtonWrapper>

        <UploadScreenWrapper>
          <div className='backend-title'>
            <i className={`fa fa-${this.props.downloadLinkType.icon}`} />
            {this.props.downloadLinkType.title}
            <span style={{ fontStyle: 'italic' }}>{this.props.downloadLinkType.subtitle}</span>
          </div>

          {
            isPresent(this.props.downloadLinkType.podcast) &&
              <div style={{ margin: '0 0 20px 0' }}>
                <div className='backend-subtitle'>
                  {`Select an Episode from ${this.props.downloadLinkType.podcast.title}`}
                  <InfoTooltip>Select a podcast episode to use as the download link.</InfoTooltip>
                </div>
                <div>
                  <table className='table-wrapper admin-table'>
                    <tbody>
                      {
                        PodcastEpisodeStore.indexData.map(episode => (
                          <tr key={episode.id}>
                            <td>
                              {episode.title}
                            </td>
                            <td style={{ whiteSpace: 'nowrap' }}>{episode.publishedAt?.fullTextFormattedDateWithYear}</td>
                            <td style={{ whiteSpace: 'nowrap', width: '100px' }}>
                              {
                                isPresent(episode.mp3) && this.props.values[this.props.downloadLinkType.id] === episode.mp3?.url &&
                                  <span style={{ marginLeft: '8px', color: Theme.green }}>
                                    <i className='fa fa-check' /> Selected
                                  </span>
                              }
                              {
                                isPresent(episode.mp3) && this.props.values[this.props.downloadLinkType.id] !== episode.mp3?.url &&
                                  <button
                                    className='gray-button'
                                    style={{height: '20px'}}
                                    onClick={() => this.props.onSelectEpisode(episode)}
                                  >
                                    Select
                                  </button>
                              }
                              {
                                isBlank(episode.mp3) &&
                                  <span style={{ color: Theme.gray, fontSize: '8px', lineHeight: '10px' }}>
                                    No audio file available
                                  </span>
                              }
                            </td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </table>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '15px', marginTop: '15px' }}>
                    <Paginator
                      store={PodcastEpisodeStore}
                    />
                    <input
                      type='text'
                      className='episode-search-input'
                      placeholder='Search episodes...'
                      onChange={this.onSearchChange}
                    />
                  </div>
                </div>
              </div>
          }
          <div style={{ margin: '0 0 20px 0' }}>
            <div className='backend-subtitle'>Upload a File from your Computer</div>
            <div style={{ fontSize: 14 }}>
              Select file to upload from your computer:
            </div>
            <div style={{ margin: '10px 0' }}>
              <DownloadLinkFileUpload
                worksheet={this.props.worksheet}
                downloadLinkType={this.props.downloadLinkType}
                onChange={this.props.onChange}
              />
            </div>
          </div>

          <div style={{ margin: '0 0 20px 0' }}>
            <div className='backend-subtitle'>Enter a Link</div>
            <div style={{ fontSize: 14 }}>
              Enter a link to a file hosted elsewhere that should be copied to our servers:
            </div>
            <DownloadLinkCopyFromWeb
              worksheet={this.props.worksheet}
              downloadLinkType={this.props.downloadLinkType}
              onChange={this.props.onChange}
            />
          </div>

          {
            !isPresent(this.props.downloadLinkType.podcast) && (
              <BackendExplainer compact background='#e0dfdf'>
                If you want to enable podcast episode selection, you need to link a Podcast to this Download Link Type in the Download Links settings.
              </BackendExplainer>
            )
          }
        </UploadScreenWrapper>
      </>
    );
  }
}

export default DownloadLinkUploadScreen;