import React from 'react';
import { observer } from 'mobx-react';
import { ChatUI } from '@seedlang/state';
import DeleteButton from 'components/button/delete_button';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin: 10px;
`;

@observer
class ChatParticipantEdit extends React.Component {

  render() {
    return (
      <Wrapper>
        <DeleteButton
          allowDeletion
          onConfirm={() => ChatUI.onLeaveChat()}
          message='Leave this chat?'
        >
          <Button
            background={Theme.red}
          >
            Leave Chat
          </Button>
        </DeleteButton>
      </Wrapper>
    );
  }
}

export default ChatParticipantEdit;
