import React from 'react';
import { observer } from 'mobx-react';
import { CardIconsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { DeckUI , AppUI } from '@seedlang/state';
import { ClassroomIcon, NoteIcon, BookmarkIcon, VocabThinIcon, SettingsIcon, ChatIconWithCount, DotIconWrapper } from '@seedlang/icons';
import { Theme , Constants } from '@seedlang/constants';
import { flexCenter } from '@seedlang/style_mixins';
import { pixify, isPresent } from '@seedlang/utils';
import LightbulbIcon from 'components/lightbulb_icon';
import MicrophoneToggleIcon from 'components/microphone_toggle_icon';
import MultipleChoiceToggleIcon from 'components/multiple_choice_toggle_icon';
import LanguageButton from 'components/button/language_button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: fit-content;
  top: ${props => props.top};
  right: ${props => props.right};
  left: ${props => props.left};
  gap: 5px;
  z-index: ${Theme.z['card-info-3']};
  .info-icon {
    width: 4px;
  }
  .classroom-icon {
    width: 28px;
    margin-top: -8px;
    svg {
      fill: #333;
    }
  }
  .graph-outline-icon {
    width: 16px;
  }
  .list-with-bullet-icon {
    height: 15px;
    svg {
      width: 18px;
      fill: #333;
    }
  }
  .note-icon {
    width: 21px;
    margin-left: -2px;
  }
  .settings-icon {
    width: 25px;
    fill: #333;
  }
`;

const IconWrapper = styled.div`
  ${flexCenter()}
  cursor: pointer;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const Background = styled.div`
  ${flexCenter()}
  background: ${props => props.minimized ? 'rgba(255, 255, 255, 0.6)' : 'none'};
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  .bookmark-icon {
    svg {
      #border {
        fill: #333;
      }
      #background {
        fill: ${props => props.background};
      }
      width: 18px;
    }
  }
`;

@observer
class CardIcons extends React.Component {

  static defaultProps = {
    right: '15px',
    top: '15px',
  }

  render() {
    return (
      <Wrapper
        right={this.props.right}
        top={this.props.top}
        left={this.props.left}
      >
        {
          this.props.icons.map((item, index) => {
            switch(item) {
              case 'toggleMicrophone':
                return (
                  <IconWrapper
                    onClick={() => this.props.onToggleMicrophone(this.props.card)}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <MicrophoneToggleIcon />
                    </Background>
                  </IconWrapper>
                );
              case 'toggleMultipleChoiceConjugation':
                return (
                  <IconWrapper
                    onClick={() => this.props.onToggleMultipleChoice(this.props.card)}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <MultipleChoiceToggleIcon
                        textInput={AppUI.user.textInputConjugationTrainer}
                      />
                    </Background>
                  </IconWrapper>
                );
              case 'toggleMultipleChoiceNumber':
                return (
                  <IconWrapper
                    onClick={() => this.props.onToggleMultipleChoice(this.props.card)}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <MultipleChoiceToggleIcon
                        textInput={AppUI.user.textInputNumberTrainer}
                      />
                    </Background>
                  </IconWrapper>
                );
              case 'toggleReview':
                return (
                  <IconWrapper
                    onClick={this.props.onToggleReview}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background
                      background={isPresent(DeckUI.reviewCard(this.props.card)) ? Theme.orange : '#FFF'}
                    >
                      <BookmarkIcon />
                    </Background>
                  </IconWrapper>
                );
              case 'deckGrammar':
                return (
                  <IconWrapper
                    onClick={() => DeckUI.gotoDeckGrammar()}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <ClassroomIcon />
                    </Background>
                  </IconWrapper>
                );
              case 'tenseDescription':
                return (
                  <IconWrapper
                    onClick={() => DeckUI.gotoTenseDescription()}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <ClassroomIcon />
                    </Background>
                  </IconWrapper>
                );
              case 'cardComments':
                if (!DeckUI.user.guest || this.props.card.commentsCount > 0) {
                  return (
                    <IconWrapper
                      onClick={() => DeckUI.gotoCardComments(this.props.card)}
                      key={item}
                      width={pixify(this.props.iconWidth)}
                      height={pixify(this.props.iconWidth)}
                      right={pixify(this.props.iconRightPos)}
                      top={pixify(this.props.iconTop(index))}
                    >
                      <Background>
                        <ChatIconWithCount
                          width={DeckUI.layout.minimumHeight ? 18 : 22}
                          count={this.props.card.commentsCount}
                          fontSize={DeckUI.layout.minimumHeight ? '8px' : '10px'}
                          top={DeckUI.layout.minimumHeight ? '-2px' : '-1px'}
                        />
                      </Background>
                    </IconWrapper>
                  );
                } else {
                  return;
                }
              case 'cardNote':
                return (
                  <IconWrapper
                    onClick={() => DeckUI.gotoCardNote(this.props.card)}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <NoteIcon
                        showText={isPresent(this.props.card.note)}
                      />
                    </Background>
                  </IconWrapper>
                );
              case 'reviewDetails':
                return (
                  <IconWrapper
                    onClick={() => DeckUI.gotoReviewDetails(this.props.card)}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <DotIconWrapper
                        showDot={this.props.card.userSubmission && this.props.card.cardTypeId === Constants.REVIEW_CARD_TYPES.find(item => item.slug === 'user_submission').id && isPresent(this.props.card.word?.target.videoClip)}
                        width={DeckUI.layout.minimumHeight ? 18 : 22}
                        dotSize={DeckUI.layout.minimumHeight ? 10 : 14}
                        dotColor={Theme.green}
                      >
                        <SettingsIcon/>
                      </DotIconWrapper>
                    </Background>
                  </IconWrapper>
                );
              case 'vocabCard':
                return (
                  <IconWrapper
                    onClick={() => DeckUI.gotoVocabCard(this.props.card)}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <DotIconWrapper
                        showDot={this.props.card.word.vocabListSentencesCount > 0}
                        width={DeckUI.layout.minimumHeight ? 18 : 22}
                        dotSize={DeckUI.layout.minimumHeight ? 10 : 14}
                      >
                        <VocabThinIcon />
                      </DotIconWrapper>
                    </Background>
                  </IconWrapper>
                );
              case 'grammarTip':
                return (
                  <IconWrapper
                    onClick={() => DeckUI.gotoGrammarTip(this.props.card)}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <LightbulbIcon
                        width='24px'
                        margin='-3px 0 0 0'
                        on={!this.props.cardState.grammarTipSeen}
                      />
                    </Background>
                  </IconWrapper>
                );
              case 'languageButton':
                return (
                  <IconWrapper
                    onClick={() => this.props.cardState.toggleShowType()}
                    key={item}
                    width={pixify(this.props.iconWidth)}
                    height={pixify(this.props.iconWidth)}
                    right={pixify(this.props.iconRightPos)}
                    top={pixify(this.props.iconTop(index))}
                  >
                    <Background>
                      <LanguageButton
                        languageId={this.props.cardState.showType === 'target' ? AppUI.deck?.languageId || this.props.card?.languageId || AppUI.targetLanguageId || 'DE' : this.props.card.sentence.sourceTextForUserLanguageId(DeckUI.user) || 'EN'}
                        useUkFlagForEn={DeckUI.user.useBritishEnglish}
                      />
                    </Background>
                  </IconWrapper>
                );
              default:
                return (
                  <span
                    key={item}
                  />
                );
            }
          })
        }
      </Wrapper>
    );
  }
}

export default CardIconsWrapper(CardIcons);
