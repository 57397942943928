import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';
import { Theme } from '@seedlang/constants';
import isBlank from 'is-blank';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  input[type="password"] {
    text-align: left;
  }
`;

const FormWrapper = styled.div`
  max-width: 600px;
`;

const Label = styled.div`
  font-size: 14px;
`;

@observer
class UserResetPassword extends React.Component {
  @observable password;
  @observable passwordConfirmation;
  @observable resetPasswordToken;
  @observable displayErrorMessage = false;
  @observable displaySuccessMessage = false;

  constructor(props) {
    super(props);
    this.resetPasswordToken = window.location.search.split('=')[1];
  }

  @computed get disabled() {
    return isBlank(this.password) || this.password !== this.passwordConfirmation || this.password.length < 6;
  }

  @autobind onSubmitPassword() {
    AppUI.userStore.resetPassword({data: {
      password: this.password,
      passwordConfirmation: this.passwordConfirmation,
      resetPasswordToken: this.resetPasswordToken,
    }}, this.afterSubmitPassword);
  }

  @autobind afterSubmitPassword(resp) {
    if (resp.success) {
      this.displaySuccessMessage = true;
    } else {
      this.displayErrorMessage = true;
    }
  }

  render() {
    return (
      <Wrapper>
        <h3>Reset Your Password</h3>
        {
          this.displaySuccessMessage &&
            <Alert
              background={Theme.green}
            >
              Your password has been changed. Please sign in with your new password <a href='/onboarding/sign_in'>here</a>.
            </Alert>
        }
        {
          this.displayErrorMessage &&
            <Alert>
              There was a problem changing your password. Please try again or contact us for assistance using our <a href='/contact'>"Contact Us"</a> page.
            </Alert>
        }
        {
          !this.resetPasswordToken &&
            <Alert
              margin='20px 0 0 0'
            >
              You are missing your password token. Please click on the button from your email again or contact us for assistance using our <a href='/contact'>"Contact Us"</a> page.
            </Alert>
        }
        {
          this.resetPasswordToken &&
            <FormWrapper>
              <Label>New Password</Label>
              <input
                type='password'
                value={this.password || ''}
                onChange={e => this.password = e.currentTarget.value}
                style={{marginBottom: 10}}
              />
              <Label>Password Confirmation</Label>
              <input
                type='password'
                value={this.passwordConfirmation || ''}
                onChange={e => this.passwordConfirmation = e.currentTarget.value}
              />
              <Button
                margin='20px 0 0 0'
                onClick={this.onSubmitPassword}
                backgroundColor={Theme.green}
                width='100%'
                disabled={this.disabled}
              >
                Submit
              </Button>
            </FormWrapper>
        }
      </Wrapper>
    );
  }
}

export default UserResetPassword;
