import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import InPlaceText from 'components/form/in_place_text';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';

@observer
class SiteCreate extends React.Component {
  @observable title;

  @autobind onClick() {
    const data = {
      siteId: AppUI.user.site.id,
      title: this.refs.title.value,
    };

    AppUI.downloadLinkTypeStore.create({ data: data }, this.props.afterCreate);
    this.refs.title.value = '';
  }

  render() {
    return (
      <div className='row' style={{marginBottom: '10px'}}>
        <div className='col-xs-9'>
          <input
            placeholder='Add a Download Link Title'
            ref='title'
          />
        </div>
        <div className='col-xs-2' style={{marginLeft: '10px'}}>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default SiteCreate;
