import React from 'react';
import { observer } from 'mobx-react';
import { CardTypeStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import CardTypeCreate from 'pages/builder/card_types/card_type_create';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

@observer
class CardTypeIndex extends React.Component {

  constructor(props) {
    super(props);
    this.loadCardTypes();
  }

  onDelete(id) {
    CardTypeStore.destroy({ ids: { cardTypeId: id } });
  }

  @autobind loadCardTypes() {
    CardTypeStore.getIndex();
  }

  render() {
    return (
      <div className='card-type-index'>
        <CardTypeCreate />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='40' />
              <th>Pos</th>
              <th>Slug</th>
              <th>Name</th>
              <th>Word Type</th>
              <th>Learning Card</th>
              <th>Allow Reviews</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              CardTypeStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.card_types.edit', params: { cardTypeId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <InPlaceText
                        model='card_types'
                        field='position'
                        defaultValue={item.position}
                        id={item.id}
                        type='number'
                        step='1'
                        afterChange={this.loadCardTypes}
                      />
                    </td>
                    <td>
                      {item.slug}
                    </td>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.wordType?.name}
                    </td>
                    <td>
                      <InPlaceCheckbox
                        id={item.id}
                        model='card_types'
                        field='learning_card'
                        value={item.learningCard}
                        icon='check'
                        afterChange={this.loadCardTypes}
                      />
                    </td>
                    <td>
                      <InPlaceCheckbox
                        id={item.id}
                        model='card_types'
                        field='allow_reviews'
                        value={item.allowReviews}
                        icon='check'
                        afterChange={this.loadCardTypes}
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message='Delete this card type?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={CardTypeStore}
        />
      </div>
    );
  }
}

export default CardTypeIndex;
