import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { DeckUI } from '@seedlang/state';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  margin: ${props => props.margin || 0};
  border-bottom-left-radius: ${props => props.borderBottomLeftRadius}px;
  border-bottom-right-radius: ${props => props.borderBottomRightRadius}px;
  display: flex;
  flex-direction: row;
  z-index: ${Theme.z['card-1']};
  overflow: hidden;
  margin-top: 10px;
`;

const CardButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

@observer
class CardButtons extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.buttonHeight)}
        width={pixify(DeckUI.layout.innerDeckWidth)}
        margin={this.props.margin}
        borderBottomLeftRadius={DeckUI.layout.isMobile ? 0 : 20}
        borderBottomRightRadius={DeckUI.layout.isMobile ? 0 : 20}
      >
        <CardButtonsWrapper>
          {this.props.children}
        </CardButtonsWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(CardButtons);
