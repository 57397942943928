import React from 'react';
import { observer } from 'mobx-react';
import LanguageEdit from 'pages/builder/languages/language_edit';

@observer
class SiteLanguageEdit extends React.Component {

  render() {
    return (
      <LanguageEdit
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SiteLanguageEdit;
