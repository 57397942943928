import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import uuid from 'uuid/v4';
import { isFunction } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { isPresent, isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { observable } from 'mobx';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props.margin};
  text-transform: none;
  cursor: ${props => props.cursor};
  i {
    color: ${props => props.color};
  }
`;

const Message = styled.div`
  position: absolute;
  font-size: 12px;
  background: #ff8700;
  color: white;
  padding: 5px;
`;

@observer
class InfoTooltip extends React.Component {
  @observable messageIsDisplayed = false;

  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  static defaultProps = {
    icon: 'info-circle',
    color: '#333',
    position: 'top',
    margin: '0 5px',
  }

  @autobind showMessage() {
    this.messageIsDisplayed = true;
    setTimeout(() => this.messageIsDisplayed = false, 1300);
  }

  render() {
    return (
      <Wrapper
        style={this.props.style}
        className='info-tooltip'
        data-tip
        data-for={this.uuid}
        margin={this.props.margin}
        color={this.props.color}
        onClick={this.props.onClick}
        cursor={isFunction(this.props.onClick) || isPresent(this.props.clickToCopy) ? 'pointer' : 'default'}
      >
        {
          isPresent(this.props.clickToCopy) &&
          <>
            <CopyToClipboard
              text={this.props.clickToCopy}
              onCopy={this.showMessage}
            >
              <div>
                <i className={`fa fa-${this.props.icon}`}/>
                <ReactTooltip
                  place={this.props.position}
                  type='dark'
                  effect='solid'
                  id={this.uuid}
                  class='custom-tooltip'
                >
                  {this.props.children}
                </ReactTooltip>
              </div>
            </CopyToClipboard>
            {
              this.messageIsDisplayed &&
              <Message>
                copied!
              </Message>
            }
          </>
        }
        {
          isBlank(this.props.clickToCopy) &&
            <>
              <i className={`fa fa-${this.props.icon}`}/>
              <ReactTooltip
                place={this.props.position}
                type='dark'
                effect='solid'
                id={this.uuid}
                class='custom-tooltip'
              >
                {this.props.children}
              </ReactTooltip>
            </>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(InfoTooltip);
