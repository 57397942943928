import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import PieChart from 'components/visualization/pie_chart';
import Legend from 'components/visualization/legend';
import ReactTooltip from 'react-tooltip';
import Uuid from 'uuid/v4';

const Wrapper = styled.div`
  ${flexCenterColumn()}
`;

const PieChartWrapper = styled.div`
  ${flexCenterColumn()}
`;

const KeyWrapper = styled.div`
  ${flexCenter()}
  margin: ${props => props.margin};
`;

const TextWrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  margin: -5px 0 15px 0;
`;

@observer
class PieChartWithLegend extends React.Component {
  @observable id;

  constructor(props) {
    super(props);
    this.id = Uuid();
  }

  render() {
    return (
      <Wrapper
        data-tip
        data-for={this.id}
      >
        <PieChartWrapper>
          <PieChart
            hideSlicesLabels
            hideTooltip
            fontSize={this.props.fontSize}
            colors={this.props.colors}
            data={this.props.data}
            width={this.props.width}
            count={this.props.count}
            margin={this.props.margin}
          />
          {
            this.props.tooltipText &&
              <ReactTooltip
                place='top'
                type='dark'
                id={this.id}
                class='custom-tooltip'
              >
                {this.props.tooltipText}
              </ReactTooltip>
          }
        </PieChartWrapper>
        {
          this.props.subText &&
            <TextWrapper>
              {this.props.subText}
            </TextWrapper>
        }
        <KeyWrapper>
          {
            this.props.legends.map(item => {
              return (
                <Legend
                  key={item[0]}
                  iconBackground={item[0]}
                  text={item[1]}
                />
              );
            })
          }
        </KeyWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PieChartWithLegend);
