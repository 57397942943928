import React from 'react';
import { computed, observable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { isPresent, isBlank } from '@seedlang/utils';
import Filter from 'components/backend_filter/filter';
import { ConceptStore, LevelStore, SentenceStore, WordStore, WordTypeStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import WordSwap from 'pages/builder/sentences/word_swap';
import cx from 'classnames';
import SortableColumns from 'components/hoc/sortable_columns';
import Modal from 'components/modal';
import WordCreate from 'pages/builder/words/word_create';
import WordBatchCreate from 'pages/builder/words/word_batch_create';
import autobind from 'autobind-decorator';
import WordIndexRow from 'pages/builder/words/word_index_row';
import ConceptWordIndex from 'pages/builder/concept_words/concept_word_index';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';
import InfoTooltip from 'components/info_tooltip';
import { AppUI } from '@seedlang/state';
import Alert from 'components/alert';
import { Link } from 'react-router';

const Wrapper = styled.div`
  .word-index {
    .prefix-wrapper {
      display: inline-block;
      font-size: 12px;
      cursor: pointer;
      width: 60px;
      .text {
        display: block;
      }
      .remove {
        display: none;
      }
      .fa-download {
        color: ${Theme.red};
        font-size: 12px;
      }
    }
    .prefix-wrapper:hover {
      .text {
        display: none;
      }
      .remove {
        text-decoration: underline;
        display: block;
      }
    }
  }
`;

const ExportButton = styled.div`
  cursor: pointer;
  margin: 20px 0 10px 0;
  background: #cccccc;
  padding: 5px;
  border-radius: 10px;
  a { 
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
  }
  font-size: 14px;
  color: #25343e;
`;

@observer
class WordIndex extends React.Component {
  @observable swapWordAssociation;
  @observable conceptWord;
  @observable conceptWordAssociation;
  @observable showCreate;
  @observable showBatchCreate;
  @observable hideNewRootsAlert;
  @observable newRoots = [];

  @computed get words() {
    return this.props.words ? this.props.words : WordStore.indexData;
  }

  @computed get hasSmartyParallelTokenisation() {
    return SentenceStore.showData.tokenisation === 'smarty_parallel';
  }

  smartyWordAssociation(wordAssociation) {
    if (this.hasSmartyParallelTokenisation && SentenceStore.showData.smartyAssociationsMapping) {
      const smartyWordAssocId = SentenceStore.showData.smartyAssociationsMapping.matched[wordAssociation.id] || null;
      if (smartyWordAssocId) {
        return toJS(SentenceStore.showData.smartyWordAssociations).find(item => item.id === smartyWordAssocId);
      }
    }
    return null;
  }

  componentDidMount() {
    const page = window.location.href.split('page/')[1];
    WordStore.setPage(page);
    if (!LevelStore.hasIndexData) {
      LevelStore.getIndex();
    }
    if (!WordTypeStore.hasIndexData) {
      WordTypeStore.getIndex();
    }
    ConceptStore.setFilter('highlight_in_edit', true, {refresh: true});
  }

  componentWillUnmount() {
    WordStore.clearIndexData();
    ConceptStore.clearIndexData();
    ConceptStore.clearFilter();
  }

  @autobind onDelete(id) {
    if (this.props.onDelete) {
      this.props.onDelete(id);
    } else {
      WordStore.destroy({ ids: { wordId: id } });
    }
  }

  @autobind onChange() {
    if (this.props.onChange) {
      this.props.onChange();
    } else if (this.props.scopedToSentence) {
      SentenceStore.reloadShow();
    } else {
      WordStore.reloadIndexFiltered();
    }
  }

  @autobind afterChangeLevel() {
    if (this.props.scopedToSentence) {
      SentenceStore.reloadShow();
    }
  }

  @autobind afterInfinitiveChange() {
    this.onChange();
  }

  @autobind showSwapWordAssociation(wordAssociation) {
    this.swapWordAssociation = wordAssociation;
  }

  @autobind showConcepts(word, wordAssociation) {
    this.conceptWord = word;
    this.conceptWordAssociation = wordAssociation;
  }

  @autobind clearConcepts() {
    this.conceptWord = null;
    this.conceptWordAssociation = null;
  }

  @autobind onRootCreate(resp) {
    this.hideNewRootsAlert = false;
    this.newRoots.push(resp.word);
  }

  @computed get showLang() {
    return isBlank(WordStore.filters['language_id']);
  }

  render() {
    return (
      <Wrapper>
        <div className='word-index'>
          {
            isPresent(this.conceptWord) &&
              <ConceptWordIndex
                onCloseModal={this.clearConcepts}
                word={this.conceptWord}
                wordAssociation={this.conceptWordAssociation}
                sentence={this.props.sentence}
                onChange={this.onChange}
              />
          }
          {
            !this.props.scopedToSentence && this.showCreate &&
              <Modal
                onCloseModal={() => this.showCreate = false}
              >
                <WordCreate />
              </Modal>
          }
          {
            !this.props.scopedToSentence && this.showBatchCreate &&
              <Modal
                onCloseModal={() => this.showBatchCreate = false}
                height='600px'
              >
                <WordBatchCreate />
              </Modal>
          }
          {
            isPresent(this.swapWordAssociation) &&
              <WordSwap
                height='600px'
                sentenceId={SentenceStore.showData.id}
                wordAssociation={this.swapWordAssociation}
                onCloseModal={() => this.swapWordAssociation = null}
              />
          }
          {
            !this.props.scopedToSentence && WordTypeStore.hasIndexData &&
              <div style={{display: 'flex'}}>
                <div style={{width: 180}}>
                  {
                    !this.props.scoped &&
                      <button
                        className='gray-button'
                        onClick={() => this.showCreate = true}
                      >
                        <i className='fa fa-plus' />
                        Create
                      </button>
                  }
                  {
                    !this.props.scoped &&
                      <button
                        className='gray-button'
                        onClick={() => this.showBatchCreate = true}
                      >
                        <i className='fa fa-plus' />
                        Batch Create
                      </button>
                  }
                </div>
                <div style={{flex: 1}}>
                  <Filter
                    store={WordStore}
                    defaultSort='frequency_ranking'
                    namespace='builder-word-index'
                    queryStrings={{include_count: true}}
                    filters={
                    [
                      {
                        type: 'select',
                        name: 'language_id',
                        options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}, {id: 'AR', name: 'Arabic'}, {id: 'CA', name: 'Czech'}, {id: 'PT', name: 'Portuguese'}],
                        labelField: 'name',
                        label: 'Language',
                        default: '',
                      },
                      {
                        type: 'text',
                        name: 'target_text',
                        label: 'target',
                        placeholder: 'Enter target',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Root',
                        name: 'root',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'plural',
                        label: 'Is Plural',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'gender',
                        label: 'Has Gender',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        label: 'Approved: Didactics',
                        name: 'reviewed2',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'exclude_from_public_lists',
                        label: 'Hide from Dictionary',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'hide_from_conjugation_trainer',
                        label: 'Hide from Conjugation Trainer',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'hide_from_plural_trainer',
                        label: 'Hide from Plural Trainer',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'hide_from_gender_trainer',
                        label: 'Hide from Gender Trainer',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'needs_secondary_video',
                        label: 'Needs Secondary Video',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'vocab_triad_concept_id',
                        label: 'Has Triad',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'masculine_gender_variant',
                        label: 'Masculine Gender Variant',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'feminine_gender_variant',
                        label: 'Feminine Gender Variant',
                        default: '',
                      },
                      {
                        type: 'multi_select',
                        name: 'gender',
                        label: 'gender',
                        options: [{id: 'masculine', name: 'masculine'}, {id: 'feminine', name: 'feminine'}, {id: 'neuter', name: 'neuter'}],
                        labelField: 'name',
                        default: '',
                      },
                      {
                        type: 'multi_select',
                        name: 'level_id',
                        label: 'Level',
                        options: LevelStore.indexData.filter(item => item.abbreviation !== 'N' && item.abbreviation !== ''),
                        default: LevelStore.pluckIndex('id'),
                        labelField: 'abbreviation',
                      },
                      {
                        type: 'multi_select',
                        name: 'word_type_id',
                        label: 'Word Type',
                        options: WordTypeStore.hasIndexData && WordTypeStore.indexData.map(item => {
                          return {id: item.id, name: item.abbreviation};
                        }),
                        default: WordTypeStore.pluckIndex('id'),
                        highlightDictionaryWordTypes: 'true',
                      },
                      {
                        type: 'number',
                        name: 'sources_count',
                        label: '# Sources',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'en_sources_count',
                        label: '# English Sources',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'sentences_count',
                        label: '# Sentences',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'concepts_count',
                        label: '# Concepts',
                        default: '',
                      },
                      {
                        type: 'boolean',
                        name: 'tree_position',
                        label: 'Is in tree',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'plural_nouns_count',
                        label: '# Plural Forms',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'conjugated_verb_strings_count',
                        label: '# Conjugated Verbs',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'synonyms_count',
                        label: '# Synonyms',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'word_derivation_stems_count',
                        label: '# Derivation Stems',
                        default: '',
                      },
                      {
                        type: 'number',
                        name: 'word_derivation_children_count',
                        label: '# Derivation Children',
                        default: '',
                      },
                      {
                        type: 'date',
                        name: 'created_at',
                        label: 'Created',
                        default: '',
                      },
                    ]
                    }
                  />
                </div>
              </div>
          }
          {
            this.newRoots.length > 0 && !this.hideNewRootsAlert &&
              <Alert
                margin='10px 0'
                showCloseButton
                onClose={() => this.hideNewRootsAlert = true}
              >
                <span>{`Please check the newly created root${this.newRoots.length > 1 ? 's are' : ' is'} properly set up: `}</span>
                {
                  this.newRoots.map((root, index) => {
                    return (
                      <>
                        <Link
                          to={{ name: 'builder.words.edit', params: { wordId: root.id } }}
                          className='underline'
                          onlyActiveOnIndex
                        >
                          {root.targetText}
                        </Link>
                        { this.newRoots.length > index + 2 && ', ' }
                      </>
                    );
                  })
                }
              </Alert>
          }
          <table className='table-wrapper admin-table'>
            <thead>
              <tr>
                <th width='15'></th>
                {
                  !this.props.scopedToSentence &&
                    <th width='30' />
                }
                <th width={this.props.scopedToSentence ? '90' : '20'}></th>
                {
                  !this.props.scopedToSentence &&
                    <th
                      width='30'
                      className={cx('sortable', this.props.columnClassNames('frequency_ranking'))}
                      onClick={this.props.onSortColumn.bind(this, 'frequency_ranking')}
                    >
                      Freq
                    </th>
                }
                {
                  !this.props.scopedToSentence &&
                    <th
                      width='30'
                      className={cx('sortable', this.props.columnClassNames('sentences_count'))}
                      onClick={this.props.onSortColumn.bind(this, 'sentences_count')}
                    >
                      Snt
                    </th>
                }
                {
                  !this.props.scopedToSentence &&
                    <th
                      width='50'
                      className={cx('sortable', this.props.columnClassNames('tree_position'))}
                      onClick={this.props.onSortColumn.bind(this, 'tree_position')}
                      data-tip data-for='tree_position'
                    >
                      <span>Tree</span>
                      <ReactTooltip
                        place='top'
                        type='dark'
                        effect='solid'
                        id='tree_position'
                        class='custom-tooltip'
                      >
                        Position in the tree of the first node<br/>this word is introduced in.
                      </ReactTooltip>
                    </th>
                }
                {
                  !this.props.scopedToSentence && this.showLang &&
                    <th
                      width='30'
                    >
                      Lang
                    </th>
                }
                {
                  !this.props.scopedToSentence &&
                    <th
                      width='100'
                      className={cx('sortable', this.props.columnClassNames('created_at'))}
                      onClick={this.props.onSortColumn.bind(this, 'created_at')}
                    >
                      Created at
                    </th>
                }
                {
                  this.hasSmartyParallelTokenisation &&
                  <th width='50'></th>
                }
                <th width='50'>Type</th>
                <th
                  width='100'
                  className={cx('sortable', this.props.columnClassNames('target_text'))}
                  onClick={this.props.onSortColumn.bind(this, 'target_text')}
                >
                  Target
                </th>
                <th width='120'>Source</th>
                {
                  this.props.scopedToSentence && !this.props.hideCase &&
                    <th width='40'>Case</th>
                }
                {
                  this.props.scopedToSentence &&
                    <th width='40'>Tense</th>
                }
                <th width='60'>Root</th>
                <th width='60'>Plural</th>
                {
                  WordStore.filters['language_id'] !== 'DE' && !this.props.scopedToSentence && !this.props.hideGenderVariant &&
                    <th
                      width='100'
                      data-tip
                      data-for='gender-var-heading'
                    >
                      <span>Gender var.</span>
                      <InfoTooltip
                        margin='1px 0 0px 5px'
                      >
                        For example in German, <i>Schülerin</i> is the gender variant of <i>Schüler</i>.
                      </InfoTooltip>
                    </th>
                }
                <th width='70'></th>
                <th width='40'>Excl</th>
                {
                  WordStore.filters['word_type_id'] && WordStore.filters['word_type_id'].length === 1 && WordStore.filters['word_type_id'][0] === '1a0d84ff-a903-4be9-b0a8-22fdcc211506' && !this.props.scopedToSentence &&
                    <th width='40'>Date</th>
                }
                <th width='120'>Level</th>
                {
                  !this.props.scopedToSentence &&
                    <th width='16'></th>
                }
              </tr>
            </thead>
            <tbody>
              {
                this.props.wordAssociations && this.props.wordAssociations.map((item, index) => {
                  return (
                    <WordIndexRow
                      key={`${item.id}-${index}`}
                      {...this.props}
                      showPlural
                      word={item.word}
                      wordAssociation={item}
                      index={index}
                      setLevel={this.setLevel}
                      isInvalid={this.isInvalid}
                      onChangeWordType={this.onChangeWordType}
                      onChange={this.onChange}
                      afterChangeLevel={this.afterChangeLevel}
                      showSwapWordAssociation={this.showSwapWordAssociation}
                      showConcepts={this.showConcepts}
                      onDelete={this.onDelete}
                      hasSmartyParallelTokenisation={this.hasSmartyParallelTokenisation}
                      smartyWordAssociation={this.smartyWordAssociation(item)}
                      scopedToSentence
                      genderOptions={AppUI.user.targetLanguages.find(item => item.languageId === this.props.sentence?.languageId)?.language.genderOptions}
                      onRootCreate={this.onRootCreate}
                    />
                  );
                })
              }
              {
                !this.props.wordAssociations && this.words.map((item, index) => {
                  if (item && item.id) {
                    return (
                      <WordIndexRow
                        key={`${item.id}-${index}`}
                        {...this.props}
                        showPlural
                        showLang={this.showLang}
                        word={item}
                        hideTense
                        index={index}
                        hideCase={this.props.hideCase}
                        setLevel={this.setLevel}
                        isInvalid={this.isInvalid}
                        onChangeWordType={this.onChangeWordType}
                        onChange={this.onChange}
                        afterChangeLevel={this.afterChangeLevel}
                        showSwapWordAssociation={this.showSwapWordAssociation}
                        showConcepts={this.showConcepts}
                        onDelete={this.onDelete}
                        genderOptions={AppUI.user.targetLanguages.find(tl => tl.languageId === item.word?.languageId)?.language.genderOptions}
                      />
                    );
                  } else {
                    return null;
                  }
                })
              }
            </tbody>
          </table>
          {
            !this.props.scopedToSentence &&
              <Paginator
                updateUrl
                store={WordStore}
              />
          }
          {
            WordStore.hasIndexData &&
              <ExportButton>
                <a
                  href={WordStore.getUrl({}, 'GET', 'exportIndex')}
                >
                  <i className='fa fa-download'/>
                  Export Complete Filtered List as CSV
                  {
                    isPresent(WordStore.totalCount) && WordStore.totalCount > 300 &&
                    <InfoTooltip icon='warning'>
                      <div>The current filters return {WordStore.totalCount} words.</div>
                      <div>The CSV file will only contain the first 300 words.</div>
                    </InfoTooltip>
                  }
                </a>
              </ExportButton>
          }
        </div>
      </Wrapper>
    );
  }
}

export default SortableColumns(WordIndex, WordStore, { sortColumn: 'frequency_ranking', sortIsAscending: true });
