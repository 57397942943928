import React from 'react';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import Paginator from 'components/paginator';
import { UserNotificationStore , NotificationStore , CampaignTypeStore } from '@seedlang/stores';
import NotificationMessage from 'components/notification/notification_message';
import CheckIfTrue from 'components/check_if_true';
import Filter from 'components/backend_filter/filter';
import Copy from 'components/copy';
import ReactTooltip from 'react-tooltip';
import ErrorBoundary from 'components/hoc/error_boundary';

@observer
class NotificationIndex extends React.Component {

  constructor(props) {
    super(props);
    CampaignTypeStore.getIndex();
    if (this.props.userId) {
      UserNotificationStore.getIndex({limit: 10, ids: {userId: this.props.userId}});
    }
  }

  store() {
    return this.props.userId ? UserNotificationStore : NotificationStore;
  }

  @autobind reloadIndex() {
    this.store().getIndex();
  }

  sendMailing(id) {
    NotificationStore.sendMailing({ids: {notificationId: id}}, this.reloadIndex);
  }

  sendTestMailing(id) {
    NotificationStore.sendMailing({data: {test_sending: true}, ids: {notificationId: id}}, this.reloadIndex);
  }

  render() {
    return (
      <div className='notification-index'>
        {
          !this.props.userId &&
            <Filter
              store={NotificationStore}
              defaultSort='-created_at'
              namespace='notification-index'
              queryStrings={{include_count: true}}
              filters={[
                {
                  type: 'select',
                  name: 'campaign_type_id',
                  options: CampaignTypeStore.indexData,
                  label: 'Campaign Type',
                  labelField: 'name',
                },
                {
                  type: 'boolean',
                  name: 'clicked_in_email',
                  default: '',
                  label: 'clicked',
                },
                {
                  type: 'boolean',
                  name: 'seen',
                  label: 'Seen',
                  default: '',
                },
                {
                  type: 'boolean',
                  label: 'Mail Sent',
                  name: 'mail_sent',
                  default: '',
                },
                {
                  type: 'boolean',
                  label: 'Hidden',
                  name: 'hidden',
                  default: '',
                },
              ]}
            />
        }
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th />
              <th>
                Date
              </th>
              {
                !this.props.userId &&
                  <th>
                    User
                  </th>
              }
              <th width='300'>
                Message
              </th>
              <th>
                Mailing
              </th>
              <th>
                Seen
              </th>
              <th>
                Mail
              </th>
              <th>
                Clik
              </th>
              <th>
                Hid
              </th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {
              this.store().indexData.map(item => {
                return (
                  <tr
                    key={item.id}
                  >
                    <td>
                      <Copy
                        text={item.id}
                      />
                    </td>
                    <td>
                      {item.createdAt.formatted}
                    </td>
                    {
                      !this.props.userId &&
                        <td>
                          {
                            item.user && item.user.id &&
                              <Link
                                to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                                className='user-overflow'
                              >
                                {item.user && item.user.name}
                              </Link>
                          }
                        </td>
                    }
                    <td>
                      <NotificationMessage
                        notification={item}
                      />
                    </td>
                    <td>
                      <div className='name-ellipses'>
                        {item.campaignType && item.campaignType.name}
                      </div>
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.seen}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.mailSent}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.clickedInEmail}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.hidden}
                      />
                    </td>
                    <td>
                      <div
                        className='underline'
                        onClick={() => this.sendTestMailing(item.id)}
                        data-tip
                        data-for={`send-test-${item.id}`}
                      >
                        <i className='fa fa-envelope fa-block send-test-mail' />
                        <ReactTooltip
                          place='top'
                          type='dark'
                          effect='solid'
                          id={`send-test-${item.id}`}
                          class='custom-tooltip'
                        >
                          Send Test Email
                        </ReactTooltip>
                      </div>
                    </td>
                    <td>
                      {
                        !item.mailSent &&
                          <div
                            className='underline'
                            onClick={() => this.sendMailing(item.id)}
                            data-tip
                            data-for={`send-${item.id}`}
                          >
                            <i className='fa fa-envelope fa-block send-mail' />
                            <ReactTooltip
                              place='top'
                              type='dark'
                              effect='solid'
                              id={`send-${item.id}`}
                              class='custom-tooltip'
                            >
                              Send Email
                            </ReactTooltip>
                          </div>
                      }
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          ids={{userId: this.props.userId}}
          store={this.store()}
        />
      </div>
    );
  }
}

export default ErrorBoundary(NotificationIndex);
