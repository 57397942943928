import React from 'react';
import { observer } from 'mobx-react';
import { SentenceComprehendCardWrapper } from '@seedlang/hoc';
import MediaSection from 'components/vocab_card/media_section';
import CardSection from 'components/vocab_card/card_section';
import { DeckUI, AppUI } from '@seedlang/state';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import SentenceBreakdown from 'components/sentence_breakdown';
import TextResizer from 'components/text_resizer';
import Text from 'components/text';
import RevealButton from 'components/button/reveal_button';
import CardButtons from 'components/card/card_buttons';
import EvaluateButtons from 'components/card/evaluate_buttons';
import { Constants } from '@seedlang/constants';
import { displayTargetTextCorrectedForLanguage } from '@seedlang/utils';
import SourceEditIcon from 'components/icons/source_edit_icon';

@observer
class SentenceComprehendCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
        showWordCard={this.props.showWordCard}
      >
        <CardContent>
          <MediaSection
            showMedia
            autoPlay={this.props.inView}
            card={this.props.card}
            cardState={this.props.cardState}
            height={DeckUI.layout.contentHeight / 3}
            borderTopRadius={10}
          />
          <CardSection
            background='#FFF'
            padding='20px'
            height={DeckUI.layout.contentHeight / 3}
          >
            {
              (!DeckUI.user.hideVocabTargetUntilRevealed || this.props.cardState.revealed) && this.props.card.isEnriched && this.props.card.sentence && this.props.card.sentence.wordAssociations.length > 0 &&
                <TextResizer
                  resizeText
                  height={(DeckUI.layout.contentHeight / 3) - 10}
                  width={DeckUI.layout.innerDeckWidth - 40}
                  characterLength={this.props.card.sentence && this.props.card.sentence.targetText && this.props.card.sentence.targetText.length}
                >
                  <SentenceBreakdown
                    center
                    showUnderline
                    highlightWordAssociationIds={this.props.card.wordAssociationIds}
                    sentence={this.props.card.sentence}
                  />
                </TextResizer>
            }
            {
              (!DeckUI.user.hideVocabTargetUntilRevealed || this.props.cardState.revealed) && !this.props.card.isEnriched && this.props.card.sentence &&
                <TextResizer
                  resizeText
                  height={(DeckUI.layout.contentHeight / 3) - 10}
                  width={DeckUI.layout.innerDeckWidth - 40}
                  characterLength={this.props.card.sentence && this.props.card.sentence.targetText && this.props.card.sentence.targetText.length}
                >
                  <Text
                    margin='0 -10px 0 0'
                  >
                    {displayTargetTextCorrectedForLanguage(this.props.card.sentence.targetText, this.props.card.sentence.languageId)}
                  </Text>
                </TextResizer>
            }
          </CardSection>
          <CardSection
            padding='20px'
            background='#CCC'
            borderBottomRadius={10}
            height={DeckUI.layout.contentHeight / 3}
          >
            {
              this.props.cardState.revealed && this.props.card.sentence && this.props.card.sentence.sourceText &&
                <TextResizer
                  resizeText
                  height={(DeckUI.layout.contentHeight / 3) - 10}
                  width={DeckUI.layout.innerDeckWidth - 40}
                  characterLength={this.props.card.sentence && this.props.card.sentence.sourceText && this.props.card.sentence.sourceText.length}
                >
                  <Text
                    rtl={Constants.RTL_LANGUAGE_IDS.indexOf(this.props.sourceLanguageId) !== -1}
                  >
                    {this.props.card.sentence.sourceTextForUser(DeckUI.user)}
                  </Text>
                </TextResizer>
            }
            {
              this.props.cardState.revealed &&
                <SourceEditIcon
                  card={this.props.card}
                />
            }
          </CardSection>
        </CardContent>
        {
          !this.props.cardState.revealed &&
            <CardButtons>
              <RevealButton
                card={this.props.card}
                userCard={this.props.userCard}
                cardState={this.props.cardState}
                submitOnEnter
                shortcut='1'
                disabled={!this.props.inView}
              />
            </CardButtons>
        }
        {
          this.props.cardState.revealed &&
            <EvaluateButtons
              card={this.props.card}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              onNextClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard, {score: Constants.EASY_SCORE, xp: 1, xpXPos: DeckUI.layout.quarterDeckWidth * 2})}
              disabled={!this.props.inView}
            />
        }
      </CardWrapper>
    );
  }
}

export default SentenceComprehendCardWrapper(SentenceComprehendCard);
