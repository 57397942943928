import React from 'react';
import { observer } from 'mobx-react';
import { GrammarTreeNodeShowWrapper, VocabWrapper } from '@seedlang/hoc';
import { flexCenter } from '@seedlang/style_mixins';
import styled from '@emotion/styled';
import Spinner from 'components/spinner';
import Text from 'components/text';
import { isPresent } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';
import { Link } from 'react-router';
import Tabs from 'components/tabs';
import VocabSentences from 'components/vocab/vocab_sentences';
import TreeNodeDecks from 'components/tree_node/tree_node_decks';
import VocabList from 'components/vocab/vocab_list';
import ConceptComments from 'components/comment/concept_comments';
import YoutubePlayer from 'components/worksheet/youtube_player';
import TextDisplay from 'components/text_display';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #FFF;
  border-radius: 5px;
`;

const TextWrapper = styled.div`
  flex: 1;
  padding-top: 20px;
`;

const TitleWrapper = styled.div`
  display: flex;
  padding-left: 20px;
`;

const AdminLink = styled.div`
  font-size: 11px;
  text-decoration: underline;
  text-align: center;
  padding: 5px;
`;

const Description = styled.div`
  padding: 0 10px 10px 10px;
`;

const Label = styled.div`
  background: #FFF;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid #CCC;
  margin-bottom: 10px;
  margin: 20px 20px 0 20px;
`;

const LabelTitle = styled.div`
  ${flexCenter()}
  background: #CCC;
  font-size: 11px;
  font-weight: bold;
  padding: 0 15px;
`;

const LabelValue = styled.div`
  ${flexCenter()}
  font-size: 16px;
  font-weight: ${props => props.bold ? 'bold' : null};
  padding: 5px 3px;
`;

@observer
class GrammarTreeNodeShow extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.showSpinner &&
            <Spinner />
        }
        {
          !this.props.showSpinner && this.props.treeNode &&
            <TitleWrapper id='page-top'>
              <TextWrapper>
                <Text heading='2'>{this.props.treeNode?.name}</Text>
                {
                  !this.props.showSpinner && isPresent(this.props.treeNodeGrammarConcept?.concept?.displayNameLine2) &&
                    <Text italic heading='3'>{this.props.treeNodeGrammarConcept?.concept?.displayNameLine2}</Text>
                }
              </TextWrapper>
              {
                this.props.treeNode.level &&
                  <Label>
                    <LabelTitle>Level</LabelTitle>
                    <LabelValue
                      bold
                    >
                      {this.props.treeNode.level.abbreviation}
                    </LabelValue>
                  </Label>
              }
            </TitleWrapper>
        }
        {
          !this.props.showSpinner && isPresent(this.props.treeNodeGrammarConcept) &&
            <Tabs
              tabs={this.props.tabs}
              background={null}
              contentPadding='0'
              margin='0 20px 20px 20px'
            >
              <Description>
                {
                  this.props.treeNodeGrammarConcept?.concept?.youtubeUrl &&
                  <YoutubePlayer
                    show={!AppUI.user.hideYoutubeVideo}
                    onToggleShow={this.props.toggleHideYoutubeVideo}
                    width='100%'
                    url={this.props.treeNodeGrammarConcept?.concept?.youtubeUrl}
                  />
                }
                {
                  this.props.treeNodeGrammarConcept?.concept &&
                    <TextDisplay
                      markdown={this.props.treeNodeGrammarConcept.concept?.longDescriptionMd}
                      htmlText={this.props.treeNodeGrammarConcept.concept?.longDescription}
                    />
                }
              </Description>
              <TreeNodeDecks
                fromGrammarSection
                treeNodeId={this.props.treeNode.id}
                userDecks={this.props.userDecks}
                decks={this.props.treeNode && this.props.treeNode.treeNodeDecks}
              />
              <VocabSentences
                conceptId={this.props.treeNodeGrammarConcept.concept.id}
              />
              <VocabList
                {...this.props}
                grammarSection
                showSpinner={this.props.showVocabSpinner}
                wordStore={this.props.wordStore}
                totalCount={this.props.treeNodeGrammarConcept.concept.rootWordsCount}
                ids={{conceptId: this.props.treeNodeGrammarConcept.concept.id}}
              />
              <ConceptComments
                expanded
                loadOnMount
                hideTitle
                padding='0'
                concept={this.props.treeNodeGrammarConcept?.concept}
              />
            </Tabs>
          }
        {
          AppUI.user.admin && this.props.treeNode && this.props.treeNode.treeId && this.props.treeNode.treeModuleId &&
            <AdminLink>
              <Link
                to={{name: 'builder.tree_nodes.edit', params: {treeNodeId: this.props.treeNode.id, treeId: this.props.treeNode.treeId, treeModuleId: this.props.treeNode.treeModuleId }}}
              >
                Admin Link
              </Link>
            </AdminLink>
        }
      </Wrapper>
    );
  }
}

export default VocabWrapper(GrammarTreeNodeShowWrapper(GrammarTreeNodeShow));
