import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { MembershipStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import MembershipCreate from 'pages/builder/memberships/membership_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

@observer
class MembershipIndex extends React.Component {

  componentDidMount() {
    this.loadMemberships();
  }

  @autobind onDelete(id) {
    MembershipStore.destroy({ids: {membershipId: id}}, this.props.afterChange);
  }

  @autobind loadMemberships() {
    MembershipStore.clearFilter();
    MembershipStore.getIndex();
  }

  @computed get memberships() {
    return this.props.memberships || MembershipStore.indexData;
  }

  render() {
    return (
      <div className='builder-membership-index'>
        <div className='row'>
          <div className='col-xs-12'>
            <MembershipCreate
              membershipGroupId={this.props.membershipGroupId}
              afterChange={this.props.afterChange}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <table className='table-wrapper admin-table'>
              <thead>
                <tr>
                  <th width='20' />
                  <th>Pos</th>
                  <th>Title</th>
                  <th>Experiment</th>
                  <th>Discount</th>
                  <th># Mnth</th>
                  <th>Stripe</th>
                  <th>Cost (USD)</th>
                  <th>Cost (EUR)</th>
                  <th>Mnth (USD)</th>
                  <th>Mnth (EUR)</th>
                  <th>Active</th>
                  <th>Default</th>
                  <th>Recurring</th>
                  <th>Highlight</th>
                  <th width='40'></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.memberships.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={{ name: 'creator.groups.memberships.edit', params: { groupId: this.props.groupId, membershipId: item.id } }}
                            className='on-click'
                          >
                            <i className='fa fa-chevron-right fa-block' />
                          </Link>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceText
                              id={item.id}
                              defaultValue={item.position}
                              model='memberships'
                              field='position'
                              type='number'
                              step='1'
                              afterChange={this.loadMemberships}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceText
                              id={item.id}
                              model='membership'
                              field='title'
                              defaultValue={item.title}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceText
                              id={item.id}
                              model='membership'
                              field='experiment'
                              defaultValue={item.experiment}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceText
                              id={item.id}
                              model='membership'
                              field='discount'
                              defaultValue={item.discount}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceText
                              id={item.id}
                              model='membership'
                              field='number_months'
                              defaultValue={item.numberMonths}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            {item.stripeAccount}
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            {item.costUsd}
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            {item.costEur}
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            {item.costMonthlyUsd}
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            {item.costMonthlyEur}
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceCheckbox
                              id={item.id}
                              model='memberships'
                              icon='check'
                              field='active'
                              value={item.active}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceCheckbox
                              id={item.id}
                              model='memberships'
                              icon='check'
                              field='default'
                              value={item.default}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceCheckbox
                              id={item.id}
                              model='memberships'
                              icon='check'
                              field='recurring'
                              value={item.recurring}
                            />
                          </div>
                        </td>
                        <td>
                          <div className='text'>
                            <InPlaceCheckbox
                              id={item.id}
                              model='memberships'
                              icon='check'
                              field='highlight'
                              value={item.highlight}
                            />
                          </div>
                        </td>
                        <td>
                          <DeleteButton
                            onConfirm={() => this.onDelete(item.id)}
                            message='Delete this Payment Plan?'
                            className='fa-block right'
                          />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
            <Paginator
              store={MembershipStore}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MembershipIndex;
