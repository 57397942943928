import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import cx from 'classnames';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { isArray } from 'lodash';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: ${props => props.borderRadius};
  border-top-right-radius: ${props => props.borderTopRightRadius};
  border-top-left-radius: ${props => props.borderTopLeftRadius};
  border-bottom-right-radius: ${props => props.borderBottomRightRadius};
  border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
  border: ${props => props.border};
  background: ${props => props.background || '#353535'};
`;

const CurrentWrapper = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.direction === 'row' ? 'flex-start' : 'flex-end'};
`;

const Current = styled.div`
  background: ${props => props.background || Theme.green};
  height: ${props => props.margin ? `calc(${props.height} - 1px)` : props.height};
  width: ${props => props.width};
  border-radius: ${props => props.borderRadius};
  border-top-right-radius: ${props => props.borderTopRightRadius};
  border-top-left-radius: ${props => props.borderTopLeftRadius};
  border-bottom-right-radius: ${props => props.borderBottomRightRadius};
  border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
  margin: ${props => props.margin};
`;

@observer
class ProgressBar extends React.Component {
  static defaultProps = {
    direction: 'row',
    height: '5px',
  }

  getDimension(value) {
    const ratio = value === 0 || this.props.total === 0 ? 1 : value / this.props.total;
    return `${ratio * 100}%`;
  }

  @computed get currentValues() {
    return isArray(this.props.current) ? this.props.current : [this.props.current];
  }

  render() {
    return (
      <Wrapper
        className={cx('progress-bar', this.props.className)}
        height={pixify(this.props.height)}
        width={this.props.width}
        background={this.props.background}
        borderRadius={this.props.borderRadius}
        borderTopRightRadius={this.props.borderTopRightRadius}
        borderTopLeftRadius={this.props.borderTopLeftRadius}
        borderBottomRightRadius={this.props.borderBottomRightRadius}
        borderBottomLeftRadius={this.props.borderBottomLeftRadius}
        border={this.props.border}
      >
        <CurrentWrapper
          direction={this.props.direction}
        >
          {
            this.currentValues.map((value, index) => {
              if (value > 0) {
                return (
                  <Current
                    margin={this.props.segmentMargin}
                    borderRadius={this.props.borderRadius}
                    borderTopRightRadius={this.props.borderTopRightRadius}
                    borderTopLeftRadius={this.props.borderTopLeftRadius}
                    borderBottomRightRadius={this.props.borderBottomRightRadius}
                    borderBottomLeftRadius={this.props.borderBottomLeftRadius}
                    key={index}
                    background={this.props.currentBackground || (this.props.currentColors && this.props.currentColors[index])}
                    className={cx('current', `current-${index}`)}
                    height={this.props.direction === 'row' ? '100%' : this.getDimension(value)}
                    width={this.props.direction === 'row' ? this.getDimension(value) : '100%'}
                  />
                );
              } else {
                return null;
              }
            })
          }
        </CurrentWrapper>
        {
          this.props.children
        }
      </Wrapper>
    );
  }
}

export default ProgressBar;
