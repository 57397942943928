import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin-top: 10px;
  ${flexCenter()}
  background: ${props => props.background};
  border-radius: 20px;
  min-height: ${props => props.height};
  width: 100%;
  cursor: pointer;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border: 4px solid white;
  img {
    margin: 0 0 0 10px;
    border-radius: 20px;
    width: ${props => props.imageSize};
    height: ${props => props.imageSize};
  }
  &:hover {
    transform: scale(1.05);
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 10px;
`;

const Name = styled.div`
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  text-align: center;
  font-weight: bold;
  color: white;
  text-align: left;
`;

const Description = styled.div`
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  color: rgba(255, 255, 255, 0.9);
  margin-top: 5px;
  font-style: italic;
`;

const ImageWrapper = styled.div`
  width: 80px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFF;
  border-radius: 10px;
  margin-left: 10px;
  .speaking-icon {
    svg {
      fill: ${Theme.orange};
      width: 63px;
      height: 63px;
    }
  }
  .dictionary-icon {
    svg {
      fill: ${Theme.green};
      width: 55px;
      height: 55px;
    }
  }
  .trivia-circle-icon {
    svg {
      fill: ${Theme.purple};
      padding-left: 6px;
      width: 57px;
      height: 57px;
    }
  }

  .word-list-icon {
    svg {
      fill: ${Theme.purple};
      width: 46px;
      height: 46px;
    }
  }
`;

@observer
class LearningPreferenceButton extends React.Component {

  render() {
    return (
      <Wrapper
        height={this.props.height || '120px'}
        onClick={this.props.onClick}
        background={this.props.background}
        imageSize={this.props.imageSize}
      >
        {
          this.props.icon &&
            <ImageWrapper>
              {this.props.icon}
            </ImageWrapper>
        }
        <TextWrapper>
          <Name
            fontSize={this.props.isMobile ? '19px' : '22px'}
            lineHeight={this.props.isMobile ? '22px' : '25px'}
          >
            {this.props.name}
          </Name>
          <Description
            fontSize='12px'
            lineHeight='14px'
          >
            {this.props.description}
          </Description>
        </TextWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LearningPreferenceButton);
