import React from 'react';
import { observer } from 'mobx-react';
import ExerciseSentence from 'components/exercise/exercise_sentence';
import { ExerciseUI } from '@seedlang/state';
import ReorderAcceptItem from 'components/exercise/reorder_accept_item';
import styled from '@emotion/styled';
import ReorderClickItem from 'components/exercise/reorder_click_item';
import autobind from 'autobind-decorator';
import { computed, observable } from 'mobx';
import { isPresent } from '@seedlang/utils';
import { StickyClickItemWrapper } from './sticky_click_item_wrapper';
const Wrapper = styled.div`

`;

const ReorderClickItemWrapper = styled(StickyClickItemWrapper)`
  width: 100%;
  margin: 0 20px 10px 0;
`;

const ExerciseEntries = styled.div`
`;

const ExerciseEntry = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding-bottom: 5px;
  @media only screen and (max-width : 649px) {
    flex-direction: column;
  }
`;

const ExerciseSentenceWrapper = styled.div`
  flex: 1;
  @media only screen and (max-width : 649px) {
    width: 100%;
  }
`;

const ReorderAcceptItemWrapper = styled.div`
  width: 50%;
  padding-right: 20px;
  @media only screen and (max-width : 649px) {
    width: 100%;
    padding-right: 0px;
  }
`;

@observer
class ExerciseMatchText extends React.Component {

  @observable showMobileClickItems = false;

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }
  @autobind onClick(item) {
    if (!ExerciseUI.exercise.state.revealed) {
      ExerciseUI.assignToNextExerciseEntry(item);
    }
  }

  @autobind onScroll() {
    this.showMobileClickItems = this.exerciseIsInView;
  }

  get exerciseIsInView() {
    const exerciseWrapper = document.getElementById('exercise');
    if (exerciseWrapper) {
      const rect = exerciseWrapper.getBoundingClientRect();
      return (
        rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.bottom >= 0
      );
    }
    return false;
  }

  @computed get clickableExerciseEntries() {
    return ExerciseUI.exercise.shuffledExerciseEntries.filter(item => !item.state.assigned);
  }

  render() {
    return (
      <Wrapper id='exercise'>
        {
          ExerciseUI.exercise.hasExerciseEntries && this.clickableExerciseEntries.length > 0 && (!ExerciseUI.layout.isMobile || this.showMobileClickItems) &&
            <ReorderClickItemWrapper>
              {
                this.clickableExerciseEntries.map(item => {
                    return (
                      <ReorderClickItem
                        onClick={() => this.onClick(item)}
                        key={item.id}
                        margin='2px'
                        assigned={item.state.assigned}
                        height={ExerciseUI.layout.isMobile ? '50px' : 'fit-content'}
                        minHeight='50px'
                        inDeck={this.props.inDeck}
                        whiteSpace={ExerciseUI.layout.isMobile ? 'nowrap' : ''}
                        fontSize={item.sentence?.rightToLeftLanguage ? '16px' : '14px'}
                      >
                        {item.prompt}
                      </ReorderClickItem>
                    );
                })
              }
            </ReorderClickItemWrapper>
        }
        <ExerciseEntries>
          {
            ExerciseUI.exercise.exerciseEntries.map(item => {
              if (isPresent(item.sentence)) {
                return (
                  <ExerciseEntry
                    key={item.id}
                    grammarTip={item.grammarTip}
                  >
                    <ReorderAcceptItemWrapper>
                      <ReorderAcceptItem
                        disabled={ExerciseUI.exercise.state.revealed}
                        value={item.state.assignedExerciseEntryPrompt || ''}
                        onClick={() => ExerciseUI.removeExerciseEntry(item)}
                        correct={ExerciseUI.exercise.state.revealed && item.state.correct}
                        incorrect={ExerciseUI.exercise.state.revealed && !item.state.correct}
                        fontSize={item.sentence?.rightToLeftLanguage ? '16px' : '14px'}
                        id={item.id}
                        justifyContent={ExerciseUI.layout.isMobile ? 'center' : 'right'}
                        height='fit-content'
                        minHeight='50px'
                        borderRadius='0'
                        width={ExerciseUI.layout.isMobile ? '100%' : null}
                      />
                    </ReorderAcceptItemWrapper>
                    {
                      item.sentence &&
                        <ExerciseSentenceWrapper>
                          <ExerciseSentence
                            showTargetText
                            sentence={item.sentence}
                            hideLanguageButton={!item.showSource}
                          />
                        </ExerciseSentenceWrapper>
                    }
                  </ExerciseEntry>
                );
              }
            })
          }
        </ExerciseEntries>
      </Wrapper>
    );
  }
}

export default ExerciseMatchText;
