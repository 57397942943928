import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import CopyToClipboard from 'react-copy-to-clipboard';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  margin: ${props => props.margin};
  position: relative;
  cursor: pointer;
`;

const Text = styled.span`
  display: inline-block;
  margin-left: 5px;
`;

const TextWrapper = styled.span`
  display: flex;
  align-items: center;
  i {
    color: ${props => props.color};
  }
`;

const Message = styled.div`
  position: absolute;
  bottom: -40px;
  font-size: 12px;
  background: #ff8700;
  color: white;
  padding: 5px;
  left: -20px;
`;

@observer
class Copy extends React.Component {
  @observable messageIsDisplayed = false;

  @autobind showMessage() {
    this.messageIsDisplayed = true;
    setTimeout(this.hideMessage, 1300);
  }

  @autobind hideMessage() {
    this.messageIsDisplayed = false;
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <CopyToClipboard
          text={this.props.text}
          onCopy={this.showMessage}
        >
          <TextWrapper
            color={this.props.color}
          >
            {
              !this.props.hideIcon &&
                <i className='fa fa-copy fa-on-click' />
            }
            {
              this.props.children &&
                <Text>
                  {this.props.children}
                </Text>
            }
          </TextWrapper>
        </CopyToClipboard>
        {
          this.messageIsDisplayed &&
            <Message>
              copied!
            </Message>
        }
      </Wrapper>
    );
  }
}

export default Copy;
