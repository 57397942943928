import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { DashboardStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import LineChartWithControls from 'components/visualization/line_chart_with_controls';

const Wrapper = styled.div`

`;

@observer
class PaymentsDashboard extends React.Component {
  @observable counts = {};

  componentDidMount() {
    DashboardStore.api({}, this.afterGetUserCounts, 'GET', 'payments');
  }

  @autobind afterGetUserCounts(resp) {
    this.counts = resp;
  }

  render() {
    return (
      <Wrapper>
        <fieldset
          style={{marginBottom: 10}}
        >
          <legend>
            Payments
          </legend>
          <LineChartWithControls
            namespace='payment-dashboard-new-payments'
            charts={[
              {
                name: 'New Payments',
                resources: [
                  {
                    name: 'Payment',
                    label: 'All Payments',
                    date_field: 'occurred_at',
                    sum_field: 'amount',
                  },
                ],
              },
            ]}
          />
        </fieldset>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PaymentsDashboard);
