import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';
import TextResizer from 'components/text_resizer';
import { isPresent } from '@seedlang/utils';
import { DeckUI } from '@seedlang/state';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  font-size: 26px;
  line-height: 36px;
`;

const ToggleLiteralSource = styled.div`
  background: #FFF;
  color: #3e3c3c;
  font-size: 12px;
  padding: 0 20px;
  border-radius: 40px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  width: 280px;
  text-align: center;
  margin: 0 auto;
  font-weight: bold;
`;

@observer
class CardTextSource extends React.Component {
  @observable showIdiomaticSource = true;

  @computed get source() {
    return this.showIdiomaticSource ? this.props.text : this.props.literalSource;
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.translateToGerman &&
            <Text
              italic
              fontSize='16px'
            >
              Translate to {DeckUI.deck.languageName}
            </Text>
        }
        {
          isPresent(this.source) &&
            <TextResizer
              resizeText
              height={this.props.height - 10}
              width={this.props.width}
              characterLength={this.source.length}
            >
              <Text>
                {this.source}
              </Text>
            </TextResizer>
        }
        {
          this.props.showLiteralSource && this.props.literalSource &&
            <ToggleLiteralSource
              onClick={() => this.showIdiomaticSource = !this.showIdiomaticSource}
            >
              Show { this.showIdiomaticSource ? 'Word for Word Translation' : 'Natural Translation'}
            </ToggleLiteralSource>
        }
      </Wrapper>
    );
  }
}

export default CardTextSource;
