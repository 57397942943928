import React from 'react';
import { observer } from 'mobx-react';
import { ExerciseUI } from '@seedlang/state';
import styled from '@emotion/styled';
import Prompt from 'components/exercise/prompt';
import { isPresent, pixify } from '@seedlang/utils/src';
import { ExerciseMultipleChoiceMediaWrapper } from '@seedlang/hoc';
import ExerciseEntry from 'components/exercise/exercise_entry';
import { isBlank } from '@seedlang/utils';
import Text from 'components/text';

const Wrapper = styled.div`

`;

const MediaOption = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  margin-right: ${props => props.marginRight ? props.marginRight : props.inDeck ? '5px' : '10px'};
  margin-left: ${props => props.inDeck ? '5px' : 0};
  margin-top: ${props => props.inDeck ? '5px' : 0};
  margin-bottom: ${props => props.marginBottom ? props.marginBottom : props.inDeck ? '5px' : 0};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 10px solid #FFF;
  border-radius: 15px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${props => props.flexDirection};
  justify-content: space-around;
`;

const AnswersWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.gridTemplateColumns};
  justify-content: ${props => props.justifyContent};
  width: 100%;
  flex-wrap: wrap;
  max-width: ${props => props.maxWidth}; 
  margin-right: 10px;
  margin-bottom: 5px;
`;

const Button = styled.button`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.color};
  cursor: ${props => props.revealed ? 'default' : 'pointer'};
  border: none;
  font-size: 16px;
  width: ${props => props.width};
  min-height: 50px;
  i {
    color: ${props => props.backgroundColor};
  }
  border-radius: 10px;
  text-transform: ${props => props.textTransform};
  padding: 5px;
  font-weight: bold;
  align-items: center;
  margin: 5px;
  font-size: 14px;
  line-height: 18px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

@observer
class ExerciseMultipleChoiceMedia extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.exercise.exerciseEntries.map(exerciseEntry => {
            return (
              <ExerciseEntry
                inDeck={this.props.inDeck}
                key={exerciseEntry.id}
                grammarTip={exerciseEntry.grammarTip}
                showButtons={!this.props.inDeck && this.props.exercise.exerciseEntries.length > 1}
                exerciseEntry={exerciseEntry}
                flexDirection='column'
                {...this.props}
              >
                {
                  exerciseEntry.prompt &&
                  <Prompt>
                    {exerciseEntry.prompt}
                  </Prompt>
                }
                <InnerWrapper
                  flexDirection={ExerciseUI.layout.isMobile ? 'column' : 'row'}
                >
                  {
                    exerciseEntry.mediaOptions && isPresent(exerciseEntry.mediaOptions) && isPresent(exerciseEntry.mediaOptions[0].image) &&
                      <MediaOption
                        width={pixify(this.props.mediaWidth)}
                        marginBottom={ExerciseUI.layout.isMobile ? '10px' : null}
                        marginRight={ExerciseUI.layout.isMobile ? '0px' : null}
                        inDeck={this.props.inDeck}
                        key={exerciseEntry.mediaOptions[0].id}
                      >
                        {
                          isPresent(exerciseEntry.mediaOptions[0].image) &&
                            <img
                              src={exerciseEntry.mediaOptions[0].image.url}
                              alt=''
                            />
                        }
                      </MediaOption>
                  }
                  <AnswersWrapper
                    maxWidth={this.props.answersMaxWidth(exerciseEntry)}
                    justifyContent={ExerciseUI.layout.viewportWidth < 1150 ? 'center' : 'space-between'}
                    gridTemplateColumns={ExerciseUI.layout.viewportWidth > 1150 && this.props.nbOptions(exerciseEntry) % 2 === 0 ? '48% 48%' : '100%'}
                  >
                    {
                      exerciseEntry.shuffledMultipleChoiceOptions.map(option => {
                        if (!isBlank(option.targetText)) {
                          return (
                            <Button
                              key={option.id}
                              onClick={() => this.props.onClick(exerciseEntry, option)}
                              selected={this.props.isSelected(exerciseEntry, option.id)}
                              backgroundColor={this.props.background(exerciseEntry, option)}
                              color={this.props.color(exerciseEntry, option)}
                              revealed={exerciseEntry.state.revealed}
                              width='100%'
                            >
                              {option.targetText}
                            </Button>
                          );
                        } else {
                          return null;
                        }
                      })
                    }
                  </AnswersWrapper>
                </InnerWrapper>
                {
                  exerciseEntry.numberOfCorrectOptions > 1 && exerciseEntry.selectAllCorrectOptions &&
                    <Text italic fontSize='13px' color='#333' margin='10px 0 0 0'>
                      Select all correct answers.
                    </Text>
                }
              </ExerciseEntry>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ExerciseMultipleChoiceMediaWrapper(ExerciseMultipleChoiceMedia);
