import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { NoMicrophoneIcon } from '@seedlang/icons';
import ReactTooltip from 'react-tooltip';
import { DeckUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: repeating-linear-gradient(
    -45deg,
    whitesmoke,
    whitesmoke 10px,
    #FFF 10px,
    #FFF 20px
  );
`;

@observer
class NoUserInput extends React.Component {

  onClickNoMicrophone() {
    if (!DeckUI.deviceCanRecordMedia) {
      DeckUI.gotoMediaBlocked();
    } else {
      DeckUI.gotoDeckSettings();
    }
  }

  render() {
    return (
      <Wrapper
        className='no-user-input'
        height={pixify(DeckUI.layout.userInputHeight - 2)}
        width={pixify(DeckUI.layout.innerDeckWidth)}
      >
        {
          !DeckUI.canRecordMedia &&
            <span
              data-tip
              data-for='no-user-input'
            >
              <NoMicrophoneIcon
                onClick={() => this.onClickNoMicrophone()}
                minimumHeight={DeckUI.layout.minimumHeight}
              />
              <ReactTooltip
                place='top'
                type='dark'
                effect='solid'
                id='no-user-input'
                class='custom-tooltip'
              >
                Audio input has been disabled.
              </ReactTooltip>
            </span>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(NoUserInput);
