import React from 'react';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import { JobStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import Modal from 'components/modal';
import JobCreate from 'pages/builder/jobs/job_create';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { Link } from 'react-router';

@observer
class JobIndex extends React.Component {
  @observable showCreate = false;

  componentDidMount() {
    JobStore.getIndex();
  }

  componentWillUnmount() {
    JobStore.clearIndexData();
  }

  onDelete(id) {
    JobStore.destroy({ ids: { jobId: id } });
  }

  render() {
    return (
      <div className='job-index'>
        {
          !this.props.hideCreateButton && this.showCreate &&
          <Modal
            onCloseModal={() => this.showCreate = false}
          >
            <JobCreate />
          </Modal>
        }
        <div style={{display: 'flex', marginBottom: '10px'}}>
          <div style={{width: 150}}>
            {
              !this.props.hideCreateButton && (
                <button
                  className='gray-button'
                  onClick={() => this.showCreate = true}
                >
                  <i className='fa fa-plus' />
                  Create
                </button>
              )
            }
          </div>
        </div>
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='40'></th>
              <th>Title</th>
              <th>Site</th>
              <th>Published</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              JobStore.hasIndexData && JobStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.job.edit', params: { jobId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        {item.title}
                      </div>
                    </td>
                    <td>
                      {item.siteName}
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceCheckbox
                          id={item.id}
                          value={item.published}
                          model='jobs'
                          field='published'
                          icon='check'
                        />
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message='Delete this page?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={JobStore}
        />
      </div>
    );
  }
}

export default JobIndex;
