import React from 'react';
import { observer } from 'mobx-react';
import { TreeNodeStore } from '@seedlang/stores';
import TypeAhead from 'components/type_ahead';
import { isEmpty } from 'lodash';

@observer
class TreeNodeSearch extends React.Component {

  static defaultProps = {
    searchByField: 'name',
    placeholder: 'Search for Tree Node (case sensitive)',
  }

  componentDidMount() {
    TreeNodeStore.setSort(this.props.searchByField);
    if (this.props.languageId) {
      TreeNodeStore.setFilter('language_id', this.props.languageId);
    }
  }

  componentWillUnmount() {
    this.onClear();
  }

  onKeyUp(value) {
    if (isEmpty(value)) {
      this.onClear();
    } else {
      TreeNodeStore.setFilter(this.props.searchByField, `~${value}~`, { refresh: true });
    }
  }

  onSelect(obj) {
    this.props.onSubmit(obj);
  }

  onClear() {
    TreeNodeStore.clearIndexData();
    TreeNodeStore.clearFilter();
    if (this.props.languageId) {
      TreeNodeStore.setFilter('language_id', this.props.languageId);
    }
  }

  render() {
    return (
      <div className='concept-search' style={this.props.style}>
        <TypeAhead
          onKeyUp={this.onKeyUp.bind(this)}
          onSelect={this.onSelect.bind(this)}
          onClear={this.onClear.bind(this)}
          options={TreeNodeStore.indexData}
          displayField={this.props.displayField || this.props.searchByField}
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default TreeNodeSearch;
