import styled from '@emotion/styled';
import { LoadingState } from '@seedlang/state';
import { DownloadLinkStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Spinner from 'components/spinner';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

const InputWrapper = styled.div`
  input {
    padding: 0;
  }
`;

@observer
class DownloadLinkFileUpload extends React.Component {
  loadingState = new LoadingState();

  static propTypes = {
    onChange: PropTypes.func,
    worksheet: PropTypes.object,
    downloadLinkType: PropTypes.object,
  }

  @autobind async performUpload() {
    try {
      this.loadingState.started();

      await DownloadLinkStore.uploadPromise(this.props.worksheet.id, this.props.downloadLinkType.id, this.data());

      this.loadingState.succeeded();

      if (this.props.onChange) {
        this.props.onChange();
      }
    }
    catch (error) {
      console.error('Failed copying file', error);
      const message = error.failed && error.message ? error.message : 'An error occurred while copying the file.';
      this.loadingState.failed(message);
    }
  }

  data() {
    const data = new FormData();
    if (this.refs.input.files[0].size <= 100000000) { // don't allow upload over 100mb
      data.append('blob', this.refs.input.files[0]);
      return data;
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }} className='in-place-file-upload'>
        <InputWrapper>
          <input
            type='file'
            ref='input'
            onChange={this.performUpload}
          />
        </InputWrapper>
        {
          isPresent(this.props.file) && isPresent(this.props.file.url) &&
          <div className='file-wrapper'>
            <i
              className='fa fa-times fa-on-click delete-btn'
              onClick={this.onDelete.bind(this)}
            />
            <div className='url-wrapper'>
              <a href={this.props.file.url}>{this.props.file.url}</a>
            </div>
          </div>
        }
        {
          this.loadingState.errorMessage && (
            <div style={{ color: 'red' }} onClick={() => this.loadingState.clear()}>
              {this.loadingState.errorMessage}
            </div>
          )
        }
        {this.loadingState.isLoading && <Spinner className='blue' />}
      </div>
    );
  }
}

export default DownloadLinkFileUpload;
