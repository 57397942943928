import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { isArray, isObject, keys } from 'lodash';
import FormLabel from 'components/form_factory/form_label';
import FormError from 'components/form_factory/form_error';
import cx from 'classnames';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin: ${props => props.margin};
  li {
    width: 100%;
    background: white;
    border: 1px solid ${Theme.green};
    border-radius: 5px;
    font-size: 14px;
    text-align: center;
    padding: 5px 10px;
    cursor: pointer;
    &.selected {
      background: ${Theme.green};
      color: white;
    }
  }
  li:not(:last-child) {
    margin-bottom: 5px;
  }
`;

@observer
class FormFlatSelect extends React.Component {

  static propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
    store: PropTypes.object.isRequired,
    options: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    includeBlank: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    form: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    options: [],
    includeBlank: true,
    disabled: false,
    defaultValue: '',
  }

  constructor(props) {
    super(props);
    this.props.form.registerComponent(this.props);
  }

  componentWillUnmount() {
    // this.props.form.unregisterComponent(this.props);
  }

  onChange(value) {
    this.props.onChange(this.props.name, value);
  }

  getValue() {
    return this.props.form.getValue(this.props.name) || '';
  }

  getError() {
    return this.props.store.getError(this.props.name);
  }

  name() {
    if (this.props.name.indexOf('.') !== -1) {
      const ary = this.props.name.split('.');
      return `${ary[0]}[${ary[1]}]`;
    }
    return this.props.name;
  }

  _options() {
    if (this.props.options && isArray(this.props.options)) {
      return this.this.props.options.map(obj => {
        const id = isObject(obj) ? obj.id : obj;
        const name = isObject(obj) ? obj.name : obj;
        return (
          <option
            key={id}
            value={id}
          >
            {name}
          </option>
        );
      });
    }
    return keys(this.props.options).map(k => {
      return (
        <option
          key={k}
          value={k}
        >
          {this.props.options[k]}
        </option>
      );
    });
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <FormLabel
          {...this.props}
        />
        <ul>
          {
            this.props.options && this.props.options.map(item => {
              return (
                <li
                  key={item.id}
                  className={cx({
                    selected: item.id === this.getValue(),
                  })}
                  onClick={() => this.onChange(item.id)}
                >
                  {item.name}
                </li>
              );
            })
          }
        </ul>
        <FormError
          message={this.getError()}
        />
      </Wrapper>
    );
  }
}

export default FormFlatSelect;
