import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import BannerNoticeShow from 'components/banner_notice/banner_notice_show';

const Wrapper = styled.div`

`;

@observer
class BannerNoticeIndex extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          AppUI.user?.site?.bannerNotices.filter(item => item.route === this.props.route).map(item => { // filtered in site_serializer
            return (
              <BannerNoticeShow
                key={item.id}
                bannerNotice={item}
              />
            );
          })
        }
      </Wrapper>
    );
  }
}

export default BannerNoticeIndex;
