import React from 'react';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import ProgressBar from 'components/progress_bar';
import { CrownIcon } from '@seedlang/icons';
import ImageWithStatus from 'components/image_with_status';
import { DeckUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';
import { Theme } from '@seedlang/constants';
import { pixify, isPresent, createUuid, kFormatter } from '@seedlang/utils';
import { DeckLinkWrapper } from '@seedlang/hoc';
import TextResizer from 'components/text_resizer';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
  width: ${props => props.width};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
  margin-bottom: ${props => props.marginBottom};
  position: relative;
  cursor: pointer;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  &:hover {
    transform: ${props => props.disabled ? 'scale(1)' : 'scale(1.03)'};
  }
`;

const InnerWrapper = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  border: 5px solid ${props => props.borderColor};
  ${flexCenterColumn()}
  padding-top: 5px;
  background: white;
  line-height: 16px;
  text-align: left;
  color: black;
  position: relative;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
  overflow: hidden;
  z-index: 2;
`;

const Background = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  border: 5px solid ${props => props.borderColor};
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 44px;
  border-top-right-radius: 44px;
  position: absolute;
  bottom: -10px;
  z-index: 1;
  background: #E7E7E7;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: block;
  height: ${props => props.height};
  width: ${props => props.width};
  border-top-right-radius: 30px;
  overflow: hidden;
`;

const Button = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 10px);
  margin-bottom: 5px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: ${props => props.background};
  padding: 0 10px;
  position: relative;
`;

const LabelWrapper = styled.div`
  z-index: ${Theme.z['over-foreground']};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Label = styled.div`
  background: ${props => props.background};
  color: white;
  padding: 3px 7px;
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  width: 45px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid rgba(255, 255, 255, 0.8);
  border-top: none;
`;

const ProgressBarWrapper = styled.div`
  width: calc(100% - 10px);
  background: #FFF;
  padding: 0;
  margin: 0 10px;
  height: ${props => props.height}px;
  padding-top: 4px;
`;

const VerticalTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const HorizontalTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  svg {
    margin-right: 4px;
  }
`;

const EmptyImage = styled.div`
  background: linear-gradient(-134deg,#3BD7C5 0%,#0DAFF6 100%);
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
  width: 100%;
  height: 100%;
`;

@observer
class DeckLink extends React.Component {

  static defaultProps = {
    marginRight: '10px',
    marginLeft: '0px',
    marginBottom: '20px',
  }

  @autobind removeAnimation() {
    DeckUI.removeFromAnimationQueue('deckId', this.props.deck.id);
  }

  constructor(props) {
    super(props);
    this.id = createUuid();
  }

  render() {
    return (
      <Wrapper
        disabled={this.props.disabled}
        width={pixify(this.props.width)}
        marginRight={pixify(this.props.marginRight)}
        marginLeft={pixify(this.props.marginLeft)}
        marginBottom={pixify(this.props.marginBottom)}
        onClick={this.props.onClick}
      >
        {
          this.props.deck && !this.props.deck.webPublished &&
            <LabelWrapper>
              <Label
                background={Theme.red}
              >
                UNP
              </Label>
            </LabelWrapper>
        }
        {
          this.props.new &&
            <LabelWrapper>
              <Label
                background={Theme.green}
              >
                NEW
              </Label>
            </LabelWrapper>
        }
        <InnerWrapper
          width={pixify(this.props.width)}
          height={pixify(this.props.height)}
          borderColor={this.props.borderColor}
        >
          <ImageWrapper
            width={pixify(this.props.width - 20)}
            height={pixify(this.props.width - 20)}
          >
            {
              !this.props.deck?.imageUrl && !this.props.children &&
                <EmptyImage />
            }
            {
              !this.props.children && this.props.deck && this.props.deck?.imageUrl &&
                <ImageWithStatus
                  showLock
                  showCrown
                  checkmarkWidth='100px'
                  borderWidth='0'
                  borderTopRadius='35px'
                  borderBottomRadius='0px'
                  hasAccess={this.props.hasAccess}
                  finished={this.props.finished}
                  points={this.props.deck.points}
                  openAccess={this.props.deck.openAccess}
                  inProgress={this.props.inProgress}
                  src={this.props.deck.imageUrl}
                  animate={isPresent(DeckUI.animationQueue.find(item => item.name === 'deckCompleted' && item.deckId === this.props.deck.id))}
                  afterAnimation={this.removeAnimation}
                />
            }
            {this.props.children}
          </ImageWrapper>
          <ProgressBarWrapper
            height={this.props.progressBarHeight + 8}
          >
            <div
              data-tip
              data-for={this.id}
            >
              <ProgressBar
                height={this.props.progressBarHeight}
                current={this.props.progressCurrent}
                total={this.props.progressTotal}
                borderRadius='0'
                background={this.props.borderColor === '#777777' ? '#777777' : '#5f5f5f'}
                currentBackground={Theme.green}
              />
              <ReactTooltip
                place='top'
                type='dark'
                effect='solid'
                id={this.id}
                class='custom-tooltip'
              >
                <center>
                  <div>{kFormatter(this.props.progressCurrent)} of {kFormatter(this.props.progressTotal)}</div>
                  <div>{this.props.progressLabel}</div>
                </center>
              </ReactTooltip>
            </div>
          </ProgressBarWrapper>
          <Button
            background={this.props.borderColor}
          >
            <VerticalTextWrapper>
              <HorizontalTextWrapper>
                {
                  this.props.showCrownIcon &&
                    <CrownIcon
                      width='24px'
                      height='24px'
                    />
                }
                <TextResizer
                  resizeText
                  width={this.props.width - 20}
                  height={60}
                  characterLength={this.props.title && this.props.title.length}
                  maximumFontSize={16}
                >
                  <Text
                    center
                    bold
                    color='#FFF'
                  >
                    {this.props.title}
                  </Text>
                </TextResizer>
              </HorizontalTextWrapper>
              {
                this.props.subTitle &&
                  <Text
                    center
                    fontSize='10px'
                    color='rgba(255, 255, 255, 0.7)'
                    bold
                  >
                    {this.props.subTitle}
                  </Text>
              }
            </VerticalTextWrapper>
          </Button>
        </InnerWrapper>
        <Background
          borderColor={this.props.borderColor}
          width={pixify(this.props.width)}
          height={pixify(this.props.height)}
        />
      </Wrapper>
    );
  }
}

export default DeckLinkWrapper(DeckLink);
