import React from 'react';
import { observer } from 'mobx-react';
import SimpleSentenceIndex from 'pages/builder/sentences/simple_sentence_index';
import { CreatorUI } from '@seedlang/state';
import DeleteButton from 'components/button/delete_button';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';
import { ConceptStore } from '@seedlang/stores';

@observer
class ProducerDefault extends React.Component {

  @autobind onDeleteSentenceVideos() {
    ConceptStore.deleteSentenceVideos({ids: {conceptId: CreatorUI.conceptId}}, CreatorUI.afterCreateSentence.bind(CreatorUI));
  }

  render() {
    return (
      <div className='producer-sentence-with-media'>
        <div>
          <SimpleSentenceIndex
            store={CreatorUI.sentenceStore}
            sentences={CreatorUI.sentences}
            sentenceId={CreatorUI.sentenceId}
            onDeleteSentence={CreatorUI.onDeleteSentence}
            onSelectSentenceId={CreatorUI.onSelectSentenceId}
          />
          <DeleteButton
            floatsRight
            onConfirm={this.onDeleteSentenceVideos}
            message='Delete all sentence videos?'
          >
            <Button
              margin='0 0 20px 0'
            >
              Delete All Sentence Videos
            </Button>
          </DeleteButton>
        </div>
      </div>
    );
  }
}

export default ProducerDefault;
