import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { CustomTabStore, DownloadLinkTypeStore, GroupStore, MembershipGroupStore, PodcastStore, SiteStore, ContactCategoryStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import autobind from 'autobind-decorator';
import NavigationItemIndex from 'pages/builder/navigation_items/navigation_item_index';
import Cookies from 'js-cookie';
import Copy from 'components/copy';
import Text from 'components/text';
import { Link } from 'react-router';
import DeleteButton from 'components/button/delete_button';
import InfoTooltip from 'components/info_tooltip';
import InPlaceSelect from 'components/form/in_place_select';
import Button from 'components/button/button';
import { Constants } from '@seedlang/constants';
import LinkWithCopy from 'components/link_with_copy';
import Alert from 'components/alert';

const Wrapper = styled.div`
  .small {
    font-size: 12px;
    line-height: 14px!important;
  }
`;

const ColorRow = styled.div`
  display: flex;
  align-items: center;
  .fa {
    margin-right: 5px;
    color: ${props => props.color}
  }
`;

@observer
class SiteEdit extends React.Component {
  @observable showPatreonSection = false;

  static defaultProps = {
    whiteLabeled: false,
  }

  constructor(props) {
    super(props);
    this.getSite();
    if (!MembershipGroupStore.hasIndexData) {
      MembershipGroupStore.getIndex();
    }
    if (!DownloadLinkTypeStore.hasIndexData) {
      DownloadLinkTypeStore.getIndex({});
    }
    if (!PodcastStore.hasIndexData) {
      PodcastStore.getIndex({});
    }
    if (!CustomTabStore.hasIndexData) {
      CustomTabStore.getIndex({});
    }
    if (!ContactCategoryStore.hasIndexData) {
      ContactCategoryStore.getIndex();
    }
  }

  componentDidMount() {
    this.getSite();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.conceptId !== prevProps.routeParams.conceptId) {
      this.getSite();
    }
  }

  componentWillUnmount() {
    SiteStore.clearShowData();
  }

  @computed get site() {
    return SiteStore.showData;
  }

  @computed get siteIsDefault() {
    return this.site && this.site.isDefault;
  }

  @autobind getSite() {
    SiteStore.getShow({ ids: { siteId: this.props.whiteLabeled ? Cookies.get('site_id') : this.props.routeParams.siteId } }, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    SiteStore.setShowData(resp);
  }

  @autobind loadGroup() {
    GroupStore.getShow({ ids: { groupId: this.props.params.groupId } });
  }

  @autobind deleteContactCategory(id) {
    ContactCategoryStore.destroy({ ids: { contactCategoryId: id } });
  }

  @autobind createContactCategory() {
    ContactCategoryStore.create({ data: { site_id: this.site.id } });
  }

  render() {
    return (
      <Wrapper>
        {
          SiteStore.hasShowData &&
          <>
            <div
              className='backend-title'
              margin='0 0 20px 0'
            >
              General
            </div>
            <div className='row'>
              <div className='col-xs-3'>
                <fieldset>
                  <legend>Name</legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.name}
                    model='sites'
                    field='name'
                  />
                </fieldset>
                {
                  !SiteStore.showData.isDefault &&
                  <fieldset>
                    <legend>Team Name <InfoTooltip>Used to refer to the site/the team to users.</InfoTooltip></legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.teamName}
                      model='sites'
                      field='team_name'
                    />
                  </fieldset>
                }
              </div>
              {
                SiteStore.showData.isDefault &&
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Feature Flags</legend>
                    <div>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.showVideoTimeoutMessage}
                        model='sites'
                        field='show_video_timeout_message'
                        icon='check'
                      >
                        Show Video Timeout Message
                      </InPlaceCheckbox>
                    </div>
                  </fieldset>
                </div>

              }
              {
                !SiteStore.showData.isDefault && !this.props.whiteLabeled &&
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Language ID</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.languageId}
                      model='sites'
                      field='language_id'
                    />
                  </fieldset>
                </div>
              }
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Access & Publication</legend>
                  <div>
                    <InPlaceCheckbox
                      id={this.site.id}
                      value={this.site.published}
                      model='sites'
                      field='published'
                      icon='check'
                    >
                      Published
                      <InfoTooltip>
                        <div>When disabled, only admins, test users and early access users</div>
                        <div>will be able to access the site.</div>
                      </InfoTooltip>
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      id={this.site.id}
                      value={this.site.paymentTestMode}
                      model='sites'
                      field='payment_test_mode'
                      icon='check'
                    >
                      Payment Test Mode
                      <InfoTooltip>
                        <div>Uses Stripe's test environment</div>
                        <div>No real charges will be made</div>
                      </InfoTooltip>
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      id={this.site.id}
                      value={this.site.allowPatreonSignIn}
                      model='sites'
                      field='allow_patreon_sign_in'
                      icon='check'
                    >
                      Sign in with Patreon
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      value={this.site.earlyAccess}
                      model='sites'
                      field='early_access'
                      id={this.site.id}
                      icon='check'
                      afterChange={this.getSite}
                    >
                      Allow Early Access
                      <InfoTooltip>
                        <div>When enabled, you will be able to share a link with users </div>
                        <div>so they can access the site before it's released.</div>
                      </InfoTooltip>
                    </InPlaceCheckbox>
                    {
                      this.site.earlyAccess &&
                      <LinkWithCopy
                        padding='0 5px'
                        link={this.site.earlyAccessUrl}
                      />
                    }
                  </div>
                </fieldset>
              </div>
              <div className='col-xs-3'>
                <fieldset>
                  <legend>Content & Grid Settings</legend>
                  <div>
                    <InPlaceCheckbox
                      id={this.site.id}
                      value={this.site.hasPodcast}
                      model='sites'
                      field='has_podcast'
                      icon='check'
                      afterChange={this.getSite}
                    >
                      Has Podcast
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      id={this.site.id}
                      value={this.site.useGridLayout}
                      model='sites'
                      field='use_grid_layout'
                      icon='check'
                    >
                      Show Grid Sections
                      <InfoTooltip position='left'>
                        <div>If disabled, the homepage will be a grid of posts ordered by date.</div>
                        <div>Grid Sections can be set up in the "Grid Sections" settings tab.</div>
                      </InfoTooltip>
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      id={this.site.id}
                      value={this.site.showWorksheetFilter}
                      model='sites'
                      field='show_worksheet_filter'
                      icon='check'
                    >
                      Show Post Filter <InfoTooltip position='left'>Users will be able to filter posts with tags (that can be edited in Post & Exercise Tags).</InfoTooltip>
                    </InPlaceCheckbox>
                  </div>
                </fieldset>
              </div>
              {
                this.site.isDefault &&
                <div className='row'>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Web Version</legend>
                      <InPlaceText
                        id={this.site.id}
                        defaultValue={this.site.currentWebVersion}
                        model='sites'
                        field='current_web_version'
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>iOS Version</legend>
                      <InPlaceText
                        id={this.site.id}
                        defaultValue={this.site.currentIosVersion}
                        model='sites'
                        field='current_ios_version'
                      />
                      <div className='small'>
                        Only set this after it's been released for a week.
                      </div>
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Android Version</legend>
                      <InPlaceText
                        id={this.site.id}
                        defaultValue={this.site.currentAndroidVersion}
                        model='sites'
                        field='current_android_version'
                      />
                      <div className='small'>
                        Only set this after it's been released for a week.
                      </div>
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Show Maintenance Page</legend>
                      <InPlaceCheckbox
                        id={this.site.id}
                        value={this.site.showMaintenancePage}
                        model='sites'
                        field='show_maintenance_page'
                        icon='check'
                      />
                      <div>
                        <div className='small'>
                          This will show the maintenance page to all non-admins.
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Last Trivia Published At</legend>
                      {this.site.daysSinceLastTriviaUpdate} Days Ago
                    </fieldset>
                  </div>
                </div>
              }
            </div>
            <div className='backend-title' onClick={() => this.showPatreonSection = !this.showPatreonSection}>
              {
                this.showPatreonSection ? <i className='fa fa-chevron-down' /> : <i className='fa fa-chevron-right' />
              }
              {`Patreon Settings${this.site.allowPatreonSignIn ? '' : ' (disabled)'}`}
            </div>
            {
              this.showPatreonSection &&
              <>
                <div className='row'>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Patreon Campaign ID</legend>
                      <InPlaceText
                        defaultValue={GroupStore.showData.patreonCampaignId}
                        model='groups'
                        field='patreon_campaign_id'
                        id={GroupStore.showData.id}
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Invite Link</legend>
                      <Copy
                        text={`https://seedlang.com?assign_group_id=${this.props.params.groupId}`}
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset>
                      <legend>Show Tags</legend>
                      <InPlaceCheckbox
                        value={GroupStore.showData.showTags}
                        model='groups'
                        field='show_tags'
                        id={GroupStore.showData.id}
                        icon='check'
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>Patreon Client ID</legend>
                      <InPlaceText
                        defaultValue={GroupStore.showData.patreonClientId}
                        model='groups'
                        field='patreon_client_id'
                        id={GroupStore.showData.id}
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>Patreon Client Secret</legend>
                      <InPlaceText
                        defaultValue={GroupStore.showData.patreonClientSecret}
                        model='groups'
                        field='patreon_client_secret'
                        id={GroupStore.showData.id}
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>Creator Access Token</legend>
                      <InPlaceText
                        defaultValue={GroupStore.showData.patreonCreatorAccessToken}
                        model='groups'
                        field='patreon_creator_access_token'
                        id={GroupStore.showData.id}
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>Creator Refresh Token</legend>
                      <InPlaceText
                        defaultValue={GroupStore.showData.patreonCreatorRefreshToken}
                        model='groups'
                        field='patreon_creator_refresh_token'
                        id={GroupStore.showData.id}
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>Webhook Secret</legend>
                      <InPlaceText
                        defaultValue={GroupStore.showData.patreonWebhookSecret}
                        model='groups'
                        field='patreon_webhook_secret'
                        id={GroupStore.showData.id}
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>All Memberships Have Access</legend>
                      <InPlaceCheckbox
                        value={GroupStore.showData.allowAllMemberships}
                        model='groups'
                        icon='check'
                        field='allow_all_memberships'
                        id={GroupStore.showData.id}
                        afterChange={this.loadGroup}
                      />
                    </fieldset>
                  </div>
                  {
                    !GroupStore.showData.allowAllMemberships &&
                    <div className='col-xs-6'>
                      <fieldset>
                        <legend>Memberships w/ Access</legend>
                        <InPlaceText
                          defaultValue={GroupStore.showData.allowedMemberships}
                          model='groups'
                          field='allowed_memberships'
                          id={GroupStore.showData.id}
                        />
                        <div style={{ margin: '5px 0 0 0' }}>
                          <div className='small'>Separate each item with a comma: a,b,c</div>
                        </div>
                      </fieldset>
                    </div>
                  }
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>No-Access Text</legend>
                      <InPlaceText
                        richText
                        defaultValue={GroupStore.showData.noAccessText}
                        model='groups'
                        field='no_access_text'
                        id={GroupStore.showData.id}
                      />
                    </fieldset>
                  </div>
                </div>
                {
                  !this.site.published &&
                  <div className='row'>
                    <div className='col-xs-12'>
                      <fieldset>
                        <legend>Patreon Membership Page</legend>
                        <InPlaceText
                          defaultValue={this.site.patreonMembershipPage}
                          model='sites'
                          field='patreon_membership_page'
                          id={this.site.id}
                        />
                      </fieldset>
                    </div>
                    <div className='small'>A link to your patreon membership will show up while the site is unpublished</div>
                  </div>
                }
                <Text fontSize='14px' margin='5px 0'>Select the content you want to block for members who haven't started their payment migration from Patreon:</Text>
                <div className='row'>
                  <div className='col-xs-3'>
                    <fieldset style={{ height: '100%' }}>
                      <legend>Vocab and Exercises</legend>
                      <div>
                        <InPlaceCheckbox
                          model='sites'
                          field='block_vocab_if_not_migrated'
                          value={this.site.blockVocabIfNotMigrated}
                          id={this.site.id}
                          icon='lock'
                          afterChange={this.getSite}
                          display='flex'
                        >
                          Vocabulary tab
                        </InPlaceCheckbox>
                      </div>
                      <div>
                        <InPlaceCheckbox
                          model='sites'
                          field='block_exercises_if_not_migrated'
                          value={this.site.blockExercisesIfNotMigrated}
                          id={this.site.id}
                          icon='lock'
                          afterChange={this.getSite}
                          display='flex'
                        >
                          Exercises tab
                        </InPlaceCheckbox>
                      </div>
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset style={{ height: '100%' }}>
                      <legend>Download Link Types</legend>
                      {
                        DownloadLinkTypeStore.indexData.map(item =>
                          <div key={item.id}>
                            <InPlaceCheckbox
                              model='download_link_types'
                              field='block_if_not_migrated'
                              value={item.blockIfNotMigrated}
                              id={item.id}
                              icon='lock'
                              display='flex'
                            >
                              {item.title}{item.subtitle ? ` - ${item.subtitle}` : ''}
                            </InPlaceCheckbox>
                          </div>,
                        )
                      }
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset style={{ height: '100%' }}>
                      <legend>Podcasts</legend>
                      {
                        PodcastStore.indexData.map(item =>
                          <div key={item.id}>
                            <InPlaceCheckbox
                              model='podcasts'
                              field='block_if_not_migrated'
                              value={item.blockIfNotMigrated}
                              id={item.id}
                              icon='lock'
                              display='flex'
                            >
                              {item.title}
                            </InPlaceCheckbox>
                          </div>,
                        )
                      }
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset style={{ height: '100%' }}>
                      <legend>Custom Tabs</legend>
                      {
                        CustomTabStore.indexData.map(item =>
                          <div style={{ display: 'flex', justifyContent: 'space-between' }} key={item.id}>
                            <InPlaceCheckbox
                              model='podcasts'
                              field='block_if_not_migrated'
                              value={item.blockIfNotMigrated}
                              id={item.id}
                              icon='lock'
                              display='flex'
                            >
                              {item.title}
                            </InPlaceCheckbox>
                            <Link
                              to={{ name: 'creator.groups.custom_tabs.edit', params: { customTabId: item.id, groupId: this.props.params.groupId } }}
                              className='on-click'
                              target='_blank'
                            >
                              <i className='fa fa-chevron-right fa-block' />
                            </Link>
                          </div>,
                        )
                      }
                    </fieldset>
                  </div>
                </div>
              </>
            }
            <div className='backend-title'>
              <i className='fa fa-credit-card' /> Payment Settings
            </div>
            <div className='row'>
              <div className='col-xs-3'>
                <fieldset>
                  <legend>Allow Promotion Codes</legend>
                  <InPlaceCheckbox
                    model='sites'
                    field='allow_promotion_codes'
                    value={this.site.allowPromotionCodes}
                    id={this.site.id}
                    icon='check'
                  />
                </fieldset>
              </div>
            </div>

            <div className='backend-title'>
              <i className='fa fa-share-alt' /> Social Media <InfoTooltip>[Currently Unused] Links to your social media profiles.</InfoTooltip>
            </div>
            <div className='row'>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>Youtube URL</legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.youtubeUrl}
                    model='sites'
                    field='youtube_url'
                  />
                </fieldset>
              </div>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>Instagram URL</legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.instagramUrl}
                    model='sites'
                    field='instagram_url'
                  />
                </fieldset>
              </div>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>TikTok URL</legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.tikTokUrl}
                    model='sites'
                    field='tik_tok_url'
                  />
                </fieldset>
              </div>
            </div>
            <div className='backend-title'>
              Discord
            </div>
            <div className='row'>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Discord Server Id</legend>
                  <InPlaceText
                    defaultValue={this.site.discordServerId}
                    model='sites'
                    field='discord_server_id'
                    id={this.site.id}
                  />
                </fieldset>
              </div>
            </div>
            <div className='backend-title'>
              Routing
            </div>
            <div className='row'>
              <div className='col-xs-3'>
                <fieldset>
                  <legend>Signed In Home Page <InfoTooltip>You can find a page's route by clicking on the page in the Site Navigation settings.</InfoTooltip></legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.signedInHomePageRoute}
                    placeholder='e.g. worksheets.index'
                    model='sites'
                    field='signed_in_home_page_route'
                  />
                </fieldset>
              </div>
              <div className='col-xs-3'>
                <fieldset>
                  <legend>Signed Out Home Page</legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.signedOutHomePageRoute}
                    placeholder='e.g. worksheets.index'
                    model='sites'
                    field='signed_out_home_page_route'
                  />
                </fieldset>
              </div>
            </div>
            <div className='backend-title'>
              <i className='fa fa-paint-brush' /> Styling
            </div>
            <div className='row'>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>
                    Main Team Color
                    <InfoTooltip>
                      <div>This is the color used in your logo.</div>
                      <div>On the platform, it will be used in action buttons</div>
                      <div>with white text and icons on it.</div>
                    </InfoTooltip>
                  </legend>
                  <ColorRow
                    color={this.site.accentColor}
                  >
                    {
                      this.site.accentColor &&
                      <i className='fa fa-square' />
                    }
                    <InPlaceText
                      submitOnEnter
                      id={this.site.id}
                      defaultValue={this.site.accentColor}
                      model='sites'
                      field='accent_color'
                      afterChange={this.getSite}
                    />
                  </ColorRow>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button margin='5px 0'><i className='fa fa-eye' /> Button Preview</Button>
                  </div>
                </fieldset>
              </div>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>
                    Secondary Team Color
                    <InfoTooltip>
                      <div>A color that works well in conjunction with the main team color.</div>
                      <div>It will be used as the background for white text and as</div>
                      <div>the color for links.</div>
                    </InfoTooltip>
                  </legend>
                  <ColorRow
                    color={this.site.secondaryAccentColor}
                  >
                    {
                      this.site.secondaryAccentColor &&
                      <i className='fa fa-square' />
                    }
                    <InPlaceText
                      submitOnEnter
                      id={this.site.id}
                      defaultValue={this.site.secondaryAccentColor}
                      model='sites'
                      field='secondary_accent_color'
                      afterChange={this.getSite}
                    />
                  </ColorRow>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button margin='5px 0' background={this.site.secondaryAccentColor}><i className='fa fa-eye' /> Button Preview</Button>
                  </div>
                </fieldset>
              </div>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>Neutral Dark Color <InfoTooltip>A dark color used in the footer and in the background of the sign in page.</InfoTooltip></legend>
                  <ColorRow
                    color={this.site.footerBackgroundColor}
                  >
                    {
                      this.site.footerBackgroundColor &&
                      <i className='fa fa-square' />
                    }
                    <InPlaceText
                      submitOnEnter
                      id={this.site.id}
                      defaultValue={this.site.footerBackgroundColor}
                      model='sites'
                      field='footer_background_color'
                      afterChange={this.getSite}
                    />
                  </ColorRow>
                </fieldset>
              </div>
            </div>
            <div className='row'>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Header Font</legend>
                  <InPlaceSelect
                    model='sites'
                    field='header_font'
                    id={this.site.id}
                    value={this.site.headerFont}
                    options={[
                      ['header_rubik', 'Rubik'],
                      ['header_karla', 'Karla'],
                      ['header_noto_sans', 'Noto Sans'],
                    ]}
                  />
                </fieldset>
              </div>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Body Font</legend>
                  <InPlaceSelect
                    model='sites'
                    field='body_font'
                    id={this.site.id}
                    value={this.site.bodyFont}
                    options={[
                      ['body_noto_sans', 'Noto Sans'],
                      ['body_karla', 'Karla'],
                      ['body_rubik', 'Rubik'],
                    ]}
                  />
                </fieldset>
              </div>
            </div>
            {
              !this.props.whiteLabeled &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Navigation</legend>
                    <NavigationItemIndex
                      items={this.site.navigationItems}
                      onItemDelete={this.onNavItemDelete}
                    />
                  </fieldset>
                </div>
              </div>
            }
            <div className='backend-title'><i className='fa fa-tags' /> Metadata <InfoTooltip>Used for SEO and social media.</InfoTooltip></div>
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Title</legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.title}
                    model='sites'
                    field='title'
                  />
                </fieldset>
              </div>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Description</legend>
                  <InPlaceText
                    wrapperClassName='word-info'
                    defaultValue={this.site.description}
                    model='sites'
                    field='description'
                    inputType='textarea'
                    id={this.site.id}
                  />
                </fieldset>
              </div>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Facebook App ID</legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.fbAppId}
                    model='sites'
                    field='fb_app_id'
                  />
                </fieldset>
              </div>
            </div>
            <div className='backend-title'><i className='fa fa-image' /> Images</div>
            <div className='row'>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Navigation Logo <InfoTooltip>This will appear in the top left of the site navigation.</InfoTooltip></legend>
                  <InPlaceImageUpload
                    image={this.site.image}
                    model='images'
                    onChange={this.getSite}
                    querystring={{
                      imageable_id: this.site.id,
                      imageable_type: 'Site',
                    }}
                  />
                </fieldset>
              </div>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Team Logo <InfoTooltip>
                    <div>This will appear in places such as the email footer and the notifications screen.</div>
                    <div>It should not have any text in the image other than the team name and should have a transparent background.</div>
                  </InfoTooltip></legend>
                  <InPlaceImageUpload
                    image={this.site.teamLogo && this.site.teamLogo.image}
                    model='team_logos'
                    id={this.site.teamLogo?.id}
                    onChange={this.getSite}
                    querystring={{
                      site_id: this.site.id,
                    }}
                  />
                </fieldset>
              </div>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Favicon</legend>
                  <InPlaceImageUpload
                    circle
                    image={this.site.favicon && this.site.favicon.image}
                    model='favicons'
                    id={this.site.favicon?.id}
                    onChange={this.getSite}
                    querystring={{
                      site_id: this.site.id,
                    }}
                  />
                </fieldset>
              </div>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Social Image <InfoTooltip>
                    <div>Used as a preview when sharing the site on social media.</div>
                    <div>If empty, the team logo will be used instead.</div>
                  </InfoTooltip></legend>
                  <InPlaceImageUpload
                    image={this.site.socialImage && this.site.socialImage.image}
                    model='social_images'
                    id={this.site.socialImage?.id}
                    onChange={this.getSite}
                    querystring={{
                      site_id: this.site.id,
                    }}
                  />
                </fieldset>
              </div>
            </div>

            <div className='backend-title'><i className='fa fa-user' /> User Settings <InfoTooltip>These settings configure the user's settings (accessible in the upper right corner of the site).</InfoTooltip></div>
            {
              !this.siteIsDefault &&
              <>
                <div className='backend-subtitle'>Privacy Settings</div>
                <div className='row'>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Terms of Service URL</legend>
                      <InPlaceText
                        id={this.site.id}
                        defaultValue={this.site.termsOfServiceUrl}
                        model='sites'
                        field='terms_of_service_url'
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Privacy URL</legend>
                      <InPlaceText
                        id={this.site.id}
                        defaultValue={this.site.privacyUrl}
                        model='sites'
                        field='privacy_url'
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>Privacy Modal Text <InfoTooltip>Displayed in Settings > Privacy.</InfoTooltip></legend>
                      <InPlaceText
                        richText
                        inputType='textarea'
                        id={this.site.id}
                        defaultValue={this.site.privacyTextHtml}
                        model='sites'
                        field='privacy_text_html'
                        maxHeight='30em'
                        scrollable
                      />
                    </fieldset>
                  </div>
                </div>
              </>
            }
            {
              this.site.hasPodcast &&
              <div className='row'>
                <div className='backend-subtitle'>Audio Feeds Settings</div>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Audio feeds message<InfoTooltip>This message appears at the top of the audio feeds page.</InfoTooltip></legend>
                    <InPlaceText
                      markdown
                      id={this.site.id}
                      inputType='textarea'
                      height='100px'
                      defaultValue={this.site.podcastSettingsMessage}
                      model='sites'
                      field='podcast_settings_message'
                      afterChange={this.getSite}
                      style={{ resize: 'auto' }}
                      placeholder={Constants.PODCAST_SETTINGS_MESSAGE}
                    />
                  </fieldset>
                </div>
              </div>
            }

            <div className='backend-title'><i className='fa fa-envelope' /> Email <InfoTooltip>Used to send emails to users.</InfoTooltip></div>
            {
              !this.site.published &&
              <div className='row'>
                <div className='col-xs-12'>
                  <Alert>Unpublished sites only send test emails.</Alert>
                </div>
              </div>
            }
            <div className='row'>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>From Email Address <InfoTooltip>This is the email address that will be used to send emails from the site.</InfoTooltip></legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.fromEmailAddress}
                    model='sites'
                    field='from_email_address'
                  />
                </fieldset>
              </div>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>BCC Email Addresses <InfoTooltip><div>Will receive copies of all emails including test emails.</div><div>Separate each email with a comma: a@b.com,c@d.com</div></InfoTooltip></legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.bccEmailAddresses}
                    model='sites'
                    field='bcc_email_addresses'
                  />
                </fieldset>
              </div>
              <div className='col-xs-4'>
                <fieldset>
                  <legend>MailerLite Settings</legend>
                  <div>
                    <InPlaceCheckbox
                      icon='check'
                      id={this.site.id}
                      value={this.site.enableMailerlite}
                      model='sites'
                      field='enable_mailerlite'
                    >
                      Enable MailerLite <InfoTooltip>This will synch users to MailerLite, but you will still be able to use the Seedlang email services.</InfoTooltip>
                    </InPlaceCheckbox>
                  </div>
                  <div>
                    <InPlaceCheckbox
                      icon='check'
                      id={this.site.id}
                      value={this.site.syncAllUsersToMailerlite}
                      model='sites'
                      field='sync_all_users_to_mailerlite'
                    >
                      Sync All Users to MailerLite <InfoTooltip>This includes users who have not yet agreed to receive emails.</InfoTooltip>
                    </InPlaceCheckbox>
                  </div>
                </fieldset>
              </div>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Email Banner (w:1600px, h:226px) <InfoTooltip>This image appears at the top of all emails sent from the site.</InfoTooltip></legend>
                  <InPlaceImageUpload
                    image={this.site.emailBanner && this.site.emailBanner.image}
                    model='email_banners'
                    id={this.site.emailBanner?.id}
                    onChange={this.getSite}
                    querystring={{
                      site_id: this.site.id,
                    }}
                  />
                </fieldset>
              </div>
            </div>
            <div className='backend-title'>
              <i className='fa fa-envelope' />
              Contact Section
              <InfoTooltip>Used to send messages to the team by users.</InfoTooltip>
              <InfoTooltip
                onClick={() => window.open(`${window.location.origin}/contact`, '_blank')}
                icon='external-link'
              >
                See Contact page
              </InfoTooltip>
            </div>
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Default Support Email Address <InfoTooltip>Messages are sent to this address when users select 'Other' or there are no categories.</InfoTooltip></legend>
                  <InPlaceText
                    id={this.site.id}
                    defaultValue={this.site.supportEmailAddress}
                    model='sites'
                    field='support_email_address'
                  />
                </fieldset>
              </div>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Contact Categories</legend>
                  <Text fontSize='14px'>
                    Users will be prompted to choose one of these contact categories, which will determine which address the message is sent to.
                  </Text>
                  <table className='table-wrapper'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th width='40px' />
                      </tr>
                    </thead>
                    <tbody>
                      {
                        ContactCategoryStore.hasIndexData && ContactCategoryStore.indexData.map(item =>
                          <tr key={item.id}>
                            <td>
                              <InPlaceText
                                id={item.id}
                                defaultValue={item.name}
                                model='contact_categories'
                                field='name'
                              />
                            </td>
                            <td>
                              <InPlaceText
                                id={item.id}
                                defaultValue={item.email}
                                model='contact_categories'
                                field='email'
                              />
                            </td>
                            <td>
                              <DeleteButton
                                onConfirm={() => this.deleteContactCategory(item.id)}
                                message='Delete this contact category?'
                                className='fa-block right'
                              />
                            </td>
                          </tr>,
                        )
                      }
                      <tr
                        style={{ cursor: 'pointer' }}
                      >
                        <td
                          colSpan='3'
                          onClick={this.createContactCategory}
                          className='underline'
                        >
                          <i className='fa fa-plus' style={{ margin: '3px' }} />
                          <Text fontSize='14px'>Create a new contact category</Text>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </fieldset>
              </div>
            </div>
          </>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SiteEdit);
