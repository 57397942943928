import React from 'react';
import { observer } from 'mobx-react';
import { computed, autorun, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import Checkbox from 'components/checkbox';
import { isPresent, isBlank } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  padding: 20px;
`;

const TagCategory = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const TagCategoryWrapper = styled.div`
  margin-bottom: 10px;
  border-radius: 10px;
  overflow: hidden;
`;

const TagCategoryName = styled.div`
  font-weight: bold;
`;

const TagWrapper = styled.div`

`;

const FilterOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  &:hover {
    background: whitesmoke;
  }
`;

const Name = styled.div`
  flex: 1;
  font-size: 16px;
`;

const CheckboxWrapper = styled.div`
  width: 20px;
`;

@observer
class WorksheetFilterOptions extends React.Component {
  @observable showSpinner = false;
  @observable tagIds = '';
  @observable progressTags = '';
  updateTimeout = null;

  constructor(props) {
    super(props);
    this.showSpinner = true;
    autorun(() => {
      if (AppUI.userIsLoaded) {
        if (this.props.isExerciseFilter) {
          this.tagIds = AppUI.user.currentUserGroup.exerciseTagIds || '';
          this.progressTags = AppUI.user.currentUserGroup.exerciseProgressTags || '';
        } else {
          this.tagIds = AppUI.user.currentUserGroup.worksheetTagIds || '';
          this.progressTags = AppUI.user.currentUserGroup.worksheetProgressTags || '';
        }
      }
    });
  }

  @autobind
  debouncedUpdate(isExercise, stringValue, isProgressTag = false) {
    if (isBlank(AppUI.user.currentUserGroup.id)) {
      return;
    }
    if (this.updateTimeout) {
      clearTimeout(this.updateTimeout);
    }

    if (isExercise) {
      if (isProgressTag) {
        AppUI.user.currentUserGroup.set('exerciseProgressTags', stringValue);
      } else {
        AppUI.user.currentUserGroup.set('exerciseTagIds', stringValue);
      }
    } else {
      if (isProgressTag) {
        AppUI.user.currentUserGroup.set('worksheetProgressTags', stringValue);
      } else {
        AppUI.user.currentUserGroup.set('worksheetTagIds', stringValue);
      }
    }

    this.updateTimeout = setTimeout(() => {
      const data = {};
      if (isExercise) {
        if (isProgressTag) {
          data.exerciseProgressTags = stringValue;
        } else {
          data.exerciseTagIds = stringValue;
        }
      } else {
        if (isProgressTag) {
          data.worksheetProgressTags = stringValue;
        } else {
          data.worksheetTagIds = stringValue;
        }
      }

      Promise.resolve(AppUI.userGroupStore.update({
        ids: { userGroupId: AppUI.user.currentUserGroup.id },
        data,
      }, this.props.onUpdateFilter));
    }, 700);
  }

  @autobind onClickTag(tagId) {
    let ary = isPresent(this.tagIds) ? this.tagIds.split(',') : [];
    if (this.tagIsSelected(tagId)) {
      ary = ary.filter(item => item !== tagId);
    } else {
      ary.push(tagId);
    }
    const stringValue = ary.join(',');
    this.tagIds = stringValue;
    this.debouncedUpdate(this.props.isExerciseFilter, stringValue);
  }

  // @autobind afterUpdateTags() {
  //   AppUI.loadUser();
  //   this.props.onUpdateFilter();
  // }

  @autobind tagIsSelected(tagId) {
    return this.tagIds?.split(',').indexOf(tagId) !== -1;
  }

  @autobind progressTagIsSelected(tag) {
    return isPresent(this.progressTags) && this.progressTags.split(',').indexOf(tag.slug) !== -1;
  }

  @autobind onClickProgressTag(tag) {
    let ary = isPresent(this.progressTags) ? this.progressTags.split(',') : [];
    if (this.progressTagIsSelected(tag)) {
      ary = ary.filter(item => item !== tag.slug);
    } else {
      ary.push(tag.slug);
    }
    const stringValue = ary.join(',');
    this.progressTags = stringValue;
    this.debouncedUpdate(this.props.isExerciseFilter, stringValue, true);
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.tagCategories.filter(item => item.published).map(item => {
            if (item.slug === 'progress' && this.props.showProgressTag) {
              return (
                <TagCategoryWrapper
                  key={item.id}
                >
                  <TagCategory>
                    <TagCategoryName>
                      {item.name}
                    </TagCategoryName>
                  </TagCategory>
                  <TagWrapper>
                    {
                      item.tags.map(tag => {
                        return (
                          <FilterOption
                            key={tag.id}
                            onClick={() => this.onClickProgressTag(tag)}
                          >
                            <Name>
                              {tag.name}
                            </Name>
                            <CheckboxWrapper>
                              <Checkbox
                                value={this.progressTagIsSelected(tag)}
                              />
                            </CheckboxWrapper>
                          </FilterOption>
                        );
                      })
                    }
                  </TagWrapper>
                </TagCategoryWrapper>
              );
            } else if (item.slug !== 'progress') {
              return (
                <TagCategoryWrapper
                  key={item.id}
                >
                  <TagCategory>
                    <TagCategoryName>
                      {item.name}
                    </TagCategoryName>
                  </TagCategory>
                  <TagWrapper>
                    {
                      item.tags.filter(item => item.published).map(tag => {
                        return (
                          <FilterOption
                            key={tag.id}
                            onClick={() => this.onClickTag(tag.id)}
                          >
                            <Name>
                              {tag.name}
                            </Name>
                            <CheckboxWrapper>
                              <Checkbox
                                value={this.tagIsSelected(tag.id)}
                              />
                            </CheckboxWrapper>
                          </FilterOption>
                        );
                      })
                    }
                  </TagWrapper>
                </TagCategoryWrapper>
              );
            }
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetFilterOptions);
