import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { UserGroupStore, MembershipTypeStore, MembershipGroupStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import Filter from 'components/backend_filter/filter';
import UserGroupCreate from 'pages/builder/user_groups/user_group_create';
import Modal from 'components/modal';
import { AppUI } from '@seedlang/state';
import { Constants } from '@seedlang/constants';
import Spinner from 'components/spinner';
import autobind from 'autobind-decorator';
import Alert from 'components/alert';
import CheckIfTrue from 'components/check_if_true';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  .text {
    padding: 0 5px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
`;

@observer
class UsersIndex extends React.Component {
  @observable showCreate = false;
  @observable showSpinner = false;
  @observable showSuccess = false;

  @computed get exportUrl() {
    const baseUrl = window.location.href.match('ngrok')
      ? 'http://localhost:3000'
      : '';
    return `${baseUrl}/api/user_groups/export?group_id=${this.props.params.groupId}`;
  }

  constructor(props) {
    super(props);
    MembershipTypeStore.getIndex();
    MembershipGroupStore.getIndex();
    this.disposer = autorun(() => {
      if (AppUI.userIsLoaded && !AppUI.user.guest && !AppUI.permissions.canAccessBasicUserData) {
        AppUI.routeStore.routeToNamed('creator.groups.worksheets.index', {groupId: this.props.params.groupId});
      }
    });
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  @computed get membershipTypeOptions() {
    const options = MembershipTypeStore.indexData.map(item => ({id: item.id, name: item.name}));
    options.push({id: 'nil', name: 'No Membership'});
    return options;
  }

  @computed get membershipGroupOptions() {
    const options = MembershipGroupStore.indexData.map(item => ({id: item.id, name: item.name}));
    options.push({id: 'nil', name: 'No Membership Group'});
    return options;
  }

  @autobind synchWithMailerLite() {
    this.showSpinner = true;
    AppUI.siteStore.synchWithMailerlite({ids: {siteId: AppUI.site.id}}, this.afterSynchWithMailerlite);
  }

  @autobind afterSynchWithMailerlite() {
    this.showSpinner = false;
    this.showSuccess = true;
    setTimeout(() => {
      this.showSuccess = false;
    }, 5000);
  }

  render() {
    return (
      <Wrapper>
        {
          this.showSuccess && (
            <Alert>
              Your users have been successfully synchronized with MailerLite.
            </Alert>
          )
        }
        {
          this.showCreate &&
            <Modal
              onCloseModal={() => this.showCreate = false}
            >
              <UserGroupCreate />
            </Modal>
        }
        {
            <Filter
              store={UserGroupStore}
              queryStrings={{include_count: true, expanded: true}}
              defaultSort='email'
              namespace='patreon-user-index'
              ids={{groupId: this.props.params.groupId}}
              filters={
                [
                  {
                    type: 'text',
                    name: 'email',
                    label: 'email',
                    placeholder: 'Email',
                    default: '',
                  },
                  {
                    type: 'text',
                    name: 'name',
                    label: 'name',
                    placeholder: 'Name',
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'membership_type_id',
                    options: this.membershipTypeOptions,
                    labelField: 'name',
                    label: 'Membership Tier',
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'membership_group_id',
                    options: this.membershipGroupOptions,
                    labelField: 'name',
                    label: 'Membership Group',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'permanent',
                    label: 'Permanent',
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'role',
                    options: Constants.USER_ROLES,
                    label: 'Role',
                    placeholder: 'Role',
                    default: '',
                  },
                ]
              }
            />
        }
        <ButtonRow>
          <button
            className='gray-button'
            onClick={() => this.showCreate = true}
          >
            <i className='fa fa-plus' />
            Add User
          </button>

          {
            AppUI.permissions.canAccessUserEmails && (
              <a href={this.exportUrl} target='_blank' rel='noopener noreferrer'>
                <button
                  style={{
                    marginLeft: '10px',
                  }}
                  className='gray-button'
                >
                  <i className='fa fa-angle-right' />
                  Export Users Table
                </button>
              </a>
            )
          }
          {
            this.showSpinner && (
              <div style={{marginLeft: '10px'}}>
                <Spinner background={AppUI.site.accentColor} />
              </div>
            )
          }
        </ButtonRow>
        <table
          className='table-wrapper'
          style={{
            marginTop: 10,
          }}
        >
          <thead>
            <tr>
              <th width='40'></th>
              {
                AppUI.permissions.canAccessUserEmails && (
                  <th>Email</th>
                )
              }
              <th>Name</th>
              {
                !AppUI.siteIsDefault &&
                  <th>Membership Type</th>
              }
              {
                AppUI.site.enableMailerlite && (
                  <th>Mailerlite</th>
                )
              }
              <th>Agreed to Email?</th>
            </tr>
          </thead>
          <tbody>
          {
            UserGroupStore.requestCounter > 0 &&
              <tr>
                <td colSpan={6}>
                  <Spinner background={AppUI.site.accentColor} />
                </td>
              </tr>
          }
            {
              UserGroupStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'creator.groups.users.edit', params: { groupId: this.props.params.groupId, userId: item.user.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    {
                      AppUI.permissions.canAccessUserEmails && (
                        <td>
                          <div className='text'>
                            {item.user.email}
                          </div>
                        </td>
                      )
                    }
                    <td>
                      <div className='text'>
                        {item.user.name}
                      </div>
                    </td>
                    {
                      !AppUI.siteIsDefault &&
                        <td>
                          <div className='text'>
                            {item.membershipType?.name}
                          </div>
                        </td>
                    }
                    {
                      AppUI.site.enableMailerlite && (
                        <td>
                          {
                            item.mailerliteId &&
                              <a href={`https://dashboard.mailerlite.com/subscribers/${item.mailerliteId}`} className='underline' target='_blank'>Mailerlite Page</a>
                          }
                        </td>
                      )
                    }
                    <td>
                      <CheckIfTrue
                        value={item.userAgreedToEmail === 'true'}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={UserGroupStore}
          queryStrings={{expanded: true, include_count: true}}
        />
      </Wrapper>
    );
  }
}

export default UsersIndex;
