import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import SentenceBreakdown from 'components/sentence_breakdown';
import { LanguageIcon } from '@seedlang/icons';
import Text from 'components/text';
import { AppUI } from '@seedlang/state';
import { flexCenter } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
`;

const TranslationWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

const LanguageIconWrapper = styled.div`
  margin: ${props => props.margin};
  width: ${props => props.width};
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  padding: ${props => props.padding};
  margin: 2px 0;
`;

const ColumnWrapper = styled.div`
  ${flexCenter()}
  width: 40px;
`;

const Level = styled.div`
  font-size: 13px;
  color: #333;

`;

const SentenceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Trivia = styled.div`
  background: ${Theme.purple};
  padding: 0 5px;
  font-weight: bold;
  color: #FFF;
  font-size: 12px;
  border-radius: 5px;
`;

@observer
class VocabSentence extends React.Component {

  static defaultProps = {
    topSentencePadding: '5px 10px',
    bottomSentencePadding: '0 10px 5px 10px',
  }

  render() {
    return (
      <Wrapper
        onClick={() => this.props.onClick && this.props.onClick(this.props.sentence)}
      >
        <SentenceWrapper>
          <Row
            padding={this.props.topSentencePadding}
          >
            <LanguageIconWrapper
              margin='3px 0'
              width='25px'
            >
              <LanguageIcon
                languageId={this.props.sentence.languageId}
              />
            </LanguageIconWrapper>
            <TranslationWrapper>
              <SentenceBreakdown
                cursor='pointer'
                pointerEvents='none'
                fontSize={this.props.fontSize}
                lineHeight={this.props.lineHeight}
                disableOnClick
                highlightWordId={this.props.wordId}
                sentence={this.props.sentence}
                highlightConceptId={this.props.highlightConceptId}
              />
            </TranslationWrapper>
          </Row>
          <Row
            padding={this.props.bottomSentencePadding}
          >
            <LanguageIconWrapper
              width='25px'
            >
              <LanguageIcon
                languageId={this.props.sentence.sourceTextForUserLanguageId(AppUI.user)}
                useUkFlagForEn={AppUI.user.useBritishEnglish}
              />
            </LanguageIconWrapper>
            <TranslationWrapper>
              <Text
                textAlign='left'
                lineHeight={this.props.lineHeight}
                fontSize={this.props.fontSize}
                italic
              >
                {this.props.sentence.sourceTextForUser(AppUI.user)}
              </Text>
            </TranslationWrapper>
          </Row>
        </SentenceWrapper>
        {
          (AppUI.user.translator || AppUI.user.admin) &&
            <ColumnWrapper>
              {
                this.props.sentence.isTriviaSentence && (this.props.sentence.isQuestion || this.props.sentence.isAnswer) &&
                  <div
                    data-tip
                    data-for={this.props.sentence.id}
                  >
                    <Trivia>
                      {this.props.sentence.isQuestion ? 'Q' : 'A'}
                    </Trivia>
                    <ReactTooltip
                      place='left'
                      type='dark'
                      effect='solid'
                      id={this.props.sentence.id}
                      class='custom-tooltip'
                    >
                      {this.props.sentence.isQuestion ? 'This is a trivia question' : 'This is a trivia answer'}
                    </ReactTooltip>
                  </div>
              }
            </ColumnWrapper>
        }
        {
          !this.props.hideLevelAbbreviation &&
            <ColumnWrapper>
              <Level>
                {this.props.sentence.levelAbbreviation}
              </Level>
            </ColumnWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabSentence);
