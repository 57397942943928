import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { ForumStore , GroupStore } from '@seedlang/stores';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';

@observer
class ForumEdit extends React.Component {

  constructor(props) {
    super(props);
    ForumStore.getShow({ ids: {forumId: this.props.params.forumId }}, this.afterGetShow);
    GroupStore.getIndex();
  }

  @autobind afterGetShow(resp) {
    ForumStore.setShowData(resp);
  }

  render() {
    if (ForumStore.hasShowData) {
      return (
        <div className='forum-edit'>
          <div className='row'>
            <div className='col-xs-4'>
              <fieldset>
                <legend>Title</legend>
                <InPlaceText
                  defaultValue={ForumStore.showDataField('title')}
                  model='forums'
                  field='title'
                  id={ForumStore.showDataField('id')}
                />
              </fieldset>
            </div>
            <div className='col-xs-4'>
              <fieldset>
                <legend>Slug</legend>
                <InPlaceText
                  defaultValue={ForumStore.showDataField('slug')}
                  model='forums'
                  field='slug'
                  id={ForumStore.showDataField('id')}
                />
              </fieldset>
            </div>
            <div className='col-xs-4'>
              <fieldset>
                <legend>Group</legend>
                <InPlaceSelect
                  includeBlank
                  id={ForumStore.showData.id}
                  value={ForumStore.showData.hasGroup && ForumStore.showData.group.id}
                  model='forums'
                  field='group_id'
                  options={GroupStore.indexData.map(item => [item.id, item.name])}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Only Admin Can Access</legend>
                <InPlaceCheckbox
                  id={ForumStore.showData.id}
                  value={ForumStore.showData.admin}
                  model='forums'
                  field='admin'
                  icon='check'
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Only Moderator Can Access</legend>
                <InPlaceCheckbox
                  id={ForumStore.showData.id}
                  value={ForumStore.showData.moderator}
                  model='forums'
                  field='moderator'
                  icon='check'
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Only Translator Can Access</legend>
                <InPlaceCheckbox
                  id={ForumStore.showData.id}
                  value={ForumStore.showData.translator}
                  model='forums'
                  field='translator'
                  icon='check'
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Hide Create Button</legend>
                <InPlaceCheckbox
                  id={ForumStore.showData.id}
                  value={ForumStore.showData.hideCreateDiscussionButton}
                  model='forums'
                  field='hide_create_discussion_button'
                  icon='check'
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Show Filters</legend>
                <InPlaceCheckbox
                  id={ForumStore.showData.id}
                  value={ForumStore.showData.showFilters}
                  model='forums'
                  field='show_filters'
                  icon='check'
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Highlight Unseen</legend>
                <InPlaceCheckbox
                  id={ForumStore.showData.id}
                  value={ForumStore.showData.highlightUnseen}
                  model='forums'
                  field='highlight_unseen'
                  icon='check'
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Image</legend>
                <InPlaceImageUpload
                  model='images'
                  image={ForumStore.showData.image}
                  querystring={{
                    imageable_id: ForumStore.showData.id,
                    imageable_type: 'Forum',
                  }}
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Language</legend>
                <InPlaceSelect
                  includeBlank
                  id={ForumStore.showData.id}
                  value={ForumStore.showData.languageId}
                  model='forums'
                  field='language_id'
                  options={AppUI.languageOptions}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Description</legend>
                <InPlaceText
                  inputType='textarea'
                  defaultValue={ForumStore.showDataField('description')}
                  model='forums'
                  field='description'
                  id={ForumStore.showDataField('id')}
                />
              </fieldset>
            </div>
          </div>
        </div>
      );
    }
    return <span />;
  }
}

export default ErrorBoundary(ForumEdit);
