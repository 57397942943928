import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import SentenceBreakdown from 'components/sentence_breakdown';
import WordBreakdown from 'components/word_breakdown';
import Copy from 'components/copy';
import VideoPlayersWithType from 'pages/builder/video_clips/video_players_with_type';
import { Link } from 'react-router';
import Text from 'components/text';
import InPlaceText from 'components/form/in_place_text';
import { isBlank, isPresent } from '@seedlang/utils';
import DeleteButton from 'components/button/delete_button';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI, DeckUI } from '@seedlang/state';
import { ChatIconWithCount, LightbulbOnIcon } from '@seedlang/icons';
import { Constants, Theme } from '@seedlang/constants';
import MultipleChoiceInput from 'pages/builder/multiple_choice_input';
import MinimalPairEdit from 'pages/builder/words/minimal_pair_edit';
import InfoTooltip from 'components/info_tooltip';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.tr`
  position: relative;
  background: ${props => props.background};
  .fa-warning {
    position: absolute;
    top: 0;
    right: 0;
  }
  td {
    background: transparent!important;
  }
`;

const Answer = styled.div`
  margin-top: 10px;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  #lines {
    fill: ${props => props.linesFill};
  }
  .lightbulb-on-icon {
    margin-top: -5px;
    svg {
      width: 22px;
      fill: ${props => props.fill};
    }
  }
`;

const IconsWrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const MissingVideo = styled.div`
  background: #CCC;
  border-radius: 105px;
  position: relative;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: italic;
  width: 150px;
  height: 150px;
  margin: 0 10px 10px 0;
`;

@observer
class CardIndexRow extends React.Component {

  onChangeCardSource(val) {
    const cardIds = this.props.deck.cards.filter(card => card.word?.id === this.props.card.word.id).map(card => card.id);
    DeckUI.cardStore.batchUpdate({data: {cardIds: cardIds, source: val}}, this.props.afterChange);
  }

  @computed get showWarning() {
    return (this.props.showMissingUnderlines &&
      this.props.card.sentence &&
      ['interjection', 'translate'].indexOf(this.props.card.cardType.slug) === -1 &&
      isBlank(this.props.card.cardWordAssociations) &&
      isBlank(this.props.card.sentence.selectedWordAssociations))
      || (isBlank(this.props.card.word) && this.props.card.conjugationCard);
  }

  @computed get hasConjugatedString() {
    if (isBlank(this.props.card.word) || isBlank(this.props.card.tenseConceptId) || isBlank(this.props.card.pronoun) || !this.props.card.cardTypeSlug.startsWith('conjugation')) { return false; }
    const conjugatedVerbString = this.props.card?.word?.conjugatedVerbStrings.find(item => item.conceptId === this.props.card.tenseConceptId);
    return isPresent(conjugatedVerbString) && isPresent(conjugatedVerbString[this.props.card.pronoun.replace('_', '')]);
  }

  render() {
    return (
      <Wrapper
        background={this.showWarning ? 'rgba(255,241,241,0.5)' : '#FFF'}
      >
        <td>
          {
            this.showWarning &&
              <i className='fa fa-warning fa-row-warning' />
          }
          <InPlaceText
            id={this.props.card.id}
            defaultValue={this.props.card.position}
            field='position'
            model='cards'
            type='number'
            afterChange={this.props.afterChange}
          />
        </td>
        <td>
          <IconsWrapper>
          {
            this.props.card.cardType.learningCard &&
              <IconWrapper
                fill={isPresent(this.props.card.grammarTip) ? Theme.orange : '#000'}
                linesFill={isPresent(this.props.card.grammarTip) ? Theme.orange : '#FFF'}
                onClick={() => this.props.setCardForGrammarTip(this.props.card)}
              >
                <LightbulbOnIcon />
              </IconWrapper>
          }
          {
            this.props.card.cardType.learningCard && isPresent(this.props.card.translation) &&
              <IconWrapper>
                <a
                  className='underline'
                  style={{fontSize: 14}}
                  href={`https://www.seedlang.com/discussions/${Constants.VOCAB_FORUM_IDS[this.props.card.languageId]}/threads/${this.props.card.translation.forumThreadId}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <ChatIconWithCount
                    width={18}
                    count={this.props.card.commentsCount}
                    fontSize='10px'
                    top='-1px'
                    left='6px'
                  />
                </a>
              </IconWrapper>
          }
          </IconsWrapper>
        </td>
        <td style={{textTransform: 'capitalize'}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div style={{marginRight: 5}}>
              <Copy
                text={this.props.card.id}
              />
            </div>
            {
              this.props.card.cardRule &&
                <Text
                  fontSize='14px'
                >
                  {this.props.card.cardRule.cardType.name}
                </Text>
            }
            {
              this.props.card.cardRule.cardType.slug === 'exercise' &&
                <InfoTooltip icon='edit' onClick={() => AppUI.scrollToPageTop('smooth', 'exercise-top')}>Edit exercises</InfoTooltip>
            }
            {
              this.props.card.interjectionCode &&
                <Text
                  fontSize='14px'
                >
                  ({this.props.card.interjectionCode})
                </Text>
            }
          </div>
        </td>
        <td>
          {
            this.props.card.sentence &&
              <Link
                to={{ name: 'builder.sentences.edit', params: { sentenceId: this.props.card.sentence.id } }}
                data-tip
                data-for={`sentence-link-${this.props.card.sentence.id}`}
              >
                <i className='fa fa-chevron-right fa-block' />
                <ReactTooltip
                  type='dark'
                  effect='solid'
                  id={`sentence-link-${this.props.card.sentence.id}`}
                  class='custom-tooltip'
                >
                  Go to Sentence
                </ReactTooltip>
              </Link>
          }
          {
            this.props.card.word &&
              <Link
                to={{ name: 'builder.words.edit', params: { wordId: this.props.card.word.id } }}
                data-tip
                data-for={`word-link-${this.props.card.word.id}`}
              >
                <i className='fa fa-chevron-right fa-block' />
                <ReactTooltip
                  type='dark'
                  effect='solid'
                  id={`word-link-${this.props.card.word.id}`}
                  class='custom-tooltip'
                >
                  Go to Word
                </ReactTooltip>
              </Link>
          }
        </td>
        <td>
          {
            this.props.card.hasTranslation &&
              <div>
                <div className='text-wrapper'>
                  <div className='icons'>
                    <Copy
                      text={this.props.card.translation.targetText}
                    />
                  </div>
                  <div className='text'>
                    {
                      this.props.card.sentence &&
                        <SentenceBreakdown
                          showUnderline={this.props.card.cardType.slug !== 'exercise'}
                          selectable={this.props.card.cardType.slug !== 'exercise'}
                          alertOnNoHighlightWordAssociationIds={this.props.card.cardRule.useAnswer}
                          highlightWordAssociationIds={this.props.card.cardType.slug === 'exercise' ? this.props.card.sentence.selectedWordAssociations.map(item => item.id) : this.props.card.cardWordAssociations.map(item => item.wordAssociationId)}
                          multipleSelections
                          sentence={this.props.card.sentence}
                          onClick={this.props.toggleCardWordAssociation.bind(this, this.props.card)}
                        />
                    }
                    {this.props.card.word && !this.props.card.vocabWordComprehendCard && this.props.card.word.targetTextWithDefiniteArticle}
                    { isBlank(this.props.card.word) && this.props.card.cardTypeSlug.startsWith('conjugation') && <InfoTooltip icon='warning'>Verb is missing. Please set up the sentence and rebuild.</InfoTooltip>}
                    { isPresent(this.props.card.word) && !this.hasConjugatedString && this.props.card.cardTypeSlug.startsWith('conjugation') && <InfoTooltip icon='warning'>This verb doesn't have conjugations for this tense.</InfoTooltip>}
                    {this.props.card.word && this.props.card.vocabWordComprehendCard &&
                      <WordBreakdown
                        card={this.props.card}
                        afterChange={this.props.afterChange}
                        editable={this.props.deck.pronunciationDeck}
                      />
                    }
                  </div>
                </div>
                <hr />
                <div className='text-wrapper'>
                  <div className='icons'>
                    <Copy
                      text={this.props.card.sourceText}
                    />
                  </div>
                  <div className='text'>
                    {this.props.card.sourceText}
                  </div>
                </div>
                {
                  this.props.card.word && (this.props.card.word?.vocabSources?.length > 1) &&
                    <hr />
                }
                {
                  this.props.card.word && (this.props.card.word?.vocabSources?.length > 1) && isBlank(this.props.card.sentence) &&
                    <div
                      className='text-wrapper'
                      style={{alignItems: 'center'}}
                    >
                      Selected source:
                      <InPlaceSelect
                        includeBlank
                        value={this.props.card.source}
                        options={this.props.card.word.vocabSources.map(item => [item, item])}
                        onChange={val => this.onChangeCardSource(val)}
                        margin='0 10px 0 10px'
                      />
                      <InfoTooltip>
                        <div>This source will be highlighted</div>
                        <div>and used in the multiple choice card.</div>
                        <div>This is optional.</div>
                      </InfoTooltip>
                    </div>
                }
                {
                  this.props.card.word && isBlank(this.props.card.word?.vocabSources) &&
                    <div className='text-wrapper'>
                      <i className='fa fa-warning fa-row-warning' />
                      <div className='text' style={{color: Theme.red}} >
                        [No Source]
                      </div>
                    </div>
                }
                {
                  this.props.card.cardRule.cardType.slug === 'vocab_sentence_translate' &&
                    <span>
                      <hr />
                      <div className='text-wrapper' style={{marginTop: 20}}>
                        <div className='icons'>
                        </div>
                        <div className='text'>
                          Alternate Prompt:
                          <InPlaceText
                            id={this.props.card.id}
                            defaultValue={this.props.card.prompt}
                            model='cards'
                            field='prompt'
                          />
                        </div>
                      </div>
                    </span>
                }
                {
                  this.props.card.minimalPairCard &&
                    <span>
                      <hr />
                      <div className='text-wrapper' style={{marginTop: 20}}>
                        <div className='icons'>
                        </div>
                        <div className='text' style={{display: 'flex', alignItems: 'baseline'}}>
                          <span style={{whiteSpace: 'nowrap', marginRight: '5px'}}>Minimal Pair:</span>
                          <MinimalPairEdit
                            word={this.props.card.word}
                            onChange={this.props.afterChange}
                          />
                          {
                            isBlank(this.props.card.word.minimalPair) &&
                              <i className='fa fa-warning' />
                          }
                        </div>
                      </div>
                    </span>
                }
                {
                  this.props.card.cardRule.multipleChoice &&
                    <div>
                      <hr />
                      <MultipleChoiceInput
                        multipleChoiceOptions={this.props.card?.sentence?.multipleChoiceOptions}
                      />
                      {
                        isPresent(this.props.card.sentence.answers) &&
                          <Answer>
                            <div className='text-wrapper'>
                              <div className='icons'>
                                <Copy
                                  text={this.props.card.sentence.answers[0].targetText}
                                />
                              </div>
                              <div className='text'>
                                <SentenceBreakdown
                                  alertOnNoHighlightWordAssociationIds
                                  showUnderline
                                  selectable
                                  highlightWordAssociationIds={this.props.card.cardWordAssociations.map(item => item.wordAssociationId)}
                                  multipleSelections
                                  sentence={this.props.card.sentence.answers[0]}
                                  onClick={this.props.toggleCardWordAssociation.bind(this, this.props.card)}
                                />
                              </div>
                            </div>
                            <hr />
                            <div className='text-wrapper'>
                              <div className='icons'>
                                <Copy
                                  text={this.props.card.sentence.answers[0].sourceText}
                                />
                              </div>
                              <div className='text'>
                                {this.props.card.sentence.answers[0].sourceText}
                              </div>
                            </div>
                          </Answer>
                      }
                    </div>
                }
              </div>
          }
        </td>
        <td>
          <div
            style={{display: 'flex', width: 320, maxHeight: 320, flexWrap: 'wrap'}}
          >
            {
              this.props.card.hasTargetVideoClip &&
                <VideoPlayersWithType
                  videoClip={this.props.card.targetVideoClip}
                  width='150'
                />
            }
            {
              !this.props.card.hasTargetVideoClip && (['trivia', 'word_pronounce', 'minimal_pair', 'sentence_pronounce'].indexOf(this.props.card.cardType.slug) !== -1) &&
                <MissingVideo>
                  <i className='fa fa-warning' />
                  Missing Video
                </MissingVideo>
            }
            {
              this.props.card.sentence && isPresent(this.props.card.sentence.answers) && isPresent(this.props.card.sentence.answers[0].target.videoClip) &&
                <VideoPlayersWithType
                  videoClip={this.props.card.sentence.answers[0].target.videoClip}
                  width='150'
                />
            }
            {
              this.props.card.sentence && isPresent(this.props.card.sentence.answers) && isBlank(this.props.card.sentence.answers[0].target.videoClip) &&
                <MissingVideo>
                  <i className='fa fa-warning' />
                  Missing Video
                </MissingVideo>
            }
            {
              this.props.card.sentence && isPresent(this.props.card.sentence.answers) && isPresent(this.props.card.sentence.answers[0].correctVideoClip) &&
                <VideoPlayersWithType
                  videoClip={this.props.card.sentence.answers[0].correctVideoClip}
                  width='150'
                />
            }
            {
              this.props.card.sentence && isPresent(this.props.card.sentence.answers) && isBlank(this.props.card.sentence.answers[0].correctVideoClip) &&
                <MissingVideo>
                  <i className='fa fa-warning' />
                  Missing Video
                </MissingVideo>
            }
            {
              this.props.card.sentence && isPresent(this.props.card.sentence.answers) && isPresent(this.props.card.sentence.answers[0].incorrectVideoClip) &&
                <VideoPlayersWithType
                  videoClip={this.props.card.sentence.answers[0].incorrectVideoClip}
                  width='150'
                />
            }
            {
              this.props.card.sentence && isPresent(this.props.card.sentence.answers) && isBlank(this.props.card.sentence.answers[0].incorrectVideoClip) &&
                <MissingVideo>
                  <i className='fa fa-warning' />
                  Missing Video
                </MissingVideo>
            }
            {
              this.props.card.hasSupplementImage &&
                <img
                  src={this.props.card.supplementImage.url}
                  style={{width: 150, height: 150}}
                  alt=''
                />
            }
            {
              this.props.card.hasSupplementVideoClip &&
               <VideoPlayersWithType
                  videoClip={this.props.card.supplementVideoClip}
                  width='150'
               />
            }
            {
              this.props.card.cardRule && this.props.card.cardRule.composite &&
                <div className='composite'>
                  {this.props.deck.feedEntryMediaItems.length} Composite Videos:
                  {
                    this.props.deck.feedEntryMediaItems.map(item => {
                      return (
                        <div
                          key={item.id}
                        >
                          {item.targetText}
                        </div>
                      );
                    })
                  }
                </div>
            }
          </div>
        </td>
        <td>
          <DeleteButton
            onConfirm={() => this.props.onDeleteCard(this.props.card.id)}
            message='Delete this card?'
            className='fa-block right'
          />
        </td>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(CardIndexRow);
