import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import Text from 'components/text';
import { pixify , isPresent } from '@seedlang/utils';
import { SourceEditWrapper } from '@seedlang/hoc';
import SourceItem from 'components/source/source_item';
import SourceSubmission from 'components/source/source_submission';

const Wrapper = styled.div`
  padding: 10px;
  background: white;
  height: ${props => props.height};
  overflow: auto;
`;

@observer
class SourceEdit extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.overlayHeight)}
      >
        <Text
          center
          italic
          margin='10px 0 0 0'
        >
          View Translations for
        </Text>
        <Text
          heading='2'
          center
          margin='0px 0 20px 0'
        >
          {this.props.translationText}
        </Text>
        {
          isPresent(this.props.word) && this.props.wordSources.map(item => {
            return (
              <SourceItem
                key={item.id}
                word={item}
                flagTranslation={this.props.flagTranslation}
              />
            );
          })
        }
        {
          isPresent(this.props.sentence) && this.props.sentenceSources.map(item => {
            return (
              <SourceItem
                key={item.id}
                word={item}
                flagTranslation={this.props.flagTranslation}
              />
            );
          })
        }
        {
          isPresent(this.props.word) &&
            <SourceSubmission
              afterUpdate={this.props.afterUpdate}
              word={this.props.word}
            />
        }
      </Wrapper>
    );
  }
}

export default SourceEditWrapper(SourceEdit);
