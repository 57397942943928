import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { AudioPlayerWrapper } from '@seedlang/hoc';
import cx from 'classnames';
import AudioPlayerProgress from 'components/media/audio_player_progress';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  height: 40px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  .spinner {
    position: absolute;
    top: -10px;
    z-index: 9999;
    left: 0;
    right: 0;
  }
`;

const PlayerComponents = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 40px;
`;

const PlayToggle = styled.div`
  padding: ${props => props.padding};
  margin-right: 10px;
  cursor: pointer;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #616161;
  font-size: 22px;
`;

const Duration = styled.div`
  font-size: 10px;
  font-style: italic;
  text-align: right;
  margin-left: 10px;
`;

@observer
class AudioPlayer extends React.Component {

  static propTypes = {
    mediaStore: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props);
    this.props.mediaStore.setupAudioPlayer(document.createElement('audio'), this.props.url, this.props.duration);
  }

  render() {
    return (
      <Wrapper>
        <PlayerComponents>
          <PlayToggle
            padding={this.props.mediaStore.playing ? null : '0 0 0 3px'}
            className={cx('fa', {
              'fa-play': !this.props.mediaStore.playing,
              'fa-pause': this.props.mediaStore.playing,
            })}
            onClick={this.props.onTogglePlay}
          />
          <AudioPlayerProgress
            currentTime={this.props.mediaStore.currentTime}
            duration={this.props.mediaStore.duration}
            onClick={this.props.mediaStore.seek}
          />
          <Duration>
            {`${this.props.durationStringWeb}s`}
          </Duration>
        </PlayerComponents>
      </Wrapper>
    );
  }
}

export default AudioPlayerWrapper(AudioPlayer);
