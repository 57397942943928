import React from 'react';
import { observer } from 'mobx-react';
import UserProfileImage from 'components/user/user_profile_image';
import Paginator from 'components/paginator';
import { isPresent } from '@seedlang/utils';
import NotificationMessage from 'components/notification/notification_message';
import { SeedlangIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import Text from 'components/text';
import { AppUI } from '@seedlang/state';
import SiteProfileImage from 'components/site_profile_image';

const Wrapper = styled.div`
  .seedlang-icon {
    margin-right: 10px;
    border-radius: 50%;
    border: 1px solid green;
    svg {
      width: 52px;
    }
  }
`;

const Notification = styled.div`
  background: ${props => props.background};
  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  line-height: 18px;
  border-bottom: 1px solid #CCC;
  text-align: left;
`;

const MessageWrapper = styled.div`
  flex: 1;
`;

const PaginatorWrapper = styled.div`
  padding: 0 5px 5px 5px;
`;

@observer
class NotificationIndex extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.props.notifications) && this.props.notifications.map((item, index) => {
            return (
              <Notification
                background={item.seen ? 'FFF' : '#edf2fa'}
                key={item.id}
              >
                {
                  item.primarySender &&
                    <UserProfileImage
                      clickToProfile
                      user={item.primarySender}
                      width={52}
                      margin='0 10px 0 0'
                    />
                }
                {
                  !item.primarySender && AppUI.site.isDefault &&
                    <SeedlangIcon />
                }
                {
                  !item.primarySender && !AppUI.site.isDefault &&
                    <SiteProfileImage
                      site={AppUI.site}
                      width={52}
                      margin='0 10px 0 0'
                    />
                }
                <MessageWrapper>
                  <NotificationMessage
                    notification={item}
                  />
                  <Text
                    italic
                    color='#777'
                    fontSize='13px'
                  >
                    {item.createdAt && item.createdAt.relativeTime}
                  </Text>
                </MessageWrapper>
              </Notification>
            );
          })
        }
        {
          isPresent(this.props.notifications) &&
            <PaginatorWrapper>
              <Paginator
                compact
                onClickScrollToTop
                store={this.props.store}
              />
            </PaginatorWrapper>
        }
      </Wrapper>
    );
  }
}

export default NotificationIndex;
