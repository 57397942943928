"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = sentryInitializer;
var Sentry = _interopRequireWildcard(require("@sentry/react"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { "default": e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n["default"] = e, t && t.set(e, n), n; }
function sentryInitializer() {
  // need to disable uBlock Origin on localhost if testing error tracking
  Sentry.init({
    dsn: 'https://019e707473e54ce39a35d78e8ed2cd6b@o85774.ingest.sentry.io/186663',
    ignoreErrors: ['AbortError', 'NotSupportedError', 'NotAllowedError'],
    beforeSend: function beforeSend(event) {
      var localhost = window && window.location && window.location.href.match('localhost');
      if (localhost) {
        return null; // this is equal to shouldSend false
      } else {
        return event;
      }
    }
  });
}