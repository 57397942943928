import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Constants } from '@seedlang/constants';
import ArrowBox from 'components/arrow_box';
import FeedbackItem from 'components/feedback/feedback_item';
import Emoticon from 'components/feedback/emoticon';
import { isBlank } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  background: white;
  width: 246px;
  flex-wrap: wrap;
`;

const Background = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

@observer
class FeedbackPicker extends React.Component {

  static defaultProps = {
    arrowBoxBottom: '35px',
  }

  render() {
    return (
      <div>
        <Background
          onClick={this.props.onClose}
        />
        <ArrowBox
          show
          arrowPosition='bottom'
          width='250px'
          height='250px'
          bottom={this.props.arrowBoxBottom}
          left='-20px'
        >
          <Wrapper>
            {
              Constants.FEEDBACK_OPTIONS.map(item => {
                if (isBlank(item.site) || item.site.includes(AppUI.site.id)) {
                  return (
                    <FeedbackItem
                      key={item.feedbackType}
                      margin='2px'
                      onClick={() => this.props.onClick(item.feedbackType)}
                    >
                      <Emoticon
                        image={item.image}
                      />
                    </FeedbackItem>
                  );
                }
              })
            }
          </Wrapper>
        </ArrowBox>
      </div>
    );
  }
}

export default ErrorBoundary(FeedbackPicker);
