import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import Copy from 'components/copy';
import styled from '@emotion/styled';
import Button from 'components/button/button';

const Wrapper = styled.div`

`;

@observer
class SettingsAffiliate extends React.Component {

  @autobind onRequestAffiliateCode() {
    AppUI.authUserStore.assignAffiliateCode({}, this.afterAffiliateCode);
  }

  @autobind afterAffiliateCode(resp) {
    AppUI.loadUser();
  }

  render() {
    return (
      <Wrapper>
        {
          AppUI.userIsLoaded &&
            <div>
              <h3>Affiliate Program</h3>

              <p>
                We will pay 8% of a new user's first-year expenditure on Seedlang to you for every user that signs up using your affiliate link.
              </p>

              {
                !AppUI.user.affiliateCode &&
                  <Button
                    onClick={this.onRequestAffiliateCode}
                  >
                    Request Affiliate Link
                  </Button>
              }
              {
                AppUI.user.affiliateCode &&
                  <div>
                    <Copy
                      className='outline-button blue'
                      text={`https://www.seedlang.com?affiliate_code=${AppUI.user.affiliateCode}`}
                    >
                      Click to Copy Affiliate Link
                    </Copy>

                    <table className='table-wrapper'>
                      <thead>
                        <tr>
                          <th>Month</th>
                          <th>New Signups</th>
                          <th>Expenditures</th>
                        </tr>
                      </thead>
                    </table>
                    <p className='notice'>
                      There have not yet been any signups using your affiliate code.
                    </p>
                  </div>
              }
            </div>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsAffiliate);
