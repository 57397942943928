import React from 'react';
import { observer } from 'mobx-react';
import Paginator from 'components/paginator';
import { ReviewIndexWrapper } from '@seedlang/hoc';
import { AppUI, DeckUI } from '@seedlang/state';
import DeleteButton from 'components/button/delete_button';
import styled from '@emotion/styled';
import { NoteIcon, ReplyIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import InPlaceText from 'components/form/in_place_text';
import ReviewIndexAttribute from 'components/review/review_index_attribute';
import InPlaceSelect from 'components/form/in_place_select';
import { isPresent } from '@seedlang/utils';
import Spinner from 'components/spinner';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ReviewIndexSortButton from 'components/review/review_index_sort_button';
import SearchInput from 'components/form/search_input';
import CommentButton from 'components/button/comment_button';
import ReactTooltip from 'react-tooltip';
import Filter from 'components/filter';
import CardTypeSelect from 'components/review/card_type_select';
import Text from 'components/text';
import Checkbox from 'components/checkbox';
import ExpandAllButton from 'components/review/expand_all_button';
import BulkEditReviews from 'components/review/bulk_edit_reviews';

const Wrapper = styled.div`
  width: 100%;
  margin-top: ${props => props.marginTop};
`;

const ReviewsWrapper = styled.div`
  background: #FFF;
  margin-top: 10px;
`;

const CardWrapper = styled.div`
  display: flex;
  background: #f1f1f1;
  padding: 10px;
  margin-bottom: 10px;
  flex-direction: column;
  margin: 10px;
`;

const SortWrapper = styled.div`
  color: #FFF;
  font-size: 14px;
  background: #636363;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  height: fit-content;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px;
  gap: 5px;
`;

const SortButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 5px;
  gap: 5px;
`;

const DifficultyScore = styled.div`
  background: ${props => props.background};
  color: #FFF;
  border-radius: 5px;
  font-size: 14px;
  padding: 5px;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const NoResults = styled.div`
  padding: 10px 10px 30px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 5px;
`;

const IconWrapper = styled.div`
  margin-right: 3px;
  .fa-times {
    margin-top: 1px;
  }
  .fa-refresh {
    margin-top: 2px;
    font-size: 10px;
  }
  .reply-icon {
    transform: rotate(180deg);
    svg {
      fill: #333;
      width: 11px;
      margin-top: 3px;
    }
  }
`;

const DifficultyScoreWrapper = styled.div`
  display: inline-flex;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CardSummary = styled.div`
  width: 100%;
  display: flex;
  gap: 0 20px;
  align-items: left;
  justify-content: space-between;
  cursor: pointer;
`;

const ExpandedCardContent = styled.div`
  margin-top: 10px;
`;

const IconsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const MobileExpandAllButton = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

@observer
class ReviewIndex extends React.Component {

  render() {
    return (
      <Wrapper
        marginTop={AppUI.layout.isMobile ? '10px' : null}
      >
        <SearchInput
          placeholder='Search for a Review'
          onChange={this.props.onUpdateSearchQuery}
          insertText={this.props.insertText}
          focusOnId={this.props.searchQuery}
          value={this.props.searchQuery}
          caretPosition={this.props.caretPosition}
          background='#FFF'
        />
        <Filter
          filterName='Review Filters'
          noFiltersSelectedMessage={this.props.noFiltersSelectedMessage}
          filterSettings={this.props.filterSettings}
          onSetFilter={this.props.onSetFilter}
          searchQuery={this.props.searchQuery}
          hasActiveFilter={this.props.hasActiveFilter}
          onToggleFiltersExpanded={this.props.onToggleFiltersExpanded}
          setShowFilterOptions={this.props.setShowFilterOptions}
          showFilterOptions={this.props.showFilterOptions}
          filteredItemsCount={this.props.filteredItemsCount}
          filteredItemsType='Review'
        />
        <BulkEditReviews
          selectedReviewIds={this.props.selectedCardIds}
          reviewDeckOptions={this.props.reviewDeckOptions}
          afterChange={this.props.getReviews}
          resetSelectedCards={this.props.resetSelectedCards}
        />
        <ReviewsWrapper>
          <SortWrapper>
            <Checkbox
              margin='0 0 0 15px'
              value={this.props.allSelected}
              onClick={this.props.toggleSelectAll}
            />
            <SortButtonsWrapper>
              <ReviewIndexSortButton
                column='created_at'
                onClick={this.props.onChangeSort}
                sort={this.props.sort}
                label='Created'
              />
              <ReviewIndexSortButton
                column='review_after'
                onClick={this.props.onChangeSort}
                sort={this.props.sort}
                label='Next Due'
              />
              <ReviewIndexSortButton
                column='difficulty_score'
                onClick={this.props.onChangeSort}
                sort={this.props.sort}
                label='Difficulty'
              />
              {
                !AppUI.layout.isMobile &&
                  <ExpandAllButton
                    onClick={this.props.toggleExpandAll}
                    expanded={this.props.allCardsExpanded}
                  />
              }
            </SortButtonsWrapper>
            {
              !AppUI.layout.isMobile && <div />
            }
            {
              AppUI.layout.isMobile && !this.props.allCardsExpanded &&
              <MobileExpandAllButton onClick={this.props.toggleExpandAll}>
                <i className='fa fa-plus'/>
              </MobileExpandAllButton>
            }
            {
              AppUI.layout.isMobile && this.props.allCardsExpanded &&
                <MobileExpandAllButton onClick={this.props.toggleExpandAll}>
                  <i className='fa fa-minus'/>
                </MobileExpandAllButton>
            }
          </SortWrapper>
          {
            this.props.showSpinner &&
              <Spinner
                background={Theme.blue}
              />
          }
          {
            !this.props.showSpinner && DeckUI.cardStore.indexData.length === 0 && DeckUI.cardStore.requestCounter === 0 &&
              <NoResults>
                No reviews were found.
              </NoResults>
          }
          {
            !this.props.showSpinner && DeckUI.cardStore.indexData.map(item => {
              return (
                <CardWrapper
                  key={`${item.id}-${Math.random()}`}
                >
                  <CardHeader>
                    <Checkbox
                      margin='0 10px 0 0'
                      value={this.props.cardIsSelected(item)}
                      onClick={() => this.props.toggleCardSelect(item)}
                    />
                    <CardSummary
                      onClick={() => this.props.toggleCardExpand(item)}
                    >
                      <div style={{color: this.props.cardDeckIsArchived(item) || item.retired ? '#636363' : null}}>{this.props.frontText(item)}</div>
                      <IconsWrapper>
                        {
                          this.props.cardDeckIsArchived(item) &&
                          <Text fontSize='12px' color='#636363'>Archived</Text>
                        }
                        {
                          item.retired && !this.props.cardDeckIsArchived(item) &&
                            <Text fontSize='12px' color='#636363'>Retired</Text>
                        }
                        {
                          !this.props.cardIsExpanded(item) &&
                          <div
                            data-tip
                            data-for={`${item.id}-expand`}
                            style={{verticalAlign: 'middle'}}
                          >
                            <i className='fa fa-plus'></i>
                            <ReactTooltip
                              type='dark'
                              effect='solid'
                              id={`${item.id}-expand`}
                              class='custom-tooltip'
                            >
                              Expand
                            </ReactTooltip>
                          </div>
                        }
                        {
                          this.props.cardIsExpanded(item) &&
                          <div
                            data-tip
                            data-for={`${item.id}-collapse`}
                          >
                            <i className='fa fa-minus'></i>
                            <ReactTooltip
                              type='dark'
                              effect='solid'
                              id={`${item.id}-collapse`}
                              class='custom-tooltip'
                            >
                              Collapse
                            </ReactTooltip>
                          </div>
                        }
                      </IconsWrapper>
                    </CardSummary>
                  </CardHeader>
                  {
                    this.props.cardIsExpanded(item) &&
                    <ExpandedCardContent>
                      <ReviewIndexAttribute
                      label='FRONT OF CARD'
                      view={this.props.view}
                      >
                      <div>
                        {
                          item.userSubmission && item.cardTypeSlug === 'user_submission' &&
                          <InPlaceText
                            allowUpdate
                            inputType='textarea'
                            id={item.id}
                            model='cards'
                            field='front_text'
                            defaultValue={item.frontText}
                          />
                        }
                        {
                          !(item.userSubmission && item.cardTypeSlug === 'user_submission') && item && item.word && !item.sentence &&
                          <span>
                            {item.word.targetTextWithDefiniteArticle}
                          </span>
                        }
                        {
                          !item.userSubmission && item.sentence &&
                          <span>
                            {item.sentence.targetText}
                          </span>
                        }
                      </div>
                    </ReviewIndexAttribute>
                      {
                        item.cardTypeSlug !== 'exercise' &&
                        <ReviewIndexAttribute
                          label='BACK OF CARD'
                          view={this.props.view}
                        >
                          <div>
                            {
                              item.userSubmission && item.cardTypeSlug === 'user_submission' &&
                              <InPlaceText
                                allowUpdate
                                inputType='textarea'
                                id={item.id}
                                model='cards'
                                field='back_text'
                                defaultValue={item.backText}
                              />
                            }
                            {
                              !(item.userSubmission && item.cardTypeSlug === 'user_submission') && item.word &&
                              <span>
                                  {item.word.vocabSourceList}
                                </span>
                            }
                            {
                              !item.userSubmission && item.sentence &&
                              <span>
                                  {item.sentence.sourceText}
                                </span>
                            }
                          </div>
                        </ReviewIndexAttribute>
                      }
                    <ReviewIndexAttribute
                      label='CARD TYPE'
                      view={this.props.view}
                    >
                      <CardTypeSelect
                        compact
                        card={item}
                        afterChange={val => {
                          item.set('cardTypeId', val);
                          this.props.getReviews();
                        }}
                      />
                    </ReviewIndexAttribute>
                    <ReviewIndexAttribute
                      label='REVIEW DECK'
                      view={this.props.view}
                    >
                      <InPlaceSelect
                        allowUpdate
                        options={item.userSubmission ? this.props.allReviewDeckOptions : this.props.reviewDeckOptions}
                        value={item.deckId}
                        model='cards'
                        field='deck_id'
                        id={item.id}
                        afterChange={val => {
                          item.set('deckId', val);
                          AppUI.set('reloadReviewDecks', true);
                          if (isPresent(this.props.deckId)) {
                            this.props.getReviews();
                          }
                        }}
                      />
                    </ReviewIndexAttribute>
                    <ReviewIndexAttribute
                      label='NOTE'
                      view={this.props.view}
                    >
                      <div>
                        <InPlaceText
                          allowUpdate
                          inputType='textarea'
                          id={item.id}
                          model='cards'
                          field='note'
                          defaultValue={item.note}
                          afterChange={value => item.set('note', value)}
                        />
                        {
                          isPresent(item.note) &&
                          <InPlaceCheckbox
                            allowUpdate
                            icon='check'
                            model='cards'
                            id={item.id}
                            value={item.showNoteBeforeReveal}
                            field='show_note_before_reveal'
                            wrapperMargin='5px 0 0 0'
                          >
                            <Text fontSize='12px' fontWeight='100' color='#555454'>Show Note Icon Before Card is Revealed</Text>
                          </InPlaceCheckbox>
                        }
                      </div>
                    </ReviewIndexAttribute>
                    <ReviewIndexAttribute
                      label='CREATED'
                      value={item.createdAt.relativeTime}
                      view={this.props.view}
                    />
                    <ReviewIndexAttribute
                      label={item.reviewAfter?.date < new Date() ? 'WAS DUE' : 'NEXT DUE'}
                      value={item.reviewAfter?.relativeTime}
                      view={this.props.view}
                    />
                    <ReviewIndexAttribute
                      label='DIFFICULTY'
                      view={this.props.view}
                    >
                      <DifficultyScoreWrapper
                        className='info-tooltip'
                        data-tip
                        data-for={item.id}
                      >
                        <DifficultyScore
                          background={item.difficultyColor}
                        >
                          {item.difficultyLabel}
                        </DifficultyScore>
                        <ReactTooltip
                          type='dark'
                          effect='solid'
                          id={item.id}
                          class='custom-tooltip'
                        >
                          Difficulty Score {item.difficultyScore} of 100
                        </ReactTooltip>
                      </DifficultyScoreWrapper>
                    </ReviewIndexAttribute>
                    <ReviewIndexAttribute
                      label='RETIRED'
                      view={this.props.view}
                    >
                      <Checkbox
                        value={item.retired}
                        icon='check'
                        onClick={() => {
                          this.props.onToggleRetired(item.id, !item.retired);
                          item.set('retired', !item.retired);
                        }}
                      />
                    </ReviewIndexAttribute>
                    <ButtonRow>
                      <CommentButton
                        height='25px'
                        fontWeight='bold'
                        fontSize='12px'

                        onClick={() => AppUI.routeStore.routeToNamed('reviews.show', {
                          page: this.props.page,
                          cardId: item.id,
                        })}
                      >
                        <IconWrapper>
                          <ReplyIcon/>
                        </IconWrapper>
                        Review Details
                      </CommentButton>
                      <DeleteButton
                        allowDeletion
                        onConfirm={() => this.props.onResetReview(item.id)}
                        message='Reset this Review?'
                      >
                        <CommentButton
                          height='25px'
                          fontWeight='bold'
                          fontSize='12px'
                        >
                          <IconWrapper>
                            <i className='fa fa-refresh'/>
                          </IconWrapper>
                          Reset Review
                        </CommentButton>
                      </DeleteButton>
                      <DeleteButton
                        allowDeletion
                        onConfirm={() => this.props.onDelete(item.id)}
                        message='Delete this Review?'
                      >
                        <CommentButton
                          height='25px'
                          fontWeight='bold'
                          fontSize='12px'
                        >
                          <IconWrapper>
                            <i className='fa fa-times'/>
                          </IconWrapper>
                          Delete Review
                        </CommentButton>
                      </DeleteButton>
                    </ButtonRow>
                  </ExpandedCardContent>
                }
                </CardWrapper>
            );})
          }
        </ReviewsWrapper>
        {
          this.props.showPagination &&
            <div className='row'>
              <div className='col-xs-12'>
                <Paginator
                  onClickScrollToTop
                  updateUrl
                  store={DeckUI.cardStore}
                  queryStrings={{my_reviews: true, language_id: AppUI.targetLanguageId}}
                  totalCount={this.props.filteredItemsCount}
                  onPagination={this.props.resetSelectedCards}
                />
              </div>
            </div>
        }
      </Wrapper>
    );
  }
}

export default ReviewIndexWrapper(ReviewIndex);
