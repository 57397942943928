import styled from '@emotion/styled';
import { ExerciseUI } from '@seedlang/state';
import { pixify } from '@seedlang/utils';

export const StickyClickItemWrapper = styled.div`
  @media only screen and (max-width: 699px) {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    background: #f1f1f1;
    position: fixed;
    bottom: 0px;
    left: 0px;
    padding: 10px 5px 30px 5px;
    margin-bottom: 0px;
    box-shadow: rgba(0, 0, 0, 0.32) 2px 2px 4px 4px;
    z-index: 999;
    width: 100%;
  }

  @media only screen and (min-width: 700px) {
    position: sticky;
    top: ${pixify(ExerciseUI.layout.desktopHeaderHeight)};
    background-color: white;
    padding-bottom: 10px;
    border-bottom: 1px solid #CCC;
  }
`;