import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { TabWrapper } from '@seedlang/hoc';
import Tab from 'components/tab';

const Wrapper = styled.div`
  margin: ${props => props.margin};
`;

const Content = styled.div`
  padding: ${props => props.contentPadding};
  background: ${props => props.background};
`;

const TabRow = styled.div`
  border-bottom: 1px solid #CCC;
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;

@observer
class Tabs extends React.Component {

  static defaultProps = {
    contentPadding: '10px 0',
    background: '#FFF',
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <TabRow>
          {
            this.props.tabs.map(tab => {
              if (!tab.hidden) {
                return (
                  <Tab
                    key={tab.name}
                    selected={tab.name === this.props.selected}
                    onClick={() => this.props.onSelectTab(tab.name)}
                    padding={this.props.tabPadding}
                    margin={this.props.tabMargin}
                    borderColor={this.props.borderColor}
                    fontSize={this.props.fontSize}
                  >
                    {tab.label || tab.name}
                  </Tab>
                );
              } else {
                return null;
              }
            })
          }
        </TabRow>
        <Content
          contentPadding={this.props.contentPadding}
          background={this.props.background}
        >
          {this.props.children[this.props.selectedIndex]}
        </Content>
      </Wrapper>
    );
  }
}

export default TabWrapper(Tabs);
