import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import TriviaRecordNoGames from 'components/trivia/trivia_record_no_games';
import TriviaRecordPieChart from 'components/trivia/trivia_record_pie_chart';
import ReactTooltip from 'react-tooltip';
import { flexCenterColumn } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  ${flexCenterColumn()}
`;

@observer
class TriviaRecord extends React.Component {

  render() {
    return (
      <Wrapper
        data-tip
        data-for='past-due-reviews'
      >
        {
          this.props.won === 0 && this.props.lost === 0 &&
            <TriviaRecordNoGames />
        }
        {
          (this.props.won > 0 || this.props.lost > 0) &&
            <TriviaRecordPieChart
              won={this.props.won}
              lost={this.props.lost}
            />
        }
        <ReactTooltip
          place='top'
          type='dark'
          id='past-due-reviews'
          class='custom-tooltip'
        >
          <div>
            {`${this.props.won} Games Won`}
          </div>
          <div>
            {`${this.props.lost} Games Lost`}
          </div>
          <div>
            {`${this.props.tied} Games Tied`}
          </div>
        </ReactTooltip>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TriviaRecord);
