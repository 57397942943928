import React from 'react';
import { observer } from 'mobx-react';
import { ReviewDetailsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { DeckUI , AppUI } from '@seedlang/state';
import Text from 'components/text';
import Button from 'components/button/button';
import { Constants , Theme } from '@seedlang/constants';
import Spinner from 'components/spinner';
import { isPresent } from '@seedlang/utils';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ProgressBar from 'components/progress_bar';
import { NeutralFaceIcon, SmileFaceIcon, BigSmileFaceIcon } from '@seedlang/icons';
import CardTypeSelect from 'components/review/card_type_select';
import Alert from 'components/alert';
import Modal from 'components/modal';
import { observable } from 'mobx';
import Paginator from 'components/paginator';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Module = styled.div`
  background: white;
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
`;

const InnerModule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.margin}
`;

const Table = styled.div`
  margin: ${props => props.margin};
  display: flex;
  flex-direction: column;
  margin: 10px;
  border-top: 1px solid #CACACA;
  border-right: 1px solid #CACACA;
  width: calc(100% - 20px);
  background: white;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Cell = styled.div`
  text-align: left;
  width: ${props => props.width};
  flex: ${props => props.flex};
  background: ${props => props.header ? '#616161' : '#FFF'};
  color: ${props => props.header ? '#FFF' : '#000'};
  border-bottom: 1px solid #CACACA;
  border-left: 1px solid #CACACA;
  padding: 5px;
  font-size: 14px;
`;

const Buttons = styled.div`
  margin-top: 10px;
`;

const ButtonWrapper = styled.div`
  max-width: ${props => props.inCard ? '100%' : '400px'};
`;

const Section = styled.div`
  width: 100%;
  padding: ${props => props.padding};
  margin: ${props => props.margin};
  background: white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  table {
    width: 100%;
    td {
      font-size: 13px;
      border-bottom: 1px solid #CACACA;
      border-left: 1px solid #CACACA;
      vertical-align: middle;
      padding: 5px;
    }
    th {
      font-size: 12px;
      background: #616161;
      color: #FFF;
    }
  }
`;

const CheckBoxWrapper = styled.div`
  margin-top: 10px;
  input {
    width: 20px;
  }
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  .icon {
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .neutral-face-icon {
    svg {
      fill: #656565;
    }
  }
  .smile-face-icon, .big-smile-face-icon {
    svg {
      fill: ${Theme.green};
    }
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
`;

const ICONS = {
  Hard: <NeutralFaceIcon />,
  Good: <SmileFaceIcon />,
  Easy: <BigSmileFaceIcon />,
};

@observer
class ReviewDetails extends React.Component {
  @observable showChangeCardTypeModal = false;

  render() {
    if (AppUI.loadingReviews) {
      return (
        <Spinner />
      );
    } else if (this.props.card) {
      return (
        <Wrapper
          inCard={!this.props.cardId}
        >
          {
            this.showChangeCardTypeModal &&
            <Modal
              onCloseModal={() => this.showChangeCardTypeModal = false}
              animate
              width={DeckUI.layout.deckWidth - 40}
              marginTop='200px'
              marginLeft='40px'
              title='Select a Card Type'
              gradient
            >
              <CardTypeSelect
                card={this.props.card}
                onChangeCardTypeId={this.props.onChangeCardTypeId}
              />
              {
                this.props.showUpdatedCardTypeMessage && this.props.card.cardTypeName &&
                <Alert
                  textAlign='center'
                  width='100%'
                  margin='10px 0 0 0'
                  background={Theme.green}
                >
                  {`Next time this card appears in a deck it will be a ${this.props.card.cardTypeName} card`}
                </Alert>
              }
            </Modal>
          }
          {
            DeckUI.user.admin && this.props.card && this.props.card.id &&
              <Module
                inCard={!this.props.cardId}
              >
                <InnerModule>
                  <Text
                    heading='3'
                    textAlign='center'
                    padding='10px 0 20px 0'
                  >
                    Interval Tester (Admin Only)
                  </Text>
                  <Buttons>
                    <Button
                      margin='0 5px'
                      onClick={() => this.props.processScore(Constants.BAD_SCORE, this.props.forceReviewAfterDue)}
                    >
                      1 Minute
                    </Button>
                    <Button
                      margin='0 5px'
                      onClick={() => this.props.processScore(Constants.GOOD_SCORE, this.props.forceReviewAfterDue)}
                    >
                      {this.props.card.formattedReviewInterval(Constants.GOOD_SCORE, this.props.forceReviewAfterDue)}
                    </Button>
                    <Button
                      margin='0 5px'
                      onClick={() => this.props.processScore(Constants.EASY_SCORE, this.props.forceReviewAfterDue)}
                    >
                      {this.props.card.formattedReviewInterval(Constants.EASY_SCORE, this.props.forceReviewAfterDue)}
                    </Button>
                  </Buttons>
                  <CheckBoxWrapper>
                    <InPlaceCheckbox
                      allowUpdate
                      onChange={val => this.props.forceReviewAfterDue = val}
                      value={this.props.forceReviewAfterDue}
                      display='flex'
                    >
                      Review is in Future
                    </InPlaceCheckbox>
                  </CheckBoxWrapper>
                </InnerModule>
              </Module>
          }
          <Module
            inCard={!this.props.cardId}
            id='review-chart-wrapper'
          >
            <InnerModule>
              <Text
                heading='3'
                textAlign='center'
                padding='10px 0 0 0'
              >
                Review Progress for
              </Text>
              {
                isPresent(this.props.card.frontText) &&
                  <Text
                    heading='2'
                    textAlign='center'
                    padding='5px 0 0 0'
                  >
                    {this.props.card.frontText}
                  </Text>
              }
              {
                this.props.card.translation &&
                  <Text
                    heading='2'
                    textAlign='center'
                    padding='5px 0 0 0'
                  >
                    {this.props.card.translation && this.props.card.translation.targetText}
                  </Text>
              }
              {
                this.props.card.cardType && this.props.card.frontText && this.props.card.backText && this.props.card.userSubmission && this.props.card.word && isPresent(this.props.card.word?.target?.videoClip) &&
                <Buttons>
                  <Button
                    fontSize='14px'
                    margin='0 5px'
                    disabled={this.props.card.cardTypeId === Constants.REVIEW_CARD_TYPES.find(item => item.slug === 'user_submission').id}
                    onClick={this.props.onSwitchBackToCustom}
                    background={Theme.green}
                  >
                    Switch back to custom card
                  </Button>
                </Buttons>
              }
              <Section
                padding={this.props.cardId ? null : '10px'}
                margin='10px 0 0 0'
              >
                <table
                  margin='20px 0 0 0'
                >
                  <thead>
                    <tr>
                      <th
                        width='90px'
                      >
                        Date
                      </th>
                      <th
                        width='90px'
                      >
                        Score
                      </th>
                      <th
                        width='90px'
                      >
                        Interval
                      </th>
                      <th
                        flex='1'
                      >
                        Progress
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      AppUI.reviewStore.indexData.filter(item => isPresent(item.repetitionInterval)).map(item => {
                        return (
                          <tr
                            key={item.id}
                          >
                            <td width='90px'>
                              <Center>
                                {item.createdAt.formattedDateWithYear}
                              </Center>
                            </td>
                            <td width='90px'>
                              <Center>
                                <IconWrapper>
                                  {ICONS[item.lastScoreText]}
                                </IconWrapper>
                                {item.lastScoreText}
                              </Center>
                            </td>
                            {
                              item.repetitionInterval > 0 &&
                                <td
                                  width='90px'
                                >
                                  <Center>
                                    {item.repetitionInterval} Day{item.repetitionInterval === 1 ? null : 's'}
                                  </Center>
                                </td>
                            }
                            {
                              item.repetitionInterval === 0 &&
                                <td
                                  width='90px'
                                >
                                  <Center>
                                    -
                                  </Center>
                                </td>
                            }
                            <td>
                                <ProgressBar
                                  borderRadius='10px'
                                  height='20px'
                                  background='#CCC'
                                  current={item.repetitionInterval}
                                  total={AppUI.user.daysUntilReviewRetired}
                                />
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </Section>
              {
                AppUI.reviewStore.hasIndexData && (AppUI.reviewStore.indexData.length >= AppUI.reviewStore.limit || AppUI.reviewStore.page !== 1) &&
                  <div style={{width: '100%'}}>
                    <Paginator
                      store={AppUI.reviewStore}
                      ids={{cardId: this.props.cardId}}
                    />
                  </div>
              }
            </InnerModule>
          </Module>
          <Module
            inCard={!this.props.cardId}
          >
            <InnerModule
              margin='0 0 30px 0'
            >
              <Text
                heading='3'
                textAlign='center'
                padding='10px 0 20px 0'
              >
                Review Details
              </Text>
              {
                !this.props.showSpinner &&
                  <Section
                    padding={this.props.cardId ? null : '10px 0'}
                    margin='0 0 20px 0'
                  >
                    <Table
                      inCard={!this.props.cardId}
                    >
                      <Row>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          Number of Reviews
                        </Cell>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          {this.props.card.viewsCount}
                        </Cell>
                      </Row>
                      <Row>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          Consecutive Positive
                        </Cell>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          {this.props.card.consecutiveGoodScores}
                        </Cell>
                      </Row>
                      <Row>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          Created
                        </Cell>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          {this.props.card?.createdAt?.formattedDateWithYearAndTime}
                        </Cell>
                      </Row>
                      <Row>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          Next Review
                        </Cell>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          {this.props.card.reviewAfter ? this.props.card.reviewAfter.formattedDateWithYearAndTime : '-'}
                        </Cell>
                      </Row>
                      <Row>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          Difficulty
                        </Cell>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          {this.props.card.difficultyLabel} ({this.props.card.difficultyScore} of 100)
                        </Cell>
                      </Row>
                      <Row>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          Retired
                        </Cell>
                        <Cell
                          flex='1'
                          inCard={!this.props.cardId}
                        >
                          {this.props.card.retired ? 'Yes' : 'No'}
                        </Cell>
                      </Row>
                    </Table>
                  </Section>
              }
              <ButtonWrapper
                inCard={!this.props.cardId}
              >
                {
                  this.props.card.cardType && this.props.hasEditableCardType &&
                    <Button
                      width='calc(100% - 20px)'
                      margin='0 0 10px 0'
                      onClick={() => this.showChangeCardTypeModal = true}
                    >
                      Change Card Type
                    </Button>
                }
                {
                  this.props.card.viewsCount > 0 &&
                    <Button
                      width='calc(100% - 20px)'
                      margin='0 0 10px 0'
                      onClick={this.props.onResetReview}
                    >
                      Reset Review
                    </Button>
                }
                {
                  this.props.card && !this.props.card.retired &&
                    <Button
                      width='calc(100% - 20px)'
                      margin='0 0 10px 0'
                      onClick={this.props.onRetireReview}
                    >
                      Retire Review
                    </Button>
                }
                <Button
                  width='calc(100% - 20px)'
                  margin='0 0 10px 0'
                  onClick={this.props.onSnoozeReview}
                >
                  Snooze Review
                </Button>
                <Button
                  width='calc(100% - 20px)'
                  margin='0 0 10px 0'
                  onClick={this.props.onDeleteReview}
                  background={Theme.red}
                  color='white'
                >
                  Delete Review
                </Button>
              </ButtonWrapper>
            </InnerModule>
          </Module>
        </Wrapper>
      );
    } else {
      return <span />;
    }
  }
}

export default ReviewDetailsWrapper(ReviewDetails);
