import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { isPresent, isBlank } from '@seedlang/utils';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import Alert from 'components/alert';
import Text from 'components/text';

const Wrapper = styled.div`

`;

const InputWrapper = styled.div`
  margin: 0 0 20px 0;
`;

@observer
class SettingsPassword extends React.Component {
  @observable respSuccess = false;
  @observable respFailure = false;
  @observable oldPassword = '';
  @observable newPassword1 = '';
  @observable newPassword2 = '';
  @observable message = '';

  @computed get disableSubmit() {
    return isBlank(this.oldPassword) || this.newPassword1 !== this.newPassword2 || this.newPassword1.length < 6;
  }

  onChangePassword() {
    AppUI.authUserStore.update({
      failureCallback: this.afterChangePassword,
      data: {
        old_password: this.oldPassword,
        password: this.newPassword1,
        password_confirmation: this.newPassword2,
      },
    }, this.afterChangePassword);
    this.reset();
  }

  @autobind afterChangePassword(resp) {
    if (resp.success) {
      this.respSuccess = true;
    } else {
      this.message = resp.userMessage;
      this.respFailure = true;
    }
  }

  @autobind reset() {
    this.oldPassword = '';
    this.newPassword1 = '';
    this.newPassword2 = '';
    this.respSuccess = false;
    this.respFailure = false;
    this.message = '';
  }

  render() {
    return (
      <Wrapper>
        <Text
          heading='3'
          margin='0 0 20px 0'
        >
          Change Password
        </Text>
        {
          this.respSuccess &&
            <Alert
              textAlign='center'
            >
              Password was changed.
            </Alert>
        }
        {
          this.respFailure &&
            <Alert
              textAlign='center'
            >
              {isPresent(this.message) ? this.message : 'There was a problem changing your password.'}
            </Alert>
        }

        <InputWrapper>
          <label className='form-label'>
            Old Password
          </label>
          <input
            ref='oldPassword'
            type='password'
            value={this.oldPassword}
            onChange={e => this.oldPassword = e.target.value}
          />
        </InputWrapper>

        <InputWrapper>
          <label className='form-label'>
            New Password
          </label>
          <input
            ref='newPassword1'
            type='password'
            value={this.newPassword1}
            onChange={e => this.newPassword1 = e.target.value}
          />
        </InputWrapper>

        <InputWrapper>
          <label className='form-label'>
            Confirm New Password
          </label>
          <input
            ref='newPassword2'
            type='password'
            value={this.newPassword2}
            onChange={e => this.newPassword2 = e.target.value}
          />
        </InputWrapper>

        <Button
          disabled={this.disableSubmit}
          onClick={() => this.onChangePassword()}
          background={AppUI.siteIsDefault ? null : AppUI.site.accentColor}
        >
          Change Password
        </Button>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsPassword);
