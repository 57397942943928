import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { CampaignStore , CampaignTypeStore , MembershipTypeStore, CampaignTypeFilteredUserStore, CampaignMessageStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import ReactTooltip from 'react-tooltip';
import { isBlank , isPresent } from '@seedlang/utils';
import Button from 'components/button/button';
import { without } from 'lodash';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import Alert from 'components/alert';
import CampaignMessageIndex from 'pages/builder/campaign_messages/campaign_message_index';
import InfoTooltip from 'components/info_tooltip';
import { Theme } from '@seedlang/constants';
import BackEndExplainer from 'pages/creator/back_end_explainer';

@observer
class CampaignEdit extends React.Component {
  @observable showMessage = false;
  @observable testSending = false;
  @observable viewAsHtml = true;
  @observable filteredUsersCount;
  @observable usersCount;

  constructor(props) {
    super(props);
    if (!CampaignTypeStore.hasShowData && this.props.params.campaignTypeId !== 'one-time') {
      CampaignTypeStore.getShow({ids: {campaignTypeId: this.props.params.campaignTypeId}});
    }
    this.getCampaign();
    if (!AppUI.siteIsDefault) {
      MembershipTypeStore.getIndex({});
    }
  }

  @computed get disableSubmit() {
    return (isBlank(CampaignStore.showData.membershipTypeIds) && !CampaignStore.showData.sendToNonMembers) || isBlank(CampaignStore.showData.subject) || isBlank(CampaignStore.showData.mailingBody);
  }

  @computed get pageName() {
    if (CampaignStore.showData.oneTimeSending) {
      return 'Send Email';
    }
    return AppUI.siteIsDefault ? 'Campaign Types' : 'Automated Emails';
  }

  @computed get showSendEmailButton() {
    return this.props.params.campaignTypeId === 'one-time';
  }

  @autobind onUpdateMembershipType(membershipTypeId) {
    let ary = CampaignStore.showData.membershipTypeIds.split(',');
    if (ary.indexOf(membershipTypeId) === -1) {
      ary.push(membershipTypeId);
    } else {
      ary = without(ary, membershipTypeId);
    }
    const membershipTypeIds = ary.join(',');
    CampaignStore.showData.set('membershipTypeIds', membershipTypeIds);
    CampaignStore.update({ids: {campaignId: this.props.params.campaignId}, data: {membershipTypeIds: membershipTypeIds}});
  }

  @autobind getCampaign() {
    CampaignStore.getShow({ids: {campaignId: this.props.params.campaignId}});
  }

  @autobind onSendCampaign(testSending) {
    CampaignStore.sendCampaign({data: {test_sending: testSending}, ids: {campaignId: this.props.params.campaignId}}, resp => this.afterSendCampaign(resp, testSending));
  }

  @autobind afterSendCampaign(resp, testSending) {
    this.showMessage = true;
    setTimeout(this.hideMessage, 3000);
    if (!testSending) {
      CampaignTypeFilteredUserStore.filteredUsersCount({ids: {campaignTypeId: CampaignStore.showData.campaignTypeId}}, this.afterGetFilteredUsersCount);
    }
    CampaignMessageStore.getIndex({filters: {campaign_id: this.props.params.campaignId}});
  }

  @autobind afterGetFilteredUsersCount(resp) {
    this.filteredUsersCount = resp['filteredUsersCount'];
    this.usersCount = resp['usersCount'];
  }

  @autobind hideMessage() {
    this.showMessage = false;
  }

  render() {
    if (CampaignStore.hasShowData) {
      return (
        <div className='campaign-edit'>
          {
            CampaignStore.showData.oneTimeSending &&
            <div className='breadcrumbs-wrapper'>
              <div className='breadcrumbs'>
                <Link
                  to={{name: 'creator.emails.index', params: {campaignTypeId: this.props.params.campaignTypeId, groupId: this.props.params.groupId}}}
                >
                  {this.pageName}
                </Link>
                <i className='fa fa-angle-double-right' />
                <div className='current'>
                  {CampaignStore.showData.name}
                </div>
              </div>
            </div>
          }
          {
            !CampaignStore.showData.oneTimeSending &&
            <div className='breadcrumbs-wrapper'>
              <div className='breadcrumbs'>
                <Link
                  to={{name: this.props.whiteLabeled ? 'creator.campaign_types.index' : 'builder.campaign_types.index', params: {campaignTypeId: this.props.params.campaignTypeId, groupId: this.props.params.groupId}}}
                >
                  {this.pageName}
                </Link>
                <i className='fa fa-angle-double-right' />
                <Link
                  to={{name: this.props.whiteLabeled ? 'creator.campaign_types.edit' : 'builder.campaign_types.edit', params: {campaignTypeId: this.props.params.campaignTypeId, groupId: this.props.params.groupId}}}
                >
                  {CampaignTypeStore.hasShowData && CampaignTypeStore.showData.name}
                </Link>
                <i className='fa fa-angle-double-right' />
                <div className='current'>
                  {CampaignStore.showData.name}
                </div>
              </div>
            </div>
          }
          {
            !AppUI.siteIsDefault &&
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Memberships <InfoTooltip>Users will only receive this email if they have one of the selected memberships.</InfoTooltip></legend>
                  {
                    MembershipTypeStore.indexData.map(item => {
                      return (
                        <div className='row' key={item.id}>
                          <InPlaceCheckbox
                            value={CampaignStore.showData.membershipTypeIds.indexOf(item.id) !== -1}
                            icon='check'
                            onChange={() => this.onUpdateMembershipType(item.id)}
                          >
                            {item.name}
                          </InPlaceCheckbox>
                        </div>
                      );
                    })
                  }
                  <div className='row'>
                    <InPlaceCheckbox
                      id={CampaignStore.showData.id}
                      value={CampaignStore.showData.sendToNonMembers}
                      afterChange={this.getCampaign}
                      model='campaign'
                      field='send_to_non_members'
                      icon='check'
                    >
                      Users with No Membership
                    </InPlaceCheckbox>
                  </div>
                </fieldset>
              </div>
            </div>
          }
          <div className='row'>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Name <InfoTooltip>This is not displayed in the email, it is only used to identify the campaign.</InfoTooltip></legend>
                <InPlaceText
                  id={CampaignStore.showData.id}
                  defaultValue={CampaignStore.showData.name}
                  model='campaigns'
                  field='name'
                />
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Settings</legend>
                {
                  !CampaignStore.showData.oneTimeSending &&
                  <div>
                    <InPlaceCheckbox
                      icon='check'
                      id={CampaignStore.showData.id}
                      value={CampaignStore.showData.current}
                      model='campaigns'
                      field='current'
                    >
                      Current
                    </InPlaceCheckbox>
                  </div>
                }
                {
                  !CampaignStore.showData.mobileNotification &&
                    <div>
                      <InPlaceCheckbox
                        icon='check'
                        id={CampaignStore.showData.id}
                        value={CampaignStore.showData.greetByName}
                        model='campaigns'
                        field='greet_by_name'
                      >
                        {`Greet by Name: ${CampaignStore.showData.greeting(AppUI.user)}`}
                        <InfoTooltip><div>You can enter a customized greeting below.</div><div>Use %name% as a placeholder and check the preview above.</div></InfoTooltip>
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  !CampaignStore.showData.mobileNotification &&
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.customGreeting}
                      model='campaigns'
                      field='custom_greeting'
                      placeholder='Custom greeting'
                      afterChange={this.getCampaign}
                      show
                    />
                }
                {
                  !AppUI.siteIsDefault &&
                    <div>
                      <InPlaceCheckbox
                        icon='check'
                        id={CampaignStore.showData.id}
                        value={CampaignStore.showData.includeTeamLogo}
                        model='campaigns'
                        field='include_team_logo'
                      >
                        Include Team Logo
                        <InfoTooltip>If enabled, the team logo will be included at the top of the email.</InfoTooltip>
                      </InPlaceCheckbox>
                      {
                        isBlank(AppUI.site.teamLogo) &&
                          <div style={{fontSize: '12px', lineHeight: '14px'}}><i className='fa fa-warning' />The team logo needs to be added to the Site Settings page</div>
                      }
                    </div>
                }
              </fieldset>
            </div>
            {
              AppUI.siteIsDefault && !CampaignStore.showData.mobileNotification && !CampaignStore.showData.oneTimeSending &&
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>User Field <InfoTooltip>If enabled, the user field will be included in the email.</InfoTooltip></legend>
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      value={CampaignStore.showData.userField}
                      model='campaigns'
                      field='user_field'
                    />
                  </fieldset>
                </div>
            }
          </div>
          {
            !CampaignStore.showData.mobileNotification &&
            <>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Email Subject</legend>
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.subject}
                      model='campaigns'
                      field='subject'
                    />
                  </fieldset>
                </div>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Title <InfoTooltip>Displayed in the body of the email, as a header</InfoTooltip></legend>
                      <InPlaceText
                        id={CampaignStore.showData.id}
                        defaultValue={CampaignStore.showData.title}
                        model='campaigns'
                        field='title'
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Button Text <InfoTooltip>Text displayed on the button</InfoTooltip></legend>
                      <InPlaceText
                        id={CampaignStore.showData.id}
                        defaultValue={CampaignStore.showData.buttonText}
                        model='campaigns'
                        field='button_text'
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Button URL <InfoTooltip>Clicking on the button will take the user to this URL</InfoTooltip></legend>
                      <InPlaceText
                        id={CampaignStore.showData.id}
                        defaultValue={CampaignStore.showData.buttonUrl}
                        model='campaigns'
                        field='button_url'
                      />
                    </fieldset>
                  </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Email Image</legend>
                    <InPlaceImageUpload
                      image={CampaignStore.showData.image}
                      model='images'
                      onChange={this.getCampaign}
                      querystring={{
                        imageable_id: CampaignStore.showData.id,
                        imageable_type: 'Campaign',
                      }}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Body <InfoTooltip>You can use %name% as a placeholder for the user's name here too.</InfoTooltip></legend>
                    <InPlaceText
                      richText={this.viewAsHtml}
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.mailingBody}
                      model='campaigns'
                      field='mailing_body'
                      inputType='textarea'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12' style={{display: 'flex', alignItems: 'center'}}>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      {
                        this.showSendEmailButton &&
                          <Button
                          margin='10px 10px 0 0'
                          disabled={this.disableSubmit}
                          onClick={() => this.onSendCampaign(false)}
                          data-tip
                          data-for='send-email'
                          >
                          <span>Send Email</span>
                          {
                            this.disableSubmit &&
                              <ReactTooltip
                                place='top'
                                type='dark'
                                effect='solid'
                                id='send-email'
                                class='custom-tooltip'
                              >
                                You need to select memberships, have an email subject and body
                              </ReactTooltip>
                            }
                          </Button>
                      }
                      <Button
                        margin='10px 0 0 0'
                        onClick={() => this.onSendCampaign(true)}
                        background={Theme.gray}
                      >
                        Send Test Email
                      </Button>
                    </div>
                    <BackEndExplainer>
                      {`Test emails will be sent to ${AppUI.user.email} and any BCC addresses if they exist.`}
                    </BackEndExplainer>
                  </div>
                </div>
                {
                  isPresent(this.filteredUsersCount) && isPresent(this.usersCount) &&
                    <Alert
                      margin='10px 0 0 0'
                    >
                      {`Sending to ${this.filteredUsersCount} users.`}
                    </Alert>
                }
              </div>
              {
                this.showMessage &&
                  <div className='alert'>
                    An email has been sent.
                  </div>
              }
            </>
          }
          {
            CampaignStore.showData.mobileNotification &&
            <>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Title (DE)</legend>
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.titleDe}
                      model='campaigns'
                      field='title_de'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Title (ES)</legend>
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.titleEs}
                      model='campaigns'
                      field='title_es'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Title (FR)</legend>
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.titleFr}
                      model='campaigns'
                      field='title_fr'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Body (DE)</legend>
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.notificationBodyDe}
                      model='campaigns'
                      field='notification_body_de'
                      inputType='textarea'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Body (ES)</legend>
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.notificationBodyEs}
                      model='campaigns'
                      field='notification_body_es'
                      inputType='textarea'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Body (FR)</legend>
                    <InPlaceText
                      id={CampaignStore.showData.id}
                      defaultValue={CampaignStore.showData.notificationBodyFr}
                      model='campaigns'
                      field='notification_body_fr'
                      inputType='textarea'
                    />
                  </fieldset>
                </div>
              </div>
            </>
          }
          <div className='row' style={{marginTop: '10px'}}>
            <div className='col-xs-12'>
              <fieldset>
                <legend>{`Campaign Messages Sent (${CampaignStore.showData.clickedCount} out of ${CampaignStore.showData.campaignMessagesCount} clicked)`}<InfoTooltip>This shows information about each individual email that has been sent out to users from this campaign.</InfoTooltip></legend>
                <CampaignMessageIndex
                  whiteLabel
                  groupId={this.props.params.groupId}
                  campaignId={this.props.params.campaignId}
                  siteId={AppUI.siteId}
                  limit={5}
                />
              </fieldset>
            </div>
          </div>
        </div>
      );
    }
    return <span />;
  }
}

export default ErrorBoundary(CampaignEdit);
