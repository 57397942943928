import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { CampaignTypeFilterStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import InPlaceSelect from 'components/form/in_place_select';
import { Constants } from '@seedlang/constants';

@observer
class CampaignTypeFilterCreate extends React.Component {
  @observable field;
  @observable operator;

  onClick() {
    if (isPresent(this.field) && isPresent(this.operator) && isPresent(this.refs.valueField.value)) {
      CampaignTypeFilterStore.create(
        {
          data: {
            value: this.refs.valueField.value,
            field: this.field,
            operator: this.operator,
            campaign_type_id: this.props.campaignType.id,
          },
        },
        this.props.onChange,
      );
      this.refs.valueField.value = '';
      this.field = null;
      this.operator = null;
    }
  }

  render() {
    return (
      <div className='mailing-type-create create-row row'>
        <div className='col-xs-3'>
          <InPlaceSelect
            includeBlank
            placeholder='Add a Field'
            value={this.field}
            options={Constants.MAILING_TYPE_FILTER_FIELDS.sort()}
            onChange={val => this.field = val}
          />
        </div>
        <div className='col-xs-3'>
          <InPlaceSelect
            includeBlank
            placeholder='Add an Operator'
            value={this.operator}
            options={['>=', '>', '=', '!=', '<', '<=', 'IS', 'IS NOT']}
            onChange={val => this.operator = val}
          />
        </div>
        <div className='col-xs-3'>
          <input
            placeholder='Add a value'
            ref='valueField'
          />
        </div>
        <div className='col-xs-3'>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default CampaignTypeFilterCreate;
