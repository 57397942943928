import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { LanguageStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import { LanguageIcon } from '@seedlang/icons';
import { Link } from 'react-router';
import Filter from 'components/backend_filter/filter';
import Modal from 'components/modal';
import { observable } from 'mobx';
import AiServicesPriorityOrder from './ai_services_priority_order';
import autobind from 'autobind-decorator';
import { isPresent } from '@seedlang/utils';
import CheckIfTrue from 'components/check_if_true';

const Wrapper = styled.div`

`;

@observer
class LanguageIndex extends React.Component {
  @observable aryIndex;
  @observable translationType;

  componentDidMount() {
    this.getLanguages();
  }

  @autobind getLanguages() {
    LanguageStore.getIndex();
  }

  @autobind resetFields() {
    this.aryIndex = null;
    this.translationType = null;
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.aryIndex) &&
            <Modal
              onCloseModal={this.resetFields}
            >
              <AiServicesPriorityOrder
                language={LanguageStore.indexData[this.aryIndex]}
                afterChange={this.getLanguages}
                translationType={this.translationType}
              />
            </Modal>
        }
        <Filter
          store={LanguageStore}
          defaultSort='name'
          namespace='language-index'
          filters={
            [
              {
                type: 'text',
                name: 'name',
                label: 'name',
                placeholder: 'Name',
                default: '',
              },
              {
                type: 'boolean',
                name: 'translate_with_ai',
                label: 'Translate with AI',
              },
              {
                type: 'boolean',
                name: 'has_lessons',
                label: 'is Seedlang language',
              },
            ]
          }
        />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th />
              <th />
              <th
                className={cx('sortable', this.props.columnClassNames('id'))}
                onClick={() => this.props.onSortColumn('id')}
              >
                ID
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('name'))}
                onClick={() => this.props.onSortColumn('name')}
              >
                Name
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('users_count'))}
                onClick={() => this.props.onSortColumn('users_count')}
              >
                Users
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('paid_users_count'))}
                onClick={() => this.props.onSortColumn('paid_users_count')}
              >
                Pros
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('translate_with_ai'))}
                onClick={() => this.props.onSortColumn('translate_with_ai')}
              >
                AI?
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('sources_count'))}
                onClick={() => this.props.onSortColumn('sources_count')}
              >
                Sources
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('translate_ai_words_count'))}
                onClick={() => this.props.onSortColumn('translate_ai_words_count')}
              >
                # AI Word Translations
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('translate_ai_sentences_count'))}
                onClick={() => this.props.onSortColumn('translate_ai_sentences_count')}
              >
                # AI Sentence Translations
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('flag_count_words'))}
                onClick={() => this.props.onSortColumn('flag_word_count')}
              >
                # Flagged Words
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('flag_count_sentences'))}
                onClick={() => this.props.onSortColumn('flag_sentence_count')}
              >
                # Flagged Sentences
              </th>
            </tr>
          </thead>
          <tbody>
            {
              LanguageStore.indexData.map((item, index) => {
                return (
                  <tr
                    key={`${item.id}-${item.updatedAt}`}
                  >
                    <td>
                      <Link
                        to={{ name: 'builder.languages.edit', params: { languageId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <LanguageIcon
                        languageId={item.id}
                      />
                    </td>
                    <td>{item.id}</td>
                    <td>{item.name}</td>
                    <td>{item.usersCount}</td>
                    <td>{item.paidUsersCount}</td>
                    <td>
                      <CheckIfTrue
                        value={item.translateWithAi}
                      />
                    </td>
                    <td>{item.sourcesCount}</td>
                    <td>{item.translateAiWordsCount}</td>
                    <td>{item.translateAiSentencesCount}</td>
                    <td>{item.flagWordCount}</td>
                    <td>{item.flagSentenceCount}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={LanguageStore}
        />
      </Wrapper>
    );
  }
}

export default SortableColumns(LanguageIndex, LanguageStore, { sortColumn: 'name', sortIsAscending: true });