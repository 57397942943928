import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Motion, spring, presets } from 'react-motion';
import { Theme } from '@seedlang/constants';
import UserProfileImage from 'components/user/user_profile_image';
import Ribbon from 'components/svg/ribbon';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  position: relative;
  margin: 10px;
  color: white;
  transform: ${props => `scale(${props.scale})`};
  z-index: 999;
`;

const Name = styled.div`
  margin-bottom: 5px;
  max-width: 90px;
  overflow: hidden;
`;

const RibbonWrapper = styled.div`
  margin-top: -20px;
  color: white;
  position: relative;
  svg {
    fill: ${props => props.fill};
    width: 70px;
  }
`;

const Score = styled.div`
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 0;
  right: 0;
  margin: 0 auto;
  font-weight: bold;
`;

@observer
class PlayerRibbon extends React.Component {
  @observable scale = 1;

  @computed get color() {
    return this.props.won ? Theme.green : Theme.red;
  }

  componentDidMount() {
    if (this.props.won && this.props.animate) {
      this.setDelayedAnimation();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.won && this.props.animate && !prevProps.animate) {
      this.setDelayedAnimation();
    }
  }

  @autobind setDelayedAnimation() {
    setTimeout(this.animate, 250);
  }

  @autobind animate() {
    this.scale = 1.3;
  }

  render() {
    return (
      <Motion
        style={{
          scale: spring(this.scale, presets.wobbly),
        }}
      >
        {
          ({scale}) =>
            <Wrapper
              scale={scale}
            >
              <Name>
                {this.props.user?.firstName}
              </Name>
              <UserProfileImage
                hideProStatus
                width={100}
                user={this.props.user}
                border={`5px solid ${this.color}`}
                zIndex={3}
              />
              <RibbonWrapper
                fill={this.color}
              >
                <Score>
                  {this.props.points}
                </Score>
                <Ribbon />
              </RibbonWrapper>
            </Wrapper>
        }
      </Motion>
    );
  }
}

export default ErrorBoundary(PlayerRibbon);
