import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import Spinner from 'components/spinner';
import { isBlank, isValidEmail } from '@seedlang/utils';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import Alert from 'components/alert';
import InPlaceTextInput from 'components/form/in_place_text_input';
import Button from 'components/button/button';
import Text from 'components/text';
import { isPresent } from '@seedlang/utils/src';

const Wrapper = styled.div`
  .spinner > div {
    background-color: ${Theme.blue};
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
`;

const ContactCategory = styled.div`
  select {
    font-size: 16px;
  }
`;

@observer
class ContactForm extends React.Component {
  @observable message = '';
  @observable email = null;
  @observable submitting = false;
  @observable showMessage = false;
  @observable contactCategoryId;

  constructor(props) {
    super(props);
    AppUI.contactCategoryStore.getIndex();
  }

  @computed get disabled() {
    if (AppUI.user.guest) {
      return !isValidEmail(this.email) || isBlank(this.message);
    } else if (!AppUI.siteIsDefault && AppUI.contactCategoryStore.indexData?.length > 0) {
      return isBlank(this.message) || !this.contactCategoryId;
     } else {
      return isBlank(this.message);
    }
  }

  @autobind onSubmit() {
    this.submitting = true;
    AppUI.contactMessageStore.create({
      data: {
        siteId: AppUI.user?.site?.id,
        message: this.message,
        email: this.email,
        webVersion: AppUI.user?.site?.currentWebVersion,
        contactCategoryId: this.contactCategoryId === 'other' ? AppUI.user?.site?.supportEmailAddress : this.contactCategoryId,
      },
    }, this.afterSubmitMessage);
  }

  @autobind afterSubmitMessage() {
    this.message = '';
    this.email = '';
    this.contactCategoryId = null;
    if (isPresent(this.refs.select)) {
      this.refs.select.value = '';
    }
    this.submitting = false;
    this.showMessage = true;
  }

  @autobind onReset() {
    this.message = '';
    this.email = '';
    this.contactCategoryId = null;
    this.refs.select.value = '';
    this.submitting = false;
    this.showMessage = false;
  }

  @autobind onSelectContactCategoryId() {
    this.contactCategoryId = this.refs.select.value;
  }

  @computed get selectedContactCategory() {
    return AppUI.contactCategoryStore.hasIndexData && AppUI.contactCategoryStore.indexData.find(item => item.id === this.contactCategoryId);
  }

  render() {
    return (
      <Wrapper>
        {
          AppUI.contactMessageStore.hasOutstandingRequests &&
            <Spinner />
        }
        {
          this.showMessage &&
            <Alert
              textAlign='center'
              margin='20px 0 0 0'
              background={Theme.green}
            >
              Your message was successfully sent.
            </Alert>
        }
        {
          (!AppUI.userIsSignedIn || AppUI.user.guest) &&
            <Text
              padding='10px 0'
              textAlign='left'
              bold
            >
              Email
            </Text>
        }
        {
          (!AppUI.userIsSignedIn || AppUI.user.guest) &&
            <InPlaceTextInput
              value={this.email}
              onChange={val => this.email = val}
            />
        }
        {
          !AppUI.siteIsDefault && AppUI.contactCategoryStore.indexData?.length > 0 &&
            <ContactCategory>
              <Text
                padding='10px 0'
                textAlign='left'
                bold
              >
                What can we help you with?
              </Text>
              <select
                ref='select'
                onChange={this.onSelectContactCategoryId}
                defaultValue=''
              >
                <option value='' disabled selected>Select a category</option>
                {
                  AppUI.contactCategoryStore.indexData.map(item =>
                    <option value={item.id} key={item.id}>{item.name}</option>,
                  )
                }
                <option value='other'>Other</option>
              </select>
            </ContactCategory>
        }
        {
          !AppUI.siteIsDefault && this.selectedContactCategory?.email?.endsWith('seedlang.com') &&
            <Alert margin='10px 0 0 0' background={AppUI.site.accentColor}>This message will be sent to the team of our learning platform, Seedlang.</Alert>
        }
        <Text
          padding='10px 0'
          textAlign='left'
          bold
        >
          Message
        </Text>
        <InPlaceTextInput
          value={this.message}
          inputType='textarea'
          onChange={val => this.message = val}
          height='140px'
          expandedHeight='140px'
        />
        <ButtonRow>
          <Button
            background={Theme.green}
            color='#FFF'
            width={130}
            disabled={this.disabled}
            onClick={this.onSubmit}
            margin='0 10px 0 0'
          >
            Send
          </Button>
          <Button
            background={Theme.red}
            color='#FFF'
            text='Reset'
            width={130}
            marginLeft={10}
            disabled={this.disabled}
            onClick={this.onReset}
          >
            Reset
          </Button>
        </ButtonRow>
      </Wrapper>
    );
  }
}

export default ContactForm;
