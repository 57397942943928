import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { flexCenter } from '@seedlang/style_mixins';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Description from 'components/exercise/description';
import YoutubeVideo from 'components/youtube_video';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  margin-bottom: 10px;
  img {
    max-width: 100%;
    max-height: 500px;
  }
`;

const Button = styled.div`
  ${flexCenter()}
  background: rgb(134, 134, 134);
  font-weight: bold;
  height: 40px;
  color: white;
  font-size: 14px;
  border-radius: 10px;
  margin-top: 10px;
  cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
  padding: 5px;
`;

const VideoWrapper = styled.div`
  width: 100%;
  ${flexCenter()}
  padding-bottom: 20px;
`;

@observer
class ExpandableDescription extends React.Component {
  @observable selectedExpanded = false;

  componentDidUpdate(prevProps) {
    if (this.props.exerciseId !== prevProps.exerciseId) {
      this.selectedExpanded = false;
    }
  }

  @computed get expanded() {
    return this.selectedExpanded || isPresent(this.props.descriptionVideo);
  }

  render() {
    return (
      <Wrapper>
        <Description
          padding={this.expanded ? '10px 10px 0 10px' : '10px'}
          borderRadius={this.expanded ? '10px 10px 0 0' : '10px'}
        >
          <div
            dangerouslySetInnerHTML={{ __html: this.props.defaultDescription }}
          />
        </Description>
        {
          this.props.expandedDescription && !this.expanded &&
            <Button
              onClick={() => !this.props.blockExpansion ? this.selectedExpanded = true : null}
              disabled={this.props.blockExpansion}
            >
              <i className='fa fa-plus' style={{marginRight: '5px'}}/>
              READ MORE
            </Button>
        }
        {
          this.expanded &&
            <Description
              padding='20px 10px 10px 10px'
              borderRadius='0 0 10px 10px '
            >
              <div
                dangerouslySetInnerHTML={{ __html: this.props.expandedDescription }}
              />
            </Description>
        }
        {
          this.props.descriptionVideo &&
            <VideoWrapper>
              <YoutubeVideo
                url={this.props.descriptionVideo}
                show
              />
            </VideoWrapper>
        }
        {
          this.props.expandedDescription && this.expanded && !this.props.hideCollapseButton &&
          <Button
            onClick={() => this.selectedExpanded = false}
          >
            <i className='fa fa-minus' style={{marginRight: '5px'}} />
            COLLAPSE
          </Button>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExpandableDescription);
