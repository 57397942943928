import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import HeaderBar from 'components/header_bar';
import { GraphOutlineIcon, FaqIcon } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { Theme } from '@seedlang/constants';
import { Link} from 'react-router';

const Wrapper = styled.div`

`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding};
`;

@observer
class Stories extends React.Component {

  constructor(props) {
    super(props);
    AppUI.createEvent('stories - view page');
  }

  render() {
    return (
      <Wrapper>
        {
          !AppUI.user.isMember &&
            <PageOnboarding
              margin={AppUI.layout.isMobile ? '0' : '0 0 10px 0'}
              hideCloseIcon
              className='tree-onboarding'
              background={Theme.orange}
              color='#FFF'
              flat
              fontSize={AppUI.layout.isMobile ? 13 : 15}
              borderRadius={AppUI.layout.isMobile ? 0 : 5}
            >
              {
                AppUI.layout.isMobile &&
                  <Link
                    to={{name: 'memberships.index'}}
                    className='underline'
                  >
                    Go Pro to unlock the entire <b>Stories</b> section.
                  </Link>
              }
              {
                !AppUI.layout.isMobile &&
                  <span>
                    <span>Unlocking the entire <b>Stories</b> section is a </span>
                    <Link
                      to={{name: 'memberships.index'}}
                      className='underline'
                    >
                      Seedlang Pro
                    </Link>
                    <span> feature.</span>
                  </span>
              }
            </PageOnboarding>
        }
        <HeaderBar
          title={AppUI.routeStore.routeName === 'stories.show' ? `VIEW ALL ${AppUI.targetLanguageName} STORIES` : `${AppUI.targetLanguageName} STORIES`}
          titleRoute='stories.home'
          pageInfo={[
            {
              route: 'stories.progress',
              current: 'Progress',
            },
          ]}
          icons={[
            {
              icon: <FaqIcon />,
              tooltipText: 'Help Center',
              iconRoute: 'faq_categories.show',
              iconParams: {faqCategoryId: 'e548f1aa-5193-432b-bff4-b9342ea1434c'},
            },
            {
              icon: <GraphOutlineIcon />,
              tooltipText: 'View Progress',
              iconRoute: 'stories.progress',
              breadcrumb: 'Progress',
            },
          ]}
        />
        <Content>
          {this.props.children}
        </Content>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Stories);
