import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { LanguageIcon , FlagTranslationIcon } from '@seedlang/icons';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import { SourceItemWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  background: whitesmoke;
  margin: 10px 0;
`;

const Source = styled.div`
  flex: 1;
`;

const Row = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

const FlagRow = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  background: ${props => props.background};
  color: ${props => props.color};  
  padding: 10px;
  border-radius: 5px;
  width: 100%;
`;

const Text = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 10px;
  text-align: left;
  flex: 1;
`;

@observer
class SourceItem extends React.Component {

  render() {
    return (
      <Wrapper>
        <Row>
          <LanguageIcon
            languageId={this.props.word.source && this.props.word.source?.languageId}
            margin='0 10px 0 0'
          />
          <Source>
            {this.props.sourceText}
          </Source>
          <FlagTranslationIcon
            onClick={this.props.toggleExpanded}
          />
        </Row>
        {
          this.props.showMessage &&
            <FlagRow
              background={Theme.green}
              color='#FFF'
            >
              {this.props.successMessage}
            </FlagRow>
        }
        {
          this.props.expanded &&
            <FlagRow
              background='#CCC'
              color='#000'
            >
              <Text>
                {this.props.flagText}
              </Text>
              <Button
                background={Theme.green}
                onClick={() => this.props.flagTranslation(this.props.word, this.props.afterSubmission)}
                margin='0 5px 0 0'
                height='30px'
                borderRadius='10px'
              >
                Yes
              </Button>
              <Button
                background={Theme.red}
                onClick={this.props.toggleExpanded}
                height='30px'
                borderRadius='10px'
              >
                No
              </Button>
            </FlagRow>
        }
    </Wrapper>
    );
  }
}

export default SourceItemWrapper(SourceItem);
