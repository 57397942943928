import React from 'react';
import { observer } from 'mobx-react';
import { LevelStore } from '@seedlang/stores';
import { Link } from 'react-router';

@observer
class InterjectionIndex extends React.Component {

  render() {
    return (
      <div className='interjection-index'>
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th width='20' />
              <th>Name</th>
              <th>Abb</th>
            </tr>
          </thead>
          <tbody>
            {
              LevelStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.levels.edit', params: { levelId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.abbreviation}
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default InterjectionIndex;
