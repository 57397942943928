"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = stripHtmlTags;
function stripHtmlTags(html) {
  var tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
}