import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  background: ${props => props.background};
  padding: ${props => props.padding};
  color: ${props => props.color};
  border-radius: ${props => props.borderRadius};
  border: 1px solid ${props => props.selectedBackground};
  font-size: ${props => props.fontSize};
  margin: ${props => props.margin};
  height: ${props => props.height};
  width: ${props => props.width};
  font-weight: ${props => props.fontWeight};
  cursor: pointer;
  display: inline-block;
  line-height: 20px;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .chat-icon {
    width: 18px;
    margin: 2px 4px 0 0;
    height: 18px;
  }
  .cards-icon {
    width: 21px;
    margin: 2px 2px 0 0;
    height: 18px;
  }
  .feed-icon {
    margin: 0 4px 0 0;
  }

  &:hover {
    background: ${props => props.selectedBackground};
    border: 1px solid ${props => props.background};
    color: ${props => props.background};
  }
`;

@observer
class OutlineButton extends React.Component {

  static defaultProps = {
    background: '#FFF',
    selectedBackground: Theme.green,
    borderRadius: '40px',
    padding: '9px 20px',
    fontSize: '17px',
  }

  render() {
    return (
      <Wrapper
        background={this.props.selected ? this.props.selectedBackground : this.props.background}
        color={this.props.selected ? this.props.background : this.props.selectedBackground}
        selectedBackground={this.props.selected ? this.props.background : this.props.selectedBackground}
        onClick={this.props.onClick}
        borderRadius={this.props.borderRadius}
        padding={this.props.padding}
        fontSize={this.props.fontSize}
        margin={this.props.margin}
        height={this.props.height}
        width={this.props.width}
        fontWeight={this.props.fontWeight}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(OutlineButton);
