import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import UserProfileImage from 'components/user/user_profile_image';
import { chunk } from 'lodash';
import { Theme } from '@seedlang/constants';

const REVIEWS = [
 {user:
   {name: 'Peter',
    imageUrl: 'https://graph.facebook.com/v2.10/10205666264573875/picture?type=large'},
  comment:
   'I must say that you really have done what I don\'t believe anyone else has... built a really immersive platform, which is fun and reinforces the learning of the user in multiple ways at once... listening, seeing the movement of the mouth, and with the type of interaction that a user can have with a sentence... to help learn the grammar placement and meanings of any word in that sentence... is really second to none!'},
 {user:{name: 'Archana', imageUrl: null},
  comment:
   'This was a unique experience.\nIt feels great to follow the native speakers rather than a automated voice.'},
 {user:
   {name: 'María del Coral',
    imageUrl: 'https://graph.facebook.com/v2.10/10156792113858530/picture?type=large'},
  comment:
   'I love how it makes me practice speaking by listening to myself and repeating. I feel I am improving my listening and speaking skills a lot. The design is clean, friendly and fast.'},
 {user:{name: 'Martin', imageUrl: null},
  comment:
   'I love this program. I\'ve been doing Duolingo for over 600 straight days. I\'ve learned a lot, but this seems like a step up. I really don\'t have time right now to find and work with a language partner, but this seems like it might be the next best thing. '},
 {user:
   {name: 'Leslee',
    imageUrl:
     'https://i.imgur.com/FjwmC8C.jpg'},
  comment:
   'There is something about the German culture that can feel very impenetrable to a non-native. Injecting some humor into these lessons makes learning the language, and by extension the culture, more approachable. And I think that shift in attitude can really affect a student\'s overall capacity to learn and absorb.'},
 {user:
   {name: 'Kelsey',
    imageUrl:
     'https://i.imgur.com/Vjyiesb.jpg'},
  comment:
   'You guys are awesome! I\'ve been trying to self-learn German for 3 years, and this is just the app I need. My biggest areas for improvement are listening comprehension, and most certainly, speaking German live. After just a few days of using this app, I\'ve felt my brain being exercised in ways that no other app has been able to achieve. In addition, your personalities in the videos are great, and the content is amusing, making me want to come back for more. Thank you so much!'},
 {user:
   {name: 'Gabriel Vera Molina',
    imageUrl: 'https://graph.facebook.com/v2.10/10210404793950741/picture?type=large'},
  comment:
   'A very interactive way of learning vocabulary and short sentences. The fact that you emphasise on speaking skills is important, since it is one of the features of language learning that\'s least taken into account '},
  ];

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 800px;
  padding: 0 40px;
  margin: ${props => props.margin};
`;

const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const BlockWrapper = styled.div`
`;

const Block = styled.div`
  padding: 15px;
  margin: 10px;
  background: ${props => props.background || '#FFF'};
  border-radius: 20px;
  overflow-y: auto;
`;

@observer
class PromotedRatings extends React.Component {

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        {
          chunk(REVIEWS, AppUI.layout.isMobile ? REVIEWS.length : Math.ceil(REVIEWS.length / 2)).map((chunkedRatings, index) => {
            return (
              <Column
                key={index}
              >
                {
                  chunkedRatings.map((item, index) => {
                    return (
                      <BlockWrapper
                        key={index}
                      >
                        <Block
                          background={this.props.blockBackground}
                        >
                          <Row>
                            <UserProfileImage
                              user={item.user}
                              margin='0 5px 0 0'
                            />
                            <Text
                              bold
                              fontSize='15px'
                              lineHeight='22px'
                              margin='0 0 0 5px'
                              color={Theme.darkBlue}
                            >
                              {item.user.name.split(' ')[0]}
                            </Text>
                          </Row>
                          <Text
                            fontSize='15px'
                            lineHeight='22px'
                          >
                            {item.comment}
                          </Text>
                        </Block>
                      </BlockWrapper>
                    );
                  })
                }
              </Column>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PromotedRatings);
