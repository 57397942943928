import React from 'react';
import { observer } from 'mobx-react';
import { CardTypeSelectWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import InPlaceSelect from 'components/form/in_place_select';
import Text from 'components/text';
import { isPresent, isBlank } from '@seedlang/utils';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import MenuList from 'components/review/menu_list';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

@observer
class CardTypeSelect extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.compact && isPresent(this.props.cardTypeOptions) && isPresent(this.props.card) &&
            <InPlaceSelect
              allowUpdate
              options={this.props.cardTypeOptions}
              value={this.props.card.cardTypeId}
              model='cards'
              field='card_type_id'
              id={this.props.card.id}
              afterChange={this.props.afterChange}
            />
        }
        {
          this.props.compact && isPresent(this.props.cardTypeOptions) && isPresent(this.props.cards) &&
            <MenuList
              options={this.props.cardTypeOptions}
              onClick={this.props.onChangeMultipleCards}
              selectedId={this.props.cardTypeId}
            />
        }
        {
          !this.props.compact && this.props.card && this.props.cardTypeOptions.map(item => {
            return (
              <Button
                borderRadius='5px'
                background={isPresent(this.props.cardTypeId) && item[0] === this.props.cardTypeId ? Theme.darkestBlue : '#ECECEC'}
                color={isPresent(this.props.cardTypeId) && item[0] === this.props.cardTypeId ? '#FFF' : '#333'}
                key={item[0]}
                onClick={() => this.props.updateCardType(item)}
              >
                {item[1]}
              </Button>
            );
          })
        }
        {
          isBlank(this.props.cardTypeOptions) && this.props.cardType &&
            <Text>
              {this.props.cardType?.name}
            </Text>
        }
        {
          this.props.compact && !isPresent(this.props.cardTypeOptions) && isPresent(this.props.cards) && isBlank(this.props.cardType) &&
            <Text fontSize='12px' color='#929292' textAlign='center'>The selected reviews are incompatible</Text>
        }
      </Wrapper>
    );
  }
}

export default CardTypeSelectWrapper(CardTypeSelect);
