import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { CloseIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  text-align: ${props => props.textAlign};
  border-radius: ${props => props.borderRadius};
  width: ${props => props.width};
  border: ${props => props.border};
  margin: ${props => props.margin};
  padding: ${props => props.padding};
  background: ${props => props.state ? Theme.colorForState[props.state] : props.background};
  color: ${props => props.color};
  cursor: ${props => props.cursor};
  position: relative;
  a {
    color: ${props => props.color};
    text-decoration: underline;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

@observer
class Alert extends React.Component {

  static defaultProps = {
    fontSize: '14px',
    lineHeight: '18px',
    background: Theme.orange,
    margin: '0 0 10px 0',
    textAlign: 'left',
    padding: '10px',
    color: 'white',
    borderRadius: '0',
  }

  render() {
    return (
      <Wrapper
        {...this.props}
        style={this.props.style}
      >
        {
          Boolean(this.props.showCloseButton) &&
            <IconWrapper>
              <CloseIcon
                width='15px'
                height='15px'
                onClick={this.props.onClose}
                fill={this.props.color}
              />
            </IconWrapper>
        }
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Alert);
