import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { DashboardStore } from '@seedlang/stores';
import DashboardCount from 'pages/builder/dashboard/dashboard_count';
import autobind from 'autobind-decorator';
import LineChartWithControls from 'components/visualization/line_chart_with_controls';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

@observer
class ExercisesDashboard extends React.Component {
  @observable counts = {};
  @observable chartSpecs = null;

  componentDidMount() {
    this.loadData();
  }

  @autobind loadData() {
    this.loadCounts();
    this.prepareChartSpecs();
  }

  @autobind async loadCounts() {
    this.counts = await DashboardStore.apiPromise({}, 'GET', 'exercises');
  }

  @autobind async prepareChartSpecs() {
    const membershipTypes = await this.loadMembershipTypes();

    const resources = [];

    if (AppUI.siteIsDefault) {
      resources.push({
        label: 'Exercises Started',
        name: 'UserExercise',
        query: 'correct_answers_count > 0',
      });
      resources.push({
        label: 'Patrons Joined',
        name: 'UserGroup',
        join_table: 'user',
        query: 'users.guest = FALSE',
      });
    }

    resources.push({
      label: 'Subscriptions',
      name: 'Analytic',
      date_field: 'date',
      scope: 'site',
      value_field: 'new_subscriptions_count',
      cumulative_value_field: 'total_subscriptions_count',
    });
    resources.push({
      label: 'Cancellations',
      name: 'Analytic',
      date_field: 'date',
      scope: 'site',
      value_field: 'cancellations_count',
      cumulative_value_field: 'cancellations_count',
    });
    membershipTypes.forEach(membershipType => (
      resources.push({
        label: `${membershipType.name} Subscriptions`,
        name: 'Analytic',
        date_field: 'date',
        scope: 'site',
        value_field: `new_subscriptions_counts_by_tier.${membershipType.id}`,
        cumulative_value_field: `total_subscriptions_counts_by_tier.${membershipType.id}`,
      })
    ));

    this.chartSpecs = [
      { name: 'Dashboard', resources },
    ];
  }

  get membershipTypes() {
    return AppUI.membershipTypeStore.indexData;
  }

  @autobind async loadMembershipTypes() {
    if (AppUI.membershipTypeStore.indexData.length === 0) {
      await AppUI.membershipTypeStore.getIndexPromise({});
    }
    return AppUI.membershipTypeStore.indexData.sort((a, b) => a.position - b.position);
  }

  render() {
    return (
      <Wrapper>
        {this.renderActiveCountsSection()}

        <fieldset
          style={{margin: '20px 0 10px 0'}}
        >
          {
            this.chartSpecs ? (
              <LineChartWithControls
                namespace={AppUI.siteIsDefault ? 'exercise-dashboard-new-exercises' : 'dashboard'}
                charts={this.chartSpecs}
              />
            ) : (
              <div>
                Loading...
              </div>
            )
          }
        </fieldset>
      </Wrapper>
    );
  }

  renderActiveCountsSection() {
    if (!AppUI.siteIsDefault) return null;

    return (
      <fieldset>
        <legend>
          Active
        </legend>
        <div className='row'>
          <div className='col-xs-3'>
            <DashboardCount
              label='1 day'
              count={this.counts.exercisesDauCount}
            />
          </div>
          <div className='col-xs-3'>
            <DashboardCount
              label='1 week'
              count={this.counts.exercisesWauCount}
            />
          </div>
          <div className='col-xs-3'>
            <DashboardCount
              label='1 month'
              count={this.counts.exercisesMauCount}
            />
          </div>
        </div>
      </fieldset>
    );
  }
}

export default ErrorBoundary(ExercisesDashboard);
