import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { CampaignMessageStore } from '@seedlang/stores';
import { Link } from 'react-router';

@observer
class CampaignMessageShow extends React.Component {

  constructor(props) {
    super(props);
    CampaignMessageStore.getShow({ids: {campaignMessageId: this.props.params.campaignMessageId}});
  }

  render() {
    if (CampaignMessageStore.hasShowData) {
      return (
        <div className='mailings-show'>
          <div className='row'>
            <div className='col-xs-6'>
              <fieldset>
                <legend>User</legend>
                <Link
                  to={{ name: 'builder.users.edit', params: { userId: CampaignMessageStore.showData.user.id } }}
                  className='on-click'
                >
                  {CampaignMessageStore.showData.user.name}
                </Link>
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Subject</legend>
                {CampaignMessageStore.showData.campaign?.subject}
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Title</legend>
                <div
                  dangerouslySetInnerHTML={{ __html: CampaignMessageStore.showData.campaign?.title }}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Body</legend>
                <div
                  dangerouslySetInnerHTML={{ __html: CampaignMessageStore.showData.campaign?.mailingBody }}
                />
              </fieldset>
            </div>
          </div>
        </div>
      );
    }
    return <span />;
  }
}

export default ErrorBoundary(CampaignMessageShow);
