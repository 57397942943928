import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import Paginator from 'components/paginator';
import Filter from 'components/backend_filter/filter';

const Wrapper = styled.div`
  table {
    margin-top: 20px;
  }
`;

const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  width: ${props => props.width};
  flex: ${props => props.expand ? 1 : null};
  padding: 0 10px;
  border-right: 1px solid white;
  height: 30px;
  cursor: ${props => props.clickable ? 'pointer' : 'default'};
  color: #FFF;
  display: block;
  font-size: 10px;
  line-height: 11px;
  font-weight: bold;
  color: white;
  margin: 0;
  display: flex;
  i {
    color: #FFF;
    text-decoration: none;
    margin-right: 4px;
  }
  .fa-check {
    margin-right: 0;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

const HeaderRow = styled.div`
  background: #636363;
  display: flex;
  align-items: center;
  height: 30px;
`;

const Table = styled.div`
  background: #FFF;
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  height: 40px;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: ${props => props.width};
  flex: ${props => props.expand ? 1 : null};
  padding: 0 5px;
  .selection-button {
    width: 100%;
  }
  .icon-wrapper {
    cursor: pointer;
  }
`;

@observer
class TranslatorUsers extends React.Component {

  componentDidUpdate(prevProps) {
    if (prevProps.params.languageId !== this.props.params.languageId) {
      this.loadUsers();
    }
  }

  @autobind loadUsers() {
    AppUI.userStore.getIndex({sort: '-translation_sources_count', filters: {admin: false, language_id: this.props.params.languageId}});
  }

  render() {
    return (
      <Wrapper>
        <FilterWrapper>
          <Filter
            store={AppUI.userStore}
            defaultSort='-translation_sources_count'
            namespace='user-translator-index'
            defaultFilters={{admin: false, translation_sources_count: '!0', language_id: this.props.params.languageId}}
            filters={
              [
                {
                  type: 'boolean',
                  name: 'translator',
                  label: 'Translator',
                  default: '',
                },
                {
                  type: 'multi_select',
                  name: 'translator_type',
                  label: 'Translator Type',
                  options: [{id: 1, name: 'Trial Translator'}, {id: 2, name: 'Translator'}, {id: 3, name: 'Translator Lead'}],
                  labelField: 'name',
                  default: '',
                },
                {
                  type: 'number',
                  name: 'translation_sources_count',
                  label: '# Translations',
                  default: '',
                },
              ]
            }
          />
        </FilterWrapper>
        <Table>
          <HeaderRow>
            <HeaderCell width='30px' />
            <HeaderCell expand >Name</HeaderCell>
            <HeaderCell width='270px'>Role</HeaderCell>
            <HeaderCell width='100px'>Last Active</HeaderCell>
            <HeaderCell width='100px'># Translations</HeaderCell>
          </HeaderRow>
          {
            AppUI.userStore.indexData.map(item => {
              return (
                <Row
                  key={item.index}
                >
                  <Cell
                    width='30px'
                  >
                    <Link
                      to={{name: 'translator.user', params: {languageId: this.props.params.languageId, userId: item.id}}}
                    >
                      <i class='fa fa-chevron-right fa-block fa-on-click' />
                    </Link>
                  </Cell>
                  <Cell
                    expand
                  >
                    <Link
                      to={{name: 'translator.user', params: {languageId: this.props.params.languageId, userId: item.id}}}
                    >
                      {item.name}
                    </Link>
                  </Cell>
                  <Cell
                    width='270px'
                  >
                    {item.translator ? item.translatorRole : '-'}
                  </Cell>
                  <Cell
                    width='100px'
                  >
                    {item.lastActiveAt?.formattedDateWithYear}
                  </Cell>
                  <Cell
                    width='100px'
                  >
                    {item.translationSourcesCount}
                  </Cell>
                </Row>
              );
            })
          }
        </Table>
        <Paginator
          store={AppUI.userStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TranslatorUsers);
