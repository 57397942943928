import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { RatingStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';

const Wrapper = styled.div`

`;

@observer
class RatingEdit extends React.Component {

  constructor(props) {
    super(props);
    RatingStore.getShow({ids: {ratingId: this.props.params.ratingId}});
  }

  render() {
    return (
      <Wrapper>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Comment</legend>
              <InPlaceText
                inputType='textarea'
                defaultValue={RatingStore.showDataField('comment')}
                model='ratings'
                field='comment'
                id={RatingStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Edited Comment</legend>
              <InPlaceText
                inputType='textarea'
                defaultValue={RatingStore.showDataField('editedComment')}
                model='ratings'
                field='edited_comment'
                id={RatingStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(RatingEdit);
