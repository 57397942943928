import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import { isPresent, isBlank } from '@seedlang/utils';
import { each } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.flexDirection};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  flex-wrap: wrap;
`;

const ExternalLink = styled.a`
`;

const LinkWrapper = styled.div`
  display: ${props => props.display};
  margin-left: 15px;
  font-size: 17px;
  align-items: center;
  justify-content: center;
  color: ${props => props.color};
  border-bottom: 2px solid transparent;
  border-bottom-color: ${props => props.isSelected ? props.borderBottomColor : 'transparent'};
  &:hover {
    border-bottom-color: ${props => `${props.borderBottomColor}a1`};
  }
  a {
    color: ${props => props.color};
  }
`;

@observer
class NavigationItemLinks extends React.Component {

  @computed get filteredNavigationItems() {
    if (!this.props.navigationItems) { return null; }
    const items = this.props.navigationItems.filter(item => (item.footerLink === this.props.footerLink || (this.props.headerLink && item.headerLink)));
    if (AppUI.user.guest || !AppUI.userIsSignedIn) {
      return items.filter(item => item.showWhenSignedOut);
    } else {
      return items.filter(item => item.showWhenSignedIn && (isBlank(item.membershipTypeIds) || item.membershipTypeIds.split(',').indexOf(AppUI.user?.currentUserGroup?.membershipTypeId) !== -1 || AppUI.userIsGroupAdmin));
    }
  }

  matchRouteDynamic(item) {
    const routeName = isPresent(item.path) ? item.path : item.routeName;
    if (item.id === AppUI.clickedLinkId) {
      return true;
    } else if (isPresent(item.path)) {
      return window.location.href.match(item.path);
    } else {
      const routeSplit = routeName.replace('.', '/').split('/').filter(Boolean);
      return AppUI.routeStore.routeName && isPresent(AppUI.routeStore.routeName.match(routeSplit[0]));
    }
  }

  routeCombine(navItem) {
    let route = { name: navItem.routeName };

    if (navItem.routeParams) {
      route = { name: navItem.routeName, params: JSON.parse(navItem.routeParams)};
    }

    if (navItem.routeIds) {
      const obj = {};
      each(navItem.routeIds.split(','), keyValue => {
        const ary = keyValue.split(':');
        obj[ary[0].trim()] = ary[1].trim();
      });
      route['params'] = obj;
    }
    return route;
  }

  @autobind onClickLink(item) {
    AppUI.set('clickedLinkId', item.id);
  }

  render() {
    return (
      <Wrapper
        flexDirection={this.props.flexDirection}
        alignItems={this.props.alignItems}
        justifyContent={this.props.justifyContent}
      >
        {
          this.filteredNavigationItems && this.filteredNavigationItems.map(item => {
            return (
              <LinkWrapper
                display='inline-flex'
                borderBottomColor={this.props.hideBorder ? 'transparent' : '#b7b7b7'}
                isSelected={this.matchRouteDynamic(item)}
                color={this.props.color}
                key={item.id}
                onClick={() =>this.onClickLink(item)}
              >
                {
                  !item.externalLink &&
                    <Link
                      to={item.path ? item.path : this.routeCombine(item)}
                      onClick={this.props.afterClick}
                      key={item.id}
                    >
                      {item.name}
                    </Link>
                }
                {
                  item.externalLink &&
                    <ExternalLink
                      href={item.path}
                      target={item.targetBlank ? '_blank' : undefined}
                    >
                      {item.name}
                    </ExternalLink>
                }
              </LinkWrapper>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(NavigationItemLinks);
