import React from 'react';
import { observer } from 'mobx-react';
import AudioPlayer from 'components/media/audio_player';
import VideoPlayer from 'components/media/video_player';
import { AppUI, ChatUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isPresent } from '@seedlang/utils';
import Dropdown from 'components/dropdown';
import UserPost from 'components/user/user_post';
import { ThreeDotsIcon } from '@seedlang/icons';
import uuidv4 from 'uuid/v4';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { MediaStore, VideoClipStore } from '@seedlang/stores';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
`;

const ChatMessageWrapper = styled.div`
  float: ${props => props.float};
  width: 100%;
  max-width: 480px;
  position: relative;
  overflow: hidden;
`;

const ChatBubble = styled.div`
  margin: ${props => props.margin};
  background: #ececec;
  position: relative;
  border-radius: 10px;
  padding: 10px;
  border: 2px solid #adadad;
  &:after, &:before {
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    ${props => props.arrowLeft ? LeftArrowAfter : RightArrowAfter};
  }
  &:before {
    ${props => props.arrowLeft ? LeftArrowBefore : RightArrowBefore};
  }
`;

const Top = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  .three-dots-icon {
    width: 4px;
    height: 15px;
    float: right;
  }
`;

const LeftArrowBefore = `
  border-color: rgba(245, 74, 32, 0);
  border-right-color: #adadad;
  border-width: 13px;
  margin-top: -13px;
  right: 100%;
`;

const LeftArrowAfter = `
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ececec;
  border-width: 10px;
  margin-top: -10px;
  right: 100%;
`;

const RightArrowBefore = `
  border-color: rgba(245, 74, 32, 0);
  border-left-color: #adadad;
  border-width: 13px;
  margin-top: -13px;
  left: 100%;
`;

const RightArrowAfter = `
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #ececec;
  border-width: 10px;
  margin-top: -10px;
  left: 100%;
`;

const Message = styled.div`
  font-size: 14px;
  line-height: 18px;
  a {
    text-decoration: underline;
  }
`;

const UserPostWrapper = styled.div`
  flex: 1;
  padding-right: 10px;
`;

@observer
class ChatMessage extends React.Component {

  constructor(props) {
    super(props);
    if (this.props.chatMessage.audioUrl) {
      this.mediaStore = new MediaStore({
        url: this.props.chatMessage.audioUrl,
        duration: this.props.chatMessage.duration,
      });
    }
  }

  componentDidMount() {
    const chatWindow = document.getElementsByClassName('chat-window')[0];
    chatWindow.scrollTop = chatWindow.scrollHeight;
  }

  @autobind onUserClick(user) {
    AppUI.gotoProfile(this.props.chatMessage.user);
  }

  @autobind onCreateComment(id, text, commentType, mediaStore) {
    let xp, xpSlug, callback;
    if (text) {
      this.props.chatMessage.comments.push({
        id: uuidv4(),
        text: text,
        user: AppUI.user,
        createdAt: (new Date()).toString(),
        commentType: commentType,
      });
    } else if (mediaStore) {
      this.mediaStore = mediaStore;
      callback = this.afterCreateComment;
    }
    if (commentType === 'response') {
      xp = 1;
      xpSlug = 'comment-response';
    } else {
      xp = 5;
      xpSlug = commentType;
    }
    ChatUI.onCreateComment(id, text, commentType, xp, xpSlug, callback);
  }

  @autobind afterCreateComment(resp) {
    VideoClipStore.multipartCreate({
      data: this.mediaStore.getFormData(),
      queryStrings: {
        videoable_type: 'Comment',
        videoable_id: resp['id'],
      },
    }, () => ChatUI.loadChat());
    this.mediaStore.onSoftReset();
  }

  @autobind onDeleteComment(id) {
    ChatUI.commentStore.destroy({ids: {commentId: id}}, this.afterDeleteComment);
  }

  @autobind afterDeleteComment() {
    if (this.props.afterChange) {
      this.props.afterChange();
    }
    ChatUI.loadChat();
  }

  render() {
    return (
      <Wrapper>
        <ChatMessageWrapper
          float={this.props.submittedByMe ? 'right' : 'left'}
        >
          <ChatBubble
            margin={this.props.submittedByMe ? '0 10px 0 0' : '0 0 0 10px'}
            arrowLeft={!this.props.submittedByMe}
          >
            <Top>
              <UserPostWrapper>
                <UserPost
                  onClick={this.onUserClick}
                  user={this.props.chatMessage.user}
                  date={this.props.chatMessage.createdAt}
                />
              </UserPostWrapper>
              {
                (this.props.submittedByMe || AppUI.user.admin) &&
                  <Dropdown>
                    <ThreeDotsIcon />
                    <ul>
                      <li
                        onClick={() => ChatUI.onDestroyChatMessage(this.props.chatMessage.id)}
                      >
                        Delete
                      </li>
                    </ul>
                  </Dropdown>
              }
            </Top>
            {
              isPresent(this.props.chatMessage.message) &&
                <Message>
                  {this.props.chatMessage.message}
                </Message>
            }
            {
              this.props.chatMessage.audioUrl &&
                <AudioPlayer
                  mediaStore={this.mediaStore}
                />
            }
            {
              this.props.chatMessage.hasVideoClip &&
                <div
                  className='video-player-wrapper'
                  style={{
                    width: this.props.width,
                    height: this.props.width,
                  }}
                >
                  <VideoPlayer
                    videoClip={this.props.chatMessage.videoClip}
                    width={this.props.width}
                  />
                </div>
            }
          </ChatBubble>
        </ChatMessageWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ChatMessage);
