import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import InPlaceText from 'components/form/in_place_text';
import Button from 'components/button/button';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isBlank, isPresent } from '@seedlang/utils';
import { Languages } from '@seedlang/constants';
import { LanguageIcon } from '@seedlang/icons';
import VideoPlayer from 'components/media/video_player';
import FlagButton from 'components/button/flag_button';

const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  background: #FFF;
  align-items: center;
  width: ${props => props.width};
`;

const TranslationWrapper = styled.div`
  flex: 1;
`;

const Row = styled.div`
  margin: ${props => props.margin};
  display: flex;
  align-items: center;
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  background: #e1e1e1;
  display: flex;
  margin-right: 10px;
  align-items: center;
  height: 35px;
  font-size: 12px;
  line-height: 22px;
  font-weight: bold;
  color: #333;
  width: 180px;
  padding: 0 0 0 10px;
`;

const Field = styled.div`
  display: flex;
  font-size: 14px;
  flex: 1;
  padding-right: 10px;
  text-align: left;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  max-width: 300px;
  input {
    height: 35px;
    margin-right: 10px;
  }
`;

const Clickable = styled.div`
  cursor: pointer;
  border: 1px dotted #CCC;
  direction: ${props => props.rtl ? 'rtl' : 'auto'};
`;

const DisplayText = styled.div`
  direction: ${props => props.rtl ? 'rtl' : 'auto'};
`;

const VideoPlayerWrapper = styled.div`
  position: relative;
  border-radius: 10px;
  width: 150px;
  height: 130px;
`;

@observer
class TranslatorSentence extends React.Component {
  @observable textEntry;
  @observable submitted = false;
  @observable forceEdit = false;

  @computed get editMode() {
    return this.forceEdit || isBlank(this.sourceText);
  }

  @computed get sourceText() {
    return this.submitted ? this.textEntry : this.translationSourceText;
  }

  @computed get translationSource(){
    return this.props.sentence.translationSources.find(item => item.source?.languageId === AppUI.user.languageId);
  }

  @computed get translationSourceText() {
    const translationSource = this.translationSource;
    if (translationSource) {
      return translationSource.source.text;
    }
    return null;
  }

  @autobind onSubmit() {
    AppUI.translationSourceStore.create({data: {
      text: this.textEntry,
      language_id: AppUI.user.languageId,
      user_id: AppUI.user.id,
      translation_id: this.props.sentence.id,
      rejected: false,
    }}, this.afterSubmit);
  }

  @autobind afterSubmit() {
    this.forceEdit = false;
    this.submitted = true;
    if (this.props.afterSubmit) {
      this.props.afterSubmit();
    }
  }

  @autobind onEditText() {
    if (!AppUI.user.blockedTranslationInput) {
      this.forceEdit = true;
      this.textEntry = this.translationSourceText;
    }
  }

  render() {
    return (
      <Wrapper
        width={this.props.width}
      >
        <TranslationWrapper>
          <Row
            margin='10px 0'
          >
            <Label>
              <LanguageIcon
                languageId={this.props.sentence.languageId}
                margin='0 5px 0 0'
              />
              Target Sentence
            </Label>
            <Field>
              {this.props.sentence.targetText}
            </Field>
          </Row>
          <Row
            margin='10px 0'
          >
            <Label>
              <LanguageIcon
                languageId='EN'
                margin='0 5px 0 0'
              />
              English Translation
            </Label>
            <Field>
              {this.props.sentence.defaultSource.text}
            </Field>
          </Row>
          {
            AppUI.user.languageId !== 'EN' &&
              <Row
                margin='10px 0'
              >
                <Label>
                  <LanguageIcon
                    languageId={AppUI.user.languageId}
                    margin='0 5px 0 0'
                  />
                  {Languages[AppUI.user.languageId]} Translation
                </Label>
                <Field>
                  {
                    this.editMode && !AppUI.user.blockedTranslationInput &&
                      <Row>
                        <InputWrapper>
                          {
                            AppUI.user.translator &&
                              <InPlaceText
                                allowUpdate
                                rtl={AppUI.user?.rightToLeftLanguage}
                                submitOnEnter
                                onSubmit={this.onSubmit}
                                onChange={val => this.textEntry = val}
                                value={this.textEntry}
                                inputHeight='35px'
                                show
                              />
                          }
                          {
                            !AppUI.user.translator &&
                              <div>
                                {this.textEntry}
                              </div>
                          }
                        </InputWrapper>
                        {
                          AppUI.user.translator &&
                            <Button
                              margin='0 0 0 10px'
                              onClick={this.onSubmit}
                              height='35px'
                            >
                              Submit
                            </Button>
                        }
                      </Row>
                  }
                  {
                    !this.editMode && !AppUI.user.blockedTranslationInput && AppUI.user.translator &&
                      <Clickable
                        rtl={AppUI.user?.rightToLeftLanguage}
                        onClick={this.onEditText}
                      >
                        {this.sourceText}
                      </Clickable>
                  }
                  {
                    !this.editMode && !AppUI.user.blockedTranslationInput && !AppUI.user.translator &&
                      <DisplayText
                        rtl={AppUI.user?.rightToLeftLanguage}
                        onClick={this.onEditText}
                      >
                        {this.sourceText}
                      </DisplayText>
                  }
                  {
                    AppUI.user.blockedTranslationInput &&
                      <div>
                        {this.sourceText}
                      </div>
                  }
                  {
                    this.translationSource && !this.editMode &&
                      <FlagButton
                        onConfirm={()=>this.props.onFlag(this.translationSource)}
                        message='Flag Incorrect Translation'
                        flagged={this.translationSource.flaggedForRetranslation}
                      />
                  }
                </Field>
              </Row>
          }
        </TranslationWrapper>
        {
          isPresent(this.props.sentence.videoClip) && !this.props.hideVideos &&
            <VideoPlayerWrapper>
              <VideoPlayer
                circle
                loop
                autoPlay
                videoClip={this.props.sentence.videoClip}
                width='130'
              />
            </VideoPlayerWrapper>
        }
        {
          this.props.sentence.target.videoClip && !this.props.hideVideos &&
            <VideoPlayerWrapper>
              <VideoPlayer
                circle
                videoClip={this.props.sentence.target.videoClip}
                width='130'
              />
            </VideoPlayerWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TranslatorSentence);
