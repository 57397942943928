import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, DeckUI } from '@seedlang/state';
import { DeckCloseWebWrapper, ComprehendCardWrapper } from '@seedlang/hoc';
import CardUserInput from 'components/card/card_user_input';
import CardText from 'components/card/card_text';
import { pixify } from '@seedlang/utils';
import MediaWithSupplement from 'components/media/media_with_supplement';
import NoUserInput from 'components/media/no_user_input';
import styled from '@emotion/styled';
import EvaluateButtons from 'components/card/evaluate_buttons';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import { flexCenterColumn } from '@seedlang/style_mixins';
import CardArrowBox from 'components/card/card_arrow_box';

const MediaSection = styled.div`
  width: 100%;
  ${flexCenterColumn()}
`;

@observer
class ComprehendCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        showWordCard={this.props.showWordCard}
        icons={this.props.icons}
        sourceLanguageId={this.props.card.sentence.sourceTextForUserLanguageId(DeckUI.user)}
      >
        <CardContent>
          <MediaSection>
            <MediaWithSupplement
              card={this.props.card}
              cardState={this.props.cardState}
              autoPlay={this.props.inView}
              borderTopRadius={10}
              afterVideoPlayback={this.props.afterVideoPlayback}
              borderBottomRadius={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
              videoClip={this.props.card.targetVideoClip}
              supplementVideoClip={this.props.card.supplementVideoClip}
              supplementImage={this.props.card.supplementImage}
              usingDataSaverHeader={AppUI.usingDataSaverHeader}
              supplementBottom={DeckUI.layout.supplementBottom}
              loopSupplement={DeckUI.user.loopSupplement}
              hideSupplement={!this.props.showDeckSupplement}
              maximizedWidth={pixify(DeckUI.layout.videoHeight)}
              minimizedWidth={pixify(DeckUI.layout.supplementWidth)}
              supplementMaximized={this.props.supplementMaximized}
              loadVideo={!this.props.showHelp}
              marginBottom={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
            />
          </MediaSection>
          <CardText
            card={this.props.card}
            cardState={this.props.cardState}
            padding='0 30px'
            hasUserInput
            cardId={this.props.card.id}
            sentence={this.props.card.sentence}
            cardTypeSlug={this.props.card && this.props.card.cardTypeSlug}
            showText={this.props.showCardText}
            expand={false}
            showType={this.props.cardState.showType}
            borderTopRadius={(DeckUI.layout.cardTextHasMinimumHeight || this.props.translateCard) && !this.props.cardState.revealed ? 10 : 0}
            borderBottomRadius={0}
            showEdit
          />
          {
            !this.props.showUserInput &&
              <NoUserInput />
          }
          {
            this.props.showUserInput &&
              <CardUserInput
                disabled={!this.props.initialVideoPlayFinished}
                card={this.props.card}
                cardState={this.props.cardState}
              />
          }
          <CardArrowBox
            card={this.props.card}
            possibleArrowBoxes={this.props.possibleArrowBoxes}
            currentArrowBox={this.props.currentArrowBox}
          />
        </CardContent>
        <EvaluateButtons
          card={this.props.card}
          cardState={this.props.cardState}
          userCard={this.props.userCard}
          disabled={!this.props.inView}
          onNextClick={this.props.onNextClick}
          advanceCard={this.props.advanceCard}
        />
      </CardWrapper>
    );
  }
}

export default DeckCloseWebWrapper(ComprehendCardWrapper(ComprehendCard));
