import React from 'react';
import { observer } from 'mobx-react';
import { BlogEntryStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import BlogEntryCreate from 'pages/builder/blog_entries/blog_entry_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';

@observer
class InterjectionIndex extends React.Component {

  componentDidMount() {
    this.getBlogEntries();
  }

  @autobind onDelete(id) {
    BlogEntryStore.destroy({ids: {blogEntryId: id}});
  }

  @autobind getBlogEntries() {
    BlogEntryStore.getIndex();
  }

  render() {
    return (
      <div className='blog-entry-index'>
        <BlogEntryCreate
          afterCreate={this.getBlogEntries}
        />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th>Title</th>
              <th>User</th>
              <th>Published At</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              BlogEntryStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.blog_entries.edit', params: { blogEntryId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        {item.title}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.user && item.user.name}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.publishedAt && item.publishedAt.formatted}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this blog entry?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={BlogEntryStore}
        />
      </div>
    );
  }
}

export default InterjectionIndex;
