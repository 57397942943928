import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Link } from 'react-router';
import { DeckRulebookStore, DeckStore, CardRuleStore, CardTypeStore , InterjectionStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import InPlaceSelect from 'components/form/in_place_select';
import cx from 'classnames';
import { isBlank, isPresent } from '@seedlang/utils';
import Modal from 'components/modal';
import CardRuleInterjectionEdit from 'pages/builder/card_rule_interjections/card_rule_interjection_edit';
import { sortBy } from 'lodash';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import DeckIndex from 'pages/builder/decks/deck_index';
import Spinner from 'components/spinner';
import Paginator from 'components/paginator';
import CheckIfTrue from 'components/check_if_true';

const Wrapper = styled.div`
  .deck-rulebook-edit {
    .text-field-group {
      .in-place-text {
        display: inline-block;
        width: 30px;
      }
    }

    .gray-button {
      margin-bottom: 20px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const LockedMessage = styled.div`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 10px;
`;

@observer
class DeckRulebookEdit extends React.Component {
  @observable edit = null;
  @observable editCardRule = {};

  constructor(props) {
    super(props);
    this.loadRulebook();
    InterjectionStore.getIndex();
    CardTypeStore.setLimit(999);
    if (!CardTypeStore.hasIndexData) {
      CardTypeStore.getIndex();
    }
    DeckStore.getIndex({limit: 5, filters: {deck_rulebook_id: this.props.params.deckRulebookId, user_id: false}, queryStrings: {include_count: true}});
  }

  @autobind onCreateCardRule() {
    CardRuleStore.create({data: {deck_rulebook_id: this.props.params.deckRulebookId}}, this.loadRulebook);
  }

  @autobind loadRulebook() {
    DeckRulebookStore.getShow({ids: {deckRulebookId: this.props.params.deckRulebookId}}, this.afterLoadRulebook);
  }

  @autobind afterLoadRulebook(resp) {
    DeckRulebookStore.setShowData(resp);
    if (isPresent(this.editCardRule)) {
      this.editCardRule = resp.cardRules.find(item => item.id === this.editCardRule.id);
    }
  }

  @autobind onDeleteRule(id) {
    CardRuleStore.destroy({ids: {cardRuleId: id}}, this.loadRulebook);
  }

  @autobind onShowModal(editType, item) {
    this.edit = editType;
    this.editCardRule = item;
  }

  @autobind onCloseModal() {
    this.edit = null;
    this.editCardRuleId = {};
  }

  @autobind onLockRulebook() {
    DeckRulebookStore.update({data: {locked: true}, ids: {deckRulebookId: this.props.params.deckRulebookId}}, this.loadRulebook);
  }

  render() {
    return (
      <Wrapper>
        <div className='deck-rulebook-edit'>
          {
            this.edit === 'name' &&
              <Modal
                onCloseModal={this.onCloseModal}
              >
                <fieldset>
                  <legend>Name</legend>
                  <InPlaceText
                    defaultValue={this.editCardRule.name}
                    model='card_rules'
                    inputType='textarea'
                    field='name'
                    id={this.editCardRule.id}
                    afterChange={this.loadRulebook}
                  />
                </fieldset>
              </Modal>
          }
          {
            this.edit === 'description' &&
              <Modal
                onCloseModal={this.onCloseModal}
              >
                <fieldset>
                  <legend>Description</legend>
                  <InPlaceText
                    defaultValue={this.editCardRule.description}
                    model='card_rules'
                    inputType='textarea'
                    field='description'
                    id={this.editCardRule.id}
                    afterChange={this.loadRulebook}
                  />
                </fieldset>
              </Modal>
          }
          {
            this.edit === 'interjections' &&
              <Modal
                onCloseModal={this.onCloseModal}
              >
                <CardRuleInterjectionEdit
                  deckRulebookId={this.props.params.deckRulebookId}
                  cardRuleId={this.editCardRule.id}
                  cardRuleInterjections={this.editCardRule.cardRuleInterjections}
                  interjections={InterjectionStore.indexData}
                  afterChange={this.loadRulebook}
                />
              </Modal>
          }
          <div className='row'>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Name</legend>
                {
                  DeckRulebookStore.showData.locked &&
                    <div>
                      {DeckRulebookStore.showData.name}
                    </div>
                }
                {
                  !DeckRulebookStore.showData.locked &&
                    <InPlaceText
                      defaultValue={DeckRulebookStore.showData.name}
                      model='deck_rulebooks'
                      field='name'
                      id={DeckRulebookStore.showData.id}
                    />
                }
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Slug</legend>
                {
                  DeckRulebookStore.showData.locked &&
                    <div>
                      {DeckRulebookStore.showData?.slug}
                    </div>
                }
                {
                  !DeckRulebookStore.showData.locked &&
                    <InPlaceText
                      defaultValue={DeckRulebookStore.showData?.slug}
                      model='deck_rulebooks'
                      field='slug'
                      id={DeckRulebookStore.showData.id}
                    />
                }
              </fieldset>
            </div>
            {
              !DeckRulebookStore.showData.locked &&
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Settings</legend>
                    <InPlaceCheckbox
                      locked={DeckRulebookStore.showData.locked}
                      model='deck_rulebooks'
                      field='default'
                      icon='check'
                      id={DeckRulebookStore.showData.id}
                      value={DeckRulebookStore.showData.default}
                    >
                      Default
                    </InPlaceCheckbox>
                  </fieldset>
                </div>
            }
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Admin Description</legend>

                <InPlaceText
                  defaultValue={DeckRulebookStore.showData.adminDescription}
                  model='deck_rulebooks'
                  field='admin_description'
                  id={DeckRulebookStore.showData.id}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Rules</legend>
                {
                  !DeckRulebookStore.showData.locked &&
                    <ButtonWrapper>
                      <button
                        className='gray-button'
                        onClick={this.onCreateCardRule}
                      >
                        <i className='fa fa-plus' />
                        Create
                      </button>
                      <button
                        className='gray-button'
                        style={{marginLeft: '10px'}}
                        onClick={this.onLockRulebook}
                      >
                        <i className='fa fa-lock' />
                        Lock Rulebook
                      </button>
                    </ButtonWrapper>
                }
                {
                  DeckRulebookStore.showData.locked &&
                    <LockedMessage>
                      <i className='fa fa-lock' /> This rulebook is locked.
                    </LockedMessage>
                }
                <table className='table-wrapper'>
                  <thead>
                    <tr>
                      <th width='30'></th>
                      <th width='40'></th>
                      <th>Card Type</th>
                      <th>Show Supp</th>
                      <th>Loop Supp</th>
                      <th width='60'>Composite</th>
                      <th width='60'>Cycle</th>
                      <th>Multi-Choice</th>
                      <th width='60'>Answer</th>
                      <th>Interjection</th>
                      {
                        !DeckRulebookStore.showData.locked &&
                          <th width='40'></th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      DeckRulebookStore.hasShowData && DeckRulebookStore.showData.cardRules.map(item => {
                        return (
                          <tr
                            key={item.id}
                            className={cx(item?.cardTypeSlug)}
                          >
                            <td className='position'>
                              {
                                !DeckRulebookStore.showData.locked &&
                                  <InPlaceText
                                    defaultValue={item.position}
                                    model='card_rules'
                                    field='position'
                                    id={item.id}
                                    type='number'
                                    step='1'
                                    afterChange={this.loadRulebook}
                                  />
                              }
                              {
                                DeckRulebookStore.showData.locked &&
                                  <div>
                                    {item.position}
                                  </div>
                              }
                            </td>
                            <td>
                              {
                                item.cardType?.id &&
                                  <Link
                                    to={{name: 'builder.card_types.edit', params: {cardTypeId: item.cardType?.id}}}
                                    className='on-click'
                                  >
                                    <i className='fa fa-chevron-right fa-block'/>
                                  </Link>
                              }
                            </td>
                            <td className='card-type'>
                              {
                                CardTypeStore.hasIndexData && !DeckRulebookStore.showData.locked &&
                                  <InPlaceSelect
                                    includeBlank
                                    model='card_rule'
                                    field='card_type_id'
                                    id={item.id}
                                    value={item.cardType ? item.cardType.id : null}
                                    options={sortBy(CardTypeStore.indexData.map(item => [item.id, item?.slug]), (item => item[1]))}
                                    afterChange={this.loadRulebook}
                                  />
                              }
                              {
                                DeckRulebookStore.showData.locked &&
                                  <div>
                                    {item.cardType?.name}
                                  </div>
                              }
                            </td>
                            <td>
                              <InPlaceCheckbox
                                locked={DeckRulebookStore.showData.locked}
                                model='card_rule'
                                field='show_supplement'
                                id={item.id}
                                value={item.showSupplement}
                                icon='check'
                              />
                            </td>
                            <td>
                              <InPlaceCheckbox
                                locked={DeckRulebookStore.showData.locked}
                                model='card_rule'
                                field='loop_supplement'
                                id={item.id}
                                value={item.loopSupplement}
                                icon='check'
                              />
                            </td>
                            <td>
                              <InPlaceCheckbox
                                locked={DeckRulebookStore.showData.locked}
                                model='card_rule'
                                field='composite'
                                id={item.id}
                                value={item.composite}
                                icon='check'
                              />
                            </td>
                            <td>
                              <InPlaceCheckbox
                                locked={DeckRulebookStore.showData.locked}
                                model='card_rule'
                                field='cycle_cards_until_correct'
                                id={item.id}
                                value={item.cycleCardsUntilCorrect}
                                icon='check'
                              />
                            </td>
                            <td>
                              <InPlaceCheckbox
                                locked={DeckRulebookStore.showData.locked}
                                model='card_rule'
                                field='multiple_choice'
                                id={item.id}
                                value={item.multipleChoice}
                                icon='check'
                              />
                            </td>
                            <td>
                              <InPlaceCheckbox
                                locked={DeckRulebookStore.showData.locked}
                                model='card_rule'
                                field='use_answer'
                                id={item.id}
                                value={item.useAnswer}
                                icon='check'
                              />
                            </td>
                            <td>
                              <div
                                className='editable'
                                onClick={() => this.onShowModal('interjections', item)}
                              >
                                {
                                  isBlank(item.cardRuleInterjections) && item.cardType?.slug === 'interjection' &&
                                    <div
                                      data-tip
                                      data-for={item.id}
                                    >
                                      <i className='fa fa-warning' />
                                      <ReactTooltip
                                        place='top'
                                        type='dark'
                                        effect='solid'
                                        id={item.id}
                                        class='custom-tooltip'
                                      >
                                        You need to select an interjection type
                                      </ReactTooltip>
                                    </div>
                                }
                                <ul className='bulleted'>
                                  {
                                    item.cardRuleInterjections.map(item => {
                                      return (
                                        <li key={item.id}>{item.interjection && item.interjection.name}</li>
                                      );
                                    })
                                  }
                                </ul>
                              </div>
                            </td>
                            {
                              !DeckRulebookStore.showData.locked &&
                                <td>
                                  <DeleteButton
                                    onConfirm={() => this.onDeleteRule(item.id)}
                                    message='Delete this rule?'
                                    className='fa-block right'
                                  />
                                </td>
                            }
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Decks {DeckStore.hasIndexData ? `(${DeckStore.totalCount})` : ''}</legend>
                <table className='table-wrapper'>
                  <thead>
                  <tr>
                    <th width='40' />
                    <th>Name</th>
                    <th># Cards</th>
                    <th>Language</th>
                    <th>Created At</th>
                    <th>Web Published</th>
                    <th>Mobile Published</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    DeckStore.indexData.map(deck => {
                      return (
                        <tr key={deck.id}>
                          <td>
                            <Link
                              to={{ name: 'builder.decks.edit', params: { deckId: deck.id } }}
                              target='_blank'
                              className='on-click'
                            >
                              <i className='fa fa-chevron-right fa-block' />
                            </Link>
                          </td>
                          <td>{deck.name}</td>
                          <td>{deck.cardsCount}</td>
                          <td>{deck.languageId}</td>
                          <td>{deck.createdAt?.formattedDateWithYear}</td>
                          <td>
                            <CheckIfTrue
                              value={deck.webPublished}
                            />
                          </td>
                          <td>
                            <CheckIfTrue
                              value={deck.mobilePublished}
                            />
                          </td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </table>
                {
                  DeckStore.requestCounter > 0 &&
                  <Spinner />
                }
                {
                  DeckStore.hasIndexData &&
                  <Paginator
                    store={DeckStore}
                    filters={{deck_rulebook_id: this.props.params.deckRulebookId}}
                    queryStrings={{include_count: true}}
                    limit={5}
                  />
                }
              </fieldset>
            </div>
          </div>
        </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Concept Categories</legend>
                <table className='table-wrapper'>
                  <thead>
                    <tr>
                      <th width='20'></th>
                      <th>Name</th>
                      <th>Concepts</th>
                    </tr>
                  </thead>
                  <tbody>
                  {
                    DeckRulebookStore.hasShowData && DeckRulebookStore.showData.conceptCategories.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Link
                              to={{ name: 'builder.concept_categories.edit', params: { conceptCategoryId: item.id } }}
                              className='on-click'
                            >
                              <i className='fa fa-chevron-right fa-block' />
                            </Link>
                          </td>
                          <td>
                            <div className='text'>
                              {item.name}
                            </div>
                          </td>
                          <td>
                            <div className='text'>
                              {item.conceptsCount}
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  }
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
      </Wrapper>
    );
  }
}

export default DeckRulebookEdit;
