import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';
import Modal from 'components/modal';
import Button from 'components/button/button';
import Spinner from 'components/spinner';
import { Link } from 'react-router';
import OutlineButton from 'components/button/outline_button';

const Wrapper = styled.div`
  p {
    max-width: 500px;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 10px 0 0 0;
`;

const DeleteModal = styled.div`
  display: flex;
  align-items center;
  justify-content: center;
  flex-direction: column;
  p {
    margin: 20px 0 0 0;
  }
  padding: 10px;
`;

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  padding: 0 5px;
`;

@observer
class AccountDeletion extends React.Component {
  @observable showConfirmDeleteModal = false;

  @autobind onDeleteUser() {
    this.showDeletionSpinner = true;
    AppUI.userStore.destroy({ ids: { userId: AppUI.user.id } }, this.afterDeleteUser);
  }

  @autobind afterDeleteUser(resp) {
    this.showDeletionConfirmation = true;
    this.showDeletionSpinner = false;
    this.deletionSuccess = resp['success'];
    if (this.deletionSuccess) {
      AppUI.signOut();
    }
  }

  render() {
    return (
      <Wrapper>
        {
          this.showConfirmDeleteModal &&
          <Modal
            onCloseModal={() => this.showConfirmDeleteModal = false}
            width={350}
          >
            <DeleteModal>
              <h3>Delete Account</h3>
              {
                !this.showDeletionConfirmation && !this.showDeletionSpinner &&
                <>
                  Are you sure that you want to delete your account and all of your data? This is not a reversible
                  action.
                  {
                    this.props.activeStripeSubscriptionExists &&
                    <p>
                      Deleting your account will also cancel your current subscription.
                    </p>
                  }
                  <ButtonGroup>
                    <ButtonWrapper>
                      <Button
                        onClick={this.onDeleteUser}
                        background={Theme.green}
                        width='100%'
                      >
                        <i className='fa fa-check' />
                        Confirm
                      </Button>
                    </ButtonWrapper>
                    <ButtonWrapper>
                      <Button
                        onClick={() => this.showConfirmDeleteModal = false}
                        background={Theme.red}
                        width='100%'
                      >
                        <i className='fa fa-times' />
                        Cancel
                      </Button>
                    </ButtonWrapper>
                  </ButtonGroup>
                </>
              }
              {
                this.showDeletionSpinner &&
                <div style={{ textAlign: 'center' }}>
                  <div>Your account is being deleted.</div>
                  <div>You will be signed out.</div>
                  <Spinner background={AppUI.site.accentColor} />
                </div>
              }
              {
                this.showDeletionConfirmation && !this.deletionSuccess &&
                <div style={{ textAlign: 'center' }}>
                  <div>Your account will be deleted by our team within 72 hours.</div>
                  <br />
                  <div>You can contact our support team if you have any questions.</div>
                  <Link
                    to={{ name: 'contact' }}
                  >
                    <Button
                      background={AppUI.siteIsDefault ? Theme.blue : AppUI.site.accentColor}
                      margin='10px 0 0 0'
                    >
                      Contact Us
                    </Button>
                  </Link>
                </div>
              }
            </DeleteModal>
          </Modal>
        }
        <h3>Seedlang Account and Data Deletion</h3>
        {
          AppUI.userIsSignedIn &&
          <div>
            <p>
              If you would like to delete your account and all of its associated data, please click the button below.
            </p>
            <OutlineButton
              selectedBackground={Theme.red}
              margin='10px 0 0 0'
              onClick={() => this.showConfirmDeleteModal = true}
            >
              Delete Account
            </OutlineButton>
          </div>
        }
        {
          !AppUI.userIsSignedIn &&
          <div>
            <p>
              You must be signed in in order to delete your account. Please sign in to continue or contact help@seedlang.com for assistance.
            </p>
            <p>
              Once you have signed in, you will only need to click a button in order to delete your account and all of its associated data.
            </p>
          </div>
        }
      </Wrapper>
    );
  }
}

export default AccountDeletion;
