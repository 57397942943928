import React from 'react';
import { observer } from 'mobx-react';
import { MultipleChoiceButtonsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import CardButton from 'components/card/card_button';
import { pixify, depixify, displayTargetTextCorrectedForLanguage } from '@seedlang/utils';
import HeightLabel from 'components/height_label';
import { DeckUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils/src';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  height: ${props => props.height};
  width: ${props => props.width};
  background: #333;
  padding: 0;
  margin: ${props => props.margin};
  position: relative;
  margin-top: ${props => props.marginTop};
`;

@observer
class MultipleChoiceButtons extends React.Component {

  static defaultProps = {
    marginTop: 10,
    margin: 0,
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.props.width)}
        height={pixify(this.props.height)}
        padding={this.props.padding}
        margin={this.props.margin}
        marginTop={pixify(this.props.marginTop)}
      >
        {
          DeckUI.layout.debug &&
            <HeightLabel
              left={0}
            >
              {this.props.height}
            </HeightLabel>
        }
        {
          isPresent(this.props.options) && this.props.options.map((item, index) => {
            return (
              <CardButton
                hideTextIfDisabled
                shortcut={index + 1}
                marginRight={index % 2 === 0 ? 5 : 0}
                marginLeft={index % 2 === 1 ? 5 : 0}
                marginBottom={index <= 1 ? 5 : 0}
                marginTop={index >= 2 ? 5 : 0}
                height={pixify(this.props.buttonHeight)}
                key={item.id}
                width={pixify(this.props.buttonWidth(index))}
                fontSize={this.props.fontSize(item, index)}
                originalCase
                disabled={this.props.cardDisabled(index)}
                showResult={this.props.selected.indexOf(item.id) !== -1 || (this.props.revealed && (item.correct || this.props.correct))}
                correctAnswer={(this.props.revealed || (this.props.selected.indexOf(item.id) !== -1)) && (item.correct || this.props.correct)}
                text={displayTargetTextCorrectedForLanguage(item[this.props.field], this.props.languageId)}
                backgroundColor='#FFF'
                disabledBackgroundColor='#565656'
                color='#000'
                disabledBorderRight='none'
                disabledColor='#828282'
                padding={this.props.padding}
                onClick={() => this.props.onClick(item)}
                borderBottomLeftRadius={index === 2 && !this.props.isMobile ? 20 : 5}
                borderBottomRightRadius={index === 3 && !this.props.isMobile ? 20 : 5}
              />
            );
          })
        }
      </Wrapper>
    );
  }
}

export default MultipleChoiceButtonsWrapper(MultipleChoiceButtons);
