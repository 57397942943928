import styled from '@emotion/styled';
import { LoadingState } from '@seedlang/state';
import { DownloadLinkStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import Spinner from 'components/spinner';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  input {
    border: 1px solid #AAA!important;
  }
`;

@observer
class DownloadLinkCopyFromWeb extends React.Component {
  @observable value = '';
  loadingState = new LoadingState();

  static propTypes = {
    onChange: PropTypes.func,
    worksheet: PropTypes.object,
    downloadLinkType: PropTypes.object,
  }

  @autobind async submit() {
    try {
      this.loadingState.started();

      await DownloadLinkStore.importPromise(this.props.worksheet.id, this.props.downloadLinkType.id, this.value);

      this.loadingState.succeeded();

      if (this.props.onChange) {
        this.props.onChange();
      }
    }
    catch (error) {
      console.error('Failed importing file', error);
      const message = error.failed && error.message ? error.message : 'An error occurred while importing the file.';
      this.loadingState.failed(message);
    }
  }

  render() {
    return (
      <div style={{ position: 'relative' }} className='in-place-file-upload'>
        <InputWrapper>
          <input
            type='text'
            onChange={el => this.value = el.target.value}
            value={this.value}
            style={{marginBottom: 10}}
          />
          <button
            style={{fontSize: '14px', height: '34px', marginBottom: '10px'}}
            onClick={() => this.submit()}
          >
            Import
          </button>
        </InputWrapper>
        {
          this.loadingState.errorMessage && (
            <div style={{ color: 'red' }} onClick={() => this.loadingState.clear()}>
              {this.loadingState.errorMessage}
            </div>
          )
        }
        {this.loadingState.isLoading && <Spinner className='blue' />}
      </div>
    );
  }
}

export default DownloadLinkCopyFromWeb;
