import React from 'react';
import { observer } from 'mobx-react';
import { NotificationsIcon } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';
import NotificationIndex from 'components/notification/notification_index';
import Spinner from 'components/spinner';
import styled from '@emotion/styled';
import Text from 'components/text';
import HeaderPopup from 'components/header_popup';
import isBlank from 'is-blank';
import { Theme } from '@seedlang/constants/src';

const Wrapper = styled.div`
  .notifications-icon {
    .icon-wrapper {
      width: 17px;
      height: 20px;
      svg {
        width: 17px;
      }
    }
  }
`;

const ScrollContainer = styled.div`
  position: relative;
  overflow: auto;
  height: calc(100% - 46px);
`;

@observer
class NotificationsWithPopup extends React.Component {

  render() {
    return (
      <Wrapper>
        <HeaderPopup
          title='Notifications'
          height={AppUI.layout.isMobile ? AppUI.layout.mobileContentHeight : 400}
          width={AppUI.layout.isMobile ? '100%' : '400px'}
          arrowRight={AppUI.layout.isMobile ? 68 : 56}
          right='-50px'
          background={this.props.background}
          onClickIcon={() => AppUI.toggleNotificationsOpened()}
          popupOpened={AppUI.notificationsOpened}
          icon={
            <NotificationsIcon
              count={AppUI.user.unseenNotificationsCount}
            />
          }
        >
          <ScrollContainer>
            {
              AppUI.user.notificationsCount === 0 &&
                <Text
                  center
                  bold
                  padding='20px'
                  fontSize='14px'
                >
                  You have no notifications
                </Text>
            }
            {
              AppUI.user.notificationsCount > 0 && !AppUI.notificationStore.initialRequestReceived &&
                <Spinner
                  className={AppUI.siteIsDefault ? 'blue' : null}
                  background={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor) ? Theme.blue : AppUI.site.accentColor}
                />
            }
            {
              AppUI.notificationStore.hasIndexData &&
                <NotificationIndex
                  notifications={AppUI.notificationStore.indexData}
                  store={AppUI.notificationStore}
                />
            }
          </ScrollContainer>
        </HeaderPopup>
      </Wrapper>
    );
  }
}

export default NotificationsWithPopup;
