import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Motion, spring } from 'react-motion';
import { ArrowLeftIcon } from '@seedlang/icons';
import { DeckUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { pixify } from '@seedlang/utils';
import key from 'keymaster';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${props => props.width};
  z-index: ${Theme.z['overlay']};
  background: white;
`;

const OverlayHeader = styled.div`
  background: ${Theme.darkBlue};
  height: ${props => props.height};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  padding: 0 10px;
  .left-arrow-icon {
    height: 21px;
    margin-top: 1px;
  }
`;

const Title = styled.div`
  flex: 1;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-left: 15px;
  padding-right: 70px;
  font-size: 18px;
`;

const OverlayImage = styled.img`
  border-radius: 50%;
  margin-right: 5px;
`;

const Content = styled.div`
  overflow: auto;
  height: ${props => props.height};
  fieldset {
    text-align: left;
  }
`;

@observer
class Overlay extends React.Component {
  @observable mounted = false;

  static propTypes = {
    overlayState: PropTypes.object.isRequired,
    layout: PropTypes.object.isRequired,
    leftPos: PropTypes.number,
  }

  static defaultProps = {
    leftPos: 0,
  }

  componentDidMount() {
    this.props.overlayState.set('mounted', true);
    key('escape', () => this.props.overlayState.removeOverlay());
  }

  componentWillUnmount() {
    key.unbind('escape');
  }

  render() {
    return (
      <Motion
        style={{
          x: spring(this.props.overlayState.isOpen && this.props.overlayState.mounted ? this.props.leftPos : this.props.layout.viewportWidth),
        }}
        onRest={this.props.overlayState.onRest.bind(this.props.overlayState)}
      >
        {
          ({x, opacity}) => {
            this.props.overlayState.setX(x);
            return (
              <Wrapper
                height={pixify(this.props.height)}
                width='100%'
                style={{
                  WebkitTransform: `translate3d(${x}px, ${this.props.leftPos}px, 0)`,
                  transform: `translate3d(${x}px, ${this.props.leftPos}px, 0)`,
                }}
              >
                {
                  !this.props.hideHeader &&
                    <OverlayHeader
                      height={pixify(DeckUI.layout.deckHeaderHeight)}
                      onClick={() => this.props.overlayState.removeOverlay()}
                    >
                      <ArrowLeftIcon />
                      <Title>
                        {
                          this.props.overlayImage &&
                            <OverlayImage
                              src={this.props.overlayImage}
                            />
                        }
                        {this.props.overlayTitle}
                      </Title>
                    </OverlayHeader>
                }
                <Content
                  height={this.props.deckOverlay ? pixify(this.props.height - 50) : pixify(this.props.layout.overlayContentHeight)}
                >
                  { this.props.children }
                </Content>
              </Wrapper>
            );
          }
        }
      </Motion>
    );
  }
}

export default Overlay;
