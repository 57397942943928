import React from 'react';
import { observer } from 'mobx-react';
import Navigation from 'components/navigation';
import { last } from 'lodash';

@observer
class Concept extends React.Component {

  render() {
    return (
      <div className='concept'>
        <Navigation
          currentRoute={last(this.props.routes).name}
          smallButtons
          links={[
            {
              link: 'builder.concepts.index',
              title: 'Concepts',
            },
            {
              link: 'builder.concept_categories.index',
              title: 'Categories',
            },
          ]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default Concept;
