import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import SubscriptionCreate from 'pages/builder/subscriptions/subscription_create';
import DeleteButton from 'components/button/delete_button';
import Filter from 'components/backend_filter/filter';
import { MembershipStore, UserSubscriptionStore, SiteStore, SubscriptionStore } from '@seedlang/stores';
import Modal from 'components/modal';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import { sortBy } from 'lodash';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';
import CheckIfTrue from 'components/check_if_true';
import InfoTooltip from 'components/info_tooltip';

const IconWrapper = styled.span`
  .fa-file-text {
    color: #333;
    cursor: pointer;
    margin-right: 4px;
  }
`;

@observer
class SubscriptionIndex extends React.Component {
  @observable showSpinner = false;

  static defaultProps = {
    whiteLabeled: false,
  }

  @observable cancellationMessage;

  @computed get subscriptions() {
    return this.props.userId ? UserSubscriptionStore.indexData : SubscriptionStore.indexData;
  }

  @computed get store() {
    return this.props.userId ? UserSubscriptionStore : SubscriptionStore;
  }

  constructor(props) {
    super(props);
    this.getSubscriptions();
    SiteStore.getIndex();
    MembershipStore.setLimit(999);
    MembershipStore.setFilters({active: true, title: '!0'});
    MembershipStore.getIndex();
  }

  @autobind getSubscriptions() {
    if (this.props.userId) {
      UserSubscriptionStore.getIndex({queryStrings: {user_id: this.props.userId}});
    } else if (this.props.from) {
      this.showSpinner = true;
      SubscriptionStore.setLimit(999);
      SubscriptionStore.setFilter('current_period_start', `RANGE[${this.props.from},${this.props.to}]`);
      SubscriptionStore.setFilter('target_language_id', this.props.targetLanguageId);
      SubscriptionStore.getIndex({}, this.afterGetIndex);
    }
  }

  @autobind afterDelete() {
    if (this.props.userId) {
      this.getSubscriptions();
    } else {
      SubscriptionStore.reloadIndexFiltered();
    }
  }

  @autobind onDelete(id) {
    SubscriptionStore.destroy({ids: {subscriptionId: id}}, this.afterDelete);
  }

  @autobind afterGetIndex(resp) {
    SubscriptionStore.setIndexData(resp);
    this.showSpinner = false;
  }

  componentWillUnmount() {
    SubscriptionStore.clearFilter();
    SubscriptionStore.setPage(1);
  }

  render() {
    return (
      <div className='subscription-index' style={{paddingBottom: this.props.from ? '40px' : '0px'}}>
        {
          this.showSpinner &&
            <Spinner />
        }
        {
          this.cancellationMessage &&
            <Modal
              onCloseModal={() => this.cancellationMessage = null}
              marginTop={200}
            >
              {this.cancellationMessage}
            </Modal>
        }
        {
          this.props.userId &&
            <SubscriptionCreate
              userId={this.props.userId}
              afterCreate={this.getSubscriptions}
            />
        }
        {
          !this.props.userId && MembershipStore.hasIndexData && !this.props.hideFilters &&
            <Filter
                store={SubscriptionStore}
                defaultSort='-current_period_start'
                namespace='subscription-index'
                queryStrings={{include_count: true}}
                filters={
                  [
                    {
                      type: 'select',
                      name: 'target_language_id',
                      options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                      labelField: 'name',
                      label: 'Language',
                      default: '',
                    },
                    {
                      type: 'text',
                      name: 'transaction_id',
                      placeholder: 'Transaction ID',
                      label: 'Transaction ID',
                      default: '',
                    },
                    {
                      type: 'date',
                      name: 'current_period_start',
                      label: 'Start',
                      default: '',
                    },
                    {
                      type: 'date',
                      name: 'current_period_end',
                      label: 'End',
                      default: '',
                    },
                    {
                      type: 'multi_select',
                      name: 'currency',
                      label: 'Currency',
                      options: [{id: 'USD', name: 'USD'}, {id: 'EUR', name: 'EUR'}],
                    },
                    {
                      type: 'multi_select',
                      name: 'payment_method',
                      label: 'Payment Method',
                      options: [{id: 'paypal', name: 'paypal'}, {id: 'stripe', name: 'stripe'}, {id: 'ios', name: 'ios'}, {id: 'android', name: 'android'}],
                    },
                    {
                      type: 'select',
                      name: 'membership_id',
                      label: 'Membership',
                      labelField: 'name',
                      options: sortBy(MembershipStore.indexData, ['membershipGroupName', 'title', 'numberMonths']).map(item => {
                        return {id: item.id, name: `${item.membershipGroupName} - ${item.title}`};
                      }),
                    },
                    {
                      type: 'boolean',
                      name: 'cancel_at_period_end',
                      label: 'Cancel at Period End',
                    },
                    {
                      type: 'boolean',
                      name: 'cancellation_message',
                      label: 'Cancellation Message',
                    },
                    {
                      type: 'boolean',
                      name: 'refunded',
                      label: 'Refunded',
                    },
                    {
                      type: 'boolean',
                      name: 'pending',
                      label: 'Pending',
                    },
                    {
                      type: 'number',
                      name: 'payments_count',
                      label: '# Payments',
                    },
                    {
                      type: 'number',
                      name: 'amount_usd',
                      label: 'Amount USD',
                    },
                  ]
                }
            />
        }
        {
          !this.showSpinner &&
            <table className='table-wrapper admin-table'>
              <thead>
                <tr>
                  <th />
                  <th>Purchased</th>
                  {
                    !this.props.userId &&
                      <th>Users</th>
                  }
                  <th>Membership</th>
                  <th>Amount</th>
                  {
                    !this.props.from &&
                      <th
                        width='100'
                        className={cx('sortable', this.props.columnClassNames('current_period_start'))}
                        onClick={this.props.onSortColumn.bind(this, 'current_period_start')}
                      >
                        Start/End
                      </th>
                  }
                  {
                    !this.props.from &&
                      <th>
                        Cancelled
                        <InfoTooltip>
                          <div>The subscription will be cancelled at the end of the current period.</div>
                          <div>If you want to cancel for a user you can do so from their subscription page.</div>
                        </InfoTooltip>
                      </th>
                  }
                  {
                    AppUI.siteIsDefault &&
                      <th>Type</th>
                  }
                  {
                    !this.props.from &&
                      <th>Refunded <InfoTooltip>Used for display puprposes and analytics.</InfoTooltip></th>
                  }
                  {
                    AppUI.siteIsDefault &&
                      <th>1x</th>
                  }
                  {
                    AppUI.siteIsDefault &&
                      <th>Pnd</th>
                  }
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  this.subscriptions.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={this.props.whiteLabeled ? { name: 'creator.groups.subscriptions.edit', params: { groupId: this.props.params.groupId, subscriptionId: item.id } } : { name: 'builder.subscriptions.edit', params: { subscriptionId: item.id } }}
                            className='on-click'
                          >
                            <i className='fa fa-chevron-right fa-block' />
                          </Link>
                        </td>
                        <td>
                          <div>{item.createdAt.formattedDateWithYear}</div>
                          <div
                            className='small'
                          >
                            {item.createdAt.formatted.split(',')[1]}
                          </div>
                        </td>
                        {
                          !this.props.userId &&
                            <td>
                              {
                                item.users.map(user => {
                                  return (
                                    <div
                                      key={item.id}
                                      className='text'
                                    >
                                      <Link
                                        to={{name: AppUI.siteIsDefault ? 'builder.users.edit' : 'creator.groups.users.edit', params: {groupId: this.props.params.groupId, userId: user.id}}}
                                      >
                                        <div>
                                          {user.name}{AppUI.siteIsDefault ? ` (${item.targetLanguageId})` : ''}
                                        </div>
                                        {
                                          AppUI.siteIsDefault &&
                                            <div
                                              className='small'
                                            >
                                              Created {item.relativeCreationDateOfUser}
                                            </div>
                                        }
                                      </Link>
                                    </div>
                                  );
                                })
                              }
                            </td>
                        }
                        <td>
                          <div>{item.membershipGroupName}</div>
                          <div className='small'>{item.membership.title}</div>
                        </td>
                        <td>
                          <div>{item.amountUsd ? item.amountUsdFormatted : item.amountFormatted} ({item.paymentsCount})</div>
                        </td>
                        {
                          !this.props.from &&
                            <td>
                              <div className='text'>
                                {item.currentPeriodStart.formattedDateWithYear}
                              </div>
                              <div className='text'>
                                {item.currentPeriodEnd?.dateObj?.getFullYear() > 2100 ? '-' : item.currentPeriodEnd.formattedDateWithYear}
                              </div>
                            </td>
                        }
                        {
                          !this.props.from &&
                            <td>
                              <div className='text'>
                                <CheckIfTrue
                                  value={item.cancelAtPeriodEnd}
                                />
                                {item.cancelledAt && item.cancelledAt.formattedDateWithYear}
                              </div>
                              <div>{item.cancellationMessage && <IconWrapper onClick={() => this.cancellationMessage = item.cancellationMessage}><i className='fa fa-file-text' /></IconWrapper>}{item.cancellationReason}</div>
                            </td>
                        }
                        {
                          AppUI.siteIsDefault &&
                          <td>
                              <div>{item.paymentMethod}</div>
                            </td>
                        }
                        {
                          !this.props.from &&
                            <td>
                              <div className='text'>
                                <InPlaceCheckbox
                                  model='subscriptions'
                                  field='refunded'
                                  id={item.id}
                                  value={item.refunded}
                                  icon='check'
                                />
                              </div>
                            </td>
                        }
                        {
                          AppUI.siteIsDefault &&
                          <td>
                          <div className='text'>
                            <InPlaceCheckbox
                              model='subscriptions'
                              field='one_time_payment'
                                id={item.id}
                                value={item.oneTimePayment}
                                icon='check'
                            />
                            </div>
                          </td>
                        }
                        {
                          AppUI.siteIsDefault &&
                          <td>
                            <div className='text'>
                              <InPlaceCheckbox
                                model='subscriptions'
                                field='pending'
                                id={item.id}
                                value={item.pending}
                                icon='check'
                              />
                            </div>
                          </td>
                        }
                        <td>
                          <DeleteButton
                            onConfirm={() => this.onDelete(item.id)}
                            message='Delete this subscription?'
                            className='fa-block right'
                          />
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
        }
        {
          !this.showSpinner && !this.props.from &&
            <Paginator
              store={this.store}
            />
        }
      </div>
    );
  }
}

export default SortableColumns(SubscriptionIndex, SubscriptionStore, { sortColumn: '-current_period_start', sortIsAscending: true });
