import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  flex: 1;
  height: 20px;
  display: flex;
  align-items: ${props => props.alignItems};
  justify-content: center;
`;

const ProgressBackground = styled.div`
  cursor: pointer;
  width: 100%;
  position: relative;
  transition: all 0.2s ease;
  height: 8px;
  border-radius: 10px;
  background: #CCC;
`;

const Bar = styled.div`
  background: ${props => props.background};
  width: ${props => props.width};
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  pointer-events: none;
`;

@observer
class AudioPlayerProgress extends React.Component {
  @observable hoverPercentage = 0;
  @observable mousedOver = false;

  static defaultProps = {
    alignItems: 'center',
  }

  componentWillUnmount() {
    this.reset();
  }

  progressPosition() {
    let position;
    if (this.refs.progressBar) {
      position = (this.refs.progressBar.offsetWidth) * (this.props.currentTime / this.props.duration);
    }
    return isNaN(position) || !position ? 0 : position;
  }

  @autobind getHoverPercentage(e) {
    this.hoverPercentage = ((e.pageX - this.refs.progressBar.getBoundingClientRect().left) / this.refs.progressBar.offsetWidth) * 100;
  }

  @autobind onMouseOver(e) {
    this.mousedOver = true;
    window.addEventListener('mousemove', this.getHoverPercentage, true);
  }

  @autobind reset() {
    this.hoverPercentage = 0;
    this.mousedOver = false;
    window.removeEventListener('mousemove', this.getHoverPercentage, true);
  }

  @autobind onClick() {
    if (this.props.onClick) {
      this.props.onClick(this.hoverPercentage);
    }
  }

  render() {
    return (
      <Wrapper
        onMouseOver={this.onMouseOver}
        onMouseOut={this.reset}
        onClick={this.onClick}
        alignItems={this.props.alignItems}
      >
        <div
          ref='progressBar'
          style={{display: 'inline-block', width: '100%'}}
        >
          <ProgressBackground>
            <Bar
              background={Theme.blue}
              width={pixify(this.progressPosition())}
            />
            <Bar
              background='#616161'
              width={`${this.hoverPercentage}%`}
            />
          </ProgressBackground>
        </div>
      </Wrapper>
    );
  }
}

export default AudioPlayerProgress;
