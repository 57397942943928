import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { OnboardingRegistrationWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import AuthenticateRegister from 'components/authenticate/authenticate_register';
import AuthenticateSignin from 'components/authenticate/authenticate_signin';
import AuthenticateForgotPassword from 'components/authenticate/authenticate_forgot_password';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';
import { CloseIcon } from '@seedlang/icons';
import Spinner from 'components/spinner';
import { isPresent } from '@seedlang/utils';
import PatreonConfirmation from 'components/user/patreon_confirmation';
import { Theme } from '@seedlang/constants';
import OnboardingIllustration from 'components/onboarding/onboarding_illustration';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0;
  position: relative;
  .patreon-logo {
    border-radius: 4px;
    width: 17px;
    margin-right: 5px;
    margin-top: 3px;
  }
  .fa-warning {
    color: #333!important;
  }
  .facebook-button {
    margin: 0 0 10px 0!important;
  }
  button {
    width: 100%;
  }
  label {
    font-size: 14px;
    margin-bottom: 0;
  }
  .form-wrapper {
    padding: 0;
  }
`;

const Content = styled.div`
  ${flexCenterColumn()}
  width: 100%;
`;

const TextLink = styled.div`
  cursor: pointer;
  font-size: 14px;
  text-decoration: underline;
  margin: ${props => props.margin};
  a {
    color: ${props => props.color};
  }
  color: ${props => props.color};
`;

const AuthLinkWrapper = styled.div`
  margin: ${props => props.margin};
  width: 100%;
  text-align: center;
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: -10px;
  .close-icon {
    width: 30px;
  }
  svg {
    width: 30px;
    fill: #333;
  }
  margin-top: -50px;
`;

const WhitePanel = styled.div`
  padding: 20px;
  background: whitesmoke;
  border-radius: 10px;
  width: 100%;
`;

@observer
class OnboardingSeedlangRegistration extends React.Component {
  @observable submittedRegistration = false;
  @observable authType = this.props.authType;

  static defaultProps = {
    authType: 'registration',
  }

  constructor(props) {
    super(props);
    AppUI.createEvent('onboarding - view sign up', {device: 'web'});
    if (AppUI.routeStore.routeName === 'onboarding.confirm') {
      this.authType = 'confirm';
    } else if (['onboarding.sign_in', 'landing', 'worksheets.index', 'page.show', 'videos.index', 'podcast.index', 'memberships.index'].includes(AppUI.routeStore.routeName)) {
      this.authType = 'signIn';
    } else if (AppUI.routeStore.routeName === 'onboarding.forgot_password') {
      this.authType = 'forgotPassword';
    }
  }

  componentDidMount() {
    if (AppUI.user?.id && !AppUI.user.guest && this.authType === 'signIn') {
      AppUI.routeToHome();
    }
  }

  @computed get groupId() {
    return window.location.href.split('/group/')[1];
  }

  @autobind afterRegistration() {
    this.submittedRegistration = true;
    this.props.afterRegistration();
  }

  @autobind reset() {
    this.submittedRegistration = false;
    this.authType = 'registration';
    AppUI.set('expandedEmailRegistrationFields', false);
  }

  @autobind signIn() {
    this.authType = 'signIn';
  }

  @autobind onClickForgotPassword() {
    this.authType = 'forgotPassword';
  }

  @autobind gotoMemberships() {
    AppUI.routeStore.routeToNamed('memberships.index');
  }

  @autobind signInWithPatreon() {
    this.authType = 'patreon';
  }

  @autobind onConfirmationRequired(unconfirmedDetails) {
    throw new Error('Confirmation required, but it should not be required on this site');
  }

  render() {
    return (
      <Wrapper
        justifyContent='center'
      >
        <OnboardingIllustration
          text={this.authType === 'registration' ? 'Please register with email to save your progress!' : 'Welcome back to Seedlang!'}
          reaction='happy'
          languageId={AppUI.targetLanguageId}
        />
        {
          AppUI.expandedEmailRegistrationFields &&
            <CloseIconWrapper>
              <CloseIcon
                onClick={() => this.reset()}
              />
            </CloseIconWrapper>
        }
        <Content>
          {
            this.props.showSpinner &&
              <Spinner
                background={Theme.blue}
              />
          }
          {
            !this.props.showSpinner && this.authType === 'registration' && false &&
              <Text
                center
                heading='3'
                margin='0 0 10px 0'
                color='#333'
              >
                Do you want to save your progress?
              </Text>
          }
          {
            !this.props.showSpinner && this.authType === 'patreon' &&
              <WhitePanel>
                <PatreonConfirmation onboarding />
              </WhitePanel>
          }
          {
            !this.props.showSpinner && this.authType === 'registration' &&
              <AuthenticateRegister
                hideSpinner
                hidePrivacy
                onboarding
                padding={0}
                expandEmail
                hideTitle={isPresent(this.props.params.languageId)}
                onClickSignIn={this.signIn}
                afterRegistration={this.afterRegistration}
                languageId={this.props.languageId}
                targetLanguageId={this.props.params?.languageId || this.props.targetLanguageId}
                levelId={this.props.levelId}
                onConfirmationRequired={this.onConfirmationRequired}
                onSigninLinkClick={this.signIn}
              />
          }
          {
            !this.props.showSpinner && this.authType === 'forgotPassword' &&
              <AuthenticateForgotPassword
                hideSpinner
                onboarding
                padding='0px 0px 20px 0px'
                show={this.props.show}
              />
          }
          {
            !this.props.showSpinner && this.authType === 'signIn' &&
              <AuthenticateSignin
                hideSpinner
                onboarding
                padding='0px 0px 20px 0px'
                show={this.props.show}
                onConfirmationRequired={this.onConfirmationRequired}
              />
          }
          {
            this.props.targetLanguageId && !this.groupId && !this.props.showSpinner && !this.props.hideGuestSignIn && this.authType === 'registration' && !this.submittedRegistration && !AppUI.registrationSubmitted && !AppUI.expandedEmailRegistrationFields && !AppUI.userIsLoaded &&
              <Button
                height='50px'
                borderRadius='50px'
                background='#8D8D8D'
                margin='10px 0 0 0'
                scaleOnHover={1.05}
                backgroundOnHover='#9d9d9d'
                onClick={this.props.onSkipRegistration}
              >
                Skip For Now
              </Button>
          }
          {
            !this.props.showSpinner &&
              <AuthLinkWrapper margin='0 0 0 0'>
                {
                  this.authType === 'signIn' &&
                    <TextLink
                      onClick={this.reset}
                      color='#333'
                    >
                      Register for a New Account
                    </TextLink>
                }
                {
                  ['signIn', 'patreon'].indexOf(this.authType) !== -1 &&
                    <TextLink
                      onClick={this.onClickForgotPassword}
                      color='#333'
                    >
                      Forgot Password?
                    </TextLink>
                }
                {
                  ['forgotPassword', 'patreon'].indexOf(this.authType) !== -1 &&
                    <TextLink
                      onClick={this.signIn}
                      color='#333'
                    >
                      Sign In to {AppUI.user.site.name}
                    </TextLink>
                }
                {
                  this.authType === 'registration' &&
                    <TextLink
                      onClick={this.signIn}
                      color='#333'
                    >
                      Already have an account?
                    </TextLink>
                }
              </AuthLinkWrapper>
          }
        </Content>
      </Wrapper>
    );
  }
}

export default OnboardingRegistrationWrapper(OnboardingSeedlangRegistration);
