import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import InPlaceSelect from 'components/form/in_place_select';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { Constants } from '@seedlang/constants';

@observer
class RegisterSelect extends React.Component {

  @autobind setRegister(word, val) {
    this.props.word.set('register', val);
  }

  @computed get registerOptions() {
    const registerOptions = Constants.REGISTER_OPTIONS;
    if (this.props.word.languageId === 'DE') {
      registerOptions.push(['austrian', 'Austrian']);
      registerOptions.push(['swiss', 'Swiss']);
    } return registerOptions;
  }

  render() {
    if (isPresent (this.props.word)) {
      return (
        <InPlaceSelect
          clickToEdit
          options={this.registerOptions}
          model='words'
          field='register'
          includeBlank
          id={this.props.word.id}
          value={this.props.word.register}
          afterChange={val => this.setRegister(this.props.word, val)}
        />
      );
    }
    return <div />;
  }
}

export default ErrorBoundary(RegisterSelect);
