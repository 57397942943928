import React from 'react';
import { observer } from 'mobx-react';
import { ExerciseTypeStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import ExerciseTypeCreate from 'pages/creator/exercise_types/exercise_type_create';
import DeleteButton from 'components/button/delete_button';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { computed, observable } from 'mobx';
import TagEdit from 'pages/creator/tags/tag_edit';
import Modal from 'components/modal';
import { isPresent } from '@seedlang/utils/src';
import styled from '@emotion/styled';

const Description = styled.div`
  width: 100%;
  cursor: pointer;
`;

@observer
class ExerciseTypeIndex extends React.Component {

  @observable descriptionModalId;

  componentDidMount() {
    ExerciseTypeStore.getIndex({sort: 'name'});
  }

  @autobind onDelete(id) {
    ExerciseTypeStore.destroy({ids: {exerciseTypeId: id}});
  }

  @autobind toggleDescriptionModal(exerciseTypeId) {
    if (this.descriptionModalId === exerciseTypeId) {
      this.descriptionModalId = null;
    } else {
      this.descriptionModalId = exerciseTypeId;
    }
  }

  @computed get descriptionModalExerciseType() {
    return ExerciseTypeStore.hasIndexData && ExerciseTypeStore.indexData.find(item => item.id === this.descriptionModalId);
  }

  render() {
    return (
      <div className='exercise-type-index'>
        {
          isPresent(this.descriptionModalId) &&
            <Modal
              onCloseModal={() => this.toggleDescriptionModal(this.descriptionModalId)}
              gradient
              title={`Edit the description of ${this.descriptionModalExerciseType.name} Exercise Type`}
            >
              <InPlaceText
                inputType='textarea'
                id={this.descriptionModalExerciseType.id}
                defaultValue={this.descriptionModalExerciseType.description}
                field='description'
                model='exercise_types'
              />
            </Modal>
        }
        <section style={{marginBottom: 10}}>
          <ExerciseTypeCreate
            onCreate={() => ExerciseTypeStore.getIndex()}
          />
        </section>
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Slug</th>
              <th>Description</th>
              <th>Evaluate Entire Sentence</th>
              <th>Allow Extra Words</th>
              <th>Hide Target</th>
              <th>Hide Source</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              ExerciseTypeStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='text'>
                        <InPlaceText
                          id={item.id}
                          defaultValue={item.name}
                          field='name'
                          model='exercise_types'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceText
                          id={item.id}
                          defaultValue={item.slug}
                          field='slug'
                          model='exercise_types'
                        />
                      </div>
                    </td>
                    <td>
                      <Description
                        onClick={() => this.toggleDescriptionModal(item.id)}
                      >
                        {`${item.description.substring(0, 20) }...`}
                      </Description>
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceCheckbox
                          id={item.id}
                          value={item.evaluateEntireSentence}
                          field='evaluate_entire_sentence'
                          model='exercise_types'
                          icon='check'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceCheckbox
                          id={item.id}
                          value={item.allowExtraWords}
                          field='allow_extra_words'
                          model='exercise_types'
                          icon='check'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceCheckbox
                          id={item.id}
                          value={item.hideTarget}
                          field='hide_target'
                          model='exercise_types'
                          icon='check'
                        />
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        <InPlaceCheckbox
                          id={item.id}
                          value={item.hideSource}
                          field='hide_source'
                          model='exercise_types'
                          icon='check'
                        />
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this exercise type?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default ExerciseTypeIndex;
