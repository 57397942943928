import React from 'react';
import { BrokenIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import {AppUI} from '@seedlang/state';

const Wrapper = styled.div`
  margin: 5px;
  padding: 5px;
  border: 2px dotted red;
  text-align: center;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  line-height: 14px;
  padding: 5px;
  .broken-icon {
    margin-bottom: 5px;
    svg {
      width: 25px;
    }
  }
`;

const ErrorBoundary = (Component, params = {}) => class extends React.Component { // eslint-disable-line react/display-name

  constructor(props) {
    super(props);
    this.state = { errorCaught: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ errorCaught: true });
    // Sentry.captureException(error, { extra: errorInfo });
  }

  _content() {
    if (this.state.errorCaught) {
      return (
        <Wrapper>
          <BrokenIcon />
          {
            !params.hideText &&
              <span>
                {AppUI.getErrorMessage()}
              </span>
          }
        </Wrapper>
      );
    }
    return <Component {...this.props} />;
  }

  render() {
    return this._content();
  }
};

ErrorBoundary.displayName = 'ErrorBoundary';
export default ErrorBoundary;
