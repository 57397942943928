import React from 'react';
import { observer } from 'mobx-react';
import { TreeModuleStore , TreeStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import TreeModuleCreate from 'pages/builder/tree_modules/tree_module_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { Constants, Languages } from '@seedlang/constants';
import { isBlank } from '@seedlang/utils';

@observer
class TreeEdit extends React.Component {

  componentDidMount() {
    this.loadTree();
  }

  componentWillUnmount() {
    TreeStore.clearShowData();
  }

  @autobind onDelete(id) {
    TreeModuleStore.destroy({ids: {treeModuleId: id}}, this.loadTree);
  }

  @autobind loadTree() {
    TreeStore.getShow({ids: {treeId: this.props.params.treeId}}, this.afterTreeGetShow);
  }

  @autobind afterTreeGetShow(resp) {
    TreeStore.setShowData(resp);
  }

  render() {
    return (
      <div
        className='tree-edit'
        style={{marginTop: 10}}
      >
        <div className='breadcrumbs-wrapper'>
          <div className='breadcrumbs'>
            <Link
              to={{name: 'builder.trees.index'}}
            >
              Trees
            </Link>
            <i className='fa fa-angle-double-right' />
            <div className='current'>
              {TreeStore.showData.name}
            </div>
          </div>
        </div>
        {
          TreeStore.hasShowData &&
            <div className='fields'>
              <div className='row'>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Tree Name</legend>
                    <InPlaceText
                      defaultValue={TreeStore.showDataField('name')}
                      model='trees'
                      field='name'
                      id={TreeStore.showDataField('id')}
                      afterChange={this.loadTree}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Slug</legend>
                    <InPlaceText
                      defaultValue={TreeStore.showDataField('slug')}
                      model='trees'
                      field='slug'
                      id={TreeStore.showDataField('id')}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Language</legend>
                    {
                      isBlank(TreeStore.showDataField('languageId')) &&
                        <InPlaceSelect
                          includeBlank
                          value={TreeStore.showDataField('languageId')}
                          model='trees'
                          field='language_id'
                          id={TreeStore.showDataField('id')}
                          options={Constants.LANGUAGE_SELECT_OPTIONS}
                          afterChange={this.loadTree}
                        />
                    }
                    {
                      !isBlank(TreeStore.showDataField('languageId')) &&
                        <div style={{marginTop: 5, fontSize: 16}}>
                          {Languages[TreeStore.showDataField('languageId')]}
                        </div>
                    }

                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Grammar tree</legend>
                    <InPlaceCheckbox
                      id={TreeStore.showDataField('id')}
                      value={TreeStore.showDataField('grammar')}
                      model='trees'
                      field='grammar'
                      icon='check'
                    >
                      Is Grammar Tree
                    </InPlaceCheckbox>
                  </fieldset>
                </div>
              </div>
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Modules</legend>
              <TreeModuleCreate
                treeId={this.props.params.treeId}
                onChange={this.loadTree}
              />
              <table className='table-wrapper admin-table'>
                <thead>
                  <tr>
                    <th width='20' />
                    <th width='40'>Position</th>
                    <th>Name</th>
                    <th># Nodes</th>
                    <th>Web Published</th>
                    <th>Mobile Published</th>
                    <th width='40'></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    TreeStore.hasShowData && TreeStore.showData.treeModules.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <Link
                              to={{ name: 'builder.tree_modules.edit', params: { treeId: this.props.params.treeId, treeModuleId: item.id } }}
                              className='on-click'
                            >
                              <i className='fa fa-chevron-right fa-block' />
                            </Link>
                          </td>
                          <td>
                            <InPlaceText
                              model='tree_modules'
                              field='position'
                              defaultValue={item.position}
                              id={item.id}
                              type='number'
                              step='1'
                              afterChange={this.loadTree}
                            />
                          </td>
                          <td>
                            <div className='text'>
                              {item.name}
                            </div>
                          </td>
                          <td>
                            <div className='text'>
                              {item.treeNodesCount}
                            </div>
                          </td>
                          <td>
                            <InPlaceCheckbox
                              id={item.id}
                              value={item.webPublished}
                              model='tree_modules'
                              field='web_published'
                              icon='check'
                            />
                          </td>
                          <td>
                            <InPlaceCheckbox
                              id={item.id}
                              value={item.mobilePublished}
                              model='tree_modules'
                              field='mobile_published'
                              icon='check'
                            />
                          </td>
                          <td>
                            <DeleteButton
                              onConfirm={() => this.onDelete(item.id)}
                              message='Delete this module?'
                              className='fa-block right'
                            />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
              <Paginator
                store={TreeModuleStore}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default TreeEdit;
