import React from 'react';
import { observer } from 'mobx-react';
import RatingTypeCreate from 'pages/builder/rating_types/rating_type_create';
import { RatingTypeStore } from '@seedlang/stores';
import { Link } from 'react-router';
import DeleteButton from 'components/button/delete_button';
import CheckIfTrue from 'components/check_if_true';

@observer
class RatingTypeIndex extends React.Component {

  constructor(props) {
    super(props);
    RatingTypeStore.getIndex();
  }

  onDelete(id) {

  }

  render() {
    return (
      <div className='rating-type-index'>
        <RatingTypeCreate />
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th width='20' />
              <th>Name</th>
              <th>Slug</th>
              <th>Overall</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              RatingTypeStore.indexData.map(item => {
                return (
                  <tr
                    key={item.id}
                  >
                    <td>
                      <Link
                        to={{ name: 'builder.rating_types.edit', params: { ratingTypeId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.slug}
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.overall}
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this rating type?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default RatingTypeIndex;
