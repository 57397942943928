import React from 'react';
import { observer } from 'mobx-react';
import { Theme } from '@seedlang/constants';
import styled from '@emotion/styled';
import ErrorBoundary from 'components/hoc/error_boundary.js';

const HintWrapper = styled.div`
  background: ${props => props.background};
  color: ${props => props.color};
  padding: ${props => props.padding};
  border-radius: ${props => props.borderRadius};
  margin-top: ${props => props.marginTop};
  visibility: ${props => props.visibility};
  min-height: 50px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

@observer
class HintWarning extends React.Component {

    static defaultProps = {
        background: Theme.orange,
        color: 'white',
        padding: '15px',
        borderRadius: '5px',
        marginTop: '0px',
    }

    render() {
        return(
            <HintWrapper
                {...this.props}
                style={this.props.style}
                visibility={this.props.visibility}
            >
                {this.props.children}
            </HintWrapper>
        );
    }
}

export default ErrorBoundary(HintWarning);