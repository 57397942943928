import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { AppUI } from '@seedlang/state';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Leaderboard from 'components/leaderboard/leaderboard';
import BadgeCollection from 'components/badge_collection';
import { Theme } from '@seedlang/constants';
import Module from 'components/module';
import { formatNumber } from '@seedlang/utils';
import PieChartWithLegend from 'components/visualization/pie_chart_with_legend';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  margin: 0;
  width: 100%;
`;

@observer
class StoriesProgress extends React.Component {
  @observable showXpBreakdown = false;

  @computed get pieChartData() {
    const unseenCount = this.props.counts.totalWordTypeLevelCount - this.props.counts.learnedCount - this.props.counts.seenCount;
    const ary = [
      {
        id: '1',
        value: this.props.counts.learnedCount,
      },
    ];
    if (this.props.counts.seenCount > 0) {
      ary.push({
        id: '2',
        value: this.props.counts.seenCount,
      });
    }
    if (unseenCount > 0) {
      ary.push({
        id: '3',
        value: unseenCount,
      });
    }
    return ary;
  }

  render() {
    return (
      <Wrapper>
        {
          AppUI.userIsLoaded && AppUI.targetLanguage && AppUI.targetLanguage.contentTreeDecksCount > 0 &&
            <Module
              title='Decks Progress'
              padding='10px 0 10px 0'
            >
              <PieChartWithLegend
                id='tree-progress'
                colors={[Theme.green, '#D8D8D8']}
                data={[
                  {y: AppUI.targetLanguage.contentTreeDecksFinishedCount},
                  {y: AppUI.targetLanguage.contentTreeDecksUnfinishedCount},
                ]}
                subText='Of 333'
                width={160}
                margin='0 0 20px 0'
                count={AppUI.targetLanguage.contentTreeDecksFinishedCount}
                subText={`${formatNumber(AppUI.targetLanguage.contentTreeDecksFinishedCount)} of ${formatNumber(AppUI.targetLanguage.contentTreeDecksCount)} Decks Finished`}
                legends={[[Theme.green, 'Finished'], ['#D8D8D8', 'Unfinished']]}
              />
            </Module>
        }
        {
          AppUI.userIsLoaded &&
            <Module
              title='My Badges'
              padding='0 20px 10px 20px'
            >
              <BadgeCollection
                user={AppUI.user}
                badges={['storymaster', 'storyteller', 'interviewMe']}
                showProgress
                width='70px'
                badgeWidth='100%'
              />
            </Module>
        }
        <Module
          title='XP Leaderboard'
          padding='0 10px 10px 10px'
        >
          <Leaderboard
            includeUpdatedAt
            lastWeekColumn='xp_last_week'
            lastMonthColumn='xp_last_month'
            totalColumn='xp'
            rankingFields={{xp: 'xpRankingTotal', xp_last_week: 'xpRankingLastWeek', xp_last_month: 'xpRankingLastMonth'}}
          />
        </Module>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(StoriesProgress);
