import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Legend from 'components/visualization/legend';
import { Theme } from '@seedlang/constants';
import { flexCenter } from '@seedlang/style_mixins';
import VerticalProgressBars from 'components/visualization/vertical_progress_bars';
import { VocabUI ,AppUI} from '@seedlang/state';
import { isBlank } from '@seedlang/utils';

const Wrapper = styled.div`

`;

const KeyWrapper = styled.div`
  ${flexCenter()}
  margin: ${props => props.margin};
`;

@observer
class WordProgress extends React.Component {

  constructor(props) {
    super(props);
    if (isBlank(VocabUI.learnedByLevel)) {
      VocabUI.getLearnedByLevel(AppUI.targetLanguageId);
    }
  }

  render() {
    return (
      <Wrapper>
        <VerticalProgressBars
          data={VocabUI.learnedByLevel}
        />
        <KeyWrapper
          margin='10px 0 0 0'
        >
          <Legend
            iconBackground={Theme.green}
            text='Learned'
          />
          <Legend
            iconBackground={Theme.orange}
            text='Seen'
          />
        </KeyWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordProgress);
