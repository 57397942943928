import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import FeedEntry from 'components/feed/feed_entry';
import { AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import { Link } from 'react-router';

const Wrapper = styled.div`

`;

const LinkWrapper = styled.div`
  margin: 10px 0 20px 0;
  a {
    font-size: 14px;
    font-weight: bold;
  }
`;

@observer
class FeedEntryShow extends React.Component {

  constructor(props) {
    super(props);
    AppUI.feedEntryStore.getShow({ids: {feedEntryId: this.props.params.feedEntryId}});
  }

  render() {
    return (
      <Wrapper>
        <LinkWrapper>
          <Link
            to='profile'
            style={{marginBottom: '10px'}}
          >
            View All Feed Entries
          </Link>
        </LinkWrapper>
        {
          isPresent(AppUI.feedEntryStore.showData) &&
            <FeedEntry
              margin='10px 0 0 0'
              user={AppUI.user}
              feedEntry={AppUI.feedEntryStore.showData}
              width={AppUI.layout.feedVideoWidth}
              feedMinimizedSupplementWidth={AppUI.layout.feedMinimizedSupplementWidth}
            />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FeedEntryShow);
