import React from 'react';
import { observer } from 'mobx-react';
import { ContentLinkWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import ProgressBar from 'components/progress_bar';
import { formatNumber } from '@seedlang/utils';
import { LockIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  align-items: center;
  padding: 20px;
  margin: 10px 0;
  border-radius: 10px;
  background: ${props => props.background};
  color: ${props => props.background === '#FFF' ? 'black' : 'white'};
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 22px;
  display: flex;
  align-items: center;
  text-align: ${props => props.isMobile ? 'center' : 'left'};
  .icon-wrapper {
    svg {
      width: 14px;
      fill: #FFF;
      margin-right: 5px;
    }
  }
`;

const Description = styled.div`
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0 0 0;
  color: ${props => props.background === '#FFF' ? '#3e3e3e' : 'rgba(255, 255, 255, 0.9)'};
  text-align: ${props => props.isMobile ? 'center' : 'left'};
  width: 100%;
`;

const Progress = styled.div`
  position: relative;
  margin: 10px 0 0 0;
  font-size: 12px;
  background: rgba(255, 255, 255, 1);
  padding: 5px;
  border-radius: 10px;
  color: #000;
  width: 100%;
`;

const ProgressText = styled.div`
  font-size: 11px;
  font-style: italic;
  color: white;
  position: absolute;
  top: 7px;
  bottom: 0;
  margin: 0 auto;
  opacity: 0.9;
  width: calc(100% - 10px);
  text-align: center;
  font-weight: bold;
`;

@observer
class ContentLink extends React.Component {

  static defaultProps = {
    background: '#FFF',
  }

  render() {
    return (
      <Link
        onClick={this.props.onClick}
        to={this.props.blockAccess ? 'memberships.index' : this.props.webRoute}
        style={{width: '100%'}}
      >
        <Wrapper
          background={this.props.blockAccess ? '#BCBCBC' : this.props.background}
          isMobile={this.props.isMobile}
        >
          <Content>
            <Title
              isMobile={this.props.isMobile}
            >
              {
                this.props.blockAccess &&
                  <LockIcon />
              }
              {this.props.title}
            </Title>
            <Description
              background={this.props.background}
              isMobile={this.props.isMobile}
            >
              {this.props.description}
            </Description>
            <Progress>
              <ProgressBar
                total={this.props.progressTotal}
                current={this.props.progressCurrent}
                height='25px'
                borderRadius='5px'
              />
              <ProgressText>
                {this.props.progressCurrent} of {formatNumber(this.props.progressTotal)} {this.props.progressText}
              </ProgressText>
            </Progress>
          </Content>
        </Wrapper>
      </Link>
    );
  }
}

export default ContentLinkWrapper(ContentLink);
