import React from 'react';
import { observer } from 'mobx-react';
import { CorrectCardsStatusWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import { flexCenter } from '@seedlang/style_mixins';

const Wrapper = styled.span`
  ${flexCenter()}
  flex: 1;
  padding: 0 10px;
`;

const StatusWrapper = styled.div`
  ${flexCenter()}
  flex: 1;
`;

const IconWrapper = styled.div`
  ${flexCenter()}
  position: relative;
  background: ${props => props.background};
  fontSize: ${props => props.fontSize};
  color: ${props => props.color};
  border-radius: 5px;
  width: ${props => props.width};
  height: ${props => props.width};
  i {
    color: ${props => props.color};
    opacity: 0.8;
    margin: 0;
    font-size: ${props => props.fontSize};
    position: absolute;
    margin: auto;
  }
`;

const Unanswered = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${props => props.color};
`;

@observer
class CorrectCardsStatus extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.userCards.map(item => {
            const showTimesIcon = ((!item.correct && item.seen) || (item.currentIncorrect && !item.correct));
            const showCheckIcon = item.correct;
            return (
              <StatusWrapper
                key={item.id}
              >
                <IconWrapper
                  width={DeckUI.layout.minimumHeight ? '18px' : '20px'}
                  fontSize={DeckUI.layout.minimumHeight ? '11px' : '16px'}
                  background={this.props.background(item)}
                  color={this.props.checkColor(item)}
                >
                  {
                    showCheckIcon &&
                      <i className='fa fa-check' />
                  }
                  {
                    showTimesIcon &&
                      <i className='fa fa-times' />
                  }
                  {
                    !showCheckIcon && !showTimesIcon &&
                      <Unanswered
                        color={this.props.defaultColor}
                      />
                  }
                </IconWrapper>
              </StatusWrapper>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default CorrectCardsStatusWrapper(CorrectCardsStatus);
