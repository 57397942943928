import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import GridItemCreate from 'pages/creator/grid_items/grid_item_create';
import { GridItemStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import { AppUI } from '@seedlang/state';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InfoTooltip from 'components/info_tooltip';
import Spinner from 'components/spinner';
import BackEndExplainer from 'pages/creator/back_end_explainer';

const Wrapper = styled.div`
  table {
    margin-top: 20px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 10px;
`;

@observer
class GridItemIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getGridItems();
  }

  @autobind onDelete(id) {
    GridItemStore.destroy({ids: {gridItemId: id}}, this.getGridItems);
  }

  @autobind getGridItems() {
    GridItemStore.getIndex();
  }

  @autobind toggleUseGridLayout() {
    const newValue = !AppUI.site.useGridLayout;
    AppUI.site.set('useGridLayout', newValue);
    AppUI.siteStore.update({data: {use_grid_layout: newValue}, ids: {siteId: AppUI.site.id}});
  }

  render() {
    return (
      <Wrapper>
        <BackEndExplainer margin='10px 0'>You can set up different sections and then add posts to them. These sections will be displayed on the homepage.</BackEndExplainer>
        <Row>
          <button
            className='gray-button'
            onClick={this.toggleUseGridLayout}
            style={{marginRight: '10px'}}
          >
            {AppUI.site.useGridLayout ? 'Disable' : 'Enable'} Grid Sections
          </button>
          {
            !AppUI.site.useGridLayout &&
              <Link
                to='worksheets.grid_with_sections'
              >
                <button
                  className='gray-button'
                  style={{marginRight: '10px'}}
                >
                  View Sections in Frontend
                </button>
              </Link>
          }
          Grid sections are currently {AppUI.site.useGridLayout ? 'enabled' : 'disabled'} in the frontend.
        </Row>
        <GridItemCreate
          afterCreate={this.getGridItems}
        />
        {
          GridItemStore.requestCounter > 0 &&
          <Spinner background={AppUI.site.accentColor} />
        }
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th>Position</th>
              <th>Name</th>
              <th>Show to Members</th>
              <th>Show to Non-Members</th>
              <th>Posts Count</th>
              <th width='10'></th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              GridItemStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'creator.grid_items.edit', params: { gridItemId: item.id, groupId: this.props.params.groupId } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td style={{width: '100px'}}>
                      <div className='text'>
                        <InPlaceText
                          type='number'
                          field='position'
                          defaultValue={item.position}
                          model='grid_items'
                          id={item.id}
                          afterChange={this.getGridItems}
                        />
                      </div>
                    </td>
                    <td>
                      <div className='text' style={{display: 'flex'}}>
                        {
                          item.isAutomatic &&
                          <InfoTooltip icon='magic' position='top' margin='0 5px 0 0'>
                            <div>This section will automatically be populated</div>
                            <div>{`with ${item.mostRecent ? 'the most recent posts.' : 'posts that haven\'t been added to a grid section'}`}</div>
                          </InfoTooltip>
                        }
                        <InPlaceText
                          field='name'
                          defaultValue={item.name}
                          model='grid_items'
                          id={item.id}
                        />
                      </div>
                    </td>
                    <td>
                      <InPlaceCheckbox
                        id={item.id}
                        value={item.showToMembers}
                        field='show_to_members'
                        model='grid_items'
                        icon={item.showToMembers ? 'eye' : 'eye-slash'}
                        afterChange={this.getGridItems}
                      />
                    </td>
                    <td>
                      <div className='text' style={{display: 'flex', paddingRight: '20px', justifyContent: 'space-between'}}>
                        <InPlaceCheckbox
                          id={item.id}
                          value={item.showToNonMembers}
                          field='show_to_non_members'
                          model='grid_items'
                          icon={item.showToNonMembers ? 'eye' : 'eye-slash'}
                          afterChange={this.getGridItems}
                        />
                      </div>
                    </td>
                    <td>
                      {
                        !item.isAutomatic &&
                          <div className='text'>
                            {item.worksheetsCount}
                          </div>
                      }
                    </td>
                    <td>
                      {
                        item.isHidden &&
                        <InfoTooltip
                          icon='eye-slash'
                          color='#e70000'
                        >
                          No user will see this section
                        </InfoTooltip>
                      }
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this grid item?'
                        className='fa-block right'
                        disabled={item.isAutomatic}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={GridItemStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GridItemIndex);
