import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { ForumUI, AppUI } from '@seedlang/state';
import { isPresent } from '@seedlang/utils';
import { SeedlangIcon } from '@seedlang/icons';
import ForumRow from 'components/forum/forum_row';
import ForumCount from 'components/forum/forum_count';
import styled from '@emotion/styled';
import Text from 'components/text';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Breadcrumbs from 'components/breadcrumbs';

const Wrapper = styled.div`
  .description {
    font-size: 14px;
    line-height: 14px;
    p {
      margin: 3px 0;
    }
  }
`;

const Image = styled.div`
  ${flexCenterColumn()}
  width: 60px;
  height: 60px;
  background-size: cover;
  border-radius: 50%;
  border: 1px solid gainsboro;
  background-image: ${props => props.backgroundImage};
  .seedlang-icon {
    width: 50px;
  }
`;

const TitleWrapper = styled.div`
  flex: 1;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  margin-top: 5px;
  color: #313131;
  line-height: 16px;
  font-size: 12px;
`;

@observer
class ForumIndex extends React.Component {

  constructor(props) {
    super(props);
    ForumUI.forumStore.clearShowData();
    ForumUI.forumThreadStore.clearIndexData();
    ForumUI.forumStore.getIndex();
  }

  componentDidMount() {
    AppUI.createEvent('discuss - view page');
  }

  validGroup(forum) {
    return !forum.hasGroup || (ForumUI.userIsLoaded && ForumUI.user.inGroup(forum.group.id));
  }

  validUser(forum) {
    return ForumUI.userIsLoaded && ((forum.admin && ForumUI.user.admin) || (forum.moderator && ForumUI.user.moderator) || (forum.translator && ForumUI.user.translator) || (!forum.admin && !forum.moderator && !forum.translator));
  }

  @computed get countForAllForums() {
    if (ForumUI.forumStore.hasIndexData) {
      return ForumUI.forumStore.indexData.map(item => item.forumThreadsCount + item.commentsCount).reduce((a, b) => a + b, 0);
    }
    return 0;
  }

  render() {
    return (
      <Wrapper>
        <Breadcrumbs
          breadcrumbs={[{name: 'All Discussions'}]}
          height='50px'
          margin='0 0 10px 0'
        />
        {
          ForumUI.forumStore.indexData.map(item => {
            if (this.validGroup(item) && this.validUser(item)) {
              return (
                <ForumRow
                  key={item.id}
                  onClick={() => ForumUI.routeStore.routeToNamed('forums.show', {page: 1, forumId: item.id})}
                >
                  {
                    item.hasImage &&
                      <Image
                        backgroundImage={`url(${item.image.url})`}
                      />
                  }
                  {
                    !item.hasImage &&
                      <Image>
                        <SeedlangIcon />
                      </Image>
                  }
                  <TitleWrapper>
                    <Text
                      fontSize='18px'
                      lineHeight='18px'
                    >
                      {item.title}
                    </Text>
                    {
                      isPresent(item.description) &&
                        <Description>
                          <div dangerouslySetInnerHTML={{ __html: item.description }} />
                        </Description>
                    }
                  </TitleWrapper>
                  <ForumCount
                    count={item.slug === 'all-forums' ? this.countForAllForums : item.forumThreadsCount + item.commentsCount}
                  />
                </ForumRow>
              );
            } else {
              return <span key={Math.random()} />;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default ForumIndex;
