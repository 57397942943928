import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { GraphOutlineIcon, FaqIcon } from '@seedlang/icons';
import HeaderBar from 'components/header_bar';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { Theme } from '@seedlang/constants';
import { Link} from 'react-router';

const Content = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding};
`;

const Wrapper = styled.div`
  width: 100%;
`;

@observer
class VocabHome extends React.Component {

  @computed get routeName() {
    return this.props.children && this.props.children.props && this.props.children.props.route ? this.props.children.props.route.name : '';
  }

  render() {
    return (
      <Wrapper>
        {
          !AppUI.user.isMember &&
            <PageOnboarding
              hideCloseIcon
              className='tree-onboarding'
              margin={AppUI.layout.isMobile ? '0' : '0 0 10px 0'}
              background={Theme.orange}
              color='#FFF'
              flat
              borderRadius={AppUI.layout.isMobile ? 0 : 5}
              fontSize={AppUI.layout.isMobile ? 13 : 15}
            >
              {
                AppUI.layout.isMobile &&
                  <Link
                    to={{name: 'memberships.index'}}
                    className='underline'
                  >
                    Go Pro to create custom <b>Vocab Trainer</b> decks.
                  </Link>
              }
              {
                !AppUI.layout.isMobile &&
                  <span>
                    <span>Creating unlimited custom <b>Vocab Trainer</b> decks is a </span>
                    <Link
                      to={{name: 'memberships.index'}}
                      className='underline'
                    >
                      Seedlang Pro
                    </Link>
                    <span> feature.</span>
                  </span>
              }
            </PageOnboarding>
        }
        <HeaderBar
          title={`${AppUI.targetLanguageName} VOCAB`}
          homeRoute='vocab.home'
          titleRoute='vocab.home'
          titleParams={{page: AppUI.wordStore.page || 1}}
          pageInfo={[
            {
              route: 'vocab.progress',
              current: 'Progress',
            },
          ]}
          icons={[
            {
              icon: <FaqIcon />,
              tooltipText: 'Help Center',
              iconRoute: 'faq_categories.show',
              iconParams: {faqCategoryId: 'b31dc1e8-07dd-4b39-936f-d5a3238a57d5'},
            },
            {
              icon: <GraphOutlineIcon />,
              tooltipText: 'View Progress',
              iconRoute: 'vocab.progress',
              breadcrumb: 'Progress',
            },
          ]}
        />
        <Content>
          {React.cloneElement(this.props.children, this.props)}
        </Content>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabHome);
