import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

const Wrapper = styled.div`
  padding: 5vmax;
  background: #FFF;
  text-align: center;
  margin-top: 10vh;
  font-size: 16px;
  line-height: 1.25em;
`;

const DEFAULT_ERROR_MESSAGE =
  'Sorry, an error has occurred.\n' +
  'Please contact us at help@seedlang.com if you need any assistance.';

@observer
class Discord extends React.Component {
  @observable stage = null;

  componentDidMount() {
    if (AppUI.user.signedOut) {
      AppUI.authenticateStore.redirectRouteNameAfterLogin = 'discord';
      AppUI.routeStore.routeToNamed('onboarding.sign_in');
    }
    else {
      const { code, state } = this.props.location.query;

      if (!code) {
        this.stage = 'redirecting';
        this.fetchDiscordAuthorizationUrlAndRedirect();
      } else {
        this.stage = 'linking';
        this.createLinkUsingDiscordAuthorizationCode(code, state);
      }
    }
  }

  @autobind fetchDiscordAuthorizationUrlAndRedirect() {
    AppUI.discordUserAssociationStore.create({}, resp => {
      const { discordAuthorizationUrl } = resp;
      if (discordAuthorizationUrl) {
        window.location.href = discordAuthorizationUrl;
      } else {
        console.error('Failed to fetch Discord link', resp);
      }
    });
  }

  @autobind createLinkUsingDiscordAuthorizationCode(code, state) {
    AppUI.discordUserAssociationStore.create({ data: { code, state } }, resp => {
      const { success } = resp;
      if (success) {
        AppUI.routeStore.routeToNamed('page.show', { pageId: 'welcome-to-discord' });
      }
      else {
        this.stage = 'error';
      }
    });
  }

  render() {
    if (this.stage === 'redirecting') {
      return <Wrapper>Redirecting...</Wrapper>;
    }

    if (this.stage === 'linking') {
      return <Wrapper>Linking...</Wrapper>;
    }

    return <Wrapper>{DEFAULT_ERROR_MESSAGE}</Wrapper>;
  }
}

export default ErrorBoundary(Discord);
