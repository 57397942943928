"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = CommaSeparated;
var _lodash = require("lodash");
function CommaSeparated(initialString) {
  var _this = this;
  var list = initialString === '' ? [] : initialString.split(',');
  this.remove = function (item) {
    return list = (0, _lodash.without)(list, item);
  };
  this.add = function (item) {
    return list.push(item);
  };
  this.contains = function (item) {
    return list.indexOf(item) !== -1;
  };
  this.toggle = function (item) {
    return _this.contains(item) ? _this.remove(item) : _this.add(item);
  };
  this.toString = function () {
    return list.join(',');
  };
}