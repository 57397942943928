import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Text from 'components/text';
import TextResizer from 'components/text_resizer';
import { DeckUI } from '@seedlang/state';
import { pixify, isPresent, isBlank } from '@seedlang/utils';
import { Theme } from '@seedlang/constants';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import ReactTooltip from 'react-tooltip';
import WordBreakdown from 'components/word_breakdown';
import Answer from 'components/vocab_card/answer';

const Wrapper = styled.div`
  width: 100%;
  ${flexCenterColumn};
  height: ${props => props.height};
`;

const PrimaryTextWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
`;

const Grammar = styled.div`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  cursor: ${props => props.cursor};
  font-style: italic;
`;

const CorrectAnswer = styled.div`
  position: absolute;
  bottom: 10px;
  background: ${Theme.green};
  border-radius: 10px;
  width: 96%;
  ${flexCenter()}
  overflow: hidden;
  height: 40px;
  i {
    position: absolute;
    left: 6px;
    top: 3px;
    color: white;
  }
`;

const Description = styled.div`
  font-size: 13px;
  margin: ${props => props.margin};
  background: ${Theme.orange};
  color: white;
  padding: 5px 10px;
  border-radius: 10px;
  i {
    color: #FFF;
    font-size: 17px;
    line-height: 19px;
    margin: 0 5px 0 0;
  }
`;

const TooltipWrapper = styled.div`
  margin: ${props => props.margin};
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 12px;
  i {
    margin-right: 3px;
  }
`;

const ConjugatedVerbsWrapper = styled.div`
  border-bottom: 2px solid #CCC;
`;

const IntroText = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  color: #FFF;
  padding: 4px 8px;
  border-radius: 5px;
  background: ${Theme.green};
`;

@observer
class ExpandedTarget extends React.Component {

  static defaultProps = {
    grammarTextSize: 14,
    grammarFontWeight: null,
    grammarColor: '#333',
    color: '#000',
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.showVocabTriadIntro &&
            <IntroText>
              <span role='img' aria-label='lightbulb'>💡</span> In this deck we will teach you...
            </IntroText>
        }
        {
          this.props.prompt &&
          <Text
            italic
            fontSize='16px'
          >
            {this.props.prompt}
          </Text>
        }
        <PrimaryTextWrapper>
          {
            this.props.word &&
              <TextResizer
                height={60}
                width={pixify(DeckUI.layout.deckWidth - 20)}
                minimumFontSize={14}
                resizeText
                characterLength={this.props.word.targetTextWithDefiniteArticle && this.props.word.targetTextWithDefiniteArticle.length}
              >
                <Text
                  bold
                  color={this.props.color}
                >
                  {isBlank(this.props.card?.underlinedCharacters) && (this.props.hideDefiniteArticle ? this.props.word.targetText : this.props.word.targetTextWithDefiniteArticle)}
                  {!isBlank(this.props.card?.underlinedCharacters) && this.props.card.vocabWordComprehendCard &&
                    <WordBreakdown
                      card={this.props.card}
                    />
                  }
                </Text>
              </TextResizer>
          }
        </PrimaryTextWrapper>
        {
          !this.props.hidePlural && this.props.word && this.props.word.isNoun && this.props.word.pluralNoun && !this.props.word.noPlural &&
            <Grammar
              color={this.props.grammarColor}
              fontSize={pixify(DeckUI.layout.minimumHeight ? '13px' : this.props.grammarTextSize)}
              fontWeight={this.props.grammarFontWeight}
            >
              {this.props.word.pluralNounsString}
            </Grammar>
        }
        {
          !this.props.hidePlural && this.props.word && this.props.word.isNoun && this.props.word.noPlural &&
            <Grammar
              color={this.props.grammarColor}
              fontSize={pixify(DeckUI.layout.minimumHeight ? '13px' : this.props.grammarTextSize)}
              fontWeight={this.props.grammarFontWeight}
            >
              No Plural
            </Grammar>
        }
        {
          this.props.word && (this.props.word.isAdjective || this.props.word.isNumber) && this.props.word.genderVariant && this.props.word.genderVariant.targetText !== this.props.word.targetText &&
          <Grammar
            color={this.props.grammarColor}
            fontSize={pixify(DeckUI.layout.minimumHeight ? '13px' : this.props.grammarTextSize)}
            fontWeight={this.props.grammarFontWeight}
          >
            {this.props.word.genderVariant.targetText}
          </Grammar>
        }
        {
          this.props.description && !DeckUI.layout.minimumHeight &&
            <Description
              margin={DeckUI.layout.minimumHeight ? '10px 0 0 0' : '20px 0 0 0'}
            >
              <i className='fa fa-info-circle' />
              {this.props.description}
            </Description>
        }
        {
          this.props.description && DeckUI.layout.minimumHeight &&
            <TooltipWrapper
              margin={DeckUI.layout.minimumHeight ? '10px 0 0 0' : '20px 0 0 0'}
              data-tip
              data-for={this.props.word.id}
            >
              <i className='fa fa-info-circle' /> More Info
              <ReactTooltip
                place='top'
                type='dark'
                effect='solid'
                id={this.props.word.id}
                width='300px'
                class='custom-tooltip'
              >
                <div
                  style={{width: 150}}
                >
                  {this.props.description}
                </div>
              </ReactTooltip>
            </TooltipWrapper>
        }
        {
          this.props.word && this.props.word.isVerb && this.props.card && this.props.card.isEnriched && this.props.word.conjugatedVerbStringsCount > 0 && this.props.word.hasConjugatedVerbStrings &&
            <Grammar
              color={this.props.grammarColor}
              fontSize={pixify(DeckUI.layout.minimumHeight ? '13px' : this.props.grammarTextSize)}
              fontWeight={this.props.grammarFontWeight}
              onClick={() => DeckUI.gotoConjugationTable(this.props.word)}
              cursor='pointer'
            >
              <ConjugatedVerbsWrapper>
                {this.props.word.conjugatedVerbStringsSummary}
              </ConjugatedVerbsWrapper>
            </Grammar>
        }
        {
          this.props.word && this.props.word.isPhoneme && this.props.card && isBlank(this.props.prompt) &&
            <Grammar
              color={this.props.grammarColor}
              fontSize={pixify(DeckUI.layout.minimumHeight ? '13px' : this.props.grammarTextSize)}
              fontWeight={this.props.grammarFontWeight}
            >
              This is how we write down this sound
            </Grammar>
        }
        {
          this.props.word && this.props.word.isGrapheme && this.props.card && isBlank(this.props.prompt) &&
            <Grammar
              color={this.props.grammarColor}
              fontSize={pixify(DeckUI.layout.minimumHeight ? '13px' : this.props.grammarTextSize)}
              fontWeight={this.props.grammarFontWeight}
            >
              {`Listen to the pronunciation of this letter${this.props.word.targetText.length > 1 ? ' combination' : null}`}
            </Grammar>
        }
        {this.props.children}
        {
          this.props.cardState?.revealed && this.props.showWrongAnswer && isPresent(this.props.wrongAnswer) &&
          <Answer
            text={this.props.wrongAnswer}
            marginBottom='10px'
          />
        }
        {
          DeckUI.showCorrectAnswer && isPresent(this.props.cardState.textEntry) &&
          <CorrectAnswer>
            <i className='fa fa-times' />
            <TextResizer
              resizeText
              height={60}
              padding={10}
              maximumFontSize={24}
              width={DeckUI.layout.deckWidth - 40}
              characterLength={this.props.cardState.textEntry && this.props.cardState.textEntry.length}
            >
              <Text
                bold
                color='#FFF'
              >
                {this.props.cardState.textEntry}
              </Text>
            </TextResizer>
          </CorrectAnswer>
        }
      </Wrapper>
    );
  }
}

export default ExpandedTarget;
