import React from 'react';
import { observer } from 'mobx-react';
import { FeedInfoWrapper } from '@seedlang/hoc';
import CardModal from 'components/card/card_modal';
import { Link } from 'react-router';
import Button from 'components/button/button';
import Alert from 'components/alert';
import styled from '@emotion/styled';
import Text from 'components/text';
import { Theme, Constants } from '@seedlang/constants';
import VideoPlayer from 'components/media/video_player';
import {shuffle} from 'lodash';
import { DeckUI, AppUI } from '@seedlang/state';
import StickerReaction from 'components/sticker_reaction';

const Wrapper = styled.div`

`;

const CenterCircle = styled.div`
  background-color: ${Theme.blue};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  position: relative;
  width: 160px;
  height: 160px;
  border: 10px solid #1e3f54;
  overflow: hidden;
  margin-bottom: 20px;
`;

const Block = styled.div`
  margin-bottom: 10px;
`;

@observer
class FeedInfo extends React.Component {

  render() {
    return (
      <Wrapper>
        <CardModal
          scale={this.props.show ? 1 : 0}
          onClose={this.props.onClose}
          width={this.props.width}
          height={this.props.height}
        >
          <Text
            heading='3'
            margin='0 0 10px 0'
          >
            {shuffle(Constants.ENCOURAGEMENTS[DeckUI.deck.languageId || 'DE'])[0][0]}
          </Text>
          <CenterCircle>
            {
              this.props.languageId === 'DE' &&
                <VideoPlayer
                  autoPlay={this.props.show}
                  hideIcon
                  hideOverlay
                  loop={false}
                  videoClip={{mp4Url: '/video/thumbs-up-005.mp4'}}
                  width='140px'
                />
            }
            {
              this.props.languageId !== 'DE' &&
                <StickerReaction
                  width='140px'
                  height='140px'
                  card={this.props.card}
                  reaction='happy'
                  languageId={AppUI.targetLanguageId}
                />
            }
          </CenterCircle>
          <Block>
            <span>You've submitted to your feed, which can be viewed on your </span>
              <Link
                to={{name: 'profile'}}
                className='underline'
              >
                profile page
              </Link>
            <span>.</span>
          </Block>
          {
            this.props.madePrivate &&
              <Alert>
                This is now a private feed entry which only you can view.
              </Alert>
          }
          {
            !this.props.madePrivate &&
              <Button
                onClick={this.props.onMakePrivate}
              >
                Make Feed Entry Private
              </Button>
          }
        </CardModal>
      </Wrapper>
    );
  }
}

export default FeedInfoWrapper(FeedInfo);
