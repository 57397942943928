import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  cursor: pointer;
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  line-height: 13px;
  color: ${props => props.color};
  height: ${props => props.height};
  background: ${props => props.background};
  padding: ${props => props.padding};
  border: ${props => props.border};
  margin: 2px 7px 2px 0;
  user-select: none;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
  a {
    color: #525252;
  }
`;

@observer
class CommentButton extends React.Component {

  static defaultProps = {
    background: '#FFF',
    color: '#525252',
    fontSize: '11px',
    padding: '3px 5px',
    border: '1px solid white',
  }

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
        background={this.props.background}
        color={this.props.color}
        padding={this.props.padding}
        fontSize={this.props.fontSize}
        border={this.props.border}
        height={this.props.height}
        fontWeight={this.props.fontWeight}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(CommentButton);
