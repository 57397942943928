import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import styled from '@emotion/styled';
import PageOnboarding from 'components/onboarding/page_onboarding';
import { DailyActivityWrapper, SettingsStreakRepairWrapper } from '@seedlang/hoc';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import { LightningBoltIcon } from '@seedlang/icons';
import Spinner from 'components/spinner';
import autobind from 'autobind-decorator';
import { autorun } from 'mobx';

const Wrapper = styled.div`
  width: 100%;
`;

const RepairStreakButton = styled.div`
  display: flex;
  align-items: center;
  .lightning-bolt-icon {
    svg {
      width: 12px;
      margin: 0 5px 0 0;
      fill: #FFF;
    }
  }
`;

@observer
class StreakRepairBanner extends React.Component {

  @autobind onClickRepair() {
    this.props.onRepairStreak();
    let calendarLoaded = false;
    autorun(() => {
      if (this.props.showStreakUpdatedMessage && !calendarLoaded) {
        AppUI.authUserDailyXpCountStore.reloadIndexFiltered({ids: {userId: this.props.userId}});
        calendarLoaded = true;
      }
    });
  }

  render() {
    return (
      <Wrapper>
      {
        this.props.showStreakRepairBanner &&
          <PageOnboarding
            uiElement='streak-repair-banner'
            margin='0 0 10px 0'
          >
            {
              !this.props.showStreakUpdatedMessage &&
                <>
                  <Text
                    center
                    bold
                    color='#333'
                    margin='10px 0 10px 0'
                  >
                    <div>We noticed you lost your streak yesterday!</div>
                    <div>You get one streak repair per month, do you want to use it now?</div>
                  </Text>
                  {
                    !this.props.showSpinner &&
                      <Button
                        background={Theme.green}
                        margin='0 0 12px 0'
                        onClick={this.onClickRepair}
                      >
                        <RepairStreakButton>
                          <LightningBoltIcon/>
                          Repair Streak
                        </RepairStreakButton>
                      </Button>
                  }
                </>
            }
            {
              this.props.showSpinner &&
              <Spinner />
            }
            {
              this.props.showStreakUpdatedMessage &&
                <Text
                  center
                  bold
                  color='#333'
                  margin='10px 0 10px 0'
                >
                  <div>Your streak has been repaired!</div>
                  <div>You will have another streak repair available next month.</div>
                </Text>
            }
          </PageOnboarding>
      }
      </Wrapper>
    );
  }
}

export default SettingsStreakRepairWrapper(DailyActivityWrapper(StreakRepairBanner));
