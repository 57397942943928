import React from 'react';
import { observer } from 'mobx-react';
import Navigation from 'components/navigation';
import { last } from 'lodash';
import { LevelStore , WordTypeStore , GrammarCaseStore , GrammarTenseStore } from '@seedlang/stores';
import styled from '@emotion/styled';
import { admin } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  ${admin()}
`;

@observer
class Builder extends React.Component {

  componentDidMount() {
    LevelStore.getIndex();
    WordTypeStore.getIndex();
    GrammarCaseStore.getIndex();
    GrammarTenseStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <div className='builder'>
          <Navigation
            currentRoute={last(this.props.routes).name}
            links={[
              {
                link: 'builder.dashboard.analytics',
                title: 'Dashboard',
              },
              {
                link: 'builder.users.index',
                title: 'Users',
                hide: !AppUI.permissions.canAccessBasicUserData,
              },
              {
                link: 'builder.decks.index',
                title: 'Decks',
                params: {
                  page: 1,
                },
              },
              {
                link: 'builder.sentences.index',
                title: 'Translations',
                match: 'translations',
              },
              {
                link: 'builder.concepts.index',
                title: 'Concepts',
              },
              {
                link: 'builder.campaign_types.index',
                title: 'Outreach',
                hide: !AppUI.permissions.canAccessBasicUserData,
              },
              {
                link: 'builder.subscriptions.index',
                title: 'Subscriptions',
                hide: !AppUI.permissions.canAccessBasicUserData,
              },
              {
                link: 'builder.site.index',
                title: 'Site',
                hide: !AppUI.permissions.canAccessBasicUserData,
              },
            ]}
          />
          {this.props.children}
        </div>
      </Wrapper>
    );
  }
}

export default Builder;
