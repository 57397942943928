import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenterColumn, flexCenter } from '@seedlang/style_mixins';
import VideoPlayer from 'components/media/video_player';
import { pixify } from '@seedlang/utils';
import { DeckUI } from '@seedlang/state';

const Wrapper = styled.div`
  ${flexCenterColumn}
`;

const InnerWrapper = styled.div`
  ${flexCenter}
`;

const VideoPlayerWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: ${props => props.width};
  height: ${props => props.height};
  margin: 0 5px;
`;

@observer
class VideoPlayerWithSupplement extends React.Component {

  render() {
    return (
      <Wrapper>
        <InnerWrapper>
          {
            this.props.hasVideoClip &&
              <VideoPlayerWrapper
                height={pixify(this.props.width)}
                width={pixify(this.props.width)}
              >
                <VideoPlayer
                  maximized={false}
                  circle
                  forcePlay={this.props.forcePlay}
                  autoPlay={this.props.autoPlay}
                  videoClip={this.props.videoClip}
                  width={this.props.width}
                  afterVideoPlayback={this.props.afterVideoPlayback}
                />
              </VideoPlayerWrapper>
          }
          {
            this.props.supplementVideoClip &&
              <VideoPlayerWrapper
                height={pixify(this.props.width)}
                width={pixify(this.props.width)}
              >
                <VideoPlayer
                  maximized={false}
                  muted
                  loop={DeckUI.user.loopSupplement}
                  circle
                  autoPlay={this.props.autoPlay || this.props.autoPlaySupplement}
                  videoClip={this.props.supplementVideoClip}
                  width={this.props.width}
                />
              </VideoPlayerWrapper>
          }
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VideoPlayerWithSupplement);
