import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import cx from 'classnames';

const Wrapper = styled.div`
  width: 100%;
`;

const Navigation = styled.div`
  display: flex;
  width: 100%;
`;

const Center = styled.div`
  flex: 1;
  font-size: 13px;
  text-align: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
`;

const IconWrapper = styled.div`
  cursor: ${props => props.cursor};
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-left: 10px;
    font-size: 29px;
    cursor: pointer;
    color: #a2a2a2;
    &.disabled {
      cursor: default;
    }
  }
`;

@observer
class DailyActivityHeader extends React.Component {

  render() {
    return (
      <Wrapper>
        <Navigation>
          <IconWrapper
            onClick={this.props.decrement}
            cursor='pointer'
          >
            <i
              className='fa fa-angle-left'
            />
          </IconWrapper>
          <Center>
            {this.props.selectedDateString}
          </Center>
          <IconWrapper
            onClick={this.props.increment}
            cursor={this.props.monthIsCurrent ? 'default' : 'pointer'}
          >
            <i
              className={cx('fa', 'fa-angle-right', {
                disabled: this.props.monthIsCurrent,
              })}
            />
          </IconWrapper>
        </Navigation>
      </Wrapper>
    );
  }
}

export default DailyActivityHeader;
