"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = loadScript;
function loadScript(url) {
  return new Promise(function (resolve) {
    var script = document.createElement('script');
    script.src = url;
    script.onload = resolve;
    document.head.appendChild(script);
  });
}