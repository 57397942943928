import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { AppUI, AfterAuth } from '@seedlang/state';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Constants } from '@seedlang/constants';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  margin: ${props => props.margin};
`;

@observer
class PatreonButton extends React.Component {

  @computed get clientId() {
    return AppUI.siteIsDefault ? Constants.EASY_GERMAN_PATREON_CLIENT_ID : AppUI.site.patreonClientId;
  }

  @computed get redirectUrl() {
    if (AppUI.siteIsDefault) {
      return window.location.href && window.location.href.match('seedlang.com') ? 'https://seedlang.com/api/patreon_callbacks/oauth' : 'http://localhost:3000/api/patreon_callbacks/oauth';
    }
    return `https://${AppUI.site.domainString}/api/patreon_callbacks/oauth`;
  }

  @autobind onClick() {
    AfterAuth.set({
      path: '/onboarding/create_password?from_patreon=true',
    });
  }

  render() {
    return (
      <Wrapper margin={this.props.margin}>
        <a
          href={`http://www.patreon.com/oauth2/authorize?response_type=code&client_id=${this.clientId}&redirect_uri=${this.redirectUrl}`}
        >
          <Button
            background={AppUI.siteIsDefault ? null : AppUI.site.accentColor}
            width={this.props.width}
            fontSize={this.props.fontSize}
            height={this.props.height}
            borderRadius={this.props.borderRadius}
            onClick={this.onClick}
          >
            Connect Your Patreon
          </Button>
        </a>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PatreonButton);
