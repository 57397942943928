import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import { isPresent } from '@seedlang/utils/src';

const Wrapper = styled.a`
  width: ${props => props.width};
  margin: ${props => props.margin};
  .fa {
    font-size: 20px;
  }
`;

const CoverWrapper = styled.div`
  height: 100%;
  padding: 5px;
  img {
    width: auto;
    height: 100%;
    border-radius: 5px;
  }
`;

@observer
class PodcastButton extends React.Component {

  static defaultProps = {
    width: '100%',
    margin: '0',
    height: '60px',
    showImage: true,
  }

  render() {
    if (isPresent(this.props.podcastEpisode) && isPresent(this.props.podcastEpisode.interactivePlayerLink)) {
      return (
        <Wrapper
          href={this.props.hasAccess ? this.props.podcastEpisode.interactivePlayerLink : null}
          download='Worksheet'
          target='_blank'
          rel='noopener noreferrer'
          width={this.props.width}
          margin={this.props.margin}
        >
          <Button
            borderRadius='10px'
            width='100%'
            height={this.props.height}
            justifyContent='space-between'
            background='#f1f3f5'
            color='#333'
            padding='0 20px 0 0'
            scaleOnHover={1.03}
            scaleOnActive={0.97}
            fontSize='18px'
            cursor='pointer'
          >
            <CoverWrapper>
              {
                this.props.showImage && isPresent(this.props.podcastEpisode?.podcast?.podcastImage) &&
                <img
                  alt=''
                  src={this.props.podcastEpisode?.podcast?.podcastImage?.image.url}
                />
              }
            </CoverWrapper>
            <div>Interactive Transcript</div>
            <i className='fa fa-external-link'/>
          </Button>
        </Wrapper>
      );
    } else {
      return null;
    }
  }
}

export default ErrorBoundary(PodcastButton);
