import React from 'react';
import { observer } from 'mobx-react';
import { ContactMessageStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import { Link } from 'react-router';
import Paginator from 'components/paginator';
import { isBlank } from '@seedlang/utils/src';

@observer
class ContactMessageIndex extends React.Component {

  static defaultProps = {
    limit: 10,
  }

  componentDidMount() {
    if (this.props.userId) {
      ContactMessageStore.setLimit(this.props.limit);
      ContactMessageStore.setFilter('user_id', this.props.userId, {refresh: true});
    } else {
      ContactMessageStore.getIndex({limit: this.props.limit});
    }
  }

  render() {
    return (
      <div className='contact-message-index'>
        {
          ContactMessageStore.hasIndexData &&
            <table className='table-wrapper admin-table'>
              <thead>
                <tr>
                  {
                    !this.props.whiteLabel &&
                      <th/>
                  }
                  <th>Created At</th>
                  {
                    isBlank(this.props.userId) &&
                      <th>User</th>
                  }
                  <th>Message</th>
                  {
                    this.props.whiteLabel &&
                    <th width='200px'>Contact Category</th>
                  }
                </tr>
              </thead>
              <tbody>
                {
                  ContactMessageStore.indexData.map(item => {
                    return (
                      <tr key={item.id}>
                        {
                          !this.props.whiteLabel &&
                            <td>
                              <Link
                                to={{name: 'builder.contact_messages.show', params: {contactMessageId: item.id}}}
                                className='on-click'
                              >
                                <i className='fa fa-chevron-right fa-block'/>
                              </Link>
                            </td>
                        }
                        <td>
                          {item.createdAt.formatted}
                        </td>
                        {
                          isBlank(this.props.userId) &&
                            <td>
                              {
                                isPresent(item.user) &&
                                <Link
                                  to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                                >
                                  {item.user.email}
                                </Link>
                              }
                            </td>
                        }
                        <td>
                          {item.message}
                        </td>
                        {
                          this.props.whiteLabel &&
                            <td>{isPresent(item.contactCategory) && `${item.contactCategory?.name} (${item.contactCategory?.email})`}</td>
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
        }
        {
          ContactMessageStore.hasIndexData &&
            <Paginator
              store={ContactMessageStore}
            />
        }
        {
          !ContactMessageStore.hasIndexData &&
            <small>None</small>
        }
      </div>
    );
  }
}

export default ContactMessageIndex;
