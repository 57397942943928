import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import DailyActivityCalendarDay from 'components/daily_activity/daily_activity_calendar_day';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { pixify } from '@seedlang/utils';
import Spinner from 'components/spinner';
import moment from 'moment';

const Wrapper = styled.div`
  ${flexCenterColumn}
  margin: 10px 0;
  height: 210px;
`;

const CalendarWrapper = styled.div`
  ${flexCenterColumn()}
  width: ${props => props.width};
`;

const Calendar = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const DayOfWeek = styled.div`
  width: 30px;
  height: 30px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Day = styled.div`
  width: 30px;
  height: 30px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
`;

@observer
class DailyActivityCalendar extends React.Component {

  render() {
    return (
      <Wrapper
        width={pixify(this.props.calendarWidth)}
      >
        {
          this.props.loading &&
            <Spinner />
        }
        {
          !this.props.loading &&
            <CalendarWrapper>
              <Row>
                {
                  ['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((item, index) => {
                    return (
                      <DayOfWeek
                        key={index}
                      >
                        {item}
                      </DayOfWeek>
                    );
                  })
                }
              </Row>
              <Calendar>
              {
                this.props.days.map((weekAry, index) => {
                  return (
                    <Row
                      key={index}
                    >
                      {
                        weekAry.map((dateAry, index2) => {
                          const dailyXpCount = this.props.store.indexData.find(item => item.occurredAt === dateAry[2]) || {};
                          return (
                            <Day
                              key={index2}
                            >
                              <DailyActivityCalendarDay
                                hide={dateAry[0] !== this.props.selectedMonth}
                                dateString={dateAry[3]}
                                month={dateAry[0]}
                                day={dateAry[1]}
                                currentDay={this.props.currentDay}
                                currentMonth={this.props.currentMonth}
                                showTooltip={dateAry[0] === this.props.selectedMonth}
                                dailyXpCount={dailyXpCount}
                                showFlags={this.props.showFlags}
                                xpBreakdowns={this.props.summarizedXpBreakdowns(dailyXpCount)}
                              />
                            </Day>
                          );
                        })
                      }
                    </Row>
                  );
                })
              }
              </Calendar>
            </CalendarWrapper>
        }
      </Wrapper>
    );
  }
}

export default DailyActivityCalendar;
