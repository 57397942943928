import { Constants } from '@seedlang/constants';
import isBlank from './is_blank';

export default function normalizeCharacters(string) {
  if (isBlank(string)) { return; }
  const str = string
    .replace(/ä/g, 'ae').replace(/Ä/g, 'Ae')
    .replace(/ä/g, 'ae').replace(/Ä/g, 'Ae') // different character
    .replace(/ö/g, 'oe').replace(/Ö/g, 'Oe')
    .replace(/ö/g, 'oe').replace(/Ö/g, 'Oe') // different character
    .replace(/ü/g, 'ue').replace(/Ü/g, 'Ue')
    .replace(/ü/g, 'ue').replace(/Ü/g, 'Ue') // different character
    .replace(/œ/g, 'oe').replace(/Œ/g, 'Oe')
    .replace(/ß/g, 'ss')
    .trim()
    .replace('  ', ' ')
    .replace(Constants.SPECIAL_PUNCTUATION.RIGHT_SINGLE_QUOTATION_MARK, Constants.SPECIAL_PUNCTUATION.APOSTROPHE)
    .replace(Constants.SPECIAL_PUNCTUATION.LEFT_SINGLE_QUOTATION_MARK, Constants.SPECIAL_PUNCTUATION.APOSTROPHE)
    .replace(/'\s+/, Constants.SPECIAL_PUNCTUATION.APOSTROPHE)
    .replace(/\(|\)/g, '')
    .replace(new RegExp(`\\s*(\\.{2,4}|${Constants.SPECIAL_PUNCTUATION.ELLIPSIS})\\s*`), ' '); // different versions of " ... "
  return str;
}
