import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { SiteStore, MeetupStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';

const Wrapper = styled.div`

`;

const RSVP = styled.div`
  margin-top: 10px;
  padding: 10px;
  background: #FFF;
  font-size: 14px;
`;

@observer
class MeetupsEdit extends React.Component {
  @observable proRsvpsCount = null;
  @observable translatorRsvpsCount = null;

  constructor(props) {
    super(props);
    this.getSite();
    this.getRsvpCounts();
  }

  @computed get site() {
    return SiteStore.indexData[0];
  }

  @autobind getSite() {
    SiteStore.getIndex();
  }

  @autobind afterRsvpCounts(resp) {
    this.proRsvpsCount = resp.proRsvpsCount;
    this.translatorRsvpsCount = resp.translatorRsvpsCount;
  }

  @autobind clearRsvps(meetup) {
    MeetupStore.clearRsvps({queryStrings: {meetup: meetup}}, this.getRsvpCounts);
  }

  @autobind getRsvpCounts() {
    MeetupStore.rsvpCounts({}, this.afterRsvpCounts);
  }

  render() {
    return (
      <Wrapper>
        {
          SiteStore.hasIndexData &&
            <div>
              <b>Next Pro Meetup</b>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Link</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.nextProMeetupLink}
                      model='sites'
                      field='next_pro_meetup_link'
                    />
                    <small>This link can be access with https://www.seedlang.com/pro-meetup-link</small>
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Date</legend>
                    <InPlaceDatePicker
                      id={this.site.id}
                      model='sites'
                      field='next_pro_meetup_date'
                      value={this.site.nextProMeetupDate}
                      afterChange={this.getSite}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Hour (Berlin)</legend>
                    <InPlaceText
                      id={this.site.id}
                      defaultValue={this.site.nextProMeetupHour}
                      model='sites'
                      field='next_pro_meetup_hour'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>RSVP Confirmation Text</legend>
                    <InPlaceText
                      richText
                      id={this.site.id}
                      defaultValue={this.site.nextProMeetupRsvpText}
                      model='sites'
                      field='next_pro_meetup_rsvp_text'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>RSVPs</legend>

                    {this.proRsvpsCount}
                  </fieldset>
                </div>
                <div className='col-xs-9'>
                  <div style={{margin: '10px 0 0 5px'}}>
                  {
                    this.proRsvpsCount > 0 &&
                      <DeleteButton
                        onConfirm={() => this.clearRsvps('pro')}
                        message='Clear RSVPs?'
                      >
                        <button
                          className='button-primary'
                        >
                          Clear RSVPs
                        </button>
                      </DeleteButton>
                  }
                  </div>
                </div>
              </div>
              <div style={{marginTop: 20}}>
                <b>Next Translator Meetup</b>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>Link</legend>
                      <InPlaceText
                        id={this.site.id}
                        defaultValue={this.site.nextTranslatorMeetupLink}
                        model='sites'
                        field='next_translator_meetup_link'
                      />
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Date</legend>
                      <InPlaceDatePicker
                        id={this.site.id}
                        model='sites'
                        field='next_translator_meetup_date'
                        value={this.site.nextTranslatorMeetupDate}
                        afterChange={this.getSite}
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Hour (Berlin)</legend>
                      <InPlaceText
                        id={this.site.id}
                        defaultValue={this.site.nextTranslatorMeetupHour}
                        model='sites'
                        field='next_translator_meetup_hour'
                      />
                      <small>This link can be access with https://www.seedlang.com/translator-meetup-link</small>
                    </fieldset>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>RSVPs</legend>
                    {this.translatorRsvpsCount}
                  </fieldset>
                </div>
                <div className='col-xs-9'>
                  <div style={{margin: '10px 0 0 5px'}}>
                  {
                    this.translatorRsvpsCount > 0 &&
                      <DeleteButton
                        onConfirm={() => this.clearRsvps('translator')}
                        message='Clear RSVPs?'
                      >
                        <button
                          className='button-primary'
                        >
                          Clear RSVPs
                        </button>
                      </DeleteButton>
                  }
                  </div>
                </div>
              </div>
            </div>
        }
        <RSVP>
          RSVP links like:
          <div>
            * https://www.seedlang.com/meetups/rsvp?meetup=pro&user_id=000016d4-8940-4ad6-b464-cac8fb0a01cb
          </div>
          <div>
            * https://www.seedlang.com/meetups/rsvp?meetup=translator&user_id=000016d4-8940-4ad6-b464-cac8fb0a01cb
          </div>
        </RSVP>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MeetupsEdit);
