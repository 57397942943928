import React from 'react';
import { observer } from 'mobx-react';
import { ChatUI, AppUI } from '@seedlang/state';
import UserInput from 'components/media/user_input';
import ChatMessage from 'components/chat/chat_message';
import { isBlank, pixify } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { SettingsIcon } from '@seedlang/icons';
import XpAnimation from 'components/xp_animation';
import uuid4 from 'uuid/v4';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import DesktopTitle from 'components/desktop_title';

const Wrapper = styled.div`
  position: relative;
  .settings-icon {
    z-index: ${Theme.z['foreground']};
    position: absolute;
    cursor: pointer;
    width: 29px;
    top: 9px;
    right: 10px;
    svg {
      fill: rgba(0, 0, 0, 0.8);
    }
  }
`;

const ChatWrapper = styled.div`
  height: ${props => props.height};
  display: flex;
  flex-direction: column;
  position: relative;
`;

const ChatWindow = styled.div`
  flex: 1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow-x: hidden;
  padding: 10px;
  background: #FFF;
`;

const Notice = styled.div`
  background: white;
  margin: 20px 0;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
`;

const UserInputWrapper = styled.div`
  position: relative;
`;

@observer
class ChatShow extends React.Component {

  constructor(props) {
    super(props);
    ChatUI.initiateChatPoll(this.props.params.chatId);
  }

  componentDidUpdate(prevProps, nextState) {
    if (prevProps.params.chatId !== this.props.params.chatId) {
      ChatUI.initiateChatPoll(this.props.params.chatId);
    }
  }

  componentWillUnmount() {
    this.allowPageScroll();
    if (!ChatUI.layout.isMobile) {
      ChatUI.clearCurrentChat();
    }
  }

  @autobind submitClientSideMessage(params) {
    ChatUI.chatStore.showData.chatMessages.push(Object.assign({}, {
      id: uuid4(),
      user: AppUI.user,
      createdAt: new Date(),
      comments: [],
    }, params));
  }

  @autobind onSubmitMedia() {
    AppUI.createXpAnimation({left: 200, type: 'chat-show', xp: 5, namespace: 'chat-show'});
    this.submitClientSideMessage({audioUrl: 'pending'});
    ChatUI.chatMessageStore.multipartCreate({
      data: ChatUI.mediaStore.getFormData(),
      queryStrings: {
        xp: ChatUI.mediaStore.mediaType === 'audio' ? 3 : 5,
        xp_slug: ChatUI.mediaStore.mediaType === 'audio' ? 'chat-message-audio' : 'chat-message-video',
        chat_id: this.props.params.chatId,
        duration: ChatUI.mediaStore.duration,
      },
    }, ChatUI.chatStore.reloadShow.bind(ChatUI.chatStore));
    ChatUI.mediaStore.onReset();
  }

  @autobind onSubmitMessage(message) {
    AppUI.createXpAnimation({left: 200, type: 'chat-show', xp: 1, namespace: 'chat-show'});
    this.submitClientSideMessage({message: message});
    ChatUI.chatMessageStore.create({data: {
      xp: 1,
      xp_slug: 'chat-message-text',
      message: message,
      chat_id: this.props.params.chatId,
    }}, ChatUI.chatStore.reloadShow.bind(ChatUI.chatStore));
  }

  @autobind onRecordVideo() {
    ChatUI.mediaStore.setupVideo({audio: true, video: true}, this.onRecord);
  }

  @autobind onRecordAudio() {
    ChatUI.mediaStore.setupAudio(this.onRecord);
  }

  @autobind onRecord() {
    ChatUI.mediaStore.onReset();
    ChatUI.mediaStore.startRecording();
  }

  @autobind blockPageScroll() {
    if (!this.props.allowPageScroll) {
      AppUI.set('allowPageScroll', false);
    }
  }

  @autobind allowPageScroll() {
    if (!this.props.allowPageScroll) {
      AppUI.set('allowPageScroll', true);
    }
  }

  render() {
    return (
      <Wrapper>
        <ChatWrapper
          height={this.props.autoHeight ? 'auto' : pixify(ChatUI.layout.chatShowHeight)}
          onMouseOver={this.blockPageScroll}
          onMouseOut={this.allowPageScroll}
        >
          {
            ChatUI.chatCreatedByMe &&
              <SettingsIcon
                onClick={() => AppUI.gotoEditChat(this.props.params.chatId)}
              />
          }
          {
            !ChatUI.layout.isMobile &&
              <DesktopTitle>
                {ChatUI.chatStore.showData.title}
              </DesktopTitle>
          }
          <ChatWindow
            className='chat-window'
          >
            {
              ChatUI.chatStore.hasShowData && isBlank(ChatUI.chatStore.showData.chatMessages) &&
                <Notice>
                  This is the start of your chat.
                </Notice>
            }
            {
              ChatUI.chatStore.hasShowData && ChatUI.chatStore.showData.chatMessages.map(item => {
                return (
                  <ChatMessage
                    key={item.id}
                    chatMessage={item}
                    readOnly={this.props.readOnly}
                    afterUpdate={this.loadChat}
                    submittedByMe={item.user.id === ChatUI.user.id}
                    width={ChatUI.layout.width}
                  />
                );
              })
            }
            <div id='scroll-into-view' />
          </ChatWindow>
          <UserInputWrapper>
            <XpAnimation
              namespace='chat-show'
              right='0'
              position='absolute'
            />
            {
              ChatUI.chatStore.hasShowData && AppUI.userIsLoaded && !this.props.readOnly &&
                <UserInput
                  userInputStore={ChatUI.userInputStore}
                  mediaStore={ChatUI.mediaStore}
                  allowTextInput
                  allowVideoInput={false}
                  allowAudioInput={false}
                  canRecordVideo={ChatUI.layout.mediaRecorderSupported}
                  height={ChatUI.layout.mediaRecorderHeight}
                  userId={ChatUI.user.id}
                  onStop={this.onStop}
                  onRecordAudio={this.onRecordAudio}
                  onRecordVideo={this.onRecordVideo}
                  onSubmitMedia={this.onSubmitMedia}
                  onSubmitMessage={this.onSubmitMessage}
                  width='180'
                />
            }
          </UserInputWrapper>
        </ChatWrapper>
      </Wrapper>
    );
  }
}

export default ChatShow;
