import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { WordStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import WordSearch from 'components/word_search';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  input {
    width: 100%;
  }
  .word-search {
    display: inline-block;
  }
  .in-place-checkbox {
    display: inline-block;
    margin: 3px 0 0 3px;
  }
`;

@observer
class GenderVariantEdit extends React.Component {

  static defaultProps = {
    placeholder: 'Enter Gender Variant',
    displayField: 'targetWithWordTypeParentInfinitiveAndSourceAndLevel',
  }

  @computed get filters() {
    let filters = {};
    if (this.props.word.wordType.name === 'Adjective') {
      filters = {root_word_id: this.props.word.id};
    }
    if (this.props.word.wordType.name === 'Noun') {
      filters = {root: true, root_word_type_id: this.props.word.wordType.id};
    }
    filters['language_id'] = this.props.word?.languageId;
    return filters;
  }

  @autobind updateWord(data) {
    WordStore.update({ ids: {wordId: this.props.word.id}, data: data }, this.props.onChange);
  }

  @autobind onRemoveGenderVariant() {
    if (!this.props.blockEdit) {
      this.updateWord({gender_variant_id: null});
    }
  }

  @autobind onSubmit(word) {
    if (!word) { return null; }
    this.updateWord({gender_variant_id: word.id});
  }

  @autobind createFeminineForm(targetText) {
    if (this.props.word.isAdjective || this.props.word.isNumber) {
      const feminineForm = this.props.word.childWords.filter(word => word.targetText === targetText)[0];
      if (feminineForm) {
        WordStore.update({ids: {wordId: this.props.word.id}, data: {gender_variant_id: feminineForm.id}}, this.props.onChange);
      } else {
        WordStore.create({data: {
            root: false,
            target_text: targetText,
            root_word_id: this.props.word.id,
            language_id: this.props.word.languageId,
            root_word_type_id: this.props.word.rootWordType?.id,
            word_type_id: this.props.word.wordType.id,
          }}, this.afterCreateFeminineForm);
      }
    } else if (this.props.word.isNoun) {
      const data = {
          root: true,
          target_text: targetText,
          language_id: this.props.word.languageId,
          word_type_id: this.props.word.wordType?.id,
          level_id: this.props.word.level?.id,
        };
      if (isPresent(this.props.word.gender)) {
        data['gender'] = this.props.word.gender === 'masculine' ? 'feminine' : 'masculine';
      }
      WordStore.create({data: data, force_create: false}, this.afterCreateFeminineForm);
    }
  }

  @autobind afterCreateFeminineForm(resp) {
    WordStore.update({ids: {wordId: this.props.word.id}, data: {gender_variant_id: resp.word.id}}, this.props.onChange);
  }

  render() {
    if (isPresent(this.props.word.genderVariant)) {
      return (
        <div
          className={!this.props.blockEdit ? 'editable' : ''}
          onClick={this.onRemoveGenderVariant}
        >
          {this.props.word.genderVariant.targetText}
        </div>
      );
    }
    return (
      <Wrapper>
        <WordSearch
          onSubmit={this.onSubmit}
          displayField={this.props.displayField}
          placeholder={this.props.placeholder}
          filters={this.filters}
          onCreateValue={isPresent(this.props.onCreateValue) ? this.props.onCreateValue : this.createFeminineForm}
          height='32px'
          width='100%'
          border={this.props.border}
        />
      </Wrapper>
    );
  }
}

export default GenderVariantEdit;
