import React from 'react';
import { computed , observable } from 'mobx';
import { observer } from 'mobx-react';
import { isPresent } from '@seedlang/utils';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import cx from 'classnames';
import WordSourceEditModal from 'pages/builder/sources/word_source_edit_modal';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import ReactTooltip from 'react-tooltip';

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  .word-source-edit {
    padding-left: 5px;
    max-width: 160px;

    .border {
      border: 1px solid gray;
    }

    .in-place-checkbox.with-label {
      border: none;
    }

    .input-grouping {
      margin-top: -3px;
      display: flex;
      .in-place-checkbox {
        margin: 7px 0 0 3px;
        display: inline-block;
        width: 20px;
      }
      .in-place-text {
        flex: 1;
      }
    }
  }
`;

const SourcePopUp = styled.div`
  .fa-info-circle {
    margin: 7px 0 0 0;
    color: #CCC;
  }
`;

@observer
class WordSourceEdit extends React.Component {
  @observable showModal = false;

  @computed get word() {
    if (this.props.wordAssociation && this.props.wordAssociation.word) {
      return this.props.wordAssociation.word;
    }
    return this.props.word;
  }

  @computed get showSource() {
    return this.word && (this.word.hasSource || this.word.hasTranslationSources);
  }

  @computed get sourceText() {
    let sourceText = '';
    if (this.props.wordAssociation && this.props.wordAssociation.hasSource) {
      sourceText = this.props.wordAssociation.source.text;
      if (this.props.wordAssociation.source.displayGender) {
        return `${sourceText}${this.word.genderAbbreviation}`;
      }
    } else if (!this.props.scopedToSentence && this.word.hasTranslationSources) {
      if (isPresent(this.props.worksheetWord)) {
        return this.props.worksheetWord.sourceList;
      }
      return this.word.vocabSourceList;
    } else if (this.props.wordAssociation?.blankSource) {
      return '[No Source]';
    }
    return sourceText;
  }

  onChange() {
    this.props.onChange();
  }

  render() {
    return (
      <Wrapper>
        <div className='word-source-edit'>
          {
            this.showModal &&
              <WordSourceEditModal
                word={this.props.word}
                wordAssociation={this.props.wordAssociation}
                onCloseModal={() => this.showModal = false}
                onChange={this.props.onChange}
                scopedToSentence={this.props.scopedToSentence}
                languageId={this.props.languageId}
                worksheetWord={this.props.worksheetWord}
              />
          }
          {
            this.showSource && (!this.word.isNumber || !isNaN(this.sourceText)) &&
              <div
                className={cx('text', 'editable', {
                  'blank-source': this.props.wordAssociation?.blankSource,
                })}
                onClick={() => this.showModal = true}
              >
                {isPresent(this.sourceText) ? this.sourceText : '[No Source]'}
              </div>
          }
          {
            this.showSource && this.word.isNumber && isNaN(this.sourceText) &&
              <div
                className={cx('text', 'editable', {
                  'blank-source': this.props.wordAssociation?.blankSource,
                })}
                onClick={() => this.showModal = true}
              >
                {isPresent(this.sourceText) ? `${this.sourceText} (${this.word.digitSourceText ? this.word.formattedDigitSourceText : 'no digit source'})` : '[No Source]'}
              </div>
          }
          {
            !this.showSource &&
              <InputWrapper>
                {
                  !this.props.wordAssociation?.blankSource &&
                    <InPlaceText
                      show
                      submitOnEnter
                      placeholder='Enter Source'
                      blockOverwrite
                      model='translation_sources'
                      field='text'
                      verb='POST'
                      fontSize='12px'
                      data={{ translation_id: this.word.id, user_id: AppUI.user.id, force_accepted: true }}
                      afterChange={this.onChange.bind(this)}
                    />
                }
                {
                  this.props.scopedToSentence &&
                    <div
                      data-for={`no-source-${this.word.id}`}
                      data-tip
                    >
                      <InPlaceCheckbox
                        model='word_associations'
                        icon='ban'
                        wrapperMargin='4px 0 0 3px'
                        field='blank_source'
                        value={this.props.wordAssociation?.blankSource}
                        id={this.props.wordAssociation?.id}
                        afterChange={this.props.onChange.bind(this)}
                      />
                      <ReactTooltip
                        place='top'
                        type='dark'
                        effect='solid'
                        id={`no-source-${this.word.id}`}
                        class='custom-tooltip'
                      >
                        This word has no source
                      </ReactTooltip>
                    </div>
                }
                {
                  false &&
                    <SourcePopUp
                      data-for={`source-popup-${this.word.id}`}
                      data-tip
                    >
                      <i
                        style={{margin: '2px 0 0 3px'}}
                        className='fa fa-info-circle fa-on-click'
                        onClick={() => this.showModal = true}
                      />
                      <ReactTooltip
                        place='top'
                        type='dark'
                        effect='solid'
                        id={`source-popup-${this.word.id}`}
                        class='custom-tooltip'
                      >
                        Open Source Popup
                      </ReactTooltip>
                    </SourcePopUp>
                }
              </InputWrapper>
          }
        </div>
      </Wrapper>
    );
  }
}

export default WordSourceEdit;
