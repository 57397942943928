import styled from '@emotion/styled';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { observer } from 'mobx-react';
import React from 'react';

const TagWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin: 5px 0;
  flex-wrap: wrap;
`;

const Tag = styled.div`
  color: #353535;
  padding: 3px 10px;
  border-radius: 5px;
  font-size: ${props => props.compact ? '11px' : '12px'};
  line-height: ${props => props.compact ? '11px' : ''};
  background: #efefef;
`;

@observer
class ExerciseTags extends React.Component {

  render() {
    return (
      <TagWrapper
        key={`exercise-tags-${this.props.exercise.id}`}
      >
        {
          this.props.exercise && this.props.exercise.orderedTags.map(item => {
            return (
            <Tag
              key={`${this.props.exercise.id}-${item.id}`}
              className='tag'
              compact={this.props.compact}
            >
              {item.name}
            </Tag>
            );
          })
        }
      </TagWrapper>
    );
  }
}

export default ErrorBoundary(ExerciseTags);
