import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { FaqItemStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import Button from 'components/button/button';

const Wrapper = styled.div`

`;

@observer
class FaqItemShow extends React.Component {

  constructor(props) {
    super(props);
    this.getFaqItem();
  }

  @autobind getFaqItem() {
    FaqItemStore.getShow({ ids: {faqItemId: this.props.params.faqItemId }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    FaqItemStore.setShowData(resp);
  }

  render() {
    return (
      <Wrapper>
        <Link
          to={{name: 'builder.faq_categories.edit', params: {faqCategoryId: this.props.params.faqCategoryId}}}
          style={{margin: '5px 0'}}
        >
          <i className='fa fa-angle-left' style={{marginRight: 4}} />
          FAQ Category
        </Link>
        {
          FaqItemStore.hasShowData &&
            <span>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Id</legend>
                    <InPlaceText
                      defaultValue={FaqItemStore.showDataField('id')}
                      model='faq_items'
                      field='id'
                      id={FaqItemStore.showDataField('id')}
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Title</legend>
                    <InPlaceText
                      defaultValue={FaqItemStore.showDataField('title')}
                      model='faq_items'
                      field='title'
                      id={FaqItemStore.showDataField('id')}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Buttons</legend>
                    <Link
                      to={{name: 'faq_items.show', params: {faqCategoryId: this.props.params.faqCategoryId, faqItemId: this.props.params.faqItemId}}}
                    >
                      <Button>
                        View in Help Center
                      </Button>
                    </Link>
                  </fieldset>
                </div>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Description</legend>
                    <InPlaceText
                      defaultValue={FaqItemStore.showDataField('description')}
                      model='faq_items'
                      field='description'
                      id={FaqItemStore.showDataField('id')}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Body</legend>
                    <InPlaceText
                      markdown
                      inputType='textarea'
                      defaultValue={FaqItemStore.showDataField('body')}
                      model='faq_items'
                      field='body'
                      id={FaqItemStore.showDataField('id')}
                    />
                  </fieldset>
                </div>
              </div>
            </span>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FaqItemShow);
