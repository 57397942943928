import isPresent from './is_present';

export default function pixify(value) {
  if (value == null || value === 'NaN' || Number.isNaN(value)) {
    return null;
  } else if (value === 'auto') {
    return 'auto';
  } else if (String(value).includes('px') || String(value).includes('%')) {
    return value;
  } else if (isPresent(value)) {
    return `${value}px`;
  }
}
