import styled from '@emotion/styled';
import { AppUI , LoadingState } from '@seedlang/state';
import { CommaSeparated, isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import InPlaceText from 'components/form/in_place_text';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Spinner from 'components/spinner';
import Text from 'components/text';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import PodcastPublicationStateBanner from './podcast_publication_state_banner';
import RssImport from './rss_import';

const Wrapper = styled.div`

`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
`;

@observer
class PodcastEdit extends React.Component {
  @observable showSpinner = false;
  membershipTypesLoadingState = new LoadingState();

  constructor(props) {
    super(props);
    this.getPodcast();
    AppUI.podcastStore.clearIndexData();
    AppUI.membershipTypeStore.getIndex({});
    window.AppUI = AppUI;
  }

  @autobind getPodcast() {
    AppUI.podcastStore.getShow({ids: {podcastId: this.props.params.podcastId}});
    setTimeout(() => {
      this.showSpinner = false;
    }, 200);
  }

  @autobind onUpdateRssFeeds() {
    this.showSpinner = true;
    AppUI.podcastStore.updateRssFeeds({ids: {podcastId: this.props.params.podcastId}}, this.getPodcast);
  }

  @autobind async onUpdateMembershipType(membershipTypeId) {
    this.membershipTypesLoadingState.started();
    try {
      const list = new CommaSeparated(AppUI.podcastStore.showData.membershipTypeIds);
      list.toggle(membershipTypeId);
      const membershipTypeIds = list.toString();

      AppUI.podcastStore.showData.set('membershipTypeIds', membershipTypeIds);
      await AppUI.podcastStore.updatePromise({ ids: { podcastId: this.props.params.podcastId }, data: { membershipTypeIds: membershipTypeIds } });
      await AppUI.podcastStore.getShowPromise({ ids: { podcastId: this.props.params.podcastId } });
      this.membershipTypesLoadingState.succeeded();
    }
    catch {
      this.membershipTypesLoadingState.failed('Error updating membership types');
    }
  }

  render() {
    return (
      <Wrapper>
        <div className='breadcrumbs' style={{marginBottom: 20}}>
          <Link
            to={{name: 'creator.podcasts.index', params: {groupId: this.props.params.groupId}}}
          >
            View Podcasts
          </Link>
        </div>
        <Title>Podcast Settings</Title>
        <div className='row'>
          <div className='col-xs-12'>
            <PodcastPublicationStateBanner
              publicationState={AppUI.podcastStore.showData.publicationState}
              onPublishChanges={this.onUpdateRssFeeds}
              showSpinner={this.showSpinner}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Podcast Title</legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.title}
                model='podcasts'
                field='title'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Podcast Language</legend>
              <InPlaceLanguageSelect
                model='podcasts'
                id={AppUI.podcastStore.showData.id}
                value={AppUI.podcastStore.showData.language}
                field='language'
                includeBlank
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Podcast Author</legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.author}
                model='podcasts'
                field='author'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Podcast Owner Name</legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.ownerName}
                model='podcasts'
                field='owner_name'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Podcast Owner Email</legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.ownerEmail}
                model='podcasts'
                field='owner_email'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Podcast Copyright Text</legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.copyright}
                model='podcasts'
                field='copyright'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Podcast Cover Image</legend>
              <InPlaceImageUpload
                image={AppUI.podcastStore.showData.podcastImage && AppUI.podcastStore.showData.podcastImage.image}
                model='podcast_images'
                id={AppUI.podcastStore.showData.podcastImage?.id}
                onChange={this.getPodcast}
                querystring={{
                  podcast_id: AppUI.podcastStore.showData.id,
                }}
              />
            </fieldset>
            <fieldset>
              <legend>Membership</legend>
              {
                typeof AppUI.podcastStore.showData.membershipTypeIds !== 'undefined' &&
                AppUI.membershipTypeStore.indexData.map(item => {
                  return (
                    <div className='row' key={item.id}>
                      <InPlaceCheckbox
                        value={AppUI.podcastStore.showData.membershipTypeIds.indexOf(item.id) !== -1}
                        icon='check'
                        onChange={() => this.onUpdateMembershipType(item.id)}
                        disabled={this.membershipTypesLoadingState.isLoading}
                      >
                        {item.name}
                      </InPlaceCheckbox>
                    </div>
                  );
                })
              }
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Website Link</legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.siteLink}
                model='podcasts'
                field='site_link'
                afterChange={this.getPodcast}
              />
            </fieldset>
            <fieldset>
              <legend>SpeakPipe integration</legend>
              <div>
                <Text fontSize='14px'><a href='https://www.speakpipe.com/' className='underline'>SpeakPipe</a> is a service used to receive voice messages from users.</Text>
              </div>
              <InPlaceText
                submitOnEnter
                label='SpeakPipe integration link'
                show
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.voiceMessageLink}
                model='podcasts'
                field='voice_message_link'
                placeholder='https://www.speakpipe.com/widget/inline/repdspsagysgj0jsy2bgqxxc30p56o3g'
                afterChange={this.getPodcast}
              />
              <InPlaceText
                submitOnEnter
                label='Button text'
                show={isBlank(AppUI.podcastStore.showData.voiceMessageButton)}
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.voiceMessageButton}
                model='podcasts'
                field='voice_message_button'
                placeholder='Eure Fragen'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
        </div>

        <div className='row'>
          <div className='col-xs-2'>
            <fieldset>
              <legend title='To reduce the size of the RSS feeds, only the configured amount of episodes will contain the full transcript.'>
                Transcript Limit
              </legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.transcriptLimit}
                model='podcasts'
                field='transcript_limit'
                type='number'
                step='1'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
          <div className='col-xs-4'>
            <fieldset>
              <legend title='The header of the transcript. This will be used for removing the transcript from older episodes to limit the size of the feed.'>
                Transcript Title
              </legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.transcriptTitle}
                model='podcasts'
                field='transcript_title'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>
                Transcript Link Title
              </legend>
              <InPlaceText
                id={AppUI.podcastStore.showData.id}
                defaultValue={AppUI.podcastStore.showData.transcriptLinkTitle}
                model='podcasts'
                field='transcript_link_title'
                afterChange={this.getPodcast}
              />
            </fieldset>
          </div>
        </div>

        <RssImport
          podcastId={AppUI.podcastStore.showData.id}
          afterImport={this.getPodcast}
        />

        {
          AppUI.user.developer &&
            <div className='row'>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Developer Tools: RSS Feeds</legend>
                  <div>
                    Last Updated: {AppUI.podcastStore.showData.rssUpdatedAt ? AppUI.podcastStore.showData.rssUpdatedAt.formatted : 'never'}
                  </div>
                  {
                    !this.showSpinner &&
                      <button
                        style={{marginTop: '10px'}}
                        className='gray-button'
                        onClick={this.onUpdateRssFeeds}
                      >
                        Update RSS Feeds
                      </button>
                  }
                  {
                    this.showSpinner &&
                      <Spinner />
                  }
                </fieldset>
              </div>
            </div>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PodcastEdit);
