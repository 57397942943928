import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { Api } from '@seedlang/stores';
import { isBlank } from '@seedlang/utils';
import { isArray, isString } from 'lodash';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import pluralize from 'pluralize';
import React from 'react';

const Wrapper = styled.div`
  margin: ${(props) => props.margin || '0'};
 
  select {
    width: ${props => props.width};
    height: ${props => props.height} !important;
    padding: 5px;
    font-size: 14px;
  }
`;

@observer
class InPlaceMultiSelect extends React.Component {
  @observable value = [];

  @computed get disabled() {
    return this.props.disabled || (!this.props.allowUpdate && !AppUI.user.canUpdateRecords);
  }

  static defaultProps = {
    height: "90px", // tall enough for 4 options
    width: "100%",
  }

  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    this.value = this.props.value;
    if (this.props.focus) {
      this.select.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.value = this.props.value;
    }
  }

  onChange() {
    window.select = this.select.current
    if (this.props.blurOnChange) {
      this.select.current.blur();
    }
    const newValue = Array.from(this.select.current.selectedOptions).map(option => option.value);

    if (this.props.model) {
      const data = { data: {} };
      this.value = newValue;
      data.data[this.props.field] = newValue;
      Api.put({
        url: `/api/${pluralize(this.props.model)}/${this.props.id}`,
        data: data,
        successCallback: ((resp) => {
          if (this.props.afterChange) {
            this.props.afterChange(newValue);
          }
        }),
      });
    } else {
      this.props.onChange(newValue);
    }
  }

  _options() {
    if (isBlank(this.props.options)) { return []};

    // options is a list of strings => use them as key and label
    // E.g. ["de", "pl"]
    if (this.props.options && isString(this.props.options[0])) {
      return (
        this.props.options.map((item, index) => {
          return (
            <option
              key={`${item}-${index}`}
              value={item}
              selected={this.value?.includes(item)}
            >
              {item}
            </option>
          );
        })
      );
    }
    
    // options is a list of arrays => [key, label]
    // E.g. [["de", "Germany"], ["pl", "Poland"]]
    if (this.props.options && isArray(this.props.options[0])) {
      return (
        this.props.options.map((item, index) => {
          return (
            <option
              key={`${item[1]}-${index}`}
              value={item[0]}
              selected={this.value?.includes(item[0])}
            >
              {item[1]}
            </option>
          );
        })
      );
    }

    // options is a list of {"${props.optionName}": value} objects
    // E.g. [{id: "de", text: "Germany"}, {id: "pl", text: "Poland"}] if optionName = "text"
    // E.g. [{id: "de", targetText: "Germany"}, {id: "pl", targetText: "Poland"}] if optionName missing
    return (
        this.props.options && this.props.options.map((item, index) => {
          const value = this.props.optionName ? item[this.props.optionName] : item.targetText;
          return (
            <option
              key={`${value}-${index}`}
              value={item.id}
              selected={this.value?.includes(item.id)}
            >
              {value}
            </option>
          );
        })
    );
  }

  render() {
    return (
      <Wrapper
        className={this.props.className}
        margin={this.props.margin}
        height={this.props.height}
        width={this.props.width}
      >
        <select
          multiple
          ref={this.select}
          onChange={this.onChange.bind(this)}
          style={this.props.style}
          disabled={this.disabled}
        >
          {this._options()}
        </select>
      </Wrapper>
    );
  }
}

export default InPlaceMultiSelect;
