import React from 'react';
import { observer } from 'mobx-react';
import { SourceStore , TranslationSourceStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import { Link } from 'react-router';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import {LanguageIcon} from '@seedlang/icons';
import { isBlank } from '@seedlang/utils';

@observer
class SourceEdit extends React.Component {

  constructor(props) {
    super(props);
    this.loadSource();
  }

  linkTo(item) {
    if (item.type === 'Word') {
      return { name: 'builder.words.edit', params: { wordId: item.id } };
    } else {
      return { name: 'builder.sentences.edit', params: { sentenceId: item.id } };
    }
  }

  removeSource(translationId) {
    TranslationSourceStore.destroyWithoutId({data: {translation_id: translationId, source_id: this.props.params.sourceId}}, this.loadSource);
  }

  @autobind loadSource() {
    SourceStore.getShow({ids: {sourceId: this.props.params.sourceId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    SourceStore.setShowData(resp);
  }

  @autobind afterChange() {
    SourceStore.reloadShow();
  }

  render() {
    return (
      <div className='source-edit'>
        {
          SourceStore.hasShowData &&
            <div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Text</legend>
                    <div style={{display: 'flex'}}>
                        <LanguageIcon
                          margin='0 5px 0 0'
                          languageId={SourceStore.showDataField('languageId')}
                          showTooltip={true}
                        />
                        <InPlaceText
                          defaultValue={SourceStore.showDataField('text')}
                          model='sources'
                          field='text'
                          submitOnEnter={true}
                          id={SourceStore.showDataField('id')}
                          suffix={SourceStore.showDataField('displayGender') ? Array.from(new Set(SourceStore.showData.translations.map(translation => {
                            return translation.genderAbbreviation;
                          }).filter(item => item !== ''))).join(' |') : ''
                          }
                        />
                      </div>
                      {
                        SourceStore.showDataField('languageId') === 'EN' &&
                        <div style={{display: 'flex', marginTop: '5px'}}>
                          <LanguageIcon
                            margin='0 5px 0 0'
                            languageId='EN2'
                            showTooltip={true}
                          />
                          <InPlaceText
                            placeholder='No British spelling variant'
                            defaultValue={isBlank(SourceStore.showDataField('ukSpelling')) ? null : SourceStore.showDataField('ukSpelling')}
                            model='sources'
                            field='ukSpelling'
                            id={SourceStore.showDataField('id')}
                            submitOnEnter={true}
                            rejectBlank={false}
                            suffix={SourceStore.showDataField('displayGender') ? Array.from(new Set(SourceStore.showData.translations.map(translation => {
                              return translation.genderAbbreviation;
                            }).filter(item => item !== ''))).join(' |') : ''}
                          />
                        </div>
                      }
                      <small>
                        Note: editing this will affect all sentences with this source.
                      </small>
                  </fieldset>
                </div>
              </div>
              {
                SourceStore.showData.translations.some(item => {return item.isNoun;}) &&
                  <div className='row'>
                    <div className='col-xs-12'>
                      <fieldset>
                        <legend>Attributes</legend>
                        <div>
                            <InPlaceCheckbox
                                icon='check'
                                model='sources'
                                id={SourceStore.showDataField('id')}
                                value={SourceStore.showDataField('displayGender')}
                                field='display_gender'
                                afterChange={this.afterChange}
                            >
                              Display gender
                            </InPlaceCheckbox>
                          </div>
                      </fieldset>
                    </div>
                  </div>
              }
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Translations</legend>
                    <table className='table-wrapper'>
                      <thead>
                        <tr>
                          <th width='20' />
                          <th>Target</th>
                          <th width='20' />
                        </tr>
                      </thead>
                      <tbody>
                        {
                          SourceStore.showData.translations.map(item => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <Link
                                    to={this.linkTo(item)}
                                    className='on-click'
                                  >
                                    <i className='fa fa-chevron-right fa-block' />
                                  </Link>
                                </td>
                                <td>
                                  <div className='text'>
                                    {item.targetText}
                                  </div>
                                </td>
                                <td>
                                  <DeleteButton
                                    onConfirm={() => this.removeSource(item.id)}
                                    message='Remove source from translation?'
                                    className='fa-block right'
                                  />
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
            </div>
        }
      </div>
    );
  }
}

export default SourceEdit;
