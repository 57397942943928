import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { LockIcon } from '@seedlang/icons';

const Button = styled.div`
  ${flexCenter()}
  height: 28px;
  border-radius: 10px;
  margin: ${props => props.margin};
  width: ${props => props.width};
  background: ${props => props.background};
  color: ${props => props.color};
  border: ${props => props.border};
  padding: 0px 10px;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  .fa {
    margin: 2px 4px 0px 0px;
    color: ${props => props.color};
  }
  .lock-icon {
    height: 7px;
    margin: -2px 3px 0 0;
    svg {
      width: 7px;
    }
  }
`;

@observer
class SelectionButton extends React.Component {

  static defaultProps = {
    color: '#636363',
    border: '2px solid #636363',
  }

  render() {
    return (
      <Button
        color={this.props.color}
        border={this.props.border}
        onClick={this.props.onClick}
        margin={this.props.margin}
        width={this.props.width}
        className='selection-button'
        background={this.props.background}
      >
        {!this.props.disabled && this.props.icon}
        {this.props.disabled && <LockIcon />}
        {this.props.children}
      </Button>
    );
  }
}

export default ErrorBoundary(SelectionButton);
