import React from 'react';
import { observer } from 'mobx-react';
import { OnboardingWrapper } from '@seedlang/hoc';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Backdrop from 'components/backdrop';
import { Theme } from '@seedlang/constants';
import { fixedCenter, absoluteCenter } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import { computed } from 'mobx';
import { isPresent } from '@seedlang/utils/src';
import AnimatedProgressBar from 'components/animated_progress_bar';

const Wrapper = styled.div`
  ${fixedCenter()}
  background: ${props => props.background};
  z-index: ${Theme.z.foreground};
  .title {
    margin-bottom: 20px;
    text-align: center;
  }
  .onboarding-spinner {
    position: absolute;
    top: 0;
    z-index: 999;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  ${absoluteCenter()}
  background: ${props => props.background};
  border-radius: ${props => props.isMobile ? 0 : '20px'};
  max-width: ${props => props.isMobile ? '100%' : '500px'};
  max-height: ${props => props.maxHeight};
  width: ${props => props.isMobile ? '100%' : 'auto'};
  max-height: ${props => props.maxHeight};
  margin: ${props => props.isMobile ? '0' : '60px auto'};
  width: 100%;
  z-index: 4;
  padding: 10px;
  @media only screen and (max-width : 649px) {
    padding: 0;
  }

  @media only screen and (max-height: 700px) {
    margin: 10px auto;
  }
`;

const OverflowInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  padding: ${props => props.padding};
  overflow-x: hidden;
`;

const ChildrenWrapper = styled.div`
  margin-top: 20px;
  height: 420px;
  width: 100%;
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
`;

@observer
class OnboardingSeedlang extends React.Component {

  constructor(props) {
    super(props);
    AppUI.setUpdatedDeviceLanguageId(this.props.params?.languageId);
  }

  @computed get currentRoute() {
    return this.props.children?.props?.route?.name;
  }

  @computed get currentStep() {
    return this.props.onboardingSteps.find(item => item.route === this.currentRoute);
  }

  @computed get maxHeight() {
    if (AppUI.layout.isMobile) {
      return '100%';
    }
    return AppUI.routeStore.routeName === 'onboarding.sign_in' ? '580px' : '640px';
  }

  render() {
    return (
      <Wrapper
        background='#044067'
      >
        {
          !AppUI.layout.isMobile &&
            <Backdrop
              percentageComplete={(this.currentIndex / 3) * 20}
            />
        }
        <InnerWrapper
          className='onboarding'
          isMobile={AppUI.layout.isMobile}
          background='#fff'
          maxHeight={this.maxHeight}
        >
          <OverflowInner
            isMobile={AppUI.layout.isMobile}
            padding={AppUI.layout.isMobile ? '30px' : '20px'}
          >
            {
              isPresent(this.currentStep) && this.props.params?.languageId &&
                <ProgressBarWrapper>
                  <AnimatedProgressBar
                    backgroundHeight={20}
                    progressHeight={20}
                    progressBarWidth={440}
                    borderTopRightRadius='10px'
                    borderTopLeftRadius='10px'
                    borderBottomRightRadius='10px'
                    borderBottomLeftRadius='10px'
                    background='#CCC'
                    total={this.props.onboardingSteps.length + 1}
                    current={this.currentStep['stepNumber']}
                  />
                </ProgressBarWrapper>
            }
            <ChildrenWrapper>
              {this.props.children}
            </ChildrenWrapper>
          </OverflowInner>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(OnboardingWrapper(OnboardingSeedlang));
