import React from 'react';
import { observer } from 'mobx-react';
import CheckIfTrue from 'components/check_if_true';
import DeleteButton from 'components/button/delete_button';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';

@observer
class RelatedWordTableRow extends React.Component {

  @autobind deleteRelatedWord() {
    if (this.props.isStem) {
      this.props.onDelete({stemId: this.props.relatedWord.id, childId: this.props.referenceWordId});
    } else {
      this.props.onDelete({stemId: this.props.referenceWordId, childId: this.props.relatedWord.id});
    }
  }

  render() {
    return (
      <tr key={`${this.props.isStem ? 'stem' : 'child'}-${this.props.relatedWord.id}`}>
        <td>
          <Link
            to={{ name: 'builder.words.edit', params: { wordId: this.props.relatedWord.id } }}
            className='on-click'
          >
            <i className='fa fa-chevron-right fa-block' />
          </Link>
        </td>
        <td>
          <div className='text'>
            <Link
              to={{name: 'builder.words.edit', params: {wordId: this.props.relatedWord.id}}}
              className='underline'
            >
              {this.props.relatedWord.targetTextWithDefiniteArticle}
              {this.props.relatedWord.plural && <span> (plur)</span>}
              {this.props.relatedWord.isNoun && !this.props.relatedWord.plural && <span> (sing)</span>}
            </Link>
          </div>
        </td>
        <td>
          <div className='text'>
            <i>{this.props.relatedWord.sourceList}</i>
          </div>
        </td>
        <td>
          <div className='text'>
            <i>{this.props.isStem ? 'Stem' : 'Child'}</i>
          </div>
        </td>
        <td>
          <div className='text'>
            { this.props.relatedWord.sentencesCount }
          </div>
        </td>
        <td>
          <div className='text'>
            { this.props.relatedWord.wordTypeName }
          </div>
        </td>
        <td>
          <CheckIfTrue
            circle
            value={this.props.relatedWord.publicInVocab}
            width='20px'
          />
        </td>
        <td>
          <DeleteButton
            onConfirm={this.deleteRelatedWord}
            message='Remove this related word association?'
            className='fa-block right'
          />
        </td>
      </tr>
    );
  }

}

export default RelatedWordTableRow;
