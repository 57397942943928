import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { SentenceStore , AssignableConceptStore } from '@seedlang/stores';
import { isPresent, isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import Spinner from 'components/spinner';
import { keys } from 'lodash';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { Constants, Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  .sentence-create {
    .spinner {
      margin-bottom: 20px;
    }
    textarea {
      margin-bottom: 10px;
      height: 34px;
      font-size: 14px;
      line-height: 16px;
    }
    .targetText {
      margin-bottom: 0;
    }
    .characters-remaining {
      font-size: 11px;
      margin-bottom: 10px;
      line-height: 15px;
    }
    .lookup-grammar {
      margin-bottom: 5px;
    }
  }
`;

const Button = styled.div`
  cursor: pointer;
  padding: 10px;
  font-size: 12px;
  background: ${props => props.background};
  color: ${props => props.color};
  margin: 0 10px 10px 0;
  border-radius: 10px;
`;

const ExpandTriviaLink = styled.div`
  text-decoration: underline;
  margin: 0 0 10px 3px;
  cursor: pointer;
  font-size: 14px;
`;

@observer
class SentenceCreate extends React.Component {
  @observable targetText = this.props.targetText || '';
  @observable sourceText = this.props.sourceText || '';
  @observable answerTargetText = '';
  @observable answerSourceText = '';
  @observable conceptId = this.props.conceptId;
  @observable automaticGrammarAssignment = false;
  @observable showSpinner = false;
  @observable showNumberWarning = false;
  @observable showSentenceCountWarning = false;
  @observable showQuotationMarkSpaceWarning = false;
  @observable showTriviaOptionInputs = this.props.showTriviaOptionInputs;
  @observable triviaAnswer1 = '';
  @observable triviaAnswer2 = '';
  @observable triviaAnswer3 = '';
  @observable triviaAnswer4 = '';
  @observable languageId;

  @computed get characterCount() {
    return this.targetText.length;
  }

  componentDidMount() {
    AssignableConceptStore.getIndex({limit: 9999});
    this.languageId = keys(Constants.LANGUAGE_OPTIONS).indexOf(AppUI.user.targetLanguageId) !== -1 ? AppUI.user.targetLanguageId : 'DE';
  }

  @autobind updateTargetText() {
    const targetText = this.refs.targetText.value.replace(/\.{3}/g, Constants.SPECIAL_PUNCTUATION.ELLIPSIS);
    const pattern = new RegExp(`([.?!]|.+${Constants.SPECIAL_PUNCTUATION.ELLIPSIS})\\s*\\S`);
    if (pattern.test(targetText)) {
      this.showSentenceCountWarning = true;
      this.targetText = this.refs.targetText.value;
    } else if (this.refs.targetText.value.match(/\d+/)) {
      this.showNumberWarning = true;
      this.targetText = this.refs.targetText.value;
    } else if (this.refs.targetText.value.match(/(«\s)|(\s»)/)) {
      this.showQuotationMarkSpaceWarning = true;
      this.targetText = this.refs.targetText.value;
    } else {
      this.showNumberWarning = false;
      this.showSentenceCountWarning = false;
      this.showQuotationMarkSpaceWarning = false;
      this.targetText = this.refs.targetText.value;
    }
  }

  @autobind onChange() {
    this.characterCount = this.refs.targetText.value.length;
  }

  @autobind onClick() {
    if (isPresent(this.refs.targetText.value) && isPresent(this.refs.sourceText.value)) {
      this.showSpinner = true;
      SentenceStore.create(
        {
          data: {
            targetText: this.targetText,
            sourceText: this.sourceText,
            answerTargetText: this.answerTargetText,
            answerSourceText: this.answerSourceText,
            interjectionId: this.props.interjectionId,
            questionId: this.props.questionId,
            conceptIds: isPresent(this.conceptId) ? [this.conceptId] : null,
            timestamp: isPresent(this.refs.timestamp) ? this.refs.timestamp.value : '',
            triviaAnswer1: this.triviaAnswer1,
            triviaAnswer2: this.triviaAnswer2,
            triviaAnswer3: this.triviaAnswer3,
            triviaAnswer4: this.triviaAnswer4,
            submittedBy: this.props.submittedBy,
            triviaWordId: this.props.triviaWordId,
            languageId: this.languageId,
          },
        },
        this.afterCreate,
      );
      this.targetText = '';
      this.sourceText = '';
      this.answerTargetText = '';
      this.answerSourceText = '';
      this.triviaAnswer1 = '';
      this.triviaAnswer2 = '';
      this.triviaAnswer3 = '';
      this.triviaAnswer4 = '';
    }
  }

  @autobind afterCreate(resp) {
    this.showSpinner = false;
    if (this.props.afterCreate) {
      this.props.afterCreate(resp);
    }
  }

  @autobind onEnterPressClick(e) {
    if (e.key === 'Enter') {
      return this.onClick();
    }
  }

  render() {
    return (
      <Wrapper>
        <div className='sentence-create translation-create'>
          {this.showSpinner && <Spinner className='blue' />}
          <div className='row'>
            {
              keys(Constants.LANGUAGE_OPTIONS).map(languageId => {
                return (
                  <Button
                    onClick={() => this.languageId = languageId}
                    background={this.languageId === languageId ? Theme.green : '#CCC'}
                    color={this.languageId === languageId ? '#FFF' : '#333'}
                  >
                    {Constants.LANGUAGE_OPTIONS[languageId]}
                  </Button>
                );
              })
            }
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <input
                className='targetText'
                type='text'
                maxLength='110'
                placeholder={this.showTriviaOptionInputs ? 'Enter a Trivia Question' : 'Enter a Sentence'}
                onChange={() => this.updateTargetText()}
                ref='targetText'
                value={this.targetText}
              />
              <div className='characters-remaining'>
                Characters remaining: {110 - this.characterCount}
              </div>
              {
                this.showNumberWarning &&
                  <div
                    className='alert'
                    style={{
                      margin: '0 0 10px 0',
                    }}
                  >
                    Numbers should be spelled out whenever possible, ex '100' should be 'hundert'.
                  </div>
              }
              {
                this.showSentenceCountWarning &&
                  <div
                    className='alert'
                    style={{
                      margin: '0 0 10px 0',
                    }}
                  >
                    Only one sentence should be added at a time.
                  </div>
              }
              {
                this.showQuotationMarkSpaceWarning &&
                  <div
                    className='alert'
                    style={{
                      margin: '0 0 10px 0',
                    }}
                  >
                    Please do not include spaces just after or before quotation marks.
                  </div>
              }
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <input
                type='text'
                placeholder='Enter an English Translation'
                value={this.sourceText}
                onChange={() => this.sourceText = this.refs.sourceText.value}
                ref='sourceText'
                onKeyPress={this.onEnterPressClick}
              />
            </div>
          </div>
          {
            !this.props.simple && !this.showTriviaOptionInputs &&
              <ExpandTriviaLink
                onClick={() => this.showTriviaOptionInputs = true}
              >
                Show Trivia Fields
              </ExpandTriviaLink>
          }
          {
            !this.props.simple && this.showTriviaOptionInputs &&
              <div style={{marginTop: '10px'}}>
                <div className='row' style={{marginBottom: 10}}>
                  <div className='col-xs-6' style={{paddingRight: 10}}>
                    <input
                      value={this.triviaAnswer1}
                      onChange={el => this.triviaAnswer1 = el.target.value}
                      placeholder='Correct Option'
                    />
                  </div>
                  <div className='col-xs-6'>
                    <input
                      value={this.triviaAnswer2}
                      onChange={el => this.triviaAnswer2 = el.target.value}
                      placeholder='Incorrect Option 1'
                    />
                  </div>
                </div>
                <div className='row' style={{marginBottom: 10}}>
                  <div className='col-xs-6' style={{paddingRight: 10}}>
                    <input
                      value={this.triviaAnswer3}
                      onChange={el => this.triviaAnswer3 = el.target.value}
                      placeholder='Incorrect Option 2'
                    />
                  </div>
                  <div className='col-xs-6'>
                    <input
                      value={this.triviaAnswer4}
                      onChange={el => this.triviaAnswer4 = el.target.value}
                      placeholder='Incorrect Option 3'
                    />
                  </div>
                </div>
                <div className='row' style={{marginBottom: 10}}>
                  <div className='col-xs-12'>
                    <input
                      type='text'
                      maxLength='110'
                      placeholder='Enter a Trivia Answer'
                      onChange={() => this.answerTargetText = this.refs.answerTargetText.value}
                      ref='answerTargetText'
                      value={this.answerTargetText}
                    />
                  </div>
                </div>
                <div className='row' style={{marginBottom: 10}}>
                  <div className='col-xs-12'>
                    <input
                      type='text'
                      maxLength='110'
                      placeholder='Enter an English Translation'
                      onChange={() => this.answerSourceText = this.refs.answerSourceText.value}
                      ref='answerSourceText'
                      value={this.answerSourceText}
                    />
                  </div>
                </div>
              </div>
          }
          <div className='row'>
            <div className='col-xs-12'>
              <button
                className={cx('button-primary', {
                  disabled: isBlank(this.targetText),
                })}
                onClick={this.onClick}
              >
                Create
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default SentenceCreate;
