import React from 'react';
import { observer } from 'mobx-react';
import { AppUI, DeckUI } from '@seedlang/state';
import { DeckCloseWebWrapper, InterjectionCardWrapper } from '@seedlang/hoc';
import CardText from 'components/card/card_text';
import { pixify } from '@seedlang/utils';
import MediaWithSupplement from 'components/media/media_with_supplement';
import { RightIcon } from '@seedlang/icons';
import DeckRating from 'components/deck/deck_rating';
import { Theme } from '@seedlang/constants';
import styled from '@emotion/styled';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import NextButton from 'components/button/next_button';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import { flexCenterColumn } from '@seedlang/style_mixins';

const PastDueCount = styled.div`
  border-radius: 5px;
  background: white;
  font-weight: bold;
  font-size: 13px;
  color: ${Theme.red};
  padding: 0 5px;
  margin-right: 2px;
`;

const MediaSection = styled.div`
  width: 100%;
  ${flexCenterColumn()}
`;

@observer
class InterjectionCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        showWordCard={this.props.showWordCard}
        icons={['languageButton']}
      >
        <CardContent>
          <MediaSection>
            <MediaWithSupplement
              autoPlay={this.props.inView}
              borderTopRadius={10}
              borderBottomRadius={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
              videoClip={this.props.card.targetVideoClip}
              usingDataSaverHeader={AppUI.usingDataSaverHeader}
              maximizedWidth={pixify(DeckUI.layout.videoHeight)}
              minimizedWidth={pixify(DeckUI.layout.supplementWidth)}
              loadVideo
              marginBottom={DeckUI.layout.cardTextHasMinimumHeight ? 10 : 0}
            />
          </MediaSection>
          <CardText
            card={this.props.card}
            cardState={this.props.cardState}
            padding='0 30px'
            hasUserInput={this.props.card.isLastCard}
            cardId={this.props.card.id}
            sentence={this.props.card.sentence}
            cardTypeSlug={this.props.card && this.props.card.cardTypeSlug}
            showText
            expand={false}
            showType={this.props.cardState.showType}
            borderTopRightRadius={(DeckUI.layout.cardTextHasMinimumHeight || this.props.translateCard) && !this.props.cardState.revealed ? 10 : 0}
            borderTopLeftRadius={(DeckUI.layout.cardTextHasMinimumHeight || this.props.translateCard) && !this.props.cardState.revealed ? 10 : 0}
            borderBottomRightRadius={this.props.card.isLastCard ? 0 : 10}
            borderBottomLeftRadius={this.props.card.isLastCard ? 0 : 10}
          />
          {
            this.props.card.isLastCard &&
              <DeckRating />
          }
        </CardContent>
        {
          this.props.card.isLastCard &&
            <CardButtons>
              {
                this.props.showMoreReviewsButton &&
                  <CardButton
                    shortcut='1'
                    marginRight={5}
                    text='More Reviews'
                    icon={this.props.moreReviewsButtonCount > 0 ? <PastDueCount>{this.props.moreReviewsButtonCount}</PastDueCount> : null}
                    backgroundColor={Theme.darkestGreen}
                    onClick={() => DeckUI.loadDeck({id: DeckUI.deck.id})}
                    disabled={!this.props.inView}
                  />
              }
              <CardButton
                submitOnEnter
                shortcut={this.props.showMoreReviewsButton ? '2' : '1'}
                marginLeft={this.props.showMoreReviewsButton ? 5 : 0}
                text='Close Deck'
                icon={<RightIcon />}
                backgroundColor={Theme.green}
                onClick={this.props.onCloseDeck}
                disabled={!this.props.inView}
              />
            </CardButtons>
        }
        {
          !this.props.card.isLastCard &&
            <CardButtons>
              <NextButton
                disabled={!this.props.inView}
                onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
                submitOnEnter
                shortcut='1'
              />
            </CardButtons>
        }
      </CardWrapper>
    );
  }
}

export default DeckCloseWebWrapper(InterjectionCardWrapper(InterjectionCard));
