import React from 'react';
import { observer } from 'mobx-react';
import { UserExerciseStore } from '@seedlang/stores';
import Paginator from 'components/paginator';

@observer
class UserExerciseIndex extends React.Component {

  constructor(props) {
    super(props);
    UserExerciseStore.getIndex({filters: {correct_answers_count: '!0'}});
  }

  render() {
    return (
      <div className='user-exercise-index'>
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th>Exercise</th>
              <th>Worksheet</th>
              <th>User</th>
              <th>Score</th>
              <th>Att</th>
            </tr>
          </thead>
          <tbody>
            {
              UserExerciseStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='text'>
                        {item.exercise ? item.exercise.name : null}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.worksheet ? item.worksheet.name : null}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.user.name}
                      </div>
                    </td>
                    <td>
                      {item.correctAnswersCount} / {item.exercise.exerciseEntriesCount}
                    </td>
                    <td>
                      {item.attemptsCount}
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={UserExerciseStore}
        />
      </div>
    );
  }
}

export default UserExerciseIndex;
