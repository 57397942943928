import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { TranslationSourceStore } from '@seedlang/stores';
import { Link } from 'react-router';

const Wrapper = styled.div`

`;

@observer
class TranslationSourceEdit extends React.Component {

  constructor(props) {
    super(props);
    TranslationSourceStore.getShow({ids: {translationSourceId: this.props.params.translationSourceId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    TranslationSourceStore.setShowData(resp);
  }

  render() {
    return (
      <Wrapper>
        {
          TranslationSourceStore.hasShowData &&
            <div>
              <div className='row'>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Source</legend>
                    {TranslationSourceStore.showData.source.text}
                  </fieldset>
                </div>
                <div className='col-xs-6'>
                  <fieldset>
                    <legend>Translation</legend>
                    <Link
                      to={{name: 'builder.words.edit', params: {wordId:TranslationSourceStore.showData.translation.id}}}
                    >
                      {TranslationSourceStore.showData.translation.targetText}
                    </Link>
                  </fieldset>
                </div>
              </div>
            </div>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TranslationSourceEdit);
