/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable object-curly-spacing */
/* eslint-disable object-curly-newline */
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants/src';
import { AppUI } from '@seedlang/state';
import { CampaignStore, CustomTabStore, GridItemWorksheetStore, WorksheetCustomTabStore, WorksheetStore } from '@seedlang/stores';
import { isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Alert from 'components/alert';
import DeleteButton from 'components/button/delete_button';
import CheckIfTrue from 'components/check_if_true';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import InPlaceText from 'components/form/in_place_text';
import GridItemSearch from 'components/grid_item_search';
import InfoTooltip from 'components/info_tooltip';
import Modal from 'components/modal';
import PodcastEpisodeSearch from 'components/podcast_episode_search';
import Spinner from 'components/spinner';
import Text from 'components/text';
import Tags from 'components/worksheet/tags';
import { autorun, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import DownloadLinkEdit from 'pages/creator/download_links/download_link_edit';
import ExerciseCreate from 'pages/creator/exercises/exercise_create';
import ExerciseIndex from 'pages/creator/exercises/exercise_index';
import MembershipTypeSelect from 'pages/creator/membership_types/membership_type_select';
import TagEdit from 'pages/creator/tags/tag_edit';
import React from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import BackEndExplainer from '../back_end_explainer';

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  .gray-button {
    margin-bottom: 10px;
  }
`;

const TableText = styled.div`
  text-transform: uppercase;
  display: flex;
`;

const SubText = styled.div`
  font-style: italic;
  font-size: 12px;
`;

const Wrapper = styled.div`
  input, textarea {
    border: 0px solid #CCC!important;
  }
  table {
    border: 0px;
    td {
      padding: 5px;
    }
    tr {
      background: transparent!important;
    }
  }
`;

const PodcastEpisode = styled.div`
  display: flex;
  margin-top: 5px;
  padding: 5px;
  background: #FFF;
  font-size: 16px;
`;

const Title = styled.div`
  flex: 1;
`;

const InvalidIconWrapper = styled.div`
  text-transform: none;
  margin: ${props => props.margin};
`;

const MembershipTypesRow = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  cursor: pointer;
  .fa {
    color: ${props => props.active ? '#000' : '#CCC'};
    margin: 0 8px 0 2px;
    font-size: 18px;
  }
`;

const NameDetails = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DatePickerRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ImageWrapper = styled.div`
  position: relative;
  display: inline-block;
  .info-tooltip {
    position: absolute;
    left: 0;
    background: green;
    color: white;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    font-size: 14px;
  }
  .fa-times {
    position: absolute;
    right: 0;
    background: red;
    color: white;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    font-size: 14px;
    cursor: pointer;
  }
  img {
    margin-top: 10px;
    max-width: 180px;
  }
`;

const Image = styled.div`
  background-size: cover;
  background-position: center center;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-image: ${props => props.backgroundImage};
`;

const Documentation = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px; 
  margin: 5px 0;
  background: #e4e4e4;
  padding: 5px 10px;
  border-radius: 5px;
  span {
    white-space: pre;
  }
  i {
    margin-right: 10px;
  }
`;

@observer
class WorksheetEdit extends React.Component {
  @observable exercises = [];
  @observable editTags = false;
  @observable editMemberships= false;
  @observable editDownloadLinks = false;
  @observable showPublicationAlert = false;
  @observable showNotificationAlert = false;
  @observable pdfGenerating = false;
  @observable askForPdfGenerationConfirm = false;
  @observable showPdfGeneratedConfirmation = false;
  @observable showSlowPdfGenerationWarning = false;
  @observable showEmailTestMessage = false;
  @observable showEmailTestSpinner = false;
  @observable creatingCustomTab = false;
  @observable worksheetDownloadLinkType;
  @observable worksheetDownloadLink;
  @observable showAdvancedSettings = false;

  constructor(props) {
    super(props);
    this.getWorksheet();
    AppUI.downloadLinkTypeStore.getIndex();
    CustomTabStore.getIndex({filters: {show_on_podcast_worksheets: false, show_on_video_worksheets: false}});
    let loaded = false;

    autorun(() => {
      if (AppUI.downloadLinkTypeStore.hasIndexData && WorksheetStore.hasShowData && !loaded) {
        loaded = true;
        this.worksheetDownloadLinkType = AppUI.downloadLinkTypeStore.indexData.find(item => item.generatedPdfs);
        if (isPresent(this.worksheetDownloadLinkType)) {
          this.worksheetDownloadLink = WorksheetStore.showData.downloadLinks.find(item => item.downloadLinkTypeId === this.worksheetDownloadLinkType.id);
        }
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.params?.worksheetId !== this.props.params?.worksheetId) {
      this.getWorksheet();
    }
  }

  @autobind getWorksheet() {
    WorksheetStore.getShow({ids: {worksheetId: this.props.params.worksheetId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    WorksheetStore.setShowData(resp);
    this.exercises = WorksheetStore.showData.exercises;
    if (this.pdfGenerating) {
      this.pdfGenerating = false;
      this.worksheetDownloadLink = null;
      if (isPresent(this.worksheetDownloadLink)) {
        this.showPdfGeneratedConfirmation = true;
      } else {
        this.showSlowPdfGenerationWarning = true;
      }
    }
  }

  @autobind onAddGridItem(gridItem) {
    GridItemWorksheetStore.create({data: {gridItemId: gridItem.id, worksheetId: this.props.params.worksheetId}}, this.getWorksheet);
  }

  @autobind onRemoveGridItem(id) {
    WorksheetStore.removeGridItem({data: {grid_item_id: id, worksheet_id: this.props.params.worksheetId}}, this.getWorksheet);
  }

  @autobind addPodcastEpisode(podcastEpisode) {
    WorksheetStore.update({data: {podcastEpisodeId: podcastEpisode.id}, ids: {worksheetId: this.props.params.worksheetId}}, this.getWorksheet);
  }

  @autobind clearPodcastEpisode() {
    WorksheetStore.update({data: {podcastEpisodeId: null}, ids: {worksheetId: this.props.params.worksheetId}}, this.getWorksheet);
  }

  republish() {
    WorksheetStore.update({data: {published: true}, ids: {worksheetId: this.props.params.worksheetId}});
  }

  gotoPdfLink() {
    const url = `/worksheet_exports/${WorksheetStore.showData.id}.pdf?timestamp=${+new Date()}`;
    const win = window.open(url, '_blank');
    win.focus();
  }

  @autobind generatePdfLink() {
    this.pdfGenerating = true;
    this.askForPdfGenerationConfirm = false;
    if (isPresent(this.worksheetDownloadLink)) {
      this.worksheetDownloadLink.set('text', '');
    }

    const successCallback = (() => {
      this.getWorksheet();
    });

    const failureCallback = (() => {
      this.pdfGenerating = false;
    });

    WorksheetStore.generatePdfLink({
      ids: {worksheetId: this.props.params.worksheetId},
      failureCallback,
    }, successCallback);

    setTimeout(() => {
      if (this.pdfGenerating === true) {
        this.showSlowPdfGenerationWarning = true;
      }
    }, 5000);
  }

  @autobind onClickGeneratePdf() {
    if (isPresent(this.worksheetDownloadLink?.text) && isPresent(this.worksheetDownloadLinkType)) {
      this.askForPdfGenerationConfirm = true;
    } else {
      this.generatePdfLink();
    }
  }

  @autobind afterUpdate(resp) {
    if (isPresent(resp) && resp.wasPublished) {
      this.showPublicationAlert = true;
      this.showNotificationAlert = resp.wasNotified;
    } else {
      this.showPublicationAlert = false;
      this.showNotificationAlert = false;
    }
  }

  @autobind toggleYoutubeThumbnail() {
    WorksheetStore.update({data: {hideYoutubeThumbnail: !WorksheetStore.showData.hideYoutubeThumbnail}, ids: {worksheetId: this.props.params.worksheetId}}, this.getWorksheet);
  }

  @autobind onSendEmailTest() {
    this.showEmailTestSpinner = true;
    if (WorksheetStore.hasShowData && isPresent(WorksheetStore.showData.campaign)) {
      CampaignStore.sendCampaign({data: {test_sending: true}, ids: {campaignId: WorksheetStore.showData.campaign?.id}}, resp => this.afterSendCampaign(resp));
    }
  }

  @autobind afterSendCampaign(resp) {
    this.showEmailTestMessage = true;
    this.showEmailTestSpinner = false;
    setTimeout(() => this.showEmailTestMessage = false, 6000);
  }

  @computed get campaign() {
    if (!WorksheetStore.hasShowData) { return; }
    return WorksheetStore.showData.campaign;
  }

  @autobind createWorksheetCustomTab(customTabId) {
    if (isBlank(customTabId)) { return; }
    this.creatingCustomTab = true;
    WorksheetCustomTabStore.create(
      {
        data: {
          custom_tab_id: customTabId,
          worksheet_id: this.props.params.worksheetId,
        },
      }, this.afterCreateCustomTab);
  }

  @autobind afterCreateCustomTab(resp) {
    this.creatingCustomTab = false;
    this.getWorksheet();
    AppUI.routeStore.routeToNamed('creator.groups.worksheets_custom_tabs.edit', { groupId: this.props.params.groupId, worksheetId: this.props.params.worksheetId, worksheetCustomTabId: resp.id });
  }

  @autobind worksheetCustomTab(customTabId) {
    return WorksheetStore.hasShowData && isPresent(WorksheetStore.showData.worksheetCustomTabs) ? WorksheetStore.showData.worksheetCustomTabs.find(item => item.customTabId === customTabId) : null;
  }

  @autobind customTab(customTabId) {
    return CustomTabStore.indexData.find(item => item.id === customTabId);
  }

  @computed get remainingCustomTabs() {
    return CustomTabStore.indexData.filter(item => !WorksheetStore.showData.worksheetCustomTabs.find(item => item.customTabId === item.id));
  }

  @autobind onDestroyWorksheetCustomTab(worksheetCustomTabId) {
    WorksheetCustomTabStore.destroy({ids: {worksheetCustomTabId: worksheetCustomTabId}}, this.getWorksheet);
  }

  render() {
    return (
      <Wrapper>
        {
          this.editTags &&
            <Modal
              onCloseModal={() => this.editTags = false}
              height='550px'
              iconFill='#000'
            >
              <TagEdit
                groupId={this.props.params.groupId}
                worksheet={WorksheetStore.showData}
                onChange={this.getWorksheet}
              />
            </Modal>
        }
        {
          this.editMemberships &&
            <Modal
              onCloseModal={() => this.editMemberships = false}
              height='300px'
              width='450px'
              top='200px'
              iconFill='#000'
            >
              <MembershipTypeSelect
                groupId={this.props.params.groupId}
                worksheet={WorksheetStore.showData}
                onChange={this.getWorksheet}
              />
            </Modal>
        }
        {
          WorksheetStore.hasShowData && this.editDownloadLinks &&
            <Modal
              onCloseModal={() => this.editDownloadLinks = false}
              height='550px'
              iconFill='#000'
            >
              <DownloadLinkEdit
                groupId={this.props.params.groupId}
                worksheet={WorksheetStore.showData}
                onChange={this.getWorksheet}
              />
            </Modal>
        }
        <div className='worksheet-edit'>
          <div className='breadcrumbs'>
            <div>
              <Link
                to={{name: 'creator.groups.worksheets.index', params: {groupId: this.props.params.groupId}}}
              >
                Posts
              </Link>
            </div>
            <i className='fa fa-angle-double-right' />
            <div className='current'>
              {WorksheetStore.showData.name}
            </div>
          </div>
          {
            AppUI.site.blockPostNotifications && !WorksheetStore.showData.published &&
              <Alert margin='5px 0'>{`User will not be notified upon publication, because all notifications are currently blocked. ${AppUI.user.canAccessAdminSite ? 'This can be modified in the site settings.' : ''}`}</Alert>
          }
          {
            this.showPublicationAlert &&
              <Alert background={Theme.green} margin='5px 0'>{`The post was just published${this.showNotificationAlert ? ' and users were notified' : '. Users were not notified.'}`}</Alert>
          }
          <div className='row'>
            <div className='col-xs-5'>
              <fieldset style={{height: '100%'}}>
                <legend>Name</legend>
                <InPlaceText
                  submitOnEnter
                  defaultValue={WorksheetStore.showData.name}
                  model='worksheets'
                  field='name'
                  id={WorksheetStore.showData.id}
                  updateCallback={this.afterUpdate}
                />
                <NameDetails>
                  <Tags
                    withEpisodeCode
                    worksheet={WorksheetStore.showData}
                  />
                  <InfoTooltip>
                    <div>Follow this format for video episode numbers:</div>
                    <div>"(SEG32)" at the end of the name</div>
                  </InfoTooltip>
                </NameDetails>
                <button
                  type='button'
                  className='gray-button'
                  style={{height: '20px', marginTop: '10px'}}
                  onClick={() => this.editTags = true}
                >
                  <i className='fa fa-angle-right' />
                  Edit Tags ({WorksheetStore.showData.tagsCount} Tag{WorksheetStore.showData.tagsCount === 1 ? '' : 's'})
                </button>
              </fieldset>
            </div>
            <div className='col-xs-4'>
              <fieldset style={{height: '100%'}}>
                <legend>Settings</legend>
                <div
                  style={{display: 'flex', justifyContent: 'space-between'}}
                >
                  <span
                    data-tip
                    data-for='published'
                  >
                    <CheckIfTrue
                      value={WorksheetStore.showData.published}
                      icon='check'
                      label='Published'
                      iconFontSize='18px'
                    />
                    {
                      (WorksheetStore.showData.publishedAt?.isFuture || isBlank(WorksheetStore.showData.publishedAt)) &&
                        <ReactTooltip
                          type='dark'
                          effect='solid'
                          id='published'
                          class='custom-tooltip'
                        >
                          {
                            !WorksheetStore.showData.published && WorksheetStore.showData.publishedAt?.isFuture &&
                              <div>{`This post will be published automatically ${WorksheetStore.showData.publishedAt.relativeTime}.`}</div>
                          }
                          {
                            !WorksheetStore.showData.published && isBlank(WorksheetStore.showData.publishedAt) &&
                              <div>Set a publication date to publish this post.</div>
                          }
                          {
                            WorksheetStore.showData.published &&
                              <div>To unpublish, delete the publication date or set it in the future.</div>
                          }
                        </ReactTooltip>
                    }
                  </span>
                  <div>
                    <InPlaceCheckbox
                      value={WorksheetStore.showData.pinned}
                      field='pinned'
                      model='worksheets'
                      id={WorksheetStore.showData.id}
                      afterChange={this.getWorksheet}
                      icon='check'
                    >
                      Pinned
                      <InfoTooltip>
                        <div>Pinned posts will be displayed at the top of the grid, </div>
                        <div>except in Grid Sections, which have their own order.</div>
                      </InfoTooltip>
                    </InPlaceCheckbox>
                  </div>
                </div>
                <div>
                  <InPlaceCheckbox
                    value={WorksheetStore.showData.accessWithoutMembership}
                    field='access_without_membership'
                    model='worksheets'
                    id={WorksheetStore.showData.id}
                    afterChange={this.getWorksheet}
                    icon='check'
                  >
                    Can Access without Membership
                  </InPlaceCheckbox>
                </div>
                <MembershipTypesRow
                  active={isPresent(WorksheetStore.showData.worksheetMembershipTypes)}
                  onClick={() => this.editMemberships = true}
                >
                  <i className={`fa fa-${isPresent(WorksheetStore.showData.worksheetMembershipTypes) ? 'lock' : 'unlock'}`} />
                  {
                    isBlank(WorksheetStore.showData.worksheetMembershipTypes) &&
                      'Accessible with all membership tiers'
                  }
                  {
                    isPresent(WorksheetStore.showData.worksheetMembershipTypes) &&
                      `Accessible with ${WorksheetStore.showData.membershipTypeNames}`
                  }
                </MembershipTypesRow>
                {
                  WorksheetStore.hasShowData &&
                  <Link
                    to={{name: 'worksheets.show', params: {worksheetId: WorksheetStore.showData.id}}}
                    className='underline'
                    style={{fontSize: 12}}
                  >
                    Go to Post
                  </Link>
                }
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset style={{height: '100%'}}>
                <legend style={{marginBottom: '-15px'}}>Publication
                  {
                    !WorksheetStore.showData.published &&
                      <InfoTooltip position='left'>
                        <div>The post will be immediately published if you set a <b>publication date</b> in the past.
                        </div>
                        <div>If the date is set in the future, the publication will be scheduled.</div>
                      </InfoTooltip>
                  }
                </legend>
                {
                  !AppUI.site.blockPostNotifications &&
                    <InPlaceCheckbox
                      value={WorksheetStore.showData.skipNotification}
                      field='skip_notification'
                      model='worksheets'
                      id={WorksheetStore.showData.id}
                      afterChange={this.getWorksheet}
                      icon='check'
                      disabled={WorksheetStore.showData.published}
                    >
                      <i className='fa fa-mail' />
                      Do Not Notify Members
                    </InPlaceCheckbox>
                }
                <DatePickerRow>
                  <InPlaceDatePicker
                    withTime
                    label='Post publication date'
                    model='worksheets'
                    field='published_at'
                    id={WorksheetStore.showData.id}
                    value={WorksheetStore.showData.publishedAt}
                    afterChange={this.getWorksheet}
                    timeIntervals={60}
                    updateCallback={this.afterUpdate}
                  />
                  <InfoTooltip position='left'>This is the date and time when the post will be published (in your local time).</InfoTooltip>
                  {
                    !WorksheetStore.showData.isValid &&
                      <InvalidIconWrapper
                        data-tip
                        data-for='valid-publish'
                      >
                        <i className='fa fa-warning' />
                        <ReactTooltip
                          type='dark'
                          effect='solid'
                          id='valid-publish'
                          class='custom-tooltip'
                          place='left'
                        >
                          <div>Look at the warning icons on the page</div>
                          <div>to make the post valid.</div>
                        </ReactTooltip>
                      </InvalidIconWrapper>
                  }
                  {
                    WorksheetStore.showData.isValid && WorksheetStore.showData.hasUnpublishedVocab &&
                      <InvalidIconWrapper
                        data-tip
                        data-for='valid-publish-vocab'
                      >
                        <i className='fa fa-warning' />
                        <ReactTooltip
                          type='dark'
                          effect='solid'
                          id='valid-publish-vocab'
                          class='custom-tooltip'
                          place='left'
                        >
                          <div>This post is valid but</div>
                          <div>has unpublished vocab ↓</div>
                        </ReactTooltip>
                      </InvalidIconWrapper>
                  }
                </DatePickerRow>
                <DatePickerRow>
                  <InPlaceDatePicker
                    label='Content publication date'
                    model='worksheets'
                    field='content_published_at'
                    id={WorksheetStore.showData.id}
                    value={WorksheetStore.showData.contentPublishedAt}
                    afterChange={this.getWorksheet}
                    updateCallback={this.afterUpdate}
                    isClearable={false}
                  />
                  <InfoTooltip position='left'>
                    <div>This is the original publication date of the video or podcast.</div>
                  </InfoTooltip>
                </DatePickerRow>
                {
                  !WorksheetStore.showData.isValid && !WorksheetStore.showData.published && WorksheetStore.showData.publishedAt?.isFuture &&
                    <Alert margin='5px 0'>{`If this post is still invalid on ${WorksheetStore.showData.publishedAt.formattedDate}, it will not be published.`}</Alert>
                }
                {
                  !WorksheetStore.showData.isValid && !WorksheetStore.showData.published && WorksheetStore.showData.publishedAt?.isPast &&
                    <Alert margin='5px 0'>This post hasn't been published because it's invalid.</Alert>
                }
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className={isBlank(WorksheetStore.showData.youtubeThumbnailUrl) && isBlank(WorksheetStore.showData.image) ? 'col-xs-6' : 'col-xs-12'}>
              <fieldset>
                <legend>Youtube Episode</legend>
                <table>
                  <tbody>
                    <tr>
                      <td width='130px'>
                        <TableText>
                          Thumbnail
                        </TableText>
                      </td>
                      <td>
                        <InPlaceImageUpload
                          model='images'
                          image={WorksheetStore.showData.image}
                          margin='0'
                          onChange={this.getWorksheet}
                          querystring={{
                            imageable_id: WorksheetStore.showData.id,
                            imageable_type: 'Worksheet',
                          }}
                          updateCallback={this.afterUpdate}
                        />
                        {
                          WorksheetStore.showData.youtubeUrlIsValid && WorksheetStore.showData.hideYoutubeThumbnail &&
                            <div style={{padding: '6.3px 10.5px'}}>
                              <button
                                type='button'
                                style={{height: '22px'}}
                                onClick={this.toggleYoutubeThumbnail}
                              >
                                Load from Youtube
                              </button>
                            </div>
                        }
                        {
                          WorksheetStore.showData.youtubeUrlIsValid && isPresent(WorksheetStore.showData.youtubeThumbnailUrl) && !WorksheetStore.showData.hideYoutubeThumbnail && isBlank(WorksheetStore.showData.image) &&
                            <ImageWrapper>
                              <i
                                className='fa fa-times'
                                onClick={this.toggleYoutubeThumbnail}
                              />
                              <InfoTooltip color='white'><div>This image is automatically retrieved from Youtube.</div><div>You can upload another file if you prefer.</div></InfoTooltip>
                              <img alt='' src={WorksheetStore.showData.youtubeThumbnailUrl} />
                            </ImageWrapper>
                        }
                      </td>
                    </tr>
                    <tr>
                      <td width='130px'>
                        <TableText>
                          Youtube URL
                          {
                            !WorksheetStore.showData.youtubeUrlIsValid && isBlank(WorksheetStore.showData.podcastEpisode) &&
                              <InvalidIconWrapper
                                data-tip
                                data-for='youtube-url'
                              >
                                <i className='fa fa-warning' />
                                <ReactTooltip
                                  type='dark'
                                  effect='solid'
                                  id='youtube-url'
                                  class='custom-tooltip'
                                >
                                  The Youtube URL must start with 'https://www.youtube.com/'
                                </ReactTooltip>
                              </InvalidIconWrapper>
                          }
                        </TableText>
                      </td>
                      <td>
                        <InPlaceText
                          show
                          submitOnEnter
                          defaultValue={WorksheetStore.showData.youtubeUrl}
                          model='worksheets'
                          field='youtube_url'
                          id={WorksheetStore.showData.id}
                          afterChange={this.getWorksheet}
                          updateCallback={this.afterUpdate}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </fieldset>
            </div>
            {
              isBlank(WorksheetStore.showData.youtubeThumbnailUrl) && isBlank(WorksheetStore.showData.image) &&
                <div className='col-xs-6'>
                  <fieldset style={{height: '100%'}}>
                    <legend>Preview Text
                      <InfoTooltip>This will be used as a thumbnail if there is no thumbnail image.</InfoTooltip>
                      {
                        isBlank(WorksheetStore.showData.imageUrl) && isBlank(WorksheetStore.showData.previewText) &&
                        <InvalidIconWrapper
                          data-tip
                          data-for='preview-text'
                        >
                          <i className='fa fa-warning' />
                          <ReactTooltip
                            type='dark'
                            effect='solid'
                            id='preview-text'
                            class='custom-tooltip'
                          >
                            A post needs either a thumbnail or a preview text
                          </ReactTooltip>
                        </InvalidIconWrapper>
                      }
                    </legend>
                    <InPlaceText
                      maxLength={300}
                      defaultValue={WorksheetStore.showData.previewText}
                      show={isBlank(WorksheetStore.showData.imageUrl) && isBlank(WorksheetStore.showData.previewText)}
                      model='worksheets'
                      field='preview_text'
                      id={WorksheetStore.showData.id}
                      inputType='textarea'
                      afterChange={this.getWorksheet}
                    />
                  </fieldset>
                </div>
            }
          </div>
          <div className='row'>
            <div className={`col-xs-${AppUI.site.showWorksheetComments ? '9' : '12'}`}>
              <fieldset>
                <legend>Podcast Episode</legend>
                <PodcastEpisodeSearch
                  onSubmit={podcastEpisode => this.addPodcastEpisode(podcastEpisode)}
                />
                {
                  isPresent(WorksheetStore.showData.podcastEpisode) &&
                    <PodcastEpisode>
                      <Title>
                        <Link
                          to={{name: 'creator.podcast_episodes.edit', params: {podcastEpisodeId: WorksheetStore.showData.podcastEpisode.id, groupId: this.props.params.groupId}}}
                        >
                          {WorksheetStore.showData.podcastEpisode.title}
                        </Link>
                      </Title>
                      <div style={{width: '40px'}}>
                        <i
                          className='fa fa-times fa-on-click fa-block right'
                          onClick={this.clearPodcastEpisode}
                        />
                      </div>
                    </PodcastEpisode>
                }
              </fieldset>
            </div>
            {
              AppUI.site.showWorksheetComments &&
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Post Comments</legend>
                    <Row>
                      {
                        WorksheetStore.showData.hasForumThread &&
                        <InPlaceCheckbox
                          blockClassName
                          id={WorksheetStore.showData.forumThread.id}
                          value={WorksheetStore.showData.forumThread.hidden}
                          field='hidden'
                          model='forum_threads'
                          icon='check'
                          afterChange={this.getWorksheet}
                        >
                          Hide Comments
                        </InPlaceCheckbox>
                      }
                      {
                        WorksheetStore.showData.hasForumThread && WorksheetStore.showData.forumThread.hidden &&
                        <i className='fa fa-warning' />
                      }
                    </Row>
                    {
                      WorksheetStore.hasShowData &&
                      <Row>
                        <Link
                          to={{name: 'worksheets.comments.show', params: {worksheetId: WorksheetStore.showData?.id}}}
                          className='underline'
                          style={{fontSize: 12}}
                        >
                          Go to comments
                        </Link>
                      </Row>
                    }
                  </fieldset>
                </div>
            }
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Video or Podcast description</legend>
                { isBlank(WorksheetStore.showData.description) && <i className='fa fa-warning' /> }
                <InPlaceText
                  smallRichText
                  show={isBlank(WorksheetStore.showData.description)}
                  defaultValue={WorksheetStore.showData.description}
                  model='worksheets'
                  field='description'
                  id={WorksheetStore.showData.id}
                  afterChange={this.getWorksheet}
                  maxHeight='250px'
                  scrollable
                  updateCallback={this.afterUpdate}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>{`Vocab (${WorksheetStore.showData.worksheetWordsCount} words)`}</legend>
                {
                  WorksheetStore.showData.hasUnpublishedVocab &&
                    <Row>
                      <i className='fa fa-warning' />
                      <Text color='#ff5252' fontSize='14px' margin='0 10px'>This post has unpublished vocab.</Text>
                    </Row>
                }
                <Row>
                  <Link
                    to={{name: 'creator.groups.vocab.edit', params: {worksheetId: this.props.params.worksheetId, groupId: this.props.params.groupId}}}
                  >
                    <button
                      type='button'
                      className='gray-button'
                      style={{marginBottom: '10px'}}
                    >
                      <i className='fa fa-angle-right' />
                      Edit Vocab
                    </button>
                  </Link>
                </Row>
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Exercises</legend>
                <ExerciseCreate
                  withModal
                  worksheetId={this.props.params.worksheetId}
                  afterCreate={this.getWorksheet}
                />
                <ExerciseIndex
                  showPosition
                  afterUpdate={this.getWorksheet}
                  groupId={this.props.params.groupId}
                  worksheetId={this.props.params.worksheetId}
                  exercises={this.exercises}
                />
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Downloads</legend>
                <Row>
                  <button
                    type='button'
                    className='gray-button'
                    onClick={() => this.editDownloadLinks = true}
                  >
                    <i className='fa fa-angle-right' />
                    Edit Download Links ({WorksheetStore.showData.downloadLinks?.length} Link{WorksheetStore.showData.downloadLinks?.length === 1 ? '' : 's'})
                  </button>
                  <button
                    type='button'
                    className='gray-button'
                    onClick={this.onClickGeneratePdf}
                    disabled={this.pdfGenerating}
                  >
                    {this.pdfGenerating ? 'Generating PDF...' : (
                      <>
                        <i className='fa fa-file-pdf-o' />
                        Generate PDF <InfoTooltip>This will generate a PDF of the post. It will be added to the post's downloads.</InfoTooltip>
                      </>
                    )}
                  </button>
                  <a
                    type='button'
                    href={`/api/worksheets/${this.props.params.worksheetId}/export`}
                    target='_blank'
                    className='gray-button'
                    rel='noreferrer'
                  >
                    <i className='fa fa-download' />
                    Export Post
                    <InfoTooltip>
                      <div>This will export the post data in json and csv formats</div>
                      <div>so that it can be reuploaded to the platform in case of data loss.</div>
                    </InfoTooltip>
                  </a>
                </Row>
                {
                  this.askForPdfGenerationConfirm && isPresent(this.worksheetDownloadLinkType) && isPresent(this.worksheetDownloadLink) &&
                  <Alert
                    showCloseButton
                    onClose={() => this.askForPdfGenerationConfirm = false}
                  >
                    <div>{`The Download Link \'${this.worksheetDownloadLinkType.title}\' already has a link. Do you want to replace it with the newly generated PDF?`}</div>
                    <div style={{display: 'flex', gap: '20px'}}>
                      <div className='underline' onClick={this.generatePdfLink}>Replace with new generated PDF</div>
                      <a
                        href={this.worksheetDownloadLink.text}
                        target='_blank'
                        rel='noreferrer'
                      >
                        See Current PDF
                      </a>
                      <div className='underline' onClick={() => this.askForPdfGenerationConfirm = false}>Cancel</div>
                    </div>
                  </Alert>
                }
                {
                  this.showPdfGeneratedConfirmation &&
                  <Alert
                    showCloseButton
                    background={Theme.green}
                    onClose={() => this.showPdfGeneratedConfirmation = false}
                  >
                    <div>{`The PDF of the post was generated${isPresent(this.worksheetDownloadLinkType) && isPresent(this.worksheetDownloadLink) ? ` and added to \'${this.worksheetDownloadLinkType.title}\'` : ''}.`}</div>
                    <a href={this.worksheetDownloadLink.text}><i className='fa fa-download' /> Download PDF</a>
                  </Alert>
                }
                {
                  this.showSlowPdfGenerationWarning && !this.showPdfGeneratedConfirmation &&
                  <Alert
                    showCloseButton
                    onClose={() => this.showSlowPdfGenerationWarning = false}
                  >
                    The PDF is being generated. In a few minutes, refresh the page and check the download links.
                  </Alert>
                }
              </fieldset>
            </div>
          </div>
          <div className='backend-title' onClick={() => this.showAdvancedSettings = !this.showAdvancedSettings} style={{cursor: 'pointer'}}>
            {
              this.showAdvancedSettings ?
                <i className='fa fa-chevron-down' />
                :
                <i className='fa fa-chevron-right' />
            }
            Advanced Settings
          </div>
          {
            this.showAdvancedSettings &&
              <>
                {
                  isPresent(WorksheetStore.showData.campaign) && !AppUI.siteIsDefault && !AppUI.site.blockPostNotifications &&
                    <div className='row'>
                      <div className='col-xs-12'>
                        <fieldset>
                          <legend>Email Settings
                            <InfoTooltip icon={WorksheetStore.showData.published ? 'lock' : 'unlock'}>
                              These cannot be edited once the post has been published.
                            </InfoTooltip>
                          </legend>
                          <div className='col-xs-6'>
                            <InPlaceCheckbox
                              value={WorksheetStore.showData.skipNotification}
                              field='skip_notification'
                              model='worksheets'
                              id={WorksheetStore.showData.id}
                              afterChange={this.getWorksheet}
                              icon='check'
                              disabled={WorksheetStore.showData.published}
                            >
                              Do Not Notify Members on Publication
                            </InPlaceCheckbox>
                          </div>
                          {
                            WorksheetStore.showData.published && isPresent(WorksheetStore.showData.campaign?.campaignTypeId) &&
                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                <Text fontSize='16px'>
                                  {WorksheetStore.showData.campaign.campaignMessagesCount} email notifications were sent.
                                </Text>
                                <Text fontSize='16px'>
                                  <Link
                                    target='_blank'
                                    className='underline'
                                    to={{
                                      name: 'creator.campaigns.edit',
                                      params: {
                                        campaignId: WorksheetStore.showData.campaign.id,
                                        campaignTypeId: WorksheetStore.showData.campaign.campaignTypeId,
                                        groupId: this.props.params.groupId,
                                      },
                                    }}
                                  >
                                    See More Information
                                  </Link>
                                </Text>
                              </div>
                          }
                          {
                            !WorksheetStore.showData.published &&
                            <>
                              <div className='row'>
                                <div className='col-xs-6'>
                                  <InPlaceCheckbox
                                    icon='check'
                                    id={this.campaign?.id}
                                    value={this.campaign?.greetByName}
                                    model='campaigns'
                                    field='greet_by_name'
                                    disabled={WorksheetStore.showData.published}
                                  >
                                    {`Add greeting before the body of the email: ${this.campaign?.greeting(AppUI.user)}`}
                                  </InPlaceCheckbox>
                                  <InPlaceText
                                    id={this.campaign?.id}
                                    defaultValue={this.campaign?.customGreeting}
                                    model='campaigns'
                                    field='custom_greeting'
                                    placeholder='Custom greeting'
                                    afterChange={val => this.campaign?.set('customGreeting', val)}
                                    disabled={WorksheetStore.showData.published}
                                    show
                                  />
                                </div>
                              </div>
                              <legend style={{marginTop: '10px'}}>Email Body</legend>
                              <InPlaceText
                                smallRichText
                                defaultValue={this.campaign?.mailingBody}
                                model='campaigns'
                                field='mailing_body'
                                id={this.campaign?.id}
                                afterChange={this.getWorksheet}
                                maxHeight='200px'
                                scrollable
                                placeholder="If left empty, the post's description will be used as the email's body."
                                blockClickToEdit={WorksheetStore.showData.published}
                              />
                              <Row>
                                <button
                                  type='button'
                                  className='gray-button'
                                  onClick={this.onSendEmailTest}
                                  style={{
                                    height: '48px',
                                    textAlign: 'center',
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {!this.showEmailTestSpinner && 'Send Test Email'}
                                  {this.showEmailTestSpinner && <Spinner background='gray' margin='0'/>}
                                </button>
                                {
                                  AppUI.user.developer && isPresent(WorksheetStore.showData.campaign?.campaignTypeId) &&
                                    <Link
                                      className='gray-button'
                                      target='_blank'
                                      to={{
                                        name: 'creator.campaigns.edit',
                                        params: {
                                          campaignId: WorksheetStore.showData.campaign.id,
                                          campaignTypeId: WorksheetStore.showData.campaign.campaignTypeId,
                                          groupId: this.props.params.groupId,
                                        },
                                      }}
                                    >
                                      See Email Campaign
                                    </Link>
                                }
                              </Row>
                              {
                                !this.showEmailTestSpinner &&
                                  <BackEndExplainer>
                                    {`Test emails will be sent to ${AppUI.user.email} and any BCC addresses if they exist.`}
                                  </BackEndExplainer>
                              }
                              {
                                this.showEmailTestMessage &&
                                <div className='alert'>
                                  An email has been sent to you. If you don't find it in your inbox, check your spam folder.
                                </div>
                              }
                              <Documentation>
                                <i className='fa fa-info-circle' />
                                <span> Use </span><code>%name%</code><span> as a placeholder to automatically insert the recipient's name in the greeting or email body.</span>
                              </Documentation>
                            </>
                          }
                        </fieldset>
                      </div>
                    </div>
                }
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>Grid Section</legend>
                      {
                        !AppUI.site.useGridLayout &&
                          <Row>
                            <i className='fa fa-warning' />
                            <Text
                              fontSize='14px'
                              margin='0 10px'
                            >
                              Grid sections are currently not displayed on the home page. This can be modified in the Site Settings.
                            </Text>
                          </Row>
                      }
                      <GridItemSearch
                        onSubmit={this.onAddGridItem}
                      />
                      {
                        WorksheetStore.hasShowData &&
                          <table style={{marginTop: '10px'}} className='table-wrapper admin-table'>
                            <thead>
                              <tr>
                                <th width='50' />
                                <th>Name</th>
                                <th>Posts Count</th>
                                <th width='40'></th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                WorksheetStore.showData.gridItems.map(item => {
                                  return (
                                    <tr key={item.id}>
                                      <td>
                                        <Link
                                          to={{ name: 'creator.grid_items.edit', params: { gridItemId: item.id, groupId: this.props.params.groupId } }}
                                          className='on-click'
                                        >
                                          <i className='fa fa-chevron-right fa-block' />
                                        </Link>
                                      </td>
                                      <td>
                                        <div className='text'>
                                          {item.name}
                                        </div>
                                      </td>
                                      <td>
                                        <div className='text'>
                                          {item.worksheetsCount}
                                        </div>
                                      </td>
                                      <td>
                                        <DeleteButton
                                          onConfirm={() => this.onRemoveGridItem(item.id)}
                                          message='Remove this grid row?'
                                          className='fa-block right'
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                      }
                    </fieldset>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <fieldset>
                      <legend>
                        {`Custom Tabs ${isPresent(WorksheetStore.showData.worksheetCustomTabs) ? `(${WorksheetStore.showData.worksheetCustomTabs?.length})` : ''}`}
                        <InfoTooltip>
                          <div>Custom tabs are used to display additional content on the post.</div>
                          <div>For example, you could set up a custom tab to embed an outtakes video.</div>
                        </InfoTooltip>
                      </legend>
                      {
                        CustomTabStore.requestCounter === 0 && CustomTabStore.indexData.length === 0 &&
                          <BackEndExplainer>You haven't set up any <Link className='underline' target='_blank' to={{ name: 'creator.groups.custom_tabs', params: { groupId: this.props.params.groupId } }}>custom tabs</Link> yet.</BackEndExplainer>
                      }
                      {
                        CustomTabStore.requestCounter === 0 && CustomTabStore.indexData.length > 0 &&
                          <table className='table-wrapper admin-table'>
                          <thead>
                            <tr>
                              <th width='50px' />
                              <th>Tab</th>
                              <th>Visibility</th>
                              <th width='40px'/>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              CustomTabStore.indexData.map(customTab => {
                                return (
                                  <tr key={customTab.id}>
                                    <td
                                      data-tip
                                      data-for={`worksheet-custom-tab-${customTab.id}`}
                                    >
                                      {
                                      isPresent(this.worksheetCustomTab(customTab.id)) &&
                                        <Link
                                          to={{ name: 'creator.groups.worksheets_custom_tabs.edit', params: { groupId: this.props.params.groupId, worksheetId: this.props.params.worksheetId, worksheetCustomTabId: this.worksheetCustomTab(customTab.id).id } }}
                                        >
                                          <i className='fa fa-chevron-right fa-block' />
                                        </Link>
                                      }
                                      {
                                        isBlank(this.worksheetCustomTab(customTab.id)) &&
                                          <ReactTooltip
                                            place='right'
                                            type='dark'
                                            effect='solid'
                                            id={`worksheet-custom-tab-${customTab.id}`}
                                            class='custom-tooltip'
                                          >
                                            No custom tab created for this template.
                                          </ReactTooltip>
                                      }
                                    </td>
                                    <td><i className={`fa fa-${customTab.icon}`} /> {customTab.title}</td>
                                    <td>{customTab.formattedMembershipTypeNames}</td>
                                    <td>
                                      {
                                        isBlank(this.worksheetCustomTab(customTab.id)) &&
                                          <div
                                            key={customTab.id}
                                            onClick={() => this.createWorksheetCustomTab(customTab.id)}
                                            style={{cursor: 'pointer'}}
                                            data-tip
                                            data-for={`create-worksheet-custom-tab-${customTab.id}`}
                                          >
                                            <i className='fa fa-plus fa-block' />
                                            <ReactTooltip
                                              place='left'
                                              type='dark'
                                              effect='solid'
                                              id={`create-worksheet-custom-tab-${customTab.id}`}
                                              class='custom-tooltip'
                                            >
                                              Create a custom tab from this template.
                                            </ReactTooltip>
                                          </div>
                                      }
                                      {
                                        isPresent(this.worksheetCustomTab(customTab.id)) &&
                                          <DeleteButton
                                            onConfirm={() => this.onDestroyWorksheetCustomTab(this.worksheetCustomTab(customTab.id).id)}
                                            message='Remove this custom tab?'
                                            className='fa-block right'
                                          />
                                      }
                                    </td>
                                  </tr>
                                );
                              })
                            }
                          </tbody>
                          </table>
                      }
                      {
                        this.creatingCustomTab &&
                          <Spinner />
                      }
                      {
                        CustomTabStore.requestCounter === 0 && CustomTabStore.indexData.length > 0 &&
                          <BackEndExplainer compact>
                            <div>Select Custom Tab templates and add them to this post. You can set up templates in the <Link className='underline' target='_blank' to={{ name: 'creator.groups.custom_tabs', params: { groupId: this.props.params.groupId } }}>Custom Tab settings</Link>.</div>
                            <div>Only custom tabs that are not set to be displayed on all posts are available to be added to posts manually.</div>
                          </BackEndExplainer>
                      }
                    </fieldset>
                  </div>
                </div>
              </>
          }
        </div>
      </Wrapper>
    );
  }
}

export default WorksheetEdit;
