import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { AppUI } from '@seedlang/state';
import ErrorBoundary from 'components/hoc/error_boundary';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import Checkbox from 'components/checkbox';
import Text from 'components/text';

const Wrapper = styled.div`
`;

const Row = styled.div`
  display: flex;
`;

const Expand = styled.div`
  font-size: 16px;
  flex: 1;
`;

@observer
class SettingsUserNotifications extends React.Component {
  @observable userAgreedToEmail;

  constructor(props) {
    super(props);
    this.disposer = autorun(() => {
      if (AppUI.userIsLoaded && !this.userIsLoaded) {
        this.userIsLoaded = true;
        this.userAgreedToEmail = AppUI.user?.currentUserGroup?.userAgreedToEmail === 'true';
      }
    });
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  @autobind onClickEmailConfirmation() {
    this.userAgreedToEmail = !this.userAgreedToEmail;
    AppUI.userGroupStore.update({ids: {userGroupId: AppUI.user.currentUserGroup?.id}, data: {user_agreed_to_email: this.userAgreedToEmail ? 'true' : 'false'}}, AppUI.loadUser);
  }

  @computed get userAgreedToEmailIsTrue() {
    return AppUI.user?.currentUserGroup?.userAgreedToEmail === 'true';
  }

  render() {
    return (
      <Wrapper>
        <Text
          heading='3'
          margin='0 0 20px 0'
        >
          Email Settings
        </Text>
        <Row>
          <Checkbox
            value={this.userAgreedToEmail}
            onClick={this.onClickEmailConfirmation}
            margin='5px 10px 0 0'
            width='20px'
            height='20px'
          />
        <Expand>
          {`I want to receive emails from ${AppUI.site.name || 'us'} about new content, membership updates, and marketing offers for related products. I can unsubscribe anytime.`}
        </Expand>
      </Row>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsUserNotifications);
