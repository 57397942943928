import React from 'react';
import { observer } from 'mobx-react';
import { ConjugationCardWrapper } from '@seedlang/hoc';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import CardSection from 'components/vocab_card/card_section';
import ConjugationCardPrompt from 'components/conjugation_card/conjugation_card_prompt';
import ConjugationCardRevealed from 'components/conjugation_card/conjugation_card_revealed';
import WordEntry from 'components/word_entry';
import TranslateButtons from 'components/vocab_card/translate_buttons';
import { DeckUI } from '@seedlang/state';
import HintWarning from 'components/card/hint_warning';
import { Constants } from '@seedlang/constants';

@observer
class ConjugationCardTextEntry extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent>
          <CardSection
            background='#FFF'
            numSections={this.props.revealed ? 1 : 2}
            borderTopRadius={10}
            borderBottomRadius={this.props.revealed ? 10 : 0}
            justifyContent={this.props.revealed ? 'auto' : 'center'}
          >
            {
              this.props.revealed &&
                <ConjugationCardRevealed
                  {...this.props}
                />
            }
            {
              !this.props.revealed &&
                <ConjugationCardPrompt
                  {...this.props}
                />
            }
          </CardSection>
          {
            !this.props.revealed &&
              <CardSection
                background='#CCC'
                numSections={2}
                borderBottomRadius={this.props.showUserInput ? 0 : 10}
              >
                <WordEntry
                  disabled={!this.props.inView}
                  focus={this.props.inView && DeckUI.firstCardHasAdvanced}
                  target={this.props.card.word.targetTextWithDefiniteArticle}
                  message={<span>Conjugate this <b>Verb</b></span>}
                  onChange={this.props.onTextInputChange}
                  onPressEnter={this.props.evaluateTextInput}
                  value={this.props.cardState.textEntry}
                  hint={this.props.hint}
                  languageId={this.props.card.languageId}
                />
                {
                  this.props.showMultipleWordsWarning &&
                    <HintWarning>
                      {Constants.WARNINGS['needsMoreWords']}
                    </HintWarning>
                }
                {
                  this.props.showAccentWarning &&
                    <HintWarning>
                      {Constants.WARNINGS['accents']}
                    </HintWarning>
                }
              </CardSection>
          }
        </CardContent>
        <TranslateButtons
          card={this.props.card}
          cardState={this.props.cardState}
          userCard={this.props.userCard}
          disabled={!this.props.inView}
          showHelpButton={this.props.showHelpButton}
          onHelpButtonClicked={this.props.onHelpButtonClicked}
          onShowAnswer={this.props.evaluateTextInput}
        />
      </CardWrapper>
    );
  }
}

export default ConjugationCardWrapper(ConjugationCardTextEntry);
