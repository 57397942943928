import React from 'react';
import { observer } from 'mobx-react';
import { BannerNoticeStore } from '@seedlang/stores';
import BannerNoticeCreate from 'pages/builder/banner_notices/banner_notice_create';
import { Link } from 'react-router';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import CheckIfTrue from 'components/check_if_true';
import { AppUI } from '@seedlang/state';
import BackEndExplainer from 'pages/creator/back_end_explainer';

@observer
class BannerNoticeIndex extends React.Component {

  componentDidMount() {
    BannerNoticeStore.getIndex();
  }

  @autobind onDelete(id) {
    BannerNoticeStore.destroy({ids: {bannerNoticeId: id}});
  }

  render() {
    return (
      <div className='chat-builder-index'>
        {
          !AppUI.siteIsDefault &&
            <BackEndExplainer margin='10px 0'>
              Banner notices can be displayed for selected groups of users on a given page, for a limited time (or not),
              along with a button.
              Users can dismiss these if they do not want to see them anymore.
            </BackEndExplainer>
        }
        <BannerNoticeCreate />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th>Created</th>
              <th>Title</th>
              <th>{AppUI.siteIsDefault ? 'Web Published' : 'Published'}</th>
              {
                AppUI.siteIsDefault &&
                  <th>Mobile Published</th>
              }
              <th>Clicked Count</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              BannerNoticeStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: AppUI.siteIsDefault ? 'builder.banner_notices.edit' : 'creator.banner_notices.edit', params: { groupId: this.props.params.groupId, bannerNoticeId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td width='130'>
                      {item.createdAt.formatted}
                    </td>
                    <td>
                      {item.title}
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.webPublished}
                      />
                    </td>
                    {
                      AppUI.siteIsDefault &&
                        <td>
                          <CheckIfTrue
                            value={item.mobilePublished}
                          />
                        </td>
                    }
                    <td>
                      {item.clickedCount}
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this banner notice?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default BannerNoticeIndex;
