import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  max-width: 600px;
  video {
    margin-top: 10px;
    border-radius: 10px;
  }
`;

@observer
class MaintenanceMessage extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text
          heading='2'
          color={this.props.color}
          textAlign='center'
          margin='0 0 10px 0'
        >
          {this.props.headline}
        </Text>
        <Text
          color={this.props.color}
          textAlign='center'
          margin='0 0 10px 0'
        >
          We will be back shortly.  Thank you for your patience. <span role='img' aria-label='smile'>🙂</span>
        </Text>
        <video
          muted
          autoPlay
          playsInline
          loop
          id='video'
          width={this.props.width}
          height={this.props.width}
          preload='auto'
          src='/images/maintenance.mp4'
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MaintenanceMessage);
