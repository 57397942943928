import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import LineChartWithControls from 'components/visualization/line_chart_with_controls';

const Wrapper = styled.div`

`;

@observer
class UsersDashboard extends React.Component {

  render() {
    return (
      <Wrapper>
        <fieldset
          style={{marginBottom: 10}}
        >
          <LineChartWithControls
            namespace='user-dashboard-new-users'
            charts={[
              {
                name: 'New Users',
                resources: [
                  {
                    name: 'User',
                    label: 'All Users',
                  },
                  {
                    name: 'User',
                    label: 'Guests',
                    query: 'GUEST = true',
                  },
                  {
                    name: 'User',
                    label: 'Registered',
                    query: 'GUEST = false',
                  },
                ],
              },
              {
                name: 'Converted Users',
                resources: [
                  {
                    name: 'User',
                    label: 'Registered Users',
                    query: 'GUEST = false',
                  },
                  {
                    name: 'User',
                    label: 'Converted Users',
                    query: 'GUEST = false AND permanent_membership = false AND subscriptions_count > 0',
                  },
                  {
                    name: 'User',
                    label: 'Converted Users < 1 Week',
                    query: 'GUEST = false AND permanent_membership = false AND subscriptions_count > 0 AND number_of_days_before_pro < 8',
                  },
                ],
              },
              {
                name: 'Users With Promotions',
                resources: [
                  {
                    name: 'User',
                    label: 'All Users',
                  },
                  {
                    name: 'User',
                    label: 'Users With Promotion Code',
                    query: 'signed_up_with_promotion_id IS NOT NULL',
                  },
                  {
                    name: 'User',
                    label: 'Users Without Promotion Code',
                    query: 'signed_up_with_promotion_id IS NULL',
                  },
                ],
              },
              {
                name: 'Users With Specific Promotions',
                resources: [
                  {
                    name: 'User',
                    label: 'App',
                    query: 'signed_up_with_promotion_id = \'5652b8d2-d1fe-439c-82a9-a1c617fa2d53\'',
                  },
                  {
                    name: 'User',
                    label: 'Vocab',
                    query: 'signed_up_with_promotion_id = \'d1128e36-7a4a-4585-b705-279f2cfca673\'',
                  },
                  {
                    name: 'User',
                    label: '5cm',
                    query: 'signed_up_with_promotion_id = \'d8e365b8-087d-48a4-a41f-6ed1c07dc372\'',
                  },
                  {
                    name: 'User',
                    label: 'Trivia',
                    query: 'signed_up_with_promotion_id = \'07eb3fae-93e4-42c8-b4dc-7d40c94f8ba5\'',
                  },
                  {
                    name: 'User',
                    label: 'Teaser',
                    query: 'signed_up_with_promotion_id = \'3a4e333c-e381-4443-85bc-4debddcc30c3\'',
                  },
                ],
              },
            ]}
          />
        </fieldset>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(UsersDashboard);
