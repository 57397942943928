import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { observable } from 'mobx';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  .tree-description {
    max-width: 450px;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: #EFEFEF;
    border-radius: 10px;
    height: ${props => props.height};
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.3);
  }
  img {
    max-width: 600px;
    width: 90%;
  }
`;

@observer
class TreeDescription extends React.Component {
  @observable treeOffset = 0;
  @observable wrapperHeight = 450;

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  @autobind onScroll() {
    if (this.refs.treeSliceWrapper) {
      const imageHeight = 500;
      const clientHeight = document.documentElement.clientHeight;
      const currentPosition = clientHeight - this.refs.treeSliceWrapper.getBoundingClientRect().top;
      const endPosition = this.wrapperHeight + clientHeight;
      const inView = currentPosition > 0 && currentPosition < endPosition;
      if (inView) {
        const percentageComplete = (currentPosition / endPosition);
        const offset = percentageComplete * imageHeight * -1;
        this.treeOffset = offset;
      }
    }
  }

  render() {
    return (
      <Wrapper
        height={pixify(this.wrapperHeight)}
      >
        <div
          ref='treeSliceWrapper'
          className='tree-description'
        >
          <img
            src='/images/tree_slice.jpg'
            ref='treeSlice'
            alt=''
            style={{
              transform: `translate3d(0%, ${this.treeOffset}px, 0px)`,
              zIndex: 9,
            }}
          />
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TreeDescription);
