import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import SegmentedTriangle from 'components/svg/segmented_triangle';
import { flexCenterColumn } from '@seedlang/style_mixins';
import ReactTooltip from 'react-tooltip';
import LevelBadge from 'components/level_badge';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  padding: ${props => props.padding};
`;

const Content = styled.div`
  position: relative;
  ${flexCenterColumn()}
`;

const BadgeWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  width: ${props => props.width};
  margin: 0 auto;
`;

const TriangleWrapper = styled.div`
  svg {
    width: 220px;
    margin-bottom: 10px;
    #segment-1 { fill: ${props => props.bgSegment1}; }
    #segment-2 { fill: ${props => props.bgSegment2}; }
    #segment-3 { fill: ${props => props.bgSegment3}; }
    #segment-4 { fill: ${props => props.bgSegment4}; }
    #segment-5 { fill: ${props => props.bgSegment5}; }
    #segment-6 { fill: ${props => props.bgSegment6}; }
    #segment-7 { fill: ${props => props.bgSegment7}; }
    #segment-8 { fill: ${props => props.bgSegment8}; }
    #segment-9 { fill: ${props => props.bgSegment9}; }
    #segment-10 { fill: ${props => props.bgSegment10}; }
  }
`;

@observer
class ProgressTriangleWithLevel extends React.Component {

  static defaultProps = {
    padding: '20px 0 0 0',
  }

  backgroundForSegment(num) {
    const progress = Math.ceil((AppUI.targetLanguage.progressOnCurrentLevel / AppUI.targetLanguage.xpRangeBetweenLevels) * 10);
    return progress >= num ? Theme.green : '#e6e6e6';
  }

  render() {
    return (
      <Wrapper
        data-tip
        data-for='xp-progress'
        padding={this.props.padding}
      >
        {
          AppUI.hasTargetLanguage &&
            <Content>
              <BadgeWrapper
                width='50px'
              >
                <LevelBadge
                  width='50px'
                  fontSize='16px'
                  level={AppUI.targetLanguage?.xpLevel}
                />
              </BadgeWrapper>
              <TriangleWrapper
                bgSegment1={this.backgroundForSegment(1)}
                bgSegment2={this.backgroundForSegment(2)}
                bgSegment3={this.backgroundForSegment(3)}
                bgSegment4={this.backgroundForSegment(4)}
                bgSegment5={this.backgroundForSegment(5)}
                bgSegment6={this.backgroundForSegment(6)}
                bgSegment7={this.backgroundForSegment(7)}
                bgSegment8={this.backgroundForSegment(8)}
                bgSegment9={this.backgroundForSegment(9)}
                bgSegment10={this.backgroundForSegment(10)}
              >
                <SegmentedTriangle />
              </TriangleWrapper>
            </Content>
        }
        {
          AppUI.hasTargetLanguage &&
            <ReactTooltip
              place='bottom'
              type='dark'
              effect='solid'
              id='xp-progress'
              class='custom-tooltip'
            >
              <div>{AppUI.targetLanguage.xp} total XP</div>
              <div>{AppUI.targetLanguage.xpForNextLevel - AppUI.targetLanguage.xp} XP until next level</div>
            </ReactTooltip>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ProgressTriangleWithLevel);
