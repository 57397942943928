import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { AppUI } from '@seedlang/state';
import { UserIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    margin: 0;
    text-align: center;
    line-height: 30px;
  }
`;

const Intro = styled.div`
  margin: 20px 0;
`;

const TeamMembers = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.flexDirection};
`;

const TeamMember = styled.div`
  width: 350px;
  background: white;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
`;

const Image = styled.div`
  width: 150px;
  height: 150px;
  background-size: cover;
  border-radius: 50%;
  margin-top: 10px;
  .user-icon {
    background: #333;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  svg {
    fill: #FFF;
    width: 80px;
    height: 80px;
  }
`;

const InfoColumn = styled.div`
  flex: 1;
  padding: 10px;
`;

const Name = styled.div`
  font-weight: bold;
  text-align: center;
`;

const Title = styled.div`
  text-align: center;
  font-style: italic;
  height: 30px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 22px;
  margin-top: 10px;
  a {
    text-decoration: underline;
  }
  p {
    margin: 0;
    padding: 0;
  }
`;

@observer
class Team extends React.Component {

  constructor(props) {
    super(props);
    AppUI.teamStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <Intro>
          <h2>We are a small team that is solving a big problem.</h2>
        </Intro>
        <TeamMembers
          flexDirection={AppUI.layout.isMobile ? 'column' : 'row'}
        >
          {
            !AppUI.teamStore.hasIndexData &&
              <Spinner />
          }
          {
            AppUI.teamStore.indexData.map(item => {
              return (
                <TeamMember
                  key={item.id}
                >
                  <Image
                    style={{
                      backgroundImage: `url(${item.imageUrl})`,
                    }}
                  />
                  <InfoColumn>
                    <Name>
                      {item.firstName}
                    </Name>
                    <Title>
                      {item.teamPageTitle}
                    </Title>
                    <Description
                      dangerouslySetInnerHTML={{ __html: item.teamPageBio }}
                    />
                  </InfoColumn>
                </TeamMember>
              );
            })
          }
          {
            AppUI.teamStore.hasIndexData &&
              <TeamMember>
                <Image>
                  <UserIcon />
                </Image>
                <InfoColumn>
                  <Name>
                    You?
                  </Name>
                  <Title />
                  <Description>
                    <p>
                      <span>We are looking for programmers and teachers to join the Seedlang team.  If you are passionate about helping to change the future of language learning, get in touch with us on our </span>
                      <a href='/contact' className='underline'>Contact Page</a>
                      <span>.</span>
                    </p>
                  </Description>
                </InfoColumn>
              </TeamMember>
          }
        </TeamMembers>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Team);
