import React from 'react';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { JobStore } from '@seedlang/stores';
import Cookies from 'js-cookie';

const Wrapper = styled.div`
  padding: 20px;
`;

const Row = styled.div`
  background: #FFF;
  cursor: pointer;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 30px;
  box-shadow: 0 2px 8px 0 rgba(0,0,0,.08);
  i {
    font-size: 30px;
  }
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 18px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

@observer
class JobsIndex extends React.Component {

  componentDidMount() {
    JobStore.getIndex();
  }

  componentWillUnmount() {
    JobStore.clearIndexData();
  }

  @computed get filteredJobs() {
    return JobStore.indexData.filter(item => item.siteId === Cookies.get('site_id') && item.published);
  }

  render() {
    return (
      <Wrapper>
        {
          this.filteredJobs.map(item => {
            return (
              <Link
                to={{name: 'jobs.show', params: { jobId: item.id }}}
              >
                <Row>
                  <TextWrapper>
                    <Title>
                      {item.title}
                    </Title>
                  </TextWrapper>
                  <i className='fa fa-angle-right' />
                </Row>
              </Link>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(JobsIndex);
