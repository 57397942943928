import React from 'react';
import { observer } from 'mobx-react';
import { FeedbackListWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { Constants } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';
import { AppUI } from '@seedlang/state';
import FeedbackItem from 'components/feedback/feedback_item';
import Emoticon from 'components/feedback/emoticon';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  margin-top: 10px;
  overflow: auto;
`;

const Count = styled.div`
  margin-left: 5px;
  color: #333;
  font-size: 11px;
  font-weight: bold;
`;

const TooltipText = styled.div`
  font-size: 12px;
`;

@observer
class FeedbackList extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.feedbackFrequencyList.map(item => {
            const feedbackItem = Constants.FEEDBACK_OPTIONS.find(fo => fo.feedbackType === item.feedbackType);
            if (feedbackItem) {
              return (
                <div
                  key={item.feedbackType}
                  data-tip
                  data-for={`${item.feedbackType}-${this.props.id}`}
                >
                  <FeedbackItem
                    removalPossible={item.users.find(user => user.id === AppUI.user.id)}
                    onClick={() => this.props.onClick(item)}
                  >
                    <Emoticon
                      image={Constants.FEEDBACK_OPTIONS.find(fo => fo.feedbackType === item.feedbackType).image}
                    />
                    <Count>
                      {item.frequency}
                    </Count>
                  </FeedbackItem>
                  <ReactTooltip
                    place='right'
                    type='dark'
                    effect='solid'
                    id={`${item.feedbackType}-${this.props.id}`}
                    class='custom-tooltip'
                  >
                    <TooltipText>
                      <div>{item.users.filter(item => isPresent(item.name)).map(item => item.name && item.name.trim()).join(', ')}</div>
                      <div>reacted with :{item.feedbackType}:</div>
                    </TooltipText>
                  </ReactTooltip>
                </div>
              );
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default FeedbackListWrapper(FeedbackList);
