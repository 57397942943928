import React from 'react';

class WhitelabelLogo extends React.Component {

  render() {
    return (
      <div className='seedlang-logo'>
        <img
          src={this.props.image?.url}
          alt={this.props.image?.id}
          style={{
            maxHeight: '45px',
          }}
        />
      </div>
    );
  }
}

export default WhitelabelLogo;
