import React from 'react';
import { observer } from 'mobx-react';
import { FaqItemStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';

@observer
class FaqItemCreate extends React.Component {

  onClick() {
    if (isPresent(this.refs.title.value)) {
      FaqItemStore.create(
        {
          data: {
            title: this.refs.title.value,
            faqCategoryId: this.props.faqCategoryId,
          },
        },
        this.props.afterChange,
      );
      this.refs.title.value = '';
    }
  }

  render() {
    return (
      <div className='concept-item-create row'>
        <div className='col-xs-10'>
          <input
            placeholder='Add a FAQ title'
            ref='title'
          />
        </div>
        <div className='col-xs-2'>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default FaqItemCreate;
