import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import AuthenticateRegister from 'components/authenticate/authenticate_register';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';

const AuthenticateRegisterWrapper = styled.div`
  padding: 0 20px;
  background: #FFF;
  border-radius: 10px;
  width: 100%;
  img {
    width: 14px!important;
  }
`;

const PatreonButton = styled.div`
  position: relative;
  color: #FFF;
  margin-top: 10px;
  display: flex;
  align-items: center;
  background: #333;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  img {
    width: 18px;
    margin-right: 5px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  font-size: 15px;
  right: 7px;
  top: 6px;
  font-size: 15px;
`;

const SignInLink = styled.div`
  margin: 20px 0 0px 0;
  text-align: center;
`;

@observer
class CheckoutRegistrationStep extends React.Component {
  constructor(props) {
    super(props);
  }

  @autobind showPatreonScreen() {
    AppUI.routeStore.routeToNamed('onboarding.patreon');
  }

  render() {
    return (
      <AuthenticateRegisterWrapper>

        <AuthenticateRegister
          width='100%'
          background='#FFF'
          borderRadius='5px'
          hideTitle
          hidePrivacy
          color='#333'
          signInCard
          checkout
          showEmailConfirmation={!AppUI.siteIsDefault}
          showTerms={!AppUI.siteIsDefault}
          afterRegistration={this.props.afterRegistration}
          onConfirmationRequired={this.props.onConfirmationRequired}
        />

        {this.renderAlternativesSection()}

      </AuthenticateRegisterWrapper>
    );
  }

  renderAlternativesSection() {
    return (
      <>
        {
          AppUI.showPatreonButton &&
          <Link
            onClick={this.showPatreonScreen}
          >
            <PatreonButton>
              <img alt='' src='/images/patreon_white.png' className='patreon-logo' />
              Connect with Patreon
              <IconWrapper
                data-for='patreon-notice'
                data-tip
              >
                <i className='fa fa-question-circle' />
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='patreon-notice'
                  className='custom-tooltip'
                >
                  <div>
                    <div>If you're an active Patreon member, click here</div>
                    <div>to link your account. Then, simply log in with</div>
                    <div>your Patreon credentials and authorize access.</div>
                  </div>
                </ReactTooltip>
              </IconWrapper>
            </PatreonButton>
          </Link>
        }
        {
          !AppUI.siteIsDefault &&
          <SignInLink>
            Member of Seedlang or another Easy Languages site? <a style={{ marginLeft: '5px' }} className='underline' href='/onboarding/sign_in'>Sign In.</a>
          </SignInLink>
        }
      </>
    );
  }
}

export default ErrorBoundary(CheckoutRegistrationStep);
