import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { findIndex } from 'lodash';
import autobind from 'autobind-decorator';
import cx from 'classnames';
import { isPresent } from '@seedlang/utils';
import FilterComponent from 'components/backend_filter/filter_component';
import FilterButton from 'components/backend_filter/filter_button';
import FilterButtonText from 'components/backend_filter/filter_button_text';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  input {
    width: 50px;
  }
`;

const FILTER_TYPES = [
  {
    symbol: '=',
    wrapper: 'EQ',
  },
  {
    symbol: '<',
    wrapper: 'LT',
  },
  {
    symbol: '>',
    wrapper: 'GT',
  },
];

@observer
class FilterNumber extends React.Component {
  @observable filterTypeIndex = 0;
  @observable value = this.props.default || 0;

  @computed get wrappedValue() {
    return FILTER_TYPES[this.filterTypeIndex].wrapper === 'EQ' ? this.value : `${FILTER_TYPES[this.filterTypeIndex].wrapper}[${this.value}]`;
  }

  componentDidMount() {
    this.unwrapValue(this.props.store.getFilterValue(this.props.name));
  }

  @autobind incrementFilterType() {
    if (!this.disabled(this.value)) {
      this.filterTypeIndex = this.filterTypeIndex >= FILTER_TYPES.length - 1 ? 0 : this.filterTypeIndex + 1;
      this.submitFilter();
    }
  }

  @autobind toggleActive() {
    this.value = this.disabled(this.value) ? (this.props.default || 0) : null;
    this.submitFilter();
  }

  @autobind onChange() {
    this.value = this.refs.input.value;
    this.submitFilter();
  }

  submitFilter() {
    if (this.disabled(this.value)) {
      this.props.store.removeFilter(this.props.name, { refresh: true });
    } else {
      this.props.store.setFilter(this.props.name, this.wrappedValue, { refresh: true });
    }
  }

  unwrapValue(value) {
    if (isPresent(value)) {
      if (isNaN(parseInt(value)) && value instanceof String) {
        this.value = value.substr(3, value.length - 4);
        this.filterTypeIndex = findIndex(FILTER_TYPES, item => item.wrapper === value.substr(0, 2));
      } else {
        this.value = value;
      }
    } else {
      this.value = '';
    }
  }

  disabled(value) {
    return value === null || value === undefined || value === '';
  }

  inputValue() {
    if (this.value === 0) {
      return '0';
    } else if (!this.disabled(this.value)) {
      return this.value;
    } else {
      return '';
    }
  }

  render() {
    return (
      <FilterComponent
        selected={isPresent(this.value)}
      >
        <Wrapper>
          <FilterButton
            onClick={this.toggleActive}
          >
            <i className={cx('fa', {
              'fa-circle': !this.disabled(this.value),
              'fa-ban': this.disabled(this.value),
            })}
            />
          </FilterButton>
          <FilterButton
            onClick={this.incrementFilterType}
          >
            <FilterButtonText
              disabled={this.disabled(this.value)}
            >
              {`${this.props.label} ${FILTER_TYPES[this.filterTypeIndex] ? FILTER_TYPES[this.filterTypeIndex].symbol : ''}`}
            </FilterButtonText>
          </FilterButton>
          <input
            onChange={this.onChange}
            ref='input'
            value={this.inputValue()}
          />
        </Wrapper>
      </FilterComponent>
    );
  }
}

export default FilterNumber;
