import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import TextResizer from 'components/text_resizer';
import Text from 'components/text';
import { flexCenter } from '@seedlang/style_mixins/src';
import { Theme } from '@seedlang/constants/src';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0px;
  margin-bottom: ${props => props.marginBottom};
  background: ${props => props.correct ? Theme.green : Theme.red};
  border-radius: 10px;
  width: ${props => props.width};
  ${flexCenter()}
  overflow: hidden;
  height: 40px;
  i {
    position: absolute;
    left: 6px;
    top: 3px;
    color: white;
    font-size: 18px;
  }
  .diff {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
  .diff, .no-diff {
    white-space: pre;
  }
`;

@observer
class Answer extends React.Component {

  static defaultProps = {
    width: '96%',
    correct: false,
  }

  render() {
    return (
      <Wrapper
        marginBottom={this.props.marginBottom}
        width={this.props.width}
        correct={this.props.correct}
      >
        <i className='fa fa-times' />
        <TextResizer
          resizeText
          height={60}
          padding={10}
          maximumFontSize={24}
          width={DeckUI.layout.deckWidth - 40}
          characterLength={this.props.text && this.props.text.length}
        >
          <Text
            bold
            color='#FFF'
          >
            {this.props.text}
          </Text>
        </TextResizer>
      </Wrapper>
    );
  }
}

export default Answer;
