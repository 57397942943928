import React from 'react';
import { ServerStore } from '@seedlang/stores';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  color: white;
  background: ${Theme.orange};
  z-index: ${Theme.z['topmost']};
  padding: 10px;
  font-size: 14px;
  line-height: 18px;
`;

@observer
class ServerAlert extends React.Component {

  render() {
    if (ServerStore.errorMessage) {
      return (
        <Wrapper>
          <i
            onClick={() => ServerStore.clearMessages()}
            className='fa fa-times'
          />
          {ServerStore.errorMessage}
        </Wrapper>
      );
    }
    return <span />;
  }
}

export default ServerAlert;
