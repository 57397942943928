import React from 'react';
import { observer } from 'mobx-react';
import { CreatorUI } from '@seedlang/state';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import DeleteButton from 'components/button/delete_button';
import { CheckWithCircleIcon } from '@seedlang/icons';
import { ConceptCategoryStore, LevelStore, ConceptStore } from '@seedlang/stores';
import cx from 'classnames';
import Filter from 'components/backend_filter/filter';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  .producer-concept {
    h3 {
      text-align: left;
      margin-bottom: 10px;
    }
    .icon-wrapper.selected {
      svg {
        fill: ${Theme.green};
      }
    }
  }
`;

@observer
class ProducerConcept extends React.Component {

  componentDidMount() {
    LevelStore.getIndex();
    if (!ConceptCategoryStore.hasOutstandingRequests) {
      ConceptCategoryStore.getIndex();
    }
  }

  @autobind onDeleteConcept(id) {
    ConceptStore.destroy({
      ids: {
        conceptId: id,
      },
    }, this.afterDeleteConcept);
  }

  @autobind afterDeleteConcept() {
    CreatorUI.conceptStore.reloadIndexFiltered();
  }

  render() {
    return (
      <Wrapper>
        <div className='producer producer-concept'>
          <div className='breadcrumbs-wrapper'>
            <div className='breadcrumbs'>
              <div className='custom-breadcrumb'>
                Concepts
              </div>
            </div>
          </div>
          <Filter
              store={CreatorUI.conceptStore}
              namespace='producer-concept-index'
              defaultSort='name'
              queryStrings={{include_count: true}}
              filters={
                [
                  {
                    type: 'text',
                    name: 'name',
                    label: 'Concept Name',
                  },
                  {
                    type: 'select',
                    name: 'language_id',
                    label: 'Language',
                    options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                    labelField: 'name',
                    default: '',
                  },
                  {
                    type: 'select',
                    name: 'concept_category_id',
                    options: ConceptCategoryStore.indexData,
                    label: 'Category',
                    labelField: 'name',
                  },
                  {
                    type: 'boolean',
                    name: 'has_all_video_clips',
                    label: 'Has All Videos',
                  },
                  {
                    type: 'multi_select',
                    name: 'level_id',
                    label: 'Level',
                    options: LevelStore.indexData.filter(item => item.abbreviation !== 'N' && item.abbreviation !== ''),
                    default: LevelStore.pluckIndex('id'),
                    labelField: 'abbreviation',
                  },
                  {
                    type: 'boolean',
                    name: 'decks_count',
                    label: 'Has deck',
                  },
                  {
                    type: 'number',
                    name: 'sentences_count',
                    label: 'Sentences Count',
                  },
                  {
                    type: 'boolean',
                    name: 'tree_node_concepts_count',
                    label: 'Is in tree node',
                  },
                ]
              }
          />
          <table
            className='table-wrapper'
            style={{marginTop: 20}}
          >
            <thead>
              <tr>
                <th width='20' />
                <th>Name</th>
                <th>Level</th>
                <th># Sentences</th>
                <th>Has All Videos</th>
                <th width='20' />
              </tr>
            </thead>
            <tbody>
              {
                CreatorUI.conceptStore.indexData.map(item => {
                  return (
                    <tr
                      key={item.id}
                    >
                      <td>
                        <Link
                          to={{ name: 'creator.producer', params: { conceptId: item.id } }}
                          className='on-click'
                        >
                          <i className='fa fa-chevron-right fa-block' />
                        </Link>
                      </td>
                      <td>
                        {item.name}
                      </td>
                      <td>
                        {item.level?.abbreviation}
                      </td>
                      <td>
                        {item.sentencesCount}
                      </td>
                      <td>
                        <CheckWithCircleIcon
                          className={cx({
                            selected: item.hasAllVideoClips,
                          })}
                        />
                      </td>
                      <td>
                        <DeleteButton
                          onConfirm={() => this.onDeleteConcept(item.id)}
                          message='Delete this concept?'
                          className='fa-block right'
                        />
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          <Paginator
            store={CreatorUI.conceptStore}
          />
        </div>
      </Wrapper>
    );
  }
}

export default ProducerConcept;
