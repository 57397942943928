import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import ConjugationTable from 'components/conjugation_table';
import StickerReaction from 'components/sticker_reaction';
import { AppUI , DeckUI } from '@seedlang/state';
import { last , capitalize } from 'lodash';
import { isPresent, isBlank, displayTargetTextCorrectedForLanguage } from '@seedlang/utils';
import VideoPlayerWithSupplement from 'components/media/video_player_with_supplement';
import { Theme } from '@seedlang/constants';
import { ConjugationCardRevealedWrapper } from '@seedlang/hoc';
import { BigSmileFaceIcon, NeutralFaceIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const SentenceWrapper = styled.div`
  margin: ${props => props.margin};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
`;

const TargetAndSource = styled.div`
  margin-bottom: 20px;
  font-style: italic;
`;

const Source = styled.div`
  margin: 10px 0 20px 0;
  font-style: italic;
`;

const Feedback = styled.div`
  background: ${props => props.background};
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  svg {
    width: 20px;
    height: 20px;
    fill: #FFF;
    position: absolute;
    top: 10px;
    left: 10px;
  }
`;

const RevealedText = styled.div`
  font-weight: bold;
  font-size: 16px;
  color: #FFF;
  margin: 10px 0 0 0;
  cursor: ${props => props.cursor};
`;

const ConjugationTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
`;

@observer
class ConjugationCardRevealed extends React.Component {

  render() {
    return (
      <Wrapper>
        <Feedback
          background={this.props.userCard.correct ? Theme.green : Theme.red}
        >
          {
            this.props.userCard.correct &&
              <BigSmileFaceIcon />
          }
          {
            !this.props.userCard.correct &&
              <NeutralFaceIcon />
          }
          {
            this.props.cardState.revealed && this.props.showVideo &&
              <VideoPlayerWithSupplement
                hasVideoClip
                autoPlay
                width={Math.floor(DeckUI.layout.deckWidth / 4.5)}
                videoClip={this.props.videoClip}
                afterVideoPlayback={this.props.afterVideoPlayback}
              />
          }
          {
            this.props.cardState.revealed && !this.props.showVideo &&
              <StickerReaction
                card={this.props.card}
                reaction={this.props.userCard.correct ? 'happy' : 'disappointed'}
              />
          }
          {
            isPresent(this.props.revealedText) &&
              <RevealedText
                onClick={this.props.onToggleEncouragement}
                cursor={this.props.userCard.correct ? 'pointer' : 'text'}
              >
                {this.props.revealedText}
              </RevealedText>
          }
        </Feedback>
        {
          isPresent(this.props.card.sentence) &&
            <SentenceWrapper
              margin='20px 0 0 0'
            >
              {displayTargetTextCorrectedForLanguage(capitalize(this.props.card.sentence.targetText), this.props.card.sentence.languageId)}
            </SentenceWrapper>
        }
        {
          isBlank(this.props.card.sentence) &&
            <SentenceWrapper
              margin='20px 0 10px 0'
            >
              <span>{this.props.personalPronoun}</span>
              <span style={{marginLeft: last(this.props.personalPronoun) === '\'' ? '0px' : '5px', textDecoration: 'underline'}}>{displayTargetTextCorrectedForLanguage(this.props.correctAnswer, this.props.card.languageId)}</span>
            </SentenceWrapper>
        }
       {
         isPresent(this.props.card.sentence) &&
            <Source>
              {this.props.card.sentence.sourceText}
            </Source>
       }
       {
          isBlank(this.props.card.sentence) &&
            <TargetAndSource>
              {this.props.card.word.targetText} -  {this.props.card.word.firstVocabSourceForUser(AppUI.user)}
            </TargetAndSource>
       }
       <ConjugationTableWrapper>
         <ConjugationTable
           inDeck
           hideHeader
           highlightPronoun={this.props.card.pronoun}
           padding='0'
           word={this.props.card.word}
           conceptId={this.props.tenseConceptId}
         />
       </ConjugationTableWrapper>
      </Wrapper>
    );
  }
}

export default ConjugationCardRevealedWrapper(ConjugationCardRevealed);
