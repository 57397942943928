import styled from '@emotion/styled';
import PrivacySettingsContent from 'components/privacy_settings_content.jsx';
import { observer } from 'mobx-react';
import React from 'react';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 40px;
  overflow: auto;
`;

@observer
class PrivacySettingsModal extends React.Component {
  render() {
    return (
      <Wrapper>
        <PrivacySettingsContent onCloseModal={this.props.onCloseModal} />
      </Wrapper>
    );
  }
}

export default PrivacySettingsModal;
