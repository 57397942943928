import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { pixify } from '@seedlang/utils';
import { LockIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.width};
  background: ${props => props.disabled ? '#928e8e' : props.background};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  svg {
    fill: #FFF;
    width: ${props => props.iconWidth};
    margin-top: ${props => props.iconMarginTop};
  }
`;

const InnerCircle = styled.div`
  ${flexCenter()}
  background: ${props => props.disabled ? '#CCC' : props.background};
  width: ${props => props.width};
  height: ${props => props.width};
  border-radius: 50%;
`;

const OuterCircle = styled.div`
  ${flexCenter()}
  background: ${props => props.disabled ? '#a19f9f' : props.background};
  width: ${props => props.width};
  height: ${props => props.width};
  border-radius: 50%;
`;

const LockWrapper = styled.div`
  z-index: ${Theme.z['foreground']};
  width: 0;
  height: 0;
  border-style: solid;
  border-width: ${props => props.smallLock ? '0 50px 50px 0' : '0 70px 70px 0'};
  border-color: transparent #777777 transparent transparent;
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
  .icon-wrapper {
    position: absolute;
    top: 20px;
    right: -56px;
  }
  .lock-icon {
    svg {
      fill: #FFF;
      width: 11px;
    }
  }
`;

@observer
class DeckLinkIconWrapper extends React.Component {

  static defaultProps = {
    iconMarginTop: '-7px',
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.props.width - this.props.widthOffsets[0])}
        disabled={this.props.disabled}
        iconWidth={pixify(this.props.iconWidth)}
        iconMarginTop={this.props.iconMarginTop}
        background={this.props.backgroundColors[0]}
      >
        {
          this.props.showLockIcon &&
            <LockWrapper
               smallLock={this.props.smallLock && AppUI.layout.isMobile}
            >
              <LockIcon />
            </LockWrapper>
        }
        <OuterCircle
          width={pixify(this.props.width - this.props.widthOffsets[1])}
          disabled={this.props.disabled}
          background={this.props.backgroundColors[1]}
        >
          <InnerCircle
            width={pixify(this.props.width - this.props.widthOffsets[2])}
            disabled={this.props.disabled}
            background={this.props.backgroundColors[2]}
          >
            {this.props.children}
          </InnerCircle>
        </OuterCircle>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DeckLinkIconWrapper);
