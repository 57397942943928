import React from 'react';
import { observer } from 'mobx-react';
import InPlaceText from 'components/form/in_place_text';
import DeleteButton from 'components/button/delete_button';
import { isBlank, isPresent } from '@seedlang/utils';
import { Link } from 'react-router';
import cx from 'classnames';
import { TreeNodeStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ReactTooltip from 'react-tooltip';
import { LightbulbOnIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  .fa-times {
    color: #CCC;
  }
  .empty {
    border: 1px solid transparent;
    justify-content: center;
    .fa {
      width: 15px;
      height: 15px;
      text-align: center;
      border-radius: 50%;
      border: 1px solid gray;
      font-size: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .builder-tree-node {
    background: white;
    margin: 5px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    font-size: 14px;
    position: relative;
    border: 1px solid gray;
    .star-icon {
      svg {
        width: 18px;
      }
    }
    .image-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      img {
        width: 150px;
        height: 150px;
        border-radius: 30px;
      }
    }
    a {
      width: 100%;
    }
    .grammar-title {
      font-style: italic;
      font-size: 14px;
      line-height: 16px;
      margin-top: 5px;
    }
    &.unpublished {
      background: #eaeaea;
    }
    &.published {
      background: #e2f13721;
    }
    .concepts {
      padding: 10px;
      width: 100%;
    }
    .fa-comment, .fa-arrow-up, .fa-arrow-down {
      color: white;
      &.inactive {
        color: #afafaf;
      }
    }
    .tree-node-header {
      background: #777;
      width: 100%;
      padding: 3px 0 5px 0;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .rating {
        flex: 1;
      }
      .rating-input {
        height: 14px;
        justify-content: flex-start;
        padding-left: 3px;
        div {
          height: 14px;
        }
        .selected {
          svg {
            fill: #e6de1e;
          }
        }
        svg {
          width: 14px;
          fill: #afafaf;
        }
      }
      .delete {
        color: white;
        display: flex;
      }
      .delete-button {
        color: white;
        height: 15px;
        font-size: 14px;
        color: white;
        width: 15px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
      .tree-node-icon-wrapper {
        width: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }
    .position-label {
      font-size: 9px;
      bottom: -8px;
      left: 2px;
      position: absolute;
      color: #989898;
    }
    &.empty {
      border: 1px solid transparent;
      justify-content: center;
      .fa {
        width: 15px;
        height: 15px;
        text-align: center;
        border-radius: 50%;
        border: 1px solid gray;
        font-size: 9px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .concept {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 12px;
      line-height: 16px;
      .name {
        flex: 1;
      }
      .level {
        width: 30px;
      }
    }
    .tree-node-content {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 10px;
      i {
        color: #333;
      }
      .name {
        flex: 1;
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
      }
      .in-place-text {
        font-size: 14px;
        height: 24px;
        padding-right: 5px;
      }

    }
  }
`;

const IconWrapper = styled.div`
  #lines {
    fill: ${props => props.linesFill};
  }
  color: ${props => props.color};
  display: flex;
  align-items: center;
  margin: 2px 0 0 4px;
  .lightbulb-on-icon {
    margin-top: -5px;
    margin-right: 4px;
    svg {
      width: 20px;
      fill: ${props => props.fill};
    }
  }
`;

@observer
class TreeNodeShow extends React.Component {

  @autobind onWebPublish(id) {
    TreeNodeStore.update({data: {web_published: !this.props.treeNode.webPublished}, ids: {treeNodeId: this.props.treeNode.id}}, this.props.loadTree);
  }

  @autobind onMobilePublish(id) {
    TreeNodeStore.update({data: {web_published: !this.props.treeNode.webPublished}, ids: {treeNodeId: this.props.treeNode.id}}, this.props.loadTree);
  }

  @autobind onClickRating(rating) {
    const adjustedRating = rating === this.props.treeNode.rating ? 0 : rating;
    TreeNodeStore.update({data: {rating: adjustedRating}, ids: {treeNodeId: this.props.treeNode.id}}, this.props.loadTree);
  }

  render() {
    return (
      <Wrapper>
        <div
          className={cx('builder-tree-node', {
            published: this.props.treeNode.webPublished,
            unpublished: !this.props.treeNode.webPublished,
          })}
        >
          <div className='tree-node-header'>
            {/*<div className='rating'>*/}
            {/*  <RatingInput*/}
            {/*    maxRating={4}*/}
            {/*    defaultValue={this.props.treeNode.rating}*/}
            {/*    onClick={this.onClickRating}*/}
            {/*  />*/}
            {/*</div>*/}
            <IconWrapper
              fill='#eaeaea'
              linesFill='#eaeaea'
              color='#eaeaea'
            >
              <LightbulbOnIcon />
              {this.props.treeNode.grammarTipsCount}
            </IconWrapper>
            <div style={{display: 'flex'}}>
              <div style={{paddingTop: '5px'}}>
              <span data-tip data-for={`didactics-${this.props.treeNode.id}`} style={{marginRight: 4}}>
                <InPlaceCheckbox
                  model='tree_nodes'
                  selectedColor='#f78139'
                  field='reviewed1'
                  value={this.props.treeNode.reviewed1}
                  id={this.props.treeNode.id}
                  icon='thumbs-up'
                  afterChange={this.afterChange}
                />
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id={`didactics-${this.props.treeNode.id}`}
                  class='custom-tooltip'
                >
                  Approved: Didactics
                </ReactTooltip>
              </span>
                <span data-tip data-for={`english-${this.props.treeNode.id}`} style={{marginRight: 4}}>
                <InPlaceCheckbox
                  model='tree_nodes'
                  field='reviewed2'
                  selectedColor='#f78139'
                  value={this.props.treeNode.reviewed2}
                  id={this.props.treeNode.id}
                  icon='thumbs-up'
                  afterChange={this.afterChange}
                />
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id={`english-${this.props.treeNode.id}`}
                  class='custom-tooltip'
                >
                  Approved: English
                </ReactTooltip>
              </span>
              </div>
              <div className='delete tree-node-icon-wrapper'>
                <DeleteButton
                  onConfirm={() => this.props.onDeleteTreeNode(this.props.treeNode.id)}
                  message='Delete this node?'
                />
              </div>
            </div>
          </div>
          <div className='tree-node-content'>
            {
              isPresent(this.props.treeNode.image) &&
                <div className='image-wrapper'>
                  <Link
                    to={{name: 'builder.tree_nodes.edit', params: {treeModuleId: this.props.treeModuleId, treeRowId: this.props.treeRowId, treeId: this.props.treeId, treeNodeId: this.props.treeNode.id}}}
                  >
                    <img src={this.props.treeNode.image.url} alt=''/>
                  </Link>
                </div>
            }
            {
              !isBlank(this.props.treeNode.name) &&
                <Link
                  to={{name: 'builder.tree_nodes.edit', params: {treeModuleId: this.props.treeModuleId, treeRowId: this.props.treeRowId, treeId: this.props.treeId, treeNodeId: this.props.treeNode.id}}}
                >
                  <div className='name'>
                    {`${this.props.treeNode.name} (${this.props.inGrammarTree ? this.props.treeNode.treeNodeDecksCount : this.props.treeNode.treeNodeConceptsCount})`}
                  </div>
                  {
                    isPresent(this.props.treeNode.grammarTitle) &&
                      <div className='grammar-title'>
                        {this.props.treeNode.grammarTitle}
                      </div>
                  }
                </Link>
            }
            {
              isBlank(this.props.treeNode.name) &&
                <InPlaceText
                  show
                  id={this.props.treeNode.id}
                  defaultValue={this.props.treeNode.name}
                  model='tree_nodes'
                  field='name'
                  afterChange={this.props.loadTree}
                />
            }
          </div>
          {
            isPresent(this.props.treeNode.concepts) &&
              <div className='concepts'>
                {
                  this.props.treeNode.concepts.map(item => {
                    return (
                      <Link
                        to={{name: 'builder.trees.concepts.edit', params: {treeRowId: this.props.treeRowId, treeModuleId: this.props.treeModuleId, treeId: this.props.treeId, conceptId: item.id, treeNodeId: this.props.treeNode.id}}}
                        key={item.id}
                        className='concept'
                      >
                        <div className='name'>{item.name}</div>
                        <div className='abbreviation'>{item.level.abbreviation}</div>
                      </Link>
                    );
                  })
                }
              </div>
          }
          <div className='position-label'>
            {this.props.treeNode.treePosition}
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default TreeNodeShow;
