import React from 'react';
import { observer } from 'mobx-react';
import { CampaignTypeFilterStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import CampaignTypeFilterCreate from 'pages/builder/campaign_type_filters/campaign_type_filter_create';
import autobind from 'autobind-decorator';

@observer
class CampaignTypeFilterIndex extends React.Component {

  @autobind onDelete(id) {
    CampaignTypeFilterStore.destroy({ids: {campaignTypeFilterId: id}}, this.props.onChange);
  }

  render() {
    return (
      <div className='campaign_type-index'>
        <CampaignTypeFilterCreate
          {...this.props}
          onChange={this.props.onChange}
        />
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th>Field</th>
              <th>Operator</th>
              <th>Value</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              this.props.campaignType.campaignTypeFilters.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <div className='text'>
                        {item.field}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.operator}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.value}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this filter?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default CampaignTypeFilterIndex;
