import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { ExerciseUI } from '@seedlang/state';
import ExerciseSentence from 'components/exercise/exercise_sentence';
import styled from '@emotion/styled';
import ExerciseEntry from 'components/exercise/exercise_entry';
import Prompt from 'components/exercise/prompt';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`

`;

const Sentence = styled.div`
  padding: 0 5px;
  border: ${props => props.border}
`;

@observer
class ExerciseHighlight extends React.Component {

  border(item) {
    if (item.state.revealed && item.state.correct) {
      return `2px solid ${Theme.green}`;
    } else if (item.state.revealed && !item.state.correct) {
      return `2px solid ${Theme.red}`;
    }
    return '2px solid transparent';
  }

  render() {
    return (
      <Wrapper>
        {
          ExerciseUI.exercise.exerciseEntries.map(item => {
            return (
              <ExerciseEntry
                key={item.id}
                grammarTip={item.grammarTip}
                showButtons={!this.props.inDeck && ExerciseUI.exercise.exerciseEntries.length > 1}
                exerciseEntry={item}
                {...this.props}
              >
                {
                  item.prompt &&
                    <Prompt>
                      {item.prompt}
                    </Prompt>
                }
                {
                  item.sentence &&
                    <Sentence
                      border={() => this.border(item)}
                    >
                      <ExerciseSentence
                        inputType='highlight'
                        sentence={item.sentence}
                        revealed={item.state.revealed}
                        firstWordIsLowercase={item.firstWordIsLowercase}
                        hideLanguageButton={!item.showSource}
                      />
                    </Sentence>
                }
              </ExerciseEntry>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseHighlight);
