import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { ResponsiveLine } from '@nivo/line';
import { pixify, isPresent } from '@seedlang/utils';
import { Constants } from '@seedlang/constants';

const Wrapper = styled.div`
  width: 100%;
`;

const ChartWrapper = styled.div`
  position: relative;
  height: ${props => props.height};
  width: 100%;
`;

@observer
class LineChart extends React.Component {

  static defaultProps = {
    height: 400,
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.props.data) &&
            <ChartWrapper
              height={pixify(this.props.height)}
            >
              <ResponsiveLine
                data={this.props.data}
                margin={{
                  top: 10,
                  right: 20,
                  bottom: 80,
                  left: 40,
                }}
                height={this.props.height}
                colors={Constants.CHART_COLORS}
                colorBy='colorIndex'
                minY='auto'
                stacked={false}
                curve='linear'
                enableGridX={true}
                lineWidth={3}
                dotSize={7}
                dotColor='inherit:darker(0.3)'
                dotBorderWidth={2}
                dotBorderColor='#efefef'
                animate={false}
                axisBottom={{
                  orient: 'bottom',
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: -90,
                  legendOffset: 36,
                  legendPosition: 'middle',
                }}
              />
            </ChartWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LineChart);
