import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import PieChartWithLegend from 'components/visualization/pie_chart_with_legend';
import { Theme } from '@seedlang/constants';
import Module from 'components/module';
import { formatNumber, kFormatter } from '@seedlang/utils';
import BadgeCollection from 'components/badge_collection';
import { AppUI } from '@seedlang/state';
import WordProgress from 'components/vocab/word_progress';
import SourceCounts from 'components/source/source_counts';

const Wrapper = styled.div`
  width: 100%;
`;

@observer
class VocabProgress extends React.Component {

  @computed get pieChartTitle() {
    let title;
    if (this.props.level && this.props.wordType) {
      title = `${this.props.level.abbreviation} ${this.props.wordType.formattedName}s`;
    } else if (this.props.level) {
      title = `${this.props.level.abbreviation} Words`;
    } else if (this.props.wordType) {
      title = `${this.props.wordType.formattedName}s`;
    } else {
      title = 'Words';
    }
    return `${title} Progress`;
  }

  @computed get pieChartData() {
    const unseenCount = this.props.counts.totalWordTypeLevelCount - this.props.counts.learnedCount - this.props.counts.seenCount;
    const ary = [
      {
        y: this.props.counts.learnedCount,
      },
    ];
    if (this.props.counts.seenCount > 0) {
      ary.push({
        y: this.props.counts.seenCount,
      });
    }
    if (unseenCount > 0) {
      ary.push({
        y: unseenCount,
      });
    }
    return ary;
  }

  @computed get colors() {
    const ary = [Theme.green, '#D8D8D8'];
    if (this.props.counts.seenCount > 0) {
      ary.splice(1, 0, Theme.orange);
    }
    return ary;
  }

  render() {
    return (
      <Wrapper>
        {
          !this.props.searchQuery && this.props.counts && this.props.counts.totalWordTypeLevelCount > 0 &&
            <Module
              title={this.pieChartTitle}
              slug='word-type-progress'
            >
              <PieChartWithLegend
                id='learned-by-level-word-type'
                colors={this.colors}
                data={this.pieChartData}
                width={160}
                margin='0 0 20px 0'
                count={kFormatter(this.props.counts.learnedCount)}
                tooltipText={`${formatNumber(this.props.counts.learnedCount)} learned, ${formatNumber(this.props.counts.seenCount)} seen of ${formatNumber(this.props.counts.totalWordTypeLevelCount)}`}
                legends={[[Theme.green, 'Learned'], [Theme.orange, 'Seen']]}
                subText={`of ${formatNumber(this.props.counts.totalWordTypeLevelCount)} Words Learned`}
              />
            </Module>
        }
        <Module
          title='Word Progress'
          slug='learned-by-level'
          padding='10px 20px 20px 20px'
        >
          <WordProgress />
        </Module>
        {
          AppUI.userIsLoaded &&
            <Module
              title='My Badges'
              padding='0 20px 10px 20px'
            >
              <BadgeCollection
                user={AppUI.user}
                badges={['vocabmaster', 'gendermaster', 'pluralmaster', 'translationmaster', 'sentencewriter']}
                showProgress
                width='70px'
                badgeWidth='100%'
              />
            </Module>
        }
        <Module
          title='Translations Added'
          slug='translation-submissions'
          padding='0 20px 20px 20px'
        >
          <SourceCounts />
        </Module>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabProgress);
