import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { MicrophoneIcon, KeyboardIcon } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  svg {
    width: ${props => props.width};
    margin: ${props => props.margin};
    fill: #333;
  }
  .microphone-icon {
      svg {
        width: 42%;
      }
    }
  }
  .keyboard-icon {
    svg {
      width: 24px;
      fill: #333;
    }
  }
`;

@observer
class MicrophoneToggleIcon extends React.Component {

  render() {
    return (
      <Wrapper
        width={this.props.width}
        margin={this.props.margin}
      >
        {
          !AppUI.user.vocabCardAudio &&
            <MicrophoneIcon />
        }
        {
          AppUI.user.vocabCardAudio &&
            <KeyboardIcon />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MicrophoneToggleIcon);
