import React from 'react';
import { observer } from 'mobx-react';
import { MembershipTypePageStore, MembershipTypeStore, PageStore, SiteStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import Cookies from 'js-cookie';
import { find, noop } from 'lodash';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';
import InfoTooltip from 'components/info_tooltip';
import Copy from 'components/copy';

@observer
class PageEdit extends React.Component {
  static defaultProps = {
    whiteLabeled: false,
  }

  componentDidMount() {
    if (!this.props.whiteLabeled) {
      SiteStore.getIndex();
    }
    this.getMembershipTypes();
    this.getPage();
    AppUI.navigationItemStore.getIndex();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.pageId !== prevProps.routeParams.pageId) {
      this.getPage();
    }
  }

  componentWillUnmount() {
    PageStore.clearShowData();
  }

  @computed get page() {
    return PageStore.showData;
  }

  @computed get filteredMembershipTypes() {
    return MembershipTypeStore.indexData.filter(item => item.siteId === Cookies.get('site_id'));
  }

  @autobind getPage() {
    PageStore.getShow({ ids: { pageId: this.props.routeParams.pageId }});
  }

  @autobind afterGetShow(resp) {
    PageStore.setShowData(resp);
  }

  @autobind getMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @autobind getMembershipTypePage(membershipTypeId) {
    return find(this.page.membershipTypePages, ['membershipTypeId', membershipTypeId]);
  }

  @autobind onChange(item, membershipTypeId, value) {
    if (value) {
      MembershipTypePageStore.create({ data: { page_id: this.page.id, membership_type_id: membershipTypeId } });
    } else {
      MembershipTypePageStore.destroy({ ids: { membershipTypePageId: item.id } });
    }
    this.getPage();
  }

  @computed get pageUrl() {
    return `${AppUI.site.domainString}/pages/${this.page.slug}`;
  }

  @computed get associatedNavigationItems() {
    return AppUI.navigationItemStore.indexData
      .filter(item => item.path === `/pages/${this.page.slug}`);
  }

  @autobind afterSlugChange(newSlug) {
    this.associatedNavigationItems.forEach(item => {
      AppUI.navigationItemStore.update({
        ids: { navigationItemId: item.id },
        data: { path: `/pages/${newSlug}` },
      }, () => AppUI.navigationItemStore.getIndex());
    });
    this.page.slug = newSlug;
  }

  render() {
    return (
      <div className='pages-edit'>
        {
          PageStore.hasShowData &&
          <div className='row'>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Name</legend>
                <InPlaceText
                  id={this.page.id}
                  defaultValue={this.page.title}
                  model='pages'
                  field='title'
                />
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Slug<InfoTooltip>You can customize the text that appears at the end of the url.</InfoTooltip></legend>
                  <InPlaceText
                    id={this.page.id}
                    defaultValue={this.page.slug}
                    model='pages'
                    field='slug'
                    show
                    afterChange={this.afterSlugChange}
                  />
                  <div className='row' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <a href={`https://${this.pageUrl}`} target='_blank' className='underline' style={{fontSize: '15px'}}>{this.pageUrl}</a>
                    <Copy text={this.pageUrl} />
                  </div>
              </fieldset>
            </div>
            {
              false &&
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Site</legend>
                    <InPlaceSelect
                      includeBlank={false}
                      options={SiteStore.indexData.map(item => [item.id, item.name])}
                      value={this.page.siteId}
                      model='pages'
                      field='site_id'
                      id={this.page.id}
                      afterChange={PageStore.reloadShow.bind(PageStore)}
                    />
                  </fieldset>
                </div>
            }
            <div className='col-xs-6'>
              <fieldset>
                <legend>Membership Types
                  <InfoTooltip>
                    <div>Unselected memberships will not be able to see the content of the page,</div>
                    <div>even if they have its url.</div>
                  </InfoTooltip>
                </legend>
                <InPlaceCheckbox
                  icon='check'
                  id={this.page.id}
                  value={this.page.showToNonMembers}
                  model='pages'
                  field='show_to_non_members'
                  afterChange={this.getPage}
                >
                  Show to non-members <InfoTooltip>Remember to check this if you want to allow non-members to access the page.</InfoTooltip>
                </InPlaceCheckbox>
                {
                  this.filteredMembershipTypes.map(item => {
                    return (
                      <div key={item.id}>
                        <InPlaceCheckbox
                          icon='check'
                          key={item.id}
                          value={Boolean(this.getMembershipTypePage(item.id))}
                          model='membership_type_pages'
                          onChange={this.onChange.bind(this, this.getMembershipTypePage(item.id), item.id)}
                        >
                          {item.name}
                        </InPlaceCheckbox>
                      </div>
                    );
                  })
                }
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <BackEndExplainer compact>
                <div>
                  {
                    this.associatedNavigationItems.length > 0
                      ? <div>This page <strong>is visible</strong> in the Site Navigation.</div>
                      : <div>This page <strong>is not visible</strong> in the Site Navigation.</div>
                  }
                </div>
                <div>
                  You can set up the navigation's visibility{' '}
                  <Link
                    className='underline'
                    to={{name: 'creator.groups.site_navigation', params: {groupId: this.props.params.groupId}}}
                  >
                    here
                  </Link>.
                </div>
              </BackEndExplainer>
            </div>
            {/* <div className="col-xs-6">
              <fieldset>
                <legend>Default</legend>
                <InPlaceCheckbox
                  icon="check"
                  value={this.page.default}
                  id={this.page.id}
                  model="pages"
                  field="default"
                  afterChange={this.getPage}
                />
              </fieldset>
            </div> */}
            <div className='col-xs-12'>
              <fieldset>
                <legend>Content</legend>
                <InPlaceText
                  wrapperClassName='word-info'
                  markdown={AppUI.siteIsDefault}
                  richText={!AppUI.siteIsDefault}
                  defaultValue={this.page.content}
                  model='pages'
                  field='content'
                  inputType='textarea'
                  id={this.page.id}
                />
              </fieldset>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default PageEdit;
