import Cookie from 'js-cookie';
import isPresent from './is_present';

export default function getCookieValue(name) {
  const value = Cookie.get(name, {path: '/'});
  if (value === 'true') {
    return true;
  } else if (value === 'false') {
    return false;
  } else {
    return isPresent(value) ? value : null;
  }
}