import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { PaymentMigrationConfigStore, PaymentMigrationStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import DashboardCount from 'pages/builder/dashboard/dashboard_count';
import React from 'react';
import { Link } from 'react-router';
import Paginator from 'components/paginator';
import Text from 'components/text';

const Wrapper = styled.div`

`;

const Title = styled.div`
  background: #FFF;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  margin: ${props => props.margin || '20px 0 10px 0'};
`;

@observer
class PaymentMigrationIndex extends React.Component {
  @observable stats = null;

  constructor(props) {
    super(props);
    this.loadStats();
    this.loadConfig();
    this.loadPendingPaymentMigrations();
  }

  @autobind loadStats() {
    AppUI.api.ajax({
      method: 'GET',
      url: '/api/payment_migration_stats',
      successCallback: resp => {
        this.stats = {
          started: resp.stripePaymentSetupStarted,
          payment: resp.waitingForPatreonCancellation,
          waiting: resp.waitingForPatreonExpiry + resp.waitingForPatreonRefund,
          completed: resp.completed,
        };
      },
    });
  }

  @autobind loadConfig() {
    PaymentMigrationConfigStore.getShow();
  }

  @autobind loadPendingPaymentMigrations() {
    PaymentMigrationStore.getIndex({ filters: { pending: true }});
  }

  render() {
    return (
      <Wrapper>
        <Title
          margin='0 0 20px 0'
        >
          Statistics
        </Title>

        <div className='row' style={{marginBottom: '20px'}}>
          <div className='col-xs-3'>
            <DashboardCount
              label='Started'
              count={this.stats?.started}
            />
          </div>
          <div className='col-xs-3'>
            <DashboardCount
              label='Payment details'
              count={this.stats?.payment}
            />
          </div>
          <div className='col-xs-3'>
            <DashboardCount
              label='Patreon cancelled'
              count={this.stats?.waiting}
            />
          </div>
          <div className='col-xs-3'>
            <DashboardCount
              label='Complete'
              count={this.stats?.completed}
            />
          </div>
        </div>

        {
          // This has been removed, because all sites should have the same copy, we'll update the config on the console.

          // AppUI.user.developer &&
          false &&
          <>
            <Title
              margin='0 0 20px 0'
            >
              Configuration
            </Title>

            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Text shown before adding payment details (HTML)</legend>
                  <InPlaceText
                    richText
                    defaultValue={PaymentMigrationConfigStore.showData.beforePaymentSetupHtml}
                    inputType='textarea'
                    model='payment_migration_config'
                    field='before_payment_setup_html'
                    afterChange={this.loadConfig}
                    maxHeight='30em'
                    scrollable
                  />
                </fieldset>
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Text shown after adding payment details and before cancelling Patreon membership - in case of
                    a monthly Patreon membership (HTML)
                  </legend>
                  <InPlaceText
                    richText
                    defaultValue={PaymentMigrationConfigStore.showData.afterPaymentSetupMonthlyHtml}
                    inputType='textarea'
                    model='payment_migration_config'
                    field='after_payment_setup_monthly_html'
                    afterChange={this.loadConfig}
                    maxHeight='30em'
                    scrollable
                  />
                </fieldset>
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Text shown after adding payment details and before cancelling Patreon membership - in case of
                    a yearly Patreon membership (HTML)
                  </legend>
                  <InPlaceText
                    richText
                    defaultValue={PaymentMigrationConfigStore.showData.afterPaymentSetupYearlyHtml}
                    inputType='textarea'
                    model='payment_migration_config'
                    field='after_payment_setup_yearly_html'
                    afterChange={this.loadConfig}
                    maxHeight='30em'
                    scrollable
                  />
                </fieldset>
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Text shown after the user has cancelled their monthly Patreon membership and we're waiting for
                    it to expire (HTML)
                  </legend>
                  <InPlaceText
                    richText
                    defaultValue={PaymentMigrationConfigStore.showData.waitingForPatreonExpiryHtml}
                    inputType='textarea'
                    model='payment_migration_config'
                    field='waiting_for_patreon_expiry_html'
                    afterChange={this.loadConfig}
                    maxHeight='30em'
                    scrollable
                  />
                </fieldset>
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Text shown after the user has cancelled their yearly Patreon membership and we're waiting for
                    the admin to refund them on Patreon (HTML)
                  </legend>
                  <InPlaceText
                    richText
                    defaultValue={PaymentMigrationConfigStore.showData.waitingForPatreonRefundHtml}
                    inputType='textarea'
                    model='payment_migration_config'
                    field='waiting_for_patreon_refund_html'
                    afterChange={this.loadConfig}
                    maxHeight='30em'
                    scrollable
                  />
                </fieldset>
              </div>
            </div>

            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Text shown after the admin refunded the yearly membership and we're waiting for a couple of days
                    for the refund to arrive to the user's bank account before starting to charge the user on our platform
                    (HTML)
                  </legend>
                  <InPlaceText
                    richText
                    defaultValue={PaymentMigrationConfigStore.showData.waitingForPatreonRefundTimeoutHtml}
                    inputType='textarea'
                    model='payment_migration_config'
                    field='waiting_for_patreon_refund_timeout_html'
                    afterChange={this.loadConfig}
                    maxHeight='30em'
                    scrollable
                  />
                </fieldset>
              </div>
            </div>
          </>
        }

        <Title
          margin='20px 0 5px 0'
        >
          Info Link
        </Title>
        <Text>This url will be linked as a help resource during the migration process.</Text>
        <InPlaceText
          show
          defaultValue={PaymentMigrationConfigStore.showData.infoUrl}
          model='payment_migration_config'
          field='info_url'
          id={PaymentMigrationConfigStore.showData.id}
        />
        <Title
          margin='20px 0 20px 0'
        >
          Pending Migrations
        </Title>

        <table
          className='table-wrapper'
          style={{
            marginTop: 10,
          }}
        >
          <thead>
            <tr>
              <th width='40'></th>
              <th>Email</th>
              <th>Status</th>
              <th># Mnths</th>
              <th>Created</th>
              <th colSpan='2'>Payment Method Added</th>
              <th colSpan='2'>Patreon Expires</th>
            </tr>
          </thead>
          <tbody>
            {
              PaymentMigrationStore.indexData.map(item => (
                <tr key={item.id}>
                  <td>
                    {
                      this.props.params.groupId && item.user &&
                      <Link
                        to={{
                          name: 'creator.groups.users.edit',
                          params: {groupId: this.props.params.groupId, userId: item.user?.id},
                        }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block'/>
                      </Link>
                    }
                  </td>
                  <td>
                    {item.user?.email}
                  </td>
                  <td>
                    {item.friendlyStatus}
                  </td>
                  <td>
                    {item.membership?.numberMonths}
                  </td>
                  <td>
                    <span title={item.createdAt.formattedDateWithYearAndTime}>{item.createdAt.formattedDate}</span>
                  </td>
                  <td>
                    <span title={item.paymentMethodAddedAt.formattedDateWithYearAndTime}>{item.paymentMethodAddedAt.formattedDate}</span>
                  </td>
                  <td>
                    ({item.paymentMethodAddedAt.relativeDays})
                  </td>
                  <td>
                    <span title={item.patreonMembershipExpiresAt.formattedDateWithYearAndTime}>{item.patreonMembershipExpiresAt.formattedDate}</span>
                  </td>
                  <td>
                    ({item.patreonMembershipExpiresAt.relativeDays})
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
        <Paginator
          store={PaymentMigrationStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PaymentMigrationIndex);
