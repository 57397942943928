import React from 'react';
import { observer } from 'mobx-react';
import { VocabTriadWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { isPresent } from '@seedlang/utils';
import VocabSentence from 'components/vocab/vocab_sentence';
import VideoPlayerWithSupplement from 'components/media/video_player_with_supplement';
import { AppUI } from '@seedlang/state';
import DotProgress from 'components/dot_progress';
import Button from 'components/button/button';
import { Link } from 'react-router';

const Wrapper = styled.div`
  background: #ebebeb;
  padding: 20px 0;
  padding: 10px;
  border-radius: 5px;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VideoAndControls = styled.div`
  display: flex;
  align-items: center;
`;

const VideoWrapper = styled.div`
  flex: 1;
`;

const VideoColumn = styled.div`
  width: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  i {
    font-size: 60px;
    color: #333;
  }
`;

const Navigation = styled.div`
  display: flex;
`;

const NavigationColumn = styled.div`
  width: 15px;
  cursor: pointer;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  i {
    font-size: 30px;
    color: #333;
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
`;

const VocabSentenceWrapper = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

@observer
class VocabTriad extends React.Component {

  render() {
    return (
      <>
        {
          isPresent(this.props.currentSentence) &&
            <Wrapper>
              <Title>
                Highlighted Story
              </Title>
              <ColumnWrapper>
                <RowWrapper>
                  {
                    this.props.currentSentence.target.videoClip &&
                      <VideoAndControls>
                        <VideoColumn
                          justifyContent='flex-end'
                          onClick={this.props.onPreviousSentence}
                        >
                          <i className='fa fa-angle-left' />
                        </VideoColumn>
                        <VideoWrapper>
                          <VideoPlayerWithSupplement
                            hasVideoClip
                            autoPlay={this.props.autoPlay}
                            autoPlayTimeStamp={this.props.sentenceIndex}
                            autoPlaySupplement
                            forcePlay
                            width={AppUI.layout.isMobile ? (AppUI.layout.viewportWidth - 100) / 2 : 180}
                            videoClip={this.props.currentSentence.target.videoClip}
                            supplementVideoClip={this.props.currentSentence.videoClip}
                          />
                          <DotProgress
                            margin={isPresent(this.props.currentSentence.videoClip) ? '-10px 0 0 0' : '10px 0 0 0'}
                            numDots={3}
                            currentDot={this.props.sentenceIndex + 1}
                          />
                        </VideoWrapper>
                        <VideoColumn
                          justifyContent='flex-start'
                          onClick={this.props.onNextSentence}
                        >
                          <i className='fa fa-angle-right' />
                        </VideoColumn>
                    </VideoAndControls>
                  }
                  {
                    this.props.word.vocabTriadDeckId &&
                      <ButtonWrapper>
                        <Link
                          to={{name: 'triad.decks.show', params: {wordId: this.props.word.id, deckId: this.props.word.vocabTriadDeckId}}}
                        >
                          <Button
                            margin='20px 0 0 0'
                          >
                            Create Deck
                          </Button>
                        </Link>
                      </ButtonWrapper>
                  }
                </RowWrapper>
                <VocabSentenceWrapper>
                  <VocabSentence
                    topSentencePadding='5px 0'
                    bottomSentencePadding='0 0 5px 0'
                    hideLevelAbbreviation
                    fontSize='16px'
                    lineHeight='18px'
                    wordId={this.props.word.id}
                    sentence={this.props.currentSentence}
                  />
                  {
                    !this.props.currentSentence.target.videoClip &&
                      <Navigation>
                        <NavigationColumn
                          justifyContent='flex-end'
                          onClick={this.props.onPreviousSentence}
                        >
                          <i className='fa fa-angle-left' />
                        </NavigationColumn>
                        <NavigationColumn
                          justifyContent='flex-start'
                          onClick={this.props.onNextSentence}
                        >
                          <i className='fa fa-angle-right' />
                        </NavigationColumn>
                      </Navigation>
                  }
                </VocabSentenceWrapper>
              </ColumnWrapper>
            </Wrapper>
        }
      </>
    );
  }
}

export default VocabTriadWrapper(VocabTriad);
