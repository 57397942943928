import React from 'react';
import { observer } from 'mobx-react';
import { NumberCardWrapper } from '@seedlang/hoc';
import WordOptionsCard from 'components/vocab_card/word_options_card';

@observer
class NumberCardMultipleChoice extends React.Component {

  render() {
    return (
      <WordOptionsCard
        {...this.props}
      />
    );
  }
}

export default NumberCardWrapper(NumberCardMultipleChoice);
