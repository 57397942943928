import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import Copy from 'components/copy';

const Wrapper = styled.div`
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  margin: ${props => props.margin};
  cursor: pointer;
  font-size: 12px;
`;

const Link = styled.div`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #FFF;
  overflow-x: scroll;
  padding: ${props => props.padding};
  white-space: nowrap;
  flex: 1;
`;

const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  font-size: 10px;
  font-weight: bold;
`;

@observer
class LinkWithCopy extends React.Component {

  static defaultProps = {
    padding: '10px',
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <Link
          onClick={() => window.open(this.props.link, '_blank')}
          padding={this.props.padding}
        >
          {this.props.link}
        </Link>
        <CopyWrapper>
          <Copy
            text={this.props.link}
          >
            COPY
          </Copy>
        </CopyWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LinkWithCopy);
