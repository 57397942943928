import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { DashboardStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import Module from 'components/module';
import { Languages } from '@seedlang/constants';
import DashboardPieChart from 'pages/builder/dashboard/dashboard_pie_chart';
import Spinner from 'components/spinner';
import Button from 'components/button/button';
import { AppUI } from '@seedlang/state';
import { DateDecorator } from '@seedlang/models';
import moment from 'moment';

const Wrapper = styled.div`

`;

const CountsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
`;

const Category = styled.div`
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  background: whitesmoke;
  padding: 10px;
`;

const ButtonWrapper = styled.div`
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Note = styled.div`
  font-size: 14px;
  font-style: italic;
  text-align: center;
  margin-top: 5px;
`;

@observer
class DidacticsDashboard extends React.Component {
  @observable counts =[];
  @observable showSpinner = true;
  @observable localLastUpdatedAt = null;

  componentDidMount() {
    this.getCounts();
  }

  @computed get lastUpdatedAt() {
    if (this.localLastUpdatedAt) {
      return new DateDecorator(moment(this.localLastUpdatedAt)).formatted;
    }
    return AppUI.user.site.didacticCountsLastUpdatedAt?.formatted;
  }

  @autobind afterCreateDidaticCounts() {
    this.localLastUpdatedAt = new Date();
    this.getCounts();
  }

  @autobind getCounts() {
    this.showSpinner = true;
    DashboardStore.api({}, this.afterGetCounts, 'GET', 'didactics');
  }

  @autobind afterGetCounts(resp) {
    this.showSpinner = false;
    this.counts = resp;
  }

  @autobind onClick() {
    DashboardStore.createDidacticCounts({}, this.afterCreateDidaticCounts);
  }

  render() {
    return (
      <Wrapper>
        {
          this.showSpinner &&
            <Spinner />
        }
        {
          !this.showSpinner && this.counts.map(item => {
            return (
              <Module
                slug={`didactics-${item.languageId}`}
                title={Languages[item.languageId]}
                key={item.languageId}
              >
                {
                  item.counts.map(category => {
                    return (
                      <div
                        key={category.category}
                      >
                        <Category>
                          {category.category} ({category.totalCount})
                        </Category>
                        <CountsWrapper>
                          {
                            category.counts.map(count => {
                              return (
                                <DashboardPieChart
                                  key={count.slug}
                                  countData={count}
                                  languageId={item.languageId}
                                />
                              );
                            })
                          }
                        </CountsWrapper>
                      </div>
                    );
                  })
                }
              </Module>
            );
          })
        }
        <ButtonWrapper>
          <Button
            onClick={this.onClick}
          >
            Refresh Counts
          </Button>
          {
            AppUI.userIsLoaded &&
              <Note>
                Last Update: {this.lastUpdatedAt}
              </Note>
          }
        </ButtonWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DidacticsDashboard);
