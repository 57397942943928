import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { LanguageIcon } from '@seedlang/icons';
import Text from 'components/text';
import { Languages } from '@seedlang/constants';
import RadioButton from 'components/radio_button';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  padding: 20px;
`;

const LanguageOption = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  &:hover {
    background: whitesmoke;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

@observer
class LanguagePicker extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.languageOptions && this.props.languageOptions.map(languageId => {
            return (
              <LanguageOption
                key={languageId}
                onClick={() => this.props.onClick(languageId)}
              >
                <TextWrapper>
                  <LanguageIcon
                    languageId={languageId}
                  />
                  <Text
                    margin='0 0 0 10px'
                  >
                    {Languages[languageId]}
                  </Text>
                </TextWrapper>
                <RadioButton
                  width='22px'
                  height='22px'
                  value={AppUI.targetLanguageId === languageId}
                />
              </LanguageOption>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LanguagePicker);
