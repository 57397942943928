import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { UserStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';

@observer
class UserCreate extends React.Component {
  @observable showCreatedMessage = false;
  @observable message = '';

  @autobind onBulkCreate() {
    if (isPresent(this.refs.users.value)) {
      UserStore.bulkCreate({data: {users: this.refs.users.value}}, this.afterBulkCreate);
    }
  }

  @autobind onCreate() {
    if (isPresent(this.refs.password.value)) {
      UserStore.create(
        {
          data: {
            email: this.refs.email.value,
            password: this.refs.password.value,
            passwordConfirmation: this.refs.password.value,
          },
        },
      this.afterCreate);
    }
  }

  @autobind afterCreate() {
    this.refs.password.value = '';
    this.refs.email.value = '';
    this.showCreatedMessage = true;
    this.message = 'A user was successfully created.';
  }

  @autobind afterBulkCreate() {
    this.refs.users.value = '';
    this.showCreatedMessage = true;
    this.message = 'Users were successfully created.';
  }

  render() {
    return (
      <div className='concept-category-create'>
        {
          this.showCreatedMessage &&
            <div className='alert'>
              {this.message}
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'><h3>Create One</h3></div>
        </div>
        <div className='row'>
          <div className='col-xs-5'>
            <input
              placeholder='Add a email'
              ref='email'
            />
          </div>
          <div className='col-xs-5'>
            <input
              placeholder='Add a password'
              ref='password'
            />
          </div>
          <div className='col-xs-2'>
            <button
              className='button-primary'
              onClick={this.onCreate}
            >
              Create
            </button>
          </div>
        </div>
        <div className='row' style={{marginTop: 20}}>
          <div className='col-xs-12'>
            <h3>Create Many</h3>
            <small>format: email, (optional name) - one per line</small>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <textarea
              ref='users'
              style={{height: 120}}
            />
          </div>
        </div>
        <div className='row' style={{marginTop: 10}}>
          <div className='col-xs-12'>
            <button
              className='button-primary'
              onClick={this.onBulkCreate}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default UserCreate;
