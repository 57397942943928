import React from 'react';
import { observer } from 'mobx-react';
import { TranslateButtonsWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import EvaluateButtons from 'components/card/evaluate_buttons';
import { HelpIcon, RightIcon } from '@seedlang/icons';
import { Theme } from '@seedlang/constants';
import { DeckUI } from '@seedlang/state';

const Wrapper = styled.span`

`;

@observer
class TranslateButtons extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          !this.props.cardState.revealed &&
            <CardButtons>
              {
                this.props.showHelpButton &&
                  <CardButton
                    shortcut='1'
                    text='Help'
                    marginRight={5}
                    icon={<HelpIcon />}
                    disabled={this.props.disabled}
                    onClick={this.props.onClickHelp}
                    backgroundColor={Theme.darkOrange}
                  />
              }
              <CardButton
                submitOnEnter
                shortcut={this.props.showHelpButton ? '2' : '1'}
                marginLeft={this.props.showHelpButton ? 5 : 0}
                text='Show Answer'
                backgroundColor={Theme.darkGreen}
                icon={<RightIcon />}
                disabled={this.props.disabled}
                onClick={() => this.props.onShowAnswer ? this.props.onShowAnswer() : DeckUI.onEvaluateTextInput(this.props.card, this.props.cardState, this.props.userCard)}
              />
            </CardButtons>
        }
        {
          this.props.cardState.revealed &&
            <EvaluateButtons
              card={this.props.card}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={this.props.disabled}
              onNextClick={this.props.onNextClick}
            />
        }
      </Wrapper>
    );
  }
}

export default TranslateButtonsWrapper(TranslateButtons);
