import React from 'react';
import { autorun, computed, observable } from 'mobx';
import PropTypes from 'prop-types';
import SeedlangDesktopHeader from 'components/layout/desktop/seedlang_header';
import WhiteLabelHeader from 'components/layout/desktop/white_label_header';
import DesktopFooter from 'components/layout/desktop/footer';
import MobileHeader from 'components/layout/mobile/header';
import MobileFooter from 'components/layout/mobile/footer';
import { observer } from 'mobx-react';
import { last, noop } from 'lodash';
import { AppUI, TreeUI, VocabUI } from '@seedlang/state';
import { RouteStore } from '@seedlang/stores';
import OverlayWrapper from 'components/layout/overlay_wrapper';
import autobind from 'autobind-decorator';
import ServerAlert from 'components/server_alert';
import { Constants, Theme } from '@seedlang/constants';
import { isBlank, isPresent, loadCss, sentryInitializer } from '@seedlang/utils';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/browser';
import Cookies from 'js-cookie';
import Spinner from 'components/spinner';
import CookieAgreement from 'components/cookie_agreement';
import MissingAgreement from 'components/missing_agreement';
import { Link } from 'react-router';
import BackToTopButton from 'components/button/back_to_top_button';
import { pixify } from '@seedlang/utils/src';

const Wrapper = styled.div`
  width: ${props => props.width};
  overflow: ${props => props.overflow};
  visibility: ${props => props.visibility};
  flex: 1;
  display: ${props => props.display};
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 100vh;
  .primary-page-onboarding {
    i {
      position: absolute;
      top: 8px;
      right: 8px;
      font-size: 20px;
      cursor: pointer;
    }
  }
  h2 {
    font-size: 26px;
    line-height: 26px;
    font-weight: bold;
    margin: 10px 0;
    color: #333;
  }
  h3 {
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    margin: 10px 0;
    color: #333;
  }
  #appleid-signin {
    background: #FFF;
    border-radius: 50px;
  }
  #appleid-signin > div > div {
    padding: 4px;
  }
`;

const MainContainer = styled.div`
  max-width: ${props => props.maxWidth};
  padding: ${props => props.padding};
  overflow: ${props => props.overflow};
  width: 100%;
  flex: 1;
  -webkit-overflow-scrolling: touch;
`;

const Localhost = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  color: white;
  font-size: 9px;
  background: #FF5252;
  text-align: center;
  line-height: 10px;
  font-weight: bold;
  z-index: 99;
  div {
    cursor: pointer;
  }
`;

const MigrationBanner = styled.div`
  top: 70px;
  height: fit-content;
  background: #AFD6E7;
  width: 100vw;
  position: relative;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  padding: 5px 10px;
  @media only screen and (max-width : 649px) {
    top: 0;
  }
`;

const Unpublished = styled.div`
  background: ${props => props.background};
  position: fixed;
  top: 0;
  height: 40px;
  z-index: 999;

  color: #FFF;
  padding: 10px;
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  .fa-warning {
    color: #FFF;
    margin-right: 5px;
  }
`;

@observer
class App extends React.Component {
  @observable showLocalhostBar = true;
  @observable previousRouteName;
  @observable loadTrees = false;
  @observable userIsLoaded = false;
  @observable showBackToTop = false;

  @computed get adminPage() {
    return AppUI.routeStore.routeName?.match('builder') || AppUI.routeStore.routeName?.match('creator');
  }

  routeContainsString(ary) {
    return AppUI.routeStore.routeName && ary.map(item => isPresent(AppUI.routeStore.routeName.match(item))).find(item => item);
  }

  @computed get deckRoute() {
    return AppUI.routeStore.routeName && !AppUI.routeStore.routeName.match('translator.decks_show') && (AppUI.routeStore.routeName.match('decks.show') || AppUI.routeStore.routeName.match('decksWithOpponent.show'));
  }

  @computed get hideHeader() {
    const routeName = last(this.props.routes).name;
    return this.deckRoute || routeName.match('onboarding') || routeName.match('unpublished') || Constants.HIDE_HEADER_ROUTES.indexOf(routeName) !== -1;
  }

  @computed get showFullWidth() {
    return AppUI.loadingUser
      || (AppUI.routeStore.routeName === 'trivia.home' && !AppUI.userIsLoaded)
      || (this.routeContainsString(['landing']) && !AppUI.layout.isMobile)
      || AppUI.routeStore.routeName === 'maintenance';
  }

  @computed get padding() {
    return '80px 10px 0 10px';
  }

  @computed get hideFooter() {
    const routeName = last(this.props.routes).name;
    return this.deckRoute || routeName.match('unpublished') || (AppUI.layout.isMobile && AppUI.user.signedOut) || Constants.HIDE_FOOTER_ROUTES.indexOf(last(this.props.routes).name) !== -1 || (AppUI.layout.isMobile && !AppUI.routeStore.routeName);
  }

  @computed get showLandingPage() {
    return !AppUI.user.signedIn && AppUI.routeStore.routeName === 'landing';
  }

  @computed get showAppStoreAnnouncement() {
    return !this.hideHeader && AppUI.userIsLoaded && !AppUI.wasUiElementSeen('app-announcement') && isBlank(AppUI.user.lastAndroidActiveAt?.date) && isBlank(AppUI.user.lastIosActiveAt?.date);
  }

  @computed get siteId() {
    return Cookies.get('site_id');
  }

  @computed get currentSite() {
    return AppUI.user.site;
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    sentryInitializer();
    this.loadConditionalIncludes();

    this.loadUser();
    if (!AppUI.siteIsDefault && AppUI.layout.isMobile) {
      document.body.style.height = 'auto';
      document.documentElement.style.height = 'auto';
      document.documentElement.style.overflow = 'auto';
    }
    if (!AppUI.layout.isNative) {
      AppUI.soundEffectStore.loadAudio();
    }
    AppUI.detectDataSaver();
    const routeName = this.props.router && this.props.router.routes && last(this.props.router.routes).name;
    AppUI.routeStore.setRouteName(routeName);
    if (window.location.search.match('clicked_mailing_id')) {
      const ary = window.location.search.split('?clicked_mailing_id=');
      if (ary[1]) {
        AppUI.campaignStore.trackClicked({ids: {campaignId: ary[1]}}, noop);
      }
    }
    autorun(() => {
      if (AppUI.targetLanguageId && AppUI.targetLanguageId !== VocabUI.targetLanguageId) {
        VocabUI.onSetTargetLanguageId(AppUI.targetLanguageId);
      }
    });
  }

  @autobind addMetaTag(property, value) {
    const metaTag = document.createElement('meta');
    metaTag.setAttribute('property', property);
    metaTag.setAttribute('content', value);
  }

  @autobind async loadConditionalIncludes() {
    if (AppUI.siteIsDefault) {
      await import('../css/default.css');
      if (process.env.NODE_ENV === 'development') {
        loadCss('https://cloud.typography.com/7576076/6435012/css/fonts.css');
      } else {
        loadCss('https://cloud.typography.com/7576076/6235012/css/fonts.css');
      }
    } else {
      await Promise.all([
        import('../css/membership_platform.css'),
        import('../css/karla-font.css'),
        import('../css/rubik-font.css'),
        import('../css/noto-sans-font.css'),
      ]);
    }
  }

  @autobind loadUser() {
    AppUI.loadUser(this.afterLoadUser);
  }

  @autobind afterLoadUser(resp) {
    AppUI.setAuthUserStoreShowData(resp);
    if (!AppUI.siteIsDefault && AppUI.routeStore.routeName === 'landing') {
      AppUI.routeStore.routeToNamed(AppUI.userIsSignedIn ? AppUI.user.site.signedInHomePageRoute : AppUI.user.site.signedOutHomePageRoute);
    }
    this.userIsLoaded = true;
    if (AppUI.siteIsDefault && !(AppUI.routeStore.routeName.startsWith('builder') || AppUI.routeStore.routeName.startsWith('creator'))) {
      AppUI.loadReviewDecks();
      TreeUI.loadTreesFromTargetLanguage(AppUI.targetLanguageId);
    }
    Sentry.setUser({id: AppUI.user.id, email: AppUI.user.email});
    if (AppUI.user.siteShowsMaintenancePage) {
      AppUI.routeStore.routeToNamed('maintenance');
    }
    if (isPresent(AppUI.user.contractedModules)) {
      AppUI.updateContractedModulesAry(AppUI.user.contractedModules.split(','));
    }
    if (!AppUI.siteIsDefault) {
      this.loadWhitelabelFonts();
    }
  }

  @autobind loadWhitelabelFonts() {
    if (AppUI.user.site.headerFont) {
      document.body.style.setProperty(
        '--header-font',
        AppUI.user.site.headerFont
          .replace('header_', '')
          .replace(/_/g, ' '),
      );
    }

    if (AppUI.user.site.bodyFont) {
      document.body.style.setProperty(
        '--body-font',
        AppUI.user.site.bodyFont
          .replace('body_', '')
          .replace(/_/g, ' '),
      );
    }
  }

  @autobind onScroll(e) {
    if (!AppUI.pageHasScrolled) {
      AppUI.set('pageHasScrolled', true);
    }
    if (AppUI.routeStore.routeName === 'stories.home' && this.refs.app) {
      const scrollPosition = this.refs.app.scrollTop;
      AppUI.set('contentTreeScrollPosition', scrollPosition);
    }
    if (['videos.index', 'podcast.index', 'worksheets.index'].indexOf(AppUI.routeStore.routeName) !== -1 && this.refs.app) {
      const scrollPosition = this.refs.app.scrollTop;
      AppUI.set('worksheetGridScrollPosition', scrollPosition);
    }
    if (AppUI.layout.isMobile && !AppUI.siteIsDefault && window.scrollY > AppUI.layout.viewportHeight) {
      this.showBackToTop = true;
    } else if (this.showBackToTop) {
      this.showBackToTop = false;
    }
  }

  componentDidMount() {
    AppUI.layout.updateDimensions();
    RouteStore.setRouter(this.context.router);
    window.addEventListener('scroll', this.onScroll, true);
  }

  componentDidUpdate(prevProps) {
    if (this.props.router && this.props.router.routes && last(this.props.router.routes).name) {
      AppUI.routeStore.setRouteName(last(this.props.router.routes).name);
    }
    if (this.props.children && prevProps.children && AppUI.routeStore.routeName !== this.previousRouteName) {
      this.previousRouteName = AppUI.routeStore.routeName;
      AppUI.set('clickedLinkId', null);
      const el = document.getElementsByClassName('app')[0];
      if (this.adminPage && (!AppUI.userIsLoaded || !AppUI.user.anyAdmin)) {
        AppUI.routeStore.routeToNamed('landing');
      } else if (!AppUI.siteIsDefault && !last(this.props.router.routes).allowWhiteLabel) {
        AppUI.routeStore.routeToNamed('worksheets.index');
      }
      if (el && AppUI.routeStore.routeName === 'stories.home' && isPresent(AppUI.contentTreeScrollPosition)) {
        el.scrollTop = AppUI.contentTreeScrollPosition;
      } else if (el && ['videos.index', 'podcast.index', 'worksheets.index'].indexOf(AppUI.routeStore.routeName) !== -1) {
        el.scrollTop = AppUI.worksheetGridScrollPosition;
      } else if (el && (AppUI.routeStore.routeName === 'memberships.index' || AppUI.routeStore.routeName === 'nouns.trainer' || AppUI.routeStore.routeName === 'vocab.home')) {
         el.scrollTop = 0;
      }
      AppUI.onNewRoute();
      if ((AppUI.user.signedOut || AppUI.user.guest) && AppUI.isUnpublishedAccessBlocked && ['onboarding', 'onboarding.sign_in', 'unpublished', 'access', 'onboarding.patreon'].indexOf(AppUI.routeStore.routeName) === -1) {
        console.log('routeStore.routeName', AppUI.routeStore.routeName);
        AppUI.routeStore.routeToNamed('unpublished');
      } else if (AppUI.user.signedOut && !RouteStore.childRoute().signedOutRoute && AppUI.siteIsDefault) {
        AppUI.routeStore.routeToNamed('landing');
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  @computed get useMobileHeader() {
    return AppUI.layout.viewportWidth < 730;
  }

  render() {
    if (!this.userIsLoaded) {
      return (
        <Spinner />
      );
    }
    if (this.userIsLoaded) {
      return (
        <Wrapper
          display='flex'
          ref='app'
          className='app'
          visibility={AppUI.loadingUser ? 'hidden' : 'visible'}
          width={AppUI.layout.isMobile ? pixify(AppUI.layout.viewportWidth) : '100%'}
          overflow={AppUI.layout.isMobile && Constants.HIDE_HEADER_ROUTES.indexOf(AppUI.routeStore.routeName) ? null : 'auto'}
        >
          {
            AppUI.layout.isMobile && !AppUI.siteIsDefault && this.showBackToTop &&
              <BackToTopButton
                floating
                background={AppUI.site.accentColor}
                right='5px'
                bottom='40px'
                onClick={() => AppUI.scrollToPageTop('smooth', 'top')}
              />
          }
          {
            !AppUI.siteIsDefault && AppUI.routeStore.routeName === 'worksheets.index' &&
              <MissingAgreement />
          }
          <span id='top' />
          {
            AppUI.layout.isMobile &&
              <OverlayWrapper />
          }
          {
            AppUI.siteIsDefault && !this.useMobileHeader && !this.hideHeader &&
              <SeedlangDesktopHeader
                position={this.showLandingPage ? 'absolute' : 'fixed'}
                background={this.showLandingPage ? 'transparent' : Theme.siteHeaderColor}
                currentRoute={last(this.props.routes).name}
                signedIn={AppUI.user.signedIn}
              />
          }
          {
            !AppUI.siteIsDefault && !this.hideHeader &&
              <WhiteLabelHeader
                position={AppUI.layout.isMobile ? 'static' : 'fixed'}
                background={AppUI.user?.site?.headerBackgroundColor}
                currentRoute={last(this.props.routes).name}
                signedIn={AppUI.user.signedIn}
                navigationItems={AppUI.user?.site?.navigationItems}
                languageId={AppUI.user?.site?.languageId}
                logo={AppUI.user?.site?.image}
              />
          }
          {
            !AppUI.siteIsDefault && !this.hideHeader && !AppUI.routeStore.routeName?.startsWith('settings') && AppUI.isPaymentMigrationStartable &&
              <MigrationBanner>
                <Link
                  to={{name: 'onboarding.migration'}}
                >
                  You are currently subscribed via Patreon. To unlock all interactive features, please <u>migrate your payment</u> to our platform.
                </Link>
              </MigrationBanner>
          }
          {
            !AppUI.siteIsDefault && !this.hideHeader && !AppUI.routeStore.routeName?.startsWith('settings') && AppUI.user.paymentMigration?.status === 'waiting_for_patreon_cancellation' &&
              <MigrationBanner>
                <Link
                  to={{name: 'onboarding.migration'}}
                >
                  Don't forget to cancel your subscription on Patreon to complete your transition to our new platform!
                </Link>
              </MigrationBanner>
          }
          {
            AppUI.siteIsDefault && this.useMobileHeader && !this.hideHeader &&
              <MobileHeader
                currentRoute={last(this.props.routes).name}
                signedIn={AppUI.user.signedIn}
              />
          }
          <MainContainer
            maxWidth={this.showFullWidth ? null : '1180px'}
            padding={this.showFullWidth || (this.useMobileHeader && AppUI.siteIsDefault) || AppUI.layout.isMobile || this.hideHeader ? null : this.padding}
            overflow={AppUI.layout.isMobile && AppUI.siteIsDefault ? 'auto' : null}
          >
            {this.props.children}
          </MainContainer>
          {
            AppUI.siteIsDefault && AppUI.layout.isMobile && last(this.props.routes).name !== 'onboarding' && !this.hideFooter &&
              <MobileFooter
                currentRoute={last(this.props.routes).name}
                bgColor='#fff'
              />
          }
          {
            (!AppUI.layout.isMobile || !AppUI.site.isDefault) && !this.hideFooter &&
              <DesktopFooter
                currentRoute={last(this.props.routes).name}
                bgColor={AppUI.user?.site?.footerBackgroundColor}
                footerMarkup={AppUI.user?.site?.footerMarkup}
              />
          }
          {
            window.location.hostname === 'localhost' && this.showLocalhostBar &&
              <Localhost
                onClick={() => this.showLocalhostBar = false}
              >
                <div>
                  localhost
                </div>
              </Localhost>
          }
          {
            window.location.hostname === 'localhost' &&
              <ServerAlert />
          }
          <CookieAgreement />
        </Wrapper>
      );
    }
  }
}

export default App;