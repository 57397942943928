import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { ExerciseStore, ExerciseTypeStore, WorksheetStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceSelect from 'components/form/in_place_select';
import { isBlank, isPresent } from '@seedlang/utils';
import ExerciseEntryIndex from 'pages/creator/exercise_entry/exercise_entry_index';
import ExerciseEntryCreate from 'pages/creator/exercise_entry/exercise_entry_create';
import Modal from 'components/modal';
import TagEdit from 'pages/creator/tags/tag_edit';
import RatingIndex from 'pages/builder/ratings/rating_index';
import { noop, some } from 'lodash';
import Alert from 'components/alert';
import Spinner from 'components/spinner';
import Text from 'components/text';
import cx from 'classnames';
import styled from '@emotion/styled';
import { ExerciseUI } from '@seedlang/state';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InfoTooltip from 'components/info_tooltip';

const NavigationButtonRow = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 5px;
`;

const EntriesSettingsRow = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: space-between;
`;

@observer
class ExerciseEdit extends React.Component {
  @observable editTags = false;
  @observable showRatingComments = false;
  @observable randomizing = false;

  constructor(props) {
    super(props);
    ExerciseTypeStore.getIndex();
    WorksheetStore.getShow({ids: {worksheetId: this.props.params.worksheetId}});
    this.getExercise();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.params?.exerciseId !== prevProps.params?.exerciseId) {
      ExerciseStore.clearShowData();
      this.getExercise();
    }
  }

  componentWillUnmount() {
    ExerciseStore.clearShowData();
  }

  @autobind getExercise() {
    ExerciseUI.exerciseId = this.props.params.exerciseId;
    ExerciseStore.getShow({ids: {exerciseId: this.props.params.exerciseId}}, this.afterGetExercise);
  }

  @autobind afterGetExercise(resp) {
    ExerciseStore.setShowData(resp);
    this.randomizing = false;
  }

  @autobind clearField(field) {
    const data = {};
    data[field] = null;
    ExerciseStore.update({ids: {exerciseId: this.props.params.exerciseId}, data: data}, this.getExercise);
  }

  @computed get hasInvalidExerciseEntry() {
    return ExerciseStore.hasShowData && !ExerciseStore.showData.exerciseType?.hideTarget && some(ExerciseStore.showData.exerciseEntries.map(item => isBlank(item.sentence)));
  }

  @autobind randomizeOrder() {
    if (ExerciseStore.showData.exerciseEntriesCount < 2) { return; }
    this.randomizing = true;
    ExerciseStore.randomizeEntries({ids: {exerciseId: this.props.params.exerciseId}}, this.getExercise);
  }

  @computed get optionsDisplayedOptions() {
    const options = [['null', `All (${ExerciseStore.showData.selectedUniqueWords.length})`]];
    if (ExerciseStore.hasShowData && isPresent(ExerciseStore.showData.wordAssociationsWithExtraWords)) {
      ExerciseStore.showData.selectedUniqueWords.slice(0, -1).forEach((wordAssociation, index) => {
        if (index > 0) {
          options.push([index + 1, (index + 1).toString()]);
        }
      });
    }
    return options;
  }

  render() {
    return (
      <div className='exercise-edit'>
        {
          this.editTags &&
            <Modal
              onCloseModal={() => this.editTags = false}
              height='550px'
            >
              <TagEdit
                groupId={this.props.params.groupId}
                exercise={ExerciseStore.showData}
                onChange={this.getExercise}
              />
            </Modal>
        }
        {
          this.showRatingComments &&
            <Modal
              onCloseModal={() => this.showRatingComments = false}
              height='550px'
            >
              <RatingIndex
                simple
                defaultFilters={{group_id: this.props.params.groupId, exercise_id: this.props.params.exerciseId, comment: true}}
              />
            </Modal>
        }
        <div className='breadcrumbs'>
          <div>
            <Link
              to={{name: 'creator.groups.worksheets.index', params: {groupId: this.props.params.groupId}}}
            >
              Posts
            </Link>
          </div>
          <i className='fa fa-angle-double-right' />
          {
            WorksheetStore.hasShowData &&
            <div>
              <Link
                to={{name: 'creator.groups.worksheets.edit', params: {worksheetId: WorksheetStore.showData.id, groupId: this.props.params.groupId}}}
              >
                {WorksheetStore.showData.name}
              </Link>
            </div>
          }
          <i className='fa fa-angle-double-right' />
          <div>
            {ExerciseStore.showData.name}
          </div>
        </div>
        {
          this.hasInvalidExerciseEntry &&
            <Alert margin='10px 0'><i className='fa fa-warning' /> This exercise has an invalid exercise entry.</Alert>
        }
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset style={{height: '100%'}}>
              <legend>Name</legend>
              <InPlaceText
                defaultValue={ExerciseStore.showData.name}
                model='exercises'
                field='name'
                id={ExerciseStore.showData.id}
              />
              {
                WorksheetStore.hasShowData && ExerciseStore.hasShowData &&
                <Link
                  to={{name: 'worksheets.exercises.show', params: {worksheetId: WorksheetStore.showData.id, exerciseId: ExerciseStore.showData.id}}}
                  className='underline'
                  style={{fontSize: 12}}
                >
                  Go to Exercise
                </Link>
              }
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset style={{height: '100%'}}>
              <legend>{`Exercise Navigation (${ExerciseUI.exerciseIndex + 1}/${WorksheetStore.showData?.exercises?.length})`}</legend>
              <NavigationButtonRow>
                <button
                  className={cx('button-primary', { disabled: ExerciseUI.exerciseIsFirst })}
                  style={{width: '50%'}}
                  onClick={() => ExerciseUI.goToPreviousExercise(noop, 'creator.groups.exercises.edit')}
                >
                  <i className='fa fa-arrow-left' /> Previous
                </button>
                <button
                  className={cx('button-primary', { disabled: ExerciseUI.exerciseIsLast })}
                  style={{width: '50%'}}
                  onClick={() => ExerciseUI.goToNextExercise(noop, 'creator.groups.exercises.edit')}
                >
                  Next <i className='fa fa-arrow-right' />
                </button>
              </NavigationButtonRow>
            </fieldset>
          </div>
          <div className='col-xs-3'>
            <fieldset>
              <legend>Exercise Type</legend>
              <InPlaceSelect
                model='exercises'
                field='exercise_type_id'
                id={ExerciseStore.showData.id}
                options={ExerciseTypeStore.indexData.map(item => [item.id, item.name])}
                value={ExerciseStore.showData.exerciseTypeId}
                afterChange={this.getExercise}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Tags</legend>
              <div className='tag-section'>
                <button
                  className='gray-button'
                  onClick={() => this.editTags = true}
                >
                  <i className='fa fa-plus' />
                  Edit Tags
                </button>
                <div className='tag-wrapper'>
                  {
                    ExerciseStore.hasShowData && ExerciseStore.showData.exerciseTags.map(item => {
                      return (
                        <div
                          key={item.id}
                          className='tag'
                        >
                          {item.tag.name}
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset style={{height: '100%'}}>
              <legend>Ratings</legend>
              <div className='ratings-section' style={{fontSize: '15px'}}>
                <span>{`Average: ${ExerciseStore.showData.ratingsAverage}`}</span>
                <span style={{marginLeft: '40px'}}>{`Number of ratings: ${ExerciseStore.showData.ratingsCount}`}</span>
              </div>
                {
                  ExerciseStore.showData.ratingsWithCommentsCount > 0 &&
                    <div
                      style={{marginTop: '5px', fontSize: '15px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                      onClick={() => this.showRatingComments = true}
                    >
                      <i className='fa fa-plus'/><span style={{marginLeft: '10px'}}>{`See the ${ExerciseStore.showData.ratingsWithCommentsCount} comment${ExerciseStore.showData.ratingsWithCommentsCount > 1 ? 's' : ''}`}</span>
                    </div>
                }
                {
                  ExerciseStore.showData.ratingsWithCommentsCount === 0 &&
                    <div
                      style={{marginTop: '5px', fontSize: '13px', display: 'flex', alignItems: 'center'}}
                    >
                      No users have left a comment yet
                    </div>
                }
            </fieldset>
          </div>
        </div>
        <div className='backend-title'>
          Description
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Default Description</legend>
              {
                isPresent(ExerciseStore.showData.defaultDescription) &&
                  <div className='button-border'>
                    <button
                      className='button-primary'
                      onClick={() => this.clearField('default_description')}
                    >
                      Clear
                    </button>
                </div>
              }
              <InPlaceText
                richText
                defaultValue={ExerciseStore.showData.defaultDescription}
                inputType='textarea'
                model='exercises'
                field='default_description'
                id={ExerciseStore.showData.id}
                afterChange={this.getExercise}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Expanded Description <InfoTooltip>This description will only be shown if the user chooses to expand the description.</InfoTooltip></legend>
              {
                isPresent(ExerciseStore.showData.expandedDescription) &&
                  <div className='button-border'>
                    <button
                      className='button-primary'
                      onClick={() => this.clearField('expanded_description')}
                    >
                      Clear
                    </button>
                </div>
              }
              <InPlaceText
                richText
                defaultValue={ExerciseStore.showData.expandedDescription}
                inputType='textarea'
                model='exercises'
                field='expanded_description'
                id={ExerciseStore.showData.id}
                afterChange={this.getExercise}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Description Video <InfoTooltip>This can be used for listening comprehension exercises for instance.</InfoTooltip></legend>
              <InPlaceText
                show
                defaultValue={ExerciseStore.showData.descriptionVideo}
                placeholder='https://www.youtube.com/watch?v=dQw4w9WgXcQ'
                model='exercises'
                field='description_video'
                id={ExerciseStore.showData.id}
                afterChange={this.getExercise}
              />
              <small>Note: setting this will automatically expand the description.</small>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>
                PDF Description
                <InfoTooltip>
                  <div>This description will only be displayed in the PDF version of the exercise</div>
                  <div>instead of the Default Description & Expanded Description.</div>
                </InfoTooltip>
              </legend>
              {
                isPresent(ExerciseStore.showData.pdfDescription) &&
                  <div className='button-border'>
                    <button
                      className='button-primary'
                      onClick={() => this.clearField('pdf_description')}
                    >
                      Clear
                    </button>
                </div>
              }
              <InPlaceText
                richText
                defaultValue={ExerciseStore.showData.pdfDescription}
                inputType='textarea'
                model='exercises'
                field='pdf_description'
                id={ExerciseStore.showData.id}
                afterChange={this.getExercise}
              />
            </fieldset>
          </div>
        </div>
        <div className='backend-title'>Exercise Entries</div>
        {
          ExerciseStore.hasShowData && ExerciseStore.showData.hasExerciseType && isPresent(ExerciseStore.showData.exerciseType.slug) &&
            <div className='row'>
              <div className='col-xs-12'>
                <ExerciseEntryCreate
                  showGrammarTip
                  exercise={ExerciseStore.showData}
                  afterCreate={this.getExercise}
                  languageId={this.props.params.groupId === 'f74a4102-d65b-448c-b261-60b6be2c7eca' ? 'DE' : null}
                />
              </div>
            </div>
        }
        {
          ExerciseStore.hasShowData && ExerciseStore.showData.hasExerciseType && isPresent(ExerciseStore.showData.exerciseType.slug) &&
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>{`Entries (${ExerciseStore.showData.exerciseEntriesCount})`}</legend>
                  {
                    ExerciseStore.showData.exerciseEntriesCount > 0 &&
                    <EntriesSettingsRow>
                      {
                        !this.randomizing &&
                          <button
                            className={cx('button-primary', {
                              disabled: ExerciseStore.showData.exerciseEntriesCount === 1,
                            })}
                            onClick={this.randomizeOrder}
                          >
                            <i className='fa fa-random'/>
                            Randomize Order of Entries
                          </button>
                      }
                      { this.randomizing && <Spinner margin='8px 0'/> }
                      {
                        ExerciseStore.showData.exerciseType.slug === 'inputOptions' &&
                          <div style={{display: 'flex', alignItems: 'center', gap: '3px'}}>
                            <Text
                              fontSize='14px'
                              color={this.optionsDisplayedOptions.length <= 1 ? 'gray' : 'black'}
                            >
                              Number of Options:
                            </Text>
                            <InPlaceSelect
                              model='exercises'
                              field='options_displayed'
                              id={ExerciseStore.showData.id}
                              options={this.optionsDisplayedOptions}
                              value={ExerciseStore.showData.optionsDisplayed}
                              style={{height: '30px'}}
                              disabled={this.optionsDisplayedOptions.length <= 1}
                            />
                          </div>
                      }
                      {
                        !ExerciseStore.showData.exerciseType.hideTarget &&
                          <InPlaceCheckbox
                            model='exercises'
                            field='capitalize_entries'
                            value={ExerciseStore.showData.capitalizeEntries}
                            id={ExerciseStore.showData.id}
                            icon='check'
                            afterChange={this.getExercise}
                          >
                            Capitalize Entries
                            <InfoTooltip position='left'>
                              <div>This will ensure the first word of each sentence is capitalized when selected by the user.</div>
                              <div>If your entries are not sentences, you should untick this option</div>
                            </InfoTooltip>
                          </InPlaceCheckbox>
                      }
                      { ExerciseStore.showData.exerciseType.hideTarget && <div /> }
                    </EntriesSettingsRow>
                  }
                  <ExerciseEntryIndex
                    exercise={ExerciseStore.showData}
                    exerciseType={ExerciseStore.showData.exerciseType}
                    afterUpdate={this.getExercise}
                    languageId={this.props.params.groupId === 'f74a4102-d65b-448c-b261-60b6be2c7eca' ? 'DE' : null}
                    hideInstruction
                    showGrammarTips
                  />
                </fieldset>
              </div>
            </div>
        }
        {
          ExerciseStore.requestCounter > 0 &&
          <Spinner />
        }
      </div>
    );
  }
}

export default ExerciseEdit;
