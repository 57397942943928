import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { TranslationSourceStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Paginator from 'components/paginator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import { Link } from 'react-router';
import Filter from 'components/backend_filter/filter';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import WordModal from 'pages/builder/words/word_modal';
import { AppUI } from '@seedlang/state';
import Copy from 'components/copy';
import { Theme } from '@seedlang/constants';
import { isPresent } from '@seedlang/utils';
import isBlank from 'is-blank';
import Text from 'components/text';

const Wrapper = styled.div`

`;

@observer
class TranslationSourceIndex extends React.Component {
  @observable selectedWordId;

  onDelete(id) {
    TranslationSourceStore.destroy({ ids: { translationSourceId: id } });
  }

  @autobind updateTranslationSource(translationSource, params) {
    TranslationSourceStore.update({data: params, ids: {translationSourceId: translationSource.id}}, this.afterUpdate);
  }

  @autobind afterUpdate() {
    TranslationSourceStore.reloadIndex();
  }

  render() {
    return (
      <Wrapper>
        {
          this.selectedWordId &&
            <WordModal
              wordId={this.selectedWordId}
              onChange={this.afterUpdate}
              onCloseModal={() => this.selectedWordId = null}
            />
        }
        <Filter
          store={TranslationSourceStore}
          defaultSort='-translation_sources.created_at'
          namespace='target'
          queryStrings={{include_count: true}}
          filters={
            [
              {
                type: 'boolean',
                name: 'user_id',
                label: 'Submitted By User',
              },
              {
                type: 'boolean',
                name: 'translated_by_ai',
                label: 'Translated By AI',
              },
              {
                type: 'boolean',
                name: 'accepted',
                label: 'accepted',
              },
              {
                type: 'boolean',
                name: 'rejected',
                label: 'rejected',
              },
              {
                type: 'select',
                name: 'joins:source|language_id',
                options: AppUI.languageOptionsForFilter,
                labelField: 'name',
                label: 'Language',
              },
            ]
          }
        />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th />
              <th width='120'>Created</th>
              <th>Lang</th>
              <th>Source</th>
              <th>Translation</th>
              <th>User</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              TranslationSourceStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.translation_sources.edit', params: { translationSourceId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        {item.createdAt.formatted}
                      </div>
                    </td>
                    <td>
                      {item.source && item.source?.languageId}
                    </td>
                    <td>
                      {
                        item.source &&
                          <div className='text' style={{display: 'flex'}}>
                            <Copy
                              text={item.source && item.source.text}
                              margin='0 5px 0 0'
                            />
                            <Link
                              to={{name: 'builder.sources.edit', params: {sourceId: item.source.id}}}
                            >
                              {item.source && item.source.text}
                            </Link>
                          </div>
                      }
                    </td>
                    <td>
                      {
                        item.translation && item.translation.type === 'Word' &&
                          <div style={{display: 'flex', alignItems: 'center'}}>
                            <a
                              href={item.translation.dictionaryUrl}
                              target='seedlang'
                              className='no-underline no-hover'
                              style={{marginRight: 5}}
                            >
                              <div className='encircle-icon'>
                                <i className='fa fa-external-link' />
                              </div>
                            </a>
                            <span
                              style={{cursor: 'pointer'}}
                              onClick={() => this.selectedWordId = item.translation.id}
                            >
                              {item.translation.targetText}
                            </span>
                          </div>

                      }
                      {
                        item.translation && item.translation.type !== 'Word' &&
                          <Link
                            to={{name: 'builder.sentences.edit', params: {sentenceId: item.translation.id}}}
                          >
                            {item.translation.targetText}
                          </Link>
                      }
                    </td>
                    <td>
                      <div className='text' style={{display: 'flex', alignItems: 'center'}}>
                        {
                          item.user &&
                            <InPlaceCheckbox
                              iconStyle={{margin: '0 5px 0 0'}}
                              icon='check'
                              bigIcon
                              selectedColor={Theme.green}
                              id={item.id}
                              value={item.accepted}
                              model='translation_sources'
                              field='accepted'
                            />
                        }
                        {
                          item.user &&
                            <InPlaceCheckbox
                              iconStyle={{margin: '0 5px 0 0'}}
                              icon='times'
                              bigIcon
                              selectedColor={Theme.red}
                              id={item.id}
                              value={item.rejected}
                              model='translation_sources'
                              field='rejected'
                            />
                        }
                        {
                          item.user &&
                            <Link
                              to={{name: 'builder.users.edit', params: {userId: item.user?.id}}}
                            >
                              {isPresent(item.user.name) ? item.user.name : 'No Name'}
                            </Link>
                        }
                        {
                          isBlank(item.user) && item.translatedByAi &&
                          <Text>AI</Text>
                        }
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message='Delete this translation source?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={TranslationSourceStore}
        />
      </Wrapper>
    );
  }
}

export default TranslationSourceIndex;
