import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { computed } from 'mobx';
import SettingsMenuRow from './settings_menu_row';

const MenuContainer = styled.div`
  grid-area: menu;
  width: ${props => props.width};
  margin: ${props => props.margin};
  border-radius: ${props => props.borderRadius};
  overflow: hidden;
`;

@observer
class SettingsMenu extends React.Component {
  @computed get navigationItems() {
    return AppUI.site.navigationItems
      .filter(item => item.settingsLink && item.path.startsWith('/pages/'))
      .map(item => {
        const match = item.path.match(/\/pages\/(.*)/);
        return { name: item.name, id: item.id, pageId: match ? match[1] : null };
      });
  }

  render() {
    return (
      <MenuContainer
        width={AppUI.layout.isMobile ? '100%' : '250px'}
        borderRadius={AppUI.layout.isMobile ? 0 : '10px'}
        isMobile={AppUI.layout.isMobile}
      >
        <SettingsMenuRow
          to={{name: 'settings.user_profile'}}
          selected={this.props.children && this.props.children.props.route.name === 'settings.user_profile'}
        >
          User Settings
        </SettingsMenuRow>
        {
          AppUI.siteIsDefault &&
            <SettingsMenuRow
              to={{name: 'settings.deck'}}
              selected={this.props.children && this.props.children.props.route.name === 'settings.deck'}
            >
              Deck Settings
            </SettingsMenuRow>
        }
        {
          !AppUI.user.guest &&
            <SettingsMenuRow
              to={{name: 'settings.password'}}
              selected={this.props.children && this.props.children.props.route.name === 'settings.password'}
            >
              Change Password
            </SettingsMenuRow>
        }
        {
          AppUI.siteIsDefault &&
            <SettingsMenuRow
              to={{name: 'settings.clear_progress'}}
              selected={this.props.children && this.props.children.props.route.name === 'settings.clear_progress'}
            >
              Clear Progress
            </SettingsMenuRow>
        }
        {
          AppUI.siteIsDefault &&
            <SettingsMenuRow
              to={{name: 'settings.streak_repair'}}
              selected={this.props.children && this.props.children.props.route.name === 'settings.streak_repair'}
            >
              Streak Repair
            </SettingsMenuRow>
        }
        {
          AppUI.siteIsDefault &&
            <SettingsMenuRow
              to={{name: 'settings.user_notifications'}}
              selected={this.props.children && this.props.children.props.route.name === 'settings.user_notifications'}
            >
              Notifications
            </SettingsMenuRow>
        }
        {
          !AppUI.siteIsDefault &&
            <SettingsMenuRow
              to={{name: 'settings.email'}}
              selected={this.props.children && this.props.children.props.route.name === 'settings.email'}
            >
              Email Settings
            </SettingsMenuRow>
        }
        {
          (AppUI.siteIsDefault || (AppUI.site.allowPatreonSignIn && AppUI.site.patreonClientId)) &&
            <SettingsMenuRow
              to={{name: 'settings.patreon'}}
              selected={this.props.children && this.props.children.props.route.name === 'settings.patreon'}
            >
              Patreon Account
            </SettingsMenuRow>
        }
        <SettingsMenuRow
          to={{name: 'settings.billing'}}
          selected={this.props.children && this.props.children.props.route.name === 'settings.billing'}
        >
          {AppUI.siteIsDefault ? 'Manage Subscription' : 'Manage Membership'}
        </SettingsMenuRow>
        {
          (AppUI.user.admin || AppUI.user.userPodcasts.length > 0) &&
            <SettingsMenuRow
              to={{name: 'settings.rss_feed'}}
              selected={this.props.children && this.props.children.props.route.name === 'settings.rss_feed'}
            >
              Audio Feeds
            </SettingsMenuRow>
        }
        <SettingsMenuRow
          to={{ name: 'settings.privacy' }}
          selected={this.props.children && this.props.children.props.route.name === 'settings.privacy'}
        >
          Privacy
        </SettingsMenuRow>
        {
          this.navigationItems.map(navigationItem => (
            <SettingsMenuRow
              key={navigationItem.id}
              to={{ name: 'settings.page.show', params: {pageId: navigationItem.pageId} }}
              selected={this.props.children && this.props.children.props.route.name === 'settings.page.show' && this.props.params['pageId'] === navigationItem.pageId}
            >
              {navigationItem.name}
            </SettingsMenuRow>
          ))
        }
      </MenuContainer>
    );
  }
}

export default SettingsMenu;