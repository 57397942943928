import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router';
import { computed, observable } from 'mobx';
import { ConceptStore, DeckGrammarConceptStore, DeckRulebookStore, LevelStore, TreeStore, WordStore } from '@seedlang/stores';
import CardIndex from 'pages/builder/cards/card_index';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceTextInput from 'components/form/in_place_text_input';
import { TreeUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isBlank, isPresent } from '@seedlang/utils';
import InPlaceText from 'components/form/in_place_text';
import ExerciseCreate from 'pages/creator/exercises/exercise_create';
import ExerciseIndex from 'pages/creator/exercises/exercise_index';
import Spinner from 'components/spinner';
import UserDeckIndex from 'pages/builder/user_decks/user_deck_index';
import Alert from 'components/alert';
import SearchMultiSelect from 'components/form/search_multi_select';
import DeleteButton from 'components/button/delete_button';
import styled from '@emotion/styled';
import RatingInput from 'components/rating_input';
import { keys, noop, orderBy, some, without, values, every, uniq, uniqBy, flatten } from 'lodash';
import CheckIfTrue from 'components/check_if_true';
import { Constants, Theme } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import Text from 'components/text';
import Checkbox from 'components/checkbox';
import InfoTooltip from 'components/info_tooltip';

const Wrapper = styled.div`
  fieldset {
    position: relative;
  }
`;

const ReviewedUser = styled.div`
  font-size: 12px;
  padding-left: 18px;
  background: #FFF;
`;

const RebuildCards = styled.div`
  display: flex;
  align-items: center;
`;

const DescriptionButton = styled.div`
  margin: 15px 0 10px 0;
  cursor: pointer;
  text-decoration: underline;
  text-align: center;
  border-radius: 10px;
  background: #d3d3d382;
  padding: 5px;
  font-size: 14px;
`;

@observer
class DeckEdit extends React.Component {
  @observable destroyUserDecks = false;
  @observable submittingRebuildingDeck = false;
  @observable showUserDecks = false;
  @observable addMissingCards = false;
  @observable keepCardPositions = false;
  @observable previousTreeNodeDecks = {};
  @observable batchDigits = '';
  @observable skippedDigits = '';
  @observable showLegacyGrammarDescription = false;
  @observable toggledConceptDescriptions = [];
  @observable showGenerateSentencesSpinner = false;
  @observable generateSentencesConfirmation = '';

  constructor(props) {
    super(props);
    if (this.props.params.treeId && !TreeUI.hasTree) {
      this.loadTree();
    }
    if (isPresent(this.props.params.treeNodeId) && !TreeUI.hasTreeNode) {
      TreeUI.treeNodeStore.getShow({queryStrings: {expanded: true}, ids: {treeNodeId: this.props.params.treeNodeId}}, this.afterTreeNodeGetShow);
    } else {
      TreeUI.deckTreeNodeStore.getIndex({queryStrings: {expanded: true}, ids: {deckId: this.props.params.deckId}}, this.afterTreeNodeGetIndex);
    }
    if (!DeckRulebookStore.hasIndexData) {
      DeckRulebookStore.getIndex({limit: 99});
    }
    this.getDeck();
  }

  componentWillUnmount() {
    TreeUI.deckStore.clearShowData();
  }

  @computed get conjugationTenseOptions() {
    const ids = keys(Constants.GRAMMAR_TENSES[TreeUI.deckStore.showData.languageId]);
    return ids.map(item => [item, Constants.GRAMMAR_TENSES[TreeUI.deckStore.showData.languageId][item]]);
  }

  @computed get showValidSetup() {
    return true;
    // return TreeUI.deckStore.hasShowData && (TreeUI.deckStore.showData.deckType === 'trivia' || TreeUI.deckStore.showData.deckTypeIsTriad);
  }

  @computed get recommendedCount() {
    if (!TreeUI.deckStore.showData.deckRulebook) { return '-';}
      if (TreeUI.deckStore.showData.deckRulebook.slug === 'exercise') {
        return 6;
      } else if (TreeUI.deckStore.showData.deckRulebook.slug === 'trivia') {
        return 0;
      } else {
        return 4;
      }
  }

  @computed get treeModule() {
    if (TreeStore.hasShowData) {
      return TreeStore.showData.treeModules.find(item => item.id === this.props.params.treeModuleId);
    }
    return {};
  }

  @computed get wordCountWarning() {
    if (!TreeUI.deckStore.hasShowData || (TreeUI.deckStore.showData.deckType !== 'triad' && TreeUI.deckStore.showData.deckType !== 'trivia')) { return null; }
    const ranges = {
      triad: [3, 7],
      trivia: [3, 8],
    };
    const wordsLength = TreeUI.deckStore.showData.words.length;
    if (wordsLength < ranges[TreeUI.deckStore.showData.deckType][0]) {
      return `This deck needs to have at least ${ranges[TreeUI.deckStore.showData.deckType][0]} words added.`;
    } else if (wordsLength > ranges[TreeUI.deckStore.showData.deckType][1]) {
      return `This deck should not have more than ${ranges[TreeUI.deckStore.showData.deckType][1]} words added.`;
    } return null;
  }

  @computed get routeName() {
    if (this.props.params.treeNodeId && TreeUI.deckStore.hasShowData && TreeUI.deckStore.showData.deckRulebook && TreeUI.deckStore.showData.deckRulebook.slug === 'trivia') {
      return 'trivia.tree_nodes.decks.show';
    } else if (TreeUI.deckStore.hasShowData && TreeUI.deckStore.showData.deckType === 'triad') {
      return 'vocab.decks.show';
    } else if (isBlank(this.props.params.treeNodeId)) {
      return 'decks.show';
    }
    return 'tree_nodes.decks.show';
  }

  @computed get params() {
    return this.props.params.treeNodeId ? {deckId: this.props.params.deckId, treeNodeId: this.props.params.treeNodeId} : {deckId: this.props.params.deckId};
  }

  @computed get hasTreeModule() {
    return isPresent(this.treeModule);
  }

  @computed get showAutoCreateExercisesButton() {
    return TreeUI.deckStore.hasShowData && TreeUI.deckStore.showData.deckRulebook && TreeUI.deckStore.showData.deckRulebook.slug === 'trivia';
  }

  @computed get missingWords() {
    return this.previousTreeNodeDecks && TreeUI.deckStore.hasShowData && TreeUI.deckStore.showData.sentenceWords.filter(word => this.previousTreeNodeDecks[word.id]?.length === 0 && TreeUI.deckStore.showData.words.map(item => item.id).indexOf(word.id) === -1);
  }

  @autobind loadTree() {
    TreeStore.getShow({ids: {treeId: this.props.params.treeId}});
  }

  @autobind rebuildDeck() {
    this.submittingRebuildingDeck = true;
    TreeUI.deckStore.update({ids: {deckId: this.props.params.deckId}, queryStrings: {add_all_missing_cards: this.addMissingCards, keep_card_positions: this.keepCardPositions, destroy_user_decks: this.destroyUserDecks}, data: {conceptId: TreeUI.deckStore.showData.concept && TreeUI.deckStore.showData.concept.id}}, this.getDeck);
  }

  @autobind getDeck() {
    this.submittingRebuildingDeck = false;
    TreeUI.deckStore.getShow({queryStrings: {expanded: true, expand_words: true}, ids: {deckId: this.props.params.deckId}}, this.afterGetDeck);
    this.getPreviousTreeNodeDecks();
  }

  @autobind afterGetDeck(resp) {
    TreeUI.deckStore.setShowData(resp);
    if (resp.grammarDescriptionOverride) {
      this.showLegacyGrammarDescription = true;
    }
    if (TreeUI.treeNodeStore.hasShowData && !resp.grammarDescriptionOverride) {
      TreeUI.treeNodeStore.showData.treeNodeGrammarConcepts.forEach(item => {
        if (isPresent(TreeUI.deckStore.showData.deckGrammarConcepts.find(deckGrammarConcept => deckGrammarConcept.conceptId === item.concept?.id))) {
          this.toggledConceptDescriptions.push(item.id);
        }
      });
    }
    if (TreeUI.deckStore.showData.conjugationWithVideoDeck) {
      TreeUI.deckWordStore.getIndex({filters: {deck_id: TreeUI.deckStore.showData.id}});
    }
  }

  @autobind onAutoCreateExercises() {
    TreeUI.deckStore.autoCreateExercises({ids: {deckId: this.props.params.deckId}}, this.getDeck);
  }

  @autobind reloadDeckOnly() {
    TreeUI.deckStore.getShow({queryStrings: {expanded: true, expand_words: true}, ids: {deckId: this.props.params.deckId}}, this.afterGetDeck);
  }

  @autobind onRateDeck(value) {
    TreeUI.deckStore.update({ids: {deckId: this.props.params.deckId}, data: {our_rating: value}}, this.getDeck);
  }

  @autobind createAllWords() {
    TreeUI.deckStore.createWords({ids: {deckId: this.props.params.deckId}, data: {treePosition: this.treePosition, allWords: true}}, this.getDeck);
  }

  @autobind createUnseenWords() {
    TreeUI.deckStore.createWords({ids: {deckId: this.props.params.deckId}, data: {treePosition: this.treePosition, allWords: false}}, this.getDeck);
  }

  @autobind onCreateDeckWord(word) {
    TreeUI.deckWordStore.create({data: {deckId: this.props.params.deckId, wordId: word.id}}, this.getDeck);
  }

  @autobind onDestroyDeckWord(id) {
    TreeUI.deckWordStore.destroy({ids: {deckId: this.props.params.deckId, wordId: id}}, this.getDeck);
  }

  @autobind onClickRating(value) {
    const rating = value === TreeUI.deckStore.showData.rating ? null : value;
    TreeUI.deckStore.update({ids: {deckId: TreeUI.deckStore.showData.id}, data: {rating: rating}}, noop);
  }

  @autobind checkIfValid() {
    TreeUI.deckStore.updateValidSetup({ids: {deckId: TreeUI.deckStore.showData.id}}, this.getDeck);
  }

  @autobind afterTreeNodeGetShow(resp) {
    TreeUI.treeNodeStore.setShowData(resp);
    this.getPreviousTreeNodeDecks(resp?.treePosition);
  }

  @autobind afterTreeNodeGetIndex(resp) {
    TreeUI.treeNodeStore.setIndexData(resp);
    this.getPreviousTreeNodeDecks(resp[0]?.treePosition);
  }

  @autobind getPreviousTreeNodeDecks(treePosition) {
    if (treePosition || this.treePosition) {
      TreeUI.deckStore.getTreeNodeDecksWithSameWords({ids: {deckId: this.props.params.deckId}}, r => this.afterGetTreeNodeDecks(r, treePosition || this.treePosition));
    }
  }

  @autobind afterGetTreeNodeDecks(resp, treePosition) {
    resp.data.forEach(item => {
      this.previousTreeNodeDecks[item.wordId] = item.treeNodeDecks.filter(treeNodeDeck => treeNodeDeck.treeNode.treeId === this.treeId && treeNodeDeck.treeNode.treePosition <= (treePosition || this.treePosition) && treeNodeDeck.deck.id !== this.props.params.deckId);
    });
  }

  @computed get wordSearchFilters() {
    const filters = [{key: 'root', value: true}, {key: 'language_id', value: TreeUI.deckStore?.showData.concept?.languageId}];
    if (TreeUI.deckStore.showData.numberDeck) {
      filters.push({key: 'word_type_id', value: Constants.WORD_TYPES.find(item => item.name === 'Number').id});
    }
    return filters;
  }

  @autobind onSubmitBatchDigits() {
    TreeUI.deckWordStore.batchCreate({data: {text: this.batchDigits, deckId: TreeUI.deckStore.showData.id}}, this.afterSubmitBatchDigits);
    this.batchDigits = '';
  }

  @autobind afterSubmitBatchDigits(resp) {
    this.skippedDigits = resp.skipped;
    this.getDeck(resp);
  }

  @computed get treePosition() {
    if (TreeUI.treeNodeStore.hasShowData) {
      return TreeUI.treeNodeStore.showData.treePosition;
    } else if (TreeUI.treeNodeStore.hasIndexData) {
      return orderBy(TreeUI.treeNodeStore.indexData, [treeNode => treeNode.treePosition])[0]?.treePosition;
    }
    return null;
  }

  @computed get treeId() {
    if (isPresent(this.props.params.treeId)) {
      return this.props.params.treeId;
    }
    if (TreeUI.treeNodeStore.hasIndexData) {
      return orderBy(TreeUI.treeNodeStore.indexData, [treeNode => treeNode.treePosition])[0]?.treeId;
    }
    return null;
  }

  @autobind deckGrammarConcept(conceptId) {
    return TreeUI.deckStore.showData.deckGrammarConcepts.find(deckGrammarConcept => deckGrammarConcept.conceptId === conceptId);
  }

  @autobind createDeckGrammarConcept(conceptId) {
    DeckGrammarConceptStore.create({data: {deck_id: this.props.params.deckId, concept_id: conceptId}}, this.getDeck);
  }

  @autobind destroyDeckGrammarConcept(conceptId) {
    DeckGrammarConceptStore.destroy({ids: {deckGrammarConceptId: this.deckGrammarConcept(conceptId)?.id}}, this.getDeck);
  }

  @computed get treeNodeGrammarConcepts() {
    if (TreeUI.treeNodeStore.hasShowData && isPresent(TreeUI.treeNodeStore.showData.treeNodeGrammarConcepts)) {
      return TreeUI.treeNodeStore.showData.treeNodeGrammarConcepts;
    } else if (TreeUI.treeNodeStore.hasIndexData && !TreeUI.treeNodeStore.hasShowData) {
      return uniq(flatten(TreeUI.treeNodeStore.indexData.filter(treeNode => treeNode.treeNodeType !== 'grammar').map(treeNode => treeNode.treeNodeGrammarConcepts)));
    } return [];
  }

  @autobind afterChangeGrammarDescriptionOverride() {
    this.toggledConceptDescriptions = [];
    this.showLegacyGrammarDescription = false;
    this.getDeck();
  }

  @autobind toggleConceptDescription(treeNodeGrammarConcepts) {
    if (this.toggledConceptDescriptions.indexOf(treeNodeGrammarConcepts.id) === -1) {
      this.toggledConceptDescriptions.push(treeNodeGrammarConcepts.id);
    } else {
      this.toggledConceptDescriptions = without(this.toggledConceptDescriptions, treeNodeGrammarConcepts.id);
    }
  }

  @autobind togglePronoun(pronoun, deckWord) {
    const pronounAry = deckWord.pronounsString.split(',').filter(item => isPresent(item));
    let newPronounString;
    if (pronounAry.includes(pronoun)) {
      newPronounString = without(pronounAry, pronoun).join(',');
      deckWord.set('pronounsString', newPronounString);
      TreeUI.deckWordStore.update({data: {pronouns_string: newPronounString}, ids: {deckWordId: deckWord.id}}, noop);
    } else {
      pronounAry.push(pronoun);
      newPronounString = pronounAry.join(',');
      deckWord.set('pronounsString', newPronounString);
      TreeUI.deckWordStore.update({data: {pronouns_string: newPronounString}, ids: {deckWordId: deckWord.id}}, noop);
    }
  }

  @autobind pronounIsSelected(pronoun, deckWord) {
    return deckWord?.pronounsString?.split(',').includes(pronoun);
  }

  @autobind toggleAllPronouns(deckWord) {
    const allPronounsString = keys(Constants.PRONOUN_STRINGS[TreeUI.deckStore.showData.languageId]).join(',');
    deckWord.set('pronounsString', allPronounsString);
    TreeUI.deckWordStore.update({data: {pronouns_string: allPronounsString}, ids: {deckWordId: deckWord.id}}, noop);
  }

  @autobind generateConjugationSentences() {
    this.showGenerateSentencesSpinner = true;
    TreeUI.deckStore.createConjugationSentences({ids: {deckId: this.props.params.deckId}}, this.afterGenerateConjugationSentences);
  }

  @autobind afterGenerateConjugationSentences(resp) {
    this.showGenerateSentencesSpinner = false;
    this.generateSentencesConfirmation = `${resp.sentencesCount} sentences were generated and added to the concept. Please check them (sources, set up) and then rebuild the deck.`;
  }

  _grammarConceptRow(item) {
    return (
      <tr key={item?.id}>
        <td>
          {
            item.concept &&
              <Link
                to={{ name: 'builder.concepts.edit', params: { conceptId: item.concept?.id } }}
                className='on-click'
                target='_blank'
                rel='noopener noreferrer'
              >
                <i className='fa fa-chevron-right fa-block' />
              </Link>
          }

        </td>
        <td>
          <Checkbox
            onClick={() => isPresent(this.deckGrammarConcept(item.concept?.id)) ? this.destroyDeckGrammarConcept(item.concept?.id) : this.createDeckGrammarConcept(item.concept?.id)}
            value={isPresent(this.deckGrammarConcept(item.concept?.id))} // hasDeckGrammarConcept
          />
        </td>
        <td>
          <Text heading={isPresent(this.deckGrammarConcept(item.concept?.id)) ? '3' : '0'}>
            <InPlaceText
              readOnly={!isPresent(this.deckGrammarConcept(item.concept?.id))}
              color={isPresent(this.deckGrammarConcept(item.concept?.id)) ? null : Theme.gray}
              id={item.concept?.id}
              defaultValue={item.concept?.displayNameLine1WithCase}
              field='display_name_line_1'
              model='concept'
              afterChange={this.loadTreeNode}
              placeholder='Display Name (Line 1)'
              warning='This changes the display name of this concept everywhere it appears.'
            />
          </Text>
          {
            isPresent(this.deckGrammarConcept(item.concept?.id)) &&
              <Text
                italic
                fontSize='16px'
              >
                <InPlaceText
                  readOnly={!isPresent(this.deckGrammarConcept(item.concept?.id))}
                  id={item.concept?.id}
                  defaultValue={item.concept?.displayNameLine2}
                  field='display_name_line_2'
                  model='concept'
                  afterChange={this.loadTreeNode}
                  placeholder='Display Name (Line 2) [optional]'
                  warning='This changes the display name of this concept everywhere it appears.'
                />
              </Text>
          }
          {
            (TreeUI.deckStore.showData?.grammarDescriptionOverride || !isPresent(this.deckGrammarConcept(item.concept?.id))) &&
              <DescriptionButton
                onClick={this.toggleConceptDescription.bind(this, item)}
              >
                {`${this.toggledConceptDescriptions.indexOf(item.id) !== -1 ? 'Hide' : 'Show'} description`}
              </DescriptionButton>
          }
          {
            ((isPresent(this.deckGrammarConcept(item.concept?.id)) && !TreeUI.deckStore.showData?.grammarDescriptionOverride) || this.toggledConceptDescriptions.indexOf(item.id) !== -1) &&
              <InPlaceText
                richText
                inputType='textarea'
                model='concepts'
                id={item.concept?.id}
                defaultValue={item.concept?.longDescription}
                field='long_description'
                placeholder='Long description'
                warning='This changes the description of this concept everywhere it appears (inside decks, in the dictionary...).'
              />
          }
        </td>
        <td>{item.concept?.sentencesCount}</td>
        <td style={{ textAlign: 'center' }}>{item.concept?.level && item.concept?.level?.abbreviation}</td>
      </tr>
    );
  }

  render() {
    return (
      <Wrapper>
        <div className='deck-edit'>
          {
            this.props.params.treeId &&
              <div className='breadcrumbs-wrapper'>
                <div className='breadcrumbs'>
                  <Link
                    to={{name: 'builder.trees.index'}}
                  >
                    Trees
                  </Link>
                  <i className='fa fa-angle-double-right' />
                  <Link
                    to={{name: 'builder.trees.edit', params: {treeId: this.props.params.treeId}}}
                  >
                    {TreeStore.hasShowData && TreeStore.showData.name}
                  </Link>
                  <i className='fa fa-angle-double-right' />
                  <Link
                    to={{name: 'builder.tree_modules.edit', params: {treeModuleId: this.props.params.treeModuleId, treeId: this.props.params.treeId}}}
                  >
                    {(this.hasTreeModule && this.treeModule.name) || 'Module'}
                  </Link>
                  <i className='fa fa-angle-double-right' />
                    <Link
                      to={{name: 'builder.tree_nodes.edit', params: {treeModuleId: this.props.params.treeModuleId, treeId: this.props.params.treeId, treeNodeId: this.props.params.treeNodeId}}}
                    >
                      {TreeUI.treeNodeStore.hasShowData ? TreeUI.treeNodeStore.showData?.name : 'Node'}
                    </Link>
                  <i className='fa fa-angle-double-right' />
                  <div className='current'>
                    {TreeUI.deckStore.showData.name}
                  </div>
                </div>
              </div>
          }
          {
            TreeUI.deckStore.hasShowData &&
              <div className='row' style={{flexWrap: 'nowrap'}}>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>
                      Name
                    </legend>
                    <InPlaceText
                      defaultValue={TreeUI.deckStore.showData.name}
                      model='decks'
                      field='name'
                      id={TreeUI.deckStore.showData.id}
                    />
                    {
                      TreeUI.deckStore.showData.concept && TreeUI.deckStore.showData.concept.id &&
                        <Link
                          to={{name: 'builder.concepts.edit', params: {conceptId: TreeUI.deckStore.showData.concept.id}}}
                          style={{fontSize: '12px', textDecoration: 'underline'}}
                        >
                          View Concept
                        </Link>
                    }
                  </fieldset>
                </div>
                <div className='col-xs-2'>
                  <fieldset>
                    <legend>
                      Deck Rulebook
                    </legend>
                    <InPlaceSelect
                      model='decks'
                      field='deck_rulebook_id'
                      value={TreeUI.deckStore.showData.deckRulebook ? TreeUI.deckStore.showData.deckRulebook.id : null}
                      id={TreeUI.deckStore.showDataField('id')}
                      options={DeckRulebookStore.indexData.map(item => [item.id, item.name])}
                      afterChange={this.getDeck}
                      includeBlank
                    />
                    {
                      !TreeUI.deckStore.showData.deckRulebook &&
                        <i className='fa fa-warning' />
                    }
                    {
                      isPresent(TreeUI.deckStore.showData.deckRulebook) &&
                      <Link
                        onlyActiveOnIndex
                        className='underline'
                        style={{fontSize: '12px'}}
                        target='_blank'
                        to={{name: 'builder.deck_rulebooks.edit', params: {deckRulebookId: TreeUI.deckStore.showData.deckRulebook.id}}}
                      >
                        See Rulebook
                      </Link>
                    }
                  </fieldset>
                </div>
                <div className='col-xs-2'>
                  <fieldset>
                    <legend>Level</legend>
                    {
                      !TreeUI.deckStore.showData.level &&
                        <i className='fa fa-warning' />
                    }
                    <InPlaceSelect
                      includeBlank
                      id={TreeUI.deckStore.showDataField('id')}
                      value={TreeUI.deckStore.showData.level && TreeUI.deckStore.showData.level.id}
                      field='level_id'
                      model='decks'
                      options={LevelStore.indexData.map(item => [item.id, item.nameWithAbbreviation])}
                      afterChange={this.getDeck}
                    />
                  </fieldset>
                </div>
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Settings</legend>
                    <div>
                      <InPlaceCheckbox
                        icon='check'
                        id={TreeUI.deckStore.showData.id}
                        value={TreeUI.deckStore.showData.webPublished}
                        field='web_published'
                        model='decks'
                        afterChange={this.getDeck}
                        disabled={this.showValidSetup && !TreeUI.deckStore.showData.validSetup}
                      >
                        Publish on Web
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        icon='check'
                        id={TreeUI.deckStore.showData.id}
                        value={TreeUI.deckStore.showData.mobilePublished}
                        field='mobile_published'
                        model='decks'
                        afterChange={this.getDeck}
                        disabled={this.showValidSetup && !TreeUI.deckStore.showData.validSetup}
                      >
                        Publish on Mobile
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        icon='check'
                        id={TreeUI.deckStore.showData.id}
                        value={TreeUI.deckStore.showData.openAccess}
                        field='open_access'
                        model='decks'
                        afterChange={this.getDeck}
                      >
                        Everyone Can Open
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        icon='check'
                        id={TreeUI.deckStore.showData.id}
                        value={TreeUI.deckStore.showData.showDeckGrammarCard}
                        field='show_deck_grammar_card'
                        model='decks'
                        afterChange={this.getDeck}
                      >
                        <div>Show Deck Grammar Card</div>
                      </InPlaceCheckbox>
                    </div>
                    <div>
                      <InPlaceCheckbox
                        icon='check'
                        id={TreeUI.deckStore.showData.id}
                        value={TreeUI.deckStore.showData.hasExternalContent}
                        field='has_external_content'
                        model='decks'
                        afterChange={this.getDeck}
                      >
                        Has External Content
                      </InPlaceCheckbox>
                    </div>
                    {
                      TreeUI.deckStore.showData.deckRulebook?.name?.match('Triad') &&
                        <div>
                          <InPlaceCheckbox
                            icon='check'
                            id={TreeUI.deckStore.showData.id}
                            value={TreeUI.deckStore.showData.hideVocabTriadIntro}
                            field='hide_vocab_triad_intro'
                            model='decks'
                            afterChange={this.getDeck}
                          >
                            Hide Vocab Triad Intro Text
                          </InPlaceCheckbox>
                        </div>
                    }
                  </fieldset>
                </div>
                {
                  TreeUI.treeNodeStore.hasIndexData &&
                    <div className='col-xs-2'>
                      <fieldset>
                        <legend>Tree Nodes</legend>
                        {
                          TreeUI.treeNodeStore.hasIndexData && TreeUI.treeNodeStore.indexData.map(item => {
                            return (
                              <div
                                key={`node-${item.id}`}
                              >
                                <a
                                  className='underline'
                                  style={{fontSize: 14}}
                                  href={`/builder/tree_nodes/${item.id}`}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                >
                                  {item.treePosition} - {item.name} [{item.treeName}]
                                </a>
                              </div>
                            );
                          })
                        }
                      </fieldset>
                    </div>
                }
              </div>
          }
          {
            TreeUI.deckStore.hasShowData && (TreeUI.deckStore.showData.deckType === 'concept' || TreeUI.deckStore.showData.deckTypeIsTriad) &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>
                      Description (Markdown)
                    </legend>
                    <InPlaceText
                      markdown
                      inputType='textarea'
                      model='decks'
                      id={TreeUI.deckStore.showData.id}
                      defaultValue={TreeUI.deckStore.showData.descriptionMd}
                      field='description_md'
                    />
                  </fieldset>
                </div>
              </div>
          }
          {
            TreeUI.deckStore.hasShowData && (TreeUI.deckStore.showData.deckType === 'concept' || TreeUI.treeNodeStore.showData.treeNodeType === 'concept' || TreeUI.deckStore.showData.deckTypeIsTriad) &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>
                      Grammar Concepts
                      <span
                        data-for='grammarConcepts'
                        data-tip
                        style={{marginLeft: '5px'}}
                      >
                        <i className='fa fa-info-circle' />
                        <ReactTooltip
                          place='right'
                          type='dark'
                          effect='solid'
                          id='grammarConcepts'
                          className='custom-tooltip'
                        >
                          <div>Select the tree node's grammar concepts you want to use in this deck.</div>
                        </ReactTooltip>
                      </span>
                    </legend>
                    {
                      !TreeUI.deckStore.showData?.showDeckGrammarCard &&
                        // <Text italic fontSize="14px">.</Text>
                        <Alert
                          textAlign='center'
                          fontSize='12px'
                          background={Theme.gray}
                        >
                          This deck has no grammar card (see settings). This grammar description will not be visible at the beginning of the deck.
                        </Alert>
                    }
                    <table className='table-wrapper'>
                      <thead>
                        <tr>
                          <th width='20' />
                          <th width='50'>Select</th>
                          <th style={{ textAlign: 'center' }}>Name & description</th>
                          <th width='50'>#Sent</th>
                          <th width='80'>Level</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.treeNodeGrammarConcepts?.map(item => {
                            if (item.concept) {
                              return this._grammarConceptRow(item);
                            } else {
                              return null;
                            }
                          })
                        }
                        {
                          isBlank(this.treeNodeGrammarConcepts) &&
                          <tr>
                            <td colSpan='100%' style={{textAlign: 'center'}}>
                              <Text italic fontSize='14px'>It looks like the deck's tree node(s) don't have grammar concepts!</Text>
                            </td>
                          </tr>
                        }
                      </tbody>
                    </table>
                    <div style={{marginTop: '10px'}}>
                      <InPlaceCheckbox
                        icon='check'
                        model='decks'
                        field='grammar_description_override'
                        value={TreeUI.deckStore.showData.grammarDescriptionOverride}
                        id={TreeUI.deckStore.showData.id}
                        afterChange={this.afterChangeGrammarDescriptionOverride}
                      >
                        Use legacy description rather than grammar concepts
                      </InPlaceCheckbox>
                    </div>
                    {
                      TreeUI.deckStore.hasShowData && TreeUI.deckStore.showData.showDeckGrammarCard &&
                        <DescriptionButton
                          onClick={() => this.showLegacyGrammarDescription = !this.showLegacyGrammarDescription}
                        >
                          {`${this.showLegacyGrammarDescription ? 'Hide' : 'Show'} description (legacy)`}
                        </DescriptionButton>
                    }
                    {
                      TreeUI.deckStore.showData.showDeckGrammarCard && this.showLegacyGrammarDescription &&
                        <InPlaceText
                          richText
                          inputType='textarea'
                          model='decks'
                          id={TreeUI.deckStore.showData.id}
                          defaultValue={TreeUI.deckStore.showData.grammarDescription}
                          field='grammar_description'
                        />
                    }
                  </fieldset>
                </div>
              </div>
          }
          <div className='row'>
            <div className='col-xs-3'>
              <fieldset style={{position: 'relative'}}>
                <legend>Approved</legend>
                  <div>
                    <InPlaceCheckbox
                      model='decks'
                      field='reviewed1'
                      value={TreeUI.deckStore.showData.reviewed1}
                      id={TreeUI.deckStore.showData.id}
                      icon='thumbs-up'
                      afterChange={this.getDeck}
                    >
                      Didactics 1
                    </InPlaceCheckbox>
                  </div>
                  {
                    isPresent(TreeUI.deckStore.showData.reviewed1User) &&
                      <ReviewedUser>
                        {TreeUI.deckStore.showData.reviewed1User.name}
                      </ReviewedUser>
                  }
                  <div>
                    <InPlaceCheckbox
                      model='decks'
                      field='reviewed3'
                      value={TreeUI.deckStore.showData.reviewed3}
                      id={TreeUI.deckStore.showData.id}
                      icon='thumbs-up'
                      afterChange={this.getDeck}
                    >
                      Didactics 2
                    </InPlaceCheckbox>
                  </div>
                  {
                    isPresent(TreeUI.deckStore.showData.reviewed3User) &&
                      <ReviewedUser>
                        {TreeUI.deckStore.showData.reviewed3User.name}
                      </ReviewedUser>
                  }
                  <div>
                    <InPlaceCheckbox
                      model='decks'
                      field='reviewed2'
                      value={TreeUI.deckStore.showData.reviewed2}
                      id={TreeUI.deckStore.showData.id}
                      icon='thumbs-up'
                      afterChange={this.getDeck}
                    >
                      English
                    </InPlaceCheckbox>
                  </div>
                  {
                    isPresent(TreeUI.deckStore.showData.reviewed2User) &&
                      <ReviewedUser>
                        {TreeUI.deckStore.showData.reviewed2User.name}
                      </ReviewedUser>
                  }
              </fieldset>
            </div>
            {
              this.showValidSetup &&
                <div className='col-xs-3'>
                  <fieldset style={{position: 'relative'}}>
                    <legend>Deck is Valid?</legend>
                    <div style={{display: 'flex', alignItems: 'center', fontSize: '14px', lineHeight: '14px'}}>
                      {
                        TreeUI.deckStore.showData.validSetup &&
                          <CheckIfTrue
                            value={TreeUI.deckStore.showData.validSetup}
                          />
                      }
                      {
                        !TreeUI.deckStore.showData.validSetup &&
                        <InfoTooltip icon='warning'><div>If everything looks good here, check that the deck rulebook</div><div>is valid, and rebuild if necessary.</div></InfoTooltip>
                      }
                      {TreeUI.deckStore.showData.validSetup ? ' Valid' : ' Invalid' }
                    </div>
                    <button
                      className='gray-button'
                      onClick={this.checkIfValid}
                    >
                      Check if Valid
                    </button>
                  </fieldset>
                </div>
            }
            <div className='col-xs-3'>
              <fieldset>
                  <legend>Rating</legend>
                  <RatingInput
                    hideTooltip
                    starWidth='24px'
                    id={TreeUI.deckStore.showDataField('id')}
                    maxRating={3}
                    defaultValue={TreeUI.deckStore.showDataField('rating')}
                    onClick={value => this.onClickRating(value)}
                  />
                </fieldset>
            </div>
            <div className='col-xs-3'>
              {
                TreeUI.deckStore.hasShowData && isPresent(TreeUI.deckStore.showData.cards) &&
                  <fieldset>
                    <legend>
                      Links
                    </legend>
                    <Link
                      to={{name: this.routeName, params: this.params}}
                      target='seedlang'
                    >
                      <button
                        className='gray-button'
                      >
                        <i className='fa fa-angle-right' />
                        View Deck
                      </button>
                    </Link>
                    {
                      isPresent(TreeUI.deckStore.showData?.treeNodeIds) && TreeUI.deckStore.showData.treeNodeIds.split(',').map(item => {
                        return (
                          <Link
                            to={{name: 'builder.tree_nodes.direct_edit', params: {treeNodeId: item}}}
                            key={item}
                            target='seedlang'
                          >
                            <button
                              className='gray-button'
                            >
                              <i className='fa fa-angle-right' />
                              View Tree Node
                            </button>
                          </Link>
                        );
                      })
                    }
                  </fieldset>
              }
            </div>
          </div>
          {
            TreeUI.deckStore.hasShowData &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Words</legend>
                    {
                      this.wordCountWarning &&
                        <Alert
                          textAlign='center'
                        >
                          <span><i className='fa fa-warning' /> <span>{this.wordCountWarning}</span></span>
                        </Alert>
                    }
                    {
                      TreeUI.deckStore.hasShowData &&
                        <SearchMultiSelect
                          ids={ConceptStore.showData.mappedVocabWordsList && ConceptStore.showData.mappedVocabWordsList.map(item => item.id)}
                          options={TreeUI.deckStore.showData.mappedVocabWordsList}
                          onCreate={this.onCreateDeckWord}
                          onDestroy={this.onDestroyDeckWord}
                          searchStore={WordStore}
                          searchFilters={this.wordSearchFilters}
                          searchField='target_text'
                          displayField='targetWithWordTypeParentInfinitiveAndSource'
                          sort='target_text'
                          linkTo='builder.words.edit'
                          linkId='wordId'
                          currentTreeNodePosition={this.treePosition}
                          previousTreeNodeDecks={this.previousTreeNodeDecks}
                          namespace='builder.deck.words'
                          filters={[
                            {
                              type: 'boolean',
                              label: 'Root',
                              name: 'root',
                            },
                            {
                              type: 'boolean',
                              name: 'plural',
                              label: 'Plural',
                              default: '',
                            },
                            {
                              type: 'multi_select',
                              name: 'level_id',
                              label: 'Level',
                              options: LevelStore.indexData.filter(item => item.abbreviation !== 'N' && item.abbreviation !== ''),
                              default: LevelStore.pluckIndex('id'),
                              labelField: 'abbreviation',
                            },
                            {
                              type: 'select',
                              name: 'word_type_id',
                              label: 'Word Type',
                              options: orderBy(Constants.WORD_TYPES, ['name']),
                              labelField: 'name',
                              highlightDictionaryWordTypes: 'true',
                            },
                          ]}
                        />
                    }
                    {
                      TreeUI.deckStore.hasShowData && (TreeUI.deckStore.showData.deckType === 'concept' || TreeUI.deckStore.showData.deckTypeIsTriad) && !TreeUI.deckStore.showData.numberDeck &&
                        <span
                          data-tip
                          data-for='auto-create-all-words'
                        >
                          <button
                            onClick={this.createAllWords}
                            className='button-primary'
                            style={{margin: '5px 5px 0 0'}}
                          >
                            <i className='fa fa-angle-right' />
                            Generate All Words
                          </button>
                          <ReactTooltip
                            place='right'
                            type='dark'
                            effect='solid'
                            id='auto-create-all-words'
                            class='custom-tooltip'
                          >
                            This will reset the list of words and fill it with all the words contained in the sentences.
                          </ReactTooltip>
                        </span>
                    }
                    {
                      TreeUI.deckStore.hasShowData && (TreeUI.deckStore.showData.deckType === 'concept' || TreeUI.deckStore.showData.deckTypeIsTriad) && !TreeUI.deckStore.showData.numberDeck &&
                        <span
                          data-tip
                          data-for='auto-create-unseen-words'
                        >
                          <button
                            className={cx('button-primary', {
                              disabled: isBlank(this.treePosition),
                            })}
                            onClick={this.createUnseenWords}
                            style={{margin: '5px 0 0 5px'}}
                            disabled={isBlank(this.treePosition)}
                          >
                            <i className='fa fa-angle-right' />
                            Generate Unseen Words
                          </button>
                          <ReactTooltip
                            place='right'
                            type='dark'
                            effect='solid'
                            id='auto-create-unseen-words'
                            class='custom-tooltip'
                          >
                            {
                              isBlank(this.treePosition) &&
                                <span>Can't find the position of this deck in the tree. Is it in a node?</span>
                            }
                            {
                              isPresent(this.treePosition) &&
                              <span>This will reset the list of words and fill it with words from the sentences <i>that don't appear in the tree before this deck</i>.</span>
                            }
                          </ReactTooltip>
                        </span>
                    }
                    {
                      TreeUI.deckStore.hasShowData && TreeUI.deckStore.showData.numberDeck &&
                        <InPlaceTextInput
                          submitOnEnter
                          inputType='text'
                          placeholder='Add numbers by entering a series of digits (ex: 11,24,27,28)'
                          onChange={value => this.batchDigits = value}
                          onSubmit={this.onSubmitBatchDigits}
                          value={this.batchDigits}
                          style={{marginTop: '5px'}}
                        />
                    }
                    {
                      !(isBlank(this.skippedDigits)) &&
                        <Alert
                          textAlign='center'
                          margin='10px 0 10px 0'
                        >
                          <span><i className='fa fa-warning' />{`There are no words with these numbers as sources: ${this.skippedDigits}`}</span>
                        </Alert>
                    }
                    {
                      this.missingWords && this.missingWords.length > 0 && TreeUI.deckStore.showData.deckTypeIsTriad &&
                        <Alert
                          textAlign='center'
                          margin='10px 0 10px 0'
                        >
                          <span><i className='fa fa-warning' /> <span>The following words appear in this deck's sentences and have not been introduced previously:</span>
                            <div>{ this.missingWords.map(item => item.targetTextWithDefiniteArticle).join(', ') }</div>
                          </span>
                        </Alert>
                    }
                  </fieldset>
                </div>
              </div>
          }
          {
            TreeUI.deckStore.hasShowData && TreeUI.deckStore.showData.conjugationWithVideoDeck &&
            <div className='row' id='exercise-top'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>Conjugation Cards Setup
                    <InfoTooltip position='right'>
                      <div>You will need to rebuild the deck and check the sentences</div><div> every time you change this set up.</div>
                    </InfoTooltip>
                  </legend>
                  <div style={{display: 'flex', gap: '20px'}}>
                    {
                      TreeUI.deckStore.showData.conjugationWithVideoDeck &&
                      <div className='col-xs-3' style={{margin: '10px 0'}}>
                        <InPlaceSelect
                          model='decks'
                          field='conjugation_tense_id'
                          value={TreeUI.deckStore.showData.conjugationTenseId}
                          id={TreeUI.deckStore.showDataField('id')}
                          options={this.conjugationTenseOptions}
                          afterChange={this.getDeck}
                          includeBlank
                          label='Conjugation Tense'
                        />
                      </div>
                    }
                    {
                      TreeUI.deckWordStore.hasIndexData && TreeUI.deckWordStore.indexData.length === 1 &&
                      <Text fontSize='14px' margin='10px 0'>
                        You've selected 1 word for this conjugation deck. Click "Generate Sentences" to generate all 6 sentences for the different pronouns. They will be added to the concept.
                      </Text>
                    }
                    {
                      TreeUI.deckWordStore.hasIndexData && TreeUI.deckWordStore.indexData.length > 1 &&
                      <Text fontSize='14px' margin='10px 0'>
                        You've selected several words for this conjugation deck. Please select which pronouns you want to conjugate each verb with.
                        Click "Generate Sentences" to generate all selected sentences and add them to the concept.
                      </Text>
                    }
                  </div>
                  {
                    TreeUI.deckWordStore.hasIndexData && TreeUI.deckWordStore.indexData.length > 1 &&
                    <table className='table-wrapper'>
                      <thead>
                      <tr>
                        <th>Word</th>
                        {
                          values(Constants.PRONOUN_STRINGS[TreeUI.deckStore.showData.languageId]).map(item => {
                            return (
                              <th width='80' key={Math.random()}>{item}</th>
                            );
                          })
                        }
                        <th width='150' key={Math.random()}>Select All</th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        TreeUI.deckWordStore.hasIndexData && TreeUI.deckWordStore.indexData.map(deckWord => {
                          return (
                            <tr key={Math.random()}>
                              <td>{deckWord.word.targetText}</td>
                              {
                                keys(Constants.PRONOUN_STRINGS[TreeUI.deckStore.showData.languageId]).map(pronoun => {
                                  return (
                                    <td key={Math.random()}>
                                      <Checkbox
                                        onClick={() => this.togglePronoun(pronoun, deckWord)}
                                        value={this.pronounIsSelected(pronoun, deckWord)}
                                      />
                                    </td>
                                  );
                                })
                              }
                              <td>
                                <button
                                  onClick={() => this.toggleAllPronouns(deckWord)}
                                >
                                  Select All
                                </button>
                              </td>
                            </tr>
                          );
                        })
                      }
                      </tbody>
                    </table>
                  }
                  {
                    TreeUI.deckWordStore.requestCounter > 0 &&
                    <Spinner />
                  }
                  {
                    TreeUI.deckWordStore.indexData.length >= 1 &&
                    <div style={{marginTop: '10px'}}>
                      {
                        this.showGenerateSentencesSpinner &&
                        <Spinner />
                      }
                      {
                        !this.showGenerateSentencesSpinner &&
                          <button
                            className='button-primary'
                            onClick={this.generateConjugationSentences}
                          >
                            <i className='fa fa-plus'/>
                            Generate Sentences
                          </button>
                      }
                      {
                        isPresent(this.generateSentencesConfirmation) &&
                        <Alert background={Theme.green}>
                          <div>{this.generateSentencesConfirmation}</div>
                          <Link
                            className='underline'
                            to={{name: 'builder.concepts.edit', params: {conceptId: TreeUI.deckStore.showData.concept.id}}}
                            target='_blank'
                          >
                            View Concept
                          </Link>.
                        </Alert>
                      }
                    </div>
                  }
                </fieldset>
              </div>
            </div>
          }
          {
            TreeUI.deckStore.hasShowData && (TreeUI.deckStore.showData.deckType === 'concept' || TreeUI.deckStore.showData.deckTypeIsTriad) &&
              <div className='row' id='exercise-top'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Exercises (Recommended: {this.recommendedCount})</legend>
                    <ExerciseCreate
                      hideName
                      filterExerciseOptions={['matchMedia', 'reorder', 'multipleChoice']}
                      deckId={this.props.params.deckId}
                      afterCreate={this.getDeck}
                      onClickAutoCreate={this.onAutoCreateExercises}
                      showAutoCreate={this.showAutoCreateExercisesButton}
                    />
                    <ExerciseIndex
                      languageId={TreeUI.deckStore.showData.concept?.languageId}
                      inlineEntries
                      showPosition
                      allowTargetInput
                      afterCreate={this.reloadDeckOnly}
                      exercises={TreeUI.deckStore.showData.exercises}
                      inDeck
                    />
                  </fieldset>
                </div>
              </div>
          }
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>
                  Cards
                </legend>
                {
                  TreeUI.deckStore.showData.deckType === 'trivia' && TreeUI.deckStore.showData.triviaCards.length !== 7 &&
                    <Alert
                      textAlign='center'
                      margin='10px 0'
                    >
                      <i className='fa fa-warning' />
                      This deck needs to have exactly 7 sentences.
                    </Alert>
                }
                <CardIndex
                  deck={TreeUI.deckStore.showData}
                  cards={TreeUI.deckStore.showData.cards}
                  afterChange={this.getDeck}
                  showMissingUnderlines={TreeUI.deckStore.showData.deckTypeIsTriad}
                />
                <div className='row' style={{marginTop: 20}}>
                  <div className='col-xs-12'>
                    {
                      !this.submittingRebuildingDeck &&
                        <RebuildCards>
                          <DeleteButton
                            onConfirm={this.rebuildDeck}
                            message={TreeUI.deckStore.showData.concept?.sentencesCount > 20 ? `Caution! This concept has ${TreeUI.deckStore.showData.concept?.sentencesCount} sentences!` : 'Rebuild all Cards?'}
                            floatsRight
                            confirmationMessageFloatsRight
                            left='175px'
                            right='auto'
                            top='-17px'
                          >
                            <button
                              className='gray-button'
                            >
                              <i className='fa fa-plus' />
                              Rebuild Cards
                            </button>
                          </DeleteButton>
                          <InPlaceCheckbox
                            wrapperMargin='0 0 0 10px'
                            onChange={() => this.addMissingCards = !this.addMissingCards}
                            value={this.addMissingCards}
                            icon='check'
                          >
                            Add Missing Cards
                          </InPlaceCheckbox>
                          <InPlaceCheckbox
                            wrapperMargin='0 0 0 10px'
                            onChange={() => this.keepCardPositions = !this.keepCardPositions}
                            value={this.keepCardPositions}
                            icon='check'
                          >
                            Keep Cards in This Order
                          </InPlaceCheckbox>
                        </RebuildCards>
                    }
                    {
                      TreeUI.deckStore.hasShowData && TreeUI.deckStore.showData.triviaDeckOutOfSynch &&
                        <div className='row'>
                          <div className='col-xs-12'>
                            <Alert
                              textAlign='center'
                              margin='10px 0 0 0'
                            >
                              <i className='fa fa-warning' />
                              This deck is out of sync with the concept, the cards should be rebuilt.
                            </Alert>
                          </div>
                        </div>
                    }
                    {
                      this.submittingRebuildingDeck &&
                        <Spinner
                          className='blue'
                        />
                    }
                  </div>
                </div>
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>
                  User Decks
                </legend>
                {
                  this.showUserDecks &&
                    <UserDeckIndex
                      deckId={this.props.params.deckId}
                    />
                }
                {
                  !this.showUserDecks &&
                    <button
                      className='gray-button'
                      onClick={() => this.showUserDecks = true}
                    >
                      <i className='fa fa-plus' />
                      Show User Decks
                    </button>
                }
              </fieldset>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default DeckEdit;
