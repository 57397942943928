import React from 'react';
import { observer } from 'mobx-react';
import { UserInputWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Meter from 'components/meter';
import { SendIcon, PencilIcon, RecordingIcon, TrashCanIcon } from '@seedlang/icons';
import AudioPlayer from 'components/media/audio_player';
import { Theme } from '@seedlang/constants';
import { flexCenter, transition } from '@seedlang/style_mixins';
import SpecialCharacterButtons from 'components/special_character_buttons';
import InPlaceTextInput from 'components/form/in_place_text_input';
import { pixify } from '@seedlang/utils';
import HeightLabel from 'components/height_label';
import { DeckUI } from '@seedlang/state';
import Text from 'components/text';

const Wrapper = styled.div`
  height: ${props => props.height};
  width: 100%;
  z-index: ${Theme.z['card-3']};
  border-top: ${props => props.borderTop};
  .icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    width: ${props => props.iconWidth};
    height: ${props => props.iconWidth};
    svg {
      fill: #FFF;
    }
  }
  .icon-wrapper {
    cursor: ${props => props.disabled ? 'auto' : 'pointer'};
  }
  .pencil-icon {
    background: ${Theme.darkestBlue};
    svg {
      width: 17px;
      margin-top: -3px;
    }
  }
  .trash-can-icon {
    svg {
      width: 17px;
      height: 18px;
      margin-top: -2px;
      fill: ${Theme.red};
    }
  }
  .send-icon {
    svg {
      width: 17px;
      margin-left: -3px;
    }
  }
`;

const Left = styled.div`
  width: 60px;
`;

const PencilIconWrapper = styled.div`
  ${flexCenter()}
  width: ${props => props.width};
  height: ${props => props.width};
  border: 2px solid ${Theme.darkestBlue};
  border-radius: 50%;
`;

const InnerWrapper = styled.div`
  ${flexCenter()}
  width: 100%;
  height: 100%;
  background: white;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 3px;
  & > div {
    margin: 0 2px;
  }
`;

const Middle = styled.div`
  flex: 1;
`;

const TextAreaWrapper = styled.div`
  position: absolute;
  top: ${props => props.top};
  left: 0;
  right: 0;
  padding: 10px;
  margin: auto;
  height: 0px;
  z-index: ${Theme.z['card-4']};
  textarea {
    ${transition(0.2)}
    height: 80px;
    line-height: 14px;
    border-radius: 10px;
    line-height: 20px;
    opacity: ${props => props.disabled ? 0.3 : 1};
    resize: none;
    width: 100%;
  }
  textarea:focus {
    border: 1px solid #D1D1D1;
  }
`;

const MeterWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  width: 100%;
`;

const AudioPlayerWrapper = styled.div`
  margin-left: 3px;
`;

const CenterIconWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Right = styled.div`
  width: ${props => props.width};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 10px;
`;

@observer
class UserInput extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(this.props.height)}
        disabled={this.props.disabled}
        iconWidth={pixify(this.props.iconWidth)}
        borderTop={this.props.borderTop}
      >
        <InnerWrapper>
          {
            DeckUI.layout.debug &&
              <HeightLabel
                left={0}
              >
                {this.props.height}
              </HeightLabel>
          }
          {
            !this.props.showAudioPlayer && this.props.showTrashIcon && this.props.mediaStore.isRecording &&
              <Left>
                <Text
                  fontSize={12}
                  marginLeft={20}
                  color='#333'
                >
                  {this.props.mediaStore.timeDisplay}
                </Text>
              </Left>
          }
          {
            (this.props.showAudioIcon || this.props.showTextIcon) &&
              <CenterIconWrapper>
                {
                  this.props.showAudioIcon &&
                    <RecordingIcon
                      type='audio'
                      marginRight={2}
                      disabled={this.props.disabled}
                      width={pixify(this.props.iconWidth)}
                      background={this.props.recordingIconBackground}
                      borderColor={this.props.recordingIconBorderColor}
                      recording={this.props.audioRecording}
                      onToggleRecord={this.props.onToggleRecordAudio}
                    />
                }
                {
                  this.props.showTextIcon &&
                    <PencilIconWrapper
                      width={pixify(this.props.iconWidth + 10)}
                      height={pixify(this.props.iconWidth + 10)}
                      onClick={() => this.props.setInputType('text')}
                    >
                      <PencilIcon
                        marginLeft={this.props.showAudioIcon ? 2 : 0}
                        width={pixify(this.props.iconWidth)}
                      />
                    </PencilIconWrapper>
                }
              </CenterIconWrapper>
          }
          {
            ((this.props.persistentTextInput || this.props.showTextInput) || this.props.showAudioPlayer) &&
              <Middle>
                {
                  (this.props.persistentTextInput || this.props.showTextInput) &&
                    <TextAreaWrapper
                      disabled={this.props.textInputDisabled}
                      expanded={this.props.expandedTextInput}
                      top={pixify(DeckUI.layout.cardTextHeight * -1)}
                    >
                      <InPlaceTextInput
                        placeholder={this.props.inputPrompt || 'Type a Message'}
                        inputType='textarea'
                        disabled={this.props.textInputDisabled}
                        caretPosition={this.props.caretPosition}
                        focusOnId={this.props.messageInput}
                        value={this.props.messageInput}
                        onKeyUp={this.props.onKeyUp.bind(this)}
                        id='user-input'
                        onChange={this.props.onUpdateMessageInput}
                        height={DeckUI.layout.cardTextHeight - 20}
                        expandedHeight={DeckUI.layout.cardTextHeight - 20}
                      />
                    </TextAreaWrapper>
                }
                {
                  (this.props.persistentTextInput || this.props.showTextInput) &&
                    <SpecialCharacterButtons
                      border='1px solid #c7c7c7'
                      onClick={this.props.insertText}
                      width={DeckUI.layout.innerDeckWidth - 50}
                      outerPadding='0 5px'
                    />
                }
                {
                  this.props.showAudioPlayer &&
                    <AudioPlayerWrapper>
                      <AudioPlayer
                        className='user-input'
                        mediaStore={this.props.mediaStore}
                        onPlayAudio={this.props.onPlayAudio}
                      />
                    </AudioPlayerWrapper>
                }
              </Middle>
          }
          {
            this.props.showTrashIcon &&
              <Right
                width={this.props.mediaStore.isRecording ? '60px' : '40px'}
              >
                <TrashCanIcon
                  className='trash-can-icon-wrapper'
                  background='#FFF'
                  onClick={this.props.onReset}
                />
              </Right>
          }
          {
            this.props.showSendIcon &&
              <SendIcon
                onClick={this.props.onSubmit}
                background={this.props.disableSendIcon ? Theme.disabled : Theme.green}
              />
          }
          <MeterWrapper>
           {
            !this.props.disabled &&
              <Meter
                numSegments={20}
                margin={5}
                projectToward='right'
                level={this.props.mediaStore.normalizedAudioLevel}
              />
           }
          </MeterWrapper>
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default UserInputWrapper(UserInput);
