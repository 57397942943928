import React from 'react';
import { observer } from 'mobx-react';
import TypeAhead from 'components/type_ahead';
import styled from '@emotion/styled';
import { WordSearchStore } from '@seedlang/stores';
import { WordSearchWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  input {
    padding: 10px;
    height: ${props => props.height};
    font-size: 14px;
  }
`;

@observer
class WordSearch extends React.Component {
  static defaultProps = {
    height: '40px',
  }

  render() {
    return (
      <Wrapper
          height={this.props.height}
      >
        <TypeAhead
          keepInputOnBlur={this.props.keepInputOnBlur}
          top='60px'
          onCreateValue={this.props.onCreateValue}
          onKeyUp={this.props.onKeyUp}
          onSelect={this.props.onSelect}
          onClear={this.props.onClear}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          options={WordSearchStore.indexData}
          displayField={this.props.displayField}
          placeholder={this.props.placeholder || 'Enter Word Target'}
          height={this.props.height}
          border={this.props.border}
        />
      </Wrapper>
    );
  }
}

export default WordSearchWrapper(WordSearch);
