import React from 'react';
import { render } from 'react-dom';
import routes from './routes';
import { Router, useRouterHistory } from 'react-router';
import createHistory from 'history/lib/createBrowserHistory';
import useNamedRoutes from 'use-named-routes';
import { AppLayout } from '@seedlang/layouts';

document.addEventListener('DOMContentLoaded', () => {
  window.layout = new AppLayout(this);
  const history = useNamedRoutes(useRouterHistory(createHistory))({ routes });
  render(
    <Router
      history={history}
      routes={routes}
    />,
    document.getElementById('root'),
  );
});
