import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Motion, spring, presets } from 'react-motion';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import VideoPlayer from 'components/media/video_player';
import StickerReaction from 'components/sticker_reaction';

const Wrapper = styled.div`
  margin: 20px 0;
  position: relative;
  width: 250px;
  height: 250px;
  @media (max-height : 550px) {
    margin: 10px 0;
  }
`;

const Center = styled.div`
  z-index: ${Theme.z['card-3']};
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  background-size: 250px;
  background-color: ${Theme.blue};
  position: absolute;
  width: 250px;
  height: 250px;
  border: 10px solid #1e3f54;
  overflow: hidden;
`;

const ChromeFix = styled.div`
  visibility: hidden;
  position: absolute;
`;

@observer
class CheckpointCardCenter extends React.Component {
  @observable scale = 0;

  constructor(props) {
    super(props);
    if (props.inView) {
      this.initiateAnimationWithDelay();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.inView && this.props.inView) {
      this.initiateAnimationWithDelay();
    }
  }

  @autobind initiateAnimationWithDelay() {
    if (this.scale !== 1) {
      setTimeout(this.initiateAnimation, 150);
    }
  }

  @autobind initiateAnimation() {
    this.scale = 1;
    AppUI.soundEffectStore.play('horn');
  }

  render() {
    return (
      <Wrapper>
        <Motion
          style={{
            scale: spring(this.scale, presets.wobbly),
          }}
        >
          {
            ({scale}) =>
              <Center
                style={{
                  transform: `scale(${scale})`,
                }}
              >
                <ChromeFix>
                  {scale}
                </ChromeFix>
                <StickerReaction
                  width='230px'
                  card={this.props.card}
                  reaction='happy'
                  languageId={this.props.languageId}
                />
              </Center>
          }
        </Motion>
      </Wrapper>
    );
  }
}

export default CheckpointCardCenter;
