import React from 'react';
import { observer } from 'mobx-react';
import { GenderCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import CardSection from 'components/vocab_card/card_section';
import MediaSection from 'components/vocab_card/media_section';
import { DeckUI } from '@seedlang/state';
import CardSourceList from 'components/vocab_card/card_source_list';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import { Theme } from '@seedlang/constants';
import Text from 'components/text';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import EvaluateButtons from 'components/card/evaluate_buttons';
import ExpandedTarget from 'components/vocab_card/expanded_target';

const IconWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 20px;
  z-index: ${Theme.z['card-3']};
  .fa-times {
    color: ${Theme.red};
  }
  .fa-check {
    color: ${Theme.green};
  }
`;

const PromptText = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #FFF;
  font-style: italic;
`;

@observer
class GenderCard extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent
          padding={0}
        >
          {
            !this.props.revealed &&
              <CardSection
                background='#5d5d5d'
                borderTopRadius={10}
                numSections={3}
              >
                <PromptText>
                  What is the correct definite article?
                </PromptText>
              </CardSection>
          }
          {
            this.props.revealed && this.props.card.hasTargetVideoClip &&
              <MediaSection
                autoPlay={this.props.inView}
                showMedia
                borderTopRadius={10}
                card={this.props.card}
                cardState={this.props.cardState}
              />
          }
          <CardSection
            background='#FFF'
            borderTopRadius={!this.props.card.hasTargetVideoClip && this.props.revealed ? 10 : 0}
            numSections={this.props.card.hasTargetVideoClip ? 3 : 2}
          >
            {
              this.props.revealed &&
                <IconWrapper>
                  {
                    this.props.userCard.correct &&
                      <i className='fa fa-check' />
                  }
                  {
                    !this.props.userCard.correct &&
                      <i className='fa fa-times' />
                  }
                </IconWrapper>
            }
            {
              this.props.showText && !this.props.revealed &&
                <Text
                  fontSize='30px'
                  bold
                >
                  {this.props.card.word.targetText}
                </Text>
            }
            {
              this.props.showText && this.props.revealed &&
                <ExpandedTarget
                  cardState={this.props.cardState}
                  height={DeckUI.vocabTextHeight}
                  word={this.props.card.word}
                  description={this.props.description}
                />
            }
          </CardSection>
          <CardSection
            background='#CCC'
            borderBottomRadius={10}
            numSections={this.props.card.hasTargetVideoClip ? 3 : 2}
          >
            <CardSourceList
              showEdit
              word={this.props.card.word}
              card={this.props.card}
              hideGender={!this.props.revealed}
            />
          </CardSection>
        </CardContent>
        {
          !this.props.revealed &&
            <CardButtons
              height={DeckUI.layout.buttonHeight}
            >
              {
                this.props.definiteArticleOptions.map((item, index) => {
                  return (
                    <CardButton
                      key={index}
                      shortcut={index + 1}
                      marginRight={5}
                      text={item[1]}
                      disabled={!this.props.inView}
                      backgroundColor={this.props.buttonColors[item[0]]}
                      color={this.props.buttonColors[item[0]] === '#FFF' ? '#333' : '#FFF'}
                      onClick={() => this.props.onClick(item[0])}
                    />
                  );
                })
              }

            </CardButtons>
        }
        {
          this.props.revealed &&
            <EvaluateButtons
              card={this.props.card}
              onNextClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
              cardState={this.props.cardState}
              userCard={this.props.userCard}
              disabled={!this.props.inView}
            />
        }
      </CardWrapper>
    );
  }
}

export default GenderCardWrapper(GenderCard);
