import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import Paginator from 'components/paginator';
import cx from 'classnames';
import { DeckStore , LevelStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import Filter from 'components/backend_filter/filter';
import SortableColumns from 'components/hoc/sortable_columns';
import CheckIfTrue from 'components/check_if_true';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ReactTooltip from 'react-tooltip';
import RatingInput from 'components/rating_input';
import { noop } from 'lodash';
import DeleteButton from 'components/button/delete_button';
import { isPresent } from '@seedlang/utils/src';

@observer
class DeckIndex extends React.Component {
  @observable page;

  constructor(props) {
    super(props);
    if (!LevelStore.hasIndexData) {
      LevelStore.getIndex();
    }
  }

  componentDidMount() {
    this.page = window.location.href.split('page/')[1];
    DeckStore.setPage(this.page);
  }

  componentWillUnmount() {
    DeckStore.clearIndexData();
    DeckStore.clearFilter();
  }

  @autobind onDelete(id) {
    DeckStore.destroy({ids: {deckId: id}}, DeckStore.reloadIndex.bind(DeckStore));
  }

  @autobind onClickRating(item, value, deckId) {
    const adjustedRating = value === item.rating ? 0 : value;
    item.rating = adjustedRating;
    DeckStore.update({ids: {deckId: deckId}, data: {rating: adjustedRating}}, noop);
  }

  render() {
    return (
      <div className='deck-index'>
        {
          this.page &&
          <Filter
            store={DeckStore}
            defaultSort='first_tree_node_position'
            defaultFilters={isPresent(this.props.filters) ? this.props.filters : {user_id: false}}
            namespace='concept-index'
            queryStrings={{include_count: true}}
            page={this.page}
            filters={
              [
                {
                  type: 'text',
                  name: 'name',
                  label: 'name',
                  placeholder: 'Enter deck name',
                  default: '',
                },
                {
                  type: 'select',
                  name: 'language_id',
                  options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                  labelField: 'name',
                  label: 'Language',
                  default: '',
                },
                {
                  type: 'multi_select',
                  name: 'deck_type',
                  options: [{id: 'concept', name: 'concept'}, {id: 'trivia', name: 'trivia'}, {id: 'vocab', name: 'vocab'}, {id: 'triad', name: 'triad'}],
                },
                {
                  type: 'multi_select',
                  name: 'level_id',
                  options: LevelStore.indexData.filter(item => item.abbreviation !== 'C1' && item.abbreviation !== 'N'),
                  default: LevelStore.pluckIndex('id'),
                  labelField: 'abbreviation',
                },
                {
                  type: 'multi_select',
                  name: 'rating',
                  options: [{id: 1, name: '1 Star'}, {id: 2, name: '2 Stars'}, {id: 3, name: '3 Stars'}],
                  label: 'Rating',
                  labelField: 'name',
                },
                {
                  type: 'boolean',
                  name: 'valid_setup',
                  label: 'Valid Setup',
                },
                {
                  type: 'boolean',
                  name: 'reviewed1',
                  label: 'Approved: Didactics 1',
                },
                {
                  type: 'boolean',
                  name: 'reviewed3',
                  label: 'Approved: Didactics 2',
                },
                {
                  type: 'boolean',
                  name: 'reviewed2',
                  label: 'Approved: English',
                },
                {
                  type: 'boolean',
                  name: 'has_reorder_exercises',
                  label: 'Has Reorder Exercises',
                },
                {
                  type: 'boolean',
                  name: 'has_match_media_exercises',
                  label: 'Has Match Media Exercises',
                },
                {
                  type: 'boolean',
                  name: 'has_grammar_tip',
                  label: 'Has Tip',
                },
                {
                  type: 'boolean',
                  name: 'open_access',
                  label: 'Open Access',
                },
                {
                  type: 'boolean',
                  name: 'web_published',
                  label: 'Web Published',
                },
                {
                  type: 'number',
                  name: 'user_decks_count',
                  label: 'All',
                },
                {
                  type: 'number',
                  name: 'cards_count',
                  label: '# Cards',
                  default: '',
                },
                {
                  type: 'number',
                  name: 'tree_nodes_count',
                  label: '# Tree Nodes',
                  default: '',
                },
                {
                  type: 'text',
                  name: 'words_list',
                  placeholder: 'Enter word',
                  default: '',
                },
              ]
            }
          />
        }
        <table className='table-wrapper' style={{display: 'block', overflowX: 'auto', whiteSpace: 'nowrap'}}>
          <thead>
            <tr>
              <th width='40' />
              <th width='60' />
              <th
                className={cx('sortable', this.props.columnClassNames('name'))}
                onClick={() => this.props.onSortColumn('name')}
              >
                Name
              </th>
              <th>Publ</th>
              <th
                data-tip data-for='node-name'
              >
                <span>Tree Node</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='node-name'
                  class='custom-tooltip'
                >
                  First tree node
                </ReactTooltip>
              </th>
              <th
                className={cx('sortable', this.props.columnClassNames('first_tree_node_position'))}
                onClick={() => this.props.onSortColumn('first_tree_node_position')}
                data-tip data-for='position'
              >
                <span>Pos</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='position'
                  class='custom-tooltip'
                >
                  Position in the tree
                </ReactTooltip>
              </th>
              <th>Cards</th>
              <th>Nodes</th>
              <th>Tips</th>
              <th width='200'>Words</th>
              <th>Vid</th>
              <th>Rating</th>
              <th>Lvl</th>
              <th
                data-tip data-for='first'
              >
                <span>1st</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='first'
                  class='custom-tooltip'
                >
                  Users who had it as their first deck
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='cnv'
              >
                <span>Cnv</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='cnv'
                  class='custom-tooltip'
                >
                  Users who turned Pro after starting with this deck.
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='percent'
              >
                <span>%</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='percent'
                  class='custom-tooltip'
                >
                  Conversion percentage
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='all'
                className={cx('sortable', this.props.columnClassNames('user_decks_count'))}
                onClick={() => this.props.onSortColumn('user_decks_count')}
              >
                <span>All</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='all'
                  class='custom-tooltip'
                >
                  Users who started this deck
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='all-finished'
                className={cx('sortable', this.props.columnClassNames('percentage_finished'))}
                onClick={() => this.props.onSortColumn('percentage_finished')}
              >
                <span>%</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='all-finished'
                  class='custom-tooltip'
                >
                  % of users who finished
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='guest'
                className={cx('sortable', this.props.columnClassNames('guest_user_decks_count'))}
                onClick={() => this.props.onSortColumn('guest_user_decks_count')}
              >
                <span>Gue</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='guest'
                  class='custom-tooltip'
                >
                  Guest users
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='guest-finished'
                className={cx('sortable', this.props.columnClassNames('percentage_guest_finished'))}
                onClick={() => this.props.onSortColumn('percentage_guest_finished')}
              >
                <span>%</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='guest-finished'
                  class='custom-tooltip'
                >
                  % of guests who finished
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='registered'
                className={cx('sortable', this.props.columnClassNames('registered_user_decks_count'))}
                onClick={() => this.props.onSortColumn('registered_user_decks_count')}
              >
                <span>Reg</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='registered'
                  class='custom-tooltip'
                >
                  Registered users
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='reg-finished'
                className={cx('sortable', this.props.columnClassNames('percentage_registered_finished'))}
                onClick={() => this.props.onSortColumn('percentage_registered_finished')}
              >
                <span>%</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='reg-finished'
                  class='custom-tooltip'
                >
                  % of registered users who finished
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='pro'
                className={cx('sortable', this.props.columnClassNames('pro_user_decks_count'))}
                onClick={() => this.props.onSortColumn('pro_user_decks_count')}
              >
                <span>Pro</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='pro'
                  class='custom-tooltip'
                >
                  Pro users
                </ReactTooltip>
              </th>
              <th
                data-tip data-for='pro-finished'
                className={cx('sortable', this.props.columnClassNames('percentage_pro_finished'))}
                onClick={() => this.props.onSortColumn('percentage_pro_finished')}
              >
                <span>%</span>
                <ReactTooltip
                  place='top'
                  type='dark'
                  effect='solid'
                  id='pro-finished'
                  class='custom-tooltip'
                >
                  % of pro users who finished
                </ReactTooltip>
              </th>
              <th>Ratings</th>
              <th
                className={cx('sortable', this.props.columnClassNames('created_at'))}
                onClick={() => this.props.onSortColumn('created_at')}
              >
                Created At
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              DeckStore.indexData.map(item => {
                return (
                  <tr key={item.id} className={cx({invalid: !item.validSetup && (item.deckTypeIsTriad || item.deckType === 'trivia')})}>
                    <td>
                      {
                        !item.validSetup && (item.deckTypeIsTriad || item.deckType === 'trivia') &&
                          <i className='fa fa-warning fa-row-warning' />
                      }
                      <div className='text'>
                        <Link
                          to={{ name: 'builder.decks.edit', params: { deckId: item.id } }}
                          className='on-click'
                        >
                          <i className='fa fa-chevron-right fa-block' />
                        </Link>
                      </div>
                    </td>
                    <td>
                        <span data-tip data-for={`didactics-1-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model='words'
                            field='reviewed1'
                            value={item.reviewed1}
                            id={item.id}
                            icon='thumbs-up'
                            afterChange={this.afterChange}
                          />
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                            id={`didactics-1-${item.id}`}
                            class='custom-tooltip'
                          >
                            Approved: Didactics 1
                          </ReactTooltip>
                        </span>
                        <span data-tip data-for={`didactics-2-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model='words'
                            field='reviewed3'
                            value={item.reviewed3}
                            id={item.id}
                            icon='thumbs-up'
                            afterChange={this.afterChange}
                          />
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                            id={`didactics-2-${item.id}`}
                            class='custom-tooltip'
                          >
                            Approved: Didactics 2
                          </ReactTooltip>
                        </span>
                        <span data-tip data-for={`english-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model='words'
                            field='reviewed2'
                            value={item.reviewed2}
                            id={item.id}
                            icon='thumbs-up'
                            afterChange={this.afterChange}
                          />
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                            id={`english-${item.id}`}
                            class='custom-tooltip'
                          >
                            Approved: English
                          </ReactTooltip>
                        </span>
                    </td>
                    <td>
                      <div className='text'>
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <span data-tip data-for={`web-published-${item.id}`} style={{marginRight: 0}}>
                        <CheckIfTrue
                          value={item.webPublished}
                        />
                        <ReactTooltip
                          place='right'
                          type='dark'
                          effect='solid'
                          id={`web-published-${item.id}`}
                          class='custom-tooltip'
                        >
                          Web published
                        </ReactTooltip>
                      </span>
                      <span data-tip data-for={`mobile-published-${item.id}`} style={{marginRight: 0}}>
                        <CheckIfTrue
                          value={item.mobilePublished}
                        />
                        <ReactTooltip
                        place='right'
                        type='dark'
                        effect='solid'
                        id={`mobile-published-${item.id}`}
                        class='custom-tooltip'
                        >
                          Mobile published
                        </ReactTooltip>
                      </span>
                    </td>
                    <td>
                      <div className='text'>
                        {item.firstTreeNodeName}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.firstTreeNodePosition}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.cardsCount}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.treeNodesCount}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.grammarTipsCount}
                      </div>
                    </td>
                    <td>
                      <div className='text' style={{whiteSpace: 'normal', width: '200px'}}>
                        {item.wordsList}
                      </div>
                    </td>
                    <td>
                      <span data-tip data-for={`narrations-${item.id}`} style={{marginRight: 0}}>
                        <CheckIfTrue
                          value={item.concept?.hasAllVideoClips}
                        />
                        <ReactTooltip
                          place='left'
                          type='dark'
                          effect='solid'
                          id={`narrations-${item.id}`}
                          class='custom-tooltip'
                        >
                          {`sentences${item.concept?.hasAllVideoClips ? '' : ' do not'} all have a narration video` }
                        </ReactTooltip>
                      </span>
                      <span data-tip data-for={`supplements-${item.id}`} style={{marginRight: 0}}>
                        <CheckIfTrue
                          value={item.concept?.hasAllSupplements}
                        />
                        <ReactTooltip
                          place='left'
                          type='dark'
                          effect='solid'
                          id={`supplements-${item.id}`}
                          class='custom-tooltip'
                        >
                          {`sentences${item.concept?.hasAllVideoClips ? '' : ' do not'} all have a supplement` }
                        </ReactTooltip>
                      </span>
                    </td>
                    <td>
                      <RatingInput
                        hideTooltip
                        starWidth='12px'
                        id={item.id}
                        marginTop='0'
                        maxRating={3}
                        defaultValue={item.rating}
                        onClick={value => this.onClickRating(item, value, item.id)}
                      />
                    </td>
                    <td>
                      <div className='text'>
                        {item.level && item.level.abbreviation}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.firstDecksCount}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.convertedToPro}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.conversionPercentage}%
                      </div>
                    </td>
                    <td style={{background: 'whitesmoke'}}>
                      <div className='text'>
                        <Link
                          to={{name: 'builder.decks.user_decks.index', params: {deckId: item.id}}}
                          className='underline'
                        >
                          <b>
                            {item.userDecksCount}
                          </b>
                        </Link>
                      </div>
                    </td>
                    <td style={{background: 'whitesmoke'}}>
                      {`${item.percentageFinished}%`}
                    </td>
                    <td>
                      {item.guestUserDecksCount}
                    </td>
                    <td>
                      {`${item.percentageGuestFinished}%`}
                    </td>
                    <td style={{background: 'whitesmoke'}}>
                      {item.registeredUserDecksCount}
                    </td>
                    <td style={{background: 'whitesmoke'}}>
                      {`${item.percentageRegisteredFinished}%`}
                    </td>
                    <td>
                      {item.proUserDecksCount}
                    </td>
                    <td>
                      {`${item.percentageProFinished}%`}
                    </td>
                    <td>
                      {item.averageRating} ({item.ratingsCount})
                    </td>
                    <td>
                      {item.createdAt.formattedDateWithYear}
                    </td>
                    <td>
                      <div className='text'>
                        <DeleteButton
                          onConfirm={this.onDelete.bind(this, item.id)}
                          message='Delete this deck?'
                          className='fa-block right'
                        />
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          updateUrl
          store={DeckStore}
        />
      </div>
    );
  }
}

export default SortableColumns(DeckIndex, DeckStore, { sortColumn: 'first_tree_node_position', sortIsAscending: true });
