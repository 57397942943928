import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { Constants } from '@seedlang/constants';
import { range } from 'lodash';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`
  width: 600px;
  display: flex;
  flex-direction: column;
  h3 {
    border-bottom: 1px solid #CCC;
    padding-bottom: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  cursor: pointer;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

const Note = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-left: 20px;
`;

@observer
class AnalyticsDateModal extends React.Component {
  @observable year = this.props.year;

  @autobind incrementYear() {
    this.year += 1;
  }

  @autobind decrementYear() {
    this.year -= 1;
  }

  render() {
    return (
      <Wrapper>
        <h3>Show Sections</h3>
        {
          this.props.sections.map(item => {
            return (
              <Column
                key={item[1]}
              >
                <Row
                  onClick={() => this.props.onUpdateHiddenSections(item[1])}
                >
                  <InPlaceCheckbox
                    icon='check'
                    value={this.props.hiddenSections.indexOf(item[1]) === -1}
                  />
                  {item[0]}
                </Row>
                {
                  item[2] &&
                    <Note>
                      {item[2]}
                    </Note>
                }
              </Column>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default AnalyticsDateModal;
