import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  background: ${props => props.background || '#716f6f'};
  width: ${props => props.width || '50px'};
  height: ${props => props.height || '50px'};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  color: white;
  font-weight: bold;
  position: ${props => props.position};
  box-shadow: ${props => props.boxShadow};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  right: ${props => props.right};
  left: ${props => props.left};
  font-size: ${props => props.fontSize || '30px'};
  cursor: pointer;
  z-index: 999;
`;

@observer
class FloatingActionButton extends React.Component {

  render() {
    return (
      <Wrapper
        className='back-to-top-button'
        position={this.props.floating ? 'fixed' : 'auto'}
        boxShadow={this.props.floating ? 'rgba(0, 0, 0, 0.32) 2px 2px 4px 2px' : null}
        width={this.props.width}
        height={this.props.height}
        top={pixify(this.props.top)}
        bottom={pixify(this.props.bottom)}
        left={pixify(this.props.left)}
        right={pixify(this.props.right)}
        background={this.props.background}
        onClick={this.props.onClick}
        fontSize={this.props.fontSize}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default FloatingActionButton;
