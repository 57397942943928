import React from 'react';
import { observer } from 'mobx-react';
import { FilterDateWrapper } from '@seedlang/hoc';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import FilterComponent from 'components/backend_filter/filter_component';
import FilterButton from 'components/backend_filter/filter_button';
import FilterButtonText from 'components/backend_filter/filter_button_text';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  input {
    width: 120px;
    height: 30px;
  }
  input:first-child {
    margin-right: 5px;
  }
`;

const DateWrapper = styled.div`
  margin: 0 0 0 3px;
  display: inline-flex;
  align-items: center;
`;

@observer
class FilterDate extends React.Component {

  render() {
    return (
      <FilterComponent
        selected={this.props.filterIsActive}
      >
        <Wrapper>
          <FilterButton
            onClick={this.props.onClick}
          >
            <i className={cx('fa', 'fa-circle', {
              disabled: !this.props.filterIsActive,
            })}
            />
            <FilterButtonText
              disabled={!this.props.filterIsActive}
            >
              {this.props.label || this.props.name}
            </FilterButtonText>
          </FilterButton>
          <DateWrapper>
            <DatePicker
              selected={this.props.beginDate}
              onChange={this.props.onChangeBeginDate}
              placeholderText='Begin Date'
            />
            <DatePicker
              selected={this.props.endDate}
              onChange={this.props.onChangeEndDate}
              placeholderText='End Date'
            />
          </DateWrapper>
        </Wrapper>
      </FilterComponent>
    );
  }
}

export default FilterDateWrapper(FilterDate);
