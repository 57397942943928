import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { KeyboardIcon, MultipleChoiceIcon } from '@seedlang/icons';

const Wrapper = styled.div`
  svg {
    width: ${props => props.width};
    margin: ${props => props.margin};
    fill: #333;
  }
  .multiple-choice-icon {
      svg {
        width: 60%;
      }
    }
  }
  .keyboard-icon {
    svg {
      width: 24px;
      fill: #333;
    }
  }
`;

@observer
class MultipleChoiceToggleIcon extends React.Component {

  render() {
    return (
      <Wrapper
        width={this.props.width}
        margin={this.props.margin}
      >
        {
          this.props.textInput &&
            <MultipleChoiceIcon />
        }
        {
          !this.props.textInput &&
            <KeyboardIcon />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(MultipleChoiceToggleIcon);
