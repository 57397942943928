import React from 'react';
import propTypes from 'prop-types';
import { observer } from 'mobx-react';
import { observable, autorun, computed } from 'mobx';
import key from 'keymaster';
import { VideoClipStore , RouteStore } from '@seedlang/stores';
import Spinner from 'components/spinner';
import { each, first } from 'lodash';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { pixify, isPresent } from '@seedlang/utils';

const Wrapper = styled.div`

`;

const VideoWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 20px;
  overflow: hidden;
  background: #333;
  width: ${props => props.width};
  height: ${props => props.height};
  video {
    margin-left: 0;
  }
`;

const UnsupportedDevice = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid gray;
  padding: 5px;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const RecordingIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 20px;
  height: 20px;
  border-radius: 30px;
  background: ${Theme.red};
  z-index: ${Theme.z['foreground']};
`;

const Countdown = styled.div`
  z-index: ${Theme.z['foreground']};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 150px;
  height: 150px;
  border-radius: 150px;
  border: 10px solid white;
  text-align: center;
  color: white;
  font-size: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SourceInputs = styled.div`
  z-index: ${Theme.z['topmost-under-overlay']};
  position: absolute;
  bottom: -20px;
  input, .in-place-select {
    width: 100%;
  }
  select {
    font-size: 11px!important;
    height: 30px!important;
  }
`;

const DEFAULT_CAMERA = 'C920';
const DEFAULT_MICROPHONE = 'Audient';

@observer
class VideoRecorder extends React.Component {

  static propTypes = {
    simplified: propTypes.bool,
    onCreateVideoClip: propTypes.func,
    afterCreateVideo: propTypes.func,
    afterCreateRequestSent: propTypes.func,
    videoableType: propTypes.string,
    videoableId: propTypes.string,
    width: propTypes.number,
  }

  static defaultProps = {
    width: 480,
  }

  @observable _mounted = true;
  @observable countdown = 2;
  @observable countingDown = false;
  @observable audioSource = null;
  @observable videoSource = null;
  @observable audioSources = [];
  @observable videoSources = [];
  @observable selectedAudioSource = null;
  @observable selectedVideoSource = null;
  @observable showSettings = false;
  @observable loadedData = false;
  @observable unsupportedDevice = false;

  @computed get canRecord() {
    return this.loadedData && !this.props.mediaStore.isRecording && (!this.props.videoableType || this.props.videoableId);
  }

  componentDidMount() {
    this._mounted = true;
    if (this.props.countdownBeep) {
      this.beep = new Audio('/sounds/beep2.wav');
      this.beep.volume = 0.1;
    }
    this.props.mediaStore.setupVideo({audio: true, video: true}, this.onDevicesLoad.bind(this));
    key('space', this.onToggleRecording.bind(this));
    key('up', this.onToggleRecording.bind(this));
    key('.', this.onToggleRecording.bind(this));
    key('b', this.onToggleRecording.bind(this));
    key('escape', this.onCancelRecording.bind(this));
    key('down', this.onCancelRecording.bind(this));
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.warn('enumerateDevices() not supported.');
      return;
    }
    navigator.mediaDevices.enumerateDevices()
      .then(this.onDevicesLoad.bind(this))
      .catch(this.setDeviceAsUnsupported.bind(this));
    autorun(() => {
      this.onSelectInputSource(this.selectedAudioSource, this.selectedVideoSource);
    });
  }

  componentWillUnmount() {
    this._mounted = false;
    clearInterval(this.interval);
    key.unbind('space', this.onToggleRecording.bind(this));
    key.unbind('escape', this.onCancelRecording.bind(this));
    this.refs.video.src = null;
    this.refs.video.pause();
    if (!RouteStore.pathname().match('/creator/') && !this.props.keepVideoStream) {
      this.props.mediaStore.clearVideoStream();
    }
  }

  defaultAudioSource() {
    if (isPresent(this.audioSources)) {
      return first(this.audioSources).deviceId;
    }
  }

  defaultVideoSource() {
    if (isPresent(this.videoSources)) {
      return first(this.videoSources).deviceId;
    }
  }

  onDevicesLoad(devices) {
    each(devices, device => {
      if (device.kind === 'audioinput') {
        device.optionLabel = device.label || `microphone ${this.audioSources.length + 1}`;
        const audioSources = this.audioSources;
        audioSources.push(device);
        this.audioSources = audioSources;
      } else if (device.kind === 'videoinput') {
        device.optionLabel = device.label || `camera ${this.videoSources.length + 1}`;
        const videoSources = this.videoSources;
        videoSources.push(device);
        this.videoSources = videoSources;
      }
      this.matchDefaultDevice(device);
    });
    if (!this.selectedAudioSource) {
      this.selectedAudioSource = this.defaultAudioSource();
    }
    if (!this.selectedVideoSource) {
      this.selectedVideoSource = this.defaultVideoSource();
    }
  }

  matchDefaultDevice(device) {
    if (device.kind === 'audioinput' && device.label.match(DEFAULT_MICROPHONE)) {
      this.selectedAudioSource = device.deviceId;
    } else if (device.kind === 'videoinput' && device.label.match(DEFAULT_CAMERA)) {
      this.selectedVideoSource = device.deviceId;
    }
  }

  setDeviceAsUnsupported(err) {
    console.warn(err);
    // Sentry.captureException(err);
    this.unsupportedDevice = true;
    throw err;
  }

  resetCountdown() {
    this.countingDown = true;
    this.countdown = 2;
  }

  startRecording() {
    clearInterval(this.interval);
    this.countingDown = false;
    this.props.mediaStore.startRecording();
  }

  stopRecording() {
    clearInterval(this.interval);
    this.props.mediaStore.stopRecording();
    this.createVideoClip();
  }

  playBeep() {
    if (this.props.countdownBeep) {
      this.beep.play();
    }
  }

  countdownToRecord() {
    this.playBeep();
    this.resetCountdown();
    this.interval = setInterval(this.decrementCounter.bind(this), 1000);
  }

  decrementCounter() {
    if (this._mounted) {
      this.countdown = this.countdown - 1;
      if (this.countdown > 0) {
        this.playBeep();
      } else if (this.countdown === 0) {
        this.startRecording();
      }
    } else {
      this.onCancelRecording();
    }
  }

  bindStream(stream) {
    if (isPresent(this.refs.video)) {
      this.stream = stream;
      this.refs.video.srcObject = this.stream;
    }
  }

  createVideoClip() {
    const formData = this.props.mediaStore.getFormData();
    if (this.props.onCreateVideoClip) {
      this.props.onCreateVideoClip(formData);
    } else if (this.props.videoableId) {
      VideoClipStore.multipartCreate({
        data: formData,
        queryStrings: {
          foreign_key: this.props.foreignKey,
          videoable_type: this.props.videoableType,
          videoable_id: this.props.videoableId,
          max_volume: this.props.mediaStore.maxAudioLevel,
          overwrite_video: this.props.overwriteVideo,
        },
      }, this.props.afterCreateVideo.bind(this));
      if (this.props.afterCreateRequestSent) {
        this.props.afterCreateRequestSent();
      }
    }
  }

  onCancelRecording() {
    clearInterval(this.interval);
    this.countingDown = false;
    this.props.mediaStore.stopRecording();
  }

  getUserMedia() {
    this.props.mediaStore.setupVideo({
      audioSource: this.audioSource,
      videoSource: this.videoSource,
    }, this.bindStream.bind(this));
  }

  onSelectInputSource(audioSource, videoSource) {
    if (videoSource && (this.audioSource !== audioSource || this.videoSource !== videoSource)) {
      this.audioSource = audioSource;
      this.videoSource = videoSource;
      this.getUserMedia();
    }
  }

  @autobind onToggleRecording(e) {
    e.preventDefault();
    if (this._mounted && !this.countingDown) {
      if (this.props.mediaStore.isRecording) {
        this.stopRecording();
      } else if (this.canRecord) {
        this.countdownToRecord();
      }
    }
  }

  @autobind onLoadedData() {
    this.loadedData = true;
  }

  render() {
    return (
      <Wrapper>
        <VideoWrapper
          width={pixify(this.props.width)}
          height={pixify(this.props.width)}
        >
          {
            !this.loadedData && !this.unsupportedDevice &&
              <Spinner />
          }
          {
            this.unsupportedDevice &&
              <UnsupportedDevice>
                Sorry, but video recording is not supported in this browser.
              </UnsupportedDevice>
          }
          {
            this.props.mediaStore.isRecording &&
              <RecordingIcon />
          }
          {
            this.countingDown &&
              <Countdown>
                {this.countdown}
              </Countdown>
          }
          <video
            muted
            autoPlay
            playsInline
            width={this.props.width}
            height={this.props.width}
            onLoadedData={this.onLoadedData}
            ref='video'
          />
        </VideoWrapper>
        {
          !this.props.simplified &&
            <SourceInputs className='row'>
              <div className='col-xs-1' />
              <div className='col-xs-5' style={{marginRight: '10px'}}>
                <select
                  onChange={() => this.selectedVideoSource = this.refs.videoDeviceSelect.value}
                  ref='videoDeviceSelect'
                  value={this.selectedVideoSource || this.defaultVideoSource()}
                >
                  {
                    this.videoSources.map(item => {
                      return (
                        <option
                          value={item.deviceId}
                          key={item.deviceId}
                        >
                          {item.optionLabel}
                        </option>
                      );
                    })
                  }
                </select>
              </div>
              <div className='col-xs-5'>
                <select
                  onChange={() => this.selectedAudioSource = this.refs.audioDeviceSelect.value}
                  ref='audioDeviceSelect'
                  value={this.selectedAudioSource || this.defaultAudioSource()}
                >
                  {
                    this.audioSources.map(item => {
                      return (
                        <option
                          value={item.deviceId}
                          key={item.deviceId}
                        >
                          {item.optionLabel}
                        </option>
                      );
                    })
                  }
                </select>
              </div>
              <div className='col-xs-1' />
            </SourceInputs>
        }
      </Wrapper>
    );
  }
}

export default VideoRecorder;
