import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { AppUI, PrivacySettingsState, PrivacySettingsUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils/src';

const PrivacyNotice = styled.div`
  width: ${props => pixify(props.width)};
  height: ${props => pixify(props.height)};
  padding: 1em;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
  text-wrap: balance;
  display: grid;
  place-content: center;
  position: relative;
  .background {
    position: absolute;
    inset: 0;
    z-index: 1;
    background-color: #DDD;
    ${({ placeholderImageUrl }) => placeholderImageUrl && `
      background-image: url('${placeholderImageUrl}');
      background-size: cover;
      filter: blur(5px);
    `}
  }
  .notice-text {
    z-index: 2;
    padding: 1em;
    background: rgba(255,255,255,0.9);
    border-radius: 0.5em;
    .title {
      font-size: 120%;
      font-weight: bold;
    }
  }
`;

const PrivacyButton = styled.div`
  display: inline-block;
  background: ${props => props.background};
  color: ${props => props.color};
  width: ${props => props.width};
  margin: ${props => props.margin};
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px;
`;

@observer
class YoutubeVideo extends React.Component {

  render() {
    if (PrivacySettingsState.isYoutubeAllowed) {
      return (
        <div className='youtube-video'>
          <iframe
            src={this.props.url}
            width={AppUI.layout.isMobile ? 360 : 540}
            height={AppUI.layout.isMobile ? 205 : 300}
            allowFullScreen
            frameBorder='0'
            title='Video'
          />
        </div>
      );
    }
    else {
      return (
        <PrivacyNotice
          className='youtube-video'
          width={AppUI.layout.isMobile ? 360 : 540}
          height={AppUI.layout.isMobile ? 'fit-content' : 300}
          isMobile={AppUI.layout.isMobile}
          placeholderImageUrl={this.props.placeholderImageUrl}
        >
          <div className='background'></div>
          <div className='notice-text'>
            <p className='title'>Opt in to watch YouTube videos</p>
            <p>To respect your privacy, we do not load YouTube videos automatically. YouTube may collect personal data and track your activity if videos are played directly from our site.</p>
            <p>If you would like to watch the video, please click the button below to opt-in and enable the YouTube content.</p>
            <PrivacyButton
              background='#0584a1'
              color='#FFF'
              onClick={PrivacySettingsUI.showModal}
              width={AppUI.layout.isMobile ? '100%' : '200px'}
              margin={AppUI.layout.isMobile ? '5px 0 0 0' : '0 0 0 5px'}
            >
              Edit Privacy Preferences
            </PrivacyButton>
          </div>
        </PrivacyNotice>
      );
    }
  }
}

export default ErrorBoundary(YoutubeVideo);
