import React from 'react';
import { observer } from 'mobx-react';
import { MembershipTypeStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import Cookies from 'js-cookie';
import Alert from 'components/alert';
import { observable } from 'mobx';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';

@observer
class MembershipTypeCreate extends React.Component {
  @observable showMailingMessage = false;

  onClick() {
    if (isPresent(this.refs.name.value)) {
      MembershipTypeStore.create(
        {
          data: {
            name: this.refs.name.value,
            site_id: Cookies.get('site_id'),
          },
        },
        this.afterCreateMembershipType,
      );
      this.refs.name.value = '';
    }
  }

  @autobind afterCreateMembershipType() {
    MembershipTypeStore.getIndex();
    this.showMailingMessage = true;
  }

  render() {
    return (
      <div className='membership-type-create row'>
        {
          this.showMailingMessage &&
            <Alert width='100%'>
              {
                AppUI.routeStore?.router?.params?.groupId &&
                <Link
                  target='_blank'
                  to={{
                    name: 'creator.campaign_types.index',
                    params: {groupId: AppUI.routeStore?.router?.params?.groupId},
                  }}
                >
                  Don't forget to create a new "Registration" mailing campaign for people who sign up to this new membership type.
                </Link>
              }
              {!AppUI.routeStore?.router?.params?.groupId && 'Don\'t forget to create a new "Registration" mailing campaign for people who sign up to this new membership type.'}
            </Alert>
        }
        <div className='col-xs-10'>
          <input
            placeholder='Add a name'
            ref='name'
          />
        </div>
        <div className='col-xs-2'>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default MembershipTypeCreate;
