import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { HeaderWrapper } from '@seedlang/hoc';
import { Link } from 'react-router';
import Dropdown from 'components/dropdown';
import { CloseIcon, CrownIcon, MenuIcon } from '@seedlang/icons';
import { AppUI } from '@seedlang/state';
import UserProfileImage from 'components/user/user_profile_image';
import WhitelabelLogo from 'components/whitelabel_logo';
import styled from '@emotion/styled';
import GoProButton from 'components/button/go_pro_button';
import { Theme } from '@seedlang/constants';
import HeaderButton from 'components/button/header_button';
import NavigationItemLinks from 'components/layout/desktop/navigation_item_links';
import { flexCenter } from '@seedlang/style_mixins';
import autobind from 'autobind-decorator';
import NotificationsWithPopup from 'components/notification/notifications_with_popup';
import { isPresent, pixify } from '@seedlang/utils';

const AgreementWrapper = styled.div`
  width: 100%;
`;

const Wrapper = styled.header`
  display: flex;
  z-index: 999;
  width: 100%;
  height: ${pixify(AppUI.layout.desktopHeaderHeight)};
  min-height: ${pixify(AppUI.layout.desktopHeaderHeight)};
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: ${props => props.background};
  position: ${props => props.position};
  border-bottom: ${props => props.borderBottom};
  top: 0;
  .on-click {
    cursor: pointer;
  }
  .on-click:hover {
    color: #FFF;
  }
`;

const MembershipBanner = styled.div`
  background: ${props => props.background};
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 16px;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
  }
  .icon-wrapper {
    margin-right: 5px;
  }
`;

const InnerWrapper = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1180px;
  position: relative;
`;

const LogoWrapper = styled.div`
  width: 190px;
  cursor: pointer;
`;

const LinkWrapper = styled.div`
  display: ${props => props.display};
  padding-bottom: 3px;
  margin-left: 15px;
  font-size: 17px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: ${props => props.borderColor};
  &:hover {
    color: #000!important;
  }
`;

const NavigationButtons = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MobileNavigation = styled.div`
  position: absolute;
  width: 100%;
  background: #FFF;
  top: ${props => props.top};
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 20px 30px 20px 0;
  z-index: 99;
  border-bottom: 1px solid #b7b7b7;
`;

const MobileIconWrapper = styled.div`
  display: flex;
  width: 50px;
  margin-left: 10px;
  .menu-icon, .close-icon {
    cursor: pointer;
    svg {
      fill: #000;
      width: 25px;
    }
  }
`;

const Seedlang = styled.div`
  ${flexCenter()}
  margin-top: 10px;
  img {
    width: 23px;
    margin-right: 5px;
  }
`;

const Hr = styled.div`
  width: 140px;
  border-bottom: 1px solid #b7b7b7;
  height: 5px;
  margin: 10px 0;
`;

@observer
class Header extends React.Component {
  @observable showMobileNavigation = false;

  static defaultProps = {
    background: Theme.siteHeaderColor,
  }

  @autobind signOutAndHideNavigation() {
    this.showMobileNavigation = false;
    AppUI.signOut(AppUI.siteIsDefault);
  }

  render() {
    return (
      <AgreementWrapper>
        {
          this.props.showWhiteLabelMembershipButton && AppUI.layout.isMobile &&
            <MembershipBanner
              background={AppUI.site.accentColor || '#1f4d6b'}
            >
              <Link
                to={{name: 'memberships.index'}}
              >
                <CrownIcon />
                <span>Become a Member</span>
              </Link>
            </MembershipBanner>
        }
        <Wrapper
          background={this.props.background}
          position={this.props.position}
        >
          {
            this.showMobileNavigation && this.props.navigationItems &&
              <MobileNavigation
                top={this.props.showWhiteLabelMembershipButton ? '90px' : '70px'}
              >
                {
                  !AppUI.userIsSignedInAndNotGuest &&
                    <Link
                      to={{name: 'onboarding.sign_in'}}
                    >
                      <LinkWrapper
                        borderColor='#FFF'
                        className='on-click'
                      >
                        Sign In
                      </LinkWrapper>
                    </Link>
                }
                {
                  !AppUI.userIsSignedInAndNotGuest &&
                    <Hr />
                }
                <NavigationItemLinks
                  {...this.props}
                  headerLink
                  flexDirection='column'
                  alignItems='flex-end'
                  afterClick={() => this.showMobileNavigation = false}
                />
                {
                  AppUI.userIsSignedInAndNotGuest &&
                    <Hr />
                }
                {
                  AppUI.userIsSignedInAndNotGuest &&
                    <LinkWrapper
                      borderColor={AppUI.routeStore.routeName.startsWith('settings') ? '#b7b7b7' : 'transparent'}
                    >
                      <Link
                        to='settings'
                        onClick={() => this.showMobileNavigation = false}
                      >
                        Settings
                      </Link>
                    </LinkWrapper>
                }
                {
                  AppUI.userIsSignedInAndNotGuest &&
                    <LinkWrapper
                      borderColor='#FFF'
                      onClick={this.signOutAndHideNavigation}
                      className='on-click'
                    >
                      Sign Out
                    </LinkWrapper>
                }
              </MobileNavigation>
            }
          <InnerWrapper>
            {
              this.props.logo &&
                <LogoWrapper>
                  <Link
                    to={{ name: this.props.whiteLabelHomeRoute }}
                  >
                    <WhitelabelLogo
                      image={this.props.logo}
                    />
                  </Link>
                </LogoWrapper>
            }
            <NavigationButtons>
              {
                this.props.showWhiteLabelNavigationItems &&
                  <NavigationItemLinks
                    {...this.props}
                    headerLink
                    matchRoute={this.matchRoute}
                    flexDirection='row'
                  />
              }
              {
                this.props.showWhiteLabelMembershipButton && !AppUI.layout.isMobile &&
                  <Link
                    to={{name: 'memberships.index'}}
                  >
                    <GoProButton
                      height='47px'
                      background={AppUI.user?.site?.accentColor}
                      disabled={isPresent(AppUI.routeStore.routeName) && AppUI.routeStore.routeName === 'memberships.index'}
                    >
                      <div className='text'>
                        <span>Become a Member</span>
                      </div>
                    </GoProButton>
                  </Link>
              }
              {
                !AppUI.whiteLabelMobileView && (!AppUI.userIsSignedIn || AppUI.user.guest) &&
                  <Link
                    to={{name: 'onboarding.sign_in'}}
                  >
                    <HeaderButton
                      height='47px'
                      borderRadius='47px'
                      fontWeight='bold'
                      background={AppUI.user?.site?.accentColor}
                    >
                      Sign In
                    </HeaderButton>
                  </Link>
              }
              {
                AppUI.whiteLabelMobileView &&
                  <MobileIconWrapper
                    onClick={() => this.showMobileNavigation = !this.showMobileNavigation}
                  >
                    {
                      !this.showMobileNavigation &&
                        <MenuIcon />
                    }
                    {
                      this.showMobileNavigation &&
                        <CloseIcon />
                    }
                  </MobileIconWrapper>
              }
              {
                !AppUI.whiteLabelGuest && AppUI.userIsSignedIn && !AppUI.whiteLabelMobileView &&
                  <NotificationsWithPopup
                    background='#efefef'
                  />
              }
              {
                !AppUI.whiteLabelGuest && !this.props.simplifiedHeader && AppUI.userIsSignedIn && !AppUI.whiteLabelMobileView &&
                  <Dropdown
                    className='user-profile user-image'
                    top='60px'
                    right={0}
                    backgroundOnHover='gray'
                  >
                    <UserProfileImage
                      hideProStatus
                      hideTooltip
                      margin='0 0 0 10px'
                      width={42}
                      user={AppUI.user}
                    />
                    <ul>
                      <li>
                        <Link
                          to='settings.user_profile'
                        >
                          Settings
                        </Link>
                      </li>
                      {
                        AppUI.user.currentUserGroup?.admin &&
                            <li>
                              <a href={`/creator/groups/${AppUI.user.currentUserGroup.group?.id}/posts`}>
                                Admin
                              </a>
                            </li>
                      }
                      <li
                        onClick={() => AppUI.signOut(AppUI.siteIsDefault)}
                        className='on-click'
                      >
                        Sign Out
                      </li>
                    </ul>
                  </Dropdown>
              }
            </NavigationButtons>
          </InnerWrapper>
        </Wrapper>
      </AgreementWrapper>
    );
  }
}

export default HeaderWrapper(Header);
