import React from 'react';
import { observer } from 'mobx-react';
import { DeckRulebookStore } from '@seedlang/stores';
import DeckRulebookCreate from 'pages/builder/deck_rulebooks/deck_rulebook_create';
import CheckIfTrue from 'components/check_if_true';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';

@observer
class DeckRulebookIndex extends React.Component {

  componentDidMount() {
    DeckRulebookStore.getIndex();
  }

  @autobind onDelete(id) {
    DeckRulebookStore.destroy({ids: {deckRulebookId: id}});
  }

  render() {
    return (
      <div className='deck-rulebook-index'>
        <DeckRulebookCreate />
        <table style={{marginTop: '10px'}} className='table-wrapper'>
          <thead>
            <tr>
              <th width='20' />
              <th>Name</th>
              <th>Slug</th>
              <th>Default</th>
              <th>Locked</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              DeckRulebookStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.deck_rulebooks.edit', params: { deckRulebookId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      {item.name}
                    </td>
                    <td>
                      {item.slug}
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.default}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.locked}
                      />
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this rulebook?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={DeckRulebookStore}
        />
      </div>
    );
  }
}

export default DeckRulebookIndex;
