import { without } from 'lodash';

export default function CommaSeparated(initialString) {
  let list = initialString === '' ? [] : initialString.split(',');

  this.remove = item => list = without(list, item);
  this.add = item => list.push(item);
  this.contains = item => list.indexOf(item) !== -1;
  this.toggle = item => this.contains(item) ? this.remove(item) : this.add(item);
  this.toString = () => list.join(',');
}
