import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';
import { computed } from 'mobx';

const PathWarningWrapper = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  margin: 5px 0;
`;

class NavigationItemPathWarning extends React.Component {
  componentDidMount() {
    AppUI.pageStore.getIndex();
  }

  @computed get isValidPagePath() {
    return AppUI.pageStore.indexData.map(item => `/pages/${item.slug}`).includes(this.props.path);
  }

  @computed get isValidExternalLink() {
    const urlRegex = /^https:\/\/[^\s/$.?#].[^\s]*$/i;
    return this.props.path?.match(urlRegex);
  }

  render() {
    if (this.isValidExternalLink || this.isValidPagePath) {
      return null;
    }

    return (
      <PathWarningWrapper>
        <i className='fa fa-warning' />
        Path must be an external link or a path to a
        <Link className='underline' to={{name: 'creator.groups.site_pages', params: {groupId: this.props.groupId}}}>
          custom page
        </Link>
      </PathWarningWrapper>
    );
  }
}

export default NavigationItemPathWarning;