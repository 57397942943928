import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import PodcastCreate from 'pages/creator/podcasts/podcast_create';
import { PodcastStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';

const Wrapper = styled.div`
  table {
    margin-top: 20px;
  }
`;

@observer
class PodcastIndex extends React.Component {

  constructor(props) {
    super(props);
    this.getPodcasts();
  }

  @autobind onDelete(id) {
    PodcastStore.destroy({ids: {podcastId: id}}, this.getPodcasts);
  }

  @autobind getPodcasts() {
    PodcastStore.getIndex();
  }

  render() {
    return (
      <Wrapper>
        <PodcastCreate
          afterCreate={this.getPodcasts}
        />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th width='80'>Pos</th>
              <th width='300'>Title</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              PodcastStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        onlyActiveOnIndex
                        to={{ name: 'creator.podcasts.edit', params: { podcastId: item.id, groupId: this.props.params.groupId } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <InPlaceText
                        id={item.id}
                        defaultValue={item.position}
                        model='podcasts'
                        field='position'
                        type='number'
                        step='1'
                        afterChange={this.getPodcasts}
                      />
                    </td>
                    <td style={{minWidth: '200px'}}>
                      <div className='text'>
                        <InPlaceText
                          field='title'
                          defaultValue={item.title}
                          model='podcasts'
                          id={item.id}
                        />
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this grid item?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={PodcastStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PodcastIndex);
