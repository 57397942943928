import React from 'react';
import { observer } from 'mobx-react';
import { PaymentStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';

@observer
class PaymentCreate extends React.Component {

  onClick() {
    if (isPresent(this.refs.transactionId.value)) {
      PaymentStore.create(
        {
          data: {
            subscriptionId: this.props.subscriptionId,
            transactionId: this.refs.transactionId.value,
          },
        },
        this.props.afterUpdatePayment,
      );
      this.refs.transactionId.value = '';
    }
  }

  render() {
    return (
      <div className='payment-create row'>
        <div className='col-xs-5'>
          <input
            placeholder='Transaction ID'
            ref='transactionId'
          />
        </div>
        <div className='col-xs-2'>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default PaymentCreate;
