import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { isBlank } from '@seedlang/utils';
import cx from 'classnames';
import autobind from 'autobind-decorator';
import { NotificationStore } from '@seedlang/stores';
import styled from '@emotion/styled';
import Button from 'components/button/button';

const Wrapper = styled.div`

`;

const FormElement = styled.div`
  margin: 10px 0;
  label {
    display: inline-block;
    margin-left: 5px;
  }
`;

@observer
class SendNotification extends React.Component {
  @observable message = '';
  @observable deviceTokens = '';
  @observable title = '';
  @observable linkText = '';
  @observable linkUrl = '';
  @observable testNotification = true;
  @observable onlySendToPros = false;
  @observable showMessage = false;
  @observable notificationType = 'mobile';

  @computed get disabled() {
    if (this.notificationType === 'mobile') {
      return isBlank(this.message) || isBlank(this.title) || isBlank(this.deviceTokens);
    } else {
      return isBlank(this.message);
    }
  }

  @autobind onSend() {
    if (this.notificationType === 'mobile') {
      NotificationStore.sendMobileNotifications({data: {
        message: this.message,
        title: this.title,
        deviceTokens: this.deviceTokens,
      }}, this.afterMailingSend);
    } else {
      NotificationStore.createForAllUsers({data: {
        message: this.message,
        link_text: this.linkText,
        link_url: this.linkUrl,
        test_notification: this.testNotification,
        only_send_to_pros: this.onlySendToPros,
      }}, this.afterMailingSend);
    }
  }

  @autobind afterMailingSend() {
    this.message = '';
    this.linkText = '';
    this.linkUrl = '';
    this.title = '';
    this.deviceTokens = '';
    this.testNotification = true;
    this.showMessage = true;
    this.onlySendToPros = false;
  }

  render() {
    return (
      <Wrapper>
        <h3
          style={{margin: '0 0 20px 0'}}
        >
          Send Notifications
        </h3>
        <FormElement>
          <div
            className={cx('button-primary', {
              disabled: this.notificationType === 'mobile',
            })}
            style={{marginRight: 5}}
            onClick={() => this.notificationType = 'mobile'}
          >
            Mobile
          </div>
          <div
            className={cx('button-primary', {
              disabled: this.notificationType === 'internal',
            })}
            onClick={() => this.notificationType = 'internal'}
          >
            Internal
          </div>
        </FormElement>
        {
          this.showMessage &&
            <div className='alert'>
              This notification has been sent.
            </div>
        }
        {
          this.notificationType === 'mobile' &&
            <div>
              <FormElement>
                <input
                  type='text'
                  ref='title'
                  placeholder='Title'
                  value={this.title}
                  onChange={() => this.title = this.refs.title.value}
                />
              </FormElement>
            </div>
        }
        <FormElement>
          <textarea
            ref='message'
            placeholder='Message'
            value={this.message}
            onChange={() => this.message = this.refs.message.value}
          />
        </FormElement>
        {
          this.notificationType === 'mobile' &&
            <div>
              <FormElement>
                <input
                  type='text'
                  ref='deviceTokens'
                  placeholder='Tokens'
                  value={this.deviceTokens}
                  onChange={() => this.deviceTokens = this.refs.deviceTokens.value}
                />
                <div
                  style={{color: '#333', fontSize: 10, marginTop: 2, marginLeft: 5}}
                >
                  Add multiple deviceTokens like: A,B,C
                </div>
              </FormElement>
            </div>
        }
        {
          this.notificationType === 'internal' &&
            <div>
              <FormElement>
                <input
                  type='text'
                  ref='linkText'
                  placeholder='Link Text'
                  value={this.linkText}
                  onChange={() => this.linkText = this.refs.linkText.value}
                />
              </FormElement>
              <FormElement>
                <input
                  type='text'
                  ref='linkUrl'
                  placeholder='Link Url'
                  value={this.linkUrl}
                  onChange={() => this.linkUrl = this.refs.linkUrl.value}
                />
              </FormElement>
              <FormElement>
                <input
                  id='test'
                  type='checkbox'
                  ref='onlySendToPros'
                  checked={this.onlySendToPros}
                  onChange={() => this.onlySendToPros = !this.onlySendToPros}
                />
                <label
                  htmlFor='test'
                >
                  Only Send to Pros
                </label>
              </FormElement>
              <FormElement>
                <input
                  id='test'
                  type='checkbox'
                  ref='testNotification'
                  checked={this.testNotification}
                  onChange={() => this.testNotification = !this.refs.testNotification.value}
                />
                <label
                  htmlFor='test'
                >
                  Send as Test
                </label>
              </FormElement>
            </div>
        }
        <FormElement>
          <Button
            disabled={this.disabled}
            onClick={this.onSend}
          >
            Submit
          </Button>
        </FormElement>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SendNotification);
