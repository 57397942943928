import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { GroupStore, UserGroupStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { formatNumber } from '@seedlang/utils';
import UserProfileImage from 'components/user/user_profile_image';
import Button from 'components/button/button';
import Paginator from 'components/paginator';
import DailyActivity from 'components/daily_activity/daily_activity';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
`;

const UserCards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
`;

const Count = styled.div`
  margin: 0 0 10px 0;
`;

const Value = styled.div`
  font-size: 14px;
  padding: 5px;
`;

const ColumnWrapper = styled.div`
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const UserCard = styled.div`
  background: #FFF;
  padding: 20px;
  margin: 0 20px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  border-radius: 10px;
`;

const CalendarWrapper = styled.div`
  position: relative;
`;

const Label = styled.div`
  background: #CCC;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-size: 12px;

`;

@observer
class ClassroomStudents extends React.Component {
  @observable showCalendarUser = null;

  constructor(props) {
    super(props);
    this.loadGroup();
  }

  @autobind loadGroup() {
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}});
    UserGroupStore.getIndex({ids: {groupId: this.props.params.groupId}});
  }

  render() {
    return (
      <Wrapper>
        <Title>
          {GroupStore.showData?.name}
        </Title>
        <Count>
          {GroupStore.showData?.userGroupsCount} Students
        </Count>
        <UserCards>
          {
            UserGroupStore.indexData.map(item => {
              return (
                <UserCard
                  key={item.id}
                >
                  <UserProfileImage
                    user={item.user}
                    width='100px'
                    margin='0 0 10px 0'
                  />
                  <Label>
                    Name
                  </Label>
                  <Value>
                    {item.user.name}
                  </Value>
                  <Label>
                    Email
                  </Label>
                  <Value>
                    {item.user.email}
                  </Value>
                  <Label>
                    Last Active
                  </Label>
                  <Value>
                     {item.user.lastActiveAt?.formattedDateWithYear || '-'}
                  </Value>
                  <Label>
                    XP
                  </Label>
                  <Value>
                    {formatNumber(item.user.xp)}
                  </Value>
                  <Label>
                    Daily XP Goal
                  </Label>
                  <Value>
                    {formatNumber(item.user.dailyXpGoal)}
                  </Value>
                  <ColumnWrapper>
                    {
                      (!this.showCalendarUser || (this.showCalendarUser.id !== item.user.id)) &&
                        <Button
                          margin='10px 0 0 0'
                          onClick={() => this.showCalendarUser = item.user}
                        >
                          Show Activity
                        </Button>
                    }
                    {
                      this.showCalendarUser && this.showCalendarUser.id === item.user.id &&
                        <CalendarWrapper>
                          <DailyActivity
                            store={AppUI.dailyXpCountStore}
                            dailyXpGoal={this.showCalendarUser?.dailyXpGoal}
                            userId={this.showCalendarUser?.id}
                            todaysDate={this.showCalendarUser?.todaysDate}
                          />
                        </CalendarWrapper>
                    }
                  </ColumnWrapper>
                </UserCard>
              );
            })
          }
        </UserCards>
        <Paginator
          store={UserGroupStore}
          ids={{groupId: this.props.params.groupId}}
          margin='20px 0'
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ClassroomStudents);
