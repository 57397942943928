import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed, toJS } from 'mobx';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { AppUI } from '@seedlang/state';
import Spinner from 'components/spinner';
import { Constants, Languages, Theme } from '@seedlang/constants';
import { LanguageIcon } from '@seedlang/icons';
import { isPresent } from '@seedlang/utils';
import SubscriptionIndex from 'pages/builder/subscriptions/subscription_index';
import moment from 'moment';
import { keys } from 'lodash';

const COLORS = [Theme.blue, Theme.purple, Theme.orange, Theme.red];

const Header = styled.div`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const Date = styled.div`
  flex: 1;
`;

const Wrapper = styled.div`

`;

const Content = styled.div`

`;

const LanguageWrapper = styled.div`

`;

const CountWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Count = styled.div`
  background: ${props => props.background};
  color: #FFF;
  padding: 2px 5px;
  border-radius: 5px;
  margin: 0 5px 5px 0;
  font-size: 14px;
  text-transform: capitalize;
`;

const LanguageTitle = styled.div`
  background: #CCC;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
  margin-bottom: 5px;
  font-weight: bold;
`;

const LanguageButton = styled.div`
  height: 40px;
  width: 60px;
  border-radius:  5px;
  background: ${props => props.background};
  border: 1px solid #CCC;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 16px;
`;

const LanguagesWrapper = styled.div`
  display: flex;
  align-items: center;
`;

@observer
class AnalyticsDayAnalysis extends React.Component {
  @observable showSpinner = true;
  @observable languageId = AppUI.getCookieValue('modal-language-id') || this.props.activeLanguageIds[0];
  @observable report = {};

  @computed get referrerOptionCounts() {
    if (!isPresent(this.report)) { return; }
    const hsh = this.report.find(item => item.languageId === this.languageId);
    if (isPresent(hsh)) {
      return hsh['referrerOptionCounts'];
    }
  }

  @computed get subscriptionPaymentTypeCounts() {
    let counts = AppUI.subscriptionStore.indexData.filter(item => item.targetLanguageId === this.languageId)
      .reduce((acc, transaction) => {
        const type = transaction.paymentMethod;
        acc[type] = (acc[type] || 0) + 1;
        return acc;
    }, {});
    counts = Object.entries(counts)
      .sort((a, b) => a[1] <= b[1] ? -1 : 1)
      .reduce((acc, pair) => {
        acc[pair[0]] = pair[1];
        return acc;
      }, {});
    return counts;
  }

  @computed get from() {
    return moment([this.props.year, parseInt(this.props.month, 10) - 1, this.props.day]).toISOString();
  }

  @computed get to() {
    return moment([this.props.year, parseInt(this.props.month, 10) - 1, parseInt(this.props.day, 10) + 1]).toISOString();
  }

  constructor(props) {
    super(props);
    AppUI.analyticStore.report({data: {day: this.props.day, month: this.props.month, year: this.props.year}}, this.afterGetDailyReportAnalysis);
  }

  @autobind afterGetDailyReportAnalysis(resp) {
    this.showSpinner = false;
    this.report = resp;
  }

  @autobind counts(type, languageId) {
    const obj = this.props.counts.find(item => item.languageId === languageId && item.date === this.props.dateString);
    return obj && isPresent(obj[type]) ? obj[type] : 0;
  }

  @autobind onSetLanguageId(languageId) {
    this.languageId = languageId;
    AppUI.setCookieValue('modal-language-id', languageId);
  }

  render() {
    return (
      <Wrapper>
        <Header>
          <Date>
            {Constants.MONTHS[this.props.month - 1]} {this.props.day}, {this.props.year}
          </Date>
          {!this.props.singleLanguage && (
            <LanguagesWrapper>
              {
                this.props.languageIds.map(languageId => {
                  return (
                    <LanguageButton
                      background={this.languageId === languageId ? '#FFF' : '#CCC'}
                      key={languageId}
                      onClick={() => this.onSetLanguageId(languageId)}
                    >
                      <LanguageIcon
                        margin='0 5px 0 0'
                        languageId={languageId}
                        width='20px'
                        height='20px'
                      />
                      {languageId.replace('2', '')}
                    </LanguageButton>
                  );
                })
              }
            </LanguagesWrapper>
          )}
        </Header>
        {
          this.showSpinner &&
            <Spinner />
        }
        {
          !this.showSpinner &&
            <Content>
              <LanguageWrapper
                key={this.languageId}
              >
                {
                  !this.props.whiteLabel &&
                    <div>
                      <h4>{this.counts('newUsersCount', this.languageId)} New Users</h4>
                      {
                        isPresent(this.referrerOptionCounts) && this.counts('newUsers', this.languageId) > 0 &&
                          <CountWrapper>
                            {
                              this.referrerOptionCounts.map((item, index) => {
                                return (
                                  <Count
                                    background={COLORS[index % 4]}
                                    key={item['reference']}
                                  >
                                    {Math.floor(item['count'] / this.counts('newUsers', this.languageId) * 100)}% {item['reference']}
                                  </Count>
                                );
                              })
                            }
                          </CountWrapper>
                      }
                    </div>
                }
                <h4>{this.counts('newSubscriptionsCount', this.languageId)} New Subscriptions</h4>
                {
                  isPresent(this.subscriptionPaymentTypeCounts) &&
                    <CountWrapper>
                      {
                        keys(this.subscriptionPaymentTypeCounts).reverse().map((item, index) => {
                          return (
                            <Count
                              background={COLORS[index]}
                              key={item}
                            >
                              {Math.floor(this.subscriptionPaymentTypeCounts[item] / AppUI.subscriptionStore.indexData.length * 100)}% {item}
                            </Count>
                          );
                        })
                      }
                    </CountWrapper>
                }
                <SubscriptionIndex
                  from={this.from}
                  to={this.to}
                  targetLanguageId={this.props.whiteLabel ? null : this.languageId}
                  hideFilters
                />
              </LanguageWrapper>
            </Content>
        }
      </Wrapper>
    );
  }
}

export default AnalyticsDayAnalysis;
