import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PropTypes from 'prop-types';
import { Api, VideoClipStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { UrlBuilder } from '@seedlang/models';
import { isBlank, isPresent } from '@seedlang/utils';
import Spinner from 'components/spinner';
import { last, noop } from 'lodash';
import VideoPlayersWithType from 'pages/builder/video_clips/video_players_with_type';
import DeleteButton from 'components/button/delete_button';

@observer
class InPlaceMediaUpload extends React.Component {
  @observable showSpinner = false;

  static propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    querystring: PropTypes.object,
  }

  @autobind onMultipartPost() {
    this.showSpinner = true;
    const extension = last(this.refs.input.files[0]?.name.split('.'));
    const isVideoClip = extension === 'mp4' || extension === '.mov';
    const model = isVideoClip ? 'video_clips' : 'images';
    const queryStrings = {};
    if (isVideoClip) {
      queryStrings.videoable_id = this.props.mediaId;
      queryStrings.videoable_type = this.props.mediaType;
    } else {
      queryStrings.imageable_id = this.props.mediaId;
      queryStrings.imageable_type = this.props.mediaType;
    }
    Api.multipartPost({
      url: new UrlBuilder().build(model, queryStrings),
      data: {data: this.data()},
      successCallback: this.onChange,
    });
  }

  @autobind onDeleteVideo() {
    if (this.props.onDeleteVideo) {
      this.props.onDeleteVideo(this.props.videoClip.id);
    } else {
      VideoClipStore.destroy({ids: {videoClipId: this.props.videoClip.id}}, this.props.afterChange || noop);
    }
  }

  @autobind onDeleteImage() {
    this.showSpinner = true;
    Api.destroy({
      url: `/api/images/${this.props.image.id}`,
      successCallback: this.onChange,
    });
  }

  data() {
    const data = new FormData();
    data.append('blob', this.refs.input.files[0]);
    return data;
  }

  @autobind onChange() {
    this.showSpinner = false;
    this.props.onChange();
  }

  render() {
    return (
      <div className='in-place-media-upload'>
        {
          isBlank(this.props.videoClip) && (isBlank(this.props.image) || isBlank(this.props.image.url)) &&
            <input
              type='file'
              ref='input'
              onChange={this.onMultipartPost}
            />
        }
        {
          this.props.videoClip &&
            <div
              className='video-wrapper'
              style={{
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <div
                className='delete-button-wrapper'
                style={{
                  position: 'absolute',
                  top: -5,
                  right: 5,
                  zIndex: 3,
                }}
              >
                <DeleteButton
                  onConfirm={this.onDeleteVideo}
                  message='Delete this video?'
                >
                  <i className='fa fa-times fa-on-click delete-btn' />
                </DeleteButton>
              </div>
              <VideoPlayersWithType
                videoClip={this.props.videoClip}
                width='150px'
              />
            </div>
        }
        {
          isPresent(this.props.image) && isPresent(this.props.image.url) &&
            <div
              className='image-wrapper'
              style={{
                marginTop: '10px',
                position: 'relative',
                display: 'inline-block',
              }}
            >
              <i
                className='fa fa-times fa-on-click delete-btn'
                onClick={() => this.onDeleteImage()}
              />
              <img
                src={this.props.image.url}
                style={{marginTop: '10px'}}
                alt=''
              />
            </div>
        }
        { this.showSpinner && <Spinner className='blue' />}
      </div>
    );
  }
}

export default InPlaceMediaUpload;
