import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import WordCardRow from 'components/word_card/word_card_row';
import WordCardTitle from 'components/word_card/word_card_title';
import WordCardRowExpanded from 'components/word_card/word_card_row_expanded';

const Wrapper = styled.div`
  width: 100%;
  .broken-circle-icon { svg { width: 10px; } }
  .arrow-icon { svg { width: 8px; } }
  .info-icon { svg { width: 2.5px; } }
  .table-icon { svg { width: 20px; } }
  .alert-icon { svg { width: 20px; } }
  .infinitive {
    .icon-wrapper {
      transform: rotate(180deg);
      display: flex;
      justify-content: flex-end;
    }
  }
  .info-icon, .arrow-icon, .broken-circle-icon {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    border: 1px solid black;
  }
  .icon-wrapper {
    width: 20px;
    svg {
      fill: #000;
    }
  }
`;

@observer
class VocabWordConcepts extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.word.concepts.map(concept => {
            if (concept.showInDictionary) {
              return (
                <WordCardRow
                  key={concept.id}
                  id={concept.id}
                  expandedId={this.props.expandedId}
                  onClick={this.props.onClickRow}
                >
                  <WordCardTitle
                    key={1}
                    concept={concept}
                  />
                  <WordCardRowExpanded
                    concept={concept}
                    user={AppUI.user}
                    pageLength={5}
                  />
                </WordCardRow>
              );
            } else {
              return null;
            }
          })
        }
      </Wrapper>
    );
  }
}

export default VocabWordConcepts;
