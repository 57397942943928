import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import PatreonConfirmation from 'components/user/patreon_confirmation';
import { flexCenterColumn } from '@seedlang/style_mixins';
import Text from 'components/text';

const Wrapper = styled.div`

`;

@observer
class SettingsPatreon extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text
          heading='3'
          margin='0 0 10px 0'
        >
          Patreon Settings
        </Text>
        <PatreonConfirmation
          showRemoveLink
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsPatreon);
