import React from 'react';
import { observer } from 'mobx-react';
import ServerAlert from 'components/server_alert';
import { CreatorUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { admin } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  ${admin()}
  .creator {
    .tag-section {
      display: flex;
      .tag-wrapper {
        flex: 1;
        .tag {
          border: 1px solid gray;
          border-radius: 5px;
          margin: 5px;
          background: white;
          display: inline-block;
          padding: 5px 10px;
          font-size: 14px;
        }
      }
    }
    .exercise-edit {
      .modal {
        height: 500px;
        overflow: auto;
      }
    }
    .chat-show {
      .chat-window {
        margin-bottom: 0;
      }
    }
    .link {
      cursor: pointer;
    }
  }
`;

@observer
class Builder extends React.Component {

  render() {
    return (
      <Wrapper>
        <div className='creator builder'>
          {CreatorUI.layout.mediaRecorderSupported && this.props.children}
          {
            !CreatorUI.layout.mediaRecorderSupported &&
              <div className='media-recorder-not-supported'>
                <i
                  className='fa fa-warning'
                  style={{
                    margin: '3px 3px 0 0',
                  }}
                />
                The "Creator" section is only supported in Chrome and Firefox.
              </div>
          }
          <ServerAlert />
        </div>
      </Wrapper>
    );
  }
}

export default Builder;