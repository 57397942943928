import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { OnboardingReferrerWrapper } from '@seedlang/hoc';
import ListSelect from 'components/list_select';
import Text from 'components/text';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import { isPresent , isBlank } from '@seedlang/utils';
import Button from 'components/button/button';
import OnboardingContent from 'components/onboarding/onboarding_content';
import OnboardingWrapper from 'components/onboarding/onboarding_wrapper';
import OnboardingIllustration from 'components/onboarding/onboarding_illustration';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';

const Continue = styled.div`
  margin-top: 20px;
`;

@observer
class OnboardingReferrer extends React.Component {

  @autobind onSubmit() {
    this.props.onSubmit();
    AppUI.routeStore.routeToNamed('onboarding.choose_deck', {languageId: this.props.params.languageId});
  }

  render() {
    return (
      <OnboardingWrapper>
        <OnboardingIllustration
          text='Where did you hear about us?'
          reaction='disappointed'
          languageId={AppUI.targetLanguageId}
        />
        <OnboardingContent>
          <ListSelect
            value={this.props.referrerOptionId}
            options={this.props.referrerOptions}
            onSelect={this.props.onSetReferrer}
            fieldName='text'
          />
        </OnboardingContent>
        <Continue>
          <Button
            onClick={this.onSubmit}
            width='440px'
            height='60px'
          >
            { this.props.referrerOptionId ? 'Continue' : 'Skip' }
          </Button>
        </Continue>
      </OnboardingWrapper>
    );
  }
}

export default OnboardingReferrerWrapper(OnboardingReferrer);
