import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { isPresent } from '@seedlang/utils';
import { ConceptWordStore } from '@seedlang/stores';
import InPlaceSelect from 'components/form/in_place_select';

@observer
class ConceptBatchAddWords extends React.Component {
  @observable wordTypeId = this.props.wordTypeId;

  @computed get wordTypeName() {
    if (this.wordTypeId) {
      return this.props.wordTypes.find(item => item.id === this.wordTypeId).name;
    }
    return null;
  }

  @computed get placeholder() {
    if (this.wordTypeId) {
      return `Add ${this.wordTypeName}s (word1,word2,word3)`;
    } else {
      return 'Add Words (word1,word2,word3)';
    }
  }

  onClick() {
    if (isPresent(this.refs.targetTexts.value)) {
      ConceptWordStore.create(
        {
          data: {
            target_texts: this.refs.targetTexts.value,
            concept_id: this.props.conceptId,
            word_params: {
              root: true,
              word_type_id: this.wordTypeId,
            },
          },
        },
      this.props.afterCreate);
      this.refs.targetTexts.value = '';
    }
  }

  render() {
    return (
      <div className='concept-category-create'>
        <div
          className='row'
          style={{marginBottom: 10}}
        >
          <div className='col-xs-12'>
            <InPlaceSelect
              options={this.props.wordTypes.map(item => [item.id, item.name])}
              includeBlank='true'
              onChange={val => this.wordTypeId = val}
              placeholder='Select a Word Type'
              value={this.wordTypeId}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <textarea
              style={{
                height: 150,
                marginBottom: 10,
              }}
              placeholder={this.placeholder}
              ref='targetTexts'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <button
              className='button-primary'
              onClick={() => this.onClick()}
            >
              Create
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ConceptBatchAddWords;
