import React from 'react';
import { observer } from 'mobx-react';
import { GroupStore } from '@seedlang/stores';
import cx from 'classnames';
import styled from '@emotion/styled';
import { AppUI, ForumUI } from '@seedlang/state';
import NavigationLink from 'components/navigation_link';
import { isPresent } from '@seedlang/utils';
import { Constants } from '@seedlang/constants';

const Wrapper = styled.div`
  .group-show {
    display: flex;
    .fa-copy {
      font-size: 20px;
    }
    .target-edit {
      display: flex;
      .fa-edit {
        cursor: pointer;
        margin: 3px 5px 0 0;
        display: inline-block;
      }
    }
    label {
      font-size: 12px;
      text-transform: uppercase;
    }
    .col-xs-2, .col-xs-12, .col-xs-10, .col-xs-6 {
      padding: 0;
    }
    input[type="text"] {
      margin: 0;
    }
    .group-tier-exercise-type {
      input[type="checkbox"] {
        margin-right: 5px;
      }
    }
    .left {
      width: 160px;
      margin-right: 10px;
      border-top: 1px solid #CCC;
      .group-nav {
        background: #e8e8e8;
        display: block;
        width: 100%;
        border-right: 1px solid #CCC;
        border-left: 1px solid #CCC;
        border-bottom: 1px solid #CCC;
        font-size: 14px;
        padding: 5px 10px;
        cursor: pointer;
        &.selected {
          background: #FFF;
        }
      }
    }
    .right {
      flex: 1;
    }
  }
`;

const Section = styled.div`
  background: #CCC;
  font-size: 12px;
  font-weight: bold;
  padding-left: 10px;
`;

const NotificationCircle = styled.div`
  background: #d67c00;
  border-radius: 100px;
  color: white;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;

@observer
class GroupShow extends React.Component {
  constructor(props) {
    super(props);
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}});
    if (!ForumUI.hasForum && AppUI.user?.site?.showWorksheetComments && isPresent(AppUI.user.site.forumId)) {
      ForumUI.loadForum(AppUI.user.site.forumId);
    }
  }

  render() {
    return (
      <Wrapper>
      <div className='group-show'>
        <div className='left'>
          {
            (AppUI.user.currentUserGroup?.canAccessAdminPosts || AppUI.siteIsDefault) &&
              <div>
                <Section>
                  Posts
                </Section>
                <NavigationLink
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.groups.worksheets.index',
                  })}
                  to={{name: 'creator.groups.worksheets.index', params: {groupId: this.props.params.groupId}}}
                >
                  Posts
                </NavigationLink>
                <NavigationLink
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.groups.exercises.index',
                  })}
                  to={{name: 'creator.groups.exercises.index', params: {groupId: this.props.params.groupId}}}
                >
                  Exercises
                </NavigationLink>
                {
                  AppUI.user?.site?.hasPodcast &&
                    <NavigationLink
                      className={cx('group-nav', {
                        selected: this.props.children.props.route.name === 'creator.podcast_episodes.index',
                      })}
                      to={{name: 'creator.podcast_episodes.index', params: {groupId: this.props.params.groupId}}}
                    >
                      Podcast Episodes
                    </NavigationLink>
                }
                <NavigationLink
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.groups.tags.index',
                  })}
                  to={{name: 'creator.groups.tags.index', params: {groupId: this.props.params.groupId}}}
                >
                  Post & Exercise Tags
                </NavigationLink>
                {
                  !AppUI.siteIsDefault && !AppUI.site.language?.hasLessons &&
                  <NavigationLink
                    className={cx('group-nav', {
                      selected: this.props.children.props.route.name === 'creator.groups.concepts.index',
                    })}
                    to={{name: 'creator.groups.concepts.index', params: {groupId: this.props.params.groupId}}}
                  >
                    Word Tags
                  </NavigationLink>
                }
                {
                  AppUI.user?.site?.showWorksheetComments &&
                    <NavigationLink
                      className={cx('group-nav', {
                        selected: this.props.children.props.route.name === 'creator.worksheet.comments',
                      })}
                      to={{name: 'creator.worksheet.comments', params: {groupId: this.props.params.groupId}}}
                    >
                      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        Comments
                        {
                          ForumUI.hasForum && ForumUI.forum.uncheckedThreadsCount > 0 &&
                            <NotificationCircle>{ForumUI.forum.uncheckedThreadsCount}</NotificationCircle>
                        }
                      </div>
                    </NavigationLink>
                }
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.emails' ,
                  })}
                  to={{name: 'creator.emails.index', params: {groupId: this.props.params.groupId}}}
                >
                  Send Email
                </NavigationLink>
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name?.startsWith('creator.download_link_types'),
                  })}
                  to={{name: 'creator.download_link_types.index', params: {groupId: this.props.params.groupId}}}
                >
                  Download Links
                </NavigationLink>
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name?.startsWith('creator.groups.custom_tabs'),
                  })}
                  to={{name: 'creator.groups.custom_tabs', params: {groupId: this.props.params.groupId}}}
                >
                  Custom Tabs
                </NavigationLink>
              </div>
          }
          {
            (AppUI.user.currentUserGroup?.canAccessAdminUsers || AppUI.siteIsDefault) &&
              <div>
                <Section>
                  User Management
                </Section>
                {
                  AppUI.permissions?.canAccessBasicUserData &&
                    <NavigationLink
                      className={cx('group-nav', {
                        selected: this.props.children.props.route.name === 'creator.groups.users.index',
                      })}
                      to={{name: 'creator.groups.users.index', params: {groupId: this.props.params.groupId}}}
                    >
                      Users
                    </NavigationLink>
                }
                {
                  AppUI.permissions?.canAccessAdmins &&
                    <NavigationLink
                      className={cx('group-nav', {
                        selected: this.props.children.props.route.name?.startsWith('creator.groups.admins.index'),
                      })}
                      to={{name: 'creator.groups.admins.index', params: {groupId: this.props.params.groupId}}}
                    >
                      Admins
                    </NavigationLink>
                }
                <NavigationLink
                  siteIsDefault
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.groups.patreon_users.index',
                  })}
                  to={{name: 'creator.groups.patreon_users.index', params: {groupId: this.props.params.groupId}}}
                >
                  Patreon Users
                </NavigationLink>
                {
                  AppUI.permissions?.canAccessBasicUserData &&
                    <NavigationLink
                      whiteLabel
                      className={cx('group-nav', {
                        selected: this.props.children.props.route.name === 'creator.groups.subscriptions.index',
                      })}
                      to={{name: 'creator.groups.subscriptions.index', params: {groupId: this.props.params.groupId}}}
                    >
                      Subscriptions
                    </NavigationLink>
                }
              </div>
          }
          {
            (AppUI.user.currentUserGroup?.canAccessAdminSite || AppUI.siteIsDefault) &&
              <div>
                <Section>
                  Site Setup
                </Section>
                <NavigationLink
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.groups.site_layout',
                  })}
                  to={{name: 'creator.groups.site_layout', params: {groupId: this.props.params.groupId}}}
                >
                  Site Settings
                </NavigationLink>
                {
                  GroupStore.hasShowData && isPresent(GroupStore.showData.site?.languageId) && Constants.LANGUAGE_ABBREVIATIONS.indexOf(GroupStore.showData.site?.languageId) === -1 &&
                    <NavigationLink
                      className={cx('group-nav', {
                        selected: this.props.children.props.route.name === 'creator.groups.language_edit',
                      })}
                      to={{name: 'creator.groups.language_edit', params: {groupId: this.props.params.groupId, languageId: GroupStore.showData.site?.languageId}}}
                    >
                      Language Settings
                    </NavigationLink>
                }
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.groups.site_navigation' || this.props.children.props.route.name === 'creator.groups.navigation_items.edit',
                  })}
                  to={{name: 'creator.groups.site_navigation', params: {groupId: this.props.params.groupId}}}
                >
                  Site Navigation
                </NavigationLink>
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.groups.worksheet_settings',
                  })}
                  to={{name: 'creator.groups.worksheet_settings', params: {groupId: this.props.params.groupId}}}
                >
                  Post Settings
                </NavigationLink>
                <NavigationLink
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name === 'creator.grid_items.index',
                  })}
                  to={{name: 'creator.grid_items.index', params: {groupId: this.props.params.groupId}}}
                >
                  Grid Sections
                </NavigationLink>
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name?.startsWith('creator.groups.site_pages'),
                  })}
                  to={{name: 'creator.groups.site_pages', params: {groupId: this.props.params.groupId}}}
                >
                  Custom Pages
                </NavigationLink>
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name?.startsWith('creator.groups.membership_groups') || this.props.children.props.route.name?.startsWith('creator.groups.memberships'),
                  })}
                  to={{name: 'creator.groups.membership_groups', params: {groupId: this.props.params.groupId}}}
                >
                  Membership Groups
                </NavigationLink>
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name?.startsWith('creator.groups.membership_types'),
                  })}
                  to={{name: 'creator.groups.membership_types.index', params: {groupId: this.props.params.groupId}}}
                >
                  Membership Types
                </NavigationLink>
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name?.startsWith('creator.campaign'),
                  })}
                  to={{name: 'creator.campaign_types.index', params: {groupId: this.props.params.groupId}}}
                >
                  Automated Emails
                </NavigationLink>
                <NavigationLink
                  whiteLabel
                  className={cx('group-nav', {
                    selected: this.props.children.props.route.name?.startsWith('creator.banner_notices'),
                  })}
                  to={{name: 'creator.banner_notices.index', params: {groupId: this.props.params.groupId}}}
                >
                  Banner Notices
                </NavigationLink>
                {
                  AppUI.user?.site?.hasPodcast &&
                    <NavigationLink
                      className={cx('group-nav', {
                        selected: this.props.children.props.route.name?.startsWith('creator.podcasts'),
                      })}
                      to={{name: 'creator.podcasts.index', params: {groupId: this.props.params.groupId}}}
                    >
                      Podcasts
                    </NavigationLink>
                }
                {
                  AppUI.site.allowPatreonSignIn &&
                    <NavigationLink
                      className={cx('group-nav', {
                        selected: this.props.children.props.route.name?.startsWith('creator.payment_migration'),
                      })}
                      to={{ name: 'creator.payment_migration.index', params: { groupId: this.props.params.groupId } }}
                    >
                      Payment Migration
                    </NavigationLink>
                }
              </div>
          }
          <Section>
            Analytics
          </Section>
          {
            !AppUI.siteIsDefault &&
              <NavigationLink
                className={cx('group-nav', {
                  selected: this.props.children.props.route.name === 'creator.exercises.dashboard',
                })}
                to={{name: 'creator.exercises.dashboard', params: {groupId: this.props.params.groupId}}}
              >
                Dashboard
              </NavigationLink>
          }
          {
            AppUI.siteIsDefault || (!AppUI.siteIsDefault && AppUI.site?.published) &&
              <NavigationLink
                className={cx('group-nav', {
                  selected: this.props.children.props.route.name === 'creator.exercises.analytics',
                })}
                to={{name: 'creator.exercises.analytics', params: {groupId: this.props.params.groupId}}}
              >
                Analytics
              </NavigationLink>
          }
          <NavigationLink
            className={cx('group-nav', {
              selected: this.props.children.props.route.name === 'creator.exercise.ratings',
            })}
            to={{name: 'creator.exercise.ratings', params: {groupId: this.props.params.groupId}}}
          >
            Exercise Ratings
          </NavigationLink>
        </div>
        <div className='right'>
          {this.props.children}
        </div>
      </div>
      </Wrapper>
    );
  }
}

export default GroupShow;
