import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { SubscriptionStore , MembershipStore } from '@seedlang/stores';
import InPlaceSelect from 'components/form/in_place_select';
import { sortBy } from 'lodash';

@observer
class SubscriptionCreate extends React.Component {
  @observable membershipId;
  @observable currency;
  @observable paymentMethod;

  constructor(props) {
    super(props);
    MembershipStore.getIndex({queryStrings: {expandMembership: true}, filters: {active: true}});
  }

  onClick() {
    if (this.currency && this.membershipId && this.paymentMethod) {
      SubscriptionStore.create(
        {
          data: {
            paymentMethod: this.paymentMethod,
            currency: this.currency,
            userId: this.props.userId,
            membershipId: this.membershipId,
            transactionId: this.refs.transactionId.value,
          },
        },
        this.props.afterCreate,
      );
      this.refs.transactionId.value = '';
    }
  }

  render() {
    return (
      <div className='concept-category-create row'>
        <div className='col-xs-2'>
          <InPlaceSelect
            onChange={val => this.membershipId = val}
            includeBlank
            value={this.membershipId}
            options={sortBy(MembershipStore.indexData, ['title']).map(item => [item.id, `${item.title} (${item.numberMonths} months) - ${item.membershipGroupName}`])}
          />
        </div>
        <div className='col-xs-1'>
          <InPlaceSelect
            onChange={val => this.currency = val}
            includeBlank
            value={this.currency}
            options={['USD', 'EUR']}
          />
        </div>
        <div className='col-xs-2'>
          <InPlaceSelect
            onChange={val => this.paymentMethod = val}
            includeBlank
            value={this.paymentMethod}
            options={['paypal', 'stripe', 'ios', 'android']}
          />
        </div>
        <div className='col-xs-4'>
          <input
            placeholder='Transaction Id'
            ref='transactionId'
          />
        </div>
        <div className='col-xs-3'>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default SubscriptionCreate;
