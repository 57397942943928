import { OnboardingWrapper } from '@seedlang/hoc';
import { AppUI } from '@seedlang/state';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { observer } from 'mobx-react';
import React from 'react';
import OnboardingSeedlang from './onboarding_seedlang';
import OnboardingWhitelabel from './onboarding_whitelabel';

@observer
class Onboarding extends React.Component {

  render() {
    return (
      AppUI.siteIsDefault ? (
        <OnboardingSeedlang {...this.props} />
      ) : (
        <OnboardingWhitelabel {...this.props} />
      )
    );
  }
}

export default ErrorBoundary(OnboardingWrapper(Onboarding));
