import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { flexCenter, flexLeftColumn } from '@seedlang/style_mixins';
import SquareButton from 'components/button/square_button';
import autobind from 'autobind-decorator';
import {keys, each} from 'lodash';

const Wrapper = styled.div`
  display: flex;
  font-size: 14px;
  margin-left: 5px;
`;

const InputWrapper = styled.div`
  ${flexLeftColumn()}
  padding: 10px 0;
`;

const IconWrapper = styled.div`
  margin-right: 5px;
  width: 20px;
  ${flexCenter()}
  cursor: pointer;
`;

const LinkWrapper = styled.div`
  cursor: pointer;
`;

@observer
class AddSource extends React.Component {

  @autobind onSubmit() {
    const obj = {};
    each(keys(this.refs), key => {
      obj[key] = this.refs[key].value;
    });
    this.props.onSubmitInput(obj);
  }

  render() {
    return (
      <Wrapper>
        <IconWrapper
          onClick={this.props.onEnableInput}
        >
          {
            !this.props.showInput &&
              <i className='fa fa-plus' />
          }
        </IconWrapper>
        {
          this.props.showInput &&
            <InputWrapper>
              {
                this.props.inputs.map(item => {
                  return (
                    <input
                      key={item.name}
                      ref={item.name}
                      placeholder={item.placeholder}
                    />
                  );
                })
              }
              <SquareButton
                margin='10px 0 0 0'
                onClick={this.onSubmit}
              >
                Submit
              </SquareButton>
            </InputWrapper>
        }
        {
          !this.props.showInput &&
            <LinkWrapper
              onClick={this.props.onEnableInput}
            >
              {this.props.linkText}
            </LinkWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(AddSource);
