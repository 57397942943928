import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { CardStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';
import WordCreate from 'pages/builder/words/word_create';
import Modal from 'components/modal';
import { noop } from 'lodash';
import autobind from 'autobind-decorator';
import Filter from 'components/backend_filter/filter';
import Copy from 'components/copy';

const Wrapper = styled.div`

`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

@observer
class UserSubmissionIndex extends React.Component {
  @observable convertCard = null;
  @observable page;

  @autobind reject(card) {
    card.set('rejectedAsWord', true);
    CardStore.update({ids: {cardId: card.id}, data: {rejected_as_word: true}}, noop);
    CardStore.update({ids: {cardId: card.id}, data: {rejected_as_word: true}}, noop);
  }

  @autobind removeRejected(card) {
    card.set('rejectedAsWord', false);
    CardStore.update({ids: {cardId: card.id}, data: {rejected_as_word: false}}, noop);
  }

  @autobind afterCreateWord() {
    this.convertCard = null;
    CardStore.reloadIndexFiltered({queryStrings: {card_submissions: true}});
  }

  render() {
    return (
      <Wrapper>
        {
          this.convertCard &&
            <Modal
              onCloseModal={() => this.convertCard = null}
              height='500px'
            >
              <WordCreate
                targetText={this.convertCard.frontText}
                sourceText={this.convertCard.backText}
                userId={this.convertCard.user && this.convertCard.user.id}
                cardId={this.convertCard.id}
                afterCreate={this.afterCreateWord}
              />
            </Modal>
        }
        {
          this.page &&
          <Filter
            store={CardStore}
            defaultSort='-created_at'
            namespace='user-submission-index'
            queryStrings={{card_submissions: true}}
            page={this.page}
            filters={
            [
              {
                type: 'select',
                name: 'language_id',
                options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                labelField: 'name',
                label: 'Language',
                default: '',
              },
              {
                type: 'boolean',
                name: 'rejected_as_word',
                label: 'Rejected',
              },
              {
                type: 'boolean',
                name: 'word_id',
                label: 'Has Word',
              },
              {
                type: 'text',
                name: 'front_text',
                label: 'Front Text',
              },
              {
                type: 'text',
                name: 'back_text',
                label: 'Back Text',
              },
            ]}
          />
        }
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th width='220'>Status</th>
              <th width='120'>Date</th>
              <th>Submission</th>
              <th width='40'>Language</th>
              <th width='120'>User</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              CardStore.indexData.map(item => {
                return (
                  <tr
                    key={item.id}
                  >
                    <td>
                      <ButtonWrapper>
                        {
                          !item.rejectedAsWord && !isPresent(item.word) &&
                            <div>
                              <button
                                className='button-primary'
                                style={{marginRight: 5}}
                                onClick={() => this.convertCard = item}
                              >
                                <i className='fa fa-check' style={{color: 'green'}}/>
                                Accept
                              </button>
                              <button
                                className='button-primary'
                                onClick={() => this.reject(item)}
                              >
                                <i className='fa fa-times' style={{color: 'red'}} />
                                Reject
                              </button>
                            </div>
                        }
                        {
                          item.rejectedAsWord &&
                            <b>Rejected</b>
                        }
                        {
                          item.rejectedAsWord &&
                            <i
                              style={{marginLeft: 5, color: '#333'}}
                              className='fa fa-times fa-on-click'
                              onClick={() => this.removeRejected(item)}
                            />
                        }
                        {
                          isPresent(item.word) &&
                            <Link
                              to={{name: 'builder.words.edit', params: {wordId: item.word.id}}}
                              className='on-click'
                            >
                              {item.word.targetTextWithNominativeDefiniteArticle}
                            </Link>
                        }
                      </ButtonWrapper>
                    </td>
                    <td>
                      {item.createdAt.formatted}
                    </td>
                    <td>
                      <div>
                        {item.frontText}
                      </div>
                      <div>
                        {item.backText}
                      </div>
                    </td>
                    <td>
                      {item.languageId}
                    </td>
                    <td>
                      {
                        item.user &&
                          <Link
                            to={{name: 'builder.users.edit', params: {userId: item.user.id}}}
                            className='on-click'
                          >
                            {isPresent(item.user.name) ? item.user.name : 'Anonymous'}
                          </Link>
                      }
                    </td>
                    <td>
                      <Copy
                        text={item.id}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          updateUrl
          queryStrings={{card_submissions: true}}
          store={CardStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(UserSubmissionIndex);
