import React from 'react';
import Navigation from 'components/navigation';
import { last } from 'lodash';

class User extends React.Component {

  render() {
    return (
      <div className='user'>
        <Navigation
          currentRoute={last(this.props.routes).name}
          smallButtons
          links={[
            {
              link: 'builder.users.index',
              title: 'Users',
            },
            {
              link: 'builder.invitations.index',
              title: 'Invitations',
            },
            {
              link: 'builder.challenges.index',
              title: 'Challenges',
            },
          ]}
        />
        {this.props.children}
      </div>
    );
  }
}

export default User;
