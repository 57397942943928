import React from 'react';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import { isBlank , isPresent } from '@seedlang/utils';
import { LanguageStore, WordStructureStore, WorksheetWordStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { Languages } from '@seedlang/constants';
import { ExerciseUI } from '@seedlang/state';
import Text from 'components/text';
import InPlaceText from 'components/form/in_place_text';
import RadioButton from 'components/radio_button';
import DeleteButton from 'components/button/delete_button';
import Alert from 'components/alert';
import { Link } from 'react-router';
import SquareButton from 'components/button/square_button';
import styled from '@emotion/styled';

const Wrapper = styled.div`
`;

@observer
class WordStructureEdit extends React.Component {
  @observable unknownComponents = [];
  @observable showFormToCreateNewWordStructure = false;

  componentDidMount() {
    if (!LanguageStore.hasShowData) {
      this.loadLanguage();
    }
  }

  @autobind loadLanguage() {
    LanguageStore.getShow({ids: {languageId: this.props.worksheetWord?.word?.languageId}});
  }

  @computed get verbStructureWorksheetWord() {
    return WorksheetWordStore.hasIndexData && WorksheetWordStore.indexData.find(item => item.id === this.props.worksheetWord?.id);
  }

  @autobind selectWordStructure(wordStructure) {
    this.props.worksheetWord.set('wordStructureId', wordStructure?.id);
    if (isBlank(wordStructure)) {
      WorksheetWordStore.update({ids: {worksheetWordId: this.props.worksheetWord?.id}, data: {wordStructureId: null}}, this.props.afterChange);
    } else {
      WorksheetWordStore.update({ids: {worksheetWordId: this.props.worksheetWord?.id}, data: {wordStructureId: wordStructure.id}}, this.props.afterChange);
    }
  }

  @autobind createNewWordStructure() {
    if (isPresent(this.refs.text.value)) {
      WordStructureStore.create({
          data: {
            text: this.refs.text.value,
            sourceText: this.refs.sourceText.value,
            wordId: this.props.worksheetWord.word?.id,
            worksheetWordId: this.props.worksheetWord?.id,
          }}
        , this.afterUpdateNewWordStructure);
    }
  }

  @autobind deleteWordStructure(wordStructureId) {
    WordStructureStore.destroy({ids: {wordStructureId: wordStructureId}}, this.props.afterChange);
  }

  @autobind afterUpdateNewWordStructure(resp) {
    if (resp.success) {
      this.showFormToCreateNewWordStructure = false;
    }
    this.props.afterChange();
    this.unknownComponents = resp.unknownComponents;
  }

  render() {
    return (
      <Wrapper>
        <Text
          heading={3}
          margin='0 0 10px 0'
        >
          Display <i>{this.props.worksheetWord.word?.targetText}</i> as a verb structure
        </Text>
        <table className='table-wrapper'>
          <thead>
          <tr>
            <th width='30'/>
            <th>{`${Languages[this.props.languageId]} verb structure`}</th>
            <th>English translation</th>
            <th width='30'/>
          </tr>
          </thead>
          <tbody>
          <tr
            style={{cursor: 'pointer'}}
          >
            <td>
              <RadioButton
                width='15px'
                height='15px'
                margin='3px'
                onClick={() => this.selectWordStructure()}
                value={isBlank(this.props.worksheetWord?.wordStructureId)}
              />
            </td>
            <td style={{fontSize: '15px'}}>
              {this.props.worksheetWord?.word?.targetText}
            </td>
            <td style={{fontSize: '15px'}}>
              {this.props.worksheetWord?.word?.vocabSourceList}
            </td>
            <td />
          </tr>
          {
            this.props.worksheetWord.word?.wordStructures?.map(wordStructure => {
              return (
                <tr
                  key={wordStructure.id}
                >
                  <td>
                    <RadioButton
                      width='15px'
                      height='15px'
                      margin='3px'
                      onClick={this.selectWordStructure.bind(this, wordStructure)}
                      value={wordStructure.id === this.props.worksheetWord?.wordStructureId}
                    />
                  </td>
                  <td>
                    <InPlaceText
                      show={isBlank(wordStructure.text)}
                      submitOnEnter
                      model='word_structures'
                      field='text'
                      defaultValue={wordStructure.text}
                      id={wordStructure.id}
                      type='text'
                      placeholder={`enter a ${Languages[this.props.languageId]} verb structure`}
                      style={{margin: '3px'}}
                      updateCallback={this.afterUpdateNewWordStructure}
                    />
                  </td>
                  <td>
                    <InPlaceText
                      show={isBlank(wordStructure.sourceText)}
                      submitOnEnter
                      model='word_structures'
                      field='source_text'
                      defaultValue={wordStructure.sourceText}
                      id={wordStructure.id}
                      type='text'
                      placeholder='enter the English translation'
                      style={{margin: '3px'}}
                      updateCallback={this.afterUpdateNewWordStructure}
                    />
                  </td>
                  <td>
                    {
                      wordStructure.creatorUserId === ExerciseUI.user.id &&
                        <DeleteButton
                          onConfirm={() => this.deleteWordStructure(wordStructure.id)}
                          message='Delete this word structure?'
                          className='fa-block right'
                        />
                    }
                  </td>
                </tr>
              );
            })
          }
          {
            this.showFormToCreateNewWordStructure &&
              <tr>
                <td />
                <td>
                  <input
                    placeholder={`enter a ${Languages[this.props.languageId]} verb structure`}
                    ref='text'
                  />
                </td>
                <td>
                  <input
                    placeholder='enter the English translation'
                    ref='sourceText'
                  />
                </td>
                <td>
                  <SquareButton
                    margin='0'
                    onClick={this.createNewWordStructure}
                  >
                    <i className='fa fa-plus' />
                  </SquareButton>
                </td>
              </tr>
          }
          {
            !this.showFormToCreateNewWordStructure &&
              <tr
                style={{cursor: 'pointer'}}
              >
                <td
                  colSpan='4'
                  onClick={() => this.showFormToCreateNewWordStructure = true}
                >
                  <i className='fa fa-plus' style={{margin: '3px'}}/>
                  <Text fontSize='14px'>Create a new verb structure</Text>
                </td>
              </tr>
          }
          </tbody>
        </table>
        {
          this.unknownComponents?.length > 0 &&
            <Alert
              width='100%'
              margin='5px 0 0 0'
              showCloseButton
              onClose={() => this.unknownComponents = []}
            >
              <span>Word structure invalid. </span>
              <span style={{fontStyle: 'italic'}}>{this.unknownComponents.join(', ')}</span>
              <span>{` ${this.unknownComponents.length > 1 ? 'are' : 'is'} not in the ${Languages[this.props.languageId]} word structure components. `}</span>
              {
                !LanguageStore.showData?.hasLessons &&
                <Link
                  className='underline'
                  to={{name: 'creator.groups.language_edit', params: {languageId: this.props.languageId}}}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Edit this list
                </Link>
              }
            </Alert>
        }
        <Text margin='20px 0 0 0' fontSize='13px'>{`Word structure components for ${Languages[this.props.languageId]}:`}</Text>
        <Text fontSize='13px'><code>{LanguageStore.showData.wordStructureComponents}</code></Text>
      </Wrapper>
    );
  }
}

export default WordStructureEdit;
