import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { last } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Word = styled.span`
  margin-right: ${props => props.marginRight};
  border-bottom: 2px solid ${props => props.borderColor};
  &:first-letter {
    text-transform: ${props => props.textTransform};
  }
`;

const Punctuation = styled.span`
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
`;

@observer
class SentenceWithCases extends React.Component {

  borderColor(item) {
    if (item.word && item.word.isDative) {
      return Theme.red;
    } else if (item.word && item.word.isAccusative) {
      return Theme.blue;
    } else if (item.word && item.word.isNominative) {
      return '#333';
    } else if (item.word && item.word.isGenitive) {
      return Theme.purple;
    }
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.sentence.wordAssociations.map(item => {
            return (
              <Word
                textTransform={item.firstInSentence ? 'capitalize' : 'default'}
                borderColor={this.borderColor(item)}
                key={item.id}
                marginRight={(item.punctuation === '-' || last(item.targetText) === '\'') ? 0 : '4px'}
              >
                {
                  item.precedingPunctuation &&
                    <Punctuation
                      marginRight={this.props.sentence.languageId === 'FR' && last(item.precedingPunctuation) === '«' ? '3px' : 0}
                    >
                      {item.precedingPunctuation}
                    </Punctuation>
                }
                {item.targetText}
                {
                  item.punctuation &&
                    <Punctuation
                        marginLeft={this.props.sentence.languageId === 'FR' && ['?', '!', ':', '»'].indexOf(item.punctuation[0]) !== -1 ? '3px' : 0}
                    >
                      {item.punctuation}
                    </Punctuation>
                }
              </Word>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default SentenceWithCases;
