import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';
import { DeckUI } from '@seedlang/state';
import HeightLabel from 'components/height_label';

const Wrapper = styled.div`
  height: ${props => props.height};
  padding: ${props => props.padding};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: ${props => props.scroll ? 'auto' : 'hidden'};
`;

@observer
class CardContent extends React.Component {

  static defaultProps = {
    padding: 0,
    scroll: false,
  }

  render() {
    return (
      <Wrapper
        scroll={this.props.scroll}
        height={pixify(this.props.height || DeckUI.layout.contentHeight)}
        padding={pixify(this.props.padding)}
      >
        {
          DeckUI.layout.debug &&
            <HeightLabel
              right={0}
            >
              {this.props.height || DeckUI.layout.contentHeight}
            </HeightLabel>
        }
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(CardContent);
