import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  padding: 0 10px;
  color: ${props => props.selected ? '#333' : '#FFF'};
  background: ${props => props.selected ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.3)'};
  font-size: 12px;
  font-weight: bold;
  i {
    margin-right: 3px;
  }
  width: 112px;
`;

@observer
class ExpandAllButton extends React.Component {

  render() {
    return (
      <Wrapper
        onClick={this.props.onClick}
        selected={this.props.expanded}
      >
        {
          this.props.expanded &&
            <i className='fa fa-minus' />
        }
        {
          !this.props.expanded &&
            <i className='fa fa-plus' />
        }
        { this.props.expanded && 'Collapse All' }
        { !this.props.expanded && 'Expand All' }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExpandAllButton);
