import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { isBlank, isPresent } from '@seedlang/utils';
import VideoPlayerRecorder from 'components/media/video_player_recorder';
import { AppUI, CreatorUI, SentenceStudioUI } from '@seedlang/state';
import SentenceCreate from 'pages/builder/sentences/sentence_create';
import TranslationIndex from 'components/sentence_studio/translation_index';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import TabLabel from 'components/tab_label';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import OutlineButton from 'components/button/outline_button';

const Wrapper = styled.div`

`;

const NextRecording = styled.div`
  position: relative;
  text-align: center;
`;

const CallToAction = styled.div`
  width: 100%;
  background: #333;
  color: white;
  font-size: 13px;
`;

const Speed = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
`;

const Unselected = styled.div`
  font-size: 16px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  text-align: center;
  background: white;
  width: 100%;
  margin-bottom: 10px;
`;

const Text = styled.div`
  font-size: 21px;
  font-weight: bold;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  text-align: center;
  background: white;
  width: 100%;
  margin-bottom: 10px;
`;

const Recordings = styled.div`

`;

const SentenceWrapper = styled.div`

`;

const FinishedRecording = styled.div`
  background: ${Theme.green};
  color: white;
  font-style: italic;
  font-size: 16px;
  margin-bottom: 10px;
  padding: 10px;
`;

const RecorderWrapper = styled.div`
  background: #333;
  width: 100%;
  min-height: 340px;
  margin-bottom: 10px;
  display: inline-block;
  position: relative;
`;

const Options = styled.div`
  background: #FFF;
  padding: 5px;
  margin-bottom: 10px;
  label {
    font-weight: normal;
    font-size: 12px;
  }
`;

const Option = styled.div`
  padding: 5px 10px;
  border: 1px solid #CCC;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin: 0 2px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;

`;

@observer
class SentenceStudio extends React.Component {

  @computed get prompt() {
    if (SentenceStudioUI.currentTranslationTargetIsWord && SentenceStudioUI.currentTranslation.isNoun && SentenceStudioUI.currentTranslation.plural) {
      return `${SentenceStudioUI.currentTranslation.targetTextWithDefiniteArticle}, ${SentenceStudioUI.currentTranslation.targetTextWithDefiniteArticle}`;
    } else if (SentenceStudioUI.currentTranslationTargetIsWord && SentenceStudioUI.currentTranslation.isNoun) {
      const pluralString = SentenceStudioUI.currentTranslation.noPlural ? '-- No Plural --' : '[Plural Form]';
      return `${SentenceStudioUI.currentTranslation.targetTextWithDefiniteArticle}, ${pluralString}`;
    } else if (SentenceStudioUI.currentTranslationTargetIsWord) {
      return `${SentenceStudioUI.currentTranslationTarget.text}, ${SentenceStudioUI.currentTranslationTarget.text}`;
    } else {
      return SentenceStudioUI.currentTranslationTarget.text;
    }
  }

  static propTypes = {
    sentenceId: PropTypes.string,
    hideInput: PropTypes.bool,
    width: PropTypes.number,
    afterCreateVideo: PropTypes.func,
    conceptId: PropTypes.string,
    onDeleteSentence: PropTypes.func,
    afterDestroyVideo: PropTypes.func,
  }

  constructor(props) {
    super(props);
    SentenceStudioUI.createMediaStore();
  }

  componentDidMount() {
    SentenceStudioUI.set('sentenceId', this.props.sentenceId);
    SentenceStudioUI.loadSentence(this.props.sentenceId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sentenceId !== this.props.sentenceId) {
      SentenceStudioUI.set('sentenceId', this.props.sentenceId);
      SentenceStudioUI.loadSentence(this.props.sentenceId);
    }
  }

  componentWillUnmount() {
    SentenceStudioUI.reset();
  }

  @autobind onDeleteVideoClip(id) {
    SentenceStudioUI.onDeleteVideoClip(id, this.props.afterDestroyVideo);
  }

  @autobind afterCreateVideoRequestSent() {
    SentenceStudioUI.afterCreateRequestSent();
    if (this.props.afterCreateVideoRequestSent) {
      this.props.afterCreateVideoRequestSent({sentenceId: this.props.sentenceId, completed: isBlank(SentenceStudioUI.currentTranslationTarget)});
    }
  }

  @autobind afterCreateVideo() {
    SentenceStudioUI.getSentence(this.props.afterCreateVideoCallback);
    if (this.props.afterCreateVideo) {
      this.props.afterCreateVideo();
    }
  }

  mainTabs() {
    const tabs = [
      {
        name: 'recordings',
        label: (
          <TabLabel
            label='Recordings'
            valid={SentenceStudioUI.sentence.hasAllVideoClips}
          />
        ),
        default: true,
      },
    ];
    if (this.props.associateMedia) {
      tabs.push({
        name: 'image_or_video',
        label: (
          <TabLabel
            label='Image or Video'
            valid={SentenceStudioUI.sentence.hasMedia}
          />
        ),
      });
    }
    return tabs;
  }

  render() {
    return (
      <Wrapper>
        <NextRecording>
          <CallToAction>
            {isPresent(SentenceStudioUI.currentTranslation) ? 'Rerecord' : 'Next Recording'}
          </CallToAction>
          {
            isBlank(SentenceStudioUI.currentTranslationTarget) &&
            <Unselected>
              A recording has not been selected
            </Unselected>
          }
          {
            isPresent(SentenceStudioUI.currentTranslationTarget) &&
              <Text>
                <span>{this.prompt}</span>
                {
                  SentenceStudioUI.translationType === 'correct' &&
                    <span style={{color: 'green'}}> (correct)</span>
                }
                {
                  SentenceStudioUI.translationType === 'incorrect' &&
                    <span style={{color: 'red'}}> (incorrect)</span>
                }
                {
                  this.props.concept && this.props.concept.level &&
                    <Speed>
                      {this.props.concept.level.abbreviation}
                      {this.props.concept.level.abbreviation === 'A1' && ': Slow'}
                      {this.props.concept.level.abbreviation === 'A2' && ': Moderately Slow'}
                      {this.props.concept.level.abbreviation === 'B1' && ': Almost Native-Speed'}
                      {this.props.concept.level.abbreviation === 'B2' && ': Native-Speed'}
                    </Speed>
                }
              </Text>
          }
        </NextRecording>
        <Recordings>
          {
            !this.props.sentenceId && !this.props.hideInput &&
              <SentenceCreate
                simplifiedLayout
                simpleSentence
                afterCreate={this.props.afterCreateSentence}
                conceptId={this.props.conceptId}
              />
          }
          {
            this.props.sentenceId &&
              <SentenceWrapper>
                <RecorderWrapper>
                  <VideoPlayerRecorder
                    countdownBeep
                    afterCreateVideo={this.afterCreateVideo}
                    afterCreateRequestSent={this.afterCreateVideoRequestSent}
                    videoableType={['correct', 'incorrect'].indexOf(SentenceStudioUI.translationType) !== -1 ? 'Translation' : 'Target'}
                    videoableId={SentenceStudioUI.videoableId}
                    foreignKey={SentenceStudioUI.foreignKey}
                    mediaStore={SentenceStudioUI.mediaStore}
                    videoClip={SentenceStudioUI.videoClip}
                    onPlaybackClosed={SentenceStudioUI.reset}
                    width={this.props.width}
                  />
                  {
                    isPresent(SentenceStudioUI.videoClip) && (SentenceStudioUI.videoClip.userId === AppUI.user.id || AppUI.user.id === '3e72839b-b297-4673-9717-87c8da0d3a60') &&
                      <ButtonWrapper>
                        <OutlineButton
                          margin='10px 0'
                          small
                          background='#FFF'
                          selectedBackground={Theme.red}
                          onClick={() => this.onDeleteVideoClip(SentenceStudioUI.videoClip.id)}
                          fontSize='12px'
                        >
                          Delete Video
                        </OutlineButton>
                      </ButtonWrapper>
                  }
                </RecorderWrapper>
                {
                  SentenceStudioUI.sentence.hasAllVideoClips &&
                    <FinishedRecording>
                      {'You\'ve finished all recordings for this sentence.'}
                    </FinishedRecording>
                }
                {
                  SentenceStudioUI.hasSentence &&
                    <TranslationIndex
                      triviaDeck={this.props.triviaDeck}
                      currentTranslation={SentenceStudioUI.currentTranslation}
                      translationType={SentenceStudioUI.translationType}
                      sentence={SentenceStudioUI.sentence}
                      onPlayVideo={SentenceStudioUI.onPlayVideoFromTranslation}
                      onSetVideoable={SentenceStudioUI.onSetVideoableFromTranslation}
                      onDeleteSentence={this.props.onDeleteSentence}
                    />
                }
                <Options>
                  <Option>
                    <InPlaceCheckbox
                      allowUpdate
                      onChange={CreatorUI.onToggleAutoAdvanceWord}
                      icon='check'
                      value={CreatorUI.autoAdvanceWord}
                    >
                      Auto-advance
                    </InPlaceCheckbox>
                  </Option>
                  {
                    false &&
                      <Option>
                        <InPlaceCheckbox
                          allowUpdate
                          onChange={CreatorUI.onToggleOverwriteVideo}
                          icon='check'
                          value={CreatorUI.overwriteVideo}
                        >
                          One Video Per Translation
                        </InPlaceCheckbox>
                      </Option>
                  }
                </Options>
              </SentenceWrapper>
          }
        </Recordings>
      </Wrapper>
    );
  }
}

export default SentenceStudio;
