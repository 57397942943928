import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI } from '@seedlang/state';
import { Languages } from '@seedlang/constants';

const Wrapper = styled.div`
  select {
    height: 40px;
  }
`;

const Label = styled.div`
  border: 1px dotted transparent;
  &:hover {
    border: 1px dotted gray;
  }
`;

const Select = styled.div`
  display: flex;
  align-items: center;
`;

@observer
class InPlaceLanguageSelect extends React.Component {
  @observable showEdit = false;

  render() {
    return (
      <Wrapper>
        {
          (this.props.showEdit || this.showEdit) &&
            <Select>
              <InPlaceSelect
                allowUpdate
                model={this.props.model}
                id={this.props.id}
                field={this.props.field ?? 'language_id'}
                value={this.props.value}
                options={AppUI.languageOptions}
                afterChange={this.props.afterChange}
                onChange={this.props.onChange}
                includeBlank={this.props.includeBlank}
              />
              {
                !this.props.showEdit &&
                  <i
                    className='fa fa-times'
                    style={{margin: '0 0 0 5px'}}
                    onClick={() => this.showEdit = false}
                  />
              }
            </Select>
        }
        {
          !this.props.showEdit && !this.showEdit &&
            <Label
              onClick={() => this.showEdit = true}
              style={{ minHeight: '26px' }}
            >
              {this.props.showAbbreviation ? this.props.value : Languages[this.props.value]}
            </Label>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(InPlaceLanguageSelect);
