import React from 'react';
import { observer } from 'mobx-react';
import { ForumStore , GroupStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import ForumCreate from 'pages/builder/forums/forum_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import CheckIfTrue from 'components/check_if_true';

@observer
class ForumIndex extends React.Component {

  componentDidMount() {
    this.loadForums();
    GroupStore.getIndex();
  }

  @autobind onDelete(id) {
    ForumStore.destroy({ids: {forumId: id}});
  }

  @autobind loadForums() {
    ForumStore.getIndex();
  }

  render() {
    return (
      <div className='forum-index'>
        <ForumCreate />
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th width='80'>Pos</th>
              <th>Title</th>
              <th>Group</th>
              <th>Admin</th>
              <th>Moderator</th>
              <th>Translator</th>
              <th># Threads</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              ForumStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.forums.edit', params: { forumId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <InPlaceText
                        id={item.id}
                        defaultValue={item.position}
                        model='forums'
                        field='position'
                        type='number'
                        step='1'
                        afterChange={this.loadForums}
                      />
                    </td>
                    <td>
                      <InPlaceText
                        id={item.id}
                        defaultValue={item.title}
                        model='forums'
                        field='title'
                      />
                    </td>
                    <td>
                      <InPlaceSelect
                        includeBlank
                        id={item.id}
                        value={item.hasGroup && item.group.id}
                        model='forums'
                        field='group_id'
                        options={GroupStore.indexData.map(item => [item.id, item.name])}
                        afterChange={this.loadForums}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.admin}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.moderator}
                      />
                    </td>
                    <td>
                      <CheckIfTrue
                        value={item.translator}
                      />
                    </td>
                    <td>
                      {item.forumThreadsCount}
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this forum?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={ForumStore}
        />
      </div>
    );
  }
}

export default ForumIndex;
