"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = pixify;
var _is_present = _interopRequireDefault(require("./is_present"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function pixify(value) {
  if (value == null || value === 'NaN' || Number.isNaN(value)) {
    return null;
  } else if (value === 'auto') {
    return 'auto';
  } else if (String(value).includes('px') || String(value).includes('%')) {
    return value;
  } else if ((0, _is_present["default"])(value)) {
    return "".concat(value, "px");
  }
}