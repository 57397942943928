import React from 'react';
import { observer } from 'mobx-react';
import CardButton from 'components/card/card_button';
import { Theme } from '@seedlang/constants';
import { CaretRightCircleIcon } from '@seedlang/icons';

@observer
class NextButton extends React.Component {

  static defaultProps = {
    text: 'Next Card',
    backgroundColor: Theme.green,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    iconSize: 20,
    iconColor: '#FFF',
  }

  render() {
    return (
      <CardButton
        submitOnEnter={this.props.submitOnEnter}
        shortcut={this.props.shortcut}
        text={this.props.text}
        backgroundColor={this.props.backgroundColor}
        marginRight={this.props.marginRight}
        marginLeft={this.props.marginLeft}
        icon={<CaretRightCircleIcon />}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      />
    );
  }
}

export default NextButton;
