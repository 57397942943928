import React from 'react';
import { Link } from 'react-router';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${AppUI.layout.isMobile ? 'space-between' : 'center'};
  background: ${props => props.selected ? '#DDD' : '#FFF'};
  font-weight: bold;
  cursor: pointer;
  height: 68px;
  display: flex;
  position: relative;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  padding: 0 10px;
  &:hover {
    background: #DDD;
  }
`;

const SettingsMenuRow = ({ to, children, selected }) => (
  <Link to={to}>
    <Row
      selected={selected}
    >
      {children}
      {
        AppUI.layout.isMobile &&
          <i className='fa fa-chevron-right' />
      }
    </Row>
  </Link>
);

export default SettingsMenuRow;