import React from 'react';
import { observer } from 'mobx-react';
import { DeckUI } from '@seedlang/state';
import { DeckCloseWebWrapper } from '@seedlang/hoc';
import { pixify } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import DeckProgressBar from 'components/deck/deck_progress_bar';
import DeckMenu from 'components/deck/deck_menu';
import { CloseIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import CorrectCardsStatus from 'components/correct_cards_status';
import { flexCenter } from '@seedlang/style_mixins';

const Wrapper = styled.div`
  height: ${props => props.height};
  display: flex;
  flex-direction: row;
  background: ${Theme.darkBlue};
  align-items: center;
`;

const Left = styled.div`
  width: ${props => props.minimumHeight ? 40 : 50}px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  .close-icon {
    width: ${props => props.minimumHeight ? 26 : 30}px;
    svg {
      fill: rgba(255, 255, 255, 0.4);
    }
  }
`;

const Right = styled.div`
  width: ${props => props.minimumHeight ? 30 : 40}px;
  display: flex;
  align-items: center;
  justify-content: center;
  .three-dots-icon {
    margin-top: -2px;
  }
`;

const CorrectCardsStatusWrapper = styled.div`
  height: 30px;
  width: 100%;
  ${flexCenter()}
  padding: 0 5px;
  border-radius: 20px;
  background: rgba(230, 230, 230, 0.6);
`;

@observer
class DeckHeader extends React.Component {

  @autobind onClickClose() {
    if (DeckUI.isLastCard) {
      this.props.onCloseDeck();
    } else {
      DeckUI.showCloseModal = true;
    }
  }

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.deckHeaderHeight)}
        className='deck-header'
      >
        <Left
          minimumHeight={DeckUI.layout.minimumHeight}
        >
          {
            DeckUI.hasDeck && DeckUI.hasUserDeck &&
              <CloseIcon
                onClick={this.onClickClose}
              />
          }
        </Left>
        {
          DeckUI.hasDeck && DeckUI.hasUserDeck && !DeckUI.showCorrectCardStatus &&
            <DeckProgressBar />
        }
        {
          DeckUI.hasDeck && DeckUI.hasUserDeck && DeckUI.correctCardsStatusCards && DeckUI.showCorrectCardStatus &&
            <CorrectCardsStatusWrapper>
              <CorrectCardsStatus
                userCards={DeckUI.correctCardsStatusCards}
                showBackground
                showCorrectColor
                showIncorrectColor
              />
            </CorrectCardsStatusWrapper>
        }
        <Right
          minimumHeight={DeckUI.layout.minimumHeight}
        >
          {
            DeckUI.userIsLoaded && DeckUI.hasDeck && DeckUI.hasUserDeck &&
              <DeckMenu />
          }
        </Right>
      </Wrapper>
    );
  }
}

export default DeckCloseWebWrapper(DeckHeader);
