import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Button from 'components/button/button';
import autobind from 'autobind-decorator';
import { isBlank, isPresent } from '@seedlang/utils';
import { Theme } from '@seedlang/constants';
import { WordStore } from '@seedlang/stores';
import Alert from 'components/alert';

const Wrapper = styled.div`

`;

const Note = styled.div`
  font-size: 13px;
  line-height: 17px;
  background: #FFF;
  padding: 10px;
  margin-bottom: 10px;
`;

@observer
class WordBatchCreate extends React.Component {
  @observable value = '';
  @observable skipped;

  @computed get containsInvalidCharacters() {
    const chars = [':', '!', '?', '/', '#', '$', '%', '€'];
    return chars.find(item => this.value && this.value.indexOf(item) !== -1);
  }

  @computed get disabled() {
    return isBlank(this.value) || this.containsInvalidCharacters;
  }

  @autobind afterSubmit(resp) {
    this.skipped = resp.skipped;
    this.value = '';
  }

  @autobind onSubmit() {
    if (!this.disabled) {
      WordStore.batchCreate({data: {text: this.value}, queryStrings: {force_create: false} }, this.afterSubmit);
    }
  }

  render() {
    return (
      <Wrapper>
        <h3>Batch Words Create</h3>
        {
          isPresent(this.skipped) &&
            <Alert
              margin='0 0 20px 0'
            >
              <b>Couldn't create:</b>
              <div
                dangerouslySetInnerHTML={{ __html: this.skipped }}
              />
            </Alert>
        }
        <textarea
          style={{
            height: 300,
            marginBottom: 10,
          }}
          onChange={e => this.value = e.currentTarget.value}
          value={this.value}
        />
        <div
            style={{display: 'flex'}}
        >
            <Button
                onClick={this.onSubmit}
                disabled={this.disabled}
            >
                Submit Vocab
            </Button>
            <Button
                onClick={() => this.showInput = false}
                disabled={this.disabled}
                background={Theme.red}
                margin='0 0 0 10px'
            >
                Cancel
            </Button>
        </div>

        {
          this.containsInvalidCharacters &&
            <Alert>You have included an invalid character.</Alert>
        }
        <Note>
          Format:<br />
          Noun[gender], Word[Plural], English, English<br />
          Noun[gender], Word[], English, English (for no plural)<br />
          Noun[gender], Word[is_plural], English, English (for is plural)<br />
          Verb, Word, English, English<br />
          Adj, Word, English, English<br />
          Adv, Word, English, English<br />
          Supported Word Types: Adj, Adv, Aux, Cnj, Int, Noun, Num, Phr (phrase), Pnn (proper noun), Prep, Prt (particle), Verb<br />
          <b>No punctuation, use infinitive/non-declined forms.</b><br />
          <br />
          Nouns should apply the gender by using the bracket to insert the first letter of the gender. For example jour[m], persona[f], Mädchen[n]
          <br />
          <b>Please do not use the article with a noun!</b>
        </Note>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordBatchCreate);
