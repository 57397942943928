import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  img {
    border-radius: 50%;
  }
`;

const Images = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

@observer
class SettingsUserImage extends React.Component {

  @computed get user() {
    return this.props.user || AppUI.user;
  }

  render() {
    return (
      <Wrapper>
        <Text
          heading='4'
          margin='0 0 20px 0'
        >
          Profile Image
        </Text>
        <Images>
          <InPlaceImageUpload
            circle
            model='images'
            image={this.user.image}
            onChange={() => AppUI.loadUser()}
            querystring={{
              imageable_id: this.user.id,
              imageable_type: 'User',
            }}
          />
        </Images>
      </Wrapper>
    );
  }
}

export default SettingsUserImage;
