import React from 'react';
import { observer } from 'mobx-react';
import { HelpCenterWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import { Link } from 'react-router';
import { Theme } from '@seedlang/constants';
import FaqHeader from 'components/faq_header';
import { JapaneseCharacterIcon, ClipboardIcon, SeedlangOutlineBoldIcon, PencilWithCircleIcon, TreeIcon, BookmarkIcon, QuestionIcon, SearchWordsIcon } from '@seedlang/icons';
import autobind from 'autobind-decorator';
import { isBlank, pixify } from '@seedlang/utils';
import Spinner from 'components/spinner';
import { sortBy } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow-x: hidden;
`;

const FaqCategory = styled.div`
  width: ${props => props.width};
  margin: ${props => props.margin};
  height: 130px;
  display: flex;
  align-items: center;
  background: white;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  transition: all .1s ease-in-out;
  border-radius: 5px;
  position: relative;
  &:hover {
    transform: scale(1.05);
  }
`;

const Unpublished = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  color: #FFF;
  background: ${Theme.red};
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bold;
  border-radius: 5px;
`;

const CategoryGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: red;
  border: 1px solid black;
`;

const FaqCategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const IconWrapper = styled.div`
  svg {
    fill: ${Theme.blue};
    width: 30px;
    margin-bottom: 5px;
  }
  .pencil-with-circle-icon {
    stroke: ${Theme.blue};
  }
  .bookmark-icon, .japanese-character-icon {
    svg {
      width: 26px;
    }
  }
`;

@observer
class HelpCenter extends React.Component {

  @autobind icon(slug) {
    const ICONS = {
      grammar: <PencilWithCircleIcon />,
      general: <ClipboardIcon />,
      reviews: <BookmarkIcon />,
      stories: <TreeIcon />,
      trivia: <QuestionIcon />,
      membership: <SeedlangOutlineBoldIcon />,
      translator: <JapaneseCharacterIcon />,
      vocab: <SearchWordsIcon />,
    };
    return ICONS[slug] || <TreeIcon />;
  }

  render() {
    return (
      <Wrapper>
        <FaqHeader
          currentRoute='help_center'
          margin='0 0 10px 0'
        />
        {
          isBlank(this.props.faqCategories) &&
            <Spinner
              background={Theme.blue}
            />
        }
        <FaqCategoryWrapper>
          {
            sortBy(this.props.faqCategories.filter(item => item.published || AppUI.user.admin), ['position']).map((faqCategory, index) => {
              return (
                <Link
                  to={{name: 'faq_categories.show', params: {faqCategoryId: faqCategory.id}}}
                  key={faqCategory.id}
                >
                  <FaqCategory
                    key={faqCategory.id}
                    width={pixify(this.props.gridItemWidth)}
                    margin={this.props.gridItemMargins[index]}
                  >
                    {
                      !faqCategory.published &&
                        <Unpublished>
                          UNP
                        </Unpublished>
                    }
                    <IconWrapper>
                      {this.icon(faqCategory.slug)}
                    </IconWrapper>
                    <Text
                      fontSize='20px'
                      lineHeight='24px'
                      bold
                      color='#333'
                    >
                      {faqCategory.name}
                    </Text>
                    <Text
                      fontSize='12px'
                      lineHeight='15px'
                      color='#333'
                      margin='2px 0 0 0'
                    >
                      {faqCategory.faqItemsCount || 0} Topics
                    </Text>
                  </FaqCategory>
                </Link>
              );
            })
          }
        </FaqCategoryWrapper>
      </Wrapper>
    );
  }
}

export default HelpCenterWrapper(HelpCenter);
