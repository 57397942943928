import React from 'react';
import { observer } from 'mobx-react';
import { SentenceBreakdownWordWrapper } from '@seedlang/hoc';
import { pixify } from '@seedlang/utils';
import styled from '@emotion/styled';
import InPlaceTextInput from 'components/form/in_place_text_input';
import { last } from 'lodash';

const Wrapper = styled.span`
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  margin-right: ${props => props.marginRight};
  padding: ${props => props.padding};
  cursor: ${props => props.selectable || props.showUnderline ? 'pointer' : 'default'};
  border-bottom: ${props => props.borderBottom};
  display: inline-flex;
  align-items: center;
  position: relative;
  input {
    text-align: center;
    border-radius: 5px;
    font-size: 18px;
    padding: 4px 5px;
    height: 38px;
    border: ${props => props.inputFocused && !props.inputBackground ? '2px solid #333' : '2px solid rgba(255, 255, 255, 0)'};
    background: ${props => props.inputBackground};
    color: ${props => props.inputBackground ? '#fbfbfb' : '#333'};
    cursor: ${props => props.inputBackground ? 'pointer' : 'default'}
  }
`;

const FakeTextInput = styled.div`
  background: white;
  border: 2px solid #FFF;
  border-radius: 5px;
  height: 38px;
  width: ${props => props.width};
`;

const GapUnderline = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  border-bottom: 2px solid #333;
`;

@observer
class SentenceBreakdownWord extends React.Component {

  render() {
    if (this.props.wordAssociation.word) {
      return (
        <Wrapper
          padding={`0 ${pixify(this.props.paddingHorizontal)}`}
          marginRight={this.props.wordText && last(this.props.wordText) === '\'' ? '0px' : pixify(this.props.marginRight)}
          fontSize={pixify(this.props.fontSize)}
          lineHeight={pixify(this.props.lineHeight)}
          borderBottom={this.props.borderBottom}
          selectable={this.props.selectable}
          showUnderline={this.props.showUnderline}
          onClick={() => this.props.onClick(this.props.wordAssociation)}
          inputFocused={this.props.inputFocused}
          inputBackground={this.props.inputBackground}
        >
          { !this.props.textInput && !this.props.showTextInput && !this.props.showGapUnderline && this.props.wordText && this.props.wordText.normalize('NFC') }
          {
            this.props.showGapUnderline &&
              <GapUnderline
                height={pixify(this.props.fontSize || 20)}
                width={pixify(this.props.wordText ? this.props.wordText.length * 20 : 70)}
              />
          }
          {
            this.props.textInput && this.props.hideTextInput &&
              <FakeTextInput
                width={pixify(40 + (this.props.inputWordLength * 10))}
              />
          }
          {
            this.props.showTextInput &&
              <InPlaceTextInput
                refs='input'
                style={{
                  width: 40 + (this.props.inputWordLength * 10),
                }}
                disabled={this.props.disableInput}
                caretPosition={this.props.wordAssociation.id === this.props.focusedWordAssociationId ? this.props.caretPosition : null}
                id={this.props.wordAssociation.id}
                submitOnEnter
                tabIndex={this.props.index + 1}
                onSubmit={this.props.onSubmit}
                readOnly={this.props.inputCorrect || this.props.inputIncorrect}
                value={this.props.inputIncorrect || this.props.inputCorrect ? this.props.wordText : this.props.inputValue}
                onFocus={() => this.props.onInputFocus(this.props.wordAssociation.id)}
                onChange={val => this.props.onInputChange(val, this.props.wordAssociation.id)}
                focusOnMount={this.props.inputFocused}
              />
          }
        </Wrapper>
      );
    }
    return <span />;
  }
}

export default SentenceBreakdownWordWrapper(SentenceBreakdownWord);
