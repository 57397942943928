import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import { isBlank } from '@seedlang/utils';
import Button from 'components/button/button';
import { Theme } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`

`;

const Label = styled.div`
  font-size: 14px;
  font-weight: bold;
  text-align: left;
`;

const Note = styled.div`
  font-size: 12px;
  line-height: 15px;
  margin-top: 20px;
  color: #333;
  font-style: italic;
  text-align: left;
`;

const ButtonRow = styled.div`
  display: flex;
`;

@observer
class SentenceSubmissionCreate extends React.Component {
  @observable targetText = '';
  @observable sourceText = '';

  @computed get disabled() {
    return isBlank(this.targetText) || isBlank(this.sourceText);
  }

  @autobind onSubmit() {
    AppUI.sentenceSubmissionStore.create({data: {
      targetText: this.targetText,
      sourceText: this.sourceText,
      targetLanguage: AppUI.targetLanguageId,
      sourceLanguage: 'EN',
      userId: AppUI.user.id,
      wordId: this.props.wordId,
    }}, this.afterCreate);
  }

  @autobind afterCreate() {
    this.onReset();
    this.props.afterCreate();
  }

  @autobind onReset() {
    this.targetText = '';
    this.sourceText = '';
  }

  render() {
    return (
      <Wrapper>
        <h4>Submit a Sentence</h4>
        <Label>Target Sentence</Label>
        <input
          type='text'
          value={this.targetText}
          onChange={e => this.targetText = e.currentTarget.value}
          style={{marginBottom: 10}}
        />
        <Label>English Translation</Label>
        <input
          type='text'
          value={this.sourceText}
          onChange={e => this.sourceText = e.currentTarget.value}
        />
        <ButtonRow>
          <Button
            margin='20px 5px 0 0'
            onClick={this.onSubmit}
            background={Theme.green}
            disabled={this.disabled}
          >
            Submit
          </Button>
          <Button
            margin='20px 0 0 0'
            onClick={this.onReset}
            background={Theme.red}
            disabled={this.disabled}
          >
            Reset
          </Button>
        </ButtonRow>
        <Note>
          Seedlang staff will evaluate sentence submissions. Those chosen will be corrected (if necessary) and videos will be recorded. The submissions are only seen by you until they are approved. Please be creative with your sentence submissions!
        </Note>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SentenceSubmissionCreate);
