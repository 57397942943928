import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Motion, spring, presets } from 'react-motion';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import { DeckUI, AppUI } from '@seedlang/state';
import { LightningBoltIcon } from '@seedlang/icons';
import Text from 'components/text';
import Button from 'components/button/button';
import { isPresent } from '@seedlang/utils/src';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  z-index: ${Theme.z['topmost']};
  background: ${props => props.background};
  color: ${props => props.color};
  border: ${props => props.border};
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 340px;
  top: ${DeckUI.layout.deckHeaderHeight + 20}px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  cursor: pointer;
`;

const Subtext = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: normal;
`;

const InnerWrapper = styled.div`
  width: calc(100% - 5px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px dotted #f78139;
  margin: 2px 0;
  padding: 10px;
  border-radius: 5px;
  flex-direction: column;
`;

const IconWrapper = styled.div`
  margin-right: 10px;
  .lightning-bolt-icon {
    svg {
      width: 18px;
      fill: ${Theme.orange};
    }
  }
`;

// const STIFFNESS = 210;
// const DAMPING = 20;

@observer
class CardNotice extends React.Component {
  @observable scale = 0;

  static defaultProps = {
    background: Theme.orange,
    color: '#FFF',
  }

  constructor(props) {
    super(props);
    if (this.props.chime) {
      AppUI.soundEffectStore.play('chime');
    }
  }

  componentDidMount() {
    this.scale = 1;
  }

  render() {
    return (
      <Motion
        style={{
          scale: spring(this.scale, presets.wobbly),
        }}
      >
        {
          ({scale}) =>
            <Wrapper
              color={this.props.color}
              background={this.props.background}
              border={this.props.border}
              style={{
                transform: `scale(${scale})`,
              }}
              onClick={() => DeckUI.hideCardNotice()}
            >
              <InnerWrapper>
                {
                  this.props.icon &&
                    <IconWrapper>
                      {
                        this.props.icon === 'celebration' &&
                          <Text
                            fontSize='20px'
                          >
                            <span role='img' aria-label='celebration'>🎉</span>
                          </Text>
                      }
                      {
                        this.props.icon === 'streak' &&
                          <LightningBoltIcon />
                      }
                    </IconWrapper>
                }
                <div dangerouslySetInnerHTML={{ __html: this.props.text }} />
                {
                  isPresent(this.props.subText) &&
                    <Subtext>{this.props.subText}</Subtext>
                }
                {
                  this.props.buttonText &&
                    <Button
                      margin='10px 0 0 0'
                      fontSize='13px'
                      onClick={this.props.onClick}
                    >
                      {this.props.buttonText}
                    </Button>
                }
              </InnerWrapper>
            </Wrapper>
        }
      </Motion>
    );
  }
}

export default CardNotice;
