import React from 'react';
import PropTypes from 'prop-types';
import { last } from 'lodash';
import styled from '@emotion/styled';
import { observable } from 'mobx';
import InfoTooltip from 'components/info_tooltip';

const Wrapper = styled.label`
  text-transform: capitalize;
`;

class FormLabel extends React.Component {
  @observable id = Math.random();

  static propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    required: PropTypes.bool,
  };

  label() {
    if (this.props.label) {
      return this.props.label;
    }
    return this.props.name;
  }

  render() {
    return (
      <Wrapper
        htmlFor={this.props.htmlFor}
      >
        {last(this.label().split('.')).replace('_', ' ')}
        {
          this.props.toolTip &&
            <InfoTooltip>
              {this.props.toolTip}
            </InfoTooltip>
        }
      </Wrapper>
    );
  }
}

export default FormLabel;
