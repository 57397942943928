import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import { isPresent, isBlank } from '@seedlang/utils/src';
import { Link } from 'react-router';
import GoProButton from 'components/button/go_pro_button';
import SettingsBillingMigrationSection from 'components/user/settings_billing_migration_section';
import { last } from 'lodash';
import WhitelabelLogo from 'components/whitelabel_logo';
import Button from 'components/button/button';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  text-align: center;
  height: 100%;
  overflow: auto;
  justify-content: center;
`;

const Content = styled.div`
`;

const LogoWrapper = styled.a`
  margin: 10px 0 20px 0;
`;

const InfoLinks = styled.div`
  font-size: 12px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: ${props => props.fontWeight};
  margin-bottom: 10px;
`;

@observer
class OnboardingWhitelabelMigration extends React.Component {

  @computed get membershipTypeName() {
    return AppUI.user?.currentUserGroup?.membershipType?.name;
  }

  // Note: the spinner is displayed in SettingsBillingMigrationSection

  render() {
    return (
      <Wrapper
        className='onboarding-migration'
      >
          {
            isPresent(this.membershipTypeName) &&
              <Content>
                <LogoWrapper href='/'>
                  <WhitelabelLogo
                    image={AppUI.site?.image}
                  />
                </LogoWrapper>
                {
                  AppUI.routeStore.routeName !== 'onboarding.migration' &&
                    <>
                      <h2>You're all set!</h2>
                      <Text>Your existing <span style={{fontWeight: 'bold'}}>{this.membershipTypeName}</span> perks are now available.</Text>
                    </>
                }
                {
                  isPresent(AppUI.user.paymentMigration) && AppUI.user.paymentMigration?.status === 'setting_up' &&
                    <div style={{marginTop: '15px', fontStyle: 'italic'}}>Migrate Your Payment Method?</div>
                }
                <SettingsBillingMigrationSection
                  onboarding
                  hideContactLink
                  hasActiveSubscription={last(AppUI.userSubscriptionStore.indexData.filter(item => item.membership.membershipTypeId === AppUI.user.currentGroupMembershipType.id && item.isActive))}
                />
                {
                  isBlank(AppUI.user.paymentMigration) &&
                    <Button
                      background={AppUI.site.accentColor}
                      margin='10px 0 0 0'
                      onClick={AppUI.routeToSignedInHome}
                    >
                      Go to Your Perks
                    </Button>
                }
                {
                  isPresent(AppUI.user.paymentMigration) && ['waiting_for_patreon_expiry', 'waiting_for_patreon_refund', 'waiting_for_patreon_refund_timeout', 'completed'].indexOf(AppUI.user.paymentMigration.status) !== -1 &&
                  <Button
                    background={AppUI.site.accentColor}
                    margin='10px 0 0 0'
                    onClick={AppUI.routeToSignedInHome}
                  >
                    Return to Home Page
                  </Button>
                }
                {
                  isPresent(AppUI.user.paymentMigration?.status) &&
                    <InfoLinks>
                      {
                        isPresent(AppUI.site.paymentMigrationConfig.infoUrl) &&
                        <a target='_blank' href={AppUI.site.paymentMigrationConfig.infoUrl} className='underline'>
                          Learn More About Our Migration Process
                        </a>
                      }
                      {
                        AppUI.user.paymentMigration?.status !== 'waiting_for_patreon_expiry' &&
                          <Link to={{name: AppUI.site.signedInHomePageRoute}} onlyActiveOnIndex className='underline'>
                            Come Back Later
                          </Link>
                      }
                    </InfoLinks>
                }
              </Content>
          }
          {
            isBlank(this.membershipTypeName) &&
              <Content>
                <LogoWrapper href='/'>
                  <WhitelabelLogo
                    image={AppUI.site?.image}
                  />
                </LogoWrapper>
                <Text>You currently do not have a Patreon membership.</Text>
                <Link
                  to={{name: 'memberships.index'}}
                 onlyActiveOnIndex
                >
                  <GoProButton
                    height='50px'
                    background={AppUI.site?.accentColor}
                  >
                    <div className='text'>
                      <span>Become a Member</span>
                    </div>
                  </GoProButton>
                </Link>
                <InfoLinks>
                  <Link to={{ name: AppUI.site.signedInHomePageRoute }} onlyActiveOnIndex className='underline'>Go Home</Link>
                </InfoLinks>
              </Content>
          }
      </Wrapper>
    );
  }
}

export default OnboardingWhitelabelMigration;
