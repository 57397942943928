import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import { RouteStore, WorksheetStore } from '@seedlang/stores';
import { isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Alert from 'components/alert';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const WarningMessage = styled(Alert)`
  font-size: 12px;
  padding: 2px;
  background: #f2dede;
  color: black;
  margin-top: 5px;
`;

@observer
class WorksheetCreate extends React.Component {
  @observable name = '';
  @observable showWarning = false;

  @computed get warningMessage() {
    const teamName = AppUI.site.teamName || AppUI.site.name?.replace('Membership', '');
    if (!AppUI.siteIsDefault && this.name && this.name.match(new RegExp(`${teamName}\\s*\\d+`, 'i'))) {
      const episodeCode = `${this.name?.match('Super') ? 'S' : ''}${AppUI.site.teamInitials} ${this.name?.match(/\d+/)?.[0] || '93'}`;
      return `Use this format for episode names to keep post names concise: "(${episodeCode})". This episode code will be displayed in post tags and will be searchable.`;
    }
    return null;
  }

  @autobind onChange(e) {
    this.name = e.target.value;
    this.showWarning = !isBlank(this.warningMessage);
  }

  @autobind onClick() {
    WorksheetStore.create(
      {
        data: {
          name: this.name,
          group_id: this.props.groupId,
        },
      },
      this.afterCreate,
    );
    this.name = '';
    this.showWarning = false;
  }

  @autobind afterCreate(resp) {
    RouteStore.routeToNamed('creator.groups.worksheets.edit', {groupId: this.props.groupId, worksheetId: resp.id});
  }

  render() {
    return (
      <div
        className='concept-category-create row'
        style={{marginBottom: 10}}
      >
        <div className='col-xs-10'>
          <input
            placeholder='Enter Post Name'
            value={this.name}
            onChange={this.onChange}
          />
          {this.showWarning && <WarningMessage>{this.warningMessage}</WarningMessage>}
        </div>
        <div className='col-xs-2'>
          <button
            type='button'
            style={{width: '100%'}}
            className='button-primary'
            onClick={this.onClick}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default WorksheetCreate;
