import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import DailyActivity from 'components/daily_activity/daily_activity';
import { StreakIcon } from '@seedlang/icons';
import ErrorBoundary from 'components/hoc/error_boundary';
import styled from '@emotion/styled';
import HeaderPopup from 'components/header_popup';

const Wrapper = styled.div`

`;

const CalendarWrapper = styled.div`
  margin: 20px;
`;

@observer
class StreakWithPopup extends React.Component {

  render() {
    return (
      <Wrapper>
        <HeaderPopup
          height={AppUI.layout.isMobile ? AppUI.layout.mobileContentHeight : null}
          title='My Activity'
          background={this.props.background}
          arrowRight={AppUI.layout.isMobile ? 17 : 56}
          right='-50px'
          width={AppUI.layout.isMobile ? '100%' : '255px'}
          onClickIcon={() => AppUI.toggleStreakPopupOpened()}
          popupOpened={AppUI.streakPopupOpened}
          icon={
            <StreakIcon
              user={AppUI.user}
              color='rgba(255, 255, 255, 0.8)'
              width={AppUI.layout.isMobile ? '10px' : '13px'}
              top={AppUI.layout.isMobile ? '3px' : 0}
              fontSize={AppUI.layout.isMobile ? '10px' : '12px'}
            />
          }
        >
          <CalendarWrapper>
            <DailyActivity
              store={AppUI.authUserDailyXpCountStore}
              dailyXpGoal={AppUI.user?.dailyXpGoal}
              userId={AppUI.user?.id}
              todaysDate={AppUI.user?.todaysDate}
            />
          </CalendarWrapper>
        </HeaderPopup>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(StreakWithPopup);
