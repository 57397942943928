import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import { Motion, spring } from 'react-motion';

import DeckSummaryCard from 'components/card/deck_summary_card';
import TriviaIntroCard from 'components/trivia_card/trivia_intro_card';
import ResultsCard from 'components/card/results_card';
import TriviaResultsCard from 'components/trivia_card/trivia_results_card';
import TriviaCard from 'components/trivia_card/trivia_card';
import TriviaTestCard from 'components/trivia_card/trivia_test_card';
import CheckpointCard from 'components/card/checkpoint_card';
import SentenceTranslateCard from 'components/vocab_card/sentence_translate_card';
import ConjugationCard from 'components/conjugation_card/conjugation_card';
import GenderCard from 'components/gender_card/gender_card';
import PluralCard from 'components/plural_card/plural_card';
import ExerciseCard from 'components/card/exercise_card';
import UserSubmissionCard from 'components/card/user_submission_card';
import WordComprehendCard from 'components/vocab_card/word_comprehend_card';
import WordOptionsCard from 'components/vocab_card/word_options_card';
import WordTranslateCard from 'components/vocab_card/word_translate_card';
import SentenceComprehendCard from 'components/vocab_card/sentence_comprehend_card';
import TranslateCard from 'components/story_card/translate_card';
import DescribeCard from 'components/story_card/describe_card';
import AnswerCard from 'components/story_card/answer_card';
import DeckGrammarCard from 'components/card/deck_grammar_card';
import InterjectionCard from 'components/story_card/interjection_card';
import ComprehendCard from 'components/story_card/comprehend_card';
import ConjugationTableCard from 'components/conjugation_card/conjugation_table_card';
import MinimalPairCard from 'components/vocab_card/minimal_pair_card';
import WordPronunciationCard from 'components/vocab_card/word_pronunciation_card';
import SentencePronunciationCard from 'components/vocab_card/sentence_pronunciation_card';
import NumberCard from 'components/number_card/number_card';

const CARDS = {
  'deck_summary': DeckSummaryCard,
  'trivia': TriviaCard,
  'trivia-test': TriviaTestCard,
  'trivia-intro': TriviaIntroCard,
  'trivia-results': TriviaResultsCard,
  'gender': GenderCard,
  'gender-results': ResultsCard,
  'plural-results': ResultsCard,
  'conjugation-results': ResultsCard,
  'conjugation-table': ConjugationTableCard,
  'plural': PluralCard,
  'number_options': NumberCard,
  'number_translate': WordTranslateCard,
  'number-results': ResultsCard,
  'exercise': ExerciseCard,
  'deck_grammar': DeckGrammarCard,
  'vocab_word_comprehend': WordComprehendCard,
  'sound_comprehend': WordComprehendCard,
  'vocab_word_options': WordOptionsCard,
  'vocab_word_translate': WordTranslateCard,
  'vocab_sentence_comprehend': SentenceComprehendCard,
  'vocab_sentence_translate': SentenceTranslateCard,
  'record_description': DescribeCard,
  'record_answer': AnswerCard,
  'conjugation': ConjugationCard,
  'conjugation-multiple-choice': ConjugationCard,
  'conjugation-fill-in-blanks': ConjugationCard,
  'checkpoint_card': CheckpointCard,
  'translate': TranslateCard,
  'interjection': InterjectionCard,
  'comprehend': ComprehendCard,
  'user_submission': UserSubmissionCard,
  'minimal_pair': MinimalPairCard,
  'sound_minimal_pair': MinimalPairCard,
  'word_pronounce': WordPronunciationCard,
  'sound_pronounce': WordPronunciationCard,
  'sentence_pronounce': SentencePronunciationCard,
};

const Wrapper = styled.div`
  top: ${DeckUI.layout.deckHeaderHeight};
  z-index: 1;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const STIFFNESS = 390;
const DAMPING = 30;

@observer
class CardAnimationWrapper extends React.Component {

  @computed get x() {
    return this.props.animateCard ? spring(this.translateX(this.props.position - 1), {stiffness: STIFFNESS, damping: DAMPING}) : this.translateX(this.props.position);
  }

  @autobind translateX(position) {
    return DeckUI.layout.deckWidth * position;
  }

  render() {
    return (
      <Motion
        style={{
          x: this.x,
        }}
      >
        {
          ({x}) => {
            return (
              <Wrapper
                style={{
                  WebkitTransform: `translate3d(${x}px, 0px, 0)`,
                  transform: `translate3d(${x}px, 0px, 0)`,
                  visibility: !DeckUI.user.displayDeckDebugInfo && x === this.translateX(1) ? 'hidden' : 'visible', // protects again tabbing to hidden card
                }}
              >
                {
                   React.createElement(CARDS[this.props.card.cardType.slug],
                    {
                      card: this.props.card,
                      userCard: this.props.userCard,
                      cardState: this.props.cardState,
                      animating: this.animating,
                      inView: this.props.position === 0,
                      triadWordId: this.props.triadWordId,
                      fromGrammarSection: this.props.fromGrammarSection,
                    },
                  )
                }
              </Wrapper>
            );
          }
        }
      </Motion>
    );
  }
}

export default CardAnimationWrapper;
