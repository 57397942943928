import React from 'react';
import { observer } from 'mobx-react';
import { action, computed, observable } from 'mobx';
import { SparkleIcon } from '@seedlang/icons';
import { random, times } from 'lodash';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  background: #07314e;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  bottom: 180px;
  .sparkle-icon-wrapper {
    pointer-events: none;
    width: 18px;
    position: fixed;
    top: 50px;
    right: 90px;
    z-index: 1;
    svg {
      fill: yellow;
    }
  }
  .pyro > .before, .pyro > .after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
    animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards; }

  .pyro > .after {
    animation-delay: 1.25s, 1.25s, 1.25s;
    animation-duration: 1.25s, 1.25s, 6.25s; }

  @keyframes bang {
    to {
      box-shadow: 224px -198.66667px #fbff00, 83px -142.66667px #ff00a6, 137px -166.66667px #ff7700, -101px -302.66667px #9dff00, -171px -186.66667px #ff003c, -200px 74.33333px #a600ff, -180px -299.66667px #00ff04, 138px -237.66667px #00ff6f, -200px -280.66667px #00ff84, -37px -382.66667px #fb00ff, 143px -143.66667px #001aff, -111px -81.66667px #2bff00, -106px 3.33333px #4000ff, 189px -255.66667px #ff0011, 21px -307.66667px #4dff00, 103px -345.66667px #00ff3c, 113px -319.66667px #00ff77, -102px -144.66667px #00ffee, -239px -350.66667px #b300ff, -208px -124.66667px #ff00f7, 36px 26.33333px #00c8ff, 248px -320.66667px #6a00ff, 65px -412.66667px #00d0ff, -200px -334.66667px #ff00a2, -111px -195.66667px #ff9500, -203px 26.33333px #ff0033, 71px -403.66667px #09ff00, -165px -374.66667px #002fff, -17px -108.66667px #ff0099, 119px -358.66667px #00ff2b, 18px -249.66667px #0022ff, -32px -371.66667px #00ff77, -182px -188.66667px #6a00ff, -164px -160.66667px #00aaff, -193px 0.33333px #ff6a00, -34px -158.66667px #00fff2, 158px -161.66667px #00b7ff, 199px -212.66667px #ffd500, -132px -57.66667px #ff00f7, 80px -391.66667px #ff00e1, -175px -371.66667px #2b00ff, -143px -412.66667px #00fff7, 191px -337.66667px #00ffe1, -25px -196.66667px #22ff00, -71px -390.66667px #00a6ff, -145px -69.66667px #ffd500, -77px -292.66667px #e600ff, -107px -127.66667px #00ff99, 57px -338.66667px #00c4ff, 145px 78.33333px #59ff00, -177px -77.66667px #b3ff00; } }

  @keyframes gravity {
    to {
      transform: translateY(200px);
      -moz-transform: translateY(200px);
      -webkit-transform: translateY(200px);
      -o-transform: translateY(200px);
      -ms-transform: translateY(200px);
      opacity: 0; } }

  @keyframes position {
    0%, 19.9% {
      margin-top: 10%;
      margin-left: 40%; }
    20%, 39.9% {
      margin-top: 40%;
      margin-left: 30%; }
    40%, 59.9% {
      margin-top: 20%;
      margin-left: 70%; }
    60%, 79.9% {
      margin-top: 30%;
      margin-left: 20%; }
    80%, 99.9% {
      margin-top: 30%;
      margin-left: 80%; } }
`;

const Gradient = styled.div`
  background: ${props => props.background};
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 0;
  left: 0;
  right: 0;
  bottom: 180px;
`;

const StarsWrapper = styled.div`

`;

const NUM_STARS = 100;

@observer
class Sky extends React.Component {
  @observable starCoordinates = [];

  constructor(props) {
    super(props);
    this.plotStars();
  }

  @computed get isLastCard() {
    return this.props.isLastCard || this.props.percentageComplete === 100;
  }

  @computed get blueRange() {
    return this.rangeVsDeckProgress(30, 80);
  }

  @computed get blueOpacity() {
    return this.rangeVsDeckProgress(0.3, 1);
  }

  @computed get orangeRange() {
    return this.rangeVsDeckProgress(0, 60);
  }

  @computed get orangeOpacity() {
    return this.rangeVsDeckProgress(0.1, 0.6);
  }

  @computed get sunY() {
    return this.isLastCard ? 780 : 1200;
  }

  @computed get starOpacity() {
    if (this.props.bigStars) {
      return 1;
    } else if (this.isLastCard) {
      return 0;
    }
    return 0.4;
  }

  @computed get starScale() {
    return this.props.bigStars ? 1 : 0.6;
  }

  @autobind rangeVsDeckProgress(min, max) {
    return min + ((max - min) * (this.props.percentageComplete / 100));
  }

  @autobind @action plotStars() {
    this.starCoordinates = [];
    times(NUM_STARS, num => {
      this.starCoordinates.push({
        left: random(0, 100),
        top: random(0, 55),
      });
    });
  }

  render() {
    return (
      <div>
        <Wrapper>
          {
            this.props.isLastCard &&
              <div className='pyro'>
                <div className='before'></div>
                <div className='after'></div>
              </div>
          }
          <StarsWrapper>
            {
              this.starCoordinates.map((coord, index) => {
                return (
                  <SparkleIcon
                    className='sparkle-icon-wrapper'
                    key={index}
                    style={{
                      left: `${coord.left}%`,
                      top: `${coord.top}%`,
                      transform: `scale(${this.starScale})`,
                      opacity: this.starOpacity,
                    }}
                  />
                );
              })
            }
          </StarsWrapper>
          <Gradient
            background='linear-gradient(to top, rgba(17, 114, 212, 0.15) 0%, rgba(17, 114, 212, 0) 30%)'
          />
          {
            this.isLastCard &&
              <Gradient
                background='linear-gradient(to top, rgba(249, 176, 12, 0.3) 0%, rgba(249, 188, 12, 0) 30%)'
              />
          }
        </Wrapper>
      </div>
    );
  }
}

export default Sky;
