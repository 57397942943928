import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { AppUI , LoadingState } from '@seedlang/state';
import Button from 'components/button/button';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';
import { EarlyAccessStore } from '@seedlang/stores';
import Spinner from 'components/spinner';
import Text from 'components/text';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  z-index: 1;
  border-radius: 20px;
  margin: 80px 20px 20px;
  max-width: 500px;
  background: #FFF;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 5px solid #CCC;
  p {
    text-decoration: center;
  }

`;

const ButtonRow = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
`;

@observer
class Access extends React.Component {
  loadingState = new LoadingState();

  token = null;

  componentDidMount() {
    this.token = this.props.params.token;
    this.validateToken();
  }

  async validateToken() {
    this.loadingState.started();
    try {
      await EarlyAccessStore.validateTokenPromise(this.token);
      AppUI.cookieStore.setCookieValue('early-access-token', this.token);
      this.loadingState.succeeded();
    } catch (error) {
      const message = error.failed && error.message ? error.message : 'An error occurred while validating the link.';
      this.loadingState.failed(message);
    }
  }

  render() {
    return (
      <Wrapper>
        <Container>
          {
            this.loadingState.isLoading &&
              <Spinner background={AppUI.site.accentColor || Theme.blue} />
          }
          {
            this.loadingState.errorMessage && (
              <Text color='red'>{this.loadingState.errorMessage}</Text>
            )
          }
          {
            this.loadingState.isSuccess && (
              <>
                <Text center>
                  Welcome to {AppUI.site.nameWithoutMembership}!
                </Text>

                <Text center>
                  You now have early access to the site.
                </Text>

                <ButtonRow>
                  <Link
                    to={{name: 'memberships.index'}}
                    style={{width: '100%'}}
                  >
                    <Button
                      margin='0'
                      width='100%'
                      background={AppUI.site.accentColor}
                    >
                      Continue
                    </Button>
                  </Link>
                </ButtonRow>
              </>
            )
          }
        </Container>
      </Wrapper>
    );
  }
}

export default Access;
