import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { pixify } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;

const NarrowColumn = styled.div`
  width: ${props => props.width};
  margin-left: ${props => props.leftSidebar ? 0 : '10px'};
  margin-right: ${props => props.leftSidebar ? '10px' : 0};
  margin-bottom: 10px;
`;

const LargeColumn = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
`;

@observer
class LearnLayout extends React.Component {

  render() {
    return (
      <Wrapper
        isMobile={AppUI.layout.isMobile}
      >
        {
          this.props.leftSidebar && AppUI.layout.hasRightColumn &&
            <NarrowColumn
              width={pixify(AppUI.layout.rightColumnWidth)}
              leftSidebar={true}
            >
              {this.props.children[1]}
            </NarrowColumn>
        }
        <LargeColumn>
          {this.props.children[0]}
        </LargeColumn>
        {
          !this.props.leftSidebar && AppUI.layout.hasRightColumn &&
            <NarrowColumn
              width={pixify(AppUI.layout.rightColumnWidth)}
            >
              {this.props.children[1]}
            </NarrowColumn>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(LearnLayout);
