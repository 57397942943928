import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { SentenceStore } from '@seedlang/stores';
import TypeAhead from 'components/type_ahead';
import { isEmpty } from 'lodash';

@observer
class SentenceSearch extends React.Component {

  static defaultProps = {
    searchByField: 'target_text',
    placeholder: 'Search for Sentence',
  }

  @computed get store() {
    return this.props.store || SentenceStore;
  }

  componentDidMount() {
    this.store.setSort(this.props.searchByField);
    this.store.setFilter('language_id', this.props.languageId || 'DE');
  }

  componentWillUnmount() {
    this.onClear();
  }

  onKeyUp(value) {
    if (isEmpty(value)) {
      this.onClear();
    } else {
      this.store.setFilter(this.props.searchByField, `~${value}~`, { refresh: true });
    }
  }

  onSelect(obj) {
    this.props.onSubmit(obj);
  }

  onClear() {
    this.store.clearIndexData();
    this.store.clearFilter();
  }

  render() {
    return (
      <div className='sentence-search'>
        <TypeAhead
          onKeyUp={this.onKeyUp.bind(this)}
          onSelect={this.onSelect.bind(this)}
          onClear={this.onClear.bind(this)}
          options={this.store.indexData}
          displayField='targetText'
          placeholder={this.props.placeholder}
        />
      </div>
    );
  }
}

export default SentenceSearch;
