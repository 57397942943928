import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import UserProfileImage from 'components/user/user_profile_image';
import { flexCenterColumn } from '@seedlang/style_mixins';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`
  flex: 1;
`;

const Content = styled.div`
  display: flex;
`;

const ImageWrapper = styled.div`
  ${flexCenterColumn()}
  margin-right: 5px;
`;

const Points = styled.div`
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  color: white;
`;

@observer
class GamesPlayedPlayer extends React.Component {

  render() {
    return (
      <Wrapper
        data-tip
        data-for={this.props.id}
      >
        <Content>
          <ImageWrapper>
            <UserProfileImage
              clickToProfile
              user={this.props.user}
            />
          </ImageWrapper>
          <Points>
            {this.props.points}
          </Points>
        </Content>
        <ReactTooltip
          place='top'
          type='dark'
          effect='solid'
          id={this.props.id}
          class='custom-tooltip'
        >
          {this.props.user && this.props.user.name}
        </ReactTooltip>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GamesPlayedPlayer);
