import styled from '@emotion/styled';
import { isBlank } from '@seedlang/utils';
import Spinner from 'components/spinner';
import { observer } from 'mobx-react';
import React from 'react';

const commonBannerStyles = `
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  min-height: 56px;
`;

const LiveBanner = styled.div`
  background-color: #adf;
  ${commonBannerStyles}
`;
const DirtyBanner = styled.div`
  background-color: #f7C797;
  ${commonBannerStyles}
`;
const DraftBanner = styled.div`
  background-color: #DDD;
  ${commonBannerStyles}
`;
const BannerText = styled.div`
  flex: 1;
`;
const SpinnerBanner = styled.div`
  ${commonBannerStyles}
  align-items: center;
  justify-content: center;
`;

@observer
class PodcastEpisodePublicationStateBanner extends React.Component {
  render() {
    const { publicationState } = this.props;

    if (this.props.showSpinner) {
      return (
        <SpinnerBanner>
          <Spinner margin={0} />
        </SpinnerBanner>
      );
    }

    if (isBlank(publicationState)) {
      return null;
    }

    if (publicationState === 'dirty') {
      return (
        <DirtyBanner>
          <BannerText>This episode contains changes that require the updating of the RSS feeds.</BannerText>
          <button className='button-primary' onClick={this.props.onPublishChanges}>Publish changes</button>
        </DirtyBanner>
      );
    }

    if (publicationState === 'live') {
      return (
        <LiveBanner>Live</LiveBanner>
      );
    }

    if (publicationState === 'draft') {
      return (
        <DraftBanner>Draft - set a publication date & time to publish or schedule</DraftBanner>
      );
    }

    if (publicationState === 'scheduled') {
      return (
        <LiveBanner>Scheduled to go live at {this.props.publishedAt.formatted}</LiveBanner>
      );
    }

    return (
      <DirtyBanner>Invalid state. Please contact the developers.</DirtyBanner>
    );
  }
}

export default PodcastEpisodePublicationStateBanner;