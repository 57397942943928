import React from 'react';
import { observer } from 'mobx-react';
import InPlaceText from 'components/form/in_place_text';
import { sortBy } from 'lodash';

@observer
class AiServicesPriorityOrder extends React.Component {

  render() {
    return (
      <div>
        <h3>Order of AI Services for {this.props.language.name} {this.props.translationType}s</h3>
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th>Pos</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {
              sortBy(this.props.language.languageAiServices.filter(item => item.translationType === this.props.translationType), 'position').map((item, index) => {
                return (
                  <tr key={item.id}>
                  <td>
                    <InPlaceText
                      defaultValue={item.position}
                      model='language_ai_services'
                      field='position'
                      id={item.id}
                      afterChange={this.props.afterChange}
                    />
                  </td>
                  <td>{item.aiService.model}</td>
                </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    );
  }
}

export default AiServicesPriorityOrder;