import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Dropdown from 'components/dropdown';
import RadioButton from 'components/radio_button';
import { AppUI } from '@seedlang/state';
import Modal from 'components/modal';
import { observable } from 'mobx';
import autobind from 'autobind-decorator';
import Text from 'components/text';
import { isBlank, isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #FFF;
  border: 3px solid #CCC;
  border-radius: 5px;
  height: 46px;
  width: ${props => props.width};
  margin: ${props => props.margin};
  justify-content: space-between;
`;

const SortLabel = styled.div`
  width: 40px;
  padding-left: 10px;
  font-weight: bold;
  margin-right: 5px;
  cursor: pointer;
  color: #333;
  font-size: 12px;
`;

const SortIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  margin-right: 5px;
  cursor: pointer;
  font-size: 16px;
  .fa {
    color: #333;
  }
`;

const SortTag = styled.div`
  color: #353535;
  margin: 0 7px 0 0;
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  .fa {
    margin-right: 5px;
  }
`;

const SortOption = styled.div`
  display: flex;
  align-items: center;
  padding: 2px 5px 2px 2px;
  font-size: 12px;
`;

const ModalContent = styled.ul`
  margin: 20px;
`;

@observer
class SortSelector extends React.Component {

  @observable showModal = false;

  @autobind onClickSelector() {
    if (AppUI.layout.isMobile) {
      this.showModal = true;
    }
  }

  static defaultProps = {
    margin: '10px 0 0 0',
  }
  render() {
    return (
      <Wrapper
        width={this.props.width}
        margin={this.props.margin}
      >
        {
          this.showModal &&
            <Modal
              top='200px'
              width='300px'
              height='200px'
              onCloseModal={() => this.showModal = false}
              gradient={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor)}
              background={!AppUI.siteIsDefault && isPresent(AppUI.site.accentColor) ? AppUI.site.accentColor : null}
              padding='0'
              title='Sort words'
              contentShouldScroll
            >
              <ModalContent>
                {
                  this.props.sortOptions.map(item => {
                    return (
                      <li
                        key={item.label}
                        onClick={() => this.props.onSetSort(item.value)}
                      >
                        <SortOption>
                          <RadioButton
                            margin='0 5px 0 5px'
                            value={this.props.selectedSortLabel === item.label}
                            width='14px'
                            height='14px'
                          />
                          <Text
                            fontSize={14}
                            lineHeight={18}
                          >
                            {item.label}
                          </Text>
                        </SortOption>
                      </li>
                    );
                  })
                }
              </ModalContent>
            </Modal>
        }
        {
          AppUI.layout.isMobile &&
            <SortLabel
              onClick={this.onClickSelector}
            >
              Sort
            </SortLabel>
        }
        {
          !AppUI.layout.isMobile &&
          <Dropdown
            showOnClick
            top={null}
            right={null}
            textAlign='start'
            fontSize='12px'
            backgroundOnHover='#efefef'
            colorOnHover='#353535'
            linkWidth='100%'
            linkOverflow='hidden'
          >
            <div style={{display: 'flex'}}>
              <SortLabel>
                Sort
              </SortLabel>
              <SortTag>
                {this.props.selectedSortLabel}
              </SortTag>
            </div>
            {
              this.props.sortOptions.length > 1 &&
              <ul>
                {
                  this.props.sortOptions.map(item => {
                    return (
                      <li
                        key={item.label}
                        onClick={() => this.props.onSetSort(item.value)}
                      >
                        <SortOption>
                          <RadioButton
                            margin='0 5px 0 5px'
                            value={this.props.selectedSortLabel === item.label}
                            width='14px'
                            height='14px'
                          />
                          {item.label}
                        </SortOption>
                      </li>
                    );
                  })
                }
              </ul>
            }
          </Dropdown>
        }
        <SortIconWrapper
          onClick={this.props.onToggleSort}
        >
          <i className={`fa fa-arrow-${this.props.sortOrder}`} />
        </SortIconWrapper>
      </Wrapper>
    );
  }
}

export default SortSelector;
