"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = kFormatter;
function kFormatter(num) {
  if (num > 999999) {
    return "".concat((num / 1000000).toFixed(2), "m");
  } else if (num > 999) {
    return "".concat((num / 1000).toFixed(1), "k");
  }
  return num;
}