import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import CardSection from 'components/vocab_card/card_section';
import WordEntry from 'components/word_entry';
import TranslateButtons from 'components/vocab_card/translate_buttons';
import { DeckUI } from '@seedlang/state';
import HintWarning from 'components/card/hint_warning';
import { Theme } from '@seedlang/constants';
import {NumberCardWrapper} from '@seedlang/hoc';
import MediaSection from 'components/vocab_card/media_section';
import ExpandedTarget from 'components/vocab_card/expanded_target';
import { flexCenter } from '@seedlang/style_mixins';
import TextResizer from 'components/text_resizer';
import Text from 'components/text';
import { isBlank } from '@seedlang/utils/src';
import Answer from 'components/vocab_card/answer';

@observer
class NumberCardTextEntry extends React.Component {

  render() {
    return (
      <CardWrapper
        card={this.props.card}
        cardState={this.props.cardState}
        userCard={this.props.userCard}
        icons={this.props.icons}
      >
        <CardContent>
            <MediaSection
              showMedia
              card={this.props.card}
              cardState={this.props.cardState}
              autoPlay={this.props.inView}
              borderTopRadius={10}
              height={this.props.sectionHeight}
            />
            <CardSection
              background='#FFF'
              height={this.props.sectionHeight}
            >
              {
                this.props.showExpandedTarget &&
                  <ExpandedTarget
                    card={this.props.card}
                    cardState={this.props.cardState}
                    height={this.props.sectionHeight}
                    word={this.props.card.word}
                  />
              }
            </CardSection>
          {
            !this.props.revealed &&
              <CardSection
                background='#CCC'
                numSections={3}
                borderBottomRadius={this.props.showUserInput ? 0 : 10}
              >
                <WordEntry
                  numberEntry
                  disabled={!this.props.inView}
                  focus={this.props.inView && DeckUI.firstCardHasAdvanced}
                  target={this.props.card.word.targetTextWithDefiniteArticle}
                  message={<span>Type this <b>Number</b></span>}
                  onChange={this.props.onTextInputChange}
                  onPressEnter={this.props.evaluateTextInput}
                  value={this.props.cardState.textEntry}
                />
                {
                  this.props.showDigitsOnlyWarning &&
                    <HintWarning>
                      You need to type numbers, not letters!
                    </HintWarning>
                }
              </CardSection>
          }
          {
            this.props.revealed &&
              <CardSection
                background='#CCC'
                numSections={3}
                borderBottomRadius={this.props.showUserInput ? 0 : 10}
                fontSize='34px'
              >
                <span>{this.props.card.word.formattedDigitSourceText}</span>
                {
                  this.props.cardState?.revealed && !this.props.correct && !isBlank(this.props.incorrectAnswer) &&
                    <Answer
                      text={this.props.incorrectAnswer}
                      marginBottom='10px'
                    />
                }
              </CardSection>
          }
        </CardContent>
        <TranslateButtons
          card={this.props.card}
          cardState={this.props.cardState}
          userCard={this.props.userCard}
          disabled={!this.props.inView}
          showHelpButton={this.props.showHelpButton}
          onHelpButtonClicked={this.props.onHelpButtonClicked}
          onShowAnswer={this.props.evaluateTextInput}
        />
      </CardWrapper>
    );
  }
}

export default NumberCardWrapper(NumberCardTextEntry);
