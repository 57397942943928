import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import VideoPlayer from 'components/media/video_player';
import AudioPlayer from 'components/media/audio_player';
import { Theme } from '@seedlang/constants';
import { DeckUI } from '@seedlang/state';
import styled from '@emotion/styled';
import { depixify, isPresent, pixify } from '@seedlang/utils';
import { MediaWithSupplementWrapper } from '@seedlang/hoc';
import HeightLabel from 'components/height_label';

const Wrapper = styled.div`
  position: relative;
  background: black;
  width: ${props => props.width};
  height: ${props => props.height};
  margin-bottom: ${props => props.marginBottom};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: ${props => props.borderBottomRadius};
  border-bottom-right-radius: ${props => props.borderBottomRadius};
`;

const Supplement = styled.div`
  position: absolute;
  width: ${props => props.width};
  height: ${props => props.height};
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  z-index: ${props => props.zIndex};
  border-radius: ${props => props.borderRadius};
  overflow: ${props => props.overflow};
  img {
    width: 100%;
  }
`;

const PrimaryVideo = styled.div`
  position: absolute;
  bottom: ${props => props.bottom};
  left: ${props => props.left};
`;

const PrimaryVideoWrapper = styled.div`
  position: relative;
  height: ${props => props.height};
  width: ${props => props.width};
  border-radius: 10px;
`;

const NonVideoWrapper = styled.div`
  padding-top: ${props => props.paddingTop};
`;

const Text = styled.div`
  background: white;
  padding: 10px 0;
  font-size: 15px;
  line-height: 18px;
  border-radius: 10px;
`;

@observer
class MediaWithSupplement extends React.Component {

  static propTypes = {
    videoClip: PropTypes.object,
    supplementVideoClip: PropTypes.object,
    supplementMaximized: PropTypes.bool,
    hideSupplement: PropTypes.bool,
    defaultSupplementMaximized: PropTypes.bool,
    loadVideo: PropTypes.bool,
    autoPlay: PropTypes.bool,
    mediaStore: PropTypes.object,
    maximizedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minimizedWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    supplementImage: PropTypes.object,
    supplementBottom: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }

  render() {
    return (
      <Wrapper
        width={pixify(this.props.maximizedWidth)}
        height={pixify(this.props.maximizedWidth)}
        borderBottomRadius={pixify(this.props.borderBottomRadius)}
        marginBottom={pixify(this.props.marginBottom)}
      >
        {
          DeckUI.layout.debug &&
            <HeightLabel
              left={0}
            >
              {this.props.maximizedWidth}
            </HeightLabel>
        }
        {
          this.props.showSupplement &&
            <Supplement
              width={pixify(this.props.supplementMaximized ? this.props.maximizedWidth : depixify(this.props.minimizedWidth) + 10)}
              height={pixify(this.props.supplementMaximized ? this.props.maximizedWidth : depixify(this.props.minimizedWidth) + 10)}
              top={this.props.supplementMaximized ? 0 : 'auto'}
              bottom={this.props.supplementMaximized ? 'auto' : pixify(this.props.supplementBottom)}
              left={this.props.supplementMaximized ? 0 : 'auto'}
              zIndex={this.props.supplementMaximized ? Theme.z['supplement-maximized'] : 90}
              borderRadius='10px'
              overflow={this.props.supplementMaximized ? null : 'hidden'}
            >
              {
                !this.props.hasSupplementVideoClip && this.props.hasSupplementImage &&
                  <img src={this.props.supplementImage.url} alt=''/>
              }
              {
                this.props.hasSupplementVideoClip &&
                  <VideoPlayer
                    hidePlayButton
                    autoPlay={this.props.autoPlay}
                    bottom='0'
                    borderTopRadius='10px'
                    borderBottomRadius={pixify(this.props.borderBottomRadius)}
                    left={this.props.supplementMaximized ? 0 : '10px'}
                    usingDataSaverHeader={this.props.usingDataSaverHeader}
                    loop={this.props.loopSupplement}
                    muted
                    videoClip={this.props.supplementVideoClip}
                    width={pixify(this.props.supplementMaximized ? this.props.maximizedWidth : this.props.minimizedWidth)}
                    maximized={this.props.supplementMaximized}
                    circle={!this.props.supplementMaximized}
                  />
              }
            </Supplement>
        }
        {
          this.props.mediaIsVideo &&
            <PrimaryVideo
              bottom={this.props.supplementMaximized ? '10px' : 'auto'}
              left={this.props.supplementMaximized ? '10px' : 0}
            >
              <PrimaryVideoWrapper
                height={pixify(this.props.primaryVideoWidth)}
                width={pixify(this.props.primaryVideoWidth)}
              >
                  <VideoPlayer
                    {...this.props}
                    addKeyBindings
                    afterVideoPlayback={this.props.afterVideoPlayback}
                    borderTopRadius={pixify(this.props.borderTopRadius)}
                    borderBottomRadius={pixify(this.props.borderBottomRadius)}
                    circle={this.props.supplementMaximized}
                    width={pixify(this.props.primaryVideoWidth)}
                    height={pixify(this.props.primaryVideoWidth)}
                    bottom='0'
                    left='0'
                    playing={true}
                    maximized={!this.props.supplementMaximized}
                  />
              </PrimaryVideoWrapper>
            </PrimaryVideo>
        }
        {
          isPresent(this.props.mediaStore) &&
            <NonVideoWrapper
              paddingTop={this.props.maximizedWidth}
            >
              <AudioPlayer
                mediaStore={this.props.mediaStore}
              />
            </NonVideoWrapper>
        }
        {
          isPresent(this.props.text) &&
            <NonVideoWrapper
              paddingTop={this.props.maximizedWidth}
            >
              <Text>
                {this.props.text}
              </Text>
            </NonVideoWrapper>
        }
      </Wrapper>
    );
  }
}

export default MediaWithSupplementWrapper(MediaWithSupplement);
