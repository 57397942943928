import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { TriviaHomeWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { TreeUI, AppUI } from '@seedlang/state';
import Module from 'components/module';
import TriviaDeckLink from 'components/trivia/trivia_deck_link';
import LearnLayout from 'pages/main/learn_layout';
import TriviaProgress from 'components/trivia/trivia_progress';
import Spinner from 'components/spinner';
import Modal from 'components/modal';
import TriviaOptions from 'components/trivia/trivia_options';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const DeckRow = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  height: 300px;
  padding: 0 10px 0 0;
`;

const InnerDeckRow = styled.div`
  display: flex;
  position: absolute;
  width: calc(100% - 20px);
  overflow: auto;
  height: 300px;
  align-items: center;
  padding: 0 0 0 20px;
`;

@observer
class TriviaHome extends React.Component {
  @observable showModules = false;
  @observable treeNodeId = null;
  @observable moduleName = null;
  @observable abbreviation = null;

  @autobind onClick(treeNode, treeModule) {
    this.treeNodeId = treeNode.id;
    this.moduleName = treeModule.name;
    this.abbreviation = treeNode.level.abbreviation;
  }

  @autobind onReset() {
    this.treeNodeId = null;
    this.moduleName = null;
    this.abbreviation = null;
  }

  render() {
    return (
      <LearnLayout>
        <Wrapper>
          {
            !TreeUI.triviaTree &&
              <Spinner />
          }
          {
            this.treeNodeId &&
              <Modal
                onCloseModal={this.onReset}
                top={100}
                width={360}
                gradient
                title={`${this.abbreviation} ${this.moduleName} Trivia`}
              >
                <TriviaOptions
                  treeNodeId={this.treeNodeId}
                />
              </Modal>
          }
          {
            TreeUI.triviaTree && TreeUI.triviaTree.treeModules.map(treeModule => {
              if (treeModule.webPublished || AppUI.user.admin) {
                return (
                  <Module
                    key={treeModule.id}
                    title={treeModule.name}
                    slug={treeModule.id}
                  >
                      {
                        treeModule.treeRows.map(treeRow => {
                          return (
                            <DeckRow
                              key={treeRow.id}
                            >
                              <InnerDeckRow>
                                {
                                  treeRow.treeNodes.map(treeNode => {
                                    const userTreeNode = AppUI.userTreeNodeStore.indexData.find(item => item.treeNodeId === treeNode.id);
                                    if (treeNode.webPublished || AppUI.user.admin) {
                                      return (
                                        <TriviaDeckLink
                                          onClick={() => this.onClick(treeNode, treeModule)}
                                          key={treeNode.id}
                                          treeNode={treeNode}
                                          userTreeNode={userTreeNode}
                                          marginRight='10px'
                                        />
                                      );
                                    } else {
                                      return null;
                                    }
                                  })
                                }
                              </InnerDeckRow>
                            </DeckRow>
                          );
                        })
                      }
                  </Module>
                );
              } else {
                return <span />;
              }
            })
          }
        </Wrapper>
        <TriviaProgress />
      </LearnLayout>
    );
  }
}

export default TriviaHomeWrapper(TriviaHome);
