import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { RecordingSessionStore, UserStore } from '@seedlang/stores';
import { Link } from 'react-router';
import Paginator from 'components/paginator';
import VideoPlayersWithType from 'pages/builder/video_clips/video_players_with_type';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI } from '@seedlang/state';
import Filter from 'components/backend_filter/filter';
import ReactTooltip from 'react-tooltip';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

const Wrapper = styled.div`

`;

@observer
class RecordingSessionsIndex extends React.Component {

  constructor(props) {
    super(props);
    RecordingSessionStore.getIndex();
    UserStore.getIndex({filters: {video_creator: true}});
  }

  @autobind onDelete(id) {
    RecordingSessionStore.destroy({ids: {recordingSessionId: id}}, this.reloadIndexFiltered);
  }

  @autobind reloadIndexFiltered() {
    RecordingSessionStore.getIndex({filters: {video_creator: true}});
  }

  render() {
    return (
      <Wrapper>
        <Filter
          store={RecordingSessionStore}
          defaultSort='-occurred_at'
          namespace='builder-word-index'
          queryStrings={{include_count: true}}
          filters={
          [
            {
              type: 'boolean',
              label: 'Initial Reviewed',
              name: 'initial_reviewed',
              default: '',
            },
            {
              type: 'boolean',
              label: 'Final Reviewed',
              name: 'final_reviewed',
              default: '',
            },
            {
              type: 'date',
              name: 'occurred_at',
              label: 'Recording Date',
              default: '',
            },
            {
              type: 'select',
              name: 'user_id',
              options: UserStore.indexData, //.map(item => {id: item?.id, name: item.name}),
              labelField: 'name',
              label: 'User',
              default: '',
            },
          ]}
        />
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th width='20' />
              <th width='80'>Date</th>
              <th>User</th>
              <th>Video</th>
              <th width='80'># Videos</th>
              <th>Reviewed</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {
              RecordingSessionStore.indexData.map(item => {
                return (
                  <tr
                    key={item?.id}
                  >
                    <td>
                      <Link
                        to={{ name: 'creator.producer.recording_sessions.show', params: { recordingSessionId: item?.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      {item.occurredAt.formattedDateWithYear}
                    </td>
                    <td>
                      {
                        UserStore.hasIndexData &&
                          <InPlaceSelect
                            includeBlank
                            model='recording_sessions'
                            value={item.user?.id}
                            id={item?.id}
                            field='user_id'
                            options={UserStore.indexData.map(item => [item?.id, item?.name])}
                            afterChange={this.reloadIndexFiltered}
                          />
                      }
                    </td>
                    <td>
                      {
                        item.videoClip &&
                          <VideoPlayersWithType
                            circle
                            autoPlay={false}
                            videoClip={item.videoClip}
                            width='150px'
                          />
                      }
                    </td>
                    <td>
                      {item.videoClipsCount}
                    </td>
                    <td>
                      <span data-tip data-for={`initial-${item?.id}`} style={{marginRight: 4}}>
                        <InPlaceCheckbox
                          model='recording_sessions'
                          field='initial_reviewed'
                          value={item.initialReviewed}
                          id={item?.id}
                          icon='thumbs-up'
                        />
                        <ReactTooltip
                          place='top'
                          type='dark'
                          effect='solid'
                          id={`initial-${item?.id}`}
                          class='custom-tooltip'
                        >
                          Initial Reviewed
                        </ReactTooltip>
                      </span>
                      <span data-tip data-for={`final-${item?.id}`} style={{marginRight: 4}}>
                        <InPlaceCheckbox
                          model='recording_sessions'
                          field='final_reviewed'
                          value={item.finalReviewed}
                          id={item?.id}
                          icon='thumbs-up'
                        />
                        <ReactTooltip
                          place='top'
                          type='dark'
                          effect='solid'
                          id={`final-${item?.id}`}
                          class='custom-tooltip'
                        >
                          Final Reviewed
                        </ReactTooltip>
                      </span>
                    </td>
                    <td>
                      {
                        (AppUI.user?.id === '3e72839b-b297-4673-9717-87c8da0d3a60' || (UserStore.hasIndexData && !UserStore.indexData.find(i => i?.id === item.user?.id))) &&
                          <DeleteButton
                            onConfirm={() => this.onDelete(item?.id)}
                            message='Delete this recording session?'
                            className='fa-block right'
                          />
                      }
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={RecordingSessionStore}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(RecordingSessionsIndex);
