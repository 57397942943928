import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import cx from 'classnames';

const Wrapper = styled.div`
  width: 100%;
  .expand-icon {
    margin: auto;
    height: 22px;
    color: #868686;
    font-size: 20px;
    pointer-events: none;
    margin-right: 5px;
  }
`;

const Row = styled.div`
  flex: 1;
  cursor: ${props => props.keepExpanded ? 'default' : 'pointer'};
  background: #E6E6E6;
  display: flex;
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const ExpandedContentWrapper = styled.div`
  margin: 5px;
  line-height: 20px;
  text-align: left;
`;

@observer
class WordCardRow extends React.Component {

  @computed get expanded() {
    return this.props.keepExpanded || (this.props.expandedId === this.props.id);
  }

  @autobind onClick(e) {
    if (e.target.id !== this.props.skipExpandId) {
      this.props.onClick(this.props.id);
    }
  }

  render() {
    return (
      <Wrapper>
        <Row
          onClick={this.onClick}
          keepExpanded={this.props.keepExpanded}
        >
          <TitleWrapper>
            {this.props.children[0]}
          </TitleWrapper>
          {
            !this.props.keepExpanded &&
              <i
                className={cx('fa', 'expand-icon', {
                  'fa-angle-down': !this.expanded,
                  'fa-angle-up': this.expanded,
                })}
              />
          }
        </Row>
        {
          this.expanded && this.props.children[1] &&
            <ExpandedContentWrapper>
              { this.props.children[1] }
            </ExpandedContentWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WordCardRow);
