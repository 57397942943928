import { Constants } from '@seedlang/constants';
import bowser from 'bowser';

const VIDEO_DIMENSIONS = {
  width: { min: Constants.VIDEO_DIMENSIONS, max: Constants.VIDEO_DIMENSIONS },
  height: { min: Constants.VIDEO_DIMENSIONS, max: Constants.VIDEO_DIMENSIONS },
};

export default function getUserMedia(params = {}, successCallback, failureCallback) {
  const constraints = {
    audio: {
      deviceId: params.audioSource ? { exact: params.audioSource } : undefined,
    },
  };
  if (params.videoSource || params.video) {
    constraints.video = {
      deviceId: params.videoSource ? { exact: params.videoSource } : undefined,
    };
    if (!bowser.safari) {
      constraints.video.width = VIDEO_DIMENSIONS.width;
      constraints.video.height = VIDEO_DIMENSIONS.height;
    }
  }
  navigator.mediaDevices.getUserMedia(constraints)
    .then(function (stream) {
     successCallback(stream);
    })
    .catch(function (err) {
      failureCallback();
    });
}
