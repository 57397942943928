import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { isPresent, isBlank } from '@seedlang/utils';
import { computed, observable } from 'mobx';
import ReactTooltip from 'react-tooltip';
import ConceptWordIndex from 'pages/builder/concept_words/concept_word_index';
import { flexCenter } from '@seedlang/style_mixins';
import { AppUI } from '@seedlang/state';
import Modal from 'components/modal';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
`;

const TagDisplay = styled.div`
  ${flexCenter()}
  background: #FFF;
  color: #343535;
  height: 20px;
  padding: 2px 5px;
  font-size: 10px;
  line-height: 12px;
  border-radius: 10px;
  font-weight: bold;
  border: 1px solid #cbcccb;
  cursor: ${props => props.isClickable ? 'pointer' : 'default'};
  &:hover {
    box-shadow: ${props => props.isClickable ? '0 0 6px rgba(33,33,33,.2)' : ''}; 
  }
  &:active {
    box-shadow: ${props => props.isClickable ? 'inset 0 0 6px rgba(33,33,33,.2)' : ''};
  }
`;

const Description = styled.div`
  
`;

@observer
class Tags extends React.Component {
  @observable showConceptEditModal;
  @observable selectedConcept;

  @computed get concepts() {
    return this.props.word?.concepts.filter(item => item.groupId === this.props.groupId);
  }

  @autobind onClickTag(concept) {
    if (isPresent(concept.longDescription)) {
      this.selectedConcept = concept;
    }
  }

  render() {
    return (
      <Wrapper>
        {
          this.showConceptEditModal &&
            <ConceptWordIndex
              hideWordCard
              noConceptsMessage="You haven't created any word tags."
              explainer="Word Tags can be created in the 'Word Tags' section."
              title={`Word Tags for \"${this.props.word?.targetText}\"`}
              onCloseModal={() => this.showConceptEditModal = false}
              word={this.props.word}
              groupId={this.props.groupId}
              onChange={this.props.onChange}
            />
        }
        {
          isPresent(this.selectedConcept) &&
          <Modal
            title={this.selectedConcept?.name}
            gradient={AppUI.siteIsDefault || isBlank(AppUI.site.accentColor)}
            background={!AppUI.siteIsDefault && isPresent(AppUI.site.accentColor) ? AppUI.site.accentColor : null}
            maxHeight='500px'
            contentShouldScroll
            onCloseModal={() => this.selectedConcept = null}
          >
            <Description dangerouslySetInnerHTML={{ __html: this.selectedConcept.longDescription }} />
          </Modal>
        }
        {
          this.concepts.map(item => {
            if (isPresent(item.name) && isPresent(item.abbreviation)) {
              return (
                <TagDisplay
                  key={item.id}
                  onClick={() => this.onClickTag(item)}
                  isClickable={isPresent(item.longDescription)}
                  data-for={`${item.id}-${this.props.word.id}`}
                  data-tip
                >
                  {item.abbreviation}
                  <ReactTooltip
                    type='dark'
                    effect='solid'
                    id={`${item.id}-${this.props.word.id}`}
                    class='custom-tooltip'
                  >
                    {item.name}
                  </ReactTooltip>
                </TagDisplay>
              );
            } else {
              return null;
            }
          })
        }
        {
          this.props.editable &&
            <TagDisplay
              onClick={() => this.showConceptEditModal = true}
              isClickable
            >
              <i className='fa fa-plus' />
            </TagDisplay>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Tags);
