import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PropTypes from 'prop-types';
import { times } from 'lodash';
import autobind from 'autobind-decorator';
import { isBlank, pixify } from '@seedlang/utils';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  width: 100%;
  height: 4px;
  display: flex;
`;

const Segment = styled.div`
  background: ${props => props.background};
  height: 4px;
  border-radius: 3px;
  box-sizing: border-box;
`;

@observer
class Meter extends React.Component {
  @observable width = this.props.width;

  static propTypes = {
    numSegments: PropTypes.number.isRequired,
    filledSegments: PropTypes.number,
    level: PropTypes.number,
    margin: PropTypes.number.isRequired,
    projectToward: PropTypes.string,
    disabled: PropTypes.bool,
    width: PropTypes.number,
  }

  static defaultProps = {
    projectToward: 'right',
    width: 0,
  }

  componentDidMount() {
    if (isBlank(this.props.width)) {
      this.boundSetWidth = this.setWidth;
      window.addEventListener('resize', this.boundSetWidth, true);
      this.setWidth();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.boundSetWidth, true);
  }

  @autobind setWidth() {
    const totalWidth = Math.floor(this.refs.audioMeter.offsetWidth) - (this.props.numSegments * this.props.margin) - 1; // minus one is a weirdness correction
    this.width = Math.floor(totalWidth / this.props.numSegments);
  }

  isActive(num) {
    if (!this.props.disabled && this.props.filledSegments) {
      return num < this.props.filledSegments;
    } else {
      return this.props.level > num * (100 / this.props.numSegments);
    }
  }

  style() {
    if (this.width === 0) {
      return {
        flex: 1,
        margin: pixify(this.props.margin),
      };
    } else if (this.props.projectToward === 'left') {
      return {
        width: pixify(this.width),
        marginLeft: pixify(this.props.margin),
        float: 'right',
      };
    } else if (this.props.projectToward === 'right') {
      return {
        width: pixify(this.width),
        marginRight: pixify(this.props.margin),
        float: 'left',
      };
    }
  }

  render() {
    return (
      <div ref='audioMeter' style={{width: '100%'}}>
        <Wrapper>
          {
            times(this.props.numSegments, num => {
              return (
                <Segment
                  key={num}
                  style={this.style()}
                  background={this.isActive(num) ? Theme.green : 'transparent'}
                />
              );
            })
          }
        </Wrapper>
      </div>
    );
  }
}

export default Meter;
