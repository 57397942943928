import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import pluralize from 'pluralize';
import { isString, isArray, first } from 'lodash';
import { Api } from '@seedlang/stores';
import cx from 'classnames';
import { isPresent, isBlank } from '@seedlang/utils';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  margin: ${props => props.margin || '0'};
 
  select {
    width: ${props => props.width};
    height: ${props => props.height};
    padding: 5px;
    font-size: 14px;
  }
`;

@observer
class InPlaceSelect extends React.Component {
  @observable value = '';
  @observable editMode = !this.props.clickToEdit || isBlank(this.props.value);

  @computed get disabled() {
    return this.props.disabled || (!this.props.allowUpdate && !AppUI.user.canUpdateRecords);
  }

  static defaultProps = {
    clickToEdit: false,
    height: '35px',
    width: '100%',
  }

  constructor(props) {
    super(props);
    this.select = React.createRef();
  }

  componentDidMount() {
    this.value = this.props.value;
    if (this.props.focus) {
      this.select.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.value = this.props.value;
    }
    if (isBlank(prevProps.value) && isPresent(this.props.value) && this.props.clickToEdit) {
      this.editMode = false;
    } else if (isPresent(prevProps.value) && isBlank(this.props.value)) {
      this.editMode = true;
    }
  }

  onClickToEdit() {
    if (!this.props.onlyCreate) {
      this.editMode = true;
    }
  }

  displayValue() {
    if (isPresent(this.value)) {
      if (this.props.options && isString(this.props.options[0])) {
        return this.value;
      } else if (isArray(this.props.options[0])) {
        const res = first(this.props.options.filter(item => item && item[0].toString() === this.value.toString()));
        return res ? res[1] : null;
      }
      return first(this.props.options.filter(item => item && item[0] === this.value))?.value;
    }
  }

  onChange() {
    if (this.props.blurOnChange) {
      this.select.current.blur();
    }
    const newValue = this.select.current.value;
    if (this.props.model) {
      const data = { data: {} };
      this.value = newValue;
      data.data[this.props.field] = newValue;
      Api.put({
        url: `/api/${pluralize(this.props.model)}/${this.props.id}`,
        data: data,
        successCallback: (resp => {
          if (this.props.afterChange) {
            this.props.afterChange(newValue);
          }
        }),
      });
    } else {
      this.props.onChange(newValue);
    }
    this.editMode = !this.props.clickToEdit;
  }

  _options() {
    if (isBlank(this.props.options)) { return [];};
    if (this.props.options && isString(this.props.options[0])) {
      return (
        this.props.options.map((item, index) => {
          return (
            <option
              key={`${item}-${index}`}
              value={item}
            >
              {item}
            </option>
          );
        })
      );
    } else if (this.props.options && isArray(this.props.options[0])) {
      return (
        this.props.options.map((item, index) => {
          return (
            <option
              key={`${item[1]}-${index}`}
              value={item[0]}
            >
              {item[1]}
            </option>
          );
        })
      );
    }
    return (
        this.props.options && this.props.options.map((item, index) => {
          const value = this.props.optionName ? item[this.props.optionName] : item.targetText;
          return (
            <option
              key={`${value}-${index}`}
              value={item.id}
            >
              {value}
            </option>
          );
        })
    );
  }

  render() {
    return (
      <Wrapper
        className={this.props.className}
        margin={this.props.margin}
        height={this.props.height}
        width={this.props.width}
      >
        {
          !this.editMode &&
            <div
              className={cx({
                editable: !this.props.onlyCreate,
              })}
              onClick={() => this.onClickToEdit()}
            >
              {this.displayValue()}
            </div>
        }
        {
          this.editMode &&
            <select
              ref={this.select}
              value={isPresent(this.value) ? this.value : ''}
              onChange={this.onChange.bind(this)}
              style={this.props.style}
              disabled={this.disabled}
            >
              {
                this.props.includeBlank &&
                  <option
                    key='-99'
                    value=''
                  >
                    {this.props.placeholder}
                  </option>
              }
              {this._options()}
            </select>
        }
      </Wrapper>
    );
  }
}

export default InPlaceSelect;
