import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { ConceptCategoryConceptStore } from '@seedlang/stores';
import { Link } from 'react-router';
import ConceptCreate from 'pages/builder/concepts/concept_create';
import Modal from 'components/modal';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import CheckIfTrue from 'components/check_if_true';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import ReactTooltip from 'react-tooltip';
import { noop } from 'lodash';
import RatingInput from 'components/rating_input';
import InPlaceCheckbox from 'components/form/in_place_checkbox';

@observer
class ConceptCategoryConceptIndex extends React.Component {
  @observable showCreate = false;

  componentDidMount() {
    ConceptCategoryConceptStore.getIndex({ sort: 'name', ids: { conceptCategoryId: this.props.conceptCategoryId }});
  }

  componentWillUnmount() {
    ConceptCategoryConceptStore.clearIndexData();
  }

  onDelete(id) {
    if (this.props.onDelete) {
      this.props.onDelete(id);
    } else {
      ConceptCategoryConceptStore.destroy({ ids: { conceptId: id } });
    }
  }

  onCopy(id) {
    ConceptCategoryConceptStore.copy({ ids: { conceptId: id } }, this.reloadConcepts);
  }

  @autobind reloadConcepts() {
    ConceptCategoryConceptStore.reloadIndexFiltered();
  }

  @autobind onClickRating(item, value, conceptId) {
    const rating = value === item.rating ? null : value;
    item.rating = rating;
    ConceptCategoryConceptStore.update({ids: {conceptId: conceptId}, data: {rating: rating}}, noop);
  }

  concepts() {
    if (this.props.concepts) {
      return this.props.concepts;
    } else if (ConceptCategoryConceptStore.hasIndexData) {
      return ConceptCategoryConceptStore.indexData;
    }
    return [];
  }

  render() {
    return (
      <div className='concept-index'>
        {
          !this.props.hideCreateButton && this.showCreate &&
          <Modal
            onCloseModal={() => this.showCreate = false}
          >
            <ConceptCreate />
          </Modal>
        }
        <div style={{display: 'flex'}}>
          <div style={{width: 150}}>
            {
              !this.props.hideCreateButton && (
                <button
                  className='gray-button'
                  onClick={() => this.showCreate = true}
                >
                  <i className='fa fa-plus' />
                  Create
                </button>
              )
            }
          </div>
        </div>
        <table className='table-wrapper'>
          <thead>
          <tr>
            <th width='40'></th>
            <th
              width='40'
              className={cx('sortable', this.props.columnClassNames('sentencesCount'))}
              onClick={this.props.onSortColumn.bind(this, 'sentencesCount')}
            >
              #
            </th>
            <th />
            <th
              width='300'
              className={cx('sortable', this.props.columnClassNames('normalized_name'))}
              onClick={this.props.onSortColumn.bind(this, 'normalized_name')}
            >
              Name
            </th>
            <th width='300'>Display</th>
            <th width='100'>Rating</th>
            <th width='100'>Lang</th>
            <th width='100'>Level</th>
            <th width='200'>Word Type</th>
            <th width='200'># Words</th>
            <th width='50'>C/T</th>
            <th>Desc?</th>
            <th width='30'></th>
            {
              !this.props.hideDelete &&
              <th width='30'></th>
            }
          </tr>
          </thead>
          <tbody>
          {
            this.concepts().map(item => {
              return (
                <tr key={item.id}>
                  <td>
                    <Link
                      to={{ name: 'builder.concepts.edit', params: { conceptId: item.id } }}
                      className='on-click'
                    >
                      <i className='fa fa-chevron-right fa-block' />
                    </Link>
                  </td>
                  <td>
                    <div className='text'>
                      {item.sentencesCount}
                    </div>
                  </td>
                  <td>
                    <div className='text' style={{width: 60, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <span data-tip data-for={`didactics-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model='concepts'
                            field='reviewed2'
                            value={item.reviewed2}
                            id={item.id}
                            icon='thumbs-up'
                          />
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                            id={`didactics-${item.id}`}
                            class='custom-tooltip'
                          >
                            Approved: Didactics 1
                          </ReactTooltip>
                        </span>
                      <span data-tip data-for={`translation-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model='concepts'
                            field='reviewed3'
                            value={item.reviewed3}
                            id={item.id}
                            icon='thumbs-up'
                          />
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                            id={`translation-${item.id}`}
                            class='custom-tooltip'
                          >
                            Approved: Didactics 2
                          </ReactTooltip>
                        </span>
                      <span data-tip data-for={`content-${item.id}`} style={{marginRight: 4}}>
                          <InPlaceCheckbox
                            model='concepts'
                            field='reviewed1'
                            value={item.reviewed1}
                            id={item.id}
                            icon='thumbs-up'
                          />
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                            id={`content-${item.id}`}
                            class='custom-tooltip'
                          >
                            Approved: English
                          </ReactTooltip>
                        </span>
                    </div>
                  </td>
                  <td>
                    <div className='text'>
                      {item.name}
                    </div>
                  </td>
                  <td>
                    <div className='text'>
                      <div>{item.displayNameLine1}</div>
                      <div><i>{item.displayNameLine2}</i></div>
                    </div>
                  </td>
                  <td>
                    <RatingInput
                      hideTooltip
                      starWidth='12px'
                      id={item.id}
                      marginTop='0'
                      maxRating={3}
                      defaultValue={item.rating}
                      onClick={value => this.onClickRating(item, value, item.id)}
                    />
                  </td>
                  <td>
                    <div className='text'>
                      {item.languageId}
                    </div>
                  </td>
                  <td>
                    <div className='text'>
                      {item.level && item.level.abbreviation}
                    </div>
                  </td>
                  <td>
                    <div className='text'>
                      {item.wordType && item.wordType.abbreviation}
                    </div>
                  </td>
                  <td>
                    <div className='text'>
                      {item.wordsCount}
                    </div>
                  </td>
                  <td>
                    <div className='text'>
                      {item.grammarTense}{item.grammarCase}
                    </div>
                  </td>
                  <td>
                    <CheckIfTrue
                      hideIfFalse
                      value={isPresent(item.longDescriptionMd)}
                    />
                  </td>
                  <td>
                    <div
                      data-tip
                      data-for={item.id}
                    >
                      <i
                        className='fa fa-copy fa-block fa-on-click'
                        onClick={() => this.onCopy(item.id)}
                      />
                      <ReactTooltip
                        place='left'
                        type='dark'
                        effect='solid'
                        id={item.id}
                        class='custom-tooltip'
                      >
                        Clone this Concept
                      </ReactTooltip>
                    </div>
                  </td>
                  {
                    !this.props.hideDelete &&
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message={this.props.deleteMessage || 'Delete this concept?'}
                        className='fa-block right'
                      />
                    </td>
                  }
                </tr>
              );
            })
          }
          </tbody>
        </table>
        {
          !this.props.hidePaginator &&
            <Paginator
              store={ConceptCategoryConceptStore}
              ids={{conceptCategoryId: this.props.conceptCategoryId}}
            />
        }
      </div>
    );
  }
}

export default SortableColumns(ConceptCategoryConceptIndex, ConceptCategoryConceptStore, { sortColumn: 'sentencesCount', sortIsAscending: false });
