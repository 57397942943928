import React from 'react';
import { observer } from 'mobx-react';
import { JobStore, SiteStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';

@observer
class JobEdit extends React.Component {
  componentDidMount() {
    this.getJob();
    SiteStore.getIndex();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.pageId !== prevProps.routeParams.pageId) {
      this.getJob();
    }
  }

  componentWillUnmount() {
    JobStore.clearShowData();
  }

  @computed get job() {
    return JobStore.showData;
  }

  @autobind getJob() {
    JobStore.getShow({ ids: { jobId: this.props.routeParams.jobId }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    JobStore.setShowData(resp);
  }

  render() {
    return (
      <div className='job-edit'>
        {
          JobStore.hasShowData &&
          <div className='row'>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Name</legend>
                <InPlaceText
                  id={this.job.id}
                  defaultValue={this.job.title}
                  model='jobs'
                  field='title'
                />
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Site</legend>
                <InPlaceSelect
                  includeBlank={false}
                  options={SiteStore.indexData.map(item => [item.id, item.name])}
                  value={this.job.siteId}
                  model='jobs'
                  field='site_id'
                  id={this.job.id}
                  afterChange={JobStore.reloadShow.bind(JobStore)}
                />
              </fieldset>
            </div>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Content</legend>
                <InPlaceText
                  wrapperClassName='word-info'
                  markdown
                  defaultValue={this.job.bodyMd}
                  model='jobs'
                  field='body_md'
                  inputType='textarea'
                  id={this.job.id}
                />
              </fieldset>
            </div>
          </div>
        }
      </div>
    );
  }
}

export default JobEdit;
