import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import { NumberCardWrapper } from '@seedlang/hoc';
import NumberCardTextEntry from 'components/number_card/number_card_text_entry';
import NumberCardMultipleChoice from 'components/number_card/number_card_multiple_choice';

@observer
class NumberCard extends React.Component {

  render() {
    if (AppUI.userIsLoaded) {
      if (this.props.showTextEntryCard) {
        return (
          <NumberCardTextEntry
            {...this.props}
          />
        );
      } else {
        return (
          <NumberCardMultipleChoice
            {...this.props}
          />
        );
      }
    }
    return <span />;
  }
}

export default NumberCardWrapper(NumberCard);
