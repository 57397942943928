import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { GridItemStore, GridItemWorksheetStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import WorksheetSearch from 'components/worksheet_search';
import DeleteButton from 'components/button/delete_button';
import { AppUI } from '@seedlang/state';
import Text from 'components/text';
import { observable } from 'mobx';
import isBlank from 'is-blank';
import Copy from 'components/copy';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  table {
    margin-top: 20px;
  }
`;

const SortingWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`;

const FormRow = styled.div`
  margin-top: 5px;
  display: flex;
  gap: 10px;
  button {
    border-radius: 10px;
    border: 1px solid gray;
    font-size: 16px;
  }
  button:disabled {
    background-color: lightgray;
    color: darkgray;
    cursor: not-allowed;
    border: 1px solid darkgray;
}
`;

@observer
class GridItemEdit extends React.Component {
  @observable sortByField;
  @observable sortByOrder;

  constructor(props) {
    super(props);
    this.getGridItem();
    AppUI.worksheetStore.clearIndexData();
  }

  @autobind getGridItem() {
    GridItemStore.getShow({ids: {gridItemId: this.props.params.gridItemId}});
  }

  @autobind onAddWorksheet(params) {
    GridItemWorksheetStore.create({data: {worksheetId: params.id, gridItemId: this.props.params.gridItemId}}, this.getGridItem);
  }

  @autobind onRemoveWorksheet(id) {
    GridItemWorksheetStore.destroy({ids: {gridItemWorksheetId: id}}, this.getGridItem);
  }

  @autobind sortPosts() {
    GridItemStore.sortPosts({ids: {gridItemId: this.props.params.gridItemId}, data: {sort: `worksheets.${this.sortByField} ${this.sortByOrder}`}}, this.getGridItem);
  }

  render() {
    return (
      <Wrapper>
        <div className='breadcrumbs' style={{marginBottom: 20}}>
          <Link
            to={{name: 'creator.grid_items.index', params: {groupId: this.props.params.groupId}}}
          >
            View Grid Items
          </Link>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Name</legend>
              <InPlaceText
                field='name'
                defaultValue={GridItemStore.showDataField('name')}
                model='grid_items'
                id={GridItemStore.showDataField('id')}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Sort Posts</legend>
              <Text small>You can change all the posts positions by selecting a field and an order below.</Text>
              <Text small>You will lose the current positions by doing this.</Text>
              <FormRow>
                <select
                  onChange={e => this.sortByField = e.target.value}
                  value={this.sortByField}
                  style={{flex: 1}}
                >
                  <option value=''>Choose a Field</option>
                  <option value='published_at'>Post Published At</option>
                  <option value='content_published_at'>Content Published At</option>
                </select>
                <select
                  onChange={e => this.sortByOrder = e.target.value}
                  value={this.sortByOrder}
                  style={{flex: 1}}
                >
                  <option value=''>Choose an order</option>
                  <option value='DESC'>Newest first</option>
                  <option value='ASC'>Oldest first</option>
                </select>
                {
                  GridItemStore.requestCounter === 0 &&
                    <button
                      style={{flex: 1}}
                      disabled={isBlank(this.sortByOrder) || isBlank(this.sortByField)}
                      onClick={this.sortPosts}
                    >
                      Sort Posts
                    </button>
                }
                {
                  GridItemStore.requestCounter > 0 &&
                  <Spinner/>
                }
              </FormRow>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Posts</legend>
              <WorksheetSearch
                onSubmit={this.onAddWorksheet}
                placeholder='Search for Post to Add to this Section'
              />
              {
                GridItemStore.hasShowData &&
                  <table className='table-wrapper admin-table'>
                    <thead>
                      <tr>
                        <th>Position</th>
                        <th>Name</th>
                        <th>Post Published At</th>
                        <th>Content Published At</th>
                        <th width='40'></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        GridItemStore.showData.gridItemWorksheets.map(item => {
                          return (
                            <tr key={item.id}>
                              <td style={{width: '100px'}}>
                                <div className='text'>
                                  <InPlaceText
                                    field='position'
                                    defaultValue={item.position}
                                    model='grid_item_worksheets'
                                    id={item.id}
                                    afterChange={this.getGridItem}
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='text' style={{display: 'flex', gap: '10px'}}>
                                  {item.worksheet.name}
                                  {
                                    AppUI.user.developer &&
                                    <Copy
                                      text={item.id}
                                    />
                                  }
                                </div>
                              </td>
                              <td>
                                <div>
                                  {item.worksheet.publishedAt?.formattedDateWithYear}
                                </div>
                              </td>
                              <td>
                                <div>
                                  {item.worksheet.contentPublishedAt?.formattedDateWithYear}
                                </div>
                              </td>
                              <td>
                                <DeleteButton
                                  onConfirm={() => this.onRemoveWorksheet(item.id)}
                                  message='Remove this worksheet?'
                                  className='fa-block right'
                                />
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
              }
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(GridItemEdit);
