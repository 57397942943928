import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import LanguageButton from 'components/button/language_button';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import SpecialCharacterButtons from 'components/special_character_buttons';
import { AppUI, ExerciseUI } from '@seedlang/state';
import autobind from 'autobind-decorator';

const Wrapper = styled.div`
  margin: ${props => props.margin};
`;

const Source = styled.div`

`;

const CharacterWrapper = styled.div`
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  input {
    background: ${props => props.background};
    color: ${props => props.background ? '#FFF' : '#000'};
    font-size: 16px;
    padding: 10px;
  }
`;

const RevealedText = styled.div`
    background: ${props => props.background};
    color: ${props => props.background ? '#FFF' : '#000'};
    font-size: 16px;
    padding: 10px;
    border: 1px solid #bcbcbc;
    line-height: 20px;
    max-width: 100%;
    width: 100%;
    border-radius: 4px;
`;

@observer
class ExerciseSentenceEnterInput extends React.Component {
  @observable languageId = this.props.exerciseEntry.sentence.languageId;

  @computed get background() {
    if (this.props.exerciseEntry.state.revealed && this.props.exerciseEntry.state.correct) {
      return Theme.green;
    } else if (this.props.exerciseEntry.state.revealed && !this.props.exerciseEntry.state.correct) {
      return Theme.red;
    } else return '';
  }

  @autobind insertText(text) {
    const el = document.getElementById(this.props.exerciseEntry.id);
    if (el) {
      const caretPosition = el.selectionStart;
      el.focus();
      const newValue = [this.props.exerciseEntry.state.submittedValue?.slice(0, caretPosition), text, this.props.exerciseEntry.state.submittedValue?.slice(caretPosition)].join('');
      this.props.exerciseEntry.state.set('submittedValue', newValue);
      setTimeout(() => {
        el.setSelectionRange(caretPosition + 1, caretPosition + 1);
      }, 0);
    }
  }

  @computed get _revealedText() {
    if (
      this.props.exerciseEntry.state.correct
      && this.props.exerciseEntry.sentence.targetText.trim().length === this.props.exerciseEntry.state.submittedValue?.trim().length
      && (this.props.exerciseEntry.state.incorrectCasing || this.props.exerciseEntry.state.incorrectSpecialCharacters)
    ) {
      return this.props.exerciseEntry.sentence.targetText?.trim().split('').map((char, index) => char !== this.props.exerciseEntry.state.submittedValue?.trim()[index] ? `<u>${char}</u>` : char).join('');
    }

    if (this.props.exerciseEntry.state.correct) {
      return this.props.exerciseEntry.sentence.targetText;
    }

    return this.props.exerciseEntry.state.submittedValue;
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        {
          this.props.exerciseEntry.sentence.source && this.props.exerciseEntry.sentence.source.text &&
            <LanguageButton
              languageId={this.languageId}
              onClick={() => this.languageId = this.languageId === 'DE' ? 'EN' : 'DE'}
            />
        }
        {
          this.languageId !== 'EN' &&
            <InputWrapper
              background={this.background}
            >
              {
                !this.props.exerciseEntry.state.revealed &&
                  <input
                    type='text'
                    id={this.props.exerciseEntry.id}
                    value={this.props.exerciseEntry.state.submittedValue || ''}
                    onChange={e => this.props.exerciseEntry.state.set('submittedValue', e.currentTarget.value)}
                    onFocus={this.props.onFocus}
                    onKeyUp={e => e.keyCode === 13 && this.props.revealOnEnter ? ExerciseUI.revealExerciseEntryAnswer(this.props.exerciseEntry, {}, this.props.afterReveal) : null}
                  />
              }
              {
                this.props.exerciseEntry.state.revealed &&
                  <RevealedText
                    background={this.background}
                    dangerouslySetInnerHTML={{ __html: this._revealedText }}
                  />
              }
            </InputWrapper>
        }
        {
          this.languageId === 'EN' &&
            <Source>
              {this.props.exerciseEntry.sentence.source.text}
            </Source>
        }
        {
          this.props.isFocused && AppUI.user.showSpecialCharacters &&
            <CharacterWrapper>
              <SpecialCharacterButtons
                showCloseButton
                languageId={ExerciseUI.groupId === 'f74a4102-d65b-448c-b261-60b6be2c7eca' ? 'DE' : null}
                onClick={this.insertText}
              />
            </CharacterWrapper>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(ExerciseSentenceEnterInput);
