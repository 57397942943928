import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';

const LEVEL_COLORS = {
  A1: '#2F25BB',
  A2: '#383199',
  B1: '#211B73',
  B2: '#100B44',
};

const Wrapper = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.height};
  cursor: ${props => props.cursor};
  margin: ${props => props.margin};
  background: ${props => props.disabled ? '#333' : props.background};
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  border-bottom-left-radius: ${props => props.borderBottomLeftRadius};
  border-bottom-right-radius: ${props => props.borderBottomRightRadius};
  overflow: hidden;
  img {
    -webkit-filter: ${props => props.disabled ? 'grayscale(1)' : 'none'};
    filter: ${props => props.disabled ? 'gray' : 'none'};
    filter: ${props => props.disabled ? 'grayscale(1)' : 'none'};
    position: absolute;
    z-index: 10;
    width: 100%;
    height: 100%;
  }
`;

const Stripes = styled.div`
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.08),
    rgba(255, 255, 255, 0.08) 10px,
    rgba(255, 255, 255, 0) 10px,
    rgba(255, 255, 255, 0) 20px
  );
`;

@observer
class TriviaImage extends React.Component {

  render() {
    return (
      <Wrapper
        disabled={this.props.disabled}
        borderTopLeftRadius={this.props.borderTopLeftRadius}
        borderTopRightRadius={this.props.borderTopRightRadius}
        borderBottomLeftRadius={this.props.borderBottomLeftRadius}
        borderBottomRightRadius={this.props.borderBottomRightRadius}
        width={this.props.width}
        height={this.props.height}
        cursor={this.props.cursor}
        margin={this.props.margin}
        background={this.props.level && LEVEL_COLORS[this.props.level]}
        onClick={this.props.onClick}
      >
        <Stripes
          className='stripes'
          disabled={this.props.disabled}
        />
        <img
          src={this.props.src}
          alt=''
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TriviaImage);
