import React from 'react';
import { observer } from 'mobx-react';
import { CustomTabStore, MembershipTypeCustomTabStore, MembershipTypeStore, SiteStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import Cookies from 'js-cookie';
import { find, isFunction, noop, camelCase, startCase } from 'lodash';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import Text from 'components/text';
import IconInPlaceSelect from 'pages/icon_in_place_select';
import isBlank from 'is-blank';
import { isPresent } from '@seedlang/utils';
import DeleteButton from 'components/button/delete_button';
import Button from 'components/button/button';
import InfoTooltip from 'components/info_tooltip';
import Alert from 'components/alert';
import BackEndExplainer from '../back_end_explainer';
import { Link } from 'react-router';

@observer
class CustomTabEdit extends React.Component {
  static defaultProps = {
    whiteLabeled: false,
  }

  componentDidMount() {
    if (!this.props.whiteLabeled) {
      SiteStore.getIndex();
    }
    this.getMembershipTypes();
    if (isBlank(this.props.customTab)) {
      this.getCustomTab();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (isPresent(this.props.routeParams) && isPresent(prevProps.routeParams) && this.props.routeParams.customTabId !== prevProps.routeParams.customTabId) {
      this.getCustomTab();
    }
  }

  componentWillUnmount() {
    if (CustomTabStore.hasShowData) {
      CustomTabStore.clearShowData();
    }
  }

  @computed get customTab() {
    return this.props.customTab || CustomTabStore.showData;
  }

  @computed get filteredMembershipTypes() {
    return MembershipTypeStore.indexData.filter(item => item.siteId === Cookies.get('site_id'));
  }

  @autobind afterUpdate() {
    if (isFunction(this.props.afterUpdate)) {
      this.props.afterUpdate();
    } else {
      this.getCustomTab();
    }
  }

  @autobind getCustomTab() {
    CustomTabStore.getShow({ ids: { customTabId: this.props.routeParams.customTabId }});
  }

  @autobind getMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @autobind getMembershipTypeCustomTab(membershipTypeId) {
    return find(this.customTab?.membershipTypeCustomTabs, ['membershipTypeId', membershipTypeId]);
  }

  @autobind onChange(membershipTypeCustomTab, membershipTypeId, value) {
    if (value) {
      MembershipTypeCustomTabStore.create({ data: { custom_tab_id: this.customTab.id, membership_type_id: membershipTypeId } }, this.afterUpdate);
    } else {
      MembershipTypeCustomTabStore.destroy({ ids: { membershipTypeCustomTabId: membershipTypeCustomTab.id } }, this.afterUpdate);
    }
  }

  @autobind onDelete() {
    CustomTabStore.destroy({ ids: { customTabId: this.customTab.id } }, this.afterUpdate);
  }

  @autobind customTabOrWorksheetCustomTab(attribute) {
    return isPresent(this.props.worksheetCustomTab) ? this.props.worksheetCustomTab[attribute] : this.customTab[attribute];
  }

  @computed get model() {
    return isPresent(this.props.worksheetCustomTab) ? 'worksheet_custom_tabs' : 'custom_tabs';
  }

  render() {
    if (isPresent(this.customTab)) {
      return (
        <div className='custom-tabs-edit'>
          {
            isBlank(this.props.worksheetCustomTab) &&
              <>
                <div className='row'>
                  <div className='col-xs-5'>
                    <fieldset style={{display: 'flex'}}>
                      <legend>Name</legend>
                      <InPlaceText
                        id={this.customTab.id}
                        defaultValue={this.customTab.title}
                        model='custom_tabs'
                        field='title'
                      />
                      {
                        isBlank(this.customTab.title) &&
                        <InfoTooltip icon='warning'>Don't forget to add a name!</InfoTooltip>
                      }
                    </fieldset>
                  </div>
                  <div className='col-xs-2'>
                    <fieldset style={{display: 'flex'}}>
                      <legend>Published</legend>
                      <InPlaceCheckbox
                        icon='check'
                        value={this.customTab.published}
                        id={this.customTab.id}
                        field='published'
                        model='custom_tabs'
                        afterChange={this.afterUpdate}
                      />
                      {
                        !this.customTab.published &&
                        <InfoTooltip icon='warning'>The custom tab isn't published</InfoTooltip>
                      }
                    </fieldset>
                  </div>
                  <div className='col-xs-3'>
                    <fieldset style={{display: 'flex', alignItems: 'center'}}>
                      <legend>Icon</legend>
                      <IconInPlaceSelect
                        includeBlank={false}
                        id={this.customTab.id}
                        value={this.customTab.icon}
                        model='custom_tabs'
                        field='icon'
                        options={[
                          ['question-circle', 'Question'],
                          ['video-camera', 'Video'],
                          ['link', 'Link'],
                          ['download', 'Download'],
                          ['play-circle', 'Play Button'],
                          ['puzzle-piece', 'Puzzle'],
                          ['lightbulb-o', 'Lightbulb'],
                          ['rocket', 'Rocket'],
                          ['globe', 'Globe'],
                        ]}
                        afterChange={this.afterUpdate}
                      />
                    </fieldset>
                  </div>
                  <div className='col-xs-2' style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                    <DeleteButton
                      onConfirm={this.onDelete}
                      message='Delete this Custom Tab?'
                      className='fa-block right'
                    >
                      <Button>Delete</Button>
                    </DeleteButton>
                  </div>
                </div>
                {
                  !this.customTab.isVisible &&
                    <Alert>You haven't selected any membership types or selected to show to non members. No members will be able to see this custom tab.</Alert>
                }
                <div className='row'>
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Membership Types</legend>
                      {
                        this.filteredMembershipTypes.map(item => {
                          return (
                            <div
                              key={item.id}
                            >
                              <InPlaceCheckbox
                                icon='check'
                                value={Boolean(this.getMembershipTypeCustomTab(item.id))}
                                model='membership_type_custom_tabs'
                                onChange={value => this.onChange(this.getMembershipTypeCustomTab(item.id), item.id, value)}
                              >
                                {item.name}
                              </InPlaceCheckbox>
                            </div>
                          );
                        })
                      }
                    </fieldset>
                  </div>
                  <div className='col-xs-6'>
                    <fieldset style={{width: '100%'}}>
                      <legend>Other Filters</legend>
                      <div>
                        <InPlaceCheckbox
                          icon='check'
                          id={this.customTab.id}
                          value={this.customTab?.showToNonMembers}
                          field='show_to_non_members'
                          model='custom_tabs'
                          afterChange={this.afterUpdate}
                        >
                          Show to non members
                        </InPlaceCheckbox>
                      </div>
                      <div>
                        <InPlaceCheckbox
                          icon='check'
                          id={this.customTab.id}
                          value={this.customTab?.blockIfNotMigrated}
                          field='block_if_not_migrated'
                          model='custom_tabs'
                          afterChange={this.afterUpdate}
                        >
                          Block Content if User Needs to Migrate
                        </InPlaceCheckbox>
                      </div>
                      {
                        isBlank(this.customTab.worksheetId) &&
                        <>
                          <div>
                            <InPlaceCheckbox
                              afterChange={this.afterUpdate}
                              icon='check'
                              id={this.customTab.id}
                              value={this.customTab?.showOnVideoWorksheets}
                              field='show_on_video_worksheets'
                              model='custom_tabs'
                            >
                              Show on All Video Posts
                            </InPlaceCheckbox>
                          </div>
                          <div>
                            <InPlaceCheckbox
                              afterChange={this.afterUpdate}
                              icon='check'
                              id={this.customTab.id}
                              value={this.customTab?.showOnPodcastWorksheets}
                              field='show_on_podcast_worksheets'
                              model='custom_tabs'
                            >
                              Show on All Podcast Posts
                            </InPlaceCheckbox>
                            {
                              !this.customTab?.showOnPodcastWorksheets && !this.customTab?.showOnVideoWorksheets &&
                                <BackEndExplainer compact>To use this custom tab, you will need to add it to individual posts.</BackEndExplainer>
                            }
                            {
                              (this.customTab?.showOnPodcastWorksheets || this.customTab?.showOnVideoWorksheets) &&
                                <BackEndExplainer compact>This custom tab will be displayed on all posts with the selected membership types. </BackEndExplainer>
                            }
                          </div>
                        </>
                      }
                    </fieldset>
                  </div>
                </div>
                {
                  !this.customTab?.showOnPodcastWorksheets && !this.customTab?.showOnVideoWorksheets &&
                    <div className='row'>
                      <div className='col-xs-12'>
                        <BackEndExplainer>You can fill in the following fields, and you will also be able to replace these when adding the custom tab to a post.</BackEndExplainer>
                      </div>
                    </div>
                }
              </>
          }
          {
            isPresent(this.props.worksheetCustomTab) &&
              <>
                <div className='row'>
                  <div className='col-xs-12'>
                    {
                      !this.customTab.published &&
                        <Alert>This custom tab is not published.</Alert>
                    }
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xs-9'>
                    <BackEndExplainer>
                    <ul>
                    {
                      (this.customTab?.membershipTypes?.length > 0 || this.customTab.showToNonMembers) &&
                        <li>{`Visibility: ${this.customTab.formattedMembershipTypeNames}.`}</li>
                    }
                    {
                      isBlank(this.customTab.membershipTypes) && !this.customTab.showToNonMembers &&
                        <li>Visibility: No membership status have been selected.</li>
                    }
                    {
                      this.customTab.blockIfNotMigrated &&
                        <li>The content will be blocked for users that need to migrate their payment method.</li>
                    }
                    </ul>
                  </BackEndExplainer>
                </div>
                <div className='col-xs-3' style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                  {
                    isPresent(this.props.groupId) &&
                      <Link to={{name: 'creator.groups.custom_tabs.edit', params: {groupId: this.props.groupId, customTabId: this.customTab.id}}}>
                        <button className='gray-button'>Edit Custom Tab Template</button>
                      </Link>
                  }
                </div>
              </div>
            </>
          }
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Text</legend>
                <InPlaceText
                  wrapperClassName='word-info'
                  richText
                  defaultValue={this.customTabOrWorksheetCustomTab('content')}
                  placeholder={this.customTab.content}
                  model={this.model}
                  field='content'
                  inputType='textarea'
                  id={this.customTabOrWorksheetCustomTab('id')}
                />
              </fieldset>
            </div>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Image</legend>
                <Text fontSize='15px'>This image will be displayed with full width under the text.</Text>
                <InPlaceImageUpload
                  image={isPresent(this.props.worksheetCustomTab?.image) ? this.props.worksheetCustomTab.image : this.customTab.image}
                  model='images'
                  onChange={this.afterUpdate}
                  querystring={{
                    imageable_id: this.customTabOrWorksheetCustomTab('id'),
                    imageable_type: isPresent(this.props.worksheetCustomTab) ? 'WorksheetCustomTab' : 'CustomTab',
                  }}
                  hideDelete={isPresent(this.props.worksheetCustomTab) && isBlank(this.props.worksheetCustomTab?.image)}
                />
                <InPlaceText
                  show
                  id={this.customTabOrWorksheetCustomTab('id')}
                  defaultValue={this.customTabOrWorksheetCustomTab('imageLinkUrl')}
                  placeholder={this.customTab.imageLinkUrl}
                  model={this.model}
                  field='image_link_url'
                  label='Image link url'
                />
              </fieldset>
            </div>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Youtube Video</legend>
                <Text fontSize='15px'>This video will be embedded with full width under the text and image (if there are any).</Text>
                <InPlaceText
                  show
                  id={this.customTabOrWorksheetCustomTab('id')}
                  defaultValue={this.customTabOrWorksheetCustomTab('youtubeUrl')}
                  model={this.model}
                  field='youtube_url'
                  placeholder={this.customTab.youtubeUrl}
                />
              </fieldset>
            </div>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Button Link</legend>
                <Text fontSize='15px'>This button will be displayed at the bottom of the tab.</Text>
                <InPlaceText
                  show
                  id={this.customTabOrWorksheetCustomTab('id')}
                  defaultValue={this.customTabOrWorksheetCustomTab('buttonUrl')}
                  model={this.model}
                  field='button_url'
                  label='Button URL'
                  placeholder={this.customTab.buttonUrl}
                />
                <InPlaceText
                  show
                  id={this.customTabOrWorksheetCustomTab('id')}
                  defaultValue={this.customTabOrWorksheetCustomTab('buttonText')}
                  model={this.model}
                  field='button_text'
                  label='Button text'
                  placeholder={this.customTab.buttonText}
                />
              </fieldset>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default CustomTabEdit;
