import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  font-size: 14px;
  padding: 10px;
  text-align: left;
  display: flex;
  align-items: center;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  color: ${props => props.disabled ? '#CCC' : '#000'};
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
  .icons {
    width: 30px;
  }
  svg, path {
    fill: ${props => props.disabled ? '#CCC' : '#000'};
  }
  .icon-wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    margin-right: 8px;
    border: ${props => props.disabled ? '1px solid #CCC' : '1px solid black'};
    border-radius: 50%;
    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    background: ${props => props.disabled ? '#FFF' : Theme.darkestBlue};
    color: ${props => props.disabled ? '#CCC' : '#FFF'};
    .icon-wrapper {
      border: ${props => props.disabled ? '1px solid #CCC' : '1px solid #FFF'};
    }
    svg, path {
      fill: ${props => props.disabled ? '#CCC' : '#FFF'};
    }
  }
`;

@observer
class DeckMenuItem extends React.Component {

  render() {
    return (
      <Wrapper
        disabled={this.props.disabled}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </Wrapper>
    );
  }
}

export default ErrorBoundary(DeckMenuItem);
