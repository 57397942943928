import React from 'react';
import { ServerStore } from '@seedlang/stores';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`

`;

const Alert = styled.div`
  background: ${props => props.background};
  display: inline-block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  color: white;
  margin: 0 0 10px 0;
  border-radius: 3px;
`;

@observer
class AuthenticateStatus extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          !ServerStore.successMessage && ServerStore.errorMessage &&
            <Alert
              background={Theme.orange}
            >
              {ServerStore.errorMessage}
            </Alert>
        }
        {
          ServerStore.successMessage &&
            <Alert
              background={Theme.green}
            >
              {ServerStore.successMessage}
            </Alert>
        }
      </Wrapper>
    );
  }
}

export default AuthenticateStatus;
