import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Text from 'components/text';
import RssFeedIndex from 'components/rss_feed/rss_feed_index';

const Wrapper = styled.div`

`;

@observer
class SettingsRssFeed extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text
          heading='3'
          margin='0 0 10px 0'
        >
          Audio Feeds
        </Text>
        <RssFeedIndex
          selectedPodcastId={this.props.params.podcastId}
        />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsRssFeed);
