import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { isPresent, pixify, isBlank } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import { RouteStore } from '@seedlang/stores';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { CrownIcon, UserIcon, LanguageIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';
import uuid from 'uuid/v4';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  margin: ${props => props.margin};
  padding: 3px;
  svg {
    fill: #FFF;
  }
`;

const InnerWrapper = styled.div`
  z-index: ${props => props.zIndex};
  width: ${props => props.width};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 50%;
  background: ${props => props.background};
`;

const ProWrapper = styled.div`
  position: absolute;
  top: -2px;
  right: -2px;
`;

const FlagWrapper = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
`;

const Image = styled.div`
  width: ${props => props.width};
  height: ${props => props.width};
  background-image: ${props => `url(${props.imageUrl})`};
  cursor: ${props => props.hasOnClick ? 'pointer' : 'default'};
  border: ${props => props.border};
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
`;

const Anonymous = styled.div`
  ${flexCenterColumn()}
  width: ${props => props.width};
  height: ${props => props.width};
  border: ${props => props.border};
  cursor: ${props => props.hasOnClick ? 'pointer' : 'default'};
  border-radius: 50%;
  background: #616161;
  .icon-wrapper {
    width: 60%;
    height: 60%;
  }
`;

const UserName = styled.div`
  width: 60px;
  font-size: ${props => props.fontSize};
  line-height: 18px;
  text-align: center;
`;

@observer
class UserProfileImage extends React.Component {

  static defaultProps = {
    width: 35,
    fontSize: 14,
  }

  constructor(props) {
    super(props);
    this.uuid = uuid();
  }

  @computed get hasOnClick() {
    return this.props.clickToProfile || isPresent(this.props.onClick);
  }

  @autobind onClick() {
    if (this.props.clickToProfile) {
      RouteStore.routeToNamed('profiles.show', {userId: this.props.user.id});
    } else if (this.props.onClick) {
      this.props.onClick();
    }
  }

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <InnerWrapper
          zIndex={this.props.zIndex}
          width={pixify(this.props.width)}
          background={AppUI.siteIsDefault ? 'linear-gradient(-134deg,#3BD7C5 0%,#0DAFF6 100%)' : 'linear-gradient(-134deg,#efefef 0%,#dddddd 100%)'}
        >
          {
            !this.props.showLanguageIcon && this.props.user && this.props.user.isMember && !this.props.hideProStatus &&
              <ProWrapper>
                <div
                  data-tip
                  data-for={this.uuid}
                >
                  <CrownIcon
                    width={pixify(Math.floor(this.props.width / 2.5))}
                    showBackground
                    background={(this.props.user.admin || this.props.user.moderator || this.props.user.translator) ? Theme.purple : '#13AB5E'}
                  />
                  {
                    !this.props.hideTooltip &&
                      <ReactTooltip
                        place='top'
                        type='dark'
                        effect='solid'
                        id={this.uuid}
                        class='custom-tooltip'
                      >
                        {this.props.user.title}
                      </ReactTooltip>
                  }
                </div>
              </ProWrapper>
          }
          {
            this.props.showLanguageIcon && this.props.user &&
              <FlagWrapper>
                <LanguageIcon
                  width='20px'
                  showTooltip
                  languageId={this.props.user.languageId}
                />
              </FlagWrapper>
          }
          {
            this.props.user && this.props.user.imageUrl &&
              <Image
                margin={this.props.margin}
                border={this.props.border}
                onClick={this.onClick}
                hasOnClick={this.hasOnClick}
                width={pixify(this.props.width)}
                imageUrl={this.props.user.imageUrl}
              />
          }
          {
            (!this.props.user || isBlank(this.props.user.imageUrl)) &&
              <Anonymous
                margin={this.props.margin}
                onClick={this.onClick}
                hasOnClick={this.hasOnClick}
                width={pixify(this.props.width)}
                border={this.props.border || this.props.anonymousBorder}
              >
                <UserIcon />
              </Anonymous>
          }
          {
            this.props.displayName &&
              <UserName
                fontSize={this.props.fontSize}
              >
                {this.props.user.name}
              </UserName>
          }
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(UserProfileImage, {hideText: true});
