import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import DeleteButton from 'components/button/delete_button';
import InPlaceText from 'components/form/in_place_text';
import { LanguageIcon } from '@seedlang/icons';
import { Languages } from '@seedlang/constants';
import { AppUI } from '@seedlang/state';
import { isBlank, isPresent } from '@seedlang/utils';
import InPlaceLanguageSelect from 'components/form/in_place_language_select';
import autobind from 'autobind-decorator';
import { TranslationSourceStore } from '@seedlang/stores';
import Spinner from 'components/spinner';
import { some } from 'lodash';
import Alert from 'components/alert';
import InfoTooltip from 'components/info_tooltip';

const Wrapper = styled.div`
  margin: ${props => props.margin};
  overflow: auto;
  height: 600px;
  table {
    margin-top: 10px;
  }
`;

const LangWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Arrows = styled.div`
  display: flex;
  flex-direction: column;
  i {
    margin: 2px 0;
    color: #333;
    cursor: pointer;
  }
`;

const AddSource = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
`;

const Column = styled.div`
  width: ${props => props.width};
  margin-right: 10px;
`;

@observer
class SentenceSourceEdit extends React.Component {
  @observable text;
  @observable languageId;
  @observable showSpinner = false;
  @observable showUkIdiomaticSource = false;

  @autobind onSubmit() {
    if (isBlank(this.languageId)) { return; }
    const data = {
      user_id: AppUI.user.id,
      force_accepted: true,
      translation_id: this.props.sentence.id,
      text: this.text,
      language_id: this.languageId,
    };
    AppUI.translationSourceStore.create({data: data}, this.afterSubmit);
  }

  @autobind afterSubmit() {
    this.text = null;
    this.props.onChange();
  }

  @computed get translationSources() {
    return this.props.sentence.translationSources;
  }

  @autobind onRemove(translationSourceId) {
    TranslationSourceStore.destroy({ ids: { translationSourceId: translationSourceId } }, this.props.onChange.bind(this));
  }

  @computed get sourceForLanguage() {
    if (isBlank(this.languageId)) { return; }
    return this.translationSources.find(item => item.source?.languageId === this.languageId)?.source;
  }

  render() {
    return (
      <Wrapper>
        <h5>Sources for "{this.props.sentence.targetText}"</h5>
        <table className='table-wrapper admin-table'>
          <thead>
          <tr>
            <th>Source</th>
            <th>User</th>
            <th>Lang</th>
            <th width='30' />
          </tr>
          </thead>
          <tbody>
          {
            this.translationSources.sort((a, b) => a.source?.languageId < b.source?.languageId ? - 1 : 1).filter(item => isPresent(item.source)).map(item => {
              return (
                <tr key={item.id}>
                  <td
                    style={{paddingLeft: 10}}
                  >
                    <InPlaceText
                      defaultValue={item.source.text}
                      model='sources'
                      field='text'
                      id={item.source.id}
                    />
                    {
                      item.source.languageId === 'EN' && (item.source.ukSpelling || this.showUkIdiomaticSource) &&
                      <>
                        <div style={{marginTop: '10px', fontWeight: 'bold'}}>
                          British Idiomatic Source <InfoTooltip position='right'>The default source displayed to users who chose British English.</InfoTooltip>
                        </div>
                        <InPlaceText
                          placeholder='No British spelling variant'
                          defaultValue={item.source.ukSpelling}
                          model='sources'
                          field='ukSpelling'
                          rejectBlank={false}
                          id={item.source.id}
                        />
                      </>
                    }
                    {
                      item.source.languageId === 'EN' && isBlank(item.source.ukSpelling) && !this.showUkIdiomaticSource &&
                      <div style={{marginTop: '10px'}}>
                        <span
                          onClick={() => this.showUkIdiomaticSource = true}
                          className='underline'
                          style={{marginRight: 20, fontSize: '14px'}}
                        >
                          <i className='fa fa-plus' style={{marginTop: '5px'}} /> Add British Idiomatic Source
                        </span>
                      </div>
                    }
                  </td>
                  <td>
                    <div style={{display: 'flex'}}>
                      {item.user?.name}{isPresent(item.user?.role) ? ` (${item.user?.role})` : ''}
                      {item.translatedByAi ? 'AI' : ''}
                    </div>
                  </td>
                  <td>
                    {
                      item.source && item.source?.languageId &&
                      <LangWrapper>
                        <LanguageIcon
                          languageId={item.source.languageId}
                          showTooltip
                          margin='0 5px 0 0'
                        />
                        {item.source.languageId}
                      </LangWrapper>
                    }
                  </td>
                  <td>
                    {
                      AppUI.user.admin &&
                      <DeleteButton
                        onConfirm={this.onRemove.bind(this, item.id)}
                        message='Remove this source from this sentence?'
                        className='fa-block right'
                      />
                    }
                  </td>
                </tr>
              );
            })
          }
          </tbody>
        </table>
        <AddSource>
          <Column width='300px'>
            <InPlaceText
              placeholder='Enter a new source'
              value={this.text}
              onChange={val => this.text = val}
              style={{ padding: '20px 10px' }}
              show
            />
          </Column>
          <Column
            width='150px'
          >
            <InPlaceLanguageSelect
              onChange={val => this.languageId = val}
              value={this.languageId}
              includeBlank
              showAbbreviation
              showEdit
            />
          </Column>
          <Column
            width='80px'
          >
            <button
              onClick={this.onSubmit}
              className='button-primary'
            >Submit</button>
          </Column>
        </AddSource>
        {
          isPresent(this.sourceForLanguage) &&
            <Alert
              margin='5px 0 0 0'
            >
              <div>{`Adding a ${Languages[this.languageId]} source will delete the existing one:`}</div>
              <div><i>{this.sourceForLanguage?.text}</i></div>
            </Alert>
        }
        {
          this.showSpinner &&
          <Spinner />
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SentenceSourceEdit);
