import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { DeckUI } from '@seedlang/state';
import { pixify, isPresent } from '@seedlang/utils';
import CardIcons from 'components/card/card_icons';
import WordCard from 'components/word_card/word_card';
import CardReviewToggle from 'components/card/card_review_toggle';

const Wrapper = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  padding: ${props => props.padding};
  background: ${props => props.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px 0 10px;
  overflow-x: hidden;
`;

const InnerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: ${props => props.height};
  overflow-y: auto;
  margin: 10px 0;
  overflow-x: hidden;
`;

@observer
class CardWrapper extends React.Component {

  static defaultProps = {
    padding: 0,
    background: '#333',
  }

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.deckHeightWithoutHeader)}
        width={pixify(DeckUI.layout.deckWidth)}
        padding={pixify(this.props.padding)}
        background={this.props.background}
      >
        <InnerWrapper
          height={pixify(DeckUI.layout.innerDeckHeightWithoutHeader)}
        >
          {
            this.props.card &&
              <CardReviewToggle
                card={this.props.card}
                disableToggleButton
              />
          }
          {this.props.children}
          {
            this.props.showWordCard &&
              <WordCard
                card={this.props.card}
                sentence={this.props.card.sentence}
                word={this.props.card.sentence && this.props.card.sentence.selectedWord}
                wordAssociation={this.props.card.sentence && this.props.card.sentence.selectedWordAssociation}
              />
          }
          {
            isPresent(this.props.icons) && !DeckUI.hideCardIcons &&
              <CardIcons
                card={this.props.card}
                userCard={this.props.userCard}
                cardState={this.props.cardState}
                icons={this.props.icons}
                flagLanguageId={this.props.sourceLanguageId}
              />
          }
        </InnerWrapper>
      </Wrapper>
    );
  }
}

export default CardWrapper;
