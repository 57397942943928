import React from 'react';
import { observer } from 'mobx-react';
import { TargetStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import TranslationIndex from 'pages/builder/translations/translation_index';
import InPlaceText from 'components/form/in_place_text';
import InPlaceVideoUpload from 'components/form/in_place_video_upload';
import CheckIfTrue from 'components/check_if_true';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { Constants } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';
import { AppUI } from '@seedlang/state';

@observer
class TargetEdit extends React.Component {

  componentDidMount() {
    this.loadTarget();
  }

  @autobind afterChange() {
    this.loadTarget();
  }

  @autobind onResolveMixedGender() {
    TargetStore.resolveMixedGender({ids: {targetId: this.props.params.targetId}}, this.loadTarget);
  }

  @autobind loadTarget() {
    TargetStore.getShow({ ids: {targetId: this.props.params.targetId }}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    TargetStore.setShowData(resp);
  }

  @autobind updateTargets() {
    TargetStore.updateTranslations({ids: {targetId: this.props.params.targetId }}, this.loadTarget);
  }

  render() {
    return (
      <div className='target-edit'>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Video Clip</legend>
              <InPlaceVideoUpload
                model='video_clips'
                removeModel='target'
                removeId={TargetStore.showData.id}
                videoClip={TargetStore.showData.videoClip}
                onChange={this.afterChange}
                querystring={{
                  videoable_id: TargetStore.showData.id,
                  videoable_type: 'Target',
                }}
              />

              {
                isPresent(TargetStore.showData?.videoClip) &&
                  <div>
                    <InPlaceCheckbox
                        icon='check'
                        model='targets'
                        id={TargetStore.showData.id}
                        value={TargetStore.showData.replaceVideo}
                        field='replace_video'
                    >
                      Replace Video
                    </InPlaceCheckbox>
                  </div>
              }
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Created at</legend>
              {TargetStore.showData.createdAt?.formattedDateWithYear}
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Text</legend>
              <InPlaceText
                model='targets'
                id={TargetStore.showData.id}
                defaultValue={TargetStore.showData.text}
                field='text'
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Text (normalized)</legend>
              {TargetStore.showData.normalizedText}
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Language</legend>
              {TargetStore.hasShowData && Constants.LANGUAGE_OPTIONS[TargetStore.showData.languageId]}
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Gender
                {
                  AppUI.user.developer &&
                    <span
                      data-for='has-genders-info'
                      data-tip
                      style={{marginLeft: '5px'}}
                    >
                      <i className='fa fa-info-circle'/>
                      <ReactTooltip
                          place='right'
                          type='dark'
                          effect='solid'
                          id='has-genders-info'
                          className='custom-tooltip'
                      >
                        <div>Check if the word is a noun,</div>
                        <div>or a French or Spanish adjective (video with both genders).</div>
                      </ReactTooltip>
                    </span>
                }
              </legend>
              {
                  AppUI.user.developer &&
                  <div
                      style={{display: 'flex'}}
                  >
                    <InPlaceCheckbox
                        icon='check'
                        model='targets'
                        id={TargetStore.showData.id}
                        value={TargetStore.showData.hasGenders}
                        field='has_genders'
                    />
                    <InPlaceSelect
                        clickToEdit={TargetStore.showData.gender}
                        options={[
                          ['feminine', 'feminine'],
                          ['masculine', 'masculine'],
                          ['neuter', 'neuter'],
                        ]}
                        model='targets'
                        field='gender'
                        includeBlank
                        id={TargetStore.showData.id}
                        value={TargetStore.showData.gender}
                        afterChange={this.afterChange}
                    />
                  </div>
              }
              {
                  !AppUI.user.developer &&
                    <div
                        style={{display: 'flex'}}
                    >
                      <CheckIfTrue
                          value={TargetStore.showData.hasGenders}
                      />
                      <div>
                        {TargetStore.showData.gender}
                      </div>
                    </div>
              }
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Translations</legend>
              {
                TargetStore.hasShowData &&
                  <TranslationIndex
                    translations={TargetStore.showData.translations}
                    targetType={TargetStore.showData.translationType}
                  />
              }
            </fieldset>
          </div>
          {
              AppUI.user.developer && TargetStore.showData.translationType === 'Word' &&
              <button
                  className='gray-button'
                  style={{marginTop: 10}}
                  onClick={this.updateTargets}
              >
                <i className='fa fa-plus' />
                Update translations targets
              </button>
          }
        </div>
      </div>
    );
  }
}

export default TargetEdit;
