import { PageStore } from '@seedlang/stores';
import DeleteButton from 'components/button/delete_button';
import Modal from 'components/modal';
import Paginator from 'components/paginator';
import { computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import PageCreate from 'pages/builder/pages/page_create';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import React from 'react';
import { Link } from 'react-router';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';

@observer
class PageIndex extends React.Component {
  static defaultProps = {
    whiteLabeled: false,
  }

  @observable showCreate = false;

  componentDidMount() {
    PageStore.getIndex();
    AppUI.navigationItemStore.getIndex();
  }

  componentWillUnmount() {
    PageStore.clearIndexData();
  }

  onDelete(id) {
    PageStore.destroy({ ids: { pageId: id } });
  }

  @computed get pages() {
    return this.props.whiteLabeled ? PageStore.indexData.filter(item => item.siteId === this.props.siteId) : PageStore.indexData;
  }

  @computed get navigationItemsSlugs() {
    return AppUI.navigationItemStore.indexData
      .filter(item => item.path.startsWith('/pages'))
      .map(item => item.path.replace('/pages/', ''));
  }

  @autobind isDeletable(page) {
    const hasAssociatedNavigationItem = this.navigationItemsSlugs.includes(page.slug);
    const hasUniqueSlug = this.pages.filter(p => p.slug === page.slug).length === 1;
    return !hasAssociatedNavigationItem || !hasUniqueSlug;
  }

  render() {
    return (
      <div className='page-index'>
        {
          this.props.whiteLabeled &&
            <BackEndExplainer margin='10px 0'>You can create pages with custom content. Remember to add their path to <Link to={{ name: 'creator.groups.site_navigation', params: { groupId: this.props.params.groupId } }} className='underline'>Site Navigation</Link> if you want the pages to be visible in the header, footer or user settings.</BackEndExplainer>
        }
        {
          !this.props.hideCreateButton && this.showCreate &&
          <Modal
            onCloseModal={() => this.showCreate = false}
          >
            <PageCreate />
          </Modal>
        }
        <div style={{display: 'flex', marginBottom: '10px'}}>
          <div style={{width: 150}}>
            {
              !this.props.hideCreateButton && (
                <button
                  className='gray-button'
                  onClick={() => this.showCreate = true}
                >
                  <i className='fa fa-plus' />
                  Create
                </button>
              )
            }
          </div>
        </div>
        <table className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='40'></th>
              <th width='40'></th>
              <th>Title</th>
              {
                !this.props.whiteLabeled &&
                  <th>Slug</th>
              }
              <th>{this.props.whiteLabeled ? 'Path' : 'Site'}</th>
              <th>Memberships</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              this.pages.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={this.props.whiteLabeled ? { name: 'creator.groups.site_pages.edit', params: { groupId: this.props.params.groupId, pageId: item.id } } : { name: 'builder.page.edit', params: { pageId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                    {
                      item.slug && (
                        <Link
                          to={{ name: 'page.show', params: { pageId: item.slug } }}
                          className='on-click'
                        >
                          <i className='fa fa-external-link fa-block' />
                        </Link>
                      )
                    }
                    </td>
                    <td>
                      <div className='text'>
                        {item.title}
                      </div>
                    </td>
                    {
                      !this.props.whiteLabeled &&
                        <td>
                          <div className='text'>
                            {item.slug}
                          </div>
                        </td>
                    }
                    <td>
                      <div className='text'>
                        {this.props.whiteLabeled ? (`/pages/${item.slug}`) : item.siteName}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.membershipTypes.map(type => type.name).join(', ')}
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={this.onDelete.bind(this, item.id)}
                        message={
                          this.isDeletable(item)
                            ? 'Delete this page?'
                            : 'Remove the link in Site Navigation before deleting this page'
                          }
                        className='fa-block right'
                        showTooltip={!this.isDeletable(item)}
                        disabled={!this.isDeletable(item)}
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={PageStore}
        />
      </div>
    );
  }
}

export default PageIndex;
