import React from 'react';
import { Link } from 'react-router';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import {
  ConceptCategoryStore,
  ConceptChildWordStore,
  ConceptGrammarDeckStore,
  ConceptGrammarTreeNodeStore,
  ConceptInfinitiveVerbStore,
  ConceptRootWordStore,
  ConceptStore,
  ConceptTreeNodeStore,
  ConceptWordStore,
  DeckStore,
  DependentIntersectingConceptStore,
  DependentUnionConceptStore,
  IntersectingConceptStore,
  IntersectingWordStore,
  LevelStore,
  SentenceConceptStore,
  SpacyAttributeStore,
  TreeNodeGrammarConceptStore,
  UnionConceptStore,
  WordStore,
  WordTypeStore,
} from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import SearchMultiSelect from 'components/form/search_multi_select';
import SentenceIndex from 'pages/builder/sentences/sentence_index';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceImageUpload from 'components/form/in_place_image_upload';
import InPlaceVideoUpload from 'components/form/in_place_video_upload';
import autobind from 'autobind-decorator';
import SentenceCreate from 'pages/builder/sentences/sentence_create';
import ConceptIndex from 'pages/builder/concepts/concept_index';
import Modal from 'components/modal';
import { isBlank, isPresent } from '@seedlang/utils';
import { Constants } from '@seedlang/constants';
import ConceptBatchAddWords from 'pages/builder/concepts/concept_batch_add_words';
import DeleteButton from 'components/button/delete_button';
import cx from 'classnames';
import styled from '@emotion/styled';
import SentenceSearch from 'components/sentence_search';
import RatingInput from 'components/rating_input';
import ReactTooltip from 'react-tooltip';
import TreeNodeSearch from 'components/tree_node_search';
import YoutubeVideo from 'components/youtube_video';
import Text from 'components/text';
import Spinner from 'components/spinner';
import Alert from 'components/alert';
import { Theme } from '@seedlang/constants/src';

const ReviewedUser = styled.div`
  font-size: 12px;
  padding-left: 18px;
  background: #FFF;
`;

const Wrapper = styled.div`
  .concept-edit {
    label {
      margin-left: 5px;
      margin-bottom: 2px;
    }
    .buttons {
      display: flex;
      justify-content: flex-start;
      padding-top: 5px;
      button {
        margin-right: 5px;
      }
    }
    .word-info {
      position: relative;
    }
    .description {
      .in-place-text-wrapper {
        .rich-entry-save {
          position: absolute;
          top: 16px;
          right: 10px;
          padding: 5px;
          height: 30px;
          line-height: 20px;
        }
      }
    }
    .concept-edit-message {
      background: orange;
      color: white;
      font-size: 14px;
      padding: 10px;
      margin-bottom: 10px;
    }
    textarea {
      height: 500px;
    }
    .in-place-checkbox.with-label {
      border: none;
      display: inline-flex;
    }
    .in-place-select {
      width: 100%;
    }
    fieldset.short-description {
      min-height: 30px;
    }

    fieldset {
      position: relative;
    }

    .paginator {
      margin: 10px 0 0 0;
      li {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .checkbox.published.false-value {
      label {
        color: red;
      }
    }
  }
`;

const Note = styled.div`
  background: #d3d3d3;
  font-size: 12px;
  padding: 10px;
  margin: 10px 2px;
  line-height: 16px;
  border-radius: 3px;
`;

@observer
class ConceptEdit extends React.Component {
  @observable showCreate = false;
  @observable showRerunCompleteMessage = false;
  @observable showBatchAdd = false;
  @observable clearingAllRoots = false;
  @observable addingAllRoots = false;

  componentDidMount() {
    // ConceptWordStore.setLimit(10);
    ConceptRootWordStore.setLimit(10);
    ConceptChildWordStore.setLimit(10);
    ConceptTreeNodeStore.getIndex({ids: {conceptId: this.props.routeParams.conceptId}});
    ConceptGrammarTreeNodeStore.getIndex({ids: {grammarConceptId: this.props.routeParams.conceptId}, queryStrings: {expanded: true}});
    ConceptGrammarDeckStore.getIndex({ids: {grammarConceptId: this.props.routeParams.conceptId}});
    if (!ConceptCategoryStore.hasIndexData) {
      ConceptCategoryStore.getIndex();
    }
    this.loadConcept();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.conceptId !== prevProps.routeParams.conceptId) {
      this.loadConcept();
    }
  }

  componentWillUnmount() {
    ConceptStore.clearShowData();
    ConceptRootWordStore.clearIndexData();
    ConceptChildWordStore.clearIndexData();
    ConceptInfinitiveVerbStore.clearIndexData();
  }

  @autobind loadConcept() {
    ConceptStore.getShow({ ids: {conceptId: this.props.routeParams.conceptId }}, this.afterGetShow);
    this.loadSentences();
  }

  @autobind afterGetShow(resp) {
    ConceptStore.setShowData(resp);
    if (resp.joinType === 'or' || resp.joinType === 'verb') {
      ConceptRootWordStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
      ConceptChildWordStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
    }
    if (resp.joinType === 'verb') {
      this.loadConceptInfinitiveVerbs();
    }
    if (resp.joinType === 'or') {
      DependentIntersectingConceptStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
      DependentUnionConceptStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
    }
  }

  @autobind onDeleteSentence(id) {
    SentenceConceptStore.destroyByIds({ data: { sentence_id: id, concept_id: this.props.routeParams.conceptId } }, this.loadSentences);
  }

  @autobind onChange() {
    ConceptStore.reloadShow();
    ConceptRootWordStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
    ConceptChildWordStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
    this.loadSentences();
  }

  @autobind onClearAllInfinitiveVerbs() {
    ConceptStore.update({data: {infinitive_verbs: []}, ids: {conceptId: this.props.routeParams.conceptId}}, this.loadConceptInfinitiveVerbs);
  }

  @autobind onClearAllSentences() {
    ConceptStore.update({data: {sentences: []}, ids: {conceptId: this.props.routeParams.conceptId}}, this.loadSentences);
  }

  @autobind onClearAllWords() {
    ConceptStore.update({data: {words: []}, ids: {conceptId: this.props.routeParams.conceptId}}, this.loadConceptWords);
  }

  @autobind onClearAllRootWords() {
    this.clearingAllRoots = true;
    ConceptStore.clearAllRoots({ids: {conceptId: ConceptStore.showData.id}}, this.afterClearAllRootWords);
  }

  @autobind afterClearAllRootWords() {
    this.clearingAllRoots = false;
    this.loadConceptWords();
  }

  @autobind deleteYoutubeUrl() {
    ConceptStore.update({data: {youtube_url: null}, ids: {conceptId: this.props.routeParams.conceptId}}, this.onChange);
  }

  @autobind afterRerun() {
    this.showRerunCompleteMessage = true;
    setTimeout(this.closeRerunComplete, 3000);
    this.onChange();
  }

  @autobind closeRerunComplete() {
    this.showRerunCompleteMessage = false;
  }

  @autobind loadConceptWords() {
    ConceptChildWordStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
    ConceptRootWordStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
    // this.onChange();
  }

  @autobind loadConceptInfinitiveVerbs() {
    ConceptInfinitiveVerbStore.getIndex({ids: { conceptId: this.props.routeParams.conceptId }});
    this.onChange();
  }

  @autobind onCreateIntersectingWord(word) {
    IntersectingWordStore.create({ ids: { conceptId: ConceptStore.showDataField('id') }, data: { id: word.id } }, this.onChange);
  }

  @autobind onDestroyIntersectingWord(id) {
    IntersectingWordStore.destroy({ ids: { intersectingWordId: id, conceptId: ConceptStore.showDataField('id') } }, this.onChange);
  }

  @autobind onCreateNonRootConceptWord(word) {
    ConceptChildWordStore.create({ data: { concept_id: ConceptStore.showDataField('id'), word_id: word.id } }, this.loadConceptWords);
  }

  @autobind onDestroyNonRootConceptWord(id) {
    ConceptChildWordStore.destroy({ ids: { concept_id: ConceptStore.showDataField('id'), word_id: id } }, this.loadConceptWords);
  }

  @autobind onCreateConceptRootWord(word) {
    ConceptRootWordStore.create({ data: { concept_id: ConceptStore.showDataField('id'), word_id: word.id } }, this.loadConceptWords);
  }

  @autobind onDestroyConceptRootWord(id) {
    ConceptRootWordStore.destroy({ ids: { concept_id: ConceptStore.showDataField('id'), word_id: id } }, this.loadConceptWords);
  }

  @autobind onCreateConceptInfinitiveVerb(word) {
    ConceptInfinitiveVerbStore.create({ data: { concept_id: ConceptStore.showDataField('id'), word_id: word.id } }, this.loadConceptInfinitiveVerbs);
  }

  @autobind onDestroyConceptInfinitiveVerb(id) {
    ConceptInfinitiveVerbStore.destroy({ ids: { concept_id: ConceptStore.showDataField('id'), word_id: id } }, this.loadConceptInfinitiveVerbs);
  }

  @autobind refreshConcept() {
    ConceptStore.refresh({ids: {conceptId: ConceptStore.showData.id}}, this.afterRerun);
  }

  @autobind addAllRoots() {
    this.addingAllRoots = true;
    ConceptStore.addAllRoots({ids: {conceptId: ConceptStore.showData.id}}, this.afterAddAllRoots);
  }

  @autobind afterAddAllRoots() {
    this.loadConceptWords();
    this.addingAllRoots = false;
  }

  @autobind onCreateIntersectingConcept(concept) {
    IntersectingConceptStore.create({ ids: { conceptId: ConceptStore.showDataField('id') }, data: { id: concept.id } }, this.onChange);
  }

  @autobind onDestroyIntersectingConcept(id) {
    IntersectingConceptStore.destroy({ ids: { intersectingConceptId: id, conceptId: ConceptStore.showDataField('id') } }, this.onChange);
  }

  @autobind onCreateUnionConcept(concept) {
    UnionConceptStore.create({ ids: { conceptId: ConceptStore.showDataField('id') }, data: { id: concept.id } }, this.onChange);
  }

  @autobind onDestroyUnionConcept(id) {
    UnionConceptStore.destroy({ ids: { unionConceptId: id, conceptId: ConceptStore.showDataField('id') } }, this.onChange);
  }

  @autobind loadSentences() {
    SentenceConceptStore.getIndex({ limit: 20, ids: { conceptId: this.props.routeParams.conceptId } });
  }

  @autobind onDeleteSpacyAttribute(id) {
    SpacyAttributeStore.destroy({ids: {spacyAttributeId: id}}, this.loadConcept);
  }

  @autobind onCreateSpacyAttribute() {
    SpacyAttributeStore.create({data: {
      conceptId: ConceptStore.showData.id,
    }}, this.loadConcept);
  }

  @autobind onAddSentence(sentence) {
    SentenceConceptStore.create({data: {sentence_id: sentence.id, concept_id: ConceptStore.showData.id}}, this.loadConcept);
  }

  @autobind onClickRating(value) {
    const rating = value === ConceptStore.showData.rating ? null : value;
    ConceptStore.update({ids: {conceptId: ConceptStore.showData.id}, data: {rating: rating}}, this.loadConcept);
  }

  @autobind onCreateDeck() {
    DeckStore.create({data: {vocab_triad: true, concept_id: ConceptStore.showData.id}}, this.refreshConcept);
  }

  @autobind refreshGrammarTreeNodesAndDecks() {
    ConceptGrammarTreeNodeStore.getIndex({ids: {grammarConceptId: this.props.routeParams.conceptId}, queryStrings: {expanded: true}});
    ConceptGrammarDeckStore.getIndex({ids: {grammarConceptId: this.props.routeParams.conceptId}});
  }

  @autobind onDeleteTreeNodeGrammarConcept(treeNode) {
    const treeNodeGrammarConceptId = treeNode.treeNodeGrammarConcepts.find(item => item.concept?.id === this.props.routeParams.conceptId)?.id;
    if (treeNodeGrammarConceptId) {
      TreeNodeGrammarConceptStore.destroy({ids: {treeNodeGrammarConceptId: treeNodeGrammarConceptId}}, this.refreshGrammarTreeNodesAndDecks);
    }
  }

  @autobind onCreateTreeNodeGrammarConcept(treeNode) {
    TreeNodeGrammarConceptStore.create({data: {tree_node_id: treeNode.id, concept_id: this.props.routeParams.conceptId}}, this.refreshGrammarTreeNodesAndDecks);
  }

  _content() {
    if (ConceptStore.hasShowData) {
      return (
        <div className='concept-edit'>
          {
            this.showCreate &&
              <Modal
                onCloseModal={() => this.showCreate = false}
              >
                <SentenceCreate
                  afterCreate={this.loadSentences}
                  conceptId={ConceptStore.showData.id}
                />
              </Modal>
          }
          {
            this.showBatchAdd &&
              <Modal
                onCloseModal={() => this.showBatchAdd = false}
              >
                <ConceptBatchAddWords
                  afterCreate={this.loadConceptInfinitiveVerbs}
                  conceptId={ConceptStore.showData.id}
                  wordTypeId={ConceptStore.showData.wordTypeId}
                  wordTypes={WordTypeStore.indexData}
                />
              </Modal>
          }
          <div className='row'>
            <div className='col-xs-6'>
              <fieldset>
                <legend>Name</legend>
                <InPlaceText
                  defaultValue={ConceptStore.showDataField('name')}
                  model='concepts'
                  field='name'
                  id={ConceptStore.showDataField('id')}
                />
              </fieldset>
              <fieldset>
                <legend>Rating</legend>
                <RatingInput
                  hideTooltip
                  starWidth='24px'
                  id={ConceptStore.showDataField('id')}
                  maxRating={3}
                  defaultValue={ConceptStore.showDataField('rating')}
                  onClick={value => this.onClickRating(value)}
                />
              </fieldset>
              <fieldset style={{position: 'relative'}}>
                <legend>Approved</legend>
                  <div>
                    <InPlaceCheckbox
                      model='concepts'
                      field='reviewed2'
                      value={ConceptStore.showData.reviewed2}
                      id={ConceptStore.showData.id}
                      icon='thumbs-up'
                      afterChange={this.onChange}
                    >
                      Didactics 1
                    </InPlaceCheckbox>
                  </div>
                  {
                    isPresent(ConceptStore.showData.reviewed2User) &&
                      <ReviewedUser>
                        {ConceptStore.showData.reviewed2User.name}
                      </ReviewedUser>
                  }
                  <div>
                    <InPlaceCheckbox
                      model='concepts'
                      field='reviewed3'
                      value={ConceptStore.showData.reviewed3}
                      id={ConceptStore.showData.id}
                      icon='thumbs-up'
                      afterChange={this.onChange}
                    >
                      Didactics 2
                    </InPlaceCheckbox>
                  </div>
                  {
                    isPresent(ConceptStore.showData.reviewed3User) &&
                      <ReviewedUser>
                        {ConceptStore.showData.reviewed3User.name}
                      </ReviewedUser>
                  }
                  <div>
                    <InPlaceCheckbox
                      model='concepts'
                      field='reviewed1'
                      value={ConceptStore.showData.reviewed1}
                      id={ConceptStore.showData.id}
                      icon='thumbs-up'
                      afterChange={this.onChange}
                    >
                      English
                    </InPlaceCheckbox>
                  </div>
                  {
                    isPresent(ConceptStore.showData.reviewed1User) &&
                      <ReviewedUser>
                        {ConceptStore.showData.reviewed1User.name}
                      </ReviewedUser>
                  }
              </fieldset>
              <fieldset>
                <legend>Language</legend>
                <InPlaceSelect
                  includeBlank
                  value={ConceptStore.showDataField('languageId')}
                  model='concepts'
                  field='language_id'
                  options={[['FR', 'French'], ['DE', 'German'], ['ES', 'Spanish']]}
                  id={ConceptStore.showDataField('id')}
                  afterChange={this.onChange}
                />
              </fieldset>
              <fieldset>
                <legend>Display Name (Line 1)</legend>
                <InPlaceText
                  defaultValue={ConceptStore.showDataField('displayNameLine1')}
                  model='concepts'
                  field='display_name_line_1'
                  id={ConceptStore.showDataField('id')}
                />
              </fieldset>
              <fieldset>
                <legend>Display Name (Line 2)</legend>
                <InPlaceText
                  defaultValue={ConceptStore.showDataField('displayNameLine2')}
                  model='concepts'
                  field='display_name_line_2'
                  id={ConceptStore.showDataField('id')}
                />
              </fieldset>
              <Note>
                Use <b>Line 1</b> and <b>Line 2</b> for more specific verb tenses such as <a className='underline' href='https://seedlang.com/builder/concepts/bf060a94-a80d-470d-b204-5469fe3e2792'>"Stem-changing Verb (e -> i)"</a>. This will override the default word type concept.
              </Note>
              <fieldset>
                <legend>Icon</legend>

                <InPlaceSelect
                  includeBlank
                  value={ConceptStore.showDataField('icon')}
                  model='concepts'
                  options={Constants.WORD_TYPE_ICONS.sort()}
                  field='icon'
                  id={ConceptStore.showDataField('id')}
                  afterChange={this.onChange}
                />
              </fieldset>
              <fieldset className='short_description'>
                <legend>Short Description (Used for grammar hints in Gender/Plural cards)</legend>
                <InPlaceText
                  defaultValue={ConceptStore.showDataField('shortDescription')}
                  model='concepts'
                  field='short_description'
                  id={ConceptStore.showDataField('id')}
                />
              </fieldset>
              <fieldset className='description'>
                <legend>Long Description</legend>
                <InPlaceCheckbox
                  model='concepts'
                  field='useConceptCategoryDescription'
                  value={ConceptStore.showData.useConceptCategoryDescription}
                  id={ConceptStore.showData.id}
                >
                  Use Category Description
                </InPlaceCheckbox>
                <InPlaceText
                  richText
                  wrapperClassName='word-info'
                  defaultValue={ConceptStore.showData.longDescription}
                  model='concepts'
                  field='long_description'
                  inputType='textarea'
                  id={ConceptStore.showData.id}
                  afterChange={this.onChange}
                />
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <fieldset>
                <legend>General Configuration</legend>
                <div className='row'>
                  <div className='col-xs-4'>
                    <InPlaceCheckbox
                      model='concepts'
                      field='automaticAssociations'
                      afterChange={this.onChange}
                      value={ConceptStore.showData.automaticAssociations}
                      id={ConceptStore.showData.id}
                    >
                      Automatic
                    </InPlaceCheckbox>
                  </div>
                  {
                    ConceptStore.showData.automaticAssociations && (
                      <div className='col-xs-4'>
                        <InPlaceSelect
                          model='concept'
                          field='joinType'
                          options={[['or', 'Join with \'or\''], ['and', 'Join with \'and\''], ['verb', 'Join with \'verb\'']]}
                          id={ConceptStore.showData.id}
                          afterChange={this.onChange}
                          value={ConceptStore.showData.joinType}
                        />
                      </div>
                    )
                  }
                  {
                    !ConceptStore.showData.automaticAssociations && (
                      <div className='col-xs-4'>
                        <InPlaceCheckbox
                          model='concepts'
                          field='sentencesMaintainOrdering'
                          afterChange={this.onChange}
                          value={ConceptStore.showData.sentencesMaintainOrdering}
                          id={ConceptStore.showData.id}
                        >
                          Keep Ordering
                        </InPlaceCheckbox>
                      </div>
                    )
                  }
                  <div className='col-xs-4'>
                    <InPlaceSelect
                      options={LevelStore.indexData}
                      optionName='nameWithAbbreviation'
                      model='concepts'
                      field='levelId'
                      id={ConceptStore.showData.id}
                      value={ConceptStore.showData.levelId}
                      includeBlank='true'
                      placeholder='Select Level'
                      afterChange={this.onChange}
                    />
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Display</legend>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='show_as_word_type'
                    value={ConceptStore.showData.showAsWordType}
                    id={ConceptStore.showData.id}
                    afterChange={this.onChange}
                  >
                    Default Word Type
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='show_as_concept'
                    value={ConceptStore.showData.showAsConcept}
                    id={ConceptStore.showData.id}
                    afterChange={this.onChange}
                  >
                    Show as Concept (Displayed Below Word Type)
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='show_as_infinitive'
                    value={ConceptStore.showData.showAsInfinitive}
                    id={ConceptStore.showData.id}
                  >
                    Show as Infinitive
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='show_on_gender_card'
                    value={ConceptStore.showData.showOnGenderCard}
                    id={ConceptStore.showData.id}
                    afterChange={this.onChange}
                  >
                    Show on Gender Card
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='show_on_plural_card'
                    value={ConceptStore.showData.showOnPluralCard}
                    id={ConceptStore.showData.id}
                    afterChange={this.onChange}
                  >
                    Show on Plural Card
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='vocab'
                    value={ConceptStore.showData.vocab}
                    id={ConceptStore.showData.id}
                    afterChange={this.onChange}
                  >
                    List as Vocab (ex: sich befinden)
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='showInDictionary'
                    value={ConceptStore.showData.showInDictionary}
                    id={ConceptStore.showData.id}
                    afterChange={this.onChange}
                  >
                    Show in dictionary
                    <span
                      data-for='showInDictionary'
                      data-tip
                      style={{marginLeft: '5px'}}
                    >
                      <i className='fa fa-info-circle' />
                      <ReactTooltip
                        place='right'
                        type='dark'
                        effect='solid'
                        id='showInDictionary'
                        className='custom-tooltip'
                      >
                        <div>Will only appear for root words.</div>
                      </ReactTooltip>
                    </span>
                  </InPlaceCheckbox>
                </div>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='displaySentences'
                    value={ConceptStore.showData.displaySentences}
                    id={ConceptStore.showData.id}
                    afterChange={this.onChange}
                  >
                    Display sentences
                  </InPlaceCheckbox>
                </div>
                <div className='row'>
                  <div className='col-xs-12'>
                    <InPlaceSelect
                      options={ConceptCategoryStore.indexData}
                      optionName='name'
                      model='concepts'
                      field='conceptCategoryId'
                      id={ConceptStore.showData.id}
                      value={ConceptStore.showData.conceptCategoryId}
                      includeBlank
                      placeholder='Select a Category'
                      afterChange={this.onChange}
                    />
                    {
                      ConceptStore.showData.conceptCategoryId === '1d691db8-93a8-4eca-af4e-98b2323538a0' && isBlank(ConceptStore.showData.vocabTriadDeckId) &&
                        <button
                          onClick={this.onCreateDeck}
                          className='gray-button'
                        >
                          Create Vocab Triad Deck
                        </button>
                    }
                    {
                      ConceptStore.showData.conceptCategoryId === 'b3b12ab6-d14f-4a33-81cf-e5638c2439d7' && isBlank(ConceptStore.showData.deckIds) &&
                        <Alert background={Theme.green} margin='10px 0 0 0'>
                          To create a trivia deck, add this concept to a tree node.
                        </Alert>
                    }
                    {
                      isPresent(ConceptStore.showData.vocabTriadDeckId) && ConceptStore.showData.conceptCategoryId === '1d691db8-93a8-4eca-af4e-98b2323538a0' &&
                        <Link
                          className='underline'
                          style={{fontSize: '12px', marginTop: '10px'}}
                          to={{name: 'builder.decks.edit', params: {deckId: ConceptStore.showData.vocabTriadDeckId}}}
                        >
                          Edit Vocab Triad Deck
                        </Link>
                    }
                    {
                      ConceptStore.showData.decksCount === 1 && ConceptStore.showData.conceptCategoryId !== '1d691db8-93a8-4eca-af4e-98b2323538a0' &&
                      <Link
                        className='underline'
                        style={{fontSize: '12px', marginTop: '10px'}}
                        to={{name: 'builder.decks.edit', params: {deckId: ConceptStore.showData.deckIds}}}
                      >
                        Edit concept's deck
                      </Link>
                    }
                    {
                      ConceptStore.showData.deckIds.split(',').length > 1 &&
                        <div style={{fontSize: '12px', marginTop: '10px'}}>
                          This concept has several decks:
                          <ol>
                            {
                              ConceptStore.showData.deckIds.split(',').map((deckId, index) => {
                                return (
                                  <Link
                                    className='underline'
                                    to={{name: 'builder.decks.edit', params: {deckId: deckId}}}
                                    key={deckId}
                                  >
                                    Deck n°{index + 1}
                                  </Link>
                                );
                              })
                            }
                          </ol>
                        </div>
                    }
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend>Word Settings</legend>
                <div>
                  <InPlaceCheckbox
                    model='concepts'
                    field='highlightInEdit'
                    value={ConceptStore.showData.highlightInEdit}
                    id={ConceptStore.showData.id}
                  >
                    Show in Edit as chbox
                  </InPlaceCheckbox>
                </div>
                {
                  ConceptStore.showData.noun &&
                    <div>
                      <InPlaceCheckbox
                        model='concepts'
                        field='plural'
                        value={ConceptStore.showData.plural}
                        afterChange={this.loadConceptWords.bind(this)}
                        id={ConceptStore.showData.id}
                      >
                        Plural
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  ConceptStore.showData.verb &&
                    <div>
                      <InPlaceCheckbox
                        model='concepts'
                        field='includeConjugations'
                        value={ConceptStore.showData.includeConjugations}
                        id={ConceptStore.showData.id}
                      >
                        Include Conjugations
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  ConceptStore.showData.verb &&
                    <div>
                      <InPlaceCheckbox
                        model='concepts'
                        field='infinitive'
                        value={ConceptStore.showData.infinitive}
                        afterChange={this.loadConceptWords.bind(this)}
                        id={ConceptStore.showData.id}
                      >
                        Infinitive
                      </InPlaceCheckbox>
                    </div>
                }
                <table className='table-wrapper'>
                  <tbody>
                    <tr>
                      <td width='50%'>Word Type</td>
                      <td>
                        <InPlaceSelect
                          options={WordTypeStore.indexData.map(item => [item.id, item.name])}
                          model='concepts'
                          field='word_type_id'
                          id={ConceptStore.showData.id}
                          value={ConceptStore.showData.wordTypeId}
                          includeBlank='true'
                          afterChange={this.onChange}
                          placeholder='Select a Word Type'
                        />
                      </td>
                    </tr>
                    {
                      ConceptStore.showData.verb && ConceptStore.showData.joinType === 'or' &&
                        <tr>
                          <td>
                            Tense
                          </td>
                          <td>
                            <InPlaceText
                              model='concepts'
                              field='grammar_tense'
                              afterChange={this.loadConceptWords.bind(this)}
                              defaultValue={ConceptStore.showDataField('grammarTense')}
                              id={ConceptStore.showDataField('id')}
                            />
                          </td>
                        </tr>
                    }
                    {
                      ConceptStore.showData.requiresCase &&
                        <tr>
                          <td>
                            Case
                          </td>
                          <td>
                            <InPlaceSelect
                              className={cx({
                                'input-alert': isBlank(ConceptStore.showData.grammarCase),
                              })}
                              placeholder='Select Case'
                              options={Constants.CASES.map(item => item.abbreviation).sort()}
                              model='concepts'
                              field='grammar_case'
                              includeBlank='true'
                              afterChange={this.loadConceptWords.bind(this)}
                              value={ConceptStore.showData.grammarCase}
                              id={ConceptStore.showData.id}
                            />
                        </td>
                      </tr>
                    }
                    <tr>
                      <td>
                        Regex
                      </td>
                      <td>
                        <InPlaceText
                          model='concepts'
                          field='regex'
                          afterChange={this.loadConceptWords.bind(this)}
                          defaultValue={ConceptStore.showDataField('regex')}
                          id={ConceptStore.showDataField('id')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Plural Regex
                      </td>
                      <td>
                        <InPlaceText
                          model='concepts'
                          field='plural_regex'
                          afterChange={this.loadConceptWords.bind(this)}
                          defaultValue={ConceptStore.showDataField('pluralRegex')}
                          id={ConceptStore.showDataField('id')}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Abbreviation
                      </td>
                      <td>
                        <InPlaceText
                          model='concepts'
                          field='abbreviation'
                          defaultValue={ConceptStore.showDataField('abbreviation')}
                          id={ConceptStore.showDataField('id')}
                        />
                      </td>
                    </tr>
                    {
                      ConceptStore.showData.noun &&
                        <tr>
                          <td>
                            Gender
                          </td>
                          <td>
                            <InPlaceSelect
                              options={[['masculine', 'masculine'], ['feminine', 'feminine'], ['neuter', 'neuter']]}
                              model='concepts'
                              field='gender'
                              includeBlank='true'
                              afterChange={this.loadConceptWords.bind(this)}
                              value={ConceptStore.showData.gender}
                              id={ConceptStore.showData.id}
                            />
                          </td>
                        </tr>
                    }
                  </tbody>
                </table>
              </fieldset>
              <fieldset>
                <legend>Image</legend>
                <InPlaceImageUpload
                  model='images'
                  image={ConceptStore.showData.image}
                  onChange={this.onChange}
                  querystring={{
                    imageable_id: ConceptStore.showDataField('id'),
                    imageable_type: 'Concept',
                  }}
                />
              </fieldset>
              <fieldset>
                <legend>Video</legend>
                <InPlaceVideoUpload
                  model='video_clips'
                  removeModel='concepts'
                  removeId={ConceptStore.showData.id}
                  videoClip={ConceptStore.showData.videoClip}
                  onChange={this.onChange}
                  querystring={{
                    videoable_id: ConceptStore.showDataField('id'),
                    videoable_type: 'Concept',
                  }}
                />
              </fieldset>
              <fieldset>
                <legend>Youtube Video</legend>
                {
                  isBlank(ConceptStore.showData.youtubeUrl) &&
                    <InPlaceText
                      show
                      submitOnEnter
                      placeholder='add Youtube embed url. ex: https://www.youtube.com/embed/KcKHImEEXZY'
                      model='concepts'
                      field='youtube_url'
                      id={ConceptStore.showData.id}
                      afterChange={this.onChange}
                    />
                }
                {
                  ConceptStore.showData.youtubeUrl &&
                    <YoutubeVideo
                      url={ConceptStore.showData.youtubeUrl}
                    />
                }
                <button
                  className={cx('button-primary', {
                    disabled: isBlank(ConceptStore.showData.youtubeUrl),
                  })}
                  onClick={this.deleteYoutubeUrl}
                  style={{marginTop: '10px'}}
                >
                  <i className='fa fa-times'/>
                  Delete Youtube Video
                </button>
              </fieldset>
            </div>
          </div>
          {
            ConceptStore.showData.automaticAssociations && ConceptStore.showData.joinType === 'verb' &&
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Infinitive Verbs</legend>
                    <SearchMultiSelect
                      ids={ConceptInfinitiveVerbStore.indexIds}
                      onCreate={this.onCreateConceptInfinitiveVerb}
                      onDestroy={this.onDestroyConceptInfinitiveVerb}
                      searchStore={WordStore}
                      searchFilters={[{key: 'infinitive', value: true}]}
                      searchField='target_text'
                      displayField='targetWithWordTypeParentInfinitiveAndSource'
                      sort='target_text'
                      linkTo='builder.words.edit'
                      linkId='wordId'
                      options={ConceptInfinitiveVerbStore.indexData}
                      paginatorStore={ConceptInfinitiveVerbStore}
                      paginatorIds={{conceptId: this.props.routeParams.conceptId}}
                    />
                    <div className='buttons'>
                      <button
                        className='button-primary'
                        onClick={() => this.showBatchAdd = true}
                      >
                        <i className='fa fa-plus' />
                        Batch Add
                      </button>
                      {
                        ConceptInfinitiveVerbStore.hasIndexData &&
                          <DeleteButton
                            confirmationMessageFloatsRight
                            onConfirm={() => this.onClearAllInfinitiveVerbs()}
                            message='Clear all Infinitive Verbs?'
                          >
                            <button
                              className='button-primary'
                            >
                              <i className='fa fa-times' />
                              Clear All
                            </button>
                          </DeleteButton>
                      }
                    </div>
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Conjugated Verbs (Automatic)</legend>
                    <SearchMultiSelect
                      disable
                      disableMessage='Input disabled'
                      ids={ConceptWordStore.indexIds}
                      searchStore={WordStore}
                      searchField='target_text'
                      displayField='targetWithWordTypeParentInfinitiveAndSource'
                      sort='target_text'
                      linkTo='builder.words.edit'
                      linkId='wordId'
                      options={ConceptWordStore.indexData}
                      paginatorStore={ConceptWordStore}
                      paginatorIds={{conceptId: this.props.routeParams.conceptId}}
                    />
                    {
                      ConceptWordStore.hasIndexData &&
                        <div className='buttons'>
                          <DeleteButton
                            confirmationMessageFloatsRight
                            onConfirm={() => this.onClearAllWords()}
                            message='Clear all Words?'
                          >
                            <button
                              className='button-primary'
                            >
                              <i className='fa fa-times' />
                              Clear All
                            </button>
                          </DeleteButton>
                        </div>
                    }
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Tense Concept</legend>
                    <SearchMultiSelect
                      ids={ConceptStore.showData.intersectingConceptIds}
                      options={ConceptStore.showData.mappedIntersectingConcepts}
                      onCreate={this.onCreateIntersectingConcept}
                      onDestroy={this.onDestroyIntersectingConcept}
                      searchStore={ConceptStore}
                      searchFilters={[{key: 'grammar_tense', value: true}]}
                      searchField='name'
                      displayField='name'
                      excludeIds={[ConceptStore.showData.id]}
                      linkTo='builder.concepts.edit'
                      linkId='conceptId'
                      sort='name'
                    />
                  </fieldset>
                </div>
              </div>
          }
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Spacy Attributes</legend>
                <div className=' create-row row'>
                  <div className='col-xs-12'>
                    <button
                      className='button-primary'
                      onClick={this.onCreateSpacyAttribute}
                    >
                      Create Spacy Attribute
                    </button>
                  </div>
                </div>
                <table className='table-wrapper admin-table'>
                  <thead>
                    <tr>
                      <th>Tense</th>
                      <th>Verb Form</th>
                      <th>Mood</th>
                      <th>Case</th>
                      <th width='50px' />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      ConceptStore.hasShowData && ConceptStore.showData.spacyAttributes.map(item => {
                        return (
                          <tr
                            key={item.id}
                          >
                            <td>
                              <InPlaceText
                                model='spacy_attributes'
                                field='morph_tense'
                                defaultValue={item.morphTense}
                                id={item.id}
                              />
                            </td>
                            <td>
                              <InPlaceText
                                model='spacy_attributes'
                                field='morph_verb_form'
                                defaultValue={item.morphVerbForm}
                                id={item.id}
                              />
                            </td>
                            <td>
                              <InPlaceText
                                model='spacy_attributes'
                                field='morph_mood'
                                defaultValue={item.morphMood}
                                id={item.id}
                              />
                            </td>
                            <td>
                              <DeleteButton
                                onConfirm={() => this.onDeleteSpacyAttribute(item.id)}
                                message='Delete this spacy attribute?'
                                className='fa-block right'
                              />
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </fieldset>
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-5'>
              <fieldset>
                <legend>Child Words</legend>
                <SearchMultiSelect
                  disable
                  disableMessage='Child words are added or removed from this concept while setting up sentences.'
                  ids={ConceptChildWordStore.allIds}
                  onCreate={this.onCreateNonRootConceptWord}
                  onDestroy={this.onDestroyNonRootConceptWord}
                  searchStore={WordStore}
                  searchField='target_text'
                  displayField='targetWithWordTypeParentInfinitiveAndSourceAndLevelAndGender'
                  searchFilters={[{key: 'language_id', value: ConceptStore.showData?.languageId}, {key: 'root', value: false}]}
                  sort='target_text'
                  linkTo='builder.words.edit'
                  linkId='wordId'
                  options={ConceptChildWordStore.indexData}
                  paginatorStore={ConceptChildWordStore}
                  paginatorIds={{conceptId: this.props.routeParams.conceptId}}
                />
                {/*{*/}
                {/*  !isPresent(ConceptStore.showData.mappedUnionConcepts) && ConceptWordStore.hasIndexData &&*/}
                {/*    <div className='buttons'>*/}
                {/*      <button*/}
                {/*        className='button-primary'*/}
                {/*        onClick={() => this.showBatchAdd = true}*/}
                {/*        style={{marginBottom: '5px'}}*/}
                {/*      >*/}
                {/*        <i className='fa fa-plus' />*/}
                {/*        Batch Add Words*/}
                {/*      </button>*/}
                {/*      <DeleteButton*/}
                {/*        confirmationMessageFloatsRight*/}
                {/*        onConfirm={() => this.onClearAllWords()}*/}
                {/*        message="Clear all Words?"*/}
                {/*        margin="0 0 5px 0"*/}
                {/*      >*/}
                {/*        <button*/}
                {/*          className='button-primary'*/}
                {/*        >*/}
                {/*          <i className='fa fa-times' />*/}
                {/*          Clear All*/}
                {/*        </button>*/}
                {/*      </DeleteButton>*/}
                {/*    </div>*/}
                {/*}*/}
              </fieldset>
            </div>
            <div className='col-xs-4'>
              <fieldset>
                <legend>Root words</legend>
                <Text fontSize='12px'>This impacts the grammar section and the dictionary.</Text>
                <SearchMultiSelect
                  disable={isPresent(ConceptStore.showData.mappedUnionConcepts)}
                  disableMessage='Input disabled because of concepts'
                  ids={ConceptRootWordStore.indexIds}
                  onCreate={this.onCreateConceptRootWord}
                  onDestroy={this.onDestroyConceptRootWord}
                  searchStore={WordStore}
                  searchField='target_text'
                  displayField='targetWithWordTypeAndSource'
                  searchFilters={[{key: 'language_id', value: ConceptStore.showData?.languageId}, {key: 'root', value: true}]}
                  sort='target_text'
                  linkTo='builder.words.edit'
                  linkId='wordId'
                  options={ConceptRootWordStore.indexData}
                  paginatorStore={ConceptRootWordStore}
                  paginatorIds={{conceptId: this.props.routeParams.conceptId}}
                />
                {
                  !isPresent(ConceptStore.showData.mappedUnionConcepts) &&
                    <div className='buttons'>
                      <div
                        data-tip
                        data-for='add-all-roots'
                      >
                        {
                          !this.addingAllRoots &&
                            <button
                              className={cx('button-primary')}
                              onClick={this.addAllRoots}
                            >
                              <i className='fa fa-arrow-right'/>
                              Add all roots
                            </button>
                        }
                        {
                          this.addingAllRoots &&
                            <Spinner
                              margin='5px auto'
                            />
                        }
                        {
                          !this.addingAllRoots &&
                            <ReactTooltip
                              place='right'
                              type='dark'
                              effect='solid'
                              id='add-all-roots'
                              class='custom-tooltip'
                            >
                              Add the roots of all the child words.
                            </ReactTooltip>
                        }
                      </div>
                      {
                        ConceptRootWordStore.hasIndexData && !this.clearingAllRoots &&
                          <DeleteButton
                            confirmationMessageFloatsRight
                            onConfirm={this.onClearAllRootWords}
                            message='Clear all Root Words?'
                          >
                            <button
                              className='button-primary'
                            >
                              <i className='fa fa-times' />
                              Clear All Roots
                            </button>
                          </DeleteButton>
                      }
                      {
                        this.clearingAllRoots &&
                          <Spinner
                            margin='5px auto'
                          />
                      }
                    </div>
                }
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Concepts</legend>
                <SearchMultiSelect
                  ids={ConceptStore.showData.unionConceptIds}
                  options={ConceptStore.showData.mappedUnionConcepts}
                  onCreate={this.onCreateUnionConcept}
                  onDestroy={this.onDestroyUnionConcept}
                  searchStore={ConceptStore}
                  searchField='name'
                  displayField='name'
                  excludeIds={[ConceptStore.showData.id]}
                  linkTo='builder.concepts.edit'
                  linkId='conceptId'
                  sort='name'
                />
              </fieldset>
            </div>
          </div>
          {
            ConceptStore.showData.automaticAssociations && ConceptStore.showData.joinType === 'and' && (
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Intersecting Words</legend>
                    {
                      ConceptStore.showData.joinType === 'and' &&
                        <SearchMultiSelect
                          ids={ConceptStore.showData.intersectingWordIds}
                          options={ConceptStore.showData.mappedIntersectingWords}
                          onCreate={this.onCreateIntersectingWord}
                          onDestroy={this.onDestroyIntersectingWord}
                          searchStore={WordStore}
                          searchField='target_text'
                          displayField='targetWithWordTypeParentInfinitiveAndSourceAndLevel'
                          sort='target_text'
                          linkTo='builder.words.edit'
                          linkId='wordId'
                        />
                    }
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Intersecting Concepts</legend>
                    <SearchMultiSelect
                      ids={ConceptStore.showData.intersectingConceptIds}
                      options={ConceptStore.showData.mappedIntersectingConcepts}
                      onCreate={this.onCreateIntersectingConcept}
                      onDestroy={this.onDestroyIntersectingConcept}
                      searchStore={ConceptStore}
                      searchField='name'
                      displayField='name'
                      excludeIds={[ConceptStore.showData.id]}
                      linkTo='builder.concepts.edit'
                      linkId='conceptId'
                      sort='name'
                    />
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Intersecting Word Type</legend>
                    <InPlaceSelect
                      options={WordTypeStore.indexData}
                      optionName='name'
                      model='concepts'
                      field='intersecting_word_type_id'
                      id={ConceptStore.showData.id}
                      value={ConceptStore.showData.intersectingWordTypeId}
                      includeBlank='true'
                      placeholder='Select a Word Type'
                    />
                  </fieldset>
                </div>
              </div>
            )
          }
          {
            ConceptTreeNodeStore.hasIndexData &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Nodes</legend>

                    <table className='table-wrapper admin-table'>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          ConceptTreeNodeStore.indexData.map(item => {
                            return (
                              <tr key={item.id}>
                                <td>
                                  <Link
                                    to={{name: 'builder.tree_nodes.edit', params: {treeId: item.treeId, treeModuleId: item.treeModuleId, treeNodeId: item.id}}}
                                    className='on-click'
                                  >
                                    <i className='fa fa-chevron-right fa-block' />
                                  </Link>
                                </td>
                                <td>
                                  {item.name}
                                </td>
                              </tr>
                            );
                          })
                        }
                      </tbody>
                    </table>
                  </fieldset>
                </div>
              </div>
          }
          <div className='row'>
            <div className='col-xs-12'>
            <fieldset>
              <legend>
                <span>Nodes (as grammar concept)</span>
                <span
                  data-for='nodesGrammarDescription'
                  data-tip
                  style={{marginLeft: '5px'}}
                >
                  <i className='fa fa-info-circle' />
                  <ReactTooltip
                    place='right'
                    type='dark'
                    effect='solid'
                    id='nodesGrammarDescription'
                    className='custom-tooltip'
                  >
                    <div>These nodes use this concept in their grammar description.</div>
                  </ReactTooltip>
                </span>
              </legend>

              <table className='table-wrapper admin-table'>
                <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Tree</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {
                  ConceptGrammarTreeNodeStore.hasIndexData && ConceptGrammarTreeNodeStore.indexData.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={{name: 'builder.tree_nodes.edit', params: {treeId: item.treeId, treeModuleId: item.treeModuleId, treeNodeId: item.id}}}
                            className='on-click'
                          >
                            <i className='fa fa-chevron-right fa-block' />
                          </Link>
                        </td>
                        <td>
                          {item.name}
                        </td>
                        <td>
                          {item.treeName}
                        </td>
                        <td>
                          {
                            item.treeNodeType !== 'grammar' &&
                              <DeleteButton
                                onConfirm={this.onDeleteTreeNodeGrammarConcept.bind(this, item)}
                                message='Remove the concept from this tree node and its decks?'
                                className='fa-block right'
                              />
                          }
                          {
                            item.treeNodeType === 'grammar' &&
                              <span
                                data-for={item.id}
                                data-tip
                              >
                                <i className='fa fa-info-circle'/>
                                <ReactTooltip
                                  place='left'
                                  type='dark'
                                  effect='solid'
                                  id={item.id}
                                  className='custom-tooltip'
                                >
                                  <div>Please delete the node from the grammar tree.</div>
                                </ReactTooltip>
                              </span>
                          }
                        </td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>
              <TreeNodeSearch
                onSubmit={this.onCreateTreeNodeGrammarConcept}
                languageId={ConceptStore.showData?.languageId}
                displayField='nameAndTreeNameAndDeckCount'
                style={{marginTop: '5px'}}
              />
            </fieldset>
          </div>
          </div>
          <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>
                <span>Decks (as grammar concept)</span>
                <span
                  data-for='nodesGrammarDescription'
                  data-tip
                  style={{marginLeft: '5px'}}
                >
                  <i className='fa fa-info-circle' />
                  <ReactTooltip
                    place='right'
                    type='dark'
                    effect='solid'
                    id='nodesGrammarDescription'
                    className='custom-tooltip'
                  >
                    <div>These decks use this concept in their grammar description.</div>
                  </ReactTooltip>
                </span>
              </legend>

              <table className='table-wrapper admin-table'>
                <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Tree Nodes</th>
                </tr>
                </thead>
                <tbody>
                {
                  ConceptGrammarDeckStore.indexData.map(item => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Link
                            to={{name: 'builder.decks.edit', params: {deckId: item.id}}}
                            className='on-click'
                          >
                            <i className='fa fa-chevron-right fa-block' />
                          </Link>
                        </td>
                        <td>
                          {item.name}
                        </td>
                        <td>{item.firstTreeNodeName}</td>
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>Sentences</legend>
                <div style={{marginBottom: 10}}>
                  <SentenceSearch
                    languageId={ConceptStore.showData?.languageId}
                    onSubmit={this.onAddSentence}
                  />
                </div>
                {
                  this.showRerunCompleteMessage &&
                    <div className='concept-edit-message'>
                      This concept has been recalculated.
                    </div>
                }
                {
                  !ConceptStore.showData.automaticAssociations && (
                    <button
                      className='gray-button'
                      onClick={() => this.showCreate = true}
                    >
                      <i className='fa fa-plus' />
                      Create
                    </button>
                  )
                }
                {
                  ConceptStore.showData.joinType === 'and' &&
                    <button
                      className='gray-button'
                      onClick={() => this.refreshConcept()}
                    >
                      <i className='fa fa-refresh' />
                      Rerun Concept
                    </button>
                }
                <div style={{marginTop: 10}}>
                  <SentenceIndex
                    conceptId={this.props.routeParams.conceptId}
                    showVideoColumns
                    sentences={SentenceConceptStore.indexData}
                    onDelete={this.onDeleteSentence}
                    deletePrompt='Remove this sentence from concept?'
                    store={SentenceConceptStore}
                    ids={{ conceptId: this.props.routeParams.conceptId }}
                    sentencesMaintainOrdering
                    onChangePosition={this.loadSentences}
                    scoped
                    hide={['ENSource']}
                    showSource
                  />
                </div>
                {
                  SentenceConceptStore.hasIndexData &&
                    <div className='buttons'>
                      <DeleteButton
                        confirmationMessageFloatsRight
                        onConfirm={() => this.onClearAllSentences()}
                        message='Clear all Sentences?'
                      >
                        <button
                          className='button-primary'
                        >
                          <i className='fa fa-times' />
                          Clear All
                        </button>
                      </DeleteButton>
                    </div>
                }
              </fieldset>
            </div>
          </div>
          {
            ConceptStore.showDataField('joinType') === 'or' &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Dependent 'And' Concepts</legend>
                    <ConceptIndex
                      concepts={DependentIntersectingConceptStore.indexData}
                      hideFilter
                      hidePaginator
                      hideCreateButton
                    />
                  </fieldset>
                </div>
              </div>
          }
          {
            ConceptStore.showDataField('joinType') === 'or' &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Dependent 'Or' Concepts</legend>
                    <ConceptIndex
                      concepts={DependentUnionConceptStore.indexData}
                      hideFilter
                      hidePaginator
                      hideCreateButton
                    />
                  </fieldset>
                </div>
              </div>
          }
        </div>
      );
    }
    return <span />;
  }

  render() {
    return (
      <Wrapper>
        {ConceptStore.filters['gender']}
        {ConceptStore.filters['join_type']}
        {this._content()}
      </Wrapper>
    );
  }
}

export default ConceptEdit;
