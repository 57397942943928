import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { MembershipGroupStore, SubscriptionStore, UserStore, GroupStore, UserGroupStore, PatreonUserStore, MembershipTypeStore , RssFeedStore , DevPaymentMigrationStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceText from 'components/form/in_place_text';
import InPlaceSelect from 'components/form/in_place_select';
import DeleteButton from 'components/button/delete_button';
import { isPresent, isBlank } from '@seedlang/utils';
import { Constants } from '@seedlang/constants';
import SubscriptionCreate from 'pages/builder/subscriptions/subscription_create';
import Cookies from 'js-cookie';
import Text from 'components/text';
import CampaignMessageIndex from 'pages/builder/campaign_messages/campaign_message_index';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import ContactMessageIndex from 'pages/builder/contact_messages/contact_message_index';
import Alert from 'components/alert';
import InfoTooltip from 'components/info_tooltip';

const Wrapper = styled.div`

`;

const Note = styled.div`
  background: white;
  margin-top: 10px;
  padding: 10px;
  font-size: 14px;
`;

const IconWrapper = styled.span`
  .fa-file-text {
    color: #333;
    cursor: pointer;
    margin-right: 4px;
  }
`;

const Item = styled.div`
  font-size: 14px;
  line-height: 18px;
`;

@observer
class UsersEdit extends React.Component {
  @observable groupId = 'f74a4102-d65b-448c-b261-60b6be2c7eca';
  @observable spinnerForRssFeedId = null;
  @observable showUpdateMailerLiteMessage = false;

  @computed get patreonEmailWithoutUser() {
    return isPresent(UserStore.showData.patreonUserEmail) && isBlank(UserStore.showData.patreonUser);
  }

  @computed get noGroup() {
    return isBlank(UserStore.showData.userGroups);
  }

  @computed get group() {
    const groupId = AppUI.site.isDefault ? 'f74a4102-d65b-448c-b261-60b6be2c7eca' : AppUI.site.groupId;
    return GroupStore.hasIndexData ? GroupStore.indexData.filter(item => item.id === groupId)[0] : null;
  }

  @computed get hasGroupButNotPermanentOrSynched() {
    return isPresent(UserStore.showData.userGroups) && !UserStore.showData.userGroups[0].permanent && isBlank(UserStore.showData.patreonUser);
  }

  @computed get hasAccess() {
    return this.group && (this.userGroup?.permanent || UserStore.showData.permanentPatreonMember || (UserStore.showData.activePatreonMember && (this.group.allowAllMemberships || this.hasMembershipWithAccess || isBlank(UserStore.showData.patreonUser.rewardTitle))));;
  }

  @computed get hasMembershipWithAccess() {
    if (!GroupStore.hasIndexData || !UserStore.hasShowData || !this.group.allowedMemberships) { return null; }
    const allowedMemberships = this.group.allowedMemberships?.split(',');
    return isPresent(allowedMemberships) && UserStore.showData.patreonUser && allowedMemberships.indexOf(UserStore.showData.patreonUser.rewardTitle) !== -1;
  }

  @computed get userGroup() {
    const groupId = AppUI.site.isDefault ? 'f74a4102-d65b-448c-b261-60b6be2c7eca' : AppUI.site.groupId;
    return UserStore.hasShowData && UserStore.showData.userGroups.find(item => item.group?.id === groupId);
  }

  @computed get filterMembershipTypes() {
    const siteId = Cookies.get('site_id');

    return MembershipTypeStore.indexData.filter(item => item.siteId === siteId);
  }

  @computed get hasMultipleUserGroups() {
    return UserStore.hasShowData && UserStore.showData.userGroups.length > 1;
  }

  constructor(props) {
    super(props);
    this.loadUser();
    this.loadSubscriptions();
    this.loadRssFeeds();
    GroupStore.getIndex();
    if (!MembershipTypeStore.hasIndexData) {
      this.getMembershipTypes();
    }
    if (!MembershipGroupStore.hasIndexData) {
      MembershipGroupStore.getIndex();
    }
  }

  componentWillUnmount() {
    SubscriptionStore.clearIndexData();
  }

  @autobind loadUser() {
    UserStore.getShow({ids: {expand_user: true, userId: this.props.params.userId}});
  }

  @autobind addToGroup() {
    const groupId = AppUI.siteIsDefault ? this.groupId : AppUI.user.site.groupId;

    UserGroupStore.create({data: {userId: this.props.params.userId, groupId: groupId}}, this.loadUser);
  }

  @autobind onRemoveUser() {
    if (this.hasMultipleUserGroups) {
      UserGroupStore.destroy({ids: {userGroupId: this.userGroup?.id}}, this.afterRemoveUser);
    } else {
      UserStore.destroy({ids: {userId: this.props.params.userId}}, this.afterRemoveUser);
    }
  }

  @autobind afterRemoveUser() {
    AppUI.routeStore.routeToNamed('creator.groups.users.index', {groupId: this.props.params.groupId});
  }

  @autobind onRemovePatreonUser() {
    PatreonUserStore.update({data: {has_seedlang_user: false}, ids: {patreonUserId: UserStore.showData.patreonUser.id}});
    UserStore.update({ids: {userId: this.props.params.userId}, data: {patreon_user_email: null}}, this.loadUser);
  }

  @autobind getMembershipTypes() {
    MembershipTypeStore.getIndex();
  }

  @autobind loadSubscriptions() {
    SubscriptionStore.getIndex({queryStrings: {user_id: this.props.params.userId}});
  }

  @autobind loadRssFeeds() {
    RssFeedStore.getIndex({ queryStrings: { user_id: this.props.params.userId } }, resp => {
      RssFeedStore.setIndexData(resp);
      this.spinnerForRssFeedId = null;
    });
  }

  @autobind onUpdateFeed(rssFeed) {
    this.spinnerForRssFeedId = rssFeed.id;
    RssFeedStore.updateFeed({ ids: { rssFeedId: rssFeed.id, user_id: this.props.params.userId } }, this.loadRssFeeds);
  }

  @autobind onRegenerateUrl(rssFeed) {
    this.spinnerForRssFeedId = rssFeed.id;
    RssFeedStore.regenerateUrl({ ids: { rssFeedId: rssFeed.id, user_id: this.props.params.userId } }, this.loadRssFeeds);
  }

  @autobind onDevInitializePaymentMigration() {
    DevPaymentMigrationStore.create({data: {siteId: AppUI.site.id, userId: this.props.params.userId}}, this.loadUser);
  }

  @autobind displayBoolean(value) {
    if (isBlank(value)) {
      return 'Not Set';
    }
    return typeof value === 'string' ? (value === 'true' ? '✅' : '❌') : value === true ? '✅' : value === false ? '❌' : value;
  }

  @autobind onUpdateMailerlite() {
    this.showUpdateMailerLiteMessage = true;
    setTimeout(() => {
      this.showUpdateMailerLiteMessage = false;
    }, 5000);
    AppUI.userStore.updateMailerlite({ids: {userId: this.props.params.userId}}, this.loadUser);
  }

  render() {
    return (
      <Wrapper>
        <div className='breadcrumbs' style={{marginBottom: 20}}>
          <Link
            to={{name: 'creator.groups.users.index', params: {groupId: this.props.params.groupId}}}
          >
            All Users
          </Link>
        </div>
        {
          UserStore.hasShowData &&
            <div>
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Name</legend>
                    {UserStore.showData.name || '-'}
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Email</legend>
                    <div>
                      {UserStore.showData.email || '-'}
                    </div>
                    <div>
                      <InPlaceCheckbox
                        model='users'
                        field='confirmed'
                        id={UserStore.showData.id}
                        value={UserStore.showData.confirmed}
                        icon='check'
                        afterChange={this.loadUser}
                      >
                        Email confirmed
                      </InPlaceCheckbox>
                      </div>
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Role <InfoTooltip><div>This only serves as a way of displaying the role</div><div>of the team member, and currently has no role</div><div>in assigning user rights.</div></InfoTooltip></legend>
                    <InPlaceSelect
                      includeBlank
                      id={UserStore.showData.id}
                      value={UserStore.showData.role}
                      model='users'
                      field='role'
                      options={Constants.USER_ROLES}
                      afterChange={this.loadUser}
                    />
                  </fieldset>
                </div>
                {
                  AppUI.siteIsDefault &&
                    <div className='col-xs-4'>
                      <fieldset>
                        <legend>Settings</legend>
                        <InPlaceCheckbox
                          model='users'
                          field='permanent_membership'
                          id={UserStore.showData.id}
                          value={UserStore.showData.permanentMembership}
                          icon='check'
                          afterChange={this.loadUser}
                        >
                          Free Seedlang Pro Membership
                        </InPlaceCheckbox>
                      </fieldset>
                    </div>
                }
                <div className='col-xs-6'>
                  <fieldset style={{display: 'flex', alignItems: 'center', gap: 5}}>
                    <legend>Membership Group <InfoTooltip><div>Users are assigned a membership group when a user is created</div>or added to this site. If you want to change the <div>membership group, you can do so from the "Membership Groups" page.</div></InfoTooltip></legend>
                    <InPlaceSelect
                      includeBlank
                      model='user_groups'
                      id={this.userGroup?.id}
                      field='membership_group_id'
                      value={this.userGroup?.membershipGroupId}
                      options={MembershipGroupStore.indexData.map(item => [item.id, item.name])}
                      afterChange={this.loadUser}
                    />
                  </fieldset>

                </div>
                <div className='col-xs-6'>
                  {
                    isPresent(this.userGroup) &&
                      <fieldset style={{display: 'flex', alignItems: 'center', gap: 5}}>
                        <legend>Free Membership <InfoTooltip>Add a date if you want the free membership to expire.</InfoTooltip></legend>
                        <InPlaceCheckbox
                          model='user_groups'
                          id={this.userGroup.id}
                          field='permanent'
                          value={this.userGroup.permanent}
                          icon='check'
                          afterChange={this.loadUser}
                        >
                          Free Membership
                        </InPlaceCheckbox>
                        {
                          !AppUI.siteIsDefault &&
                            <InPlaceSelect
                              includeBlank
                              model='user_groups'
                              id={this.userGroup.id}
                              field='membership_type_id'
                              value={this.userGroup.membershipTypeId}
                              options={this.filterMembershipTypes.map(item => [item.id, item.name])}
                              afterChange={this.loadUser}
                            />
                        }
                        <InPlaceDatePicker
                          model='user_groups'
                          id={this.userGroup.id}
                          field='permanent_until'
                          value={this.userGroup.permanentUntil}
                          afterChange={this.loadUser}
                        />
                      </fieldset>
                  }
                </div>
              </div>
              {
                AppUI.siteIsDefault &&
                <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Patreon User</legend>
                    {
                      !UserStore.showData.patreonUser &&
                        <InPlaceText
                          defaultValue={UserStore.showData.patreonUserEmail}
                          model='users'
                          field='patreon_user_email'
                          id={UserStore.showData.id}
                          placeholder='Enter Email'
                          afterChange={this.loadUser}
                        />
                    }
                    {
                      UserStore.showData.patreonUser &&
                        <table
                          className='table-wrapper'
                        >
                          <thead>
                            <tr>
                              <th>Email</th>
                              <th>Membership Title</th>
                              <th>Active Patron</th>
                              <th width='50' />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Link
                                  to={{name: 'creator.groups.patreon_users.edit', params: {groupId: this.props.params.groupId, patreonUserId: UserStore.showData.patreonUser.id}}}
                                >
                                  {UserStore.showData.patreonUser.email}
                                </Link>
                              </td>
                              <td>
                                <InPlaceText
                                  defaultValue={UserStore.showData.patreonUser.rewardTitle}
                                  model='patreon_users'
                                  field='reward_title'
                                  id={UserStore.showData.patreonUser.id}
                                  afterChange={this.loadUser}
                                />
                              </td>
                              <td>
                                <InPlaceCheckbox
                                  icon='check'
                                  model='patreon_users'
                                  id={UserStore.showData.patreonUser.id}
                                  afterChange={this.loadPatreonUser}
                                  field='is_patreon_active'
                                  value={UserStore.showData.patreonUser.isPatreonActive}
                                />
                              </td>
                              <td>
                                <DeleteButton
                                  message='Remove Association?'
                                  className='fa-block right'
                                  onConfirm={() => this.onRemovePatreonUser()}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                    }
                    {
                      this.patreonEmailWithoutUser &&
                        <Note>
                          <i className='fa fa-warning' style={{marginRight: 5}} />
                          A Patreon user email has been submitted, but no patron user was found. If a Patreon user is later created with this email address, we will sync the Seedlang and Patron accounts.
                        </Note>
                    }
                  </fieldset>
                </div>
              </div>
              }
          </div>
        }
        <div className='row'>
          <div className='col-xs-4'>
            <fieldset>
              <legend>Agreements <InfoTooltip><div>This shows whether the user has agreed to receive</div><div>emails and the terms of service.</div></InfoTooltip></legend>
              <Item>Email: {this.displayBoolean(this.userGroup?.userAgreedToEmail)}</Item>
              <Item>Terms: {this.displayBoolean(this.userGroup?.userAgreedToTerms)}</Item>
            </fieldset>
          </div>
          <div className='col-xs-4'>
            {
              UserStore.hasShowData &&
                <fieldset>
                  <legend>Timestamps</legend>
                  {
                    UserStore.showData?.createdAt?.formattedDateWithYear !== this.userGroup?.createdAt?.formattedDateWithYear &&
                      <Item>
                        Created on Seedlang: {UserStore.showData?.createdAt?.formattedDateWithYear}
                      </Item>
                  }
                  <Item>
                    Created on Site: {this.userGroup?.createdAt?.formattedDateWithYear}
                  </Item>
                  <Item>
                    Last Active on Site: {this.userGroup?.lastActiveAt ? this.userGroup?.lastActiveAt.formattedDateWithYear : '-'}
                  </Item>
                </fieldset>
            }
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Subscriptions</legend>
              {
                AppUI.user.developer &&
                  <SubscriptionCreate
                    userId={UserStore.showData.id}
                    afterCreate={this.loadSubscriptions}
                  />
              }
              {
                SubscriptionStore.hasIndexData &&
                  <table
                    className='table-wrapper'
                  >
                    <thead>
                      <tr>
                        <th />
                        <th>Created At</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Cancelled At</th>
                        <th>Amount</th>
                        <th>Cncl</th>
                        <th>Ref</th>
                        <th>Pnd</th>
                        <th>#Pay</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        SubscriptionStore.indexData.map(item => {
                          return (
                            <tr>
                              <td>
                                <Link
                                  to={AppUI.siteIsDefault ? { name: 'builder.subscriptions.edit', params: { subscriptionId: item.id } } : { name: 'creator.groups.subscriptions.edit', params: { groupId: this.props.params.groupId, subscriptionId: item.id } }}
                                  className='on-click'
                                >
                                  <i className='fa fa-chevron-right fa-block' />
                                </Link>
                              </td>
                              <td>
                                {item.createdAt?.formatted}
                              </td>
                              <td>
                                <div className='text'>
                                  {item.currentPeriodStart.formattedDateWithYear}
                                </div>
                              </td>
                              <td>
                                <div className='text'>
                                  {item.currentPeriodEnd.formattedDateWithYear}
                                </div>
                              </td>
                              <td>
                                <div>{item.cancelledAt && item.cancelledAt.formattedDateWithYear}</div>
                                <div>{item.cancellationMessage && <IconWrapper onClick={() => this.cancellationMessage = item.cancellationMessage}><i className='fa fa-file-text' /></IconWrapper>}{item.cancellationReason}</div>
                              </td>
                              <td>
                                <div>{item.amountFormatted}</div>
                                <div>{item.paymentMethod}</div>
                              </td>
                              <td>
                                <div className='text'>
                                  <InPlaceCheckbox
                                    model='subscriptions'
                                    field='cancel_at_period_end'
                                    id={item.id}
                                    value={item.cancelAtPeriodEnd}
                                    icon='check'
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='text'>
                                  <InPlaceCheckbox
                                    model='subscriptions'
                                    field='refunded'
                                    id={item.id}
                                    value={item.refunded}
                                    icon='check'
                                  />
                                </div>
                              </td>
                              <td>
                                <div className='text'>
                                  <InPlaceCheckbox
                                    model='subscriptions'
                                    field='pending'
                                    id={item.id}
                                    value={item.pending}
                                    icon='check'
                                  />
                                </div>
                              </td>
                              <td>
                                {item.paymentsCount}
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
              }
            </fieldset>
          </div>
        </div>
        {
          UserStore.showData.paymentMigration &&
            <div className='row'>
              <div className={AppUI.user.developer ? 'col-xs-6' : 'col-xs-12'}>
                <fieldset>
                  <legend>Payment Migration <InfoTooltip>This shows the status of the payment migration.</InfoTooltip></legend>
                  <div>
                    <Text style={{ fontSize: 14 }}>
                      {UserStore.showData.paymentMigration.friendlyStatus || 'Not Started'}
                    </Text>
                  </div>
                </fieldset>
              </div>
              {
                AppUI.user.developer &&
                  <div className='col-xs-6'>
                    <fieldset>
                      <legend>Payment Migration (for developers only)</legend>
                      {
                        UserStore.showData.paymentMigration?.id &&
                          <div className='row'>
                            <div className='col-xs-8'>
                              <InPlaceSelect
                                includeBlank
                                id={UserStore.showData.paymentMigration.id}
                                model='dev_payment_migrations'
                                field='status'
                                options={['setting_up', 'stripe_payment_setup_started', 'waiting_for_patreon_cancellation', 'waiting_for_patreon_expiry', 'waiting_for_patreon_refund', 'waiting_for_patreon_refund_timeout', 'completed']}
                                value={UserStore.showData.paymentMigration.status}
                                afterChange={this.loadUser}
                              />
                            </div>
                            <div className='col-xs-4'>
                              <InPlaceSelect
                                includeBlank
                                id={UserStore.showData.paymentMigration.id}
                                model='dev_payment_migrations'
                                field='patreon_pledge_cadence'
                                options={[[12, 'Yearly'], [1, 'Monthly']]}
                                value={UserStore.showData.paymentMigration.patreonPledgeCadence}
                                afterChange={this.loadUser}
                              />
                            </div>
                          </div>
                        }
                        {
                          !UserStore.showData.paymentMigration?.id &&
                            <button className='button-primary'
                              onClick={this.onDevInitializePaymentMigration}
                            >
                              Initialize Payment Migration
                            </button>
                        }
                    </fieldset>
                  </div>
              }
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Support Emails Sent By User</legend>
              <ContactMessageIndex
                userId={this.props.params.userId}
                limit={5}
                whiteLabel
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Email Notification Settings</legend>
              <div style={{display: 'flex', gap: '20px'}}>
                <InPlaceCheckbox
                  model='user_groups'
                  field='block_all_mailings'
                  id={UserStore.showData.currentUserGroup?.id}
                  value={UserStore.showData.currentUserGroup?.blockAllMailings}
                  icon='check'
                  afterChange={UserStore.reloadShow.bind(UserStore)}
                >
                  Block All Emails
                </InPlaceCheckbox>
                {
                  isPresent(UserStore.showData.blockMailingByType) &&
                    <Text fontSize='14px' style={{display: 'flex', gap: '5px'}}><label>Blocked email types:</label> {UserStore.showData.blockMailingByType}</Text>
                }
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Emails Sent By Site</legend>
              <CampaignMessageIndex
                whiteLabel
                groupId={this.props.params.groupId}
                userId={this.props.params.userId}
                siteId={AppUI.siteId}
                limit={5}
              />
            </fieldset>
          </div>
        </div>
        {
          AppUI.site.enableMailerlite &&
            <div className='row'>
            <div className='col-xs-6'>
              <fieldset>
                <legend>MailerLite Sync</legend>
                {
                  this.userGroup?.mailerliteId &&
                    <div>
                      <button className='button-primary' onClick={this.onUpdateMailerlite}>Sync with Mailerlite</button>
                    </div>
                }
                {
                  UserStore.hasShowData && isBlank(this.userGroup?.mailerliteId) &&
                    <Note>
                      Note: This user is not synced with Mailerlite.
                    </Note>
                }
              </fieldset>
            </div>
            <div className='col-xs-6'>
              <fieldset>
                <legend>MailerLite Page</legend>
                {
                  this.userGroup?.mailerliteId &&
                    <div>
                      <a href={`https://dashboard.mailerlite.com/subscribers/${this.userGroup.mailerliteId}`} className='underline' target='_blank'>Mailerlite Page</a>
                    </div>
                }
                {
                  this.showUpdateMailerLiteMessage &&
                    <Alert>
                      User updated in Mailerlite
                    </Alert>
                }
              </fieldset>
            </div>
          </div>
        }
        {
          !AppUI.siteIsDefault && (
            <div className='row'>
              <div className='col-xs-12'>
                <fieldset>
                  <legend>RSS Feeds</legend>
                  <table
                    className='table-wrapper'
                  >
                    <thead>
                      <tr>
                        <th>Podcast</th>
                        <th>Private RSS feed URL</th>
                        <th></th>
                        <th>Last Updated</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        RssFeedStore.indexData.map(item => {
                          return (
                            <tr key={item.id}>
                              <td>
                                {item.podcast.title}
                              </td>
                              <td style={{ width: '27em'}}>
                                {item.url}
                              </td>
                              <td>
                                <DeleteButton
                                  message='A new RSS URL will be generated and the existing one will stop working. Do you want to continue?'
                                  faIcon='fa-recycle'
                                  onConfirm={this.onRegenerateUrl.bind(this, item)}
                                />
                              </td>
                              <td style={{ width: '8em' }}>
                                {item.updatedAt.formatted}
                              </td>
                              <td style={{ width: '8em'}}>
                                <button className='button-primary' onClick={this.onUpdateFeed.bind(this, item)}>
                                  Update Feed
                                </button>
                              </td>
                              <td style={{ width: '6em'}}>
                                {this.spinnerForRssFeedId === item.id && <i title='Please allow 20 seconds for an update.'>Updating...</i>}
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </table>
                </fieldset>
              </div>
            </div>
          )
        }
        <div
          className='row'
        >
          <div className='col-xs-12'>
            <fieldset>
              <legend>{this.hasMultipleUserGroups ? 'Remove User' : 'Delete User'}</legend>
              <DeleteButton
                right
                message={`Are you sure you want to ${this.hasMultipleUserGroups ? 'remove' : 'delete'} this user? This action is not reversible.`}
                onConfirm={this.onRemoveUser}
              >
                <button className='button-primary'>
                  {this.hasMultipleUserGroups ? 'Remove User' : 'Delete User'}
                </button>
              </DeleteButton>
              {
                UserStore.hasShowData && UserStore.showData.userGroups.length > 1 &&
                  <Note>
                    This user exists on multiple sites and cannot be deleted. If they would want all of their data deleted, please contact help@seedlang.com.
                  </Note>
              }
            </fieldset>
          </div>
        </div>
        {
          this.hasAccess &&
            <Note>
              <span role='img' aria-label='celebration'>🎉</span> This user has membership access.
            </Note>
        }
        {
          this.noGroup && AppUI.siteIsDefault &&
            <Note>
              <i className='fa fa-warning' style={{marginRight: 5}} />
              This user will not see the Patron button, because they have not been added to a group
            </Note>
        }
        {
          !this.hasAccess && !this.noGroup && AppUI.siteIsDefault &&
            <Note>
              <div style={{fontWeight: 'bold'}}>
              <i className='fa fa-warning' style={{marginRight: 5}} /> This user does not worksheet access.
              </div>
              <div>
                To have access, they would need to either:
              </div>
              <div>
                * have "Permanent" access checked
              </div>
              <div>
                * be synced to a Patreon User that has a blank Membership or one of these Memberships:
              </div>
              {
                this.group &&
                  <ul>
                    {
                      this.group.allowedMemberships && this.group.allowedMemberships.split(',').map(item => {
                        return (
                          <li
                            key={item}
                            style={{margin: '0 0 0 10px'}}
                          >
                            {item}
                          </li>
                        );
                      })
                    }
                  </ul>
              }
            </Note>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(UsersEdit);
