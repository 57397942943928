import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { CreatorUI, AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isPresent } from '@seedlang/utils';
import VideoPlayerRecorder from 'components/media/video_player_recorder';
import Filter from 'components/backend_filter/filter';
import Paginator from 'components/paginator';
import cx from 'classnames';
import styled from '@emotion/styled';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { Link } from 'react-router';
import { Theme } from '@seedlang/constants';
import ReactTooltip from 'react-tooltip';
import SortableColumns from 'components/hoc/sortable_columns';
import OutlineButton from 'components/button/outline_button';

const Wrapper = styled.div`
  display: flex;
  .fa-selected {
    background: ${Theme.green};
    color: #FFF;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
`;

const RightColumn = styled.div`
  margin-left: 10px;
  width: 460px;
`;

const CallToAction = styled.div`
  width: 100%;
  background: #333;
  color: white;
  font-size: 13px;
`;

const Speed = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
`;

const NextRecording = styled.div`
  position: relative;
  text-align: center;
`;

const Text = styled.div`
  font-size: 21px;
  font-weight: bold;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  text-align: center;
  background: white;
  width: 100%;
  margin-bottom: 10px;
`;

const VideoRecorderWrapper = styled.div`
  background: #333;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 340px;
  position: relative;
`;

const Ranking = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: #545454;
`;

const Abbreviation = styled.span`
  background: ${props => props.background};
  color: #FFF;
  padding: 5px;
`;

const Options = styled.div`
  background: #FFF;
  padding: 5px;
  margin-bottom: 10px;
  label {
    font-weight: normal;
    font-size: 12px;
  }
`;

const Option = styled.div`
  padding: 5px 10px;
  border: 1px solid #CCC;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin: 0 2px;
`;

@observer
class WordIndex extends React.Component {

  constructor(props) {
    super(props);
    const page = window.location.href.split('page/')[1];
    CreatorUI.wordStore.setPage(page);
    CreatorUI.createMediaStore();
    if (!CreatorUI.wordTypeStore.hasIndexData) {
      CreatorUI.wordTypeStore.getIndex();
    }
    if (!CreatorUI.levelStore.hasIndexData) {
      CreatorUI.levelStore.getIndex();
    }
  }

  @computed get prompt() {
    if (CreatorUI.wordToRecord.isNoun && CreatorUI.wordToRecord.plural) {
      return `${CreatorUI.wordToRecord.targetTextWithDefiniteArticle}, ${CreatorUI.wordToRecord.targetTextWithDefiniteArticle}`;
    } else if (CreatorUI.wordToRecord.isNoun) {
      const pluralForm = CreatorUI.wordToRecord.pluralNounsString ? CreatorUI.wordToRecord.pluralNounsString : '[Plural Form]';
      const pluralString = CreatorUI.wordToRecord.noPlural ? '-- No Plural --' : pluralForm;
      return `${CreatorUI.wordToRecord.targetTextWithDefiniteArticle}, ${pluralString}`;
    } else if (CreatorUI.wordToRecord.languageId !== 'DE' && CreatorUI.wordToRecord.isAdjective && CreatorUI.wordToRecord.root) {
      return `${CreatorUI.wordToRecord.targetText}, ${CreatorUI.wordToRecord.genderVariant ? CreatorUI.wordToRecord.genderVariant.targetText : '[Feminine Form]'}`;
    } else if (CreatorUI.wordToRecord.languageId !== 'DE' && CreatorUI.wordToRecord.isAdjective && CreatorUI.wordToRecord.rootWord.genderVariant === CreatorUI.wordToRecord) {
      return `${CreatorUI.wordToRecord.rootWord.targetText}, ${CreatorUI.wordToRecord.targetText}`;
    } else if (CreatorUI.wordToRecord.isNumber && isPresent(CreatorUI.wordToRecord.genderVariant)) {
      return `${CreatorUI.wordToRecord.targetText}, ${CreatorUI.wordToRecord.genderVariant.targetText}`;
    } else if (CreatorUI.wordToRecord) {
      return `${CreatorUI.wordToRecord.targetText}, ${CreatorUI.wordToRecord.targetText}`;
    } return null;
  }

  @autobind onClickRecord(word) {
    CreatorUI.onClickWordRecord(word);
  }

  @autobind onClickPlay(videoClip) {
    CreatorUI.set('videoClip', videoClip);
  }

  @autobind onCloseVideo() {
    CreatorUI.set('videoClip', null);
  }

  @autobind afterCreateVideo(resp) {
    const word = CreatorUI.wordStore.indexData.find(item => item.target.id === resp['videoableId']);
    word.target.setVideoClip(resp['videoClip']);
    this.reloadWordIndexFiltered();
  }

  @autobind afterCreateRequestSent() {
    CreatorUI.incrementWordToRecord();
  }

  @autobind reloadWordIndexFiltered() {
    CreatorUI.wordStore.reloadIndexFiltered();
  }

  @autobind onDeleteVideo(videoClipId) {
    CreatorUI.videoClipStore.destroy({ids: {videoClipId: CreatorUI.videoClip.id}}, this.reloadWordIndexFiltered);
    this.onCloseVideo();
  }

  render() {
    return (
      <Wrapper>
        <LeftColumn>
          <table className='table-wrapper admin-table'>
            <thead>
              <tr>
                <th width='20'>Dict</th>
                <th width='20'>Edit</th>
                <th width='20'>Rec</th>
                <th
                  className={cx('sortable', this.props.columnClassNames('frequency_ranking'))}
                  onClick={this.props.onSortColumn.bind(this, 'frequency_ranking')}
                >
                  Rank
                </th>
                <th
                  className={cx('sortable', this.props.columnClassNames('target_text'))}
                  onClick={this.props.onSortColumn.bind(this, 'target_text')}
                  style={{minWidth: '150px'}}
                >
                  Target
                </th>
                <th>
                  EN sources
                </th>
                <th>
                  Word Type
                </th>
                <th width='60px'>
                  Level
                </th>
                <th>
                  Exclude
                </th>
                <th>Play</th>
              </tr>
            </thead>
            <tbody>
              {
                CreatorUI.wordStore.indexData.map(item => {
                  return (
                    <tr
                      key={item.id}
                      style={{
                        background: CreatorUI.wordToRecord && item.id === CreatorUI.wordToRecord.id ? 'rgba(255, 255, 0, 0.36)' : '#FFF',
                      }}
                    >
                      <td>
                        <a
                          href={item.dictionaryUrl}
                          target='seedlang'
                          className='no-underline no-hover'
                        >
                          <i className='fa fa-block fa-external-link' />
                        </a>
                      </td>
                      <td>
                        <Link
                          to={{ name: 'builder.words.edit', params: { wordId: item.id } }}
                          target='word-page'
                          className='on-click'
                        >
                          <i className='fa fa-chevron-right fa-block' />
                        </Link>
                      </td>
                      <td>
                        <i
                          className={cx('fa', 'fa-microphone', 'fa-block', 'fa-on-click', {
                            'fa-selected': CreatorUI.wordToRecord && item.id === CreatorUI.wordToRecord.id,
                          })}
                          onClick={() => this.onClickRecord(item)}
                        />
                      </td>
                      <td>
                        <Ranking>
                          {item.frequencyRanking}
                        </Ranking>
                      </td>
                      <td>
                        {item.targetTextWithDefiniteArticle}
                      </td>
                      <td>
                        <div style={{fontStyle: 'italic'}}>{item.sourceList}</div>
                      </td>
                      <td>
                        {item.wordType && item.wordType.abbreviation}
                      </td>
                      <td>
                        <InPlaceSelect
                          includeBlank
                          model='words'
                          id={item.id}
                          value={item.level && item.level.id}
                          field='level_id'
                          options={CreatorUI.levelStore.indexData.filter(item => ['N'].indexOf(item.abbreviation) === -1).map(item => [item.id, item.abbreviation])}
                          afterChange={this.reloadWordIndexFiltered}
                        />
                      </td>
                      <td>
                        <InPlaceCheckbox
                          model='words'
                          id={item.id}
                          value={item.excludeFromPublicLists}
                          field='exclude_from_public_lists'
                          icon='check'
                        />
                      </td>
                      <td>
                        {
                          item.target.videoClips && item.target.videoClips.map(videoClip => {
                            return (
                              <div
                                data-tip={this.props.showTooltip}
                                data-for={videoClip.id}
                                key={videoClip.id}
                              >
                                <i
                                  className='fa fa-play fa-block fa-on-click'
                                  onClick={() => this.onClickPlay(videoClip)}
                                  style={{
                                    margin: '3px 0',
                                    background: videoClip.userId === AppUI.user.id ? Theme.green : '#e0e0e0',
                                    color: videoClip.userId === AppUI.user.id ? '#FFF' : '#333',
                                  }}
                                />
                                <ReactTooltip
                                  place='top'
                                  type='dark'
                                  effect='solid'
                                  id={videoClip.id}
                                  class='custom-tooltip'
                                >
                                  {videoClip.user?.name}
                                </ReactTooltip>
                              </div>
                            );
                          })
                        }
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
          <Paginator
            updateUrl
            store={CreatorUI.wordStore}
          />
        </LeftColumn>
        <RightColumn>
          {
            CreatorUI.wordToRecord &&
              <NextRecording>
                <CallToAction>
                  Next Recording
                </CallToAction>
                <Text>
                  {
                    CreatorUI.wordToRecord && CreatorUI.wordToRecord.target &&
                      <div className='main=text'>
                        {this.prompt}
                      </div>
                  }
                  {
                    CreatorUI.wordToRecord.level &&
                      <Speed>
                        <Abbreviation
                            background={CreatorUI.wordToRecord.level.abbreviation === 'A1' || CreatorUI.wordToRecord.level.abbreviation === 'A2' ? Theme.green : Theme.orange}
                        >
                          {CreatorUI.wordToRecord.level.abbreviation}
                        </Abbreviation>
                        {CreatorUI.wordToRecord.level.abbreviation === 'A1' && ' Slow'}
                        {CreatorUI.wordToRecord.level.abbreviation === 'A2' && ' Moderately Slow'}
                        {CreatorUI.wordToRecord.level.abbreviation === 'B1' && ' Almost Native-Speed'}
                        {CreatorUI.wordToRecord.level.abbreviation === 'B2' && ' Native-Speed'}
                      </Speed>
                  }
                </Text>
              </NextRecording>
          }
          {
            CreatorUI.wordToRecord &&
              <VideoRecorderWrapper>
                <VideoPlayerRecorder
                  countdownBeep
                  afterCreateVideo={this.afterCreateVideo}
                  afterCreateRequestSent={this.afterCreateRequestSent}
                  videoableType='Target'
                  videoableId={CreatorUI.wordToRecord && CreatorUI.wordToRecord.target && CreatorUI.wordToRecord.target.id}
                  mediaStore={CreatorUI.mediaStore}
                  videoClip={CreatorUI.videoClip}
                  onPlaybackClosed={this.onCloseVideo}
                  width={300}
                  onUnmount={this.onCloseVideo}
                  overwriteVideo={CreatorUI.overwriteVideo}
                />
                {
                  isPresent(CreatorUI.videoClip) && CreatorUI.videoClip.userId === AppUI.user.id &&
                    <OutlineButton
                      margin='10px 0'
                      small
                      background='#FFF'
                      selectedBackground={Theme.red}
                      onClick={this.onDeleteVideo}
                      fontSize='12px'
                    >
                      Delete Video
                    </OutlineButton>
                }
              </VideoRecorderWrapper>
          }
          {
            CreatorUI.wordTypeStore.hasIndexData && CreatorUI.levelStore.hasIndexData &&
              <Filter
                store={CreatorUI.wordStore}
                defaultSort='frequency_ranking'
                namespace='creator-word-index'
                limit='10'
                defaultFilters={{root: true}}
                queryStrings={{include_count: true}}
                filters={
                [
                  {
                    type: 'select',
                    name: 'language_id',
                    label: 'Language',
                    options: [{id: 'DE', name: 'German'}, {id: 'ES', name: 'Spanish'}, {id: 'FR', name: 'French'}],
                    labelField: 'name',
                    default: '',
                  },
                  {
                    type: 'text',
                    name: 'target_text',
                    label: 'target',
                    placeholder: 'Enter target',
                    default: '',
                  },
                  {
                    type: 'number',
                    name: 'sentences_count',
                    label: '# Sentences',
                    default: '',
                  },
                  {
                    type: 'number',
                    name: 'decks_count',
                    label: '# Decks',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'joins:target|video_clip_id',
                    label: 'Has Video',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'joins:target|replace_video',
                    label: 'Replace Video',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'needs_secondary_video',
                    label: 'Needs Secondary Video',
                    default: '',
                  },
                  {
                    type: 'boolean',
                    name: 'exclude_from_public_lists',
                    label: 'Excluded from dictionary',
                    default: false,
                    hidden: false,
                    exactValue: false,
                  },
                  {
                    type: 'multi_select',
                    name: 'level_id',
                    options: CreatorUI.levelStore.indexData.filter(item => item.abbreviation !== '' && item.abbreviation !== 'N'),
                    labelField: 'abbreviation',
                  },
                  {
                    type: 'multi_select',
                    name: 'word_type_id',
                    options: CreatorUI.wordTypeStore.indexData.filter(item => ['adv', 'adj', 'noun', 'vrb', 'num'].indexOf(item.abbreviation) !== -1).map(item => {
                      return {id: item.id, name: item.abbreviation};
                    }),
                  },
                ]
                }
              />
          }
          <Options>
            <Option>
              <InPlaceCheckbox
                onChange={CreatorUI.onToggleAutoAdvanceWord}
                icon='check'
                value={CreatorUI.autoAdvanceWord}
              >
                Auto-advance
              </InPlaceCheckbox>
            </Option>
            <Option>
              <InPlaceCheckbox
                onChange={CreatorUI.onToggleOverwriteVideo}
                icon='check'
                value={CreatorUI.overwriteVideo}
              >
                One Video Per Word
              </InPlaceCheckbox>
            </Option>
          </Options>
        </RightColumn>
      </Wrapper>
    );
  }
}

export default SortableColumns(WordIndex, CreatorUI.wordStore, { sortColumn: 'frequency_ranking', sortIsAscending: true });
