import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable } from 'mobx';
import { uniq } from 'lodash';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import { GroupStore, WordStore, WorksheetStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import { isBlank, isPresent } from '@seedlang/utils';
import Alert from 'components/alert';
import { Theme } from '@seedlang/constants';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import DeleteButton from 'components/button/delete_button';
import Spinner from 'components/spinner';
import { AppUI, ExerciseUI } from '@seedlang/state';
import WorksheetWordIndex from 'pages/creator/worksheets/worksheet_word_index';
import InfoTooltip from 'components/info_tooltip';
import ReactTooltip from 'react-tooltip';

const Wrapper = styled.div`

`;

const Note = styled.div`
  font-size: 13px;
  line-height: 17px;
  background: #FFF;
  padding: 10px;
  margin-top: 10px;
`;

const Row = styled.div`
  display: flex;
  button {
    margin-right: 10px;
  }
  .sort-button {
    padding: 10px;
    background: #e4e4e4;
    border-radius: 5px;
    margin: 3px 0 10px 0;
    border: 1px solid gray;
  }
`;

@observer
class VocabEdit extends React.Component {
  @observable showInput = false;
  @observable value = '';
  @observable skipped;
  @observable unprocessed;
  @observable showInstructions = false;
  @observable showExample = false;
  @observable showSpinner = false;
  @observable worksheetWordsLoaded = false;
  @observable intervalId;

  @computed get disabled() {
    return isBlank(this.value) || this.containsInvalidCharacters;
  }

  @computed get invalidCharacters() {
    const chars = ['.', ':', '!', '?', '#', '$', '%', '€', '1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
    return !AppUI.site.showAlternateTargetText && this.props.params.groupId === 'f74a4102-d65b-448c-b261-60b6be2c7eca' ? uniq(chars.filter(item => this.value && this.value.indexOf(item) !== -1)) : null;
  }

  @computed get containsInvalidCharacters() {
    return isPresent(this.invalidCharacters);
  }

  constructor(props) {
    super(props);
    if (!WorksheetStore.hasShowData) {
      this.getWorksheet();
    }
    autorun(() => {
      if (WorksheetStore.hasShowData && !this.worksheetWordsLoaded) {
        this.getWorksheetWords();
        this.worksheetWordsLoaded = true;
      }
    });
    GroupStore.getShow({ids: {groupId: this.props.params.groupId}});
  }

  componentDidMount() {
    if (!WorksheetStore.hasShowData || WorksheetStore.showData.id !== this.props.params.worksheetId) {
      this.getWorksheet();
    }
  }

  componentWillUnmount() {
    ExerciseUI.worksheetWordStore.clearIndexData();
  }

  @autobind getWorksheet() {
    WorksheetStore.getShow({ids: {worksheetId: this.props.params.worksheetId}}, this.afterGetWorksheet);
  }

  @autobind afterGetWorksheet(resp) {
    WorksheetStore.setShowData(resp);
  }

  @autobind getWorksheetWords(params = {}) {
    ExerciseUI.worksheetWordStore.clearFilter();
    if (WorksheetStore.showData?.positionsAreChronological) {
      ExerciseUI.worksheetWordStore.getIndex({ids: {worksheetId: this.props.params.worksheetId}, limit: params.limit || WorksheetStore.showData.worksheetWordsCount, sort: 'position'}, this.afterGetWorksheetWords);
    } else {
      ExerciseUI.worksheetWordStore.getIndex({ids: {worksheetId: this.props.params.worksheetId}, limit: params.limit || WorksheetStore.showData.worksheetWordsCount, sort: 'translations.target_text'}, this.afterGetWorksheetWords);
    }
  }

  @autobind afterGetWorksheetWords(resp) {
    ExerciseUI.worksheetWordStore.setIndexData(resp);
    this.showSpinner = false;
  }

  @autobind afterSetWordOrder(isChronological) {
    if (isChronological) {
      ExerciseUI.worksheetWordStore.getIndex({ids: {worksheetId: this.props.params.worksheetId}, limit: WorksheetStore.showData.worksheetWordsCount, sort: 'position'});
    } else {
      ExerciseUI.worksheetWordStore.getIndex({ids: {worksheetId: this.props.params.worksheetId}, limit: WorksheetStore.showData.worksheetWordsCount, sort: 'translations.target_text'});
    }
    this.getWorksheet();
  }

  @autobind onSubmit() {
    this.showSpinner = true;
    this.showInput = false;
    if (!this.disabled) {
      WordStore.batchCreate({data: {worksheet_id: this.props.params.worksheetId, text: this.value}}, this.afterSubmit);
    }
  }

  @autobind onRemoveWord(id) {
    WorksheetStore.removeWord({data: {word_id: id, worksheet_id: this.props.params.worksheetId}}, this.getWorksheet);
  }

  @autobind moveToUnprocessedVocab(wordId) {
    ExerciseUI.worksheetWordStore.moveToUnprocessedVocab({data: {word_id: wordId, worksheet_id: this.props.params.worksheetId}}, this.afterMoveToUnprocessedVocab);
  }

  @autobind afterMoveToUnprocessedVocab(resp) {
    this.getWorksheetWords();
    this.getWorksheet();
  }

  @autobind afterSubmit(resp) {
    this.showSpinner = false;
    this.skipped = resp.skipped;
    this.unprocessed = resp.unprocessed;
    this.wordIds = resp.wordIds;
    this.showInput = false;
    this.value = '';
    this.getWorksheetWords({limit: WorksheetStore.showData.worksheetWordsCount + resp.wordIds.length});
    this.getWorksheet();
  }

  @autobind clearVocab() {
    ExerciseUI.worksheetWordStore.clearIndexData();
    WorksheetStore.clearVocab({ids: {worksheetId: this.props.params.worksheetId}}, this.getWorksheetWords);
  }

  @computed get hidePositions() {
    return !AppUI.site.showChronologicalSort;
  }

  @autobind sortAlphabetically() {
    ExerciseUI.worksheetWordStore.clearIndexData();
    this.showSpinner = true;
    WorksheetStore.sortWorksheetWordsAlphabetically({ids: {worksheetId: this.props.params.worksheetId}}, this.getWorksheetWords);
  }

  render() {
    return (
      <Wrapper>
        <div className='breadcrumbs'>
          <div>
            <Link
              to={{name: 'creator.groups.worksheets.index', params: {groupId: this.props.params.groupId}}}
            >
              Worksheets
            </Link>
          </div>
          <i className='fa fa-angle-double-right' />
          {
            WorksheetStore.hasShowData &&
              <div>
                <Link
                  to={{name: 'creator.groups.worksheets.edit', params: {worksheetId: this.props.params.worksheetId, groupId: this.props.params.groupId}}}
                >
                  {WorksheetStore.showData.name}
                </Link>
              </div>
          }
          <i className='fa fa-angle-double-right' />
          <div className='current'>
            Vocab
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>{`Vocab List ${WorksheetStore.hasShowData && WorksheetStore.showData.worksheetWordsCount > 0 ? `- ${WorksheetStore.showData.worksheetWordsCount} words` : ''}`}</legend>
              {
                isPresent(this.skipped) &&
                  <Alert
                    margin={isPresent(this.unprocessed) ? '0 0 5px 0' : '0 0 20px 0'}
                  >
                    <b>Couldn't create:</b>
                    <div
                      dangerouslySetInnerHTML={{ __html: this.skipped }}
                    />
                  </Alert>
              }
              {
                isPresent(this.unprocessed) &&
                  <Alert margin='0 0 20px 0' background={Theme.green}>
                    <div><b>Added automatically to unprocessed vocab:</b></div>
                    <div
                      dangerouslySetInnerHTML={{ __html: this.unprocessed }}
                    />
                    <div><i className='fa fa-warning' style={{color: '#FFF'}} /> <b>Remember to proofread {WorksheetStore.showData.publishUnprocessedVocab ? '' : 'and publish '}the <span onClick={() => document.getElementById('unprocessed-vocab')?.scrollIntoView({behavior: 'smooth'})} className='underline'>unprocessed vocab</span>.</b></div>
                  </Alert>
              }
              {
                !this.showInput &&
                  <Row>
                    <button
                      style={{marginBottom: 10}}
                      className='gray-button'
                      onClick={() => this.showInput = true}
                    >
                      <i className='fa fa-plus' />
                      Add Vocab
                    </button>
                    {
                      this.hidePositions && ExerciseUI.worksheetWordStore.indexData.length > 1 &&
                        <button
                          style={{marginBottom: 10}}
                          className='gray-button'
                          onClick={this.sortAlphabetically}
                        >
                          <i className='fa fa-sort-alpha-asc' />
                          Sort Alphabetically
                        </button>
                    }
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                      <InPlaceCheckbox
                        id={WorksheetStore.showData.id}
                        value={WorksheetStore.showData.publishWords}
                        field='publish_words'
                        model='worksheets'
                        icon='check'
                        afterChange={this.getWorksheet}
                        wrapperMargin='10px 10px 0 10px'
                      >
                        Publish Vocab
                      </InPlaceCheckbox>
                      {
                        WorksheetStore.hasShowData &&
                        <Link
                          to={{name: 'worksheets.vocab.show', params: {worksheetId: WorksheetStore.showData.id}}}
                          className='underline'
                          style={{fontSize: 12}}
                        >
                          Go to Vocab
                        </Link>
                      }
                    </div>
                    {
                      !WorksheetStore.showData.publishWords &&
                      <span
                        data-tip
                        data-for='unpublished'
                      >
                        <i className='fa fa-warning' />
                        <ReactTooltip
                          place='top'
                          type='dark'
                          effect='solid'
                          id='unpublished'
                          class='custom-tooltip'
                        >
                          Vocab is unpublished
                        </ReactTooltip>
                      </span>
                    }
                    {
                      ExerciseUI.worksheetHasInvalidVocab &&
                        <span
                          data-tip
                          data-for='invalidVocab'
                        >
                          <i className='fa fa-warning' />
                          <ReactTooltip
                            place='top'
                            type='dark'
                            effect='solid'
                            id='invalidVocab'
                            class='custom-tooltip'
                          >
                            <div>Some words are invalid</div>
                            <div>and will not be displayed</div>
                            </ReactTooltip>
                        </span>
                    }
                    {
                      AppUI.site.showChronologicalSort &&
                        <InPlaceCheckbox
                          id={WorksheetStore.showData.id}
                          value={WorksheetStore.showData.positionsAreChronological}
                          field='positions_are_chronological'
                          model='worksheets'
                          icon='check'
                          afterChange={this.afterSetWordOrder}
                          wrapperMargin='10px'
                        >
                          {'Order is chronological '}
                          <InfoTooltip>
                            If not, chronological sort won't be offered to users.
                          </InfoTooltip>
                        </InPlaceCheckbox>
                    }
                  </Row>
              }
              {
                (this.showSpinner || (!this.showInput && !ExerciseUI.worksheetWordStore.hasIndexData && ExerciseUI.worksheetWordStore.requestCounter > 0)) &&
                  <Spinner />
              }
              {
                !this.showInput &&
                  <WorksheetWordIndex
                    deleteMessage='Remove Word from Worksheet?'
                    highlightedWordIds={this.wordIds}
                    languageId={AppUI.site.languageId}
                    onMoveToUnprocessedVocab={this.moveToUnprocessedVocab}
                    worksheetId={this.props.params.worksheetId}
                    worksheetWordCount={WorksheetStore.hasShowData && WorksheetStore.showData.worksheetWordsCount}
                    afterChange={this.getWorksheetWords}
                    hidePositions={this.hidePositions}
                    positionsAreChronological={WorksheetStore.hasShowData && AppUI.site.showChronologicalSort && WorksheetStore.showData.positionsAreChronological}
                  />
              }
              {
                !this.showInput && WorksheetStore.hasShowData && ExerciseUI.worksheetWordStore.hasIndexData &&
                  <DeleteButton
                    confirmationMessageFloatsRight
                    onConfirm={this.clearVocab}
                    message='Are you sure?'
                    left='200px'
                    right='auto'
                    top='-14px'
                  >
                    <Button
                      margin='10px 0 0 0'
                    >
                      <i className='fa fa-times' />
                      Clear All Vocab
                    </Button>
                  </DeleteButton>
              }
              {
                this.containsInvalidCharacters &&
                  <Alert>{`You have included ${this.invalidCharacters.length > 1 ? '' : 'an'} invalid character${this.invalidCharacters.length > 1 ? 's' : ''}: ${this.invalidCharacters.join('')}.`}</Alert>
              }
              {
                this.showInput &&
                  <textarea
                    style={{
                      height: 300,
                      marginBottom: 10,
                    }}
                    onChange={e => this.value = e.currentTarget.value}
                    value={this.value}
                  />
              }
              {
                this.showInput &&
                <Button
                  onClick={this.onSubmit}
                  disabled={this.disabled}
                >
                  Submit Vocab
                </Button>
              }
              {
                this.showInput &&
                <Button
                  onClick={() => this.showInput = false}
                  background={Theme.red}
                  margin='0 0 0 10px'
                >
                  Cancel
                </Button>
              }
              {
                this.showInput && AppUI.site.showChronologicalSort &&
                  <InPlaceCheckbox
                    id={WorksheetStore.showData.id}
                    value={WorksheetStore.showData.positionsAreChronological}
                    field='positions_are_chronological'
                    model='worksheets'
                    icon='check'
                    wrapperMargin='10px'
                  >
                    {'Order is chronological '}
                    <InfoTooltip>
                      If not, chronological sort won't be offered to users.
                    </InfoTooltip>
                  </InPlaceCheckbox>
              }
              {
                this.showInput &&
                  <Note
                    onClick={() => this.showInstructions = !this.showInstructions}
                  >
                    <i className={`fa fa-angle-${this.showInstructions ? 'down' : 'right'}`} /> Show instructions
                  </Note>
              }
              {
                this.showInput && this.showInstructions &&
                  <Note>
                    {/* <b>Short Format</b>:<br />
                    <code>
                      Word<br />
                      Word;English<br />
                      Word;English<br />
                    </code>
                    You can put articles before nouns. Gender and word type will be detected.
                    All content between parentheses will be removed, but parentheses can be used to indicate register (colloq.), gender (m) or word type (adv).
                    <br/><br/><b>Long Format</b>:<br/> */}
                    <code>
                      Noun[gender], Word[Plural]{AppUI.site.showAlternateTargetText ? '(Romanized)' : ''}, English1<br />
                      Noun[gender], Word[]{AppUI.site.showAlternateTargetText ? '(Romanized)' : ''}, English1<br />
                      Noun[gender], Word[is_plural]{AppUI.site.showAlternateTargetText ? '(Romanized)' : ''}, English1, English2<br />
                      Verb, Word{AppUI.site.showAlternateTargetText ? '(Romanized)' : ''}, English1, English2<br />
                      Adj, Word{AppUI.site.showAlternateTargetText ? '(Romanized)' : ''}, English1<br />
                      Adv, Word{AppUI.site.showAlternateTargetText ? '(Romanized)' : ''}, English1, English2<br />
                    </code>
                    You can add several English translations, separated by commas. Do not add a translation for the plural form.
                    <br />
                    <ul>
                      <li>Both formats can be mixed in the same list.</li>
                      <li>Supported Word Types: Adj, Adv, Aux, Cnj, Int, Noun, Num, Phr (phrase), Pnn (proper noun), Prep, Prt (particle), Verb<br /></li>
                      <li><b>No punctuation, use infinitive/non-declined forms.</b><br /></li>
                      <li>Please do not use the article with a noun!</li>
                      <li>Gender options are: 'm', 'f', or 'n'</li>
                      {
                        AppUI.site.languageId?.startsWith('EN') &&
                          <li>For English vocab lists, enter a definition instead of English translations: <code>Noun,grandad[grandads],a familiar and affectionate term for one's grandfather.</code></li>
                      }
                      {
                        AppUI.site.showWordStress &&
                          <li>You can add a stress to a character by preceding it with a forward slash: <code>вопр/ос[вопр/осы]</code>. Don't worry, it will be displayed nicely to users.</li>
                      }
                    </ul>

                  </Note>
              }
              {
                this.showInput &&
                  <Note
                    onClick={() => this.showExample = !this.showExample}
                  >
                    <i className={`fa fa-angle-${this.showExample ? 'down' : 'right'}`} /> Show example list
                  </Note>
              }
              {
                this.showInput && this.showExample &&
                  <Note>
                    <InPlaceText
                      richText
                      blockClickToEdit
                      showEditButton
                      inputType='textarea'
                      placeholder='No example has been added for now, you can add it here!'
                      defaultValue={GroupStore.showData.vocabListExample}
                      model='groups'
                      field='vocab_list_example'
                      id={GroupStore.showData.id}
                    />
                  </Note>
              }
            </fieldset>
          </div>
        </div>
        <div className='row' id='unprocessed-vocab'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Unprocessed Vocab</legend>
              <Note>
                Use this for phrases, words with punctuation, or anything that won't work in the vocab list.
              </Note>
              <Row>
                <InPlaceCheckbox
                  id={WorksheetStore.showData.id}
                  value={WorksheetStore.showData.publishUnprocessedVocab}
                  field='publish_unprocessed_vocab'
                  model='worksheets'
                  icon='check'
                  afterChange={this.getWorksheet}
                >
                  Publish Unprocessed Vocab
                </InPlaceCheckbox>
                {
                  !WorksheetStore.showData.publishUnprocessedVocab &&
                    <i className='fa fa-warning' />
                }
              </Row>
              <InPlaceText
                richText
                defaultValue={WorksheetStore.showData.unprocessedVocab}
                model='worksheets'
                field='unprocessed_vocab'
                id={WorksheetStore.showData.id}
                afterChange={this.getWorksheetWords}
              />
            </fieldset>
          </div>
        </div>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(VocabEdit);
