import React from 'react';
import { observer } from 'mobx-react';
import { computed, toJS } from 'mobx';
import styled from '@emotion/styled';
import { LanguageIcon } from '@seedlang/icons';
import { formatNumber, kFormatter , isBlank, isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import InfoTooltip from 'components/info_tooltip';
import Copy from 'components/copy';

const Wrapper = styled.div`
  padding: ${props => props.padding};
  position: ${props => props.position};
  background: red;
  display: flex;
  align-items: center;
  background: ${props => props.background};
  font-weight: ${props => props.fontWeight};
  font-size: ${props => props.fontSize};
  top: 0;
  z-index: ${props => props.zIndex};
`;

const Column = styled.div`
  width: ${props => props.width};
  border-left: ${props => props.border};
  padding: 5px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  i {
    margin: 3px 0 0 5px;
    font-size: 16px;
  }
`;

const ColumnHeader = styled.span`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: start;
`;

const ColumnLabel = styled.span`
  line-height: 1;
  overflow: hidden;
  text-wrap: nowrap;
`;

export const buttonColumnWidth = 50;
export const dateColumnWidth = 120;
export const languageColumnWidth = 100;
export const nonDataColumnsWidth = buttonColumnWidth + dateColumnWidth + languageColumnWidth;

@observer
class AnalyticsListRow extends React.Component {

  @computed get day() {
    return this.props.date && parseInt(this.props.date.split(' ')[1], 10);
  }

  @autobind count(type) {
    const value = isPresent(this.props.count) && this.props.count[type];
    if (isBlank(value) || (value === 0 && this.props.displayDashesIfZero.indexOf(type) !== -1)) {
      return '-';
    }
    return value;
  }

  render() {
    return (
      <Wrapper
        background={this.props.header ? '#CCC' : '#FFF'}
        fontWeight={this.props.header ? 'bold' : 'default'}
        fontSize={this.props.header ? '12px' : '14px'}
        padding={this.props.languageIds.length === 1 ? '5px 0' : null}
        position={this.props.position}
        zIndex={this.props.position === 'sticky' ? '99' : null}
      >
        {
          this.props.timePeriod === 'daily' &&
            <Column width={`${buttonColumnWidth}px`}>
              {
                this.props.firstRow &&
                  <IconWrapper
                    onClick={() => this.props.onSelectDay(this.day)}
                  >
                    <i className='fa fa-external-link' />
                  </IconWrapper>
              }
            </Column>
        }
        <Column style={{display: 'flex', alignItems: 'center'}} width={`${dateColumnWidth}px`}>
          <span
            style={{marginRight: '5px'}}
          >
            {this.props.date}
          </span>
          <Copy
            color='#CCC'
            text={this.props.id}
          />
        </Column>
        {!this.props.singleLanguage && (
          <Column width={`${dateColumnWidth}px`}>
            <Row>
              {
                !this.props.header &&
                  <LanguageIcon
                    languageId={this.props.languageId}
                    margin='0 5px 0 0'
                  />
              }
              {this.props.languageId.replace('2', '')}
            </Row>
          </Column>
        )}
        {
          this.props.statFields.map(([fieldKey, field]) => {
            return (
              <Column
                width={`${this.props.dataColumnWidth ?? 200}px`}
                key={fieldKey}
                border={field.showBorder ? '1px solid #CCC' : 'none'}
              >
                {
                  this.props.header &&
                    <ColumnHeader>
                      <ColumnLabel>{field.name}</ColumnLabel>
                      {
                        field.tooltip &&
                          <InfoTooltip>{field.tooltip}</InfoTooltip>
                      }
                    </ColumnHeader>
                }
                {
                  !this.props.header &&
                    <div>
                      {this.count(fieldKey) !== '-' ? field.prefix : null}{kFormatter(this.count(fieldKey))}
                    </div>
                }
              </Column>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default AnalyticsListRow;
