import React from 'react';
import { observer } from 'mobx-react';
import { TriviaCardWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import { AppUI, DeckUI } from '@seedlang/state';
import { flexCenter, flexCenterColumn } from '@seedlang/style_mixins';
import VideoPlayer from 'components/media/video_player';
import { Theme } from '@seedlang/constants';
import PointsBar from 'components/points_bar';
import CardText from 'components/card/card_text';
import MultipleChoiceButtons from 'components/card/multiple_choice_buttons';
import { pixify } from '@seedlang/utils';
import CardButtons from 'components/card/card_buttons';
import CardButton from 'components/card/card_button';
import { BookmarkIcon, ChatIconWithCount, GraphCircleIcon, RightIcon } from '@seedlang/icons';
import WordCard from 'components/word_card/word_card';
import EvaluateButtons from 'components/card/evaluate_buttons';
import CardIcons from 'components/card/card_icons';
import LanguageButton from 'components/button/language_button';
import CardReviewToggle from 'components/card/card_review_toggle';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  height: ${props => props.height};
  width: ${props => props.width};
  background: #e8e8e8;
  position: relative;
  background: #333;
  .graph-icon {
    svg {
      width: 16px;
      margin: 0 2px 0 0;
      fill: #FFF;
    }
  }
`;

const Center = styled.div`
  ${flexCenter()}
  flex: 1;
  height: 100%;
`;

const VideoSection = styled.div`
  ${flexCenter()}
  flex: 1;
  width: 100%;
`;

const VideoPlayerWrapper = styled.div`
  position: relative;
  width: ${props => props.width};
  height: ${props => props.width};
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  margin-left: 10px;
`;

const Right = styled.div`
  margin-right: 10px;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  height: 100%;
`;

const ChatIconWrapper = styled.div`
  ${flexCenter()}
  cursor: pointer;
  margin-top: 5px;
  width: ${props => props.width};
  height: ${props => props.height};
  background: #FFF;
  border-radius: 10px;
  svg {
    margin: 1px 0 0 0;
  }
`;

const CardTextWrapper = styled.div`
  ${flexCenter()}
  position: relative;
  width: calc(100% - 20px);
  border-radius: 5px;
  background: ${props => props.background};
  height: ${props => props.height};
  padding: 0 20px;
`;

const TextAndButtonsSection = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  height: ${props => props.height};
  padding-bottom: 10px;
`;

const IconWrapper = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};
  background: #FFF;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 5px;
  ${flexCenterColumn()}
  .note-icon {
    width: 21px;
    margin-left: -2px;
  }
  .bookmark-icon {
    svg {
      #border {
        fill: #333;
      }
      #background {
        fill: ${props => props.background};
      }
      width: 18px;
    }
  }
`;

const IconColumn = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 3;
`;

@observer
class TriviaTestCard extends React.Component {

  render() {
    return (
      <Wrapper
        height={pixify(DeckUI.layout.contentHeightWithoutButtons)}
        width={pixify(DeckUI.layout.deckWidth)}
      >
        {
          this.props.card &&
            <CardReviewToggle
              card={this.props.card}
            />
        }
        <VideoSection>
          <Left>
            <PointsBar
              width='25px'
              align='left'
              id={this.props.card.id}
              points={this.props.myTurn.points}
              height={DeckUI.layout.triviaVideoSectionHeight}
              correct={this.props.myTurn.correct}
              current={DeckUI.userDeck.points}
              total={140}
            />
          </Left>
          <Center>
            <VideoPlayerWrapper
              height={pixify(DeckUI.layout.triviaVideoWidth)}
              width={pixify(DeckUI.layout.triviaVideoWidth)}
            >
              <VideoPlayer
                border={`10px solid ${this.props.borderColor}`}
                borderRadius='20px'
                autoPlay={this.props.inView}
                maximized
                afterInitialPlay={this.props.afterInitialPlay}
                usingDataSaverHeader={AppUI.usingDataSaverHeader}
                videoClip={this.props.questionVideoClip}
                width={DeckUI.layout.triviaVideoWidth ? DeckUI.layout.triviaVideoWidth - 10 : 0}
                wrapperWidth={DeckUI.layout.triviaVideoWidth}
              />
            </VideoPlayerWrapper>
          </Center>
          <Right>
            {
              this.props.hasOpponent && !DeckUI.reviewDeck &&
                <PointsBar
                  width='25px'
                  align='right'
                  id={this.props.card.id}
                  points={this.props.opponentTurn.points}
                  height={DeckUI.layout.triviaVideoSectionHeight}
                  correct={this.props.opponentTurn.correct}
                  current={this.props.opponentPoints}
                  total={140}
                />
            }
          </Right>
        </VideoSection>
        <TextAndButtonsSection
          height={pixify(DeckUI.layout.triviaTextAndButtonsSectionHeight)}
        >
          {
            this.props.showSentence &&
              <CardTextWrapper
                height={pixify(DeckUI.layout.triviaTextAndButtonsSectionHeight - DeckUI.layout.buttonHeight - 10)}
                background='#e8e8e8'
              >
                <CardText
                  card={this.props.card}
                  cardState={this.props.cardState}
                  wordsAreEnriched
                  margin='0'
                  padding='0 30px'
                  arrowLeft='-2px'
                  languageButtonTop='5px'
                  languageButtonRight='5px'
                  hideHighlight
                  flex='none'
                  sentence={this.props.sentence}
                  languageIconWidth='30px'
                  width={DeckUI.layout.innerDeckWidth - 80}
                  height={DeckUI.layout.triviaTextHeight - 60}
                  background='transparent'
                  position='unset'
                  cardId={this.props.card.id}
                  cardTypeSlug={this.props.card && this.props.card.cardTypeSlug}
                  showText={this.props.showSentence}
                  showType={this.props.showType}
                >
                  <IconColumn>
                    <ChatIconWrapper
                      width={DeckUI.layout.minimumHeight ? '30px' : '40px'}
                      height={DeckUI.layout.minimumHeight ? '30px' : '40px'}
                      onClick={() => DeckUI.gotoCardComments(this.props.card)}
                    >
                      <ChatIconWithCount
                        width={18}
                        count={this.props.card.commentsCount}
                        fontSize={DeckUI.layout.minimumHeight ? '8px' : '9px'}
                        top='-1px'
                      />
                    </ChatIconWrapper>
                    {
                      !DeckUI.reviewDeck &&
                        <IconWrapper
                          width={DeckUI.layout.minimumHeight ? '30px' : '40px'}
                          height={DeckUI.layout.minimumHeight ? '30px' : '40px'}
                          onClick={() => DeckUI.onToggleReview(DeckUI.currentCard)}
                          background={DeckUI.currentCard?.cardState?.addToReviews ? Theme.orange : '#FFF'}
                        >
                          <BookmarkIcon />
                        </IconWrapper>
                    }
                    <IconWrapper
                      width={DeckUI.layout.minimumHeight ? '30px' : '40px'}
                      height={DeckUI.layout.minimumHeight ? '30px' : '40px'}
                      onClick={this.props.toggleShowType}
                    >
                      <LanguageButton
                        width='20px'
                        background='#FFF'
                        languageId={this.props.showType === 'target' ? DeckUI.deck.languageId || 'DE' : 'EN'}
                        useUkFlagForEn={DeckUI.user.useBritishEnglish}
                      />
                    </IconWrapper>
                  </IconColumn>
                </CardText>
              </CardTextWrapper>
          }
          {
            !this.props.showSentence && this.props.card.sentence &&
              <MultipleChoiceButtons
                blockFontCalculation
                disabled={!this.props.inView || !this.props.initialVideoPlayFinished}
                height={pixify(DeckUI.layout.triviaTextAndButtonsSectionHeight - 10)}
                buttonHeight={pixify((DeckUI.layout.triviaTextAndButtonsSectionHeight - 20) / 2)}
                width={DeckUI.layout.innerDeckWidth}
                options={this.props.card.sentence.randomMultipleChoiceOptions}
                onClick={this.props.onClick}
                selected={[this.props.multipleChoiceSelected, this.props.myTurn.timerStoppedAt && this.props.opponentTurn.pastMultipleChoiceOptionId]}
                isMobile={DeckUI.layout.isMobile}
                field='targetText'
                padding='5px'
                margin='0'
                marginTop={0}
                fontSize={DeckUI.layout.minimumHeight ? '14px' : '16px'}
                languageId={this.props.card.languageId}
              />
          }
          {
            this.props.showSentence && !DeckUI.reviewDeck &&
              <CardButtons>
                <CardButton
                  shortcut='1'
                  marginRight={5}
                  text='View Results'
                  backgroundColor={Theme.darkestGreen}
                  icon={<GraphCircleIcon />}
                  disabled={!this.props.inView}
                  onClick={() => DeckUI.gotoTriviaResults(this.props.card)}
                />
                <CardButton
                  submitOnEnter
                  shortcut='2'
                  marginLeft={5}
                  text='Next Card'
                  backgroundColor={Theme.green}
                  icon={<RightIcon />}
                  disabled={!this.props.inView}
                  onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
                />
              </CardButtons>
          }
          {
            this.props.showSentence && DeckUI.reviewDeck &&
              <EvaluateButtons
                card={this.props.card}
                alwaysEnable
                onNextClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
                cardState={this.props.cardState}
                userCard={this.props.userCard}
                disabled={!this.props.inView}
              />
          }
        </TextAndButtonsSection>
        {
          this.props.showWordCard &&
            <WordCard
              card={this.props.card}
              sentence={this.props.sentence}
              word={this.props.sentence && this.props.sentence.selectedWord}
              wordAssociation={this.props.sentence && this.props.sentence.selectedWordAssociation}
            />
        }
        {
          !this.props.showWordCard && DeckUI.reviewDeck &&
            <CardIcons
              right='10px'
              top='5px'
              card={this.props.card}
              userCard={this.props.userCard}
              cardState={this.props.cardState}
              icons={this.props.icons}
            />
        }
      </Wrapper>
    );
  }
}

export default TriviaCardWrapper(TriviaTestCard);
