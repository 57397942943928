import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { isPresent } from '@seedlang/utils';
import { AppUI } from '@seedlang/state';

@observer
class ReferrerOptionCreate extends React.Component {
  @observable slug = '';
  @observable text = '';
  @observable languageId = '';

  onClick() {
    if (isPresent(this.text) && isPresent(this.slug) && isPresent(this.languageId)) {
      const data = {
        text: this.text,
        slug: this.slug,
        languageId: this.languageId,
      };
      AppUI.referrerOptionStore.create({data: data}, this.props.afterCreate);
      this.text = '';
      this.slug = '';
      this.languageId = '';
    }
  }

  render() {
    return (
      <div className='tree-create row' style={{marginBottom: '20px'}}>
        <div className='col-xs-3'>
          <input
            placeholder='Enter Text'
            value={this.text}
            onChange={el => this.text = el.target.value}
          />
        </div>
        <div className='col-xs-3'>
          <input
            placeholder='Enter Slug'
            value={this.slug}
            onChange={el => this.slug = el.target.value}
          />
        </div>
        <div className='col-xs-3'>
          <select
            style={{height: '34px'}}
            value={this.languageId}
            onChange={el => this.languageId = el.target.value}
          >
            <option />
            <option value='DE'>German</option>
            <option value='FR'>French</option>
            <option value='ES'>Spanish</option>
          </select>
        </div>
        <div className='col-xs-3'>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default ReferrerOptionCreate;
