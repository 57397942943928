import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import cx from 'classnames';
import { ModuleWrapper } from '@seedlang/hoc';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  margin: ${props => props.margin};
  border-radius: 5px;
  width: 100%;
`;

const Name = styled.div`
  width: 100%;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
  position: relative;
  &:hover {
    cursor: pointer;
    background: whitesmoke;
  }
  .fa{
    color: #cecece;
    position: absolute;
    right: 13px;
    top: 13px;
  }
`;

const Content = styled.div`
  padding: ${props => props.padding || '0 0 10px 0'};
  width: 100%;
  display: ${props => props.center ? 'flex' : null};
  flex-direction: ${props => props.center ? 'column' : null};
  align-items: ${props => props.center ? 'center' : null};
`;

@observer
class Module extends React.Component {
  render() {
    return (
      <Wrapper
        margin={this.props.margin || '0 0 10px 0'}
      >
        <Name
          onClick={this.props.onToggleMinimize}
        >
          {this.props.title}
            <i
              className={cx('fa', {
                'fa-minus': !this.props.contracted,
                'fa-plus': this.props.contracted,
              })}
            />
        </Name>
        {
          !this.props.contracted &&
            <Content
              padding={this.props.padding}
              center={this.props.center}
            >
              {this.props.children}
            </Content>
        }
      </Wrapper>
    );
  }
}

export default ModuleWrapper(Module);
