import React from 'react';
import { observer } from 'mobx-react';
import { WordTypeStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import WordTypeCreate from 'pages/builder/word_types/word_type_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import { isPresent } from '@seedlang/utils';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 12px;
`;

const Column = styled.div`
  margin: 0 3px;
`;

@observer
class WordTypeIndex extends React.Component {

  constructor(props) {
    super(props);
    WordTypeStore.getIndex();
  }

  @autobind onDelete(id) {
    WordTypeStore.destroy({ ids: { wordTypeId: id } });
  }

  render() {
    return (
      <div className='word_type-index'>
        <WordTypeCreate />
        <table style={{marginTop: '10px'}} className='table-wrapper admin-table'>
          <thead>
            <tr>
              <th width='20' />
              <th>Name</th>
              <th>Abbreviation</th>
              <th>Parent</th>
              <th>Preceding</th>
              <th>Root</th>
              <th>Eval/Root</th>
              <th>Next/Case</th>
              <th width='300px'>spaCy Attributes</th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              WordTypeStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: 'builder.word_types.edit', params: { wordTypeId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    <td>
                      <div className='text'>
                        {item.name}
                      </div>
                    </td>
                    <td>
                      <div className='text'>
                        {item.abbreviation}
                      </div>
                    </td>
                    <td>
                      {isPresent(item.rootWordType) ? item.rootWordType.name : null}
                    </td>
                    <td>
                      {isPresent(item.precedingWordType) ? item.precedingWordType.name : null}
                    </td>
                    <td>
                      <InPlaceCheckbox
                        value={item.root}
                        model='word_types'
                        field='root'
                        icon='check'
                        id={item.id}
                      />
                    </td>
                    <td>
                      <InPlaceCheckbox
                        value={item.evaluateFromRoot}
                        model='word_types'
                        field='evaluate_from_root'
                        icon='check'
                        id={item.id}
                      />
                    </td>
                    <td>
                      <InPlaceCheckbox
                        value={item.useNextWordForCase}
                        model='word_types'
                        field='use_next_word_for_case'
                        icon='check'
                        id={item.id}
                      />
                    </td>
                    <td>
                      {
                        item.spacyAttributes.map(item => {
                          return (
                            <Row
                              key={item.id}
                            >
                              <Column>
                                {item.tag}
                              </Column>
                              <Column>
                                {item.dep}
                              </Column>
                              <Column>
                                {item.pos}
                              </Column>
                              <Column>
                                {item.morphDefinite}
                              </Column>
                            </Row>
                          );
                        })
                      }
                    </td>
                    <td>
                      {
                        AppUI.user.id === '3e72839b-b297-4673-9717-87c8da0d3a60' &&
                          <DeleteButton
                            onConfirm={() => this.onDelete(item.id)}
                            message='Delete this word_type?'
                            className='fa-block right'
                          />
                      }
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={WordTypeStore}
        />
      </div>
    );
  }
}

export default WordTypeIndex;
