import React from 'react';
import { observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import ForumThreadIndex from 'components/forum_thread/forum_thread_index';
import { AppUI, ForumUI } from '@seedlang/state';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import { ForumStore } from '@seedlang/stores';
import Spinner from 'components/spinner';

const Wrapper = styled.div`

`;

@observer
class WorksheetComments extends React.Component {
  @observable showMarkAllThreadsAsSeenByAdminSpinner = false;

  @autobind markAllThreadsAsSeenByAdmin() {
    this.showMarkAllThreadsAsSeenByAdminSpinner = true;
    ForumStore.markAllThreadsAsSeenByAdmin({ids: {forumId: AppUI.user.site.forumId}}, this.afterMarkAllThreadsAsSeenByAdmin);
  }

  @autobind afterMarkAllThreadsAsSeenByAdmin() {
    this.showMarkAllThreadsAsSeenByAdminSpinner = false;
    ForumUI.forumThreadStore.reloadIndexFiltered();
  }

  render() {
    return (
      <Wrapper>
        <BackEndExplainer margin='10px 0'>
          <div>All comments left by users on posts and exercises can be found here, with the most recent comments at the top.</div>
          <div>When a new comment is left in a thread, the thread becomes "unchecked" and a notification appears in the left sidebar.</div>
          <div>It is recommended to mark threads as "checked" once they have been read by a team member.</div>
          </BackEndExplainer>
        {
          AppUI.userIsLoaded &&
            <ForumThreadIndex
              hideLogo
              hideName
              loadForum
              defaultFilters={{comments_count: 'true'}}
              forumId={AppUI.user.site.forumId}
              groupId={this.props.params.groupId}
            />
        }
        <button
          className='gray-button'
          onClick={this.markAllThreadsAsSeenByAdmin}
          style={{margin: '10px 0', width: '250px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
        >
          {
            this.showMarkAllThreadsAsSeenByAdminSpinner ?
              <Spinner margin='0' />
            :
              'Mark all threads as checked'
          }
        </button>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetComments);
