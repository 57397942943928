import styled from '@emotion/styled';
import { AppUI , LoadingState } from '@seedlang/state';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import InPlaceFileUpload from 'components/form/in_place_file_upload';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceText from 'components/form/in_place_text';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Spinner from 'components/spinner';
import { without } from 'lodash';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router';
import PodcastEpisodePublicationStateBanner from './podcast_episode_publication_state_banner';
import { CommaSeparated } from '@seedlang/utils';

const Wrapper = styled.div`

`;

@observer
class PodcastEpisodeEdit extends React.Component {
  @observable showSpinner = false;
  @observable showCreatePostSpinner = false;
  membershipTypesLoadingState = new LoadingState();

  constructor(props) {
    super(props);
    this.getPodcastEpisode();
    AppUI.podcastStore.getIndex();
    AppUI.podcastEpisodeStore.clearIndexData();
    AppUI.membershipTypeStore.getIndex({});
  }

  @autobind getPodcastEpisode() {
    AppUI.podcastEpisodeStore.getShow({ids: {podcastEpisodeId: this.props.params.podcastEpisodeId}});
    setTimeout(() => {
      this.showSpinner = false;
      this.showCreatePostSpinner = false;
    }, 200);
  }

  @autobind async onUpdateMembershipType(membershipTypeId) {
    this.membershipTypesLoadingState.started();
    try {
      const list = new CommaSeparated(AppUI.podcastEpisodeStore.showData.membershipTypeIds);
      list.toggle(membershipTypeId);
      const membershipTypeIds = list.toString();

      AppUI.podcastEpisodeStore.showData.set('membershipTypeIds', membershipTypeIds);
      await AppUI.podcastEpisodeStore.updatePromise({ids: {podcastEpisodeId: this.props.params.podcastEpisodeId}, data: {membershipTypeIds: membershipTypeIds}});
      await AppUI.podcastEpisodeStore.getShowPromise({ ids: { podcastEpisodeId: this.props.params.podcastEpisodeId } });
      this.membershipTypesLoadingState.succeeded();
    }
    catch {
      this.membershipTypesLoadingState.failed('Error updating membership types');
    }
  }

  @autobind onUpdateForInactiveMembers() {
    AppUI.podcastEpisodeStore.showData.set('forMembersWithoutSubscription', !AppUI.podcastEpisodeStore.showData.forMembersWithoutSubscription);
    AppUI.podcastEpisodeStore.update({ids: {podcastEpisodeId: this.props.params.podcastEpisodeId}, data: {forMembersWithoutSubscription: AppUI.podcastEpisodeStore.showData.forMembersWithoutSubscription}}, this.getPodcastEpisode);
  }

  @autobind onUpdateRssFeeds() {
    this.showSpinner = true;
    AppUI.podcastStore.updateRssFeeds({ids: {podcastId: AppUI.podcastEpisodeStore.showData.podcast.id}}, this.getPodcastEpisode);
  }

  @autobind onCreateWorksheet() {
    this.showCreatePostSpinner = true;
    AppUI.worksheetStore.create({data: {podcast_episode_id: AppUI.podcastEpisodeStore.showData.id}}, this.getPodcastEpisode);
  }

  render() {
    return (
      <Wrapper>
        <div className='breadcrumbs' style={{marginBottom: 20}}>
          <Link
            to={{name: 'creator.podcast_episodes.index', params: {groupId: this.props.params.groupId}}}
          >
            View Podcast Episodes
          </Link>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <PodcastEpisodePublicationStateBanner
              publicationState={AppUI.podcastEpisodeStore.showDataField('publicationState')}
              publishedAt={AppUI.podcastEpisodeStore.showDataField('publishedAt')}
              onPublishChanges={this.onUpdateRssFeeds}
              showSpinner={this.showSpinner}
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Title</legend>
              <InPlaceText
                field='title'
                defaultValue={AppUI.podcastEpisodeStore.showDataField('title')}
                model='podcast_episodes'
                id={AppUI.podcastEpisodeStore.showDataField('id')}
                afterChange={this.getPodcastEpisode}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Subtitle</legend>
              <InPlaceText
                field='subtitle'
                defaultValue={AppUI.podcastEpisodeStore.showDataField('subtitle')}
                model='podcast_episodes'
                id={AppUI.podcastEpisodeStore.showDataField('id')}
                afterChange={this.getPodcastEpisode}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Podcast</legend>
              <InPlaceSelect
                includeBlank
                field='podcast_id'
                value={AppUI.podcastEpisodeStore.showDataField('podcastId')}
                options={AppUI.podcastStore.indexData.map(item => [item.id, item.title])}
                model='podcast_episodes'
                id={AppUI.podcastEpisodeStore.showDataField('id')}
                afterChange={this.getPodcastEpisode}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Publish(ed) At</legend>
              <InPlaceDatePicker
                model='podcast_episodes'
                field='published_at'
                id={AppUI.podcastEpisodeStore.showData.id}
                value={AppUI.podcastEpisodeStore.showData.publishedAt}
                afterChange={this.getPodcastEpisode}
                withTime
                timeIntervals={60}
              />
            </fieldset>
          </div>
        </div>
        {
          AppUI.podcastEpisodeStore.hasShowData &&
            <div className='row'>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Membership</legend>
                  {
                    AppUI.membershipTypeStore.indexData.map(item => {
                      return (
                        <div className='row' key={item.id}>
                          <InPlaceCheckbox
                            value={AppUI.podcastEpisodeStore.showData.membershipTypeIds.indexOf(item.id) !== -1}
                            icon='check'
                            onChange={() => this.onUpdateMembershipType(item.id)}
                            disabled={this.membershipTypesLoadingState.isLoading}
                          >
                            {item.name}
                          </InPlaceCheckbox>
                        </div>
                      );
                    })
                  }
                  <div className='row' style={{marginTop: 10}}>
                    <InPlaceCheckbox
                      value={AppUI.podcastEpisodeStore.showData.forMembersWithoutSubscription}
                      icon='check'
                      onChange={() => this.onUpdateForInactiveMembers()}
                    >
                      For Inactive Members Only
                    </InPlaceCheckbox>
                  </div>
                </fieldset>
              </div>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Interactive Player Link</legend>
                  <InPlaceText
                    field='interactive_player_link'
                    defaultValue={AppUI.podcastEpisodeStore.showDataField('interactivePlayerLink')}
                    model='podcast_episodes'
                    id={AppUI.podcastEpisodeStore.showDataField('id')}
                    afterChange={this.getPodcastEpisode}
                  />
                </fieldset>
              </div>
            </div>
        }
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Description (HTML)</legend>
              <InPlaceText
                richText
                defaultValue={AppUI.podcastEpisodeStore.showData.description}
                inputType='textarea'
                model='podcast_episodes'
                field='description'
                id={AppUI.podcastEpisodeStore.showData.id}
                afterChange={this.getPodcastEpisode}
                maxHeight='30em'
                scrollable
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>MP3 (Max 100MB)</legend>
              <InPlaceFileUpload
                model='mp3s'
                file={AppUI.podcastEpisodeStore.showData.mp3}
                id={AppUI.podcastEpisodeStore.showData.id}
                showAudioPlayer
                successCallback={this.getPodcastEpisode}
                querystring={{
                  mp3able_id: AppUI.podcastEpisodeStore.showData.id,
                  mp3able_type: 'PodcastEpisode',
                }}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Subtitles (.vtt or .srt)</legend>
              <InPlaceFileUpload
                model='subtitle_files'
                file={AppUI.podcastEpisodeStore.showData.subtitleFile}
                id={AppUI.podcastEpisodeStore.showData.id}
                successCallback={this.getPodcastEpisode}
                querystring={{
                  subtitlable_id: AppUI.podcastEpisodeStore.showData.id,
                  subtitlable_type: 'PodcastEpisode',
                }}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Link to Post</legend>
              {
                AppUI.podcastEpisodeStore.showDataField('worksheetId') ? (
                  // The `link` is autogenerated on the backend in PodcastEpisode model when there is an associated `worksheet`
                  <>
                    <InPlaceText
                      field='link'
                      defaultValue={AppUI.podcastEpisodeStore.showDataField('link')}
                      model='podcast_episodes'
                      id={AppUI.podcastEpisodeStore.showDataField('id')}
                      readOnly
                    />
                    <Link to={{ name: 'creator.groups.worksheets.edit', params: { groupId: this.props.params.groupId, worksheetId: AppUI.podcastEpisodeStore.showDataField('worksheetId') } }}>
                      <button
                        className='gray-button'
                      >
                        <i className='fa fa-angle-right' />
                        Go to Post
                      </button>
                    </Link>

                  </>
                ) : (
                  <>
                    {
                      this.showCreatePostSpinner ? (
                        <Spinner />
                      ) : (
                        <button
                          className='gray-button'
                          onClick={this.onCreateWorksheet}
                        >
                          <i className='fa fa-plus' />
                          Create Post
                        </button>
                      )
                    }
                  </>
                )
              }
            </fieldset>
          </div>
        </div>
        {
          AppUI.user.developer && AppUI.podcastEpisodeStore.hasShowData && AppUI.podcastEpisodeStore.showData.podcast &&
            <div className='row'>
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Developer Tools: RSS Feeds - {AppUI.podcastEpisodeStore.showData.podcast.title}</legend>
                  <div>
                    Last Updated: {AppUI.podcastEpisodeStore.showData.podcast.rssUpdatedAt ? AppUI.podcastEpisodeStore.showData.podcast.rssUpdatedAt.formatted : 'never'}
                  </div>
                  {
                    !this.showSpinner &&
                      <button
                        style={{marginTop: '10px'}}
                        className='gray-button'
                        onClick={this.onUpdateRssFeeds}
                      >
                        Update RSS Feeds
                      </button>
                  }
                  {
                    this.showSpinner &&
                      <Spinner />
                  }
                </fieldset>
              </div>
            </div>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(PodcastEpisodeEdit);
