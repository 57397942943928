import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';
import SettingsMenu from './settings_menu';
import { isPresent, isBlank } from '@seedlang/utils';

const Wrapper = styled.div`
  margin: ${props => props.margin};
  display: flex;
  flex-direction: ${props => props.flexDirection};
  gap: 15px;
  align-items: start;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  background: #FFF;
  font-weight: bold;
  cursor: pointer;
  height: 68px;
  display: flex;
  position: relative;
  margin: 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 14px;
  padding: 0 10px;
  &:hover {
    background: #DDD;
  }
`;

const ContentContainer = styled.div`
  flex: 1;
  margin: ${props => props.margin};
  padding: 20px;
  border-radius: ${props => props.borderRadius};
  overflow: hidden;
  background: white;
  width: 100%;
`;

const Content = styled.div`
  background: white;
  padding: 10px;
  h3 {
    margin: 0 0 20px 0;
  }
`;
@observer
class Settings extends React.Component {

@computed get flexDirection() {
  if (AppUI.layout.isDesktop) {
    return AppUI.siteIsDefault ? 'row' : 'row-reverse';
  }
  return 'column';
}

  render() {
    return (
      <Wrapper
        flexDirection={this.flexDirection}
        margin={AppUI.layout.isMobile ? '10px 0 0 0' : null}
      >
        {
          isBlank(this.props.children) && AppUI.layout.isMobile &&
            <Header>
              Settings
            </Header>
        }
        {
          isPresent(this.props.children) &&
            <>
              {
                AppUI.layout.isMobile &&
                  <Header onClick={() => AppUI.routeStore.routeToNamed('settings')}>
                    <i className='fa fa-chevron-left' />
                    Back to Settings
                  </Header>
              }
              <ContentContainer
                borderRadius={AppUI.layout.isMobile ? 0 : '10px'}
              >
                <Content>
                  {this.props.children}
                </Content>
              </ContentContainer>
            </>
        }
        {
          AppUI.userIsSignedIn && (AppUI.layout.isDesktop || isBlank(this.props.children)) &&
            <SettingsMenu>
              {this.props.children}
            </SettingsMenu>
        }
      </Wrapper>
    );
  }
}

export default Settings;
