import styled from '@emotion/styled';
import { ExerciseUI } from '@seedlang/state';
import { UserWorksheetStore } from '@seedlang/stores';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.margin};
  .fa {
    margin-right: 5px;
    font-size: 20px;
  }
`;

@observer
class CompletedButton extends React.Component {

  @autobind onClick() {
    const newOverrideCompleted = !this.props.worksheet?.overrideCompleted;
    this.props.worksheet.set('overrideCompleted', newOverrideCompleted);
    UserWorksheetStore.updateByWorksheetId({
      ids: {worksheetId: this.props.worksheet.id},
      data: {overrideCompleted: newOverrideCompleted},
    });
    if (ExerciseUI.worksheetStore.hasIndexData && ExerciseUI.worksheetStore.indexData.find(w => w.id === this.props.worksheet.id)) {
      this.findWorksheetAndUpdateOverrideCompleted(ExerciseUI.worksheetStore.indexData, newOverrideCompleted);
    }
    if (ExerciseUI.gridItemStore.hasIndexData) {
      ExerciseUI.gridItemStore.indexData.forEach(gridItem => {
        this.findWorksheetAndUpdateOverrideCompleted(gridItem.gridItemWorksheets, newOverrideCompleted);
      });
    }
    if (ExerciseUI.recentWorksheetStore.hasIndexData) {
      this.findWorksheetAndUpdateOverrideCompleted(ExerciseUI.recentWorksheetStore.indexData, newOverrideCompleted);
    }
  }

  @autobind findWorksheetAndUpdateOverrideCompleted(worksheetList, overrideCompleted) {
    worksheetList.forEach(worksheet => {
      if (worksheet.id === this.props.worksheet.id) {
        worksheet.set('overrideCompleted', overrideCompleted);
      }
    });
  }

  @computed get color() {
    return '#333';
  }

  render() {
    return (
      <Wrapper
        margin='10px 0 -10px 0'
      >
        <Button
          background={ExerciseUI.site.accentColor}
          color='white'
          onClick={this.onClick}
          fontSize='14px'
          lineHeight='14px'
          height='30px'
          width='100%'
          minWidth='fit-content'
          whiteSpace='nowrap'
          scaleOnHover={1.02}
          scaleOnActive={1}
          borderRadius='5px'
          padding='0px 20px'
        >
          {
            this.props.worksheet?.overrideCompleted ?
              <i className='fa fa-check-square-o' />
            :
              <i className='fa fa-square-o' />
          }
          <div>{ this.props.worksheet?.overrideCompleted ? 'You\'ve Completed This Episode' : 'Mark Episode as Completed' }</div>
        </Button>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(CompletedButton);