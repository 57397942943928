const Levels = [
  {
    id: '8f53a51c-a6db-42ef-b865-b766ddc8ac64',
    name: 'Beginner',
    abbreviation: 'A1',
  },
  {
    id: '2591d8a5-62ed-4091-95bd-c6b076e2bc0b',
    name: 'Elementary',
    abbreviation: 'A2',
  },
  {
    id: 'c2ceb16d-0e42-4a04-aee4-c836805ee654',
    name: 'Intermediate',
    abbreviation: 'B1',
  },
  {
    id: '764dc5a9-78a5-4d08-baf2-e20c289d9cf8',
    name: 'Upper Intermediate',
    abbreviation: 'B2',
  },
  {
    id: '3130a18e-6ac5-44e5-b305-09600811407e',
    name: 'Advanced',
    abbreviation: 'C1',
  },
  {
    id: 'd2e223be-965d-43c6-957f-7e50f0bb71cb',
    name: 'Native Speaker',
    abbreviation: 'N',
  },
];

export default Levels;
