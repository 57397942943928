import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { ListWithBulletIcon, FaqIcon } from '@seedlang/icons';
import HeaderBar from 'components/header_bar';

const Wrapper = styled.div`
`;

const Content = styled.div`
`;

@observer
class Grammar extends React.Component {

  constructor(props) {
    super(props);
    AppUI.createEvent('grammar - view page');
  }

  render() {
    return (
      <Wrapper>
        <HeaderBar
          title={`${AppUI.targetLanguageName} GRAMMAR`}
          homeRoute='grammar'
          titleRoute='grammar'
          titleParams={{page: AppUI.wordStore.page || 1}}
          pageInfo={[
            {
              route: 'grammar.index',
              current: 'Index',
            },
          ]}
          icons={[
            {
              icon: <FaqIcon />,
              tooltipText: 'Help Center',
              iconRoute: 'faq_categories.show',
              iconParams: {faqCategoryId: 'bca6b92e-f8fa-4b7e-8b0c-0364f4779f25'},
            },
            {
              icon: <ListWithBulletIcon />,
              tooltipText: 'View index',
              iconRoute: 'grammar.index',
              breadcrumb: 'Index',
            },
          ]}
        />
        <Content>
          {this.props.children}
        </Content>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(Grammar);
