import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Text from 'components/text';
import { AppUI } from '@seedlang/state';
import ReactTooltip from 'react-tooltip';
import { isPresent } from '@seedlang/utils';
import { computed } from 'mobx';

const Wrapper = styled.div`

`;

@observer
class NameWithoutIcons extends React.Component {

  @computed get dataFor() {
    return `user-${this.props.user?.id}-${Math.random()}`;
  }

  render() {
    return (
      <Wrapper
        data-tip
        data-for={this.dataFor}
      >
        <Text
          bold
          margin='0 4px 0 0'
          fontSize='14px'
          lineHeight='18px'
          color={!AppUI.siteIsDefault && isPresent(AppUI.site.accentColor) && this.props.user.admin ? AppUI.site.accentColor : null}
        >
          {isPresent(this.props.user?.name) ? this.props.user?.name : 'Anonymous'}
        </Text>
        {
          this.props.user.admin &&
            <ReactTooltip
              type='dark'
              effect='solid'
              id={this.dataFor}
              class='custom-tooltip'
            >
              Team Member
            </ReactTooltip>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(NameWithoutIcons);
