import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { pixify } from '@seedlang/utils';
import { AnimatedProgressBarWrapper } from '@seedlang/hoc';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  border-radius: 50px;
  background: ${props => props.background};
  height: ${props => props.height};
  flex: 1;
  position: relative;
`;

const ProgressWrapper = styled.div`
  height: ${props => props.height};
  width: ${props => props.width};
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  border-radius: 10px;
`;

const Progress = styled.div`
  height: ${props => props.height};
  background: ${Theme.green};
  transform: ${props => props.transform};
  top: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  border-radius: 10px;
  will-change: transform;
  -moz-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  transition: all 0.4s linear;
`;

@observer
class AnimatedProgressBar extends React.Component {

  render() {
    return (
      <>
      <Wrapper
        background={this.props.background}
        height={pixify(this.props.backgroundHeight)}
      >
      </Wrapper>
      <ProgressWrapper
        height={pixify(this.props.progressHeight)}
        width={pixify(this.props.progressBarWidth)}
      >
        <Progress
          height={pixify(this.props.progressHeight)}
          transform={`translate(${this.props.xPos}px, 0)`}
        />
      </ProgressWrapper>
      </>
    );
  }
}

export default AnimatedProgressBarWrapper(AnimatedProgressBar);
