import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Module from 'components/module';
import { Theme } from '@seedlang/constants';
import { TreeUI , AppUI } from '@seedlang/state';
import ProgressBar from 'components/progress_bar';

const Wrapper = styled.div`
  padding: 10px;
  background: #FFF;
  margin-bottom: 10px;
`;

const TreeNodeWrapper = styled.li`
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: normal;
  margin-bottom: 0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background: ${props => props.background};
  color: ${props => props.color};
  font-size: 12px;
  padding: 5px 10px;
`;

const UNPLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

const UNPLabel = styled.div`
  background: ${props => props.background};
  color: white;
  padding: 3px 7px;
  font-size: 11px;
  line-height: 12px;
  font-weight: 700;
  width: 45px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TreeNodeTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 5px;
`;

@observer
class GrammarIndexModule extends React.Component {

  render() {
    return (
      <Wrapper>
        <Module
          slug={this.props.module.id}
          title={`${this.props.module.name}${this.props.module.webPublished || !AppUI.user.admin ? '' : ' (unpublished)'}`}
          margin='0px'
        >
          <ul>
            {
              this.props.module.treeNodes.map(treeNode => {
                if (treeNode.webPublished || AppUI.user.admin) {
                  return (
                    <TreeNodeTitleWrapper
                      key={treeNode.id}
                    >
                      <TreeNodeWrapper
                        onClick={() => this.props.onSelectNode(treeNode)}
                        justifyContent='space-between'
                        fontSize='13px'
                        background={treeNode.id === this.props.currentTreeNodeId ? Theme.blue : 'rgb(231 231 231);'}
                        color={treeNode.id === this.props.currentTreeNodeId ? '#FFF' : null}
                      >
                        {treeNode.shortName || treeNode.name}
                        <div className='details'>
                          {
                            !treeNode.webPublished &&
                            <UNPLabelWrapper>
                              <UNPLabel
                                background={Theme.red}
                              >
                                UNP
                              </UNPLabel>
                            </UNPLabelWrapper>
                          }
                        </div>
                      </TreeNodeWrapper>
                      <ProgressBar
                        current={TreeUI.userTreeNodeStore.indexData.find(item => item.treeNodeId === treeNode.id)?.finishedUserDecksCount}
                        total={treeNode.treeNodeDecksCount}
                        width='100%'
                        borderBottomRightRadius='5px'
                        borderBottomLeftRadius='5px'
                        height='5px'
                      />
                    </TreeNodeTitleWrapper>
                  );
                } else {
                  return null;
                }
              })
            }
          </ul>
        </Module>
      </Wrapper>
    );
  }
}

export default GrammarIndexModule;
