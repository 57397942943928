import React from 'react';
import { observer } from 'mobx-react';
import { autorun, computed, observable, toJS } from 'mobx';
import { AppUI } from '@seedlang/state';
import { CampaignTypeStore } from '@seedlang/stores';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ErrorBoundary from 'components/hoc/error_boundary';
import autobind from 'autobind-decorator';
import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import Button from 'components/button/button';
import Text from 'components/text';
import Alert from 'components/alert';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  label {
    font-weight: 300;
    font-size: 16px;
  }
  input[type="checkbox"] {
    width: 20px;
  }
  p {
    margin: 0 0 10px 0;
    font-size: 16px;
  }
`;

const Buttons = styled.div`

`;

@observer
class SettingsUserNotifications extends React.Component {
  @observable pristineBlockMailingByType = [];
  @observable pristineBlockAllMailings = false;
  @observable blockMailingByType = [];
  @observable blockAllMailings = false;
  @observable showMessage = false;
  @observable userIsLoaded = false;

  constructor(props) {
    super(props);
    this.disposer = autorun(() => {
      if (AppUI.userIsLoaded && !this.userIsLoaded) {
        this.userIsLoaded = true;
        this.pristineBlockMailingByType = isPresent(AppUI.user.blockMailingByType) ? AppUI.user.blockMailingByType.split(',') : [];
        this.pristineBlockAllMailings = AppUI.user.blockAllMailings;
        this.blockMailingByType = isPresent(AppUI.user.blockMailingByType) ? AppUI.user.blockMailingByType.split(',') : [];
        this.blockAllMailings = AppUI.user.blockAllMailings;
      }
    });
    CampaignTypeStore.getIndex({filters: {unsubscribable: true}});
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  @computed get pristineBlockMailingByTypeString() {
    return this.pristineBlockMailingByType && toJS(this.pristineBlockMailingByType).sort().join(',');
  }

  @computed get blockMailingByTypeString() {
    return this.blockMailingByType && toJS(this.blockMailingByType).sort().join(',');
  }

  @computed get dirtyForm() {
    return (this.blockAllMailings !== this.pristineBlockAllMailings) || (this.pristineBlockMailingByTypeString !== this.blockMailingByTypeString);
  }

  @autobind toggleBlockMailingByType(slug) {
    if (this.blockMailingByType.indexOf(slug) === -1) {
      this.blockMailingByType.push(slug);
    } else {
      this.blockMailingByType = this.blockMailingByType.filter(item => item !== slug);
    }
  }

  @autobind onReset() {
    this.blockMailingByType = this.pristineBlockMailingByType;
    this.blockAllMailings = this.pristineBlockAllMailings;
  }

  @autobind onSubmit() {
    if (AppUI.userIsSignedIn) {
      AppUI.authUserStore.update({data: {block_mailing_by_type: this.blockMailingByTypeString, block_all_mailings: this.blockAllMailings}});
    } else {
      const notificationToken = window.location.search.split('=')[1];
      if (notificationToken) {
        AppUI.userStore.getIndex({filters: {notification_token: notificationToken}}, this.afterGetUsers);
      }
    }
    this.pristineBlockMailingByType = this.blockMailingByType;
    this.pristineBlockAllMailings = this.blockAllMailings;
  }

  @autobind afterGetUsers(resp) {
    const userId = resp[0]['id'];
    AppUI.userStore.update({ids: {userId: userId}, data: {block_mailing_by_type: this.blockMailingByTypeString, block_all_mailings: this.blockAllMailings}}, this.afterUserUpdate);
  }

  @autobind afterUserUpdate() {
    this.showMessage = true;
  }

  @autobind toggleBlockAllMailings() {
    this.blockAllMailings = !this.blockAllMailings;
  }

  render() {
    return (
      <Wrapper>
        {
          this.showMessage &&
            <Alert
              textAlign='center'
              width='100%'
            >
              Your email notification settings have been saved.
            </Alert>
        }
        <Text
          heading='3'
          margin='0 0 20px 0'
        >
          Email Notifications
        </Text>
        <Text
          fontSize='14px'
        >
          I would like to receive emails for
        </Text>
        {
          CampaignTypeStore.indexData.map(item => {
            return (
              <div
                key={item.id}
              >
                <InPlaceCheckbox
                  allowUpdate
                  icon='check'
                  id={item.id}
                  key={item.id}
                  onChange={() => this.toggleBlockMailingByType(item.slug)}
                  value={!this.blockAllMailings && this.blockMailingByType && this.blockMailingByType.indexOf(item.slug) === -1}
                  disabled={this.blockAllMailings}
                >
                  {item.name}
                </InPlaceCheckbox>
              </div>
            );
          })
        }
        <div>
        <InPlaceCheckbox
          allowUpdate
          icon='check'
          onChange={this.toggleBlockAllMailings}
          value={this.blockAllMailings}
        >
          Block All Emails
        </InPlaceCheckbox>
        </div>
        <Buttons>
          <Button
            disabled={!this.dirtyForm}
            background={Theme.green}
            onClick={this.onSubmit}
            margin='10px 2px 0 2px'
          >
            <i className='fa fa-check' />
            Submit
          </Button>
          {
            !this.props.hideReset &&
              <Button
                background={Theme.red}
                disabled={!this.dirtyForm}
                onClick={this.onReset}
                margin='10px 2px 0 2px'
              >
                <i className='fa fa-times' />
                Reset
              </Button>
          }
        </Buttons>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SettingsUserNotifications);
