import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { BrokenIcon } from '@seedlang/icons';
import styled from '@emotion/styled';
import {AppUI} from '@seedlang/state';

const Wrapper = styled.div`
  font-size: 12px;
  line-height: 14px;
  padding: 5px;
  .broken-icon {
    margin-bottom: 5px;
    svg {
      width: 25px;
    }
  }
`;

const Message = styled.div`
  margin: 5px;
  padding: 5px;
  border: 2px dotted red;
  text-align: center;
  background: white;
`;

@observer
class ErrorBoundary extends React.Component {
  @observable errorCaught = false;

  componentDidCatch(error, errorInfo) {
    this.errorCaught = true;
    // Sentry.captureException(error, { extra: errorInfo });
  }

  render() {
    if (this.errorCaught) {
      return (
        <Wrapper>
          <Message>
            <BrokenIcon />
            {
              !this.props.hideText &&
              <span>
                {AppUI.getErrorMessage()};
              </span>
            }
          </Message>
        </Wrapper>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
