import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Constants } from '@seedlang/constants';
import { isBlank } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
`;

const ColorOption = styled.div`
  background: ${props => props.background};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ColorOptionInner = styled.div`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: ${props => props.background};
`;

@observer
class InPlaceColorPicker extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          Constants.COLOR_OPTIONS.map(item => {
            return (
              <ColorOption
                onClick={() => this.props.onChange(item[0])}
                key={item[0]}
                background={Constants.COLORS[item[0]]}
              >
                <ColorOptionInner
                  background={this.props.value === item[0] || (isBlank(this.props.value) && item[0] === 'blue') ? '#FFF' : Constants.COLORS[item[0]]}
                />
              </ColorOption>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(InPlaceColorPicker);
