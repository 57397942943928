import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import Text from 'components/text';

const Wrapper = styled.div`
  margin: ${props => props.margin};
  width: 100%;
  display: flex;
  flex-direction: column;
  background: linear-gradient(-134deg,#3BD7C5 0%,#0DAFF6 100%);
  padding: 10px;
  align-items: center;
  height: 100px;
  justify-content: center;
`;

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Title = styled.div`
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 4px;
    width: 16px;
  }
`;

@observer
class FaqHeader extends React.Component {

  render() {
    return (
      <Wrapper
        margin={this.props.margin}
      >
        <LinksWrapper>
          {
            this.props.currentRoute === 'help_center' &&
              <Text
                color='#FFF'
                bold
                fontSize='20px'
                lineHeight='24px'
              >
                Seedlang Help Center
              </Text>
          }
          {
            this.props.currentRoute !== 'help_center' &&
              <Link
                to={{name: 'help_center'}}
              >
                <Text
                  color='#FFF'
                  bold
                  underline
                  fontSize='20px'
                  lineHeight='24px'
                >
                  Seedlang Help Center
                </Text>
              </Link>
          }
          {
            this.props.currentRoute !== 'help_center' &&
              <Text
                color='#FFF'
                bold
                fontSize='20px'
                lineHeight='24px'
              >
                &nbsp;/&nbsp;
              </Text>
          }
          {
            this.props.currentRoute === 'faq_categories.show' && this.props.faqCategoryName &&
              <TextWrapper>
                <Text
                  color='#FFF'
                  bold
                  fontSize='20px'
                  lineHeight='24px'
                >
                  {this.props.faqCategoryName}
                </Text>
              </TextWrapper>
          }
          {
            this.props.currentRoute !== 'faq_categories.show' && this.props.faqCategoryName &&
              <Link
                to={{name: 'faq_categories.show', params: {faqCategoryId: this.props.faqCategoryId}}}
              >
                <TextWrapper>
                  <Text
                    color='#FFF'
                    bold
                    underline
                    fontSize='20px'
                    lineHeight='24px'
                  >
                    {this.props.faqCategoryName}
                  </Text>
                </TextWrapper>
              </Link>
          }
        </LinksWrapper>
        <Title>
          <Text
            bold
            fontSize='28px'
            lineHeight='32px'
            margin='5px 0 0 0'
            color='#FFF'
          >
            {this.props.title}
          </Text>
        </Title>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FaqHeader);
