import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import { ExerciseReorderWrapper } from '@seedlang/hoc';
import { DeckUI, ExerciseUI } from '@seedlang/state';
import ExerciseSentence from 'components/exercise/exercise_sentence';
import ExerciseWordAssociations from 'components/exercise/exercise_word_associations';
import styled from '@emotion/styled';
import Prompt from 'components/exercise/prompt';
import ExerciseEntry from 'components/exercise/exercise_entry';
import Text from 'components/text';
import { displayTargetTextCorrectedForLanguage, isPresent } from '@seedlang/utils/src';
import { flexCenter } from '@seedlang/style_mixins';
import { remove } from 'lodash';
import autobind from 'autobind-decorator';
import ExercisePrompt from 'components/exercise/exercise_prompt';
import VideoPlayer from 'components/media/video_player';
import { flexCenterColumn } from '@seedlang/style_mixins/src';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 5;
  flex: 1;
  .not-selected {
    color: ${props => props.inDeck ? 'white' : 'black'};
    background: ${props => props.inDeck ? '#696969' : null};
    border-radius: 10px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${props => props.inDeck ? '14px' : '16px'};
    padding: ${props => props.inDeck ? '0 20px' : '0'};
  }
`;

const Sentence = styled.div`

`;

const Result = styled.div`
  background: #DEDEDE;
  border-radius: 5px;
  width: calc(100% - 20px);
  margin: 0 0 20px 0;
  font-size: 16px;
  padding: 10px;
  font-weight: bold;
  position: relative;
`;

const ExerciseWordAssociationsWrapper = styled.div`
  margin: ${props => props.margin};
`;

const Content = styled.div`

`;

const ShowEnglishTranslation = styled.div`
  color: #696969;
  font-size: 12px;
  &:hover {
    text-decoration: none;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  ${flexCenter()}
  margin-top: 20px;
  @media (max-width: 899px) {
    flex-direction: column;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  button {
    margin-bottom: 10px;
  }
  .fa {
    margin: 0;
  }
`;

const VideoSection = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  margin-top: 40px;
`;

const VideoWrapper = styled.div`
  ${flexCenterColumn()}
  height: ${props => props.height};
  width: ${props => props.width};
  position: relative;
`;

@observer
class ExerciseReorder extends React.Component {
  @observable showEnglishTranslations = [];

  @computed get margin() {
    if (ExerciseUI.layout.minimumHeight) {
      return 0;
    } else if (this.props.inDeck) {
      return '10px 0 20px 0';
    } else {
      return '10px 0';
    }
  }

  @autobind toggleEnglishTranslation(exerciseEntry) {
    if (this.englishTranslationIsToggled(exerciseEntry)) {
      remove(this.showEnglishTranslations, item => item === exerciseEntry);
    } else {
      this.showEnglishTranslations.push(exerciseEntry.id);
    }
  }

  @autobind englishTranslationIsToggled(exerciseEntry) {
    return this.showEnglishTranslations.indexOf(exerciseEntry.id) !== -1;
  }

  render() {
    return (
      <Wrapper
        inDeck={this.props.inDeck}
      >
        {
          this.props.exercise && this.props.exercise.exerciseEntries.map(item => {
            return (
              <ExerciseEntry
                inDeck={this.props.inDeck}
                key={item.id}
                grammarTip={item.grammarTip}
                showButtons={!this.props.inDeck && this.props.exercise.exerciseEntries.length > 1}
                exerciseEntry={item}
                {...this.props}
              >
                {
                  ((DeckUI.hasDeck && !DeckUI.layout.minimumHeight) || isPresent(item.instruction)) &&
                    <Text
                      center
                      bold
                    >
                      {isPresent(item.instruction) ? item.instruction : 'Translate this Sentence'}
                    </Text>
                }
                {
                  item.prompt && (this.props.inDeck || !this.props.hideEnglishPrompts || item.showSource) &&
                    <Prompt>
                      {item.prompt}
                    </Prompt>
                }
                {
                  isPresent(item.videoClip) &&
                    <VideoSection>
                      <VideoWrapper
                        height='180px'
                        width='180px'
                      >
                        <VideoPlayer
                          maximized={false}
                          autoPlay={this.props.inView}
                          circle
                          videoClip={item.videoClip}
                          width={Math.floor(DeckUI.layout.deckWidth / 3.4)}
                          position='relative'
                        />
                      </VideoWrapper>
                    </VideoSection>
                }
                {
                  DeckUI.hasDeck && this.props.cardState.revealed && !item.state.correct &&
                    <Result>
                      {displayTargetTextCorrectedForLanguage(item.sentence.targetText, DeckUI.deck.languageId)}
                      {
                        isPresent(this.props.sourceText) && item.showSource &&
                          <Text
                            center
                            italic
                            style={{fontWeight: 'normal'}}
                          >
                            {this.props.sourceText}
                          </Text>
                      }
                    </Result>
                }
                {
                  item.sentence &&
                    <Sentence>
                      {
                        (!item.state.revealed || item.state.correct || !this.props.inDeck) &&
                          <ExerciseWordAssociationsWrapper
                            margin={this.margin}
                          >
                            <ExerciseWordAssociations
                              clickable
                              exerciseEntry={item}
                              clickWithinSentence
                              center={this.props.inDeck}
                              inDeck={this.props.inDeck}
                              wordAssociations={item.wordAssociationsWithExtraWords}
                              assignedWordAssociationIds={item.sentence.state.assignedWordAssociationIds}
                              setAssignedWordAssociations={ExerciseUI.setAssignedWordAssociations}
                              sentence={item.sentence}
                              onSetValueToWordAssociation={ExerciseUI.onSetValueToWordAssociation}
                              onClick={this.props.assignToNextWordAssociation}
                            />
                          </ExerciseWordAssociationsWrapper>
                      }
                      <ExerciseSentence
                        inputType='reorder'
                        wordMargin='0 5px 5px 0'
                        wordHeight='40px'
                        hideLanguageButton
                        inDeck={this.props.inDeck}
                        justifyContent={this.props.inDeck ? 'center' : 'flex-start'}
                        sentence={item.sentence}
                        revealed={item.state.revealed}
                        showAlternateTextFeedback={item.state.correct && item.state.submittedValueMatchesAlternateText}
                        exercise={this.props.exercise}
                      />
                    </Sentence>
                }
                {
                  !this.props.inDeck && this.props.hideEnglishPrompts && !item.showSource &&
                    <ExercisePrompt
                      exerciseEntry={item}
                    />
                }
                {
                  item.state.revealed && item.state.correct && item.showSource &&
                    <Text
                      center={this.props.inDeck}
                      italic={this.props.inDeck}
                      style={{fontWeight: 'normal'}}
                    >
                      {this.props.sourceText}
                    </Text>
                }
              </ExerciseEntry>
            );
          })
        }
      </Wrapper>
    );
  }
}

export default ExerciseReorderWrapper(ExerciseReorder);
