import React from 'react';
import { observer } from 'mobx-react';
import { SiteStore, PageStore } from '@seedlang/stores';
import { computed } from 'mobx';
import autobind from 'autobind-decorator';
import { PageEdit } from 'pages/builder/pages';

@observer
class SitePageEdit extends React.Component {
  componentDidMount() {
    this.getPage();
    SiteStore.getIndex();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.routeParams.pageId !== prevProps.routeParams.pageId) {
      this.getPage();
    }
  }

  componentWillUnmount() {
    PageStore.clearShowData();
  }

  @computed get page() {
    return PageStore.showData;
  }

  @autobind getPage() {
    PageStore.getShow({ ids: { pageId: this.props.routeParams.pageId }});
  }

  render() {
    return (
      <PageEdit
        {...this.props}
        whiteLabeled
      />
    );
  }
}

export default SitePageEdit;
