import React from 'react';
import { observer } from 'mobx-react';
import { isPresent, isBlank , pixify } from '@seedlang/utils';
import SentenceBreakdownWord from 'components/sentence_breakdown_word';
import styled from '@emotion/styled';
import { SentenceBreakdownWrapper } from '@seedlang/hoc';
import { last } from 'lodash';

const Wrapper = styled.div`
  width: ${props => pixify(props.width)};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  cursor: ${props => props.cursor};
  margin: ${props => props.margin};
  pointer-events: ${props => props.pointerEvents};
  justify-content: ${props => props.center ? 'center' : 'left'};
  .fa-warning {
    display: absolute;
    top: 0;
    right: 0;
  }
`;

const WordWrapper = styled.span`
  display: inline-flex;
  align-items: center;
`;

const Punctuation = styled.span`
  height: ${props => props.lineHeight};
  font-size: ${props => props.fontSize};
  display: inline-flex;
  align-items: center;
  margin-right: ${props => props.marginRight || '3px'};
  margin-left: ${props => props.marginLeft};
`;

@observer
class SentenceBreakdown extends React.Component {

  static defaultProps = {
    width: '100%',
  }

  render() {
    return (
      <Wrapper
        pointerEvents={this.props.pointerEvents}
        cursor={this.props.cursor}
        center={this.props.center}
        margin={!this.props.margin && this.props.center ? '0 -10px 0 0' : this.props.margin}
        width={this.props.width}
      >
        {
          isPresent(this.props.wordAssociations) && this.props.wordAssociations.map((item, index) => {
            return (
              <WordWrapper
                key={item.id}
              >
                {
                  (item.precedingPunctuation || (item.punctuation && this.props.sentence?.rightToLeftLanguage)) &&
                    <Punctuation
                      lineHeight={pixify(this.props.lineHeight)}
                      fontSize={pixify(this.props.fontSize)}
                      marginRight={this.props.sentence.languageId === 'FR' && last(item.precedingPunctuation) === '«' ? '3px' : 0}
                    >
                      {item.punctuation && this.props.sentence?.rightToLeftLanguage ? item.punctuation : item.precedingPunctuation}
                    </Punctuation>
                }
                {
                  this.props.wordAssociations[index - 1]?.targetText?.endsWith('\'') &&
                    <SentenceBreakdownWord
                      {...this.props}
                      wordAssociation={this.props.wordAssociations[index - 1]}
                      index={index - 1}
                      onSubmit={this.props.onSubmit}
                      onClick={this.props.onClick}
                    />
                }
                {
                  !item.targetText?.endsWith('\'') &&
                    <SentenceBreakdownWord
                      {...this.props}
                      wordAssociation={item}
                      index={index}
                      onSubmit={this.props.onSubmit}
                      onClick={this.props.onClick}
                    />
                }
                {
                  item.punctuation && !this.props.sentence.rightToLeftLanguage &&
                    <Punctuation
                      lineHeight={pixify(this.props.lineHeight)}
                      fontSize={pixify(this.props.fontSize)}
                      marginRight={item.punctuation === '-' ? '0px' : pixify(this.props.marginRight)}
                      marginLeft={(this.props.sentence.languageId === 'FR' && ['?', '!', ':', '»'].indexOf(item.punctuation[0]) !== -1) ? '3px' : 0}
                    >
                      {item.punctuation}
                    </Punctuation>
                }
              </WordWrapper>
            );
          })
        }
        {
          ((this.props.alertOnNoSelections && !this.props.sentence.hasSelections) || (this.props.alertOnNoHighlightWordAssociationIds && isBlank(this.props.highlightWordAssociationIds))) &&
            <i className='fa fa-warning' />
        }
      </Wrapper>
    );
  }
}

export default SentenceBreakdownWrapper(SentenceBreakdown);
