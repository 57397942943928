import React from 'react';
import { observer } from 'mobx-react';
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';
import OutlineButton from 'components/button/outline_button';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin: 10px;
  display: flex;
  flex-direction: column;
`;

@observer
class More extends React.Component {

  render() {
    return (
      <Wrapper>
        <OutlineButton
          width='100%'
          margin='0 0 10px 0'
          background={Theme.blue}
          selectedBackground='#FFF'
          onClick={() => AppUI.routeStore.routeToNamed('settings')}
        >
          Settings
        </OutlineButton>
        <OutlineButton
          width='100%'
          margin='0 0 10px 0'
          background={Theme.blue}
          selectedBackground='#FFF'
          onClick={() => AppUI.gotoFaq()}
        >
          FAQ
        </OutlineButton>
        <OutlineButton
          width='100%'
          margin='0 0 10px 0'
          background={Theme.blue}
          selectedBackground='#FFF'
          onClick={() => AppUI.gotoContact()}
        >
          Contact Seedlang
        </OutlineButton>
        <OutlineButton
          width='100%'
          margin='0 0 10px 0'
          background={Theme.blue}
          selectedBackground='#FFF'
          onClick={() => AppUI.gotoPartners()}
        >
          Partners
        </OutlineButton>
        <OutlineButton
          width='100%'
          margin='0 0 10px 0'
          background={Theme.blue}
          selectedBackground='#FFF'
          onClick={() => AppUI.gotoPrivacy()}
        >
          Privacy
        </OutlineButton>
        <OutlineButton
          width='100%'
          margin='0 0 10px 0'
          selectedBackground={Theme.red}
          background='#FFF'
          onClick={() => AppUI.signOut()}
        >
          Sign Out
        </OutlineButton>
      </Wrapper>
    );
  }
}

export default More;
