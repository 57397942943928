import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { each, isEmpty, isFunction, orderBy, sortBy } from 'lodash';
import TypeAhead from 'components/type_ahead';
import DeleteButton from 'components/button/delete_button';
import { isPresent } from '@seedlang/utils';
import Paginator from 'components/paginator';
import { Link } from 'react-router';
import styled from '@emotion/styled';
import ReactTooltip from 'react-tooltip';
import Filter from 'components/backend_filter/filter';
import { computed } from 'mobx';
import { debounce } from 'lodash/function';

const Wrapper = styled.div`
  position: relative;
`;

const DisableMessage = styled.div`
  background: white;
  padding: 5px;
  font-size: 13px;
  font-style: italic;
  color: #797979;
`;

@observer
class SearchMultiSelect extends React.Component {

  static propTypes = {
    ids: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    onCreate: PropTypes.func,
    onDestroy: PropTypes.func,
    searchStore: PropTypes.object.isRequired,
    searchField: PropTypes.string.isRequired,
    displayField: PropTypes.string.isRequired,
    sort: PropTypes.string.isRequired,
    excludeIds: PropTypes.array,
    paginatorStore: PropTypes.object,
    paginatorIds: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    currentTreeNodePosition: PropTypes.number,
    defaultValue: PropTypes.string,
  }

  constructor(props) {
    super(props);
    this.props.searchStore.setPage(1);
    this.props.searchStore.setLimit(999);
    if (this.props.paginatorStore) {
      this.props.paginatorStore.setPage(1);
    }
    if (this.props.searchFilters) {
      each(this.props.searchFilters, filter => {
        this.props.searchStore.setFilter(filter.key, filter.value, {skipCookie: true});
      });
    }
    this.onClear();
    this.debouncedSearch = debounce(this.performSearch.bind(this), 300); // 300ms delay
  }

  componentWillUnmount() {
    this.onClear();
  }

  onKeyUp(value) {
    if (isEmpty(value)) {
      this.onClear();
    } else if (value.length > 1) {
      if (this.props.sort) {
        this.props.searchStore.setSort(`-SIMILARITY(${this.props.sort}`);
      }
      const filterValue = value.match('"') ? value : `~${value}~`;
      this.debouncedSearch(filterValue);
    }
  }

  performSearch(filterValue){
    this.props.searchStore.setFilter(this.props.searchField, filterValue, { skipCookie: true, refresh: true });
  }

  onClear() {
    this.props.searchStore.clearIndexData();
  }

  linkParams(id) {
    const params = {};
    params[this.props.linkId] = id;
    return params;
  }

  _formattedItem(item) {
    if (isFunction(item[this.props.displayField]) && isPresent(item[this.props.displayField]())) {
      return item[this.props.displayField]();
    } else if (this.props.displayField && isPresent(item[this.props.displayField])) {
      return item[this.props.displayField];
    }
    return item.name;
  }

  @computed get defaultFilters() {
    const res = {};
    this.props.searchFilters.forEach(item => {
      res[item.key] = item.value;
    });
    return res;
  }

  render() {
    return (
      <Wrapper>
        {
          isPresent(this.props.filters) &&
            <Filter
              store={this.props.searchStore}
              defaultSort={this.props.sort}
              defaultFilters={this.defaultFilters}
              filters={this.props.filters}
              namespace={this.props.namespace}
            />
        }
        {
          !this.props.disable &&
            <TypeAhead
              excludeIds={this.props.excludeIds}
              onKeyUp={this.onKeyUp.bind(this)}
              onSelect={this.props.onCreate}
              onClear={this.onClear.bind(this)}
              options={this.props.searchStore.indexData}
              displayField={this.props.displayField}
            />
        }
        {
          this.props.disable && this.props.disableMessage &&
            <DisableMessage>
              {this.props.disableMessage}
            </DisableMessage>
        }
        {
          isPresent(this.props.options) && (
            <table>
              <tbody>
                {
                  sortBy(this.props.options, ['name']).map(item => {
                    return (
                      <tr
                        key={item.id}
                        style={{ borderBottom: '1px solid #CCC' }}
                      >
                        <td style={{padding: '5px'}}>
                          <div className='text'>
                            { !this.props.linkTo && item.name }
                            {
                              this.props.linkTo &&
                                <Link
                                  to={{ name: this.props.linkTo, params: this.linkParams(item.id) }}
                                >
                                  {this._formattedItem(item)}
                                  {
                                    this.props.previousTreeNodeDecks && this.props.previousTreeNodeDecks.hasOwnProperty(item.id) &&
                                      <span data-tip data-for={`didactics-1-${item.id}`} style={{marginRight: 4}}>
                                        <span> ({this.props.previousTreeNodeDecks[item.id]?.length})</span>
                                        <ReactTooltip
                                          place='right'
                                          type='dark'
                                          effect='solid'
                                          id={`didactics-1-${item.id}`}
                                          class='custom-tooltip'
                                        >
                                          {
                                            this.props.previousTreeNodeDecks[item.id].length > 0 &&
                                              <span>
                                                This word appears in {this.props.previousTreeNodeDecks[item.id]?.length} tree deck{this.props.previousTreeNodeDecks[item.id]?.length > 1 ? 's' : ''} before this one:
                                                {
                                                  orderBy(this.props.previousTreeNodeDecks[item.id], [deck => deck.treeNode.treePosition]).map(treeNodeDeck => {
                                                    return (
                                                        <li
                                                          key={item.id + treeNodeDeck.id}
                                                        >
                                                          {treeNodeDeck.treeNode.name} - {treeNodeDeck.deck.name} ({this.props.currentTreeNodePosition - treeNodeDeck.treeNode.treePosition} nodes ago)
                                                        </li>
                                                    );
                                                  })
                                                }
                                              </span>
                                          }
                                          {
                                              this.props.previousTreeNodeDecks[item.id].length === 0 &&
                                                <span>This word does not appear in any previous node.</span>
                                          }
                                        </ReactTooltip>
                                    </span>
                                  }
                                </Link>
                            }
                          </div>
                        </td>
                        {
                          !this.props.disable &&
                            <td width='30px' style={{padding: '5px'}}>
                              <DeleteButton
                                onConfirm={this.props.onDestroy.bind(this, item.id)}
                                message='Delete this item?'
                                className='fa-block right'
                              />
                            </td>
                        }
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          )
        }
        {
          this.props.paginatorStore &&
            <Paginator
              store={this.props.paginatorStore}
              ids={this.props.paginatorIds}
            />
        }
      </Wrapper>
    );
  }
}

export default SearchMultiSelect;
