import React from 'react';

class Ribbon extends React.Component {

  render() {
    return (
      <svg viewBox='0 0 154 174' version='1.1' xmlns='https://www.w3.org/2000/svg'>
        <g>
          <path d='M0,0 L154,0 L154,163.526084 C154,169.048932 149.522847,173.526084 144,173.526084 C142.791325,173.526084 141.592662,173.306965 140.462156,172.879354 L80.537844,150.213178 C78.2580976,149.350871 75.7419024,149.350871 73.462156,150.213178 L13.537844,172.879354 C8.37217563,174.833252 2.60062729,172.229597 0.646729997,167.063928 C0.219119683,165.933422 3.36857678e-15,164.734759 0,163.526084 L0,0 Z' id='Rectangle'></path>
        </g>
      </svg>
    );
  }
}

export default Ribbon;
