import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { VocabDecksWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import DeckLink from 'components/deck/deck_link';
import { Theme } from '@seedlang/constants';
import DeckLinkIconWrapper from 'components/deck/deck_link_icon_wrapper';
import { AppUI } from '@seedlang/state';
import { CubeIcon, JigsawIcon, NumbersOutlineIcon, PluralOutlineIcon } from '@seedlang/icons';
import { isPresent, pixify } from '@seedlang/utils';
import Modal from 'components/modal';
import VocabOptions from 'components/vocab/vocab_options';
import { capitalize } from 'lodash';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  padding: 0 10px 10px 10px;
  width: ${props => props.width};
  max-height: ${props => props.maxHeight};
  background: #FFF;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  justify-content: center;
`;

const DeckRow = styled.div`
  display: flex;
  max-width: ${props => props.maxWidth};
  overflow: auto;
  min-height: ${props => props.minHeight};
  align-items: center;
  justify-content: ${props => props.justifyContent};
  padding: ${props => props.padding};
  flex-wrap: wrap;
`;

const Aa = styled.div`
  font-size: 33px;
  color: #FFF;
`;

@observer
class VocabDecks extends React.Component {

  @computed get deck_width() {
    return AppUI.layout.viewportWidth < 430 ? (AppUI.layout.viewportWidth / 2) - 25 : 190;
  }

  @computed get widthGreaterThanDecks() {
    const deckLinksWidth = AppUI.targetLanguageId === 'DE' ? 1010 : 810;
    return AppUI.layout.leftColumnWidth > deckLinksWidth;
  }

  @computed get mobileHeight() {
    if (isPresent(this.props.searchQuery)) {
      return AppUI.layout.mobileContentHeight - 165;
    }
    return AppUI.layout.mobileContentHeight - 220;
  }

  render() {
    return (
      <Wrapper
        width='100%'
        maxHeight={AppUI.layout.isMobile ? pixify(this.mobileHeight) : null}
      >
        {
          AppUI.showVocabModal &&
            <Modal
              onCloseModal={() => AppUI.set('showVocabModal', null)}
              top={AppUI.layout.isMobile ? '60px' : '100px'}
              width={pixify(AppUI.layout.defaultModalWidth)}
              gradient
              title={`${capitalize(AppUI.showVocabModal)} Deck Options`}
            >
              <VocabOptions
                onSetFilter={this.props.onSetFilter}
                filterSettings={this.props.filterSettings}
                type={AppUI.showVocabModal}
                title={this.props.titles[AppUI.showVocabModal]}
                filters={this.props.filters}
                selectedWordIds={this.props.selectedWordIds}
              />
            </Modal>
        }
        <DeckRow
          justifyContent='center'
          minHeight={AppUI.layout.isMobile ? this.mobileHeight : null}
          maxWidth={AppUI.layout.viewportWidth > 4 * (this.deck_width + 10) && AppUI.targetLanguageId === 'DE' ? '90%' : '100%'} // to avoid orphan trainer on the second row (bc DE has 5 trainers)
          padding='5px'
        >
          <DeckLink
            disabled={this.props.vocabDeckDisabled}
            title={this.props.titles['vocab']}
            borderColor={this.props.vocabDeckDisabled ? '#777' : Theme.blue}
            width={this.deck_width}
            marginRight='10px'
            onClick={() => this.props.onClick('vocab')}
            progressCurrent={this.props.progress['vocab']['current']}
            progressTotal={this.props.progress['vocab']['total']}
            progressLabel='Words Seen'
          >
            <DeckLinkIconWrapper
              showLockIcon={this.props.vocabDeckDisabled}
              disabled={this.props.vocabDeckDisabled}
              iconWidth='30px'
              width={this.deck_width - 10}
              widthOffsets={[10, 60, 100]}
              backgroundColors={['#00b8db', '#00c8e4', '#7bdef0']}
            >
              <Aa>
                Aa
              </Aa>
            </DeckLinkIconWrapper>
          </DeckLink>
          <DeckLink
            showLockIcon={this.props.conjugationDeckDisabled}
            disabled={this.props.conjugationDeckDisabled}
            title={this.props.titles['conjugation']}
            borderColor={this.props.conjugationDeckDisabled ? '#777' : Theme.red}
            width={this.deck_width}
            onClick={() => this.props.onClick('conjugation')}
            progressCurrent={this.props.progress['conjugation']['current']}
            progressTotal={this.props.progress['conjugation']['total']}
            progressLabel='Conjugation Cards Seen'
            marginRight='10px'
          >
            <DeckLinkIconWrapper
              showLockIcon={this.props.conjugationDeckDisabled}
              disabled={this.props.conjugationDeckDisabled}
              iconWidth='43px'
              width={this.deck_width - 10}
              widthOffsets={[10, 60, 100]}
              backgroundColors={[Theme.red, '#ff7575', '#fd9594']}
              iconMarginTop='-3px'
            >
              <JigsawIcon />
            </DeckLinkIconWrapper>
          </DeckLink>
          <DeckLink
            showLockIcon={this.props.genderDeckDisabled}
            disabled={this.props.genderDeckDisabled}
            title={this.props.titles['gender']}
            borderColor={this.props.genderDeckDisabled ? '#777' : Theme.green}
            width={this.deck_width}
            marginRight='10px'
            onClick={() => this.props.onClick('gender')}
            progressCurrent={this.props.progress['gender']['current']}
            progressTotal={this.props.progress['gender']['total']}
            progressLabel='Gender Cards Seen'
          >
            <DeckLinkIconWrapper
              showLockIcon={this.props.genderDeckDisabled}
              disabled={this.props.genderDeckDisabled}
              iconWidth='40px'
              width={this.deck_width - 10}
              widthOffsets={[10, 60, 100]}
              backgroundColors={[Theme.green, '#21ca74', '#27e082']}
              iconMarginTop='-3px'
            >
              <CubeIcon />
            </DeckLinkIconWrapper>
          </DeckLink>
          {
            (AppUI.targetLanguageId === 'DE' || !AppUI.targetLanguageId) &&
              <DeckLink
                showLockIcon={this.props.pluralDeckDisabled}
                disabled={this.props.pluralDeckDisabled}
                title={this.props.titles['plural']}
                borderColor={this.props.pluralDeckDisabled ? '#777' : Theme.purple}
                width={this.deck_width}
                marginRight='10px'
                onClick={() => this.props.onClick('plural')}
                progressCurrent={this.props.progress['plural']['current']}
                progressTotal={this.props.progress['plural']['total']}
                progressLabel='Plural Cards Seen'
              >
                <DeckLinkIconWrapper
                  showLockIcon={this.props.pluralDeckDisabled}
                  disabled={this.props.pluralDeckDisabled}
                  iconWidth='45px'
                  width={this.deck_width - 10}
                  widthOffsets={[10, 60, 100]}
                  backgroundColors={[Theme.purple, '#ae88ff', '#c3a6ff']}
                  iconMarginTop='-3px'
                >
                  <PluralOutlineIcon />
                </DeckLinkIconWrapper>
              </DeckLink>
          }
          <DeckLink
            showLockIcon={this.props.numberDeckDisabled}
            disabled={this.props.numberDeckDisabled}
            title={this.props.titles['number']}
            borderColor={this.props.numberDeckDisabled ? '#777' : Theme.orange}
            width={this.deck_width}
            marginRight='10px'
            onClick={() => this.props.onClick('number')}
            progressCurrent={this.props.progress['number']['current']}
            progressTotal={this.props.progress['number']['total']}
            progressLabel='Number Cards Seen'
          >
            <DeckLinkIconWrapper
              showLockIcon={this.props.numberDeckDisabled}
              disabled={this.props.numberDeckDisabled}
              iconWidth='45px'
              width={this.deck_width - 10}
              widthOffsets={[10, 60, 100]}
              backgroundColors={[Theme.orange, 'rgb(250 161 94)', 'rgb(251 179 107)']}
              iconMarginTop='-3px'
            >
              <NumbersOutlineIcon />
            </DeckLinkIconWrapper>
          </DeckLink>
        </DeckRow>
      </Wrapper>
    );
  }
}

export default VocabDecksWrapper(VocabDecks);
