"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = getYoutubeEmbedUrl;
var _is_present = _interopRequireDefault(require("./is_present"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { "default": e }; }
function getYoutubeEmbedUrl(youtubeUrl) {
  var youtubeVideoId;
  if ((0, _is_present["default"])(youtubeUrl) && youtubeUrl.match('watch')) {
    var regexp = /watch\?v=(...........)/g;
    var match = regexp.exec(youtubeUrl);
    if (match) {
      youtubeVideoId = match[1];
    }
  }
  if ((0, _is_present["default"])(youtubeUrl) && youtubeUrl.match('watch') && (0, _is_present["default"])(youtubeVideoId)) {
    return "https://www.youtube.com/embed/".concat(youtubeVideoId);
  } else {
    return youtubeUrl;
  }
  return null;
}