import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { NavigationItemStore } from '@seedlang/stores';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import NavigationItemPathWarning from './navigation_item_path_warning';

const CreateButton = styled.button`
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

@observer
class NavigationItemCreate extends React.Component {
  @observable name = '';
  @observable path = '';

  onClick() {
    const data = Object.assign({
      name: this.refs.name.value,
      route_name: this.refs.route_name.value,
      path: this.refs.path.value,
      site_id: this.props.siteId,
    }, this.props.data);

    NavigationItemStore.create({ data: data }, this.props.afterCreate());
    this.refs.name.value = '';
    this.refs.route_name.value = '';
    this.refs.path.value = '';
  }

  componentDidMount() {
    AppUI.pageStore.getIndex();
  }

  render() {
    return (
      <div className='nav-item-create'>
        <div className='row'>
          <div className='col-xs-2'>
            <input
              placeholder='Add a name'
              ref='name'
              value={this.name}
              onChange={e => this.name = e.target.value}
            />
          </div>
          <div className='col-xs-2'>
            <input
              placeholder='Add a path'
              ref='path'
              value={this.path}
              onChange={e => this.path = e.target.value}
            />
          </div>
          <div className='col-xs-2'>
            <CreateButton
              onClick={this.onClick.bind(this)}
              className='button-primary'
              disabled={this.name === '' || this.path === ''}
            >
              Create
            </CreateButton>
          </div>
        </div>
        {
          this.path &&
            <NavigationItemPathWarning
              groupId={this.props.groupId}
              path={this.path}
            />
        }
      </div>
    );
  }
}

export default NavigationItemCreate;
