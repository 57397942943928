import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import InPlaceText from 'components/form/in_place_text';
import { CampaignTypeFilteredUserStore, CampaignTypeStore } from '@seedlang/stores';
import CampaignIndex from 'pages/builder/campaigns/campaign_index';
import { Link } from 'react-router';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import autobind from 'autobind-decorator';
import CampaignTypeFilterIndex from 'pages/builder/campaign_type_filters/campaign_type_filter_index';
import UserIndex from 'pages/builder/users/simple_user_index';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';
import InfoTooltip from 'components/info_tooltip';
import Text from 'components/text';
import BackEndExplainer from 'pages/creator/back_end_explainer';

@observer
class CampaignTypeEdit extends React.Component {
  @observable showSendToFilteredUsersMessage = false;
  @observable usersCount = 0;
  @observable filteredUsersCount = 0;
  @observable submitting;

  constructor(props) {
    super(props);
    this.onChange();
  }

  @computed get pageName() {
    return AppUI.siteIsDefault ? 'Campaign Types' : 'Automated Emails';
  }

  @autobind onChange() {
    this.CampaignType();
    this.loadFilteredUsers();
  }

  @autobind CampaignType() {
    CampaignTypeStore.getShow({ids: {campaignTypeId: this.props.params.campaignTypeId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    CampaignTypeStore.setShowData(resp);
  }

  @autobind onSendToFilteredUsers() {
    this.submitting = true;
    CampaignTypeStore.sendToFilteredUsers({ids: {campaignTypeId: this.props.params.campaignTypeId}}, this.afterSendToFilteredUsers);
  }

  @autobind loadFilteredUsers() {
    CampaignTypeFilteredUserStore.filteredUsersCount({ids: {campaignTypeId: this.props.params.campaignTypeId}}, this.afterGetFilteredUsersCount);
    CampaignTypeFilteredUserStore.getIndex({ids: {campaignTypeId: this.props.params.campaignTypeId}});
  }

  @autobind afterGetFilteredUsersCount(resp) {
    this.filteredUsersCount = resp['filteredUsersCount'];
    this.usersCount = resp['usersCount'];
  }

  @autobind afterSendToFilteredUsers() {
    this.submitting = false;
    this.showSendToFilteredUsersMessage = true;
    this.loadFilteredUsers();
    setTimeout(() => this.showSendToFilteredUsersMessage = false, 3000);
  }

  render() {
    if (CampaignTypeStore.hasShowData) {
      return (
        <div className='mailing-type-edit'>
          <div className='breadcrumbs-wrapper'>
            <div className='breadcrumbs'>
              <Link
                to={{name: this.props.whiteLabeled ? 'creator.campaign_types.index' : 'builder.campaign_types.index', params: {groupId: this.props.params.groupId}}}
              >
                {this.pageName}
              </Link>
              <i className='fa fa-angle-double-right' />
              <div className='current'>
                {CampaignTypeStore.showData.name}
              </div>
            </div>
          </div>
          <BackEndExplainer margin='10px 0'>This a mailing campaign type. It must contain campaigns to send emails.</BackEndExplainer>
          <div className='row'>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Name</legend>
                <InPlaceText
                  id={CampaignTypeStore.showData.id}
                  defaultValue={CampaignTypeStore.showData.name}
                  field='name'
                  model='campaign_types'
                />
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Email Trigger</legend>
                {
                  CampaignTypeStore.showData.slug !== 'one-time-mailing' &&
                    <InPlaceSelect
                      id={CampaignTypeStore.showData.id}
                      value={CampaignTypeStore.showData.slug}
                      field='slug'
                      model='campaign_types'
                      includeBlank
                      options={[['registration', 'New Subscription'], ['subscription-cancellation', 'Subscription Cancellation'], ['migration-complete', 'Payment Migration Complete'], ['payment-problems', 'Payment Problems'], ['video-notification', 'New Videos'], ['podcast-episode-notification', 'New Podcast Episodes'], ['one-time-mailing', 'Manual Emails']]}
                      afterChange={this.onChange}
                    />
                }
                { CampaignTypeStore.showData.slug === 'one-time-mailing' && <Text fontSize={15}>Manual Emails</Text> }
              </fieldset>
            </div>
            <div className='col-xs-3'>
              <fieldset>
                <legend>Min Mailing Delay (hours)<InfoTooltip>Emails from this campaign will be spaced out<br/> by at least this number of hours for a given user.</InfoTooltip></legend>
                <InPlaceText
                  id={CampaignTypeStore.showData.id}
                  defaultValue={CampaignTypeStore.showData.minimumMailingInterval}
                  field='minimum_mailing_interval'
                  model='campaign_types'
                />
              </fieldset>
            </div>
            {
              false &&
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Maximum # of Emails</legend>
                    <InPlaceText
                      id={CampaignTypeStore.showData.id}
                      defaultValue={CampaignTypeStore.showData.maximumEmailsToSend}
                      field='maximum_emails_to_send'
                      model='campaign_types'
                    />
                  </fieldset>
                </div>
            }
            <div className='col-xs-3'>
              <fieldset>
                <legend>Settings</legend>
                {
                  AppUI.siteIsDefault &&
                    <InPlaceCheckbox
                      icon='check'
                      model='campaign_types'
                      field='mobile_notification'
                      id={CampaignTypeStore.showData.id}
                      value={CampaignTypeStore.showData.mobileNotification}
                    >
                      Mobile
                    </InPlaceCheckbox>
                }
                {
                  AppUI.siteIsDefault &&
                    <InPlaceCheckbox
                      icon='check'
                      model='campaign_types'
                      field='unsubscribable'
                      id={CampaignTypeStore.showData.id}
                      value={CampaignTypeStore.showData.unsubscribable}
                    >
                      Unsubscribable
                      <InfoTooltip position='left'>
                        <div>When enabled, users can opt out of receiving emails from this campaign type in their notification settings.</div>
                      </InfoTooltip>
                    </InPlaceCheckbox>
                }
                {
                  AppUI.siteIsDefault &&
                    <InPlaceCheckbox
                      icon='check'
                      model='campaign_types'
                      field='one_time_sending'
                      id={CampaignTypeStore.showData.id}
                      value={CampaignTypeStore.showData.oneTimeSending}
                    >
                      One Time Sending
                      <InfoTooltip position='left'>
                        <div>When enabled, users will only receive this email once.</div>
                      </InfoTooltip>
                    </InPlaceCheckbox>
                }
                {
                  AppUI.siteIsDefault &&
                    <InPlaceCheckbox
                      icon='check'
                      model='campaign_types'
                      field='personal'
                      id={CampaignTypeStore.showData.id}
                      value={CampaignTypeStore.showData.personal}
                    >
                      Personal
                    </InPlaceCheckbox>
                }
                {
                  !CampaignTypeStore.showData.mobileNotification &&
                    <div>
                      <InPlaceCheckbox
                        icon='check'
                        id={CampaignTypeStore.showData.id}
                        value={CampaignTypeStore.showData.greetByName}
                        model='campaign_types'
                        field='greet_by_name'
                      >
                        {`Greet by Name: ${CampaignTypeStore.showData.greeting(AppUI.user)}`}
                        <InfoTooltip position='left'>
                          <div>You can enter a customized greeting below. It will be used as the first line of the email.</div>
                          <div>Use %name% as a placeholder and check the preview above.</div>
                        </InfoTooltip>
                      </InPlaceCheckbox>
                    </div>
                }
                {
                  !CampaignTypeStore.showData.mobileNotification &&
                    <InPlaceText
                      id={CampaignTypeStore.showData.id}
                      defaultValue={CampaignTypeStore.showData.customGreeting}
                      model='campaign_types'
                      field='custom_greeting'
                      placeholder='Custom greeting'
                      afterChange={this.onChange}
                      show
                    />
                }
              </fieldset>
            </div>
            {
              AppUI.siteIsDefault &&
                <div className='col-xs-3'>
                  <fieldset>
                    <legend>Priority (higher sent first)</legend>
                    <InPlaceText
                      afterChange={this.onChange}
                      id={CampaignTypeStore.showData.id}
                      defaultValue={CampaignTypeStore.showData.priority}
                      field='priority'
                      model='campaign_types'
                    />
                  </fieldset>
                </div>
            }
          </div>
          {
            AppUI.siteIsDefault &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Email Addresses (this will override filters)</legend>
                    <InPlaceText
                      afterChange={this.onChange}
                      id={CampaignTypeStore.showData.id}
                      defaultValue={CampaignTypeStore.showData.emails}
                      field='emails'
                      model='campaign_types'
                    />
                  </fieldset>
                </div>
              </div>
          }
          {
            AppUI.siteIsDefault &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Filters</legend>
                    <CampaignTypeFilterIndex
                      campaignType={CampaignTypeStore.showData}
                      onChange={this.onChange}
                    />
                  </fieldset>
                </div>
              </div>
          }
          <div className='row'>
            <div className='col-xs-12'>
              <fieldset>
                <legend>
                  Campaigns
                  <InfoTooltip position='right'>
                    <div>A campaign type can contain multiple campaigns,</div>
                    <div>each with its own subject, content, and settings.</div>
                  </InfoTooltip>
                </legend>
                <CampaignIndex
                  oneTimeSending={false}
                  groupId={this.props.params.groupId}
                  whiteLabeled={this.props.whiteLabeled}
                  campaignTypeId={this.props.params.campaignTypeId}
                  mobileNotification={CampaignTypeStore.showData.mobileNotification}
                />
              </fieldset>
            </div>
          </div>
          {
            (AppUI.siteIsDefault || AppUI.user.admin) &&
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>{`Filtered Users (${this.filteredUsersCount}/${this.usersCount})`} <InfoTooltip>Users that could potentially receive these emails (if triggered).</InfoTooltip></legend>
                    {
                      this.showSendToFilteredUsersMessage &&
                        <div className='alert'>
                          Messages for this campaign have been sent.
                        </div>
                    }
                    {
                      !this.submitting && AppUI.siteIsDefault &&
                        <button
                          type='button'
                          className='gray-button'
                          onClick={this.onSendToFilteredUsers}
                          style={{ marginBottom: 10 }}
                        >
                          <i className='fa fa-plus' />
                          Send "Current" Campaigns to
                        </button>
                    }
                    {
                      this.submitting &&
                      <Spinner />
                    }
                    <UserIndex
                      whiteLabel={!AppUI.siteIsDefault}
                      groupId={this.props.params.groupId}
                      store={CampaignTypeFilteredUserStore}
                      ids={{campaignTypeId: this.props.params.campaignTypeId}}
                    />
                  </fieldset>
                </div>
              </div>
          }
        </div>
      );
    }
    return <span />;
  }
}

export default ErrorBoundary(CampaignTypeEdit);
