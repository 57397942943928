import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';

const Wrapper = styled.div`
    padding: 10px;
`;

@observer
class AppListing extends React.Component {

  render() {
    return (
      <Wrapper></Wrapper>
    );
  }
}

export default AppListing;