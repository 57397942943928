import styled from '@emotion/styled';
import { Theme } from '@seedlang/constants';
import { AfterAuth, AppUI , LoadingState } from '@seedlang/state';
import { UserSubscriptionChangeOptionStore } from '@seedlang/stores';
import { absoluteCenter, fixedCenter, flexCenterColumn, smallText } from '@seedlang/style_mixins';
import { isPresent } from '@seedlang/utils';
import autobind from 'autobind-decorator';
import Button from 'components/button/button';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Spinner from 'components/spinner';
import WhitelabelLogo from 'components/whitelabel_logo';
import { last, noop } from 'lodash';
import { computed, observable, when } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import CheckoutRegistrationStep from './checkout_registration_step';
import CheckoutWhitelabelConfirmationStep from './checkout_whitelabel_confirmation_step';
import AuthenticateConfirm from 'components/authenticate/authenticate_confirm';
import Checkbox from 'components/checkbox';

const Wrapper = styled.div`
  ${flexCenterColumn()}
  ${smallText()}
  ${fixedCenter()}
  background: ${props => props.background};
  z-index: ${Theme.z.foreground};
`;

const Title = styled.div`
  display: block;
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  text-align: center;
  color: #000;
  margin: ${props => props.margin};
  word-break: break-word;
`;

const Content = styled.div`
  &.centered-vertically {
    ${flexCenterColumn()}
  }
  ${absoluteCenter()}
  background: ${props => props.background};
  border-radius: ${props => props.isMobile ? 0 : '20px'};
  max-width: ${props => props.isMobile ? '100%' : '500px'};
  max-height: ${props => props.maxHeight};
  height: ${props => props.isMobile ? '100%' : 'auto'};
  margin: ${props => props.isMobile ? '0' : '60px auto'};
  width: 100%;
  z-index: 4;
  padding: 20px;
  overflow: auto;
`;

const InnerContent = styled.div`
  ${flexCenterColumn()}
  background: #FFF;
  border-radius: 10px;
  margin-top: 0px;
`;

const PaymentWrapper = styled.div`
  ${flexCenterColumn()}
  width: 100%;
  padding: 0 20px;
  background: #FFF;
  border-radius: 5px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0px 0 20px 0;
  .img {
    width: 190px;
  }
`;

const RedirectText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  width: 400px;
  background: #dfdfdf;
  padding: 20px;
  border-radius: 10px;
  overflow-wrap: anywhere;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Terms = styled.div`
  margin: 10px 0 5px 0;
  text-align: ${props => props.textAlign};
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: start;
  font-size: 14px;
  line-height: 1.5;
`;

const timeoutPromise = ms => new Promise((_resolve, reject) => {
  return setTimeout(() => reject(new Error('Timeout')), ms);
});

const withTimeout = (ms, fn) => {
  return Promise.race([fn(), timeoutPromise(ms)]);
};

@observer
class CheckoutWhitelabel extends React.Component {
  @observable startedCheckoutAsUnregistered = false;
  @observable show = 'checkout';
  @observable registrationComplete = false;
  @observable isDataLoaded = false;
  @observable activeSubscription = null;
  @observable isConfirmed = false;
  @observable cancellationPolicyAccepted = false;
  @observable contractExecutionAccepted = false;

  redirectLoadingState = new LoadingState();

  constructor(props) {
    super(props);

    if (document.referrer.match('checkout.stripe.com') && AppUI.getCookieValue('redirected-to-stripe')) {
      // checkout.stripe.com stays as the document.referrer even when it's not the last click
      // need to protect against having a loop between memberships.index and this page
      // which would make it impossible to get to the checkout page
      AppUI.setCookieValue('redirected-to-stripe', false);
      AppUI.routeStore.routeToNamed('memberships.index');
    } else {
      if (!AppUI.currentlyViewedMembership || AppUI.currentlyViewedMembership.id !== this.props.params.membershipId) {
        AppUI.membershipStore.getShow({ ids: { membershipId: this.props.params.membershipId } });
      }
      AppUI.createEvent('checkout - payment form', { membership_id: this.props.params.membershipId });

      this.loadUser();
    }

    this.contentRef = React.createRef();
    this.innerRef = React.createRef();
  }

  adjustLayout = () => {
    const contentEl = this.contentRef.current;
    const innerEl = this.innerRef.current;

    const contentMargin = this.props.isMobile ? 0 : 60;
    const contentPadding = 20;
    const extraHeight = contentMargin * 2 + contentPadding * 2;

    if (innerEl.clientHeight + extraHeight > contentEl.clientHeight) {
      contentEl.classList.remove('centered-vertically');
    }
    else {
      contentEl.classList.add('centered-vertically');
    }
  }

  componentDidMount() {
    this.adjustLayout();
    window.addEventListener('resize', this.adjustLayout);
  }

  componentDidUpdate() {
    this.adjustLayout();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.adjustLayout);
    this.disposer && this.disposer();
    AppUI.set('currentlyViewedMembership', null);
  }

  // ---------- Data ----------

  @computed get membership() {
    return AppUI.currentlyViewedMembership || AppUI.membershipStore.showData;
  }

  async loadUser() {
    this.show = 'loading';

    await when(() => AppUI.user);

    if (AppUI.user.guest || AppUI.user.signedOut) {
      console.log('Checkout - User is not signed in, showing registration form -', AppUI.user.id, AppUI.user.email, AppUI.user.guest, AppUI.user.signedOut);
      this.show = 'registration';
      return;
    }

    this.gotoConfirmationStep();
  }

  @computed get subscriptionChangeOption() {
    if (!UserSubscriptionChangeOptionStore.hasIndexData) return null;
    return UserSubscriptionChangeOptionStore.indexData.find(item => item.membershipId === this.props.params.membershipId);
  }

  // ---------- Registration ----------

  @autobind afterRegistration() {
    AppUI.loadUser();
    this.registrationComplete = true;
    this.gotoConfirmationStep();
  }

  // ---------- Email Confirmation ----------

  @autobind onConfirmationRequired(unconfirmedDetails) {
    console.log('Checkout - User needs to confirm their email address before proceeding to payment');
    this.show = 'confirm_email';

    // Resume checkout after the user clicks the link in the email
    AfterAuth.set({ path: window.location.pathname, label: 'Continue to Payment' });
  }

  // ---------- Membership Change Confirmation ----------

  @autobind async gotoConfirmationStep() {
    this.show = 'loading';
    console.log('Checkout - Loading data to see if the user needs to confirm the membership change');

    // We need to know the current subscription of the user so that we can display a confirmation message
    if (!AppUI.userSubscriptionStore.hasIndexData) {
      await AppUI.userSubscriptionStore.getIndexPromise({});
    }

    this.activeSubscription = AppUI.user.currentGroupMembershipType && last(AppUI.userSubscriptionStore.indexData.filter(item => item.membership.membershipTypeId === AppUI.user.currentGroupMembershipType.id && item.isActive));

    if (this.activeSubscription) {
      await UserSubscriptionChangeOptionStore.getIndexPromise();
    }

    if (this.isConfirmationNeeded && !this.isConfirmed) {
      console.log('Checkout - The user needs to confirm the membership change');
      this.show = 'confirm_membership_change';
      return;
    }

    this.gotoCheckoutStep();
  }

  @computed get isConfirmationNeeded() {
    return !!this.activeSubscription;
  }

  @autobind onConfirmMembershipChange() {
    this.isConfirmed = true;
    this.gotoCheckoutStep();
  }

  // ---------- Redirecting to payment provider ----------

  @autobind gotoCheckoutStep() {
    if (this.show === 'checkout') return;

    this.show = 'checkout';

    if (!this.setViewedCheckoutCount) {
      this.setViewedCheckoutCount = true;
      AppUI.user.set('viewedCheckoutCount', AppUI.user.viewedCheckoutCount + 1);
      AppUI.authUserStore.update({ data: { viewedCheckoutCount: AppUI.user.viewedCheckoutCount } }, noop);
    }

    // this.forwardToStripe();
  }

  @autobind async forwardToStripe() {
    const defaultErrorMessage = 'We are sorry, there seems to be an issue. Please contact us if you are unable to resolve the issue.';

    this.redirectLoadingState.started();

    try {
      AppUI.setCookieValue('redirected-to-stripe', true);

      await withTimeout(5000,
        async () => {
          const resp = await AppUI.stripeStore.createSessionPromise({
            data: {
              quantity: 1,
              membership_id: this.props.params.membershipId,
            },
          });

          window.location = resp.url;
        },
      );

      // this.redirectLoadingState never succeeds because we expect to be redirected
    } catch (error) {
      console.error('Error forwarding to Stripe:', error);
      this.redirectLoadingState.failed(`${defaultErrorMessage}${error.message ? ` (${error.message})` : ''}`);
    }
  }

  // ---------- Presentation ----------

  @computed get contentMaxHeight() {
    if (AppUI.layout.isMobile) {
      return '100%';
    }
    return AppUI.showPatreonButton ? '720px' : '680px';
  }

  @computed get backgroundColor() {
    return isPresent(AppUI.site?.footerBackgroundColor) ? AppUI.site?.footerBackgroundColor : '#044067';
  }

  render() {
    return (
      <Wrapper
        className='Checkout__Wrapper'
        background={this.backgroundColor}
        isMobile={AppUI.layout.isMobile}
      >
        {
          this.membership &&
          <Content
            className='Checkout__Content'
            background='#FFF'
            maxHeight={this.contentMaxHeight}
            isMobile={AppUI.layout.isMobile}
            ref={this.contentRef}
          >
            <InnerContent className='Checkount__InnerContent' ref={this.innerRef}>
              {
                isPresent(AppUI.site?.image) &&
                <LogoWrapper>
                  <WhitelabelLogo
                    image={AppUI.site.image}
                  />
                </LogoWrapper>
              }

              {
                this.show !== 'loading' && this.show !== 'confirm_email' &&
                <Title
                  margin='0 0 10px 0'
                >
                  {this.subscriptionChangeOption ? 'Changing your Subscription' : 'Become a Member'}
                </Title>
              }

              {
                this.show === 'loading' &&
                <Spinner background={AppUI.site.accentColor || Theme.blue} />
              }

              {
                this.show === 'registration' &&
                  <CheckoutRegistrationStep afterRegistration={this.afterRegistration} onConfirmationRequired={this.onConfirmationRequired} />
              }

              {
                this.show === 'confirm_email' &&
                  <AuthenticateConfirm />
              }

              {
                this.show === 'confirm_membership_change' &&
                <CheckoutWhitelabelConfirmationStep
                  subscriptionChangeOption={this.subscriptionChangeOption}
                  onConfirm={this.onConfirmMembershipChange}
                />
              }
              {
                this.show === 'checkout' &&
                  <PaymentWrapper>
                    {
                      this.redirectLoadingState.errorMessage &&
                        <RedirectText>
                          {this.redirectLoadingState.errorMessage}

                          <Button
                            margin='10px 0 0 0'
                            onClick={() => AppUI.routeStore.routeToNamed('contact')}
                          >
                            Contact Us
                          </Button>
                        </RedirectText>
                    }
                    {
                      !this.redirectLoadingState.errorMessage &&
                        <Section>
                          <Terms>
                            <Checkbox
                              value={this.cancellationPolicyAccepted}
                              onClick={() => this.cancellationPolicyAccepted = !this.cancellationPolicyAccepted}
                              selectedBackground={AppUI.site.accentColor}
                            />
                            <span>I have read and agree to the <a href={AppUI.site.termsOfServiceUrl} className='underline' target='_blank'>cancellation policy</a>.</span>
                          </Terms>

                          <Button
                            margin='10px 0 0 0'
                            onClick={() => this.forwardToStripe()}
                            disabled={!this.cancellationPolicyAccepted}
                          >
                            Continue to Payment
                          </Button>
                        </Section>
                    }
                  </PaymentWrapper>
                }

              </InnerContent>
            </Content>
        }

      </Wrapper>
    );
  }

}

export default ErrorBoundary(CheckoutWhitelabel);
