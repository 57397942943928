import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import CardWrapper from 'components/layout/card_wrapper';
import CardContent from 'components/layout/card_content';
import NextButton from 'components/button/next_button';
import CardButtons from 'components/card/card_buttons';
import DeckGrammar from 'components/deck/deck_grammar';
import { DeckUI } from '@seedlang/state';

const InnerContent = styled.div`
  background: #FFF;
  border-radius: 10px;
  overflow: auto;
  height: 100%;
`;

@observer
class DeckGrammarCard extends React.Component {

  render() {
    return (
      <CardWrapper>
        <CardContent
          scroll
        >
          <InnerContent>
            <DeckGrammar/>
          </InnerContent>
        </CardContent>
        <CardButtons>
          <NextButton
            disabled={!this.props.inView}
            card={this.props.card}
            cardState={this.props.cardState}
            userCard={this.props.userCard}
            shortcut='1'
            submitOnEnter
            onClick={() => DeckUI.advanceCard(this.props.card, this.props.cardState, this.props.userCard)}
          />
        </CardButtons>
      </CardWrapper>
    );
  }
}

export default DeckGrammarCard;
