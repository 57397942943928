import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Paginator from 'components/paginator';
import ExerciseProgress from 'components/exercise/exercise_progress';
import { flexCenter } from '@seedlang/style_mixins';
import Text from 'components/text';
import { ExerciseUI , AppUI } from '@seedlang/state';
import Spinner from 'components/spinner';
import WorksheetFilter from 'components/worksheet/worksheet_filter';
import autobind from 'autobind-decorator';
import { computed, observable } from 'mobx';
import { isBlank, isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  flex: 1;
  border-radius: 10px;
  overflow: hidden;
`;

const Row = styled.div`
  ${flexCenter()}
  background: ${props => props.header ? '#CCC' : '#FFF'};
  cursor: ${props => props.header ? 'default' : 'pointer'};
  padding: 10px;
  border-radius: ${props => props.borderRadius};
  &:hover {
    background: ${props => props.header ? '#CCC' : 'whitesmoke'};
  }
`;

const Worksheet = styled.div`
  flex: 1;
`;

const Score = styled.div`
  width: 80px;
`;

@observer
class WorksheetList extends React.Component {
  @observable searchQuery = '';

  constructor(props) {
    super(props);
    ExerciseUI.worksheetStore.getIndex({queryStrings: {include_count: true}});
    AppUI.createEvent('worksheets - view worksheet list');
  }

  @autobind onSearchQueryChange(val) {
    this.searchQuery = val || '';
    ExerciseUI.worksheetStore.setFilter('name', `~${this.searchQuery}~`, {refresh: true});
  }

  render() {
    return (
      <Wrapper>
        <WorksheetFilter
          onSearchQueryChange={this.onSearchQueryChange}
          searchQuery={this.searchQuery}
          routeName='Worksheet'
          hideSort
          mobilePadding='0 5px'
        />
        {
          ExerciseUI.worksheetStore.requestCounter > 0 &&
            <Spinner
              marginTop={20}
            />
        }
        {
          ExerciseUI.worksheetStore.requestCounter === 0 && !ExerciseUI.worksheetStore.hasIndexData && isPresent(this.searchQuery) &&
            <Text center margin='20px 0' small color='#333'>Sorry, we don't have any post that match this search.</Text>
        }
        {
          ExerciseUI.worksheetStore.hasIndexData &&
            <Row
              header
              borderRadius={ExerciseUI.layout.isMobile ? '0' : '10px 10px 0 0'}
            >
              <Worksheet>
                <Text
                  bold
                  fontSize='14px'
                >
                  Worksheet
                </Text>
              </Worksheet>
              <Score>
                <Text
                  bold
                  fontSize='14px'
                >
                  My Score
                </Text>
              </Score>
            </Row>
        }
          {
            ExerciseUI.worksheetStore.indexData.map((item, index) => {
              return (
                <Row
                  key={item.id}
                  onClick={() => ExerciseUI.routeToFirstTab(item)}
                  borderRadius={index === ExerciseUI.worksheetStore.indexData.length - 1 && !ExerciseUI.layout.isMobile ? '0 0 10px 10px' : '0'}
                >
                  <Worksheet>
                    <Text
                      bold
                    >
                      {item.nameWithPublished}
                    </Text>
                    <Text
                      italic
                      fontSize='14px'
                    >
                      {item.publishedAtDate}
                    </Text>
                  </Worksheet>
                  <Score>
                    <ExerciseProgress
                      largeVersion
                      current={item.correctAnswersCount}
                      total={item.exerciseEntriesCount}
                    />
                  </Score>
                </Row>
              );
            })
          }
          <Paginator
            withSpinner
            onClickScrollToTop
            totalCount={ExerciseUI.worksheetStore.hasIndexData ? ExerciseUI.worksheetStore.indexData[0].filteredWorksheetsCount : null}
            store={ExerciseUI.worksheetStore}
            queryStrings={{include_count: true}}
            ids={{groupId: this.props.params.groupId}}
            margin='10px 0 20px 0'
          />
      </Wrapper>
    );
  }
}

export default ErrorBoundary(WorksheetList);
