import React from 'react';
import { observer } from 'mobx-react';
import { observable, computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import TagCategoryCreate from 'pages/creator/tags/tag_category_create';
import TagCreate from 'pages/creator/tags/tag_create';
import autobind from 'autobind-decorator';
import { TagCategoryStore , TagStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import DeleteButton from 'components/button/delete_button';
import { flexCenter } from '@seedlang/style_mixins';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import InPlaceSelect from 'components/form/in_place_select';
import { AppUI } from '@seedlang/state';
import ReactTooltip from 'react-tooltip';
import InfoTooltip from 'components/info_tooltip';
import { startCase } from 'lodash';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import Modal from 'components/modal';
import { isPresent, isBlank } from '@seedlang/utils';
import TagCategorySettings from './tag_category_settings';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

@observer
class TagCategoryIndicators extends React.Component {

  render() {
    return (
      <Wrapper>
        <InfoTooltip
          icon='file-text'
          margin='0 20px'
          position='left'
          color={!this.props.tagCategory.isWorksheetTag ? '#939090' : ''}
        >
          {
            this.props.tagCategory.isWorksheetTag ?
              <div>Posts can be tagged with this category.</div> :
              <div>Posts cannot be tagged with this category.</div>
          }
        </InfoTooltip>
        <InfoTooltip
          icon='tasks'
          position='left'
          color={!this.props.tagCategory.isExerciseTag ? '#939090' : ''}
        >
          {
            this.props.tagCategory.isExerciseTag ?
              <div>Exercises can be tagged with this category.</div> :
              <div>Exercises cannot be tagged with this category.</div>
          }
        </InfoTooltip>
        <InfoTooltip
          icon='eye'
          margin='0 20px'
          position='left'
          color={!this.props.tagCategory.displayOnVideoWorksheets && !this.props.tagCategory.displayOnPodcastWorksheets ? '#939090' : ''}
        >
          {
            (this.props.tagCategory.displayOnVideoWorksheets || this.props.tagCategory.displayOnPodcastWorksheets) ?
              <div>{`This tag is displayed on the ${this.props.tagCategory.displayOnVideoWorksheets ? 'videos' : ''}${this.props.tagCategory.displayOnVideoWorksheets && this.props.tagCategory.displayOnPodcastWorksheets ? ' and ' : ''}${this.props.tagCategory.displayOnPodcastWorksheets ? 'podcasts' : ''} page${this.props.tagCategory.displayOnVideoWorksheets && this.props.tagCategory.displayOnPodcastWorksheets ? 's' : ''}.`}</div> :
              <div>This tag is not displayed on any post grid.</div>
          }
          </InfoTooltip>
          <InfoTooltip
            icon='filter'
            position='left'
            color={!this.props.tagCategory.displayOnVideoFilters && !this.props.tagCategory.displayOnPodcastFilters ? '#939090' : ''}
          >
            {
              (this.props.tagCategory.displayOnVideoFilters || this.props.tagCategory.displayOnPodcastFilters || this.props.tagCategory.displayOnHomeFilters) ?
                <div>{`This tag is used for filtering on the ${this.props.tagCategory.displayOnHomeFilters ? 'home' : ''}${this.props.tagCategory.displayOnHomeFilters && this.props.tagCategory.displayOnVideoFilters ? ' & ' : ''}${this.props.tagCategory.displayOnVideoFilters ? 'videos' : ''}${this.props.tagCategory.displayOnVideoFilters && this.props.tagCategory.displayOnPodcastFilters ? ' & ' : ''}${this.props.tagCategory.displayOnPodcastFilters ? 'podcasts' : ''} page${this.props.tagCategory.displayOnVideoFilters && this.props.tagCategory.displayOnPodcastFilters ? 's' : ''}.`}</div> :
                <div>This tag is not used for filtering on any page.</div>
            }
          </InfoTooltip>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(TagCategoryIndicators);
