import React from 'react';
import { observer } from 'mobx-react';
import RatingIndex from 'pages/builder/ratings/rating_index';
import BackEndExplainer from 'pages/creator/back_end_explainer';

@observer
class ExerciseRatingsIndex extends React.Component {

  render() {
    return (
      <div>
        <BackEndExplainer margin='10px 0'>
          <div>These are the ratings and comments left by users after they have completed an exercise.</div>
        </BackEndExplainer>
        <RatingIndex
          simple
          showExercise
          groupId={this.props.params.groupId}
          defaultFilters={{group_id: this.props.params.groupId, comment: true}}
        />
      </div>
    );
  }
}

export default ExerciseRatingsIndex;
