import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import Text from 'components/text';
import { FilterTagsWrapper } from '@seedlang/hoc';
import { Theme } from '@seedlang/constants/src';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: ${props => props.width};
  padding: ${props => props.padding};
  justify-content: ${props => props.justifyContent};
  height: 50px;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ScrollWrapper = styled.div`
  display: flex;
  height: 30px;
`;

const Tag = styled.div`
  background: ${props => props.background};
  padding: 5px;
  padding-right: ${props => props.paddingRight};
  border-radius: 5px;
  margin: 3px;
  position: relative;
  border: ${props => props.border};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: max-content;
`;

const TimesIconWrapper = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  color: ${props => props.color};
  i {
    font-size: 12px;
  }
`;

@observer
class FilterTags extends React.Component {

  render() {
    return (
      <Wrapper
        width={this.props.width}
        padding={this.props.padding}
        justifyContent={this.props.inDeckOptions ? 'safe center' : ''}
      >
        <ScrollWrapper>
          {
            this.props.filteredFilterSettings.map(item => {
              return (
                <Tag
                  onClick={() => this.props.onClick(item)}
                  key={item.label}
                  paddingRight={this.props.readOnly ? '5px' : '20px'}
                  background={this.props.backgroundColor}
                  border={this.props.inDeckOptions ? '' : '1px solid #CCC'}
                >
                  <Text
                    color={this.props.color}
                    bold
                    fontSize={12}
                    lineHeight={15}
                  >
                    {item.tagDisplay}
                  </Text>
                  {
                    !this.props.readOnly &&
                      <TimesIconWrapper
                        color={this.props.color}
                      >
                        <i className='fa fa-times' />
                      </TimesIconWrapper>
                  }
                </Tag>
              );
            })
          }
        </ScrollWrapper>
      </Wrapper>
    );
  }
}

export default FilterTagsWrapper(FilterTags);
