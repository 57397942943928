import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import styled from '@emotion/styled';
import moment from 'moment';
import AnalyticsListRow from 'components/analytics/analytics_list_row';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #FFF;
  font-size: 13px;
  border-bottom: 1px solid #CCC;
  border-right: 1px solid #CCC;
  position: relative;
`;

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
`;

@observer
class AnalyticsListItem extends React.Component {

  @computed get formattedDate() {
    return moment(this.props.date).format('MMM D');
  }

  render() {
    return (
      <Wrapper>
        {
          this.props.languageIds.map((languageId, index) => {
            const item = this.props.items.find(item => item.languageId === languageId);
            if (isPresent(item)) {
              return (
                <AnalyticsListRow
                  {...this.props}
                  id={item.id}
                  key={index}
                  count={item}
                  languageId={languageId}
                  date={index === 0 ? this.formattedDate : null}
                  firstRow={index === 0}
                  timePeriod={this.props.timePeriod}
                >
                </AnalyticsListRow>
              );
            }
          })
        }
      </Wrapper>
    );
  }
}

export default AnalyticsListItem;
