import React from 'react';
import { observer } from 'mobx-react';
import { RouteStore, SiteStore } from '@seedlang/stores';
import { observable } from 'mobx';

@observer
class SiteCreate extends React.Component {
  @observable error = false;

  onClick() {
    const data = Object.assign({
      name: this.refs.name.value,
    }, this.props.data);

    SiteStore.create({ data: data }, this.afterCreate.bind(this));
    this.refs.name.value = '';
  }

  afterCreate(resp) {
    if (this.props.routeToAfterCreate) {
      RouteStore.routeToNamed(this.props.routeToAfterCreate, Object.assign({}, {siteId: resp.siteId}, this.props.routeParams));
    } else if (resp.siteId && this.props.routeToName) {
      RouteStore.routeToNamed(this.props.routeToName, Object.assign({siteId: resp.siteId}, this.props.routeIds));
    } else if (resp.siteId) {
      RouteStore.routeTo(`/builder/site/${resp.siteId}`);
    } else {
      this.error = true;
    }
  }

  render() {
    return (
      <div className='site-create'>
        {
          this.error &&
          <div className='row'>
            <div className='col-xs-12'>
              <div className='alert'>Sorry, there was an error</div>
            </div>
          </div>
        }
        <input
          placeholder='Add an site name'
          ref='name'
        />
        <div>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
            style={{
              marginTop: 10,
            }}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default SiteCreate;
