import React from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { Link } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { Theme } from '@seedlang/constants';
import { isPresent } from '@seedlang/utils';

const Wrapper = styled.div`
  border-radius: ${props => props.borderRadius};
  padding: ${props => props.padding};
  background: ${props => props.selected ? (props.bgHover || '#14c7f1') : (props.background || '#1499b7')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 10px;
  font-weight: 400;
  color: white;
  font-size: 14px;
  height: 40px;
  position: relative;
  &:hover {
    background: ${props => props.bgHover ? props.bgHover : '#14c7f1'}; ;
  }
  .classroom-icon {
    svg {
      margin-top: -5px;
      width: 24px;
    }
  }
`;

const IconWrapper = styled.div`
  svg {
    fill: #FFF;
    width: ${props => props.width};
    margin: ${props => props.margin};
  }
`;

const TextWrapper = styled.div`
  margin-left: ${props => props.marginLeft};
  font-size: ${props => props.fontSize};
`;

const Label = styled.div`
  background: ${props => props.background || Theme.green};
  color: white;
  font-size: 11px;
  line-height: 13px;
  padding: 1px 5px 2px 5px;
  position: absolute;
  left: -3px;
  top: -3px;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.8);
`;

@observer
class HeaderLink extends React.Component {

  @computed get padding() {
    if (this.props.padding) {
      return this.props.padding;
    }
    return this.props.showText ? '8px 10px' : '8px 15px';
  }

  static defaultProps = {
    iconWidth: '20px',
    textMarginLeft: '5px',
  }

  render() {
    return (
      <Link
        to={this.props.to}
      >
        <Wrapper
          borderRadius={this.props.showText ? '40px' : '5px'}
          padding={this.padding}
          selected={this.props.selected}
          background={this.props.background}
          bgHover={this.props.backgroundHover}
          data-tip
          data-for={this.props.text}
        >
          {
            this.props.showNew &&
              <Label>
                New
              </Label>
          }
          {
            this.props.showUnpublished &&
              <Label
                background={Theme.red}
              >
                UNP
              </Label>
          }
          <IconWrapper
            width={this.props.iconWidth}
            margin={this.props.iconMargin}
          >
            {this.props.icon}
          </IconWrapper>
          {
            this.props.showText &&
              <TextWrapper
                fontSize={this.props.fontSize}
                marginLeft={isPresent(this.props.icon) ? this.props.textMarginLeft : 0}
              >
                {this.props.text}
              </TextWrapper>
          }
          {
            !this.props.showText &&
              <ReactTooltip
                place='bottom'
                type='dark'
                effect='solid'
                id={this.props.text}
                class='custom-tooltip'
              >
                {this.props.text}
              </ReactTooltip>
          }
        </Wrapper>
      </Link>
    );
  }
}

export default ErrorBoundary(HeaderLink);
