import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import Spinner from 'components/spinner';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

@observer
class RssImport extends React.Component {
  @observable showSpinner = false;
  @observable rssUrl = '';
  @observable membershipTypeIds = [];
  @observable isSuccess = false;
  @observable errorMessage = null;

  constructor(props) {
    super(props);
    AppUI.membershipTypeStore.getIndex({});
  }

  toggleMembershipType(id) {
    if (this.isMembershipTypeChecked(id)) {
      this.uncheckMembershipType(id);
    } else {
      this.checkMembershipType(id);
    }
  }

  @action checkMembershipType(id) {
    this.membershipTypeIds.push(id);
  }

  @action uncheckMembershipType(id) {
    this.membershipTypeIds = this.membershipTypeIds.filter(i => i !== id);
  }

  isMembershipTypeChecked(id) {
    return this.membershipTypeIds.includes(id);
  }

  @autobind onImportRssFeed() {
    this.showSpinner = true;

    AppUI.podcastStore.importRssFeed({
      ids: {
        podcastId: this.props.podcastId,
      },
      data: {
        rssUrl: this.rssUrl,
        membershipTypeIds: this.membershipTypeIds,
      },
      failureCallback: error => {
        this.errorMessage = error.message;
        this.showSpinner = false;
      },
    }, this.afterImport);
  }

  @autobind afterImport() {
    this.showSpinner = false;
    this.isSuccess = true;
    this.props.afterImport();
  }

  @autobind onClear() {
    this.rssUrl = '';
    this.membershipTypeIds = [];
    this.isSuccess = false;
    this.errorMessage = null;
  }

  render() {
    return (
      <div className='col-xs-12'>
        <fieldset>
          <legend>Import RSS Feed</legend>

          {
            this.showSpinner ? (
              <Spinner />
            ) :
            this.isSuccess ? (
              <>
                <p>The RSS feeds is being imported in the background.</p>
                <button className='button-primary' onClick={this.onClear}>Clear</button>
              </>
            ) :
            this.errorMessage ? (
              <>
                <p>{this.errorMessage}</p>
                <button className='button-primary' onClick={this.onClear}>Clear</button>
              </>
            ) :
            (
              <>
                <input
                  type='url'
                  placeholder='https://example.com/feed.rss'
                  value={this.rssUrl}
                  onChange={e => this.rssUrl = e.target.value}
                />

                {
                  AppUI.membershipTypeStore.indexData.map(item => {
                    return (
                      <div className='row' key={item.id}>
                        <InPlaceCheckbox
                          value={this.isMembershipTypeChecked(item.id)}
                          icon='check'
                          onChange={() => this.toggleMembershipType(item.id)}
                        >
                          {item.name}
                        </InPlaceCheckbox>
                      </div>
                    );
                  })
                }

                <button className='button-primary' onClick={this.onImportRssFeed}>Import</button>
              </>
            )
          }

        </fieldset>
      </div>
    );
  }
}

export default ErrorBoundary(RssImport);