import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import Spinner from 'components/spinner';
import { formatNumber } from '@seedlang/utils';
import Text from 'components/text';
import FilterOptionsItem from 'components/filter_options_item';
import FilterTags from 'components/filter_tags';

const Wrapper = styled.div`
`;

const Content = styled.div`
  padding: 10px 10px 50px 10px;
`;

const Footer = styled.div`
  background: #FFF;
  height: 40px;
  display: flex;
  align-items: center;
  flex-direction: row;
  position: fixed;
  width: 100%;
  bottom: 0;
  border-top: 1px solid #CCC;
  padding-right: 20px;
`;

const SpinnerWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 5px;
`;

const Column = styled.div`
  position: relative;
  height: 30px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justifyContent};
  padding-right: ${props => props.paddingRight};
`;

const Filters = styled.div`
  flex: 1;
  overflow: auto;
`;

@observer
class FilterOptions extends React.Component {

  render() {
    return (
      <Wrapper>
        <Content>
          {
            this.props.hasActiveFilter &&
              <FilterTags
                width='100%'
                filterSettings={this.props.filterSettings}
                onSetFilter={this.props.onSetFilter}
              />
          }
          {
            this.props.filterSettings.filter(item => !item.hide).length > 0 &&
              <Filters>
                {
                  this.props.filterSettings.filter(item => !item.hide).map(item => {
                    return (
                      <FilterOptionsItem
                        key={item.filterKey}
                        onSetFilter={this.props.onSetFilter}
                        {...item}
                      />
                    );
                  })
                }
              </Filters>
          }
        </Content>
        <Footer>
          <Column />
          <Column
            justifyContent='flex-end'
          >
            {
              this.props.filteredItemsCount === null &&
                <SpinnerWrapper>
                  <Spinner
                    margin='0'
                    width={80}
                    wrapperWidth={30}
                    wrapperHeight={30}
                  />
                </SpinnerWrapper>
            }
            {
              this.props.filteredItemsCount !== null &&
                <Text
                  fontSize={12}
                  lineHeight={15}
                >
                  {`${formatNumber(this.props.filteredItemsCount)} ${this.props.filteredItemsType}s`}
                </Text>
            }
          </Column>
        </Footer>
      </Wrapper>
    );
  }
}

export default ErrorBoundary(FilterOptions);
