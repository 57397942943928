import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { AppUI } from '@seedlang/state';
import styled from '@emotion/styled';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
`;

const Section = styled.div`
  background: ${props => props.isMobile ? '#FFF' : null};
  box-shadow: ${props => props.isMobile ? '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)' : null};
  margin: ${props => props.isMobile ? '0 10px 80px 10px' : '0 0 80px 0'};
  display: flex;
  padding: 10px;
  border-radius: 10px;
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 22px;
  font-weight: bold;
  text-align: ${props => props.textAlign};
`;

const ImageWrapper = styled.div`
  order: ${props => props.order};
  width: 300px;
  height: 300px;
  background: #FFF;
  border-radius: 10px;
  box-shadow: 0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07);
  overflow: hidden;
  img, video {
    width: 100%;
    height: 100%;
  }
`;

const LeftArrowDescription = styled.div`
  order: 2;
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  text-align: left;
  line-height: 22px;
  box-shadow: ${props => props.isMobile ? null : '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)'};
  max-width: 500px;
  margin-left: ${props => props.isMobile ? null : '40px'};
  &:after {
    ${props => props.showArrow ? LeftArrow : null}
  }
`;

const RightArrowDescription = styled.div`
  order: 2;
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 10px;
  flex: 1;
  line-height: 22px;
  text-align: ${props => props.isMobile ? 'left' : 'right'};
  box-shadow: ${props => props.isMobile ? null : '0 15px 35px rgba(50,50,93,.1), 0 5px 15px rgba(0,0,0,.07)'};
  max-width: 500px;
  margin-right: ${props => props.isMobile ? null : '40px'};
  &:after {
    ${props => props.showArrow ? RightArrow : null}
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;
  box-sizing: border-box;
  justify-content: ${props => props.justifyContent};
  flex-direction: ${props => props.isMobile ? 'column' : 'row'};
  align-items: ${props => props.isMobile ? 'center' : null};
`;

const LeftArrow = `
  right: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-right-color: #ffffff;
  border-width: 30px;
  margin-top: -30px;
`;

const RightArrow = `
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  left: 100%;
  border-left-color: #ffffff;
  border-width: 30px;
  margin-top: -30px;
`;

@observer
class FlashcardDescription extends React.Component {
  @observable scrollPosition = 0;

  render() {
    return (
      <Wrapper>
        <Section
          isMobile={AppUI.layout.isMobile}
        >
          <ContentWrapper
            isMobile={AppUI.layout.isMobile}
            justifyContent={AppUI.layout.isMobile ? 'center' : 'flex-start'}
          >
            <ImageWrapper>
              <img src='/images/teachers2.jpg' alt='' />
            </ImageWrapper>
            <LeftArrowDescription
              isMobile={AppUI.layout.isMobile}
              showArrow={!AppUI.layout.isMobile}
            >
              <Title
                textAlign={AppUI.layout.isMobile ? 'center' : null}
              >
                Real people saying real things
              </Title>
              <p>In Seedlang every sentence is a video. In fact, every word is a video. This means teaching a language requires tens of thousands of recorded videos. Why go through all of that effort? Because we believe there is no shortcut: <b>building fluency requires listening to natives talk and doing your best to talk like them</b>.</p>
            </LeftArrowDescription>
          </ContentWrapper>
        </Section>
        <Section
          isMobile={AppUI.layout.isMobile}
        >
          <ContentWrapper
            isMobile={AppUI.layout.isMobile}
            justifyContent={AppUI.layout.isMobile ? 'center' : 'flex-end'}
          >
            <ImageWrapper
              order={AppUI.layout.isMobile ? '1' : '3'}
            >
              <video
                muted
                autoPlay
                playsInline
                loop
                id='video'
                width='300'
                height='300'
                preload='auto'
                src='/images/mic2.mp4'
              />
            </ImageWrapper>
            <RightArrowDescription
              isMobile={AppUI.layout.isMobile}
              showArrow={!AppUI.layout.isMobile}
            >
              <Title
                textAlign={AppUI.layout.isMobile ? 'center' : null}
              >
                Active learning through speaking
              </Title>
              <p>Recording audio of your speech and comparing it with that of native speakers allows you to improve your pronunciation. As you practice these improvements, <b>your muscle memory for the language strengthens and speaking becomes effortless</b>.</p>
            </RightArrowDescription>
          </ContentWrapper>
        </Section>
        <Section
          isMobile={AppUI.layout.isMobile}
        >
          <ContentWrapper
            isMobile={AppUI.layout.isMobile}
            justifyContent={AppUI.layout.isMobile ? 'center' : 'flex-start'}
          >
            <ImageWrapper>
              <video
                muted
                autoPlay
                playsInline
                loop
                id='video'
                width='300'
                height='300'
                preload='auto'
                src='/images/grammar_walkthrough.mp4'
              />
            </ImageWrapper>
            <LeftArrowDescription
              isMobile={AppUI.layout.isMobile}
              showArrow={!AppUI.layout.isMobile}
            >
              <Title
                textAlign={AppUI.layout.isMobile ? 'center' : null}
              >
                Grammar at your fingertips
              </Title>
              <p>We are most receptive to learning grammar after we've made a mistake. So, if you make a mistake with a word, simply click it to display detailed grammar information.  <b>It's like having a grammar book always ready to tell you what you need to know, when you need to know it</b>.</p>
            </LeftArrowDescription>
          </ContentWrapper>
        </Section>
      </Wrapper>
    );
  }
}

export default FlashcardDescription;
