import React from 'react';
import { observer } from 'mobx-react';
import { autorun } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { AppUI } from '@seedlang/state';
import { getCookieValue, setCookieValue } from '@seedlang/utils';
import { noop } from 'lodash';

const Wrapper = styled.div`

`;

@observer
class RedirectToHome extends React.Component {

  constructor(props) {
    super(props);
    let slug;
    const urlParams = new URLSearchParams(window.location.search);
    if (window.location.href.includes('/easygerman')) {
      slug = 'easy-german';
    } else if (window.location.href.includes('/promotion/')) {
      slug = window.location.href.split('/').includes('promotion') ? window.location.href.split('/')[window.location.href.split('/').indexOf('promotion') + 1] : null;
    }
    if (urlParams.has('pr')) {
      slug = urlParams.get('pr');
    }
    if (slug) {
      setCookieValue('promotion-code', slug);
      if (AppUI.userIsSignedIn) {
        AppUI.authUserStore.addEasyGermanUserGroup({}, noop);
      } else {
        const promotionCookie = `promotion_${slug}_tracked`;
        if (!getCookieValue(promotionCookie)) {
          setCookieValue(promotionCookie, true);
          AppUI.promotionStore.incrementVisitorsCount({ids: {slug: slug}}, noop);
        }
      }
    }
    this.disposer = autorun(() => {
      if (AppUI.authUserStore.hasShowData) {
        AppUI.routeToHome();
      }
    });
  }

  componentWillUnmount() {
    this.disposer && this.disposer();
  }

  render() {
    return (
      <Wrapper />
    );
  }
}

export default ErrorBoundary(RedirectToHome);
