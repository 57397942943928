import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import InPlaceSelect from 'components/form/in_place_select';
import InPlaceText from 'components/form/in_place_text';
import DeleteButton from 'components/button/delete_button';
import autobind from 'autobind-decorator';
import { CardRuleInterjectionStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';

@observer
class CardRuleInterjectionEdit extends React.Component {
  @observable interjectionId;

  @autobind onCreate() {
    CardRuleInterjectionStore.create({data: {interjection_id: this.interjectionId, card_rule_id: this.props.cardRuleId}}, this.props.afterChange);
  }

  @autobind onDestroy(id) {
    CardRuleInterjectionStore.destroy({ids: {cardRuleInterjectionId: id}}, this.props.afterChange);
  }

  render() {
    return (
      <div className='deck-rule-interjection-edit'>
        {
          isPresent(this.props.cardRuleInterjections) &&
            <fieldset>
              <legend>Interjections</legend>
              <table className='table-wrapper'>
                <tbody>
                  {
                    this.props.cardRuleInterjections.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <InPlaceText
                              model='card_rule_interjection'
                              field='position'
                              defaultValue={item.position}
                              id={item.id}
                              type='number'
                              step='1'
                              afterChange={this.props.afterChange}
                            />
                          </td>
                          <td>
                            <InPlaceSelect
                              model='card_rule_interjection'
                              field='interjection_id'
                              id={item.id}
                              value={item.interjection.id}
                              options={this.props.interjections.map(item => [item.id, item.name])}
                              afterChange={this.props.afterChange}
                              includeBlank
                            />
                          </td>
                          <td>
                            <DeleteButton
                              onConfirm={() => this.onDestroy(item.id)}
                              className='fa-block right'
                              message='Delete this interjection?'
                            />
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </fieldset>
        }
        <fieldset>
          <legend>Create an Interjection</legend>
          <div className='row'>
            <div className='col-xs-12'>
              <InPlaceSelect
                value={this.interjectionId}
                options={this.props.interjections.map(item => [item.id, item.name])}
                onChange={id => this.interjectionId = id}
                includeBlank
              />
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <button
                style={{marginTop: 10}}
                className='button-primary'
                onClick={this.onCreate}
              >
                Submit
              </button>
            </div>
          </div>
        </fieldset>
      </div>
    );
  }
}

export default CardRuleInterjectionEdit;
