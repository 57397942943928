import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { flexCenter } from '@seedlang/style_mixins';
import { PaginatorWrapper } from '@seedlang/hoc';
import { isPresent } from '@seedlang/utils/src';
import Spinner from 'components/spinner';
import { AppUI } from '@seedlang/state';
import { Theme } from '@seedlang/constants';

const Wrapper = styled.div`
  margin: ${props => props.margin};
  font-size: ${props => props.compact ? 13 : 14}px;
  display: flex;
  align-items: center;
`;

const Button = styled.div`
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  color: ${props => props.disabled ? '#CCC' : '#000'};
  background: ${props => props.disabled ? '#F1F1F1' : '#FFF'};
  padding: ${props => props.compact ? '0 10px' : '5px 10px'};
  ${flexCenter()}
  height: 30px;
  border: 1px solid #CCC;
  margin: 0 3px 0 0;
  border-radius: 3px;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    background: ${props => props.disabled ? '#F1F1F1' : '#FAFAFA'};
  }
`;

const PageNum = styled.div`
  ${flexCenter()}
  margin: 0 0 0 5px;
  padding: 5px 0;
  input {
    margin-left: 5px;
    display: inline-block;
    width: 30px;
    height: 28px;
    padding: 0 3px;
  }
`;

@observer
class Paginator extends React.Component {

  static defaultProps = {
    margin: '10px 0 0 0',
  }

  render() {
    return (
      <Wrapper
        compact={this.props.compact}
        margin={this.props.margin}
      >
        <Button
          compact={this.props.compact}
          disabled={this.props.isFirstPage}
          onClick={
            () => {
              if (!this.props.isFirstPage) {
                this.props.onPageIncremented(-1);
              }
            }
          }
        >
          Prev
        </Button>
        <Button
          compact={this.props.compact}
          disabled={this.props.isLastPage}
          onClick={
            () => {
              if (!this.props.isLastPage) {
                this.props.onPageIncremented(1);
              }
            }
          }
        >
          Next
        </Button>
        <PageNum>
          <span>Page</span>
          {
            (!this.props.totalCount || this.props.hideInput) &&
              <span style={{margin: '0 4px'}}>{this.props.derivedPage}</span>
          }
          {
            this.props.totalCount && !this.props.hideInput &&
              <input
                ref='pageNumber'
                onChange={() => this.props.onSetPage(this.refs.pageNumber.value)}
                value={this.props.page}
              />
          }
          {
            this.props.totalCount &&
              <span style={{margin: this.props.hideInput ? '0 4px 0 0' : '0 4px'}}>of {this.props.pagesCount}</span>
          }
          {
            !this.props.hideInput && this.props.selectedPageIsNotSubmitted && this.props.totalCount &&
              <Button
                onClick={this.props.onSubmitPage}
              >
                Submit
              </Button>
          }
          {
            this.props.withSpinner && this.props.showSpinner &&
              <Spinner background={AppUI.site.accentColor || Theme.blue} margin='0' height='14px' />
          }
        </PageNum>
      </Wrapper>
    );
  }
}

export default PaginatorWrapper(Paginator);
