import {Constants} from '@seedlang/constants';

export default function displayTarget(targetText, languageId = 'DE') {
    if (targetText && languageId === 'FR') {
        return targetText.replace(/'\s/g, '\'')
          .replace(' - ', '-')
          .replace(/«/g, `«${Constants.SPECIAL_PUNCTUATION.NARROW_NO_BREAK_SPACE}`)
          .replace('»', `${Constants.SPECIAL_PUNCTUATION.NARROW_NO_BREAK_SPACE}»`);
    }
    return targetText;
}
