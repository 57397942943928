import React from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { QRCodeSVG } from 'qrcode.react';
import Button from 'components/button/button';
import Copy from 'components/copy';
import { AppUI } from '@seedlang/state';
import autobind from 'autobind-decorator';
import { isBlank, isPresent } from '@seedlang/utils';
import Tab from 'components/tab';
import TextDisplay from 'components/text_display';
import Text from 'components/text';
import NoAccessButton from 'components/worksheet/no_access_button';
import { Constants } from '@seedlang/constants';

const Wrapper = styled.div`
  h3 {
    font-size: 18px!important;
    line-height: 18px!important;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 30px;
  }
`;

const InputRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const AppIcon = styled.div`
  padding: 10px;
  cursor: pointer;
  background: ${props => props.background};
  border-radius: 10px;
  &:hover {
    background: #DDD;
  }
`;

const Name = styled.div`
  font-size: 11px;
  width: 50px;
  line-height: 13px;
  text-align: center;
  margin-top: 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Section = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const InputWrapper = styled.div`
  input {
    max-width: 250px;
    border-radius: 5px;
    height: 40px;
  }
`;

const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  display: flex;
  width: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 0;
`;

const TabRow = styled.div`
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid #CCC;
  flex-wrap: wrap;
  gap: 4px;
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: min-content;
  padding: 0 4px;
`;

const TabImage = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  object-fit: cover;
  flex-shrink: 0;
`;

const Note = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-style: ${props => props.fontStyle ? props.fontStyle : 'italic'};
  margin-bottom: ${props => props.marginBottom};
  margin-top: ${props => props.marginTop};
`;

const APPS = [
  {
    slug: 'apple',
    name: 'Apple Podcasts',
    linkPrefix: 'podcast://',
  },
  {
    slug: 'overcast',
    name: 'Overcast',
    linkPrefix: 'overcast://x-callback-url/add?url=https://',
  },
  {
    slug: 'podcast_addict',
    name: 'Podcast Addict',
    linkPrefix: 'podcastaddict://',
  },
  {
    slug: 'pocket_casts',
    name: 'Pocket Casts',
    linkPrefix: 'pktc://subscribe/',
  },
  {
    slug: 'castro',
    name: 'Castro',
    linkPrefix: 'castros://subscribe/',
  },
  {
    slug: 'downcast',
    name: 'Downcast',
    linkPrefix: 'downcast://',
  },
  {
    slug: 'android',
    name: 'Android',
    linkPrefix: 'pcast://',
  },
  {
    slug: 'spotify',
    name: 'Spotify',
  },
  {
    slug: 'other_apps',
    name: 'Other Apps',
  },
];

@observer
class RssFeedIndex extends React.Component {
  @observable selectedApp = 'apple';
  @observable currentUserPodcastIndex = 0;

  componentDidMount() {
    if (this.props.selectedPodcastId && AppUI.userIsLoaded) {
      const podcastIndex = AppUI.user.userPodcasts.findIndex(
        podcast => podcast.podcast?.id === this.props.selectedPodcastId,
      );
      if (podcastIndex !== -1) {
        this.currentUserPodcastIndex = podcastIndex;
      }
    }
  }

  @computed get currentApp() {
    return APPS.find(item => item.slug === this.selectedApp);
  }

  @computed get currentUserPodcast() {
    if (AppUI.userIsLoaded && isPresent(AppUI.user.userPodcasts[0])) {
      return AppUI.user.userPodcasts[this.currentUserPodcastIndex];
    } return null;
  }

  @autobind currentAppUrl(rssFeedUrl) {
    const urlWithoutProtocol = rssFeedUrl.replace('https://', '').replace('http://', '');
    return `${this.currentApp.linkPrefix}${urlWithoutProtocol}`;
  }

  @autobind openInSelectedApp() {
    window.location.href = this.currentAppUrl(this.currentUserPodcast.rssFeedUrl);
  }

  @computed get message() {
    if (isPresent(AppUI.site?.podcastSettingsMessage)) {
      return AppUI.site.podcastSettingsMessage;
    } return Constants.PODCAST_SETTINGS_MESSAGE;
  }

  @autobind blockedUntilMigrated(userPodcast) {
    return AppUI.paymentMigrationEnabled && userPodcast.blockIfNotMigrated && AppUI.isPaymentMigrationStartable;
  }

  render() {
    return (
      <Wrapper>
        {
          isBlank(this.currentUserPodcast) &&
            <h3>You don't currently have access to any RSS feeds</h3>
        }
        {
          isPresent(this.currentUserPodcast) &&
            <div>
              <Note
                fontStyle='none'
              >
                <TextDisplay
                  markdown={this.message}
                />
              </Note>
              <TabWrapper>
                <TabRow>
                  {
                    AppUI.user.userPodcasts.map((item, index) => {
                      return (
                        <Tab
                          key={item.id}
                          onClick={() => this.currentUserPodcastIndex = index}
                          selected={this.currentUserPodcastIndex === index}
                          borderColor={AppUI.site.accentColor}
                        >
                          <TabContent>
                            {item.podcast?.podcastImage?.image && (
                              <TabImage
                                src={item.podcast.podcastImage.image.url}
                                alt={`Cover image for ${item.podcast.title}`}
                              />
                            )}
                            {item.podcast.title}
                          </TabContent>
                        </Tab>
                      );
                    })
                  }
                </TabRow>
              </TabWrapper>
              <Section>
                {
                  this.currentUserPodcast.podcast?.podcastImage?.image &&
                    <img
                      alt=''
                      width='200px'
                      style={{borderRadius: '20px'}}
                      src={this.currentUserPodcast.podcast.podcastImage.image.url}
                    />
                }
              </Section>
              {
                this.blockedUntilMigrated(this.currentUserPodcast) &&
                  <Section>
                    <NoAccessButton migrate />
                  </Section>
              }
              {
                !this.blockedUntilMigrated(this.currentUserPodcast) &&
                <>
                  <Section>
                  <h3>Select your Podcast App</h3>
                  <IconWrapper>
                    {
                      APPS.map(item => {
                        return (
                          <AppIcon
                            key={item['slug']}
                            onClick={() => this.selectedApp = item['slug']}
                            background={this.selectedApp === item['slug'] ? '#DDD' : '#FFF'}
                          >
                            <img src={`/images/${item['slug']}.svg`} width='50px' height='50px' alt=''
                                  style={{borderRadius: '10px'}}
                            />
                            <Name>
                              {item['name']}
                            </Name>
                          </AppIcon>
                        );
                      })
                    }
                  </IconWrapper>
                </Section>
                {
                  ['spotify', 'other_apps'].indexOf(this.selectedApp) === -1 &&
                    <Section>
                      <h3>Viewing this page on your phone?</h3>
                      <Note marginBottom='10px'>Just tap the button to subscribe</Note>
                      <Button
                        onClick={this.openInSelectedApp}
                        background={AppUI.site.accentColor}
                      >
                        {`Open in ${this.currentApp['name']}`}
                      </Button>
                    </Section>
                }
                {
                  ['spotify', 'other_apps'].indexOf(this.selectedApp) === -1 &&
                    <Section>
                      <h3>Viewing this page on your computer?</h3>
                      <Note marginBottom='10px'>Scan this QR code with your phone</Note>
                      <QRCodeSVG
                        value={this.currentAppUrl(this.currentUserPodcast.rssFeedUrl)}
                      />
                    </Section>
                }
                {
                  this.selectedApp === 'spotify' &&
                    <Section>
                      <Text>Unfortunately, it's not currently possible to subscribe to the members-only feed in Spotify.</Text>
                      <Text>We encourage you to try the apps listed here instead.</Text>
                    </Section>
                }
                {
                  this.selectedApp === 'other_apps' &&
                    <Section>
                      <h3>Your RSS feed URL</h3>
                      <Note fontStyle='none'>Most podcast apps have an option to subscribe to a podcast feed by URL.</Note>
                      <Note fontStyle='none'>You can copy this link and paste it into the appropriate field in your podcast app of choice.</Note>
                      <InputRow>
                        <InputWrapper>
                          <input value={this.currentUserPodcast.rssFeedUrl}/>
                        </InputWrapper>
                        <Copy
                          hideIcon
                          text={this.currentUserPodcast.rssFeedUrl}
                        >
                          <Button background={AppUI.site.accentColor}>Copy Link</Button>
                        </Copy>
                      </InputRow>
                      <Note marginTop='20px'>Note that apps not listed on this page may not support chapters and chapter images.</Note>
                    </Section>
                }
              </>
            }
            </div>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(RssFeedIndex);
