import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import { isBlank, isPresent } from '@seedlang/utils';
import { flexCenter } from '@seedlang/style_mixins';
import VocabProgressBar from 'components/vocab/vocab_progress_bar';
import Frequency from 'components/vocab/frequency';

const Wrapper = styled.div`
  position: ${props => props.position};
  display: flex;
  flex-direction: ${props => props.flexDirection};
  justify-content: center;
  gap: 10px;
  top: 10px;
  right: 10px;
  min-width: 70px;
`;

const Label = styled.div`
  background: #FFF;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid #CCC;
  flex: 1;
`;

const Title = styled.div`
  ${flexCenter()}
  background: #CCC;
  font-size: 11px;
  font-weight: bold;
  padding: 0 3px;
`;

const Value = styled.div`
  ${flexCenter()}
  font-size: 16px;
  font-weight: ${props => props.bold ? 'bold' : null};
  padding: 5px 3px;
`;

@observer
class VocabLabels extends React.Component {

  static defaultProps = {
    position: 'absolute',
    flexDirection: 'column',
  }

  render() {
    return (
      <Wrapper
        position={this.props.position}
        flexDirection={this.props.flexDirection}
      >
        {
          this.props.word.wordType &&
          <Label>
            <Title>Type</Title>
            <Value
              bold
            >
              {this.props.word.wordType.shortName}
            </Value>
          </Label>
        }
        {
          this.props.word.level &&
          <Label>
            <Title>Level</Title>
            <Value
              bold
            >
              {this.props.word.level.abbreviation}
            </Value>
          </Label>
        }
        {
          this.props.word.frequencyRanking && (isBlank(this.props.word.consecutiveGoodScores) || isBlank(this.props.word.viewsCount)) &&
            <Label>
              <Title>Frequency</Title>
              <Value>
                <Frequency
                  word={this.props.word}
                />
              </Value>
            </Label>
        }
        {
          isPresent(this.props.word.consecutiveGoodScores) && isPresent(this.props.word.viewsCount) &&
            <Label>
              <Title>Progress</Title>
              <Value>
                <VocabProgressBar
                  word={this.props.word}
                />
              </Value>
            </Label>
        }
      </Wrapper>
    );
  }
}

export default VocabLabels;
