import React from 'react';
import { observer } from 'mobx-react';
import styled from '@emotion/styled';
import TextDisplay from 'components/text_display';
import {DeckUI} from '@seedlang/state';
import {isPresent} from '@seedlang/utils/src';
import Text from 'components/text';

const Wrapper = styled.div`
  text-align: left;
  font-size: 16px;
  line-height: 22px;
  padding: 20px 20px 50px 20px;
  .close-icon {
    width: ${props => props.minimized ? '22' : '30'}px;
  }
  h3 {
    border-bottom: 1px solid gray;
    font-size: 18px;
    width: 100%;
  }
  table {
    .header-row {
      td {
        background: #777;
        color: white;
        font-size: 12px;
        font-weight: bold;
        padding: 5px 10px;
      }
    }
  }
  .info-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 4px;
    }
  }
`;

@observer
class TenseDescription extends React.Component {

  render() {
    return (
      <Wrapper>
        <Text heading='3' style={{borderBottom: '1px solid gray', marginTop: '10px'}}>{DeckUI.currentCard.concept.displayNameLine1WithCase}</Text>
        {
          DeckUI.currentCard.concept.displayNameLine2 &&
            <Text italic fontSize='14px'>{DeckUI.currentCard.concept.displayNameLine2}</Text>
        }
        {
          isPresent(DeckUI.currentCard.concept?.longDescription) &&
            <TextDisplay
              htmlText={DeckUI.currentCard.concept?.longDescription}
            />
        }
      </Wrapper>
    );
  }
}

export default TenseDescription;
