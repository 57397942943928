import React from 'react';
import { observer } from 'mobx-react';
import Paginator from 'components/paginator';
import { WordCardRowExpandedWrapper } from '@seedlang/hoc';
import styled from '@emotion/styled';
import Text from 'components/text';
import TranslationWithSource from 'components/translation_with_source';
import TextDisplay from 'components/text_display';
import { isPresent, isBlank } from '@seedlang/utils';
import WordCardTitle from 'components/word_card/word_card_title';
import Spinner from 'components/spinner';

const Wrapper = styled.div`
  text-align: left;
  margin-bottom: 10px;
  padding-bottom: 10px;
  h3 {
    font-size: 16px;
  }
`;

const SentencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

@observer
class WordCardRowExpanded extends React.Component {

  render() {
    return (
      <Wrapper>
        {
          this.props.title &&
            <Text
              bold
              margin='10px 0 0 0'
            >
              {this.props.title || 'Related Sentences'}
            </Text>
        }
        {
          isPresent(this.props.htmlDescription) &&
            <TextDisplay
              htmlText={this.props.htmlDescription}
            />
        }
        {
          isBlank(this.props.htmlDescription) && isPresent(this.props.markdownDescription) &&
            <TextDisplay
              markdown={this.props.markdownDescription}
            />
        }
        <SentencesWrapper>
          {
            this.props.word && this.props.word.sentencesCount === 0 &&
              <Text>
                No Sentences Found.
              </Text>
          }
          {
            this.props.displaySentences && this.props.sentences.map((item, index) => {
              return (
                <TranslationWithSource
                  background={index % 2 ? '#FFF' : 'whitesmoke'}
                  key={item.id}
                  className='sentence'
                  translation={item}
                  fontSize='14px'
                  languageButtonWrapperWidth='25px'
                  languageButtonWrapperPadding='0 5px 0 0'
                  showSentenceBreakdown
                  onClickPlayVideo={this.props.onClickPlayVideo}
                  sentenceBreakdownProps={{
                    highlightWordId: this.props.word && this.props.word.id,
                    highlightConceptId: this.props.concept && this.props.concept.id,
                    hideWordAssociationHighlight: true,
                    disableOnClick: true,
                  }}
                  user={this.props.user}
                />
              );
            })
          }
          {
            this.props.showSpinner &&
            <Spinner />
          }
        </SentencesWrapper>
        {
          this.props.displaySentences && !this.props.willReceiveSentences && (this.props.store.page > 1 || this.props.store.indexData.length === this.props.store.limit) &&
            <Paginator
              compact
              store={this.props.store}
              ids={{
                wordId: this.props.word && this.props.word.id,
                conceptId: this.props.concept && this.props.concept.id,
              }}
            />
        }
      </Wrapper>
    );
  }
}

export default WordCardRowExpandedWrapper(WordCardRowExpanded);
