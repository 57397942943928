"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = displayTarget;
var _constants = require("@seedlang/constants");
function displayTarget(targetText) {
  var languageId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'DE';
  if (targetText && languageId === 'FR') {
    return targetText.replace(/'\s/g, '\'').replace(' - ', '-').replace(/«/g, "\xAB".concat(_constants.Constants.SPECIAL_PUNCTUATION.NARROW_NO_BREAK_SPACE)).replace('»', "".concat(_constants.Constants.SPECIAL_PUNCTUATION.NARROW_NO_BREAK_SPACE, "\xBB"));
  }
  return targetText;
}