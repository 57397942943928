import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import PieChart from 'components/visualization/pie_chart';
import { flexCenterColumn } from '@seedlang/style_mixins';
import { Theme } from '@seedlang/constants';
import autobind from 'autobind-decorator';
import { Motion, spring, presets } from 'react-motion';
import { AppUI } from '@seedlang/state';

const Wrapper = styled.div`
  border: 5px solid #00c1ee;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background: white;
  border-radius: 50%;
  ${flexCenterColumn()}
`;

@observer
class ResultsCardCenter extends React.Component {
  @observable scale = 0;

  componentDidMount() {
    if (this.props.inView && this.scale === 0) {
      this.setDelayedInitiateAnimation();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.scale === 0 && !prevProps.inView && this.props.inView) {
      this.setDelayedInitiateAnimation();
    }
  }

  @autobind setDelayedInitiateAnimation() {
    setTimeout(this.initiateAnimation, 100);
  }

  @autobind initiateAnimation() {
    this.scale = 1;
    if (this.props.percentageCorrect >= 50) {
      AppUI.soundEffectStore.play('horn');
    } else {
      AppUI.soundEffectStore.play('lostGame');
    }
  }

  render() {
    return (
      <Motion
        style={{
          scale: spring(this.scale, presets.wobbly),
        }}
      >
        {
          ({scale}) =>
            <Wrapper
              style={{
                transform: `scale(${scale})`,
              }}
            >
              <PieChart
                hideTooltip
                margin={0}
                fontSize='30px'
                lineHeight='34px'
                colors={[Theme.green, '#D8D8D8']}
                data={[
                  {y: this.props.correctCount},
                  {y: this.props.numCards - this.props.correctCount },
                ]}
                width={180}
                count={`${this.props.percentageCorrect}%`}
              />
            </Wrapper>
          }
      </Motion>
    );
  }
}

export default ErrorBoundary(ResultsCardCenter);
