import React from 'react';
import { observer } from 'mobx-react';
import { ForumStore } from '@seedlang/stores';
import { isPresent } from '@seedlang/utils';

@observer
class ForumCreate extends React.Component {

  onClick() {
    if (isPresent(this.refs.title.value)) {
      ForumStore.create(
        {
          data: {
            title: this.refs.title.value,
          },
        },
      );
      this.refs.title.value = '';
    }
  }

  render() {
    return (
      <div className='concept-category-create row'>
        <div className='col-xs-10'>
          <input
            placeholder='Add a title'
            ref='title'
          />
        </div>
        <div className='col-xs-2'>
          <button
            className='button-primary'
            onClick={this.onClick.bind(this)}
          >
            Create
          </button>
        </div>
      </div>
    );
  }
}

export default ForumCreate;
