import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import styled from '@emotion/styled';
import { SiteReleaseStore } from '@seedlang/stores';
import InPlaceText from 'components/form/in_place_text';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';

const Wrapper = styled.div`

`;

@observer
class SiteReleaseEdit extends React.Component {

  constructor(props) {
    super(props);
    this.getSiteRelease();
  }

  @autobind createForumThread() {
    SiteReleaseStore.createForumThread({ids: {siteReleaseId: this.props.params.siteReleaseId}}, this.getSiteRelease);
  }

  @autobind getSiteRelease() {
    SiteReleaseStore.getShow({ids: {siteReleaseId: this.props.params.siteReleaseId}}, this.afterGetShow);
  }

  @autobind afterGetShow(resp) {
    SiteReleaseStore.setShowData(resp);
  }

  render() {
    return (
      <Wrapper>
        {
          SiteReleaseStore.hasShowData &&
            <div>
              <div className='row'>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Version</legend>
                    <InPlaceText
                      id={SiteReleaseStore.showData.id}
                      defaultValue={SiteReleaseStore.showData.version}
                      model='site_releases'
                      field='version'
                    />
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Published</legend>
                    <InPlaceCheckbox
                      id={SiteReleaseStore.showData.id}
                      defaultValue={SiteReleaseStore.showData.published}
                      model='site_releases'
                      field='published'
                      icon='check'
                    />
                  </fieldset>
                </div>
                <div className='col-xs-4'>
                  <fieldset>
                    <legend>Title</legend>
                    <InPlaceText
                      id={SiteReleaseStore.showData.id}
                      defaultValue={SiteReleaseStore.showData.title}
                      model='site_releases'
                      field='title'
                    />
                  </fieldset>
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <fieldset>
                    <legend>Forum Thread</legend>
                    {
                      SiteReleaseStore.showData.forumThreadId &&
                        <Link
                          to={{name: 'forums.forum_threads.show', params: {forumId: '9332f9ec-35ac-453d-aab3-fec5d814e300', forumThreadId: SiteReleaseStore.showData.forumThreadId}}}
                        >
                          Go to Forum Thread
                        </Link>
                    }
                    {
                      !SiteReleaseStore.showData.forumThreadId &&
                        <button
                          className='gray-button'
                          onClick={this.createForumThread}
                        >
                          <i className='fa fa-plus' />
                          Create Forum Thread
                        </button>
                    }
                  </fieldset>
                </div>
              </div>
            </div>
        }
      </Wrapper>
    );
  }
}

export default ErrorBoundary(SiteReleaseEdit);
