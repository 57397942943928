import React from 'react';
import { observer } from 'mobx-react';
import { LanguageStore, SiteStore, WordTypeStore } from '@seedlang/stores';
import Text from 'components/text';
import styled from '@emotion/styled';
import autobind from 'autobind-decorator';
import DeleteButton from 'components/button/delete_button';
import Checkbox from 'components/checkbox';
import { isBlank, isPresent } from '@seedlang/utils';
import InPlaceText from 'components/form/in_place_text';
import ReactTooltip from 'react-tooltip';
import BackEndExplainer from 'pages/creator/back_end_explainer';
import SpecialCharacterButtons from 'components/special_character_buttons';

const WordTypeRow = styled.div`
  display: flex;
  margin-bottom: 2px;
  align-items: center;
  font-size: 14px;
`;

const AddButton = styled.div`
  cursor: pointer;
`;

const WordTypeGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

@observer
class LanguageEdit extends React.Component {

  constructor(props) {
    super(props);
    this.loadLanguage();
    WordTypeStore.getIndex();
    SiteStore.getIndex();
  }

  @autobind loadLanguage() {
    LanguageStore.getShow({ids: {languageId: this.props.params.languageId}});
  }

  @autobind onToggleWordType(wordType) {
    if (LanguageStore.hasShowData && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons)) {
      WordTypeStore.toggleLanguage({ids: {wordTypeId: wordType.id, languageId: this.props.params.languageId}}, WordTypeStore.getIndex);
    }
  }

  @autobind toggleGender(gender) {
    LanguageStore.toggleGender({ids: {languageId: this.props.params.languageId, gender: gender}}, this.loadLanguage);
  }

  render() {
    return (
      <div>
        <div className='row'>
          <div className='backend-title'>
            {`${LanguageStore.showData.name} Language Settings`}
          </div>
          {
            this.props.whiteLabeled &&
              <div className='row'>
                <BackEndExplainer>Here you can set up some linguistic information for the language of the platform.</BackEndExplainer>
              </div>
          }
          {
            !this.props.whiteLabeled &&
              <div className='col-xs-6'>
                <fieldset>
                  <legend>Sites</legend>
                  <ul>
                    {
                      SiteStore.hasIndexData && SiteStore.indexData.filter(item => item.languageId === this.props.params.languageId).map(item => {
                        return (
                          <li
                            key={item.id}
                          >
                            <a href={`https://${item.domainString}`} target='_blank' rel='noopener noreferrer'>
                              {item.name}
                            </a>
                          </li>
                        );
                      })
                    }
                  </ul>
                </fieldset>
              </div>
          }
        </div>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Word Types</legend>
              <BackEndExplainer compact margin='5px 0'>These are the word types that can be selected when entering vocab lists.</BackEndExplainer>
              {
                LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                  <Text fontSize='12px' margin='0 0 5px 0'>The word types for this language cannot be modified.</Text>
              }
              <WordTypeGrid>
                {
                WordTypeStore.hasIndexData && WordTypeStore.indexData.filter(item => item.root && item.wordOption).map(item => {
                  return (
                    <WordTypeRow
                      key={item.id}
                    >
                      <Checkbox
                        width='15px'
                        height='15px'
                        margin='0 5px 0 0'
                        onClick={() => this.onToggleWordType(item)}
                        value={item.languagesString && item.languagesString.includes(`|${this.props.params.languageId}|`)}
                        showLock={LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons}
                      />
                      {item.name}
                    </WordTypeRow>
                  );
                })
              }
              </WordTypeGrid>
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Genders</legend>
              <BackEndExplainer compact margin='5px 0'>Add genders that exist in this language, and the articles used with each gender in the singular and plural. These articles will be displayed before nouns in the vocab list.</BackEndExplainer>
              <table className='table-wrapper admin-table'>
                <thead>
                  <tr>
                    <th>Gender</th>
                    <th>Singular article</th>
                    <th>Plural article</th>
                    {
                      !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                        <th/>
                    }
                  </tr>
                </thead>
                <tbody>
                {
                  LanguageStore.hasShowData && ['feminine','masculine','neuter'].map(item => {
                    const gender = LanguageStore.showData.genders.find(i => i.gender === item);
                    return (
                      <tr
                        key={item}
                      >
                        <td>
                          <div style={{color: isPresent(gender) ? null : '#cbc8c8'}}>
                            {item}
                          </div>
                        </td>
                        <td>
                          {
                            gender && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                              <InPlaceText
                                id={gender.id}
                                submitOnEnter
                                defaultValue={gender.singularArticle}
                                model='genders'
                                field='singular_article'
                              />
                          }
                          {
                            this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                              gender.singularArticle
                          }
                        </td>
                        <td>
                          {
                            gender && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                              <InPlaceText
                                id={gender.id}
                                submitOnEnter
                                defaultValue={gender.pluralArticle}
                                model='genders'
                                field='plural_article'
                              />
                          }
                          {
                            this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                              gender.pluralArticle
                          }
                        </td>
                        {
                          !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                          <td>
                            {
                              isPresent(gender) &&
                              <DeleteButton
                                onConfirm={() => this.toggleGender(item)}
                                message='Remove this gender?'
                                className='fa-block right'
                              />
                            }
                            {
                              isBlank(gender) &&
                              <AddButton
                                onClick={() => this.toggleGender(item)}
                                data-tip
                                data-for={`add-gender-${item}`}
                              >
                                <i className='fa fa-plus'/>
                                <ReactTooltip
                                  place='left'
                                  type='dark'
                                  effect='solid'
                                  id={`add-gender-${item}`}
                                  class='custom-tooltip'
                                >
                                  {`Add ${item} gender`}
                                </ReactTooltip>
                              </AddButton>
                            }
                          </td>
                        }
                      </tr>
                    );
                  })
                }
                </tbody>
              </table>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Special Characters</legend>
              {
                LanguageStore.hasShowData &&
                  <BackEndExplainer compact margin='5px 0'>{`${LanguageStore.showData.name} characters that users might not have on their keyboard. These will be shown in buttons in exercises and comment sections.`}</BackEndExplainer>
              }
              {
                LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                <Text>{LanguageStore.showData.specialCharacters}</Text>
              }
              {
                LanguageStore.hasShowData && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                  <InPlaceText
                    submitOnEnter
                    show
                    id={LanguageStore.showData.id}
                    defaultValue={LanguageStore.showData.specialCharacters}
                    model='languages'
                    field='special_characters'
                    afterChange={this.loadLanguage}
                    maxLength={20}
                  />
              }
              {
                LanguageStore.hasShowData && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                  <Text fontSize='12px' margin='0 0 5px 0'>Please enter characters without any spaces or punctuation between characters.</Text>
              }
              {
                LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                  <Text fontSize='12px' margin='0 0 5px 0'>The special characters for this language cannot be edited.</Text>
              }
              <div className='row'>
                <Text fontSize='14px' margin='0 0 5px 0'>Preview:</Text>
                <SpecialCharacterButtons
                  options={LanguageStore.showData.specialCharacterList}
                />
              </div>
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Verb Structure components</legend>
              <div className='row'>
                <BackEndExplainer compact margin='5px 0'>Verb structures are entries in the vocab that exemplify the structure of a verb. For instance, "to talk to somebody" is a verb structure for the verb "to talk". If "to" and "somebody" are entered below as keywords, the system will be able to automatically determine that "to talk to sombody" relates to the verb "to talk".</BackEndExplainer>
                <Text fontSize='14px' margin='0 0 5px 0'>{`Keywords used in verb structures in ${LanguageStore.showData.name}:`}</Text>
              </div>
              {
                LanguageStore.hasShowData && this.props.whiteLabeled && LanguageStore.showData.hasLessons &&
                <Text>{LanguageStore.showData.wordStructureComponents}</Text>
              }
              {
                LanguageStore.hasShowData && !(this.props.whiteLabeled && LanguageStore.showData.hasLessons) &&
                <InPlaceText
                  submitOnEnter
                  inputType='textarea'
                  show={isBlank(LanguageStore.showData.wordStructureComponents)}
                  id={LanguageStore.showData.id}
                  defaultValue={LanguageStore.showData.wordStructureComponents}
                  model='languages'
                  field='word_structure_components'
                  afterChange={this.loadLanguage}
                />
              }
              <div className='row'>
                <Text fontSize='12px' margin='0 0 5px 0'>Separated by commas, with optional short version in between brackets: something[sth],somebody[sb],with,against</Text>
              </div>
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default LanguageEdit;
