import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { CampaignTypeCampaignStore , CampaignStore } from '@seedlang/stores';
import Paginator from 'components/paginator';
import DeleteButton from 'components/button/delete_button';
import CampaignCreate from 'pages/builder/campaigns/campaign_create';
import autobind from 'autobind-decorator';
import { Link } from 'react-router';
import InPlaceCheckbox from 'components/form/in_place_checkbox';
import ReactTooltip from 'react-tooltip';
import SortableColumns from 'components/hoc/sortable_columns';
import cx from 'classnames';
import InfoTooltip from 'components/info_tooltip';

@observer
class CampaignIndex extends React.Component {
  @observable showMessage = false;
  @observable campaignTypeId;

  constructor(props) {
    super(props);
    if (props.oneTimeSending) {
      this.campaignTypeId = 'one-time';
    }
    else {
      this.campaignTypeId = props.campaignTypeId;
    }
  }

  componentDidMount() {
    this.loadCampaigns();
  }

  componentWillUnmount() {
    CampaignStore.clearIndexData();
  }

  @autobind onDelete(id) {
    CampaignStore.destroy({ids: {campaignId: id}}, this.loadCampaigns);
  }

  @autobind loadCampaigns() {
    CampaignTypeCampaignStore.getIndex({ids: {campaignTypeId: this.campaignTypeId}, sort: '-current', limit: 5});
  }

  sendTestSending(id) {
    CampaignStore.sendCampaign({data: {test_sending: true}, ids: {campaignId: id}}, this.afterSendTestSending);
  }

  @autobind afterSendTestSending() {
    this.showMessage = true;
    setTimeout(this.hideMessage, 3000);
  }

  @autobind hideMessage() {
    this.showMessage = false;
  }

  @autobind onSortColumn(columnName) {
    this.props.onSortColumn(columnName, false, {ids: {campaignTypeId: this.campaignTypeId}});
  }

  render() {
    return (
      <div className='campaign-index'>
        {
          this.showMessage &&
            <div className='alert'>
              A test has been sent.
            </div>
        }
        <CampaignCreate
          {...this.props}
          oneTimeSending={this.props.oneTimeSending}
          afterCreate={this.loadCampaigns}
          mobileNotification={this.props.mobileNotification}
        />
        <table className='table-wrapper'>
          <thead>
            <tr>
              <th width='20' />
              {
                !this.props.mobileNotification &&
                  <th
                    className={cx('sortable', this.props.columnClassNames('name'))}
                    onClick={() => this.onSortColumn('name')}
                  >
                    Name
                  </th>
              }
              {
                !this.props.mobileNotification &&
                  <th>Email Subject</th>
              }
              {
                !this.props.mobileNotification &&
                  <th
                    className={cx('sortable', this.props.columnClassNames('created_at'))}
                    onClick={() => this.onSortColumn('created_at')}
                    width='90px'
                  >
                    Created At
                  </th>
              }
              {
                this.props.mobileNotification &&
                  <th>Title</th>
              }
              {
                this.props.mobileNotification &&
                  <th>Body</th>
              }
              <th># Sent</th>
              <th># Clicked</th>
              <th>% Clicked</th>
              {
                !this.props.oneTimeSending &&
                  <th
                    className={cx('sortable', this.props.columnClassNames('current'))}
                    onClick={() => this.props.onSortColumn('current', false, {ids: {campaignTypeId: this.campaignTypeId}})}
                  >
                    Current
                    <InfoTooltip position='left'>
                      <div>When triggered, the current campaign(s) will be sent to users.</div>
                    </InfoTooltip>
                  </th>
              }
              <th width='40'></th>
              <th width='40'></th>
            </tr>
          </thead>
          <tbody>
            {
              CampaignTypeCampaignStore.indexData.map(item => {
                return (
                  <tr key={item.id}>
                    <td>
                      <Link
                        to={{ name: this.props.whiteLabeled ? 'creator.campaigns.edit' : 'builder.campaigns.edit', params: { groupId: this.props.groupId, campaignTypeId: this.props.campaignTypeId, campaignId: item.id } }}
                        className='on-click'
                      >
                        <i className='fa fa-chevron-right fa-block' />
                      </Link>
                    </td>
                    {
                      !this.props.mobileNotification &&
                        <td>
                          {item.name}
                        </td>
                    }
                    {
                      !this.props.mobileNotification &&
                        <td>
                          {item.subject?.length > 40 ? `${item.subject.substring(0, 40)}...` : item.subject}
                        </td>
                    }
                    {
                      !this.props.mobileNotification &&
                        <td>
                          {item.createdAt?.formattedDateWithYear}
                        </td>
                    }
                    {
                      this.props.mobileNotification &&
                        <td>
                          {item.title}
                        </td>
                    }
                    {
                      this.props.mobileNotification &&
                        <td>
                          {item.notificationBody}
                        </td>
                    }
                    <td>
                      {item.campaignMessagesCount}
                    </td>
                    <td>
                      {item.clickedCount}
                    </td>
                    <td>
                      {item.percentageClicked}
                    </td>
                    {
                      !this.props.oneTimeSending &&
                        <td>
                          <InPlaceCheckbox
                            icon='check'
                            iconStyle={{fontSize: 24}}
                            model='campaigns'
                            field='current'
                            id={item.id}
                            value={item.current}
                          />
                        </td>
                    }
                    <td>
                      <div
                        className='underline'
                        onClick={() => this.sendTestSending(item.id)}
                        data-tip
                        data-for={`send-test-${item.id}`}
                      >
                        <i className='fa fa-envelope fa-block send-test-mail' />
                        <ReactTooltip
                          place='top'
                          type='dark'
                          effect='solid'
                          id={`send-test-${item.id}`}
                          class='custom-tooltip'
                        >
                          Send Test
                        </ReactTooltip>
                      </div>
                    </td>
                    <td>
                      <DeleteButton
                        onConfirm={() => this.onDelete(item.id)}
                        message='Delete this campaign?'
                        className='fa-block right'
                      />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
        <Paginator
          store={CampaignTypeCampaignStore}
          ids={{campaignTypeId: this.props.campaignTypeId}}
        />
      </div>
    );
  }
}

// export default CampaignIndex;
export default SortableColumns(CampaignIndex, CampaignTypeCampaignStore, { sortColumn: 'current', sortIsAscending: false });
