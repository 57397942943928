import React from 'react';
import { observer } from 'mobx-react';
import ErrorBoundary from 'components/hoc/error_boundary.js';
import InPlaceText from 'components/form/in_place_text';
import InPlaceDatePicker from 'components/form/in_place_date_picker';
import { BlogEntryStore } from '@seedlang/stores';

@observer
class BlogEntryEdit extends React.Component {

  constructor(props) {
    super(props);
    BlogEntryStore.getShow({ids: {blogEntryId: this.props.params.blogEntryId}});
  }

  render() {
    return (
      <div className='blog-entry-edit'>
        <div className='row'>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Title</legend>
              <InPlaceText
                id={BlogEntryStore.showData.id}
                model='blog_entries'
                field='title'
                value={BlogEntryStore.showData.title}
              />
            </fieldset>
          </div>
          <div className='col-xs-6'>
            <fieldset>
              <legend>Published At</legend>
              <InPlaceDatePicker
                id={BlogEntryStore.showData.id}
                model='blog_entries'
                field='published_at'
                value={BlogEntryStore.showData.publishedAt}
              />
            </fieldset>
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-12'>
            <fieldset>
              <legend>Body</legend>
              <InPlaceText
                richText
                show
                inputType='textarea'
                id={BlogEntryStore.showData.id}
                model='blog_entries'
                field='body'
                value={BlogEntryStore.showData.body}
              />
            </fieldset>
          </div>
        </div>
      </div>
    );
  }
}

export default ErrorBoundary(BlogEntryEdit);
