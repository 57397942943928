import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import styled from '@emotion/styled';
import { createUuid } from '@seedlang/utils';

const Wrapper = styled.div`
  height: ${props => props.height};
  margin: ${props => props.margin};
`;

@observer
class LottieAnimation extends React.Component {
  @observable id;

  constructor(props) {
    super(props);
    this.id = createUuid();
  }

  componentDidMount() {
    window.lottie.loadAnimation({
      container: document.getElementById(this.id),
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `/lottie/${this.props.animation}.json`,
    });
  }

  render() {
    return (
      <Wrapper
        height={this.props.height}
        margin={this.props.margin}
        id={this.id}
      />
    );
  }
}

export default LottieAnimation;
